import React, { useEffect, useState, useRef } from "react";
import Lodash from "lodash";
import { Formik } from "formik";
import * as Yup from "yup";
import { useDispatch } from "react-redux";
import { useHistory } from "react-router-dom";
import { TextField } from "@material-ui/core";
import { Card, CardBody, CardFooter, CardHeader, Col, Container, Row, Form, Button } from "reactstrap";
import PageHeader from "components/Headers/PageHeader.js";
import { LOGINSTATE } from "../../../config/config.json";
import { ChsStyles } from "constants/globalStyles";
import { createResource, updateResource } from "actions/resource";

const reviewSchema = Yup.object({
  Category: Yup.string().required(),
  Title: Yup.string().required().min(4).max(1000),
});

const ResourceDetail = (props) => {
  const formRef = useRef();
  const history = useHistory();
  const dispatch = useDispatch();

  const [resource, setResource] = useState({});
  const [isEdit, setIsEdit] = useState(false); // For edit resource details passed by parent page

  useEffect(() => {
    const tempResource = props.location.state?.resource;
    if (tempResource && !Lodash.isEmpty(tempResource)) {
      setResource(tempResource);
      setIsEdit(true);
    }
  }, []);

  useEffect(() => {
    if (formRef.current && resource && !Lodash.isEmpty(resource)) {
      formRef.current?.resetForm();
      formRef.current.setFieldValue("Title", resource.title, false);
      formRef.current.setFieldValue("Description", resource.description, false);
      formRef.current.setFieldValue("Category", resource.category, false);
      formRef.current.setFieldValue("Language", resource.Language, false);
      formRef.current.setFieldValue("File", resource.file, false);
      formRef.current.setFieldValue("json", resource.json, false);
      formRef.current.setFieldValue("Primer", resource.Primer, false);
      formRef.current.setFieldValue("Logo", resource.Logo, false);
    }
  }, [resource]);

  // handle change event of the file
  const handleFileChange = async (e) => {
    try {
      const file = e.target.files[0];
      if (LOGINSTATE) console.log(file);
      if (formRef.current) {
        formRef.current.setFieldValue("File", file, false);
      }
    } catch (err) {
      if (LOGINSTATE) console.log("file error", err);
    }
  };


  return (
    <>
      <PageHeader />

      {/* Page content */}
      <Container className="mt--7" fluid>
        <Row className="mt-5">
          <div className="col">
            <Card className="shadow">
              <CardHeader className="border-0">
                <div className="row">
                  <div className="mb-xl-0 col-11">
                    <h3 className="mb-0">APP Resources</h3>
                    <h6 className="mb-0">Resources for tools and more</h6>
                  </div>
                  <div className="mb-xl-0"></div>
                </div>
              </CardHeader>
              <CardBody>
                <Formik
                  initialValues={{
                    Title: "",
                    Description: "",
                    Category: "local",
                    Language: "",
                    File: "",
                    json: "",
                    Primer: "",
                    Logo: "",
                  }}
                  validationSchema={reviewSchema}
                  onSubmit={(values, actions) => {
                    console.log("formik values: ", values);
                    let params = {
                      title: values.Title,
                      description: values.Description,
                      category: values.Category,
                      Language: values.Language,
                      file: values.File,
                      json: values.json,
                      Primer: values.Primer,
                      Logo: values.Logo,
                    };
                    let param = {
                      title: values.Title,
                      description: values.Description,
                      category: values.Category,
                      Language: values.Language,
                      file: values.File,
                      json: values.json,
                      Primer: values.Primer,
                      Logo: values.Logo,
                      resourceId: resource._id,
                    };
                    if (resource.file) {
                      dispatch(updateResource(param, history));
                    } else {
                      dispatch(createResource(params, history));
                    }
                  }}
                  innerRef={formRef}
                >
                  {(props) => (
                    <Form role="form" style={{ marginTop: -25 }} onSubmit={props.handleSubmit}>
                      <Row>
                        <Col lg="4">
                          <TextField
                            select
                            fullWidth
                            label={"Resource Category*"}
                            variant="outlined"
                            value={props.values.Category}
                            onChange={props.handleChange("Category")}
                            onBlur={props.handleChange("Category")}
                            SelectProps={{ native: true }}
                            style={ChsStyles.Question}
                            className={"form-control-alternative form-control-edit"}
                            error={props.touched.Category && Boolean(props.errors.Category)}
                            helperText={props.touched.Category && props.errors.Category}
                            disabled={true}
                          >
                            {/* <option key="" value=""></option> */}
                            <option key="local" value="local">
                              Local Resources
                            </option>
                          </TextField>
                        </Col>
                      </Row>

                      <Row>
                        <Col lg="8">
                          <TextField
                            fullWidth
                            label={"Resource Title*"}
                            variant="outlined"
                            value={props.values.Title}
                            onChange={props.handleChange("Title")}
                            onBlur={props.handleChange("Title")}
                            style={ChsStyles.Question}
                            className={"form-control-alternative form-control-edit"}
                            error={props.touched.Title && Boolean(props.errors.Title)}
                            helperText={props.touched.Title && props.errors.Title}
                          />
                        </Col>
                      </Row>

                      <Row>
                        <Col lg="12">
                          <TextField
                            fullWidth
                            label={"Resource Description"}
                            variant="outlined"
                            value={props.values.Description}
                            onChange={props.handleChange("Description")}
                            onBlur={props.handleChange("Description")}
                            style={ChsStyles.Question}
                            className={"form-control-alternative form-control-edit"}
                            error={props.touched.Description && Boolean(props.errors.Description)}
                            helperText={props.touched.Description && props.errors.Description}
                          />
                        </Col>
                      </Row>

                      <Row>
                        <Col md="8">
                          {isEdit ? (
                            <>
                              <TextField
                                fullWidth
                                id="File"
                                name="File"
                                variant="outlined"
                                label="Resource File to Attach"
                                value={props.values.File}
                                style={ChsStyles.Question}
                                className={"form-control-alternative form-control-edit"}
                                error={props.touched.File && Boolean(props.errors.File)}
                                helperText={props.touched.File && props.errors.File}
                                disabled={isEdit}
                              />
                              <h6 className="mb-0 mt--2">* Attachment can not edit again</h6>
                            </>
                          ) : (
                            <TextField
                              fullWidth
                              id="File"
                              name="File"
                              type="file"
                              variant="outlined"
                              label="Resource File to Attach"
                              multiple={false}
                              onChange={(e) => {
                                handleFileChange(e);
                              }}
                              InputLabelProps={{ shrink: true }}
                              style={ChsStyles.Question}
                              className={"form-control-alternative form-control-edit"}
                              error={props.touched.File && Boolean(props.errors.File)}
                              helperText={props.touched.File && props.errors.File}
                            />
                          )}
                        </Col>
                      </Row>
                    </Form>
                  )}
                </Formik>
              </CardBody>
              <CardFooter className="py-4">
                <Row>
                  <Col lg="6" className="text-left">
                    <Button
                      style={ChsStyles.GeneralButton}
                      color="primary"
                      onClick={() => {
                        history.goBack();
                      }}
                    >
                      BACK
                    </Button>
                    <Button
                      style={ChsStyles.GeneralButton}
                      color="primary"
                      onClick={() => {
                        if (formRef.current) {
                          formRef.current.handleSubmit();
                        }
                      }}
                    >
                      SAVE
                    </Button>
                  </Col>
                </Row>
              </CardFooter>
            </Card>
          </div>
        </Row>
      </Container>
    </>
  );
};

export default ResourceDetail;
