import { withStyles } from "@material-ui/core";
import { Pagination as MuiPagination } from "@material-ui/lab";
import Pagination from "react-responsive-pagination";

const MuiPaginationCustom = withStyles({
  root: {
    "& svg": {
      transform: "scaleX(-1)"
    }
  }
})(MuiPagination);

export const RtlPagination = (props) => {
  return (
    !!props.rtol || (props.rtol && props.rtol === true)
      ? <MuiPaginationCustom
        dir="rtl"
        page={props.current}
        onChange={(e, page) => props.onPageChange(page)}
        count={props.total}
        // color='secondary'
        shape="rounde"
        size='large'
        style={{
          justifyContent: "center",
          display: 'flex'
        }}
      ></MuiPaginationCustom>
      : <Pagination
        current={props.current}
        total={props.total}
        onPageChange={props.onPageChange}
      ></Pagination>
  )
}