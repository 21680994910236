import * as actionType from "constants/actionTypes";
import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
// reactstrap components
import { Card, CardBody, CardTitle, Col, Container, Row } from "reactstrap";


const headImage = {
  dashboard: {
    fg: "url(" + require("../../assets/img/theme/dashboard.png").default + ")",
    bg: "url(" + require("../../assets/img/theme/dashboardBg.png").default + ")"
  },
  faq: {
    fg: "url(" + require("../../assets/img/theme/faq.png").default + ")",
    bg: "url(" + require("../../assets/img/theme/faqBg.png").default + ")"
  },
  takeAssessment: {
    fg: "url(" + require("../../assets/img/theme/takeAssessment.png").default + ")",
    bg: "url(" + require("../../assets/img/theme/takeAssessmentBg.png").default + ")"
  },
  resources: {
    fg: "url(" + require("../../assets/img/theme/resources.png").default + ")",
    bg: "url(" + require("../../assets/img/theme/resourcesBg.png").default + ")"
  },
  print: {
    fg: null,
    bg: "url(" + require("../../assets/img/theme/takeAssessmentBg.png").default + ")"
  }
}

const Header = (props) => {

  useEffect(() => {
    // console.log("props by child: ", props?.hType);
  }, []);


  return (
    <div
      style={{
        backgroundColor: '#af1e23',
        backgroundImage: props?.hType ? headImage[props.hType]?.bg : null,
        backgroundSize: "cover",
        backgroundPosition: "center top",
      }}
    >
      <div
        className="header pb-8 pt-3 pt-md-6"
        style={{
          backgroundImage: props?.hType ? headImage[props.hType]?.fg : null,
          backgroundSize: "contain",
          backgroundRepeat: "no-repeat",
          backgroundPosition: "center top",
          // backgroundOrigin: "content-box",
        }}
      >
        <Container fluid style={{ minHeight: 100 }}>
        </Container>
      </div>
    </div>
  );

  // if ("faq" === props?.hType) {
  //   return (
  //     <div
  //       style={{
  //         backgroundColor: '#af1e23',
  //         backgroundImage: "url(" + require("../../assets/img/theme/faqBg.png").default + ")",
  //         backgroundSize: "cover",
  //         backgroundPosition: "center top",
  //       }}
  //     >
  //       <div
  //         className="header pb-8 pt-3 pt-md-6"
  //         style={{
  //           backgroundImage: "url(" + require("../../assets/img/theme/faq.png").default + ")",
  //           backgroundSize: "contain",
  //           backgroundRepeat: "no-repeat",
  //           backgroundPosition: "center top",
  //           // backgroundOrigin: "content-box",
  //         }}
  //       >
  //         <Container fluid style={{ minHeight: 100 }}>
  //         </Container>
  //       </div>
  //     </div>
  //   )
  // }
  // else if ("takeAssessment" === props?.hType) {
  //   return (
  //     <div
  //       style={{
  //         backgroundColor: '#af1e23',
  //         backgroundImage: "url(" + require("../../assets/img/theme/takeAssessmentBg.png").default + ")",
  //         backgroundSize: "cover",
  //         backgroundPosition: "center top",
  //       }}
  //     >
  //       <div
  //         className="header pb-8 pt-3 pt-md-6"
  //         style={{
  //           backgroundImage: "url(" + require("../../assets/img/theme/takeAssessment.png").default + ")",
  //           backgroundSize: "contain",
  //           backgroundRepeat: "no-repeat",
  //           backgroundPosition: "center top",
  //           // backgroundOrigin: "content-box",
  //         }}
  //       >
  //         <Container fluid style={{ minHeight: 100 }}>
  //         </Container>
  //       </div>
  //     </div>
  //   )
  // }
  // else if ("resources" === props?.hType) {
  //   return (
  //     <div
  //       style={{
  //         backgroundColor: '#af1e23',
  //         backgroundImage: "url(" + require("../../assets/img/theme/resourcesBg.png").default + ")",
  //         backgroundSize: "cover",
  //         backgroundPosition: "center top",
  //       }}
  //     >
  //       <div
  //         className="header pb-8 pt-3 pt-md-6"
  //         style={{
  //           backgroundImage: "url(" + require("../../assets/img/theme/resources.png").default + ")",
  //           backgroundSize: "contain",
  //           backgroundRepeat: "no-repeat",
  //           backgroundPosition: "center top",
  //           // backgroundOrigin: "content-box",
  //         }}
  //       >
  //         <Container fluid style={{ minHeight: 100 }}>
  //         </Container>
  //       </div>
  //     </div>
  //   )
  // }
  // else {
  //   return null;
  // }



};

export default Header;
