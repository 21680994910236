import { TextField } from "@material-ui/core";
// core components
import PageHeader from "components/Headers/PageHeader.js";
import { SURVEY_TEMPLATE_LOCAL } from "constants/actionTypes";
import { ChsStyles, GlobalStylesColor } from "constants/globalStyles";
import { Formik } from "formik";
import { processArray } from "helper";
import Lodash from "lodash";
import React, { useEffect, useRef, useState } from "react";
import "react-confirm-alert/src/react-confirm-alert.css"; // Import css
import { useDispatch, useSelector } from "react-redux";
import Pagination from "react-responsive-pagination";
import { useHistory } from "react-router-dom";
// reactstrap components
import { Button, Card, CardBody, CardFooter, CardHeader, CardTitle, Col, Container, Form, Row } from "reactstrap";
import * as Yup from "yup";
import { LOGINSTATE } from "../../../config/config.json";

const reviewSchema = Yup.object({
  Question: Yup.string().required().min(3).max(1000),
  Note: Yup.string().min(0).max(1000),
  Code: Yup.string().required().min(2).max(10),
});

const SurveyCreateTemplate = (props) => {
  // dispatch and history
  const dispatch = useDispatch();
  const history = useHistory();

  const initQuestionData = {
    question: "",
    questionNote: "",
    questionCode: "",
    questionOrder: 0,
    questionComments: [],
    scoreRecommendations: [
      {
        score: null,
        recommendation: "",
      },
    ],
  };

  const initSurveyInfo = {
    type: "",
    title: "",
    segment: "",
  };

  const [surveyNew, setSurveyNew] = useState({}); // Create survey details
  const [questionData, setQuestionData] = useState([]); // Question details
  const [questionCount, setQuestionCount] = useState(0); // To get the maximum number of questions
  const [questionCounter, setQuestionCounter] = useState(0); // Current active question
  const [navigationCounter, setNavigationCounter] = useState(0); // To support footer navigation
  const [surveyInfo, setSurveyInfo] = useState(initSurveyInfo);
  const [segmentData, setSegmentData] = useState([]); // Segment details
  const [segmentCounter, setSegmentCounter] = useState(0); // Current active segment
  const [commentsList, setCommentsList] = useState([""]);
  const [recommendationList, setRecommendationList] = useState([{ score: null, recommendation: "" }]);
  const [refreshToken, setRefreshToken] = useState(0);
  const [showPagination, setShowPagination] = useState(true);

  // To update fomik values
  const formRef = useRef();

  // get the details passed by the parameter to the page. Afterwards, process needed information
  useEffect(() => {
    if (LOGINSTATE) console.log("---------------> called effect1");

    // Loading sequence for the data
    if (localStorage.getItem(SURVEY_TEMPLATE_LOCAL)) {
      let surveySaved = JSON.parse(localStorage.getItem(SURVEY_TEMPLATE_LOCAL));
      if (LOGINSTATE) console.log("localstore surveySaved: ", surveySaved);
      if (surveySaved !== undefined || surveySaved !== null) {
        setInitSurvey(surveySaved);
      }
    }
  }, []);

  const surveySaved = useSelector((state) => state.survey.localSurveyTemplate);
  useEffect(() => {
    if (LOGINSTATE) console.log("---------------> called effect2");
    if (LOGINSTATE) console.log("dispatch surveySaved: ", surveySaved);

    if (surveySaved !== undefined || surveySaved !== null) {
      setInitSurvey(surveySaved);
    }
  }, [surveySaved]);

  const setInitSurvey = (surveySaved) => {
    if (LOGINSTATE) console.log("---------- surveySaved: ", surveySaved);
    // get survey details
    if (!Lodash.isEmpty(surveySaved) && surveySaved.segments && !Lodash.isEmpty(surveySaved.segments)) {
      if (LOGINSTATE) console.log("survey is updating...");
      setSurveyNew(surveySaved);
      setSegmentData(surveySaved.segments);

      // get the parameter passed values. Then set data accordingly.
      // segmentCounter is sometimes difficult to check whether empty or not. Therefore, checking the both values
      let segmentCounter = props.location.state?.segmentCounter;
      let segmentData = props.location.state?.segmentData;

      if (segmentCounter !== undefined && segmentData !== undefined && !Lodash.isEmpty(segmentData)) {
        if (LOGINSTATE) console.log("process inside");
        setSegmentCounter(segmentCounter);
        setQuestionData(segmentData[segmentCounter].questions);
        setQuestionCount(segmentData[segmentCounter].questions.length);
        setSurveyInfo({ type: surveySaved.type, title: surveySaved.title, segment: segmentData[segmentCounter].title });
      }
    }
  };

  // this will set the formik values for the question
  useEffect(() => {
    if (LOGINSTATE) console.log("---------------> called effect3");
    if (LOGINSTATE) console.log("------- question");

    if (formRef.current && !Lodash.isEmpty(questionData)) {
      formRef.current?.resetForm();
      formRef.current.setFieldValue("Question", questionData[questionCounter].question, false);
      formRef.current.setFieldValue("Note", questionData[questionCounter].questionNote, false);
      formRef.current.setFieldValue("Code", questionData[questionCounter].questionCode, false);

      // since comments and recomendations are using external arrays, reset them too
      setCommentsList(questionData[questionCounter].questionComments);
      setRecommendationList(questionData[questionCounter].scoreRecommendations);
      formRef.current.setFieldValue("Comments", questionData[questionCounter].questionComments, false);
      formRef.current.setFieldValue("Recommendations", questionData[questionCounter].scoreRecommendations, false);
    }
  }, [questionCounter, questionData, questionCount]);

  // Add new question
  const handleNewQuestionClick = async (e) => {
    // If the segment is the first one, clear the formik
    if (formRef.current && questionData.length === 0) {
      formRef.current?.resetForm();
      formRef.current.setFieldValue("Question", "", false);
      formRef.current.setFieldValue("Note", "", false);
      formRef.current.setFieldValue("Code", "", false);
    }
    let updatedQuestions = await processArray(questionData, "ADD", questionCounter + 1, initQuestionData);
    setQuestionCount(updatedQuestions.length);
    setQuestionData(updatedQuestions);
    if (LOGINSTATE) console.log("updatedQuestions: ", updatedQuestions);
    setRefreshToken(refreshToken + 1);
  };

  const handleDeleteQuestionClick = async (e) => {
    let updatedQuestions = await processArray(questionData, "DELETE", questionCounter, null);
    setQuestionCounter(questionCounter <= 0 ? 0 : questionCounter - 1);
    setQuestionCount(updatedQuestions.length);
    setQuestionData(updatedQuestions);
    setNavigationCounter(navigationCounter <= 0 ? 0 : navigationCounter - 1); // To manage the change of question count visually
    if (LOGINSTATE) console.log("updatedQuestions: ", updatedQuestions);
    if (LOGINSTATE) console.log("navigationCounter: ", navigationCounter);
  };

  const handleMoveUpQuestionClick = async (e) => {
    let updatedQuestions = await processArray(questionData, "MOVEUP", questionCounter, null);
    if (!Lodash.isEmpty(updatedQuestions)) {
      setQuestionCounter(questionCounter + 1);
      setQuestionData(updatedQuestions);
    }
    if (LOGINSTATE) console.log("updatedQuestions: ", updatedQuestions);
  };

  const handleMoveDownQuestionClick = async (e) => {
    let updatedQuestions = await processArray(questionData, "MOVEDOWN", questionCounter, null);
    if (!Lodash.isEmpty(updatedQuestions)) {
      setQuestionCounter(questionCounter - 1);
      setQuestionData(updatedQuestions);
    }
    if (LOGINSTATE) console.log("updatedQuestions: ", updatedQuestions);
  };

  const updateQuestion = async (values) => {
    let updatedQuestion = questionData[questionCounter];
    updatedQuestion["question"] = values.Question;
    updatedQuestion["questionNote"] = values.Note;
    updatedQuestion["questionCode"] = values.Code;
    updatedQuestion["questionOrder"] = questionCounter;
    updatedQuestion["questionComments"] = values.Comments;
    updatedQuestion["scoreRecommendations"] = values.Recommendations;

    let updatedQuestions = await processArray(questionData, "UPDATEAT", questionCounter, updatedQuestion);
    setQuestionData(updatedQuestions);

    // Locally save the survey
    let survey = surveyNew;
    let segments = segmentData;
    segments[segmentCounter]["questions"] = questionData;
    survey["segments"] = segments;
    setSurveyNew(survey);
    // alert("Details are saved to local");
    if (LOGINSTATE) console.log("Details are saved to local: ", survey);
    dispatch({ type: SURVEY_TEMPLATE_LOCAL, payload: survey });
    localStorage.setItem(SURVEY_TEMPLATE_LOCAL, JSON.stringify(survey));
  };

  return (
    <>
      <PageHeader />

      {/* Page content */}
      <Container className="mt--7" fluid>
        {/* Custom cards for survey type */}

        {/* Card stats */}
        <Row className="mt-5">
          <Col lg="6" xl="4">
            <Card className="card-stats mb-4 mb-xl-0">
              <CardBody>
                <Row>
                  <div className="col">
                    <CardTitle tag="h5" className="text-uppercase text-muted mb-0">
                      CHS Tool
                    </CardTitle>
                    <span className="h2 font-weight-bold mb-0">CHSAT</span>
                  </div>
                  <Col className="col-auto">
                    <div className="icon icon-shape bg-danger text-white rounded-circle shadow">
                      <i className="fas fa-clipboard-check" style={{ fontSize: 32 }} />
                    </div>
                  </Col>
                </Row>
                <p className="mt-3 mb-0 text-muted text-sm"></p>
              </CardBody>
            </Card>
          </Col>
        </Row>

        <br />

        {/* create survey questions */}
        <Row>
          <div className="col">
            <Card className="shadow">
              {/* survey header */}
              <CardHeader className="border-0">
                <Row>
                  <Col lg="9">
                    <small>{surveyInfo.title}</small>
                    <h3 className="mb-0">{surveyInfo.segment}</h3>
                    {/* {console.log("$questionCounter", questionCounter)}
                    {console.log("$questionData", questionData)} */}
                    <h6 className="mb-0">Managing questions</h6>
                  </Col>

                  <Col lg="3" className="text-right">
                    <i
                      className="fas fa-minus-circle"
                      style={{ fontSize: 28, color: GlobalStylesColor.mbppThemeBlue, marginRight: 5, cursor: "pointer" }}
                      onClick={(e) => {
                        e.preventDefault();
                        handleDeleteQuestionClick(e);
                      }}
                    />
                    <span className="sr-only">Delete</span>

                    <i
                      className="fas fa-chevron-circle-up"
                      onClick={(e) => {
                        e.preventDefault();
                        handleMoveUpQuestionClick(e);
                      }}
                      style={{ fontSize: 28, color: GlobalStylesColor.mbppThemeBlue, marginRight: 5, cursor: "pointer" }}
                    />
                    <span className="sr-only">Move Up</span>

                    <i
                      className="fas fa-chevron-circle-down"
                      onClick={(e) => {
                        e.preventDefault();
                        handleMoveDownQuestionClick(e);
                      }}
                      style={{ fontSize: 28, color: GlobalStylesColor.mbppThemeBlue, marginRight: 5, cursor: "pointer" }}
                    />
                    <span className="sr-only">Move Down</span>

                    <i
                      className="fas fa-plus-circle"
                      onClick={(e) => {
                        e.preventDefault();
                        handleNewQuestionClick(e);
                      }}
                      style={{ fontSize: 28, color: GlobalStylesColor.mbppThemeBlue, marginRight: 5, cursor: "pointer" }}
                    />
                    <span className="sr-only">Add</span>
                  </Col>
                </Row>
              </CardHeader>

              {/* question content */}
              <CardBody style={{ display: questionData.length > 0 ? "block" : "none" }}>
                <Formik
                  initialValues={{
                    Question: "",
                    Note: "",
                    Code: "",
                    Comments: [""],
                    Recommendations: [
                      {
                        score: null,
                        recommendation: "",
                      },
                    ],
                  }}
                  validationSchema={reviewSchema}
                  onSubmit={(values, actions) => {
                    updateQuestion(values);
                  }}
                  innerRef={formRef}
                >
                  {(props) => (
                    <Form role="form" onSubmit={props.handleSubmit} onReset={props.handleReset}>
                      <hr className="my-2" />
                      <h6 className="heading-small mb-2" style={ChsStyles.SubTopic}>
                        Assessment Question
                      </h6>

                      <Row>
                        <Col lg="1"></Col>
                        <Col lg="11">
                          <Row>
                            <Col lg="6">
                              <TextField
                                fullWidth
                                label={"Question Code*"}
                                variant="outlined"
                                value={props.values.Code}
                                onChange={props.handleChange("Code")}
                                onBlur={props.handleSubmit}
                                style={ChsStyles.Question}
                                className={"form-control-alternative form-control-edit"}
                                error={props.touched.Code && Boolean(props.errors.Code)}
                                helperText={props.touched.Code && props.errors.Code}
                              />
                              <div style={ChsStyles.QuickNote}>This code should be unique to identify the question for this assessment.</div>
                            </Col>
                          </Row>

                          <Row>
                            <Col lg="12">
                              <TextField
                                fullWidth
                                label={"Question*"}
                                variant="outlined"
                                value={props.values.Question}
                                onChange={props.handleChange("Question")}
                                onBlur={props.handleSubmit}
                                style={ChsStyles.Question}
                                className={"form-control-alternative form-control-edit"}
                                error={props.touched.Question && Boolean(props.errors.Question)}
                                helperText={props.touched.Question && props.errors.Question}
                              />
                            </Col>
                          </Row>

                          <Row>
                            <Col lg="12">
                              <TextField
                                fullWidth
                                label={"Question Note"}
                                variant="outlined"
                                value={props.values.Note}
                                onChange={props.handleChange("Note")}
                                onBlur={props.handleSubmit}
                                style={ChsStyles.Question}
                                className={"form-control-alternative form-control-edit"}
                                error={props.touched.Note && Boolean(props.errors.Note)}
                                helperText={props.touched.Note && props.errors.Note}
                              />
                            </Col>
                          </Row>
                        </Col>
                      </Row>

                      <Row>
                        <Col lg="12" className="text-right">
                          {props.submitCount > 0 && !props.isValid ? (
                            <h6 small style={{ fontWeight: "bold", marginTop: 40, marginBottom: -20, color: "#af1e23" }}>
                              There are errors! Please correct and retry.
                            </h6>
                          ) : null}
                        </Col>
                      </Row>

                      <Row>
                        <Col lg="6" className="text-left"></Col>
                        <Col lg="6" className="text-right"></Col>
                      </Row>
                    </Form>
                  )}
                </Formik>
              </CardBody>
              <CardBody style={{ display: questionData.length > 0 ? "none" : "block" }}>
                <Row>
                  <Col lg="12">
                    <i
                      className="fas fa-plus-circle"
                      style={{ fontSize: 28, color: GlobalStylesColor.mbppThemeBlue, marginRight: 5 }}
                      onClick={(e) => {
                        e.preventDefault();
                        handleNewQuestionClick(e);
                      }}
                    />
                    <span className="sr-only">Add</span>
                  </Col>
                </Row>
              </CardBody>

              {/* survey footer */}
              <CardFooter className="py-4">
                <Row>
                  <Col lg="12" className="text-center">
                    <div aria-label="..." style={{ marginBottom: 5, marginTop: 5, overflowX: "auto" }}>
                      {showPagination && questionCount && questionCount > 0 ? (
                        <Pagination
                          current={questionCounter + 1}
                          total={questionCount}
                          onPageChange={(current) => {
                            setShowPagination(false);
                            setQuestionCounter(current - 1);

                            setShowPagination(true);
                          }}
                        ></Pagination>
                      ) : null}
                    </div>
                  </Col>
                </Row>
                <Row>
                  <Col lg="6" className="text-left">
                    <Button
                      style={ChsStyles.GeneralButton}
                      color="primary"
                      onClick={() => {
                        window.history.back();
                      }}
                    >
                      BACK
                    </Button>

                    {questionCounter + 1 >= questionCount ? null : (
                      <Button
                        style={ChsStyles.GeneralButton}
                        color="primary"
                        onClick={() => {
                          setQuestionCounter(questionCounter + 1);
                        }}
                      >
                        NEXT
                      </Button>
                    )}
                  </Col>
                </Row>
              </CardFooter>
            </Card>
          </div>
        </Row>
      </Container>
    </>
  );
};

export default SurveyCreateTemplate;
