import "react-confirm-alert/src/react-confirm-alert.css"; // Import css
import { Tooltip } from "@material-ui/core";
// core components
import PageHeader from "components/Headers/PageHeader.js";
import { LOGINSTATE, SUPPORT_EMAIL } from "../../../config/config.json";
import { GlobalStylesColor } from "constants/globalStyles";
import Lodash from "lodash";
import React, { useEffect, useState, useRef } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useHistory, useLocation } from "react-router-dom";
import { ChsStyles } from "constants/globalStyles";
import { TextField } from "@material-ui/core";
import { Badge, Card, CardHeader, Container, Row, Table, CardBody, CardFooter, CardTitle, Col, Form, Button } from "reactstrap";
import PublicOutlinedIcon from "@material-ui/icons/PublicOutlined";
import VpnLockOutlinedIcon from "@material-ui/icons/VpnLockOutlined";
import { Formik } from "formik";
import * as Yup from "yup";
import { fetchLocalizationContent, updateLocalizationContent, createLocalizationContent, fetchMasterDataLanguageAll } from "actions/masterData";
import { confirmAlert } from "react-confirm-alert";
import { initialValues_assessmentPage } from "helper";

const LocaleRow = ({ locale, position, editClick, statusClick }) => (
  // LANGUAGECODE |	SAMPLE(APP_NEXT) |	PUBLISHED |	ACTIONS

  <tr>
    <td style={{ whiteSpace: "break-spaces" }}>{locale.languageCode}</td>
    <td style={{ whiteSpace: "break-spaces", color: GlobalStylesColor.mbppThemeBlue }}>{locale?.assessmentPage?.app_next}</td>
    <td>
      {locale.status ? (
        <Badge color="" className="badge-dot">
          <i className="bg-success" />
          YES
        </Badge>
      ) : (
        <Badge color="" className="badge-dot mr-4">
          <i className="bg-warning" />
          NO
        </Badge>
      )}
    </td>
    <td className="text-right">
      <div className="row">
        <Tooltip title="Edit" arrow>
          <div
            className="navbar-toggler"
            style={{ cursor: "pointer" }}
            onClick={(e) => {
              editClick(e, locale, position);
              window.scrollTo(0, 0);
            }}
          >
            <i className="far fa-edit" />
          </div>
        </Tooltip>
        <Tooltip title="Change publish status" arrow>
          <div
            className="navbar-toggler"
            style={{ cursor: "pointer" }}
            onClick={(e) => {
              statusClick(e, locale);
            }}
          >
            {locale.status ? <PublicOutlinedIcon style={{ color: GlobalStylesColor.mbppIconColorLight }} /> : <VpnLockOutlinedIcon style={{ color: GlobalStylesColor.mbppIconColorLight }} />}
          </div>
        </Tooltip>
      </div>
    </td>
  </tr>
);

const reviewSchema = Yup.object({
  languageCode: Yup.string().required("Language is required"),
});

// export function
const SystemLocalization = () => {
  // dispatch and history
  const dispatch = useDispatch();
  const history = useHistory();
  const location = useLocation();
  const formRef = useRef();

  const [chsLanguages, setChsLanguages] = useState([]); // For translations
  const [systemLocaleAll, setSystemLocaleAll] = useState([]);
  const [systemLocaleDetail, setSystemLocaleDetail] = useState({});
  const [showingDetails, setShowingDetails] = useState(false);
  const [refreshCounter, setRefreshCounter] = useState(0);

  useEffect(() => {
    dispatch(fetchLocalizationContent());
    dispatch(fetchMasterDataLanguageAll());
  }, []);

  // get system content
  const systemContent = useSelector((state) => state.masterData.localizationContentAll);
  useEffect(() => {
    if (LOGINSTATE) console.log("systemContent from server: ", systemContent);
    if (systemContent !== undefined && systemContent !== null && Lodash.isArray(systemContent)) {
      setSystemLocaleAll(systemContent);
    }
  }, [systemContent]);

  const languageAll = useSelector((state) => state.masterData.languageAll);
  useEffect(() => {
    if (LOGINSTATE) console.log("languageAll: ", languageAll);
  }, [languageAll, systemLocaleDetail]);

  useEffect(() => {
    if (LOGINSTATE) console.log("systemLocaleAll effect: ", systemLocaleDetail);

    // if (systemLocaleAll && systemLocaleDetailPosition && systemLocaleDetailPosition > -1 && formRef.current && !Lodash.isEmpty(systemLocaleAll[systemLocaleDetailPosition])) {
    if (systemLocaleDetail && systemLocaleDetail.assessmentPage && !Lodash.isEmpty(systemLocaleDetail.assessmentPage) && formRef.current && showingDetails) {
      let formikSystemLocaleDetail = systemLocaleDetail.assessmentPage;
      if (LOGINSTATE) console.log("populate the formik with details: ", formikSystemLocaleDetail);
      formRef.current?.resetForm();
      formRef.current.setFieldValue("languageCode", formikSystemLocaleDetail?.languageCode, false);
      formRef.current.setFieldValue("app_ok", formikSystemLocaleDetail.app_ok, false);
      formRef.current.setFieldValue("app_yes", formikSystemLocaleDetail.app_yes, false);
      formRef.current.setFieldValue("app_no", formikSystemLocaleDetail.app_no, false);
      formRef.current.setFieldValue("app_next", formikSystemLocaleDetail.app_next, false);
      formRef.current.setFieldValue("app_back", formikSystemLocaleDetail.app_back, false);
      formRef.current.setFieldValue("app_success", formikSystemLocaleDetail.app_success, false);
      formRef.current.setFieldValue("app_error", formikSystemLocaleDetail.app_error, false);
      formRef.current.setFieldValue("app_partly_save", formikSystemLocaleDetail.app_partly_save, false);
      formRef.current.setFieldValue("app_complete", formikSystemLocaleDetail.app_complete, false);
      formRef.current.setFieldValue("app_sorry", formikSystemLocaleDetail.app_sorry, false);
      formRef.current.setFieldValue("app_no_active_survey", formikSystemLocaleDetail.app_no_active_survey, false);
      formRef.current.setFieldValue("app_download", formikSystemLocaleDetail.app_download, false);
      formRef.current.setFieldValue("assessment_completion", formikSystemLocaleDetail.assessment_completion, false);
      formRef.current.setFieldValue("chs_short", formikSystemLocaleDetail.chs_short, false);
      formRef.current.setFieldValue("icat_short", formikSystemLocaleDetail.icat_short, false);
      formRef.current.setFieldValue("tie_short", formikSystemLocaleDetail.tie_short, false);
      formRef.current.setFieldValue("app_assessment_switch_title", formikSystemLocaleDetail.app_assessment_switch_title, false);
      formRef.current.setFieldValue("app_assessment_step_01", formikSystemLocaleDetail.app_assessment_step_01, false);
      formRef.current.setFieldValue("app_assessment_step_01_message_01", formikSystemLocaleDetail.app_assessment_step_01_message_01, false);
      formRef.current.setFieldValue("app_assessment_step_02", formikSystemLocaleDetail.app_assessment_step_02, false);
      formRef.current.setFieldValue("app_assessment_step_02_message_02", formikSystemLocaleDetail.app_assessment_step_02_message_02, false);
      formRef.current.setFieldValue("app_assessment_switch_message", formikSystemLocaleDetail.app_assessment_switch_message, false);
      formRef.current.setFieldValue("app_assessment_incomplete_title", formikSystemLocaleDetail.app_assessment_incomplete_title, false);
      formRef.current.setFieldValue("app_assessment_institute_details", formikSystemLocaleDetail.app_assessment_institute_details, false);
      formRef.current.setFieldValue("app_assessment_step_01_title", formikSystemLocaleDetail.app_assessment_step_01_title, false);
      formRef.current.setFieldValue("app_assessment_step_01_message", formikSystemLocaleDetail.app_assessment_step_01_message, false);
      formRef.current.setFieldValue("app_assessment_sentence_ref", formikSystemLocaleDetail.app_assessment_sentence_ref, false);
      formRef.current.setFieldValue("app_assessment_sentence", formikSystemLocaleDetail.app_assessment_sentence, false);
      formRef.current.setFieldValue("app_assessment_organization_details", formikSystemLocaleDetail.app_assessment_organization_details, false);
      formRef.current.setFieldValue("app_assessment_answer_opt1", formikSystemLocaleDetail.app_assessment_answer_opt1, false);
      formRef.current.setFieldValue("app_assessment_answer_opt2", formikSystemLocaleDetail.app_assessment_answer_opt2, false);
      formRef.current.setFieldValue("app_assessment_answer_opt3", formikSystemLocaleDetail.app_assessment_answer_opt3, false);
      formRef.current.setFieldValue("app_assessment_answer_opt4", formikSystemLocaleDetail.app_assessment_answer_opt4, false);
      formRef.current.setFieldValue("app_assessment_answer_opt5", formikSystemLocaleDetail.app_assessment_answer_opt5, false);
      formRef.current.setFieldValue("app_assessment_answer_opt6", formikSystemLocaleDetail.app_assessment_answer_opt6, false);
      formRef.current.setFieldValue("chsat", formikSystemLocaleDetail.chsat, false);
      formRef.current.setFieldValue("icat", formikSystemLocaleDetail.icat, false);
      formRef.current.setFieldValue("tie", formikSystemLocaleDetail.tie, false);
      formRef.current.setFieldValue("answer_remark", formikSystemLocaleDetail.answer_remark, false);
      formRef.current.setFieldValue("chs_app", formikSystemLocaleDetail.chs_app, false);
      formRef.current.setFieldValue("print_preview", formikSystemLocaleDetail.print_preview, false);
      formRef.current.setFieldValue("segment_score", formikSystemLocaleDetail.segment_score, false);
      formRef.current.setFieldValue("total_score", formikSystemLocaleDetail.total_score, false);
      formRef.current.setFieldValue("version", formikSystemLocaleDetail.version, false);
      formRef.current.setFieldValue("font_size", formikSystemLocaleDetail.font_size, false);
      formRef.current.setFieldValue("self_assessment", formikSystemLocaleDetail.self_assessment, false);
      formRef.current.setFieldValue("recommendations_self_assessment", formikSystemLocaleDetail.recommendations_self_assessment, false);
      formRef.current.setFieldValue("pdf_version", formikSystemLocaleDetail.pdf_version, false);
      formRef.current.setFieldValue("print", formikSystemLocaleDetail.print, false);
      formRef.current.setFieldValue("app_tool", formikSystemLocaleDetail.app_tool, false);
      formRef.current.setFieldValue("insitutional_details", formikSystemLocaleDetail.insitutional_details, false);
      formRef.current.setFieldValue("overall", formikSystemLocaleDetail.overall, false);
      formRef.current.setFieldValue("segment", formikSystemLocaleDetail.segment, false);
      formRef.current.setFieldValue("assessment_answer", formikSystemLocaleDetail.assessment_answer, false);
      formRef.current.setFieldValue("no_recommendation", formikSystemLocaleDetail.no_recommendation, false);
      formRef.current.setFieldValue("update", formikSystemLocaleDetail.update, false);
      formRef.current.setFieldValue("answer", formikSystemLocaleDetail.answer, false);
      formRef.current.setFieldValue("no_remark", formikSystemLocaleDetail.no_remark, false);
      formRef.current.setFieldValue("password_invalid", formikSystemLocaleDetail.password_invalid, false);
      formRef.current.setFieldValue("user_not_register", formikSystemLocaleDetail.user_not_register, false);
      formRef.current.setFieldValue("user_unlink", formikSystemLocaleDetail.user_unlink, false);
      formRef.current.setFieldValue("user_deactivate", formikSystemLocaleDetail.user_deactivate, false);
      formRef.current.setFieldValue("user_not_create", formikSystemLocaleDetail.user_not_create, false);
      formRef.current.setFieldValue("organization_deactivate", formikSystemLocaleDetail.organization_deactivate, false);
      formRef.current.setFieldValue("location_deactivate", formikSystemLocaleDetail.location_deactivate, false);
      formRef.current.setFieldValue("login_message", formikSystemLocaleDetail.login_message, false);

      formRef.current.setFieldValue("user_successfully_created", formikSystemLocaleDetail.user_successfully_created, false);
      formRef.current.setFieldValue("session_expired", formikSystemLocaleDetail.session_expired, false);

      let finalLang = Lodash.filter(languageAll, { code: formikSystemLocaleDetail.languageCode });
      setChsLanguages(finalLang);
    } else if (formRef.current && showingDetails) {
      formRef.current?.resetForm();
    }

    setRefreshCounter(refreshCounter + 1);
  }, [systemLocaleDetail]);

  const handleStatusClick = (e, locale) => {
    e.preventDefault();
    if (LOGINSTATE) console.log("locale", locale);

    let valuesTrue = {
      contentId: locale._id,
      status: false,
      assessmentPage: locale.assessmentPage,
    };

    let valuesFalse = {
      contentId: locale._id,
      status: true,
      assessmentPage: locale.assessmentPage,
    };

    locale.status ? dispatch(updateLocalizationContent(valuesTrue, history)) : dispatch(updateLocalizationContent(valuesFalse, history));
  };

  const handleViewClick = (e, locale) => {
    e.preventDefault();
  };

  const handleEditClick = (e, locale, position) => {
    e.preventDefault();
    setSystemLocaleDetail(locale);
    setShowingDetails(true);
  };

  const handleCreateClick = (e, locale, type) => {
    e.preventDefault();
  };

  const handleCreateNewClick = (e, locale, type) => {
    e.preventDefault();

    let finalLang = [];

    if (languageAll !== undefined && languageAll !== null) {
      finalLang = Lodash.reject(languageAll, function (currentLang) {
        return Lodash.find(systemContent, { languageCode: currentLang.code });
      });

      setChsLanguages(finalLang);
    }

    if (Lodash.isEmpty(finalLang)) {
      confirmAlert({
        title: "Error",
        message: "Localizations are exist for all languages.",
        buttons: [
          {
            label: "Ok",
            onClick: () => { },
          },
        ],
        closeOnEscape: false,
        closeOnClickOutside: false,
      });
    } else {
      setSystemLocaleDetail({});
      setShowingDetails(true);
    }
  };

  const updateLocales = (values) => {
    if (LOGINSTATE) console.log("formik values: ", values);

    if (systemLocaleDetail && systemLocaleDetail._id) {
      let params = {
        contentId: systemLocaleDetail._id,
        assessmentPage: values,
      };
      dispatch(updateLocalizationContent(params, history));
    } else {
      for (const locale of systemLocaleAll) {
        if (locale.languageCode === values.languageCode) {
          confirmAlert({ title: "Error", message: "Locale already exists", buttons: [{ label: "Ok", onClick: () => { } }], closeOnEscape: false, closeOnClickOutside: false });
          return;
        }
      }
      let params = {
        languageCode: values.languageCode,
        assessmentPage: values,
      };
      dispatch(createLocalizationContent(params, history));
    }
  };

  // handle change event of the file
  const handleFileChangePrimer = async (e, props) => {
    try {
      // const files = e.target.files;
      const file = e.target.files[0];
      console.log(file);
      if (file) {
        formRef.current.setFieldValue("Primer", file, false);
      }
    } catch (err) {
      if (LOGINSTATE) console.log("file error", err);
    }
  };

  // handle change event of the file
  const handleFileChangeLogo = async (e, props) => {
    try {
      // const files = e.target.files;
      const file = e.target.files[0];
      if (LOGINSTATE) console.log(file);
      if (file) {
        formRef.current.setFieldValue("Logo", file, false);
      }
    } catch (err) {
      if (LOGINSTATE) console.log("file error", err);
    }
  };

  return (
    <>
      <PageHeader />

      {/* Page content */}
      <Container className="mt--7" fluid>
        {/* locale tags */}

        <Row className="mt-5" style={{ display: showingDetails ? "block" : "none" }}>
          <div className="col">
            <Card className="shadow">
              <CardHeader className="border-0">
                <div className="row">
                  <div className="mb-xl-0 col-11">
                    <h3 className="mb-0">Localization</h3>
                    <h6 className="mb-0">Standard localization tags</h6>
                  </div>
                  <div className="mb-xl-0"></div>
                </div>
              </CardHeader>

              <CardBody>
                <Formik
                  initialValues={initialValues_assessmentPage}
                  validationSchema={reviewSchema}
                  onSubmit={(values, actions) => {
                    console.log("values", values);
                    updateLocales(values);
                  }}
                  innerRef={formRef}
                >
                  {(props) => (
                    <Form role="form" style={{ marginTop: -25 }} onSubmit={props.handleSubmit} id={refreshCounter}>
                      <Row>
                        <Col lg="4">
                          <TextField
                            select
                            fullWidth
                            label={"Language*"}
                            variant="outlined"
                            value={props.values.languageCode}
                            onChange={props.handleChange("languageCode")}
                            onBlur={props.handleChange("languageCode")}
                            style={ChsStyles.Question}
                            SelectProps={{ native: true }}
                            className={"form-control-alternative form-control-edit"}
                            error={props.touched.languageCode && Boolean(props.errors.languageCode)}
                            helperText={props.touched.languageCode && props.errors.languageCode}
                          // disabled={systemLocaleDetail && systemLocaleDetail?.languageCode ? true : false}
                          >
                            <option key="" value=""></option>
                            {chsLanguages.map((key, i) => {
                              {
                                if (LOGINSTATE) console.log("key code", key.code);
                              }
                              {
                                if (LOGINSTATE) console.log("props.values.languageCode", props.values.languageCode);
                              }
                              return (
                                <option key={i} value={key.code}>
                                  {key.name}
                                </option>
                              );
                            })}
                          </TextField>
                        </Col>
                      </Row>

                      <hr className="my-2" />
                      <h6 className="heading-small mb-4" style={ChsStyles.SubTopic}>
                        Common statements
                      </h6>

                      <Row>
                        <Col lg="4">
                          <TextField
                            fullWidth
                            label={"app_ok*"}
                            variant="outlined"
                            value={props.values.app_ok}
                            onChange={props.handleChange("app_ok")}
                            onBlur={props.handleChange("app_ok")}
                            style={ChsStyles.Question}
                            className={"form-control-alternative form-control-edit"}
                            error={props.touched.app_ok && Boolean(props.errors.app_ok)}
                            helperText={props.touched.app_ok && props.errors.app_ok}
                          />
                        </Col>

                        <Col lg="4">
                          <TextField
                            fullWidth
                            label={"app_yes*"}
                            variant="outlined"
                            value={props.values.app_yes}
                            onChange={props.handleChange("app_yes")}
                            onBlur={props.handleChange("app_yes")}
                            style={ChsStyles.Question}
                            className={"form-control-alternative form-control-edit"}
                            error={props.touched.app_yes && Boolean(props.errors.app_yes)}
                            helperText={props.touched.app_yes && props.errors.app_yes}
                          />
                        </Col>

                        <Col lg="4">
                          <TextField
                            fullWidth
                            label={"app_no*"}
                            variant="outlined"
                            value={props.values.app_no}
                            onChange={props.handleChange("app_no")}
                            onBlur={props.handleChange("app_no")}
                            style={ChsStyles.Question}
                            className={"form-control-alternative form-control-edit"}
                            error={props.touched.app_no && Boolean(props.errors.app_no)}
                            helperText={props.touched.app_no && props.errors.app_no}
                          />
                        </Col>
                      </Row>

                      <Row>
                        <Col lg="4">
                          <TextField
                            fullWidth
                            label={"app_next*"}
                            variant="outlined"
                            value={props.values.app_next}
                            onChange={props.handleChange("app_next")}
                            onBlur={props.handleChange("app_next")}
                            style={ChsStyles.Question}
                            className={"form-control-alternative form-control-edit"}
                            error={props.touched.app_next && Boolean(props.errors.app_next)}
                            helperText={props.touched.app_next && props.errors.app_next}
                          />
                        </Col>

                        <Col lg="4">
                          <TextField
                            fullWidth
                            label={"app_back*"}
                            variant="outlined"
                            value={props.values.app_back}
                            onChange={props.handleChange("app_back")}
                            onBlur={props.handleChange("app_back")}
                            style={ChsStyles.Question}
                            className={"form-control-alternative form-control-edit"}
                            error={props.touched.app_back && Boolean(props.errors.app_back)}
                            helperText={props.touched.app_back && props.errors.app_back}
                          />
                        </Col>

                        <Col lg="4">
                          <TextField
                            fullWidth
                            label={"app_partly_save*"}
                            variant="outlined"
                            value={props.values.app_partly_save}
                            onChange={props.handleChange("app_partly_save")}
                            onBlur={props.handleChange("app_partly_save")}
                            style={ChsStyles.Question}
                            className={"form-control-alternative form-control-edit"}
                            error={props.touched.app_partly_save && Boolean(props.errors.app_partly_save)}
                            helperText={props.touched.app_partly_save && props.errors.app_partly_save}
                          />
                        </Col>
                      </Row>

                      <Row>
                        <Col lg="4">
                          <TextField
                            fullWidth
                            label={"app_complete*"}
                            variant="outlined"
                            value={props.values.app_complete}
                            onChange={props.handleChange("app_complete")}
                            onBlur={props.handleChange("app_complete")}
                            style={ChsStyles.Question}
                            className={"form-control-alternative form-control-edit"}
                            error={props.touched.app_complete && Boolean(props.errors.app_complete)}
                            helperText={props.touched.app_complete && props.errors.app_complete}
                          />
                        </Col>

                        <Col lg="4">
                          <TextField
                            fullWidth
                            label={"app_success*"}
                            variant="outlined"
                            value={props.values.app_success}
                            onChange={props.handleChange("app_success")}
                            onBlur={props.handleChange("app_success")}
                            style={ChsStyles.Question}
                            className={"form-control-alternative form-control-edit"}
                            error={props.touched.app_success && Boolean(props.errors.app_success)}
                            helperText={props.touched.app_success && props.errors.app_success}
                          />
                        </Col>

                        <Col lg="4">
                          <TextField
                            fullWidth
                            label={"app_error*"}
                            variant="outlined"
                            value={props.values.app_error}
                            onChange={props.handleChange("app_error")}
                            onBlur={props.handleChange("app_error")}
                            style={ChsStyles.Question}
                            className={"form-control-alternative form-control-edit"}
                            error={props.touched.app_error && Boolean(props.errors.app_error)}
                            helperText={props.touched.app_error && props.errors.app_error}
                          />
                        </Col>
                      </Row>

                      <Row>
                        <Col lg="4">
                          <TextField
                            fullWidth
                            label={"print_preview*"}
                            variant="outlined"
                            value={props.values.print_preview}
                            onChange={props.handleChange("print_preview")}
                            onBlur={props.handleChange("print_preview")}
                            style={ChsStyles.Question}
                            className={"form-control-alternative form-control-edit"}
                            error={props.touched.print_preview && Boolean(props.errors.print_preview)}
                            helperText={props.touched.print_preview && props.errors.print_preview}
                          />
                        </Col>

                        <Col lg="4">
                          <TextField
                            fullWidth
                            label={"Segment score*"}
                            variant="outlined"
                            value={props.values.segment_score}
                            onChange={props.handleChange("segment_score")}
                            onBlur={props.handleChange("segment_score")}
                            style={ChsStyles.Question}
                            className={"form-control-alternative form-control-edit"}
                            error={props.touched.segment_score && Boolean(props.errors.segment_score)}
                            helperText={props.touched.segment_score && props.errors.segment_score}
                          />
                        </Col>

                        <Col lg="4">
                          <TextField
                            fullWidth
                            label={"Total score*"}
                            variant="outlined"
                            value={props.values.total_score}
                            onChange={props.handleChange("total_score")}
                            onBlur={props.handleChange("total_score")}
                            style={ChsStyles.Question}
                            className={"form-control-alternative form-control-edit"}
                            error={props.touched.total_score && Boolean(props.errors.total_score)}
                            helperText={props.touched.total_score && props.errors.total_score}
                          />
                        </Col>
                      </Row>

                      <Row>
                        <Col lg="4">
                          <TextField
                            fullWidth
                            label={"Overall recommendation*"}
                            variant="outlined"
                            value={props.values.overall}
                            onChange={props.handleChange("overall")}
                            onBlur={props.handleChange("overall")}
                            style={ChsStyles.Question}
                            className={"form-control-alternative form-control-edit"}
                            error={props.touched.overall && Boolean(props.errors.overall)}
                            helperText={props.touched.overall && props.errors.overall}
                          />
                        </Col>

                        <Col lg="4">
                          <TextField
                            fullWidth
                            label={"Segment recommendation*"}
                            variant="outlined"
                            value={props.values.segment}
                            onChange={props.handleChange("segment")}
                            onBlur={props.handleChange("segment")}
                            style={ChsStyles.Question}
                            className={"form-control-alternative form-control-edit"}
                            error={props.touched.segment && Boolean(props.errors.segment)}
                            helperText={props.touched.segment && props.errors.segment}
                          />
                        </Col>

                        <Col lg="4">
                          <TextField
                            fullWidth
                            label={"Insituitional details*"}
                            variant="outlined"
                            value={props.values.insitutional_details}
                            onChange={props.handleChange("insitutional_details")}
                            onBlur={props.handleChange("insitutional_details")}
                            style={ChsStyles.Question}
                            className={"form-control-alternative form-control-edit"}
                            error={props.touched.insitutional_details && Boolean(props.errors.insitutional_details)}
                            helperText={props.touched.insitutional_details && props.errors.insitutional_details}
                          />
                        </Col>
                      </Row>

                      <Row>
                        <Col lg="4">
                          <TextField
                            fullWidth
                            label={"Assessment answers*"}
                            variant="outlined"
                            value={props.values.assessment_answer}
                            onChange={props.handleChange("assessment_answer")}
                            onBlur={props.handleChange("assessment_answer")}
                            style={ChsStyles.Question}
                            className={"form-control-alternative form-control-edit"}
                            error={props.touched.assessment_answer && Boolean(props.errors.assessment_answer)}
                            helperText={props.touched.assessment_answer && props.errors.assessment_answer}
                          />
                        </Col>

                        <Col lg="4">
                          <TextField
                            fullWidth
                            label={"No recommendation message*"}
                            variant="outlined"
                            value={props.values.no_recommendation}
                            onChange={props.handleChange("no_recommendation")}
                            onBlur={props.handleChange("no_recommendation")}
                            style={ChsStyles.Question}
                            className={"form-control-alternative form-control-edit"}
                            error={props.touched.no_recommendation && Boolean(props.errors.no_recommendation)}
                            helperText={props.touched.no_recommendation && props.errors.no_recommendation}
                          />
                        </Col>

                        <Col lg="4">
                          <TextField
                            fullWidth
                            label={"Update*"}
                            variant="outlined"
                            value={props.values.update}
                            onChange={props.handleChange("update")}
                            onBlur={props.handleChange("update")}
                            style={ChsStyles.Question}
                            className={"form-control-alternative form-control-edit"}
                            error={props.touched.update && Boolean(props.errors.update)}
                            helperText={props.touched.update && props.errors.update}
                          />
                        </Col>
                      </Row>

                      <Row>
                        <Col lg="4">
                          <TextField
                            fullWidth
                            label={"app_sorry*"}
                            variant="outlined"
                            value={props.values.app_sorry}
                            onChange={props.handleChange("app_sorry")}
                            onBlur={props.handleChange("app_sorry")}
                            style={ChsStyles.Question}
                            className={"form-control-alternative form-control-edit"}
                            error={props.touched.app_sorry && Boolean(props.errors.app_sorry)}
                            helperText={props.touched.app_sorry && props.errors.app_sorry}
                          />
                        </Col>

                        <Col lg="4">
                          <TextField
                            fullWidth
                            label={"app_no_active_assessment*"}
                            variant="outlined"
                            value={props.values.app_no_active_survey}
                            onChange={props.handleChange("app_no_active_survey")}
                            onBlur={props.handleChange("app_no_active_survey")}
                            style={ChsStyles.Question}
                            className={"form-control-alternative form-control-edit"}
                            error={props.touched.app_no_active_survey && Boolean(props.errors.app_no_active_survey)}
                            helperText={props.touched.app_no_active_survey && props.errors.app_no_active_survey}
                          />
                        </Col>

                        {/* <Col lg="4">
                          <TextField
                            fullWidth
                            label={"app_download*"}
                            variant="outlined"
                            value={props.values.app_download}
                            onChange={props.handleChange("app_download")}
                            onBlur={props.handleChange("app_download")}
                            style={ChsStyles.Question}
                            className={"form-control-alternative form-control-edit"}
                            error={props.touched.app_download && Boolean(props.errors.app_download)}
                            helperText={props.touched.app_download && props.errors.app_download}
                          />
                        </Col> */}
                        <Col lg="4">
                          <TextField
                            fullWidth
                            label={"assessment_completion*"}
                            variant="outlined"
                            value={props.values.assessment_completion}
                            onChange={props.handleChange("assessment_completion")}
                            onBlur={props.handleChange("assessment_completion")}
                            style={ChsStyles.Question}
                            className={"form-control-alternative form-control-edit"}
                            error={props.touched.assessment_completion && Boolean(props.errors.assessment_completion)}
                            helperText={props.touched.assessment_completion && props.errors.assessment_completion}
                          />
                        </Col>
                      </Row>

                      <Row>
                        <Col lg="4">
                          <TextField
                            fullWidth
                            label={"chsat_short_form*"}
                            variant="outlined"
                            value={props.values.chs_short}
                            onChange={props.handleChange("chs_short")}
                            onBlur={props.handleChange("chs_short")}
                            style={ChsStyles.Question}
                            className={"form-control-alternative form-control-edit"}
                            error={props.touched.chs_short && Boolean(props.errors.chs_short)}
                            helperText={props.touched.chs_short && props.errors.chs_short}
                          />
                        </Col>

                        <Col lg="4">
                          <TextField
                            fullWidth
                            label={"icat_short_form*"}
                            variant="outlined"
                            value={props.values.icat_short}
                            onChange={props.handleChange("icat_short")}
                            onBlur={props.handleChange("icat_short")}
                            style={ChsStyles.Question}
                            className={"form-control-alternative form-control-edit"}
                            error={props.touched.icat_short && Boolean(props.errors.icat_short)}
                            helperText={props.touched.icat_short && props.errors.icat_short}
                          />
                        </Col>

                        <Col lg="4">
                          <TextField
                            fullWidth
                            label={"tie_short_form*"}
                            variant="outlined"
                            value={props.values.tie_short}
                            onChange={props.handleChange("tie_short")}
                            onBlur={props.handleChange("tie_short")}
                            style={ChsStyles.Question}
                            className={"form-control-alternative form-control-edit"}
                            error={props.touched.tie_short && Boolean(props.errors.tie_short)}
                            helperText={props.touched.tie_short && props.errors.tie_short}
                          />
                        </Col>
                      </Row>

                      <Row>
                        <Col lg="4">
                          <TextField
                            fullWidth
                            label={"answer_remark*"}
                            variant="outlined"
                            value={props.values.answer_remark}
                            onChange={props.handleChange("answer_remark")}
                            onBlur={props.handleChange("answer_remark")}
                            style={ChsStyles.Question}
                            className={"form-control-alternative form-control-edit"}
                            error={props.touched.answer_remark && Boolean(props.errors.answer_remark)}
                            helperText={props.touched.answer_remark && props.errors.answer_remark}
                          />
                        </Col>

                        <Col lg="4">
                          <TextField
                            fullWidth
                            label={"version of the assessment*"}
                            variant="outlined"
                            value={props.values.version}
                            onChange={props.handleChange("version")}
                            onBlur={props.handleChange("version")}
                            style={ChsStyles.Question}
                            className={"form-control-alternative form-control-edit"}
                            error={props.touched.version && Boolean(props.errors.version)}
                            helperText={props.touched.version && props.errors.version}
                          />
                        </Col>

                        <Col lg="4">
                          <TextField
                            fullWidth
                            label={"default_font_size*"}
                            variant="outlined"
                            value={props.values.font_size}
                            onChange={props.handleChange("font_size")}
                            onBlur={props.handleChange("font_size")}
                            style={ChsStyles.Question}
                            className={"form-control-alternative form-control-edit"}
                            error={props.touched.font_size && Boolean(props.errors.font_size)}
                            helperText={props.touched.font_size && props.errors.font_size}
                          />
                        </Col>
                      </Row>

                      <Row>
                        <Col lg="4">
                          <TextField
                            fullWidth
                            label={"self_assessment*"}
                            variant="outlined"
                            value={props.values.self_assessment}
                            onChange={props.handleChange("self_assessment")}
                            onBlur={props.handleChange("self_assessment")}
                            style={ChsStyles.Question}
                            className={"form-control-alternative form-control-edit"}
                            error={props.touched.self_assessment && Boolean(props.errors.self_assessment)}
                            helperText={props.touched.self_assessment && props.errors.self_assessment}
                          />
                        </Col>

                        <Col lg="4">
                          <TextField
                            fullWidth
                            label={"recommendations_for_the_self_assessment*"}
                            variant="outlined"
                            value={props.values.recommendations_self_assessment}
                            onChange={props.handleChange("recommendations_self_assessment")}
                            onBlur={props.handleChange("recommendations_self_assessment")}
                            style={ChsStyles.Question}
                            className={"form-control-alternative form-control-edit"}
                            error={props.touched.recommendations_self_assessment && Boolean(props.errors.recommendations_self_assessment)}
                            helperText={props.touched.recommendations_self_assessment && props.errors.recommendations_self_assessment}
                          />
                        </Col>

                        <Col lg="4">
                          <TextField
                            fullWidth
                            label={"pdf_version*"}
                            variant="outlined"
                            value={props.values.pdf_version}
                            onChange={props.handleChange("pdf_version")}
                            onBlur={props.handleChange("pdf_version")}
                            style={ChsStyles.Question}
                            className={"form-control-alternative form-control-edit"}
                            error={props.touched.pdf_version && Boolean(props.errors.pdf_version)}
                            helperText={props.touched.pdf_version && props.errors.pdf_version}
                          />
                        </Col>
                      </Row>

                      <Row>
                        <Col lg="4">
                          <TextField
                            fullWidth
                            label={"print_button*"}
                            variant="outlined"
                            value={props.values.print}
                            onChange={props.handleChange("print")}
                            onBlur={props.handleChange("print")}
                            style={ChsStyles.Question}
                            className={"form-control-alternative form-control-edit"}
                            error={props.touched.print && Boolean(props.errors.print)}
                            helperText={props.touched.print && props.errors.print}
                          />
                        </Col>

                        <Col lg="4">
                          <TextField
                            fullWidth
                            label={"app_tool*"}
                            variant="outlined"
                            value={props.values.app_tool}
                            onChange={props.handleChange("app_tool")}
                            onBlur={props.handleChange("app_tool")}
                            style={ChsStyles.Question}
                            className={"form-control-alternative form-control-edit"}
                            error={props.touched.app_tool && Boolean(props.errors.app_tool)}
                            helperText={props.touched.app_tool && props.errors.app_tool}
                          />
                        </Col>

                        <Col lg="4">
                          <TextField
                            fullWidth
                            label={"answer*"}
                            variant="outlined"
                            value={props.values.answer}
                            onChange={props.handleChange("answer")}
                            onBlur={props.handleChange("answer")}
                            style={ChsStyles.Question}
                            className={"form-control-alternative form-control-edit"}
                            error={props.touched.answer && Boolean(props.errors.answer)}
                            helperText={props.touched.answer && props.errors.answer}
                          />
                        </Col>
                      </Row>

                      <Row>
                        <Col lg="4">
                          <TextField
                            fullWidth
                            label={"no_remark*"}
                            variant="outlined"
                            value={props.values.no_remark}
                            onChange={props.handleChange("no_remark")}
                            onBlur={props.handleChange("no_remark")}
                            style={ChsStyles.Question}
                            className={"form-control-alternative form-control-edit"}
                            error={props.touched.no_remark && Boolean(props.errors.no_remark)}
                            helperText={props.touched.no_remark && props.errors.no_remark}
                          />
                        </Col>
                      </Row>

                      <hr className="my-2" />
                      <h6 className="heading-small mb-4" style={ChsStyles.SubTopic}>
                        Answer options
                      </h6>

                      <Row>
                        <Col lg="6">
                          <TextField
                            fullWidth
                            label={"app_assessment_answer_opt1*"}
                            variant="outlined"
                            value={props.values.app_assessment_answer_opt1}
                            onChange={props.handleChange("app_assessment_answer_opt1")}
                            onBlur={props.handleChange("app_assessment_answer_opt1")}
                            style={ChsStyles.Question}
                            className={"form-control-alternative form-control-edit"}
                            error={props.touched.app_assessment_answer_opt1 && Boolean(props.errors.app_assessment_answer_opt1)}
                            helperText={props.touched.app_assessment_answer_opt1 && props.errors.app_assessment_answer_opt1}
                          />
                        </Col>

                        <Col lg="6">
                          <TextField
                            fullWidth
                            label={"app_assessment_answer_opt2*"}
                            variant="outlined"
                            value={props.values.app_assessment_answer_opt2}
                            onChange={props.handleChange("app_assessment_answer_opt2")}
                            onBlur={props.handleChange("app_assessment_answer_opt2")}
                            style={ChsStyles.Question}
                            className={"form-control-alternative form-control-edit"}
                            error={props.touched.app_assessment_answer_opt2 && Boolean(props.errors.app_assessment_answer_opt2)}
                            helperText={props.touched.app_assessment_answer_opt2 && props.errors.app_assessment_answer_opt2}
                          />
                        </Col>
                      </Row>

                      <Row>
                        <Col lg="6">
                          <TextField
                            fullWidth
                            label={"app_assessment_answer_opt3*"}
                            variant="outlined"
                            value={props.values.app_assessment_answer_opt3}
                            onChange={props.handleChange("app_assessment_answer_opt3")}
                            onBlur={props.handleChange("app_assessment_answer_opt3")}
                            style={ChsStyles.Question}
                            className={"form-control-alternative form-control-edit"}
                            error={props.touched.app_assessment_answer_opt3 && Boolean(props.errors.app_assessment_answer_opt3)}
                            helperText={props.touched.app_assessment_answer_opt3 && props.errors.app_assessment_answer_opt3}
                          />
                        </Col>

                        <Col lg="6">
                          <TextField
                            fullWidth
                            label={"app_assessment_answer_opt4*"}
                            variant="outlined"
                            value={props.values.app_assessment_answer_opt4}
                            onChange={props.handleChange("app_assessment_answer_opt4")}
                            onBlur={props.handleChange("app_assessment_answer_opt4")}
                            style={ChsStyles.Question}
                            className={"form-control-alternative form-control-edit"}
                            error={props.touched.app_assessment_answer_opt4 && Boolean(props.errors.app_assessment_answer_opt4)}
                            helperText={props.touched.app_assessment_answer_opt4 && props.errors.app_assessment_answer_opt4}
                          />
                        </Col>
                      </Row>

                      <Row>
                        <Col lg="6">
                          <TextField
                            fullWidth
                            label={"app_assessment_answer_opt5*"}
                            variant="outlined"
                            value={props.values.app_assessment_answer_opt5}
                            onChange={props.handleChange("app_assessment_answer_opt5")}
                            onBlur={props.handleChange("app_assessment_answer_opt5")}
                            style={ChsStyles.Question}
                            className={"form-control-alternative form-control-edit"}
                            error={props.touched.app_assessment_answer_opt5 && Boolean(props.errors.app_assessment_answer_opt5)}
                            helperText={props.touched.app_assessment_answer_opt5 && props.errors.app_assessment_answer_opt5}
                          />
                        </Col>

                        <Col lg="6">
                          <TextField
                            fullWidth
                            label={"app_assessment_answer_opt6*"}
                            variant="outlined"
                            value={props.values.app_assessment_answer_opt6}
                            onChange={props.handleChange("app_assessment_answer_opt6")}
                            onBlur={props.handleChange("app_assessment_answer_opt6")}
                            style={ChsStyles.Question}
                            className={"form-control-alternative form-control-edit"}
                            error={props.touched.app_assessment_answer_opt6 && Boolean(props.errors.app_assessment_answer_opt6)}
                            helperText={props.touched.app_assessment_answer_opt6 && props.errors.app_assessment_answer_opt6}
                          />
                        </Col>
                      </Row>

                      <hr className="my-2" />
                      <h6 className="heading-small mb-4" style={ChsStyles.SubTopic}>
                        Assessment statements
                      </h6>

                      <Row>
                        <Col lg="12">
                          <TextField
                            fullWidth
                            label={"app_assessment_switch_title*"}
                            variant="outlined"
                            value={props.values.app_assessment_switch_title}
                            onChange={props.handleChange("app_assessment_switch_title")}
                            onBlur={props.handleChange("app_assessment_switch_title")}
                            style={ChsStyles.Question}
                            className={"form-control-alternative form-control-edit"}
                            error={props.touched.app_assessment_switch_title && Boolean(props.errors.app_assessment_switch_title)}
                            helperText={props.touched.app_assessment_switch_title && props.errors.app_assessment_switch_title}
                          />
                        </Col>
                      </Row>

                      <Row>
                        <Col lg="12">
                          <TextField
                            fullWidth
                            label={"app_assessment_switch_message*"}
                            variant="outlined"
                            value={props.values.app_assessment_switch_message}
                            onChange={props.handleChange("app_assessment_switch_message")}
                            onBlur={props.handleChange("app_assessment_switch_message")}
                            style={ChsStyles.Question}
                            className={"form-control-alternative form-control-edit"}
                            error={props.touched.app_assessment_switch_message && Boolean(props.errors.app_assessment_switch_message)}
                            helperText={props.touched.app_assessment_switch_message && props.errors.app_assessment_switch_message}
                          />
                        </Col>
                      </Row>

                      <Row>
                        <Col lg="12">
                          <TextField
                            fullWidth
                            label={"app_assessment_incomplete_title*"}
                            variant="outlined"
                            value={props.values.app_assessment_incomplete_title}
                            onChange={props.handleChange("app_assessment_incomplete_title")}
                            onBlur={props.handleChange("app_assessment_incomplete_title")}
                            style={ChsStyles.Question}
                            className={"form-control-alternative form-control-edit"}
                            error={props.touched.app_assessment_incomplete_title && Boolean(props.errors.app_assessment_incomplete_title)}
                            helperText={props.touched.app_assessment_incomplete_title && props.errors.app_assessment_incomplete_title}
                          />
                        </Col>
                      </Row>

                      <Row>
                        <Col lg="12">
                          <TextField
                            fullWidth
                            label={"app_assessment_institute_details*"}
                            variant="outlined"
                            value={props.values.app_assessment_institute_details}
                            onChange={props.handleChange("app_assessment_institute_details")}
                            onBlur={props.handleChange("app_assessment_institute_details")}
                            style={ChsStyles.Question}
                            className={"form-control-alternative form-control-edit"}
                            error={props.touched.app_assessment_institute_details && Boolean(props.errors.app_assessment_institute_details)}
                            helperText={props.touched.app_assessment_institute_details && props.errors.app_assessment_institute_details}
                          />
                        </Col>
                      </Row>

                      <Row>
                        <Col lg="12">
                          <TextField
                            fullWidth
                            label={"app_assessment_step_01_title*"}
                            variant="outlined"
                            value={props.values.app_assessment_step_01_title}
                            onChange={props.handleChange("app_assessment_step_01_title")}
                            onBlur={props.handleChange("app_assessment_step_01_title")}
                            style={ChsStyles.Question}
                            className={"form-control-alternative form-control-edit"}
                            error={props.touched.app_assessment_step_01_title && Boolean(props.errors.app_assessment_step_01_title)}
                            helperText={props.touched.app_assessment_step_01_title && props.errors.app_assessment_step_01_title}
                          />
                        </Col>
                      </Row>

                      <Row>
                        <Col lg="12">
                          <TextField
                            fullWidth
                            label={"app_assessment_step_01_message*"}
                            variant="outlined"
                            value={props.values.app_assessment_step_01_message}
                            onChange={props.handleChange("app_assessment_step_01_message")}
                            onBlur={props.handleChange("app_assessment_step_01_message")}
                            tyle={ChsStyles.Question}
                            className={"form-control-alternative form-control-edit"}
                            error={props.touched.app_assessment_step_01_message && Boolean(props.errors.app_assessment_step_01_message)}
                            helperText={props.touched.app_assessment_step_01_message && props.errors.app_assessment_step_01_message}
                          />
                        </Col>
                      </Row>

                      <Row>
                        <Col lg="12">
                          <TextField
                            fullWidth
                            label={"app_assessment_sentence*"}
                            variant="outlined"
                            value={props.values.app_assessment_sentence}
                            onChange={props.handleChange("app_assessment_sentence")}
                            onBlur={props.handleChange("app_assessment_sentence")}
                            style={ChsStyles.Question}
                            className={"form-control-alternative form-control-edit"}
                            error={props.touched.app_assessment_sentence && Boolean(props.errors.app_assessment_sentence)}
                            helperText={props.touched.app_assessment_sentence && props.errors.app_assessment_sentence}
                          />
                        </Col>
                      </Row>

                      <Row>
                        <Col lg="12">
                          <TextField
                            fullWidth
                            label={"app_assessment_sentence_hyperlink*"}
                            variant="outlined"
                            value={props.values.app_assessment_sentence_ref}
                            onChange={props.handleChange("app_assessment_sentence_ref")}
                            onBlur={props.handleChange("app_assessment_sentence_ref")}
                            style={ChsStyles.Question}
                            className={"form-control-alternative form-control-edit"}
                            error={props.touched.app_assessment_sentence_ref && Boolean(props.errors.app_assessment_sentence_ref)}
                            helperText={props.touched.app_assessment_sentence_ref && props.errors.app_assessment_sentence_ref}
                          />
                        </Col>
                      </Row>

                      <Row>
                        <Col lg="12">
                          <TextField
                            fullWidth
                            label={"app_assessment_organization_details*"}
                            variant="outlined"
                            value={props.values.app_assessment_organization_details}
                            onChange={props.handleChange("app_assessment_organization_details")}
                            onBlur={props.handleChange("app_assessment_organization_details")}
                            style={ChsStyles.Question}
                            className={"form-control-alternative form-control-edit"}
                            error={props.touched.app_assessment_organization_details && Boolean(props.errors.app_assessment_organization_details)}
                            helperText={props.touched.app_assessment_organization_details && props.errors.app_assessment_organization_details}
                          />
                        </Col>
                      </Row>

                      <Row>
                        <Col lg="12">
                          <TextField
                            fullWidth
                            label={"Assessment step 01*"}
                            variant="outlined"
                            value={props.values.app_assessment_step_01}
                            onChange={props.handleChange("app_assessment_step_01")}
                            onBlur={props.handleChange("app_assessment_step_01")}
                            style={ChsStyles.Question}
                            className={"form-control-alternative form-control-edit"}
                            error={props.touched.app_assessment_step_01 && Boolean(props.errors.app_assessment_step_01)}
                            helperText={props.touched.app_assessment_step_01 && props.errors.app_assessment_step_01}
                          />
                        </Col>
                      </Row>

                      <Row>
                        <Col lg="12">
                          <TextField
                            fullWidth
                            label={"Assessment step 01 subtitle*"}
                            variant="outlined"
                            value={props.values.app_assessment_step_01_message_01}
                            onChange={props.handleChange("app_assessment_step_01_message_01")}
                            onBlur={props.handleChange("app_assessment_step_01_message_01")}
                            style={ChsStyles.Question}
                            className={"form-control-alternative form-control-edit"}
                            error={props.touched.app_assessment_step_01_message_01 && Boolean(props.errors.app_assessment_step_01_message_01)}
                            helperText={props.touched.app_assessment_step_01_message_01 && props.errors.app_assessment_step_01_message_01}
                          />
                        </Col>
                      </Row>

                      <Row>
                        <Col lg="12">
                          <TextField
                            fullWidth
                            label={"Assessment step 02*"}
                            variant="outlined"
                            value={props.values.app_assessment_step_02}
                            onChange={props.handleChange("app_assessment_step_02")}
                            onBlur={props.handleChange("app_assessment_step_02")}
                            style={ChsStyles.Question}
                            className={"form-control-alternative form-control-edit"}
                            error={props.touched.app_assessment_step_02 && Boolean(props.errors.app_assessment_step_02)}
                            helperText={props.touched.app_assessment_step_02 && props.errors.app_assessment_step_02}
                          />
                        </Col>
                      </Row>

                      <Row>
                        <Col lg="12">
                          <TextField
                            fullWidth
                            label={"Assessment step 02 subtitle*"}
                            variant="outlined"
                            value={props.values.app_assessment_step_02_message_02}
                            onChange={props.handleChange("app_assessment_step_02_message_02")}
                            onBlur={props.handleChange("app_assessment_step_02_message_02")}
                            style={ChsStyles.Question}
                            className={"form-control-alternative form-control-edit"}
                            error={props.touched.app_assessment_step_02_message_02 && Boolean(props.errors.app_assessment_step_02_message_02)}
                            helperText={props.touched.app_assessment_step_02_message_02 && props.errors.app_assessment_step_02_message_02}
                          />
                        </Col>
                      </Row>

                      <Row>
                        <Col lg="12" className="">
                          {props.submitCount > 0 && !props.isValid ? <h5 style={{ marginTop: 20, marginBottom: 0, color: GlobalStylesColor.mbppThemeBlue }}>The translation schema contain errors.</h5> : null}
                        </Col>
                      </Row>

                      <Row>
                        <Col lg="6">
                          <TextField
                            fullWidth
                            label={"Core Humanitarian Standards Assessment Tool*"}
                            variant="outlined"
                            value={props.values.chsat}
                            onChange={props.handleChange("chsat")}
                            onBlur={props.handleChange("chsat")}
                            style={ChsStyles.Question}
                            className={"form-control-alternative form-control-edit"}
                            error={props.touched.chsat && Boolean(props.errors.chsat)}
                            helperText={props.touched.chsat && props.errors.chsat}
                          />
                        </Col>

                        <Col lg="6">
                          <TextField
                            fullWidth
                            label={"Institutional Capacity Assessment Tool*"}
                            variant="outlined"
                            value={props.values.icat}
                            onChange={props.handleChange("icat")}
                            onBlur={props.handleChange("icat")}
                            style={ChsStyles.Question}
                            className={"form-control-alternative form-control-edit"}
                            error={props.touched.icat && Boolean(props.errors.icat)}
                            helperText={props.touched.icat && props.errors.icat}
                          />
                        </Col>
                      </Row>

                      <Row>
                        <Col lg="6">
                          <TextField
                            fullWidth
                            label={"Tool for Institutional Engagement*"}
                            variant="outlined"
                            value={props.values.tie}
                            onChange={props.handleChange("tie")}
                            onBlur={props.handleChange("tie")}
                            style={ChsStyles.Question}
                            className={"form-control-alternative form-control-edit"}
                            error={props.touched.tie && Boolean(props.errors.tie)}
                            helperText={props.touched.tie && props.errors.tie}
                          />
                        </Col>

                        <Col lg="6">
                          <TextField
                            fullWidth
                            label={"APP Self Assessment Tool*"}
                            variant="outlined"
                            value={props.values.chs_app}
                            onChange={props.handleChange("chs_app")}
                            onBlur={props.handleChange("chs_app")}
                            style={ChsStyles.Question}
                            className={"form-control-alternative form-control-edit"}
                            error={props.touched.chs_app && Boolean(props.errors.chs_app)}
                            helperText={props.touched.chs_app && props.errors.chs_app}
                          />
                        </Col>
                      </Row>

                      <hr className="my-2" />
                      <h6 className="heading-small mb-4" style={ChsStyles.SubTopic}>
                        Login statements
                      </h6>

                      <Row>
                        <Col lg="6">
                          <TextField
                            fullWidth
                            label={"User not registered*"}
                            variant="outlined"
                            value={props.values.user_not_register}
                            onChange={props.handleChange("user_not_register")}
                            onBlur={props.handleChange("user_not_register")}
                            style={ChsStyles.Question}
                            className={"form-control-alternative form-control-edit"}
                            error={props.touched.user_not_register && Boolean(props.errors.user_not_register)}
                            helperText={props.touched.user_not_register && props.errors.user_not_register}
                          />
                        </Col>

                        <Col lg="6">
                          <TextField
                            fullWidth
                            label={"Password Invalid*"}
                            variant="outlined"
                            value={props.values.password_invalid}
                            onChange={props.handleChange("password_invalid")}
                            onBlur={props.handleChange("password_invalid")}
                            style={ChsStyles.Question}
                            className={"form-control-alternative form-control-edit"}
                            error={props.touched.password_invalid && Boolean(props.errors.password_invalid)}
                            helperText={props.touched.password_invalid && props.errors.password_invalid}
                          />
                        </Col>
                      </Row>

                      <Row>
                        <Col lg="6">
                          <TextField
                            fullWidth
                            label={"User Removed*"}
                            variant="outlined"
                            value={props.values.user_unlink}
                            onChange={props.handleChange("user_unlink")}
                            onBlur={props.handleChange("user_unlink")}
                            style={ChsStyles.Question}
                            className={"form-control-alternative form-control-edit"}
                            error={props.touched.user_unlink && Boolean(props.errors.user_unlink)}
                            helperText={props.touched.user_unlink && props.errors.user_unlink}
                          />
                        </Col>

                        <Col lg="6">
                          <TextField
                            fullWidth
                            label={"User Deactivate*"}
                            variant="outlined"
                            value={props.values.user_deactivate}
                            onChange={props.handleChange("user_deactivate")}
                            onBlur={props.handleChange("user_deactivate")}
                            style={ChsStyles.Question}
                            className={"form-control-alternative form-control-edit"}
                            error={props.touched.user_deactivate && Boolean(props.errors.user_deactivate)}
                            helperText={props.touched.user_deactivate && props.errors.user_deactivate}
                          />
                        </Col>
                      </Row>

                      <Row>
                        <Col lg="6">
                          <TextField
                            fullWidth
                            label={"User Not Complete*"}
                            variant="outlined"
                            value={props.values.user_not_create}
                            onChange={props.handleChange("user_not_create")}
                            onBlur={props.handleChange("user_not_create")}
                            style={ChsStyles.Question}
                            className={"form-control-alternative form-control-edit"}
                            error={props.touched.user_not_create && Boolean(props.errors.user_not_create)}
                            helperText={props.touched.user_not_create && props.errors.user_not_create}
                          />
                        </Col>

                        <Col lg="6">
                          <TextField
                            fullWidth
                            label={"Organization Deactivate*"}
                            variant="outlined"
                            value={props.values.organization_deactivate}
                            onChange={props.handleChange("organization_deactivate")}
                            onBlur={props.handleChange("organization_deactivate")}
                            style={ChsStyles.Question}
                            className={"form-control-alternative form-control-edit"}
                            error={props.touched.organization_deactivate && Boolean(props.errors.organization_deactivate)}
                            helperText={props.touched.organization_deactivate && props.errors.organization_deactivate}
                          />
                        </Col>
                      </Row>

                      <Row>
                        <Col lg="6">
                          <TextField
                            fullWidth
                            label={"Location Deactivate*"}
                            variant="outlined"
                            value={props.values.location_deactivate}
                            onChange={props.handleChange("location_deactivate")}
                            onBlur={props.handleChange("location_deactivate")}
                            style={ChsStyles.Question}
                            className={"form-control-alternative form-control-edit"}
                            error={props.touched.location_deactivate && Boolean(props.errors.location_deactivate)}
                            helperText={props.touched.location_deactivate && props.errors.location_deactivate}
                          />
                        </Col>

                        <Col lg="6">
                          <TextField
                            fullWidth
                            label={"Support Email Message*"}
                            variant="outlined"
                            value={props.values.login_message}
                            onChange={props.handleChange("login_message")}
                            onBlur={props.handleChange("login_message")}
                            style={ChsStyles.Question}
                            className={"form-control-alternative form-control-edit"}
                            error={props.touched.login_message && Boolean(props.errors.login_message)}
                            helperText={props.touched.login_message && props.errors.login_message}
                          />
                        </Col>
                      </Row>

                      <Row>
                        <Col lg="6">
                          <TextField
                            fullWidth
                            label={"User Create Message*"}
                            variant="outlined"
                            value={props.values.user_successfully_created}
                            onChange={props.handleChange("user_successfully_created")}
                            onBlur={props.handleChange("user_successfully_created")}
                            style={ChsStyles.Question}
                            className={"form-control-alternative form-control-edit"}
                            error={props.touched.user_successfully_created && Boolean(props.errors.user_successfully_created)}
                            helperText={props.touched.user_successfully_created && props.errors.user_successfully_created}
                          />
                        </Col>

                        <Col lg="6">
                          <TextField
                            fullWidth
                            label={"Session Expire Message*"}
                            variant="outlined"
                            value={props.values.session_expired}
                            onChange={props.handleChange("session_expired")}
                            onBlur={props.handleChange("session_expired")}
                            style={ChsStyles.Question}
                            className={"form-control-alternative form-control-edit"}
                            error={props.touched.session_expired && Boolean(props.errors.session_expired)}
                            helperText={props.touched.session_expired && props.errors.session_expired}
                          />
                        </Col>
                      </Row>
                    </Form>
                  )}
                </Formik>
              </CardBody>

              {/* localization footer */}
              <CardFooter className="py-4">
                <Row>
                  <Col lg="6" className="text-left">
                    <Button
                      style={ChsStyles.GeneralButton}
                      color="primary"
                      onClick={() => {
                        window.history.back();
                      }}
                    >
                      BACK
                    </Button>

                    <Button
                      style={ChsStyles.GeneralButton}
                      color="primary"
                      onClick={() => {
                        if (formRef.current) {
                          formRef.current.handleSubmit();
                        }
                      }}
                    >
                      SAVE
                    </Button>
                  </Col>
                </Row>
              </CardFooter>
            </Card>
          </div>
        </Row>

        <br />

        {/* localization details */}
        <Row>
          <div className="col">
            <Card className="shadow">
              <CardHeader className="border-0">
                <div className="row">
                  <div className="mb-xl-0 col-11">
                    <h3 className="mb-0">Available APP Locales</h3>
                    {/* <h6 className="mb-0">Assessment should separately localize for below Locales</h6> */}
                  </div>
                  <div className="mb-xl-0">
                    <Tooltip title="Add new locale" arrow>
                      <div
                        className="navbar-toggler"
                        style={{ cursor: "pointer" }}
                        onClick={(e) => {
                          handleCreateNewClick(e);
                        }}
                      >
                        <i className="fas fa-plus-circle" style={{ fontSize: 30, color: GlobalStylesColor.mbppThemeBlue }} />
                      </div>
                    </Tooltip>
                  </div>
                </div>
              </CardHeader>
              <Table className="align-items-center table-flush" responsive>
                <thead className="thead-light">
                  <tr>
                    <th scope="col">Language Code</th>
                    <th scope="col">Sample (app_next)</th>
                    <th scope="col">Published</th>
                    <th scope="col">Actions</th>
                  </tr>
                </thead>
                <tbody>
                  <tr></tr>
                  {systemLocaleAll.map((locale, i) => {
                    return <LocaleRow key={i} locale={locale} position={i} editClick={handleEditClick} statusClick={handleStatusClick} viewClick={handleViewClick} createClick={handleCreateClick} />;
                  })}
                </tbody>
              </Table>
            </Card>
          </div>
        </Row>
      </Container>
    </>
  );
};

export default SystemLocalization;
