import { fetchFrmQus, fetchFrmTopics } from "actions/forum";
import Lodash from "lodash";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useHistory, useLocation } from "react-router-dom";
// reactstrap components
import { Card, CardBody, CardHeader, Container, Row, Table, Col } from "reactstrap";

// core components
import PageHeader from "components/Headers/PageHeader.js";
import CommonHeader from "components/Headers/CommonHeader";

import { fetchMasterDataLanguage, fetchLocalizationContent } from "actions/masterData";
import { LANGUAGE } from "constants/actionTypes";

import { CryptoPassword, initialValues_assessmentPage, RTL_FLAG_RIGHT, RTL_FLAG_LEFT } from "helper";
import { LOGINSTATE } from "../../../config/config.json";

import { useTranslation } from "react-i18next";

const ForumTableQuestionRow = ({ QusId, question, subTopicId, qdescription, rtlAlignment }) => (
  <Row id={QusId}>
    <Col style={{ whiteSpace: "break-spaces", color: "#af1e23", textAlign: rtlAlignment === RTL_FLAG_RIGHT ? RTL_FLAG_RIGHT : RTL_FLAG_LEFT }}>
      <b>{question}</b>
    </Col>
  </Row>
);

const ForumTableAnswerRow = ({ answerId, answer, ansdescription, rtlAlignment }) => (
  <Row id={answerId}>
    <Col style={{ whiteSpace: "break-spaces", textAlign: rtlAlignment === RTL_FLAG_RIGHT ? RTL_FLAG_RIGHT : RTL_FLAG_LEFT }}>
      <p>
        <b>{answer}</b>
      </p>
    </Col>
  </Row>
);

const FaqPublic = (props) => {
  // dispatch and history
  const dispatch = useDispatch();
  const history = useHistory();
  const location = useLocation();
  // get forum topics
  const forumalltopics = useSelector((state) => state.forum.frmAllTopics);
  const forumallquestions = useSelector((state) => state.forum.frmAllQus);

  const [subTopicId, setSubTopicId] = useState(null);
  // set state sub topic
  const [subTopic, setSubTopic] = useState(null);

  const [QusId, setQ] = useState(null);
  const [question, setQuestions] = useState(null);

  const [rtlAlignment, setRtlAlignment] = useState(RTL_FLAG_LEFT);
  const [languageCode, setLanguageCode] = useState(props?.location?.state?.languageCode);

  const { t, i18n } = useTranslation();

  useEffect(() => {
    // fetching forum topics
    dispatch(fetchFrmTopics(history, t));
    dispatch(fetchFrmQus(history, t));
  }, []);


  const userLang = useSelector((state) => state.masterData.languageByCode);
  if (LOGINSTATE) console.log("userLanguage", userLang);

  useEffect(() => {
    userLang &&
      userLang.language &&
      Object.values(userLang.language).map((lang, i) => {
        if (lang && lang.dir === "rtl") {
          setRtlAlignment(RTL_FLAG_RIGHT);
        } else {
          setRtlAlignment(RTL_FLAG_LEFT);
        }
        const params = { languageCode: lang.code };
        dispatch(fetchLocalizationContent(params, history));

        i18n.changeLanguage(lang.code);
        setLanguageCode(lang.code);
      });
  }, [userLang]);

  useEffect(() => {
    if (subTopic && subTopicId) {
      setQ(subTopic._id);
      setQuestions(subTopic.topic);
    }
  }, [forumalltopics, forumallquestions]);

  return (
    <>
      <PageHeader />
      {/* <CommonHeader hType="faq" /> */}

      {/* Page content */}
      <Container className="mt--9">
        {forumalltopics.length > 0 &&
          [forumalltopics[1]].map((props, i) => {
            return (
              <Row key={i} className="mt-5" id={props.id}>
                <div className="col">
                  <Card className="shadow">
                    <CardHeader className="border-0">
                      <div className="row">
                        <div className="mb-xl-0 col-12">
                          <h3 className="mb-0" style={{ textAlign: rtlAlignment === RTL_FLAG_RIGHT ? RTL_FLAG_RIGHT : RTL_FLAG_LEFT }}>{t('faq.Frequently Asked Questions (FAQs)')}</h3>
                          {/* <h3 className="mb-0" style={{ textAlign: rtlAlignment === RTL_FLAG_RIGHT ? RTL_FLAG_RIGHT : RTL_FLAG_LEFT }}>{props.topic}</h3> */}
                          {/* <h6 className="mb-0">{props.description}</h6> */}
                        </div>
                      </div>
                    </CardHeader>
                    <CardBody>
                      {!Lodash.isEmpty(Lodash.filter(props.subTopic, { subTopic: languageCode }))
                        ? Object.values(Lodash.filter(props.subTopic, { subTopic: languageCode })).map((subprops, i) => {
                          return (
                            <>
                              {Lodash.filter(forumallquestions, { subTopic: subprops._id }).map((index, j) => {
                                return (
                                  <div style={{ marginBottom: 20 }}>
                                    <ForumTableQuestionRow QusId={index} question={index.question} qdescription={index.description} rtlAlignment={rtlAlignment} />

                                    {Object.values(index.answer).map((ans, l) => {
                                      return <ForumTableAnswerRow answerId={ans._id} answer={ans.answer} ansdescription={ans.description} rtlAlignment={rtlAlignment} />;
                                    })}
                                  </div>
                                );
                              })}
                            </>
                          );
                        })
                        : Object.values(Lodash.filter(props.subTopic, { subTopic: "en" })).map((subprops, i) => {
                          return (
                            <>
                              {Lodash.filter(forumallquestions, { subTopic: subprops._id }).map((index, j) => {
                                return (
                                  <div style={{ marginBottom: 20 }}>
                                    <ForumTableQuestionRow QusId={index} question={index.question} qdescription={index.description} rtlAlignment={rtlAlignment} />

                                    {Object.values(index.answer).map((ans, l) => {
                                      return <ForumTableAnswerRow answerId={ans._id} answer={ans.answer} ansdescription={ans.description} rtlAlignment={rtlAlignment} />;
                                    })}
                                  </div>
                                );
                              })}
                            </>
                          );
                        })}
                      {/* </Table> */}
                    </CardBody>
                  </Card>
                </div>
              </Row>
            );
          })}
      </Container>
    </>
  );
};

export default FaqPublic;
