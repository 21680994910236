import React, { useState, useEffect } from "react";
import { useFormik } from "formik";
import * as yup from "yup";
import { useDispatch, useSelector } from "react-redux";
import { useLocation, useHistory } from "react-router-dom";
import { ChsStyles } from "constants/globalStyles";
import { RECOMMENDATION_LOCAL } from "constants/actionTypes";

// reactstrap components
import { Card, CardHeader, CardBody, CardFooter, Container, FormGroup, Form, Button, Row, Col } from "reactstrap";

import { TextField, Grid } from "@material-ui/core";

import {} from "actions/survey";
import Lodash from "lodash";

// core components
import PageHeader from "components/Headers/PageHeader.js";
import { LOGINSTATE } from "../../../config/config.json";

const validationSchemaQuestion = yup.object({
  from: yup.number("Enter min score").required("Scor is required"),

  to: yup.number("Enter max score").required("score is required"),

  // recommendation: yup.string("Enter recommendation").required("recommendation is required"),

  recommendation: yup.string().when("recommendationValidate", {
    is: true,
    then: yup.string().required("recommendation is required"),
  }),

  note: yup.string().when("noteValidate", {
    is: true,
    then: yup.string().required("recommendation note is required"),
  }),
});

const RecommendationDetailsEdit = (props) => {
  const dispatch = useDispatch();
  const history = useHistory();

  const [renderKi, setRenderKi] = useState(0);

  const initSurveyRecommendations = {
    languageCode: "en",
    type: "",
    title: "",
    description: "",
    overall: {
      recommendations: [],
      status: true,
    },
    segments: [],
    status: "DEFAULT",
    published: false,
  };
  const [surveyRecommendations, setSurveyRecommendations] = useState(initSurveyRecommendations); // Full object
  const [overallData, setOverallData] = useState(initSurveyRecommendations?.overall?.recommendations); // Overall details
  const [segmentData, setSegmentData] = useState(initSurveyRecommendations?.segments); // Segment details
  const [type, setType] = useState("");
  const [position, setPosition] = useState(-1);
  const [segmentId, setSegmentId] = useState("");

  const [paramType, setParamType] = useState("");
  const [paramSegmentId, setParamSegmentId] = useState(-1);
  const [paramPosition, setParamPosition] = useState(-1);

  const formik = useFormik({
    initialValues: {
      from: 0,
      to: 0,
      recommendation: "",
      note: "",
      type: "",
      segmentId: "",
      segmentCode: "",
      recommendationValidate: false,
      noteValidate: false,
    },
    validationSchema: validationSchemaQuestion,
    onSubmit: (values, onSubmitProps) => {
      if (LOGINSTATE) console.log("1. paramType", paramType);
      if (LOGINSTATE) console.log("2. paramSegmentId", paramSegmentId);
      if (LOGINSTATE) console.log("3. paramPosition", paramPosition);
      if (LOGINSTATE) console.log("1g. surveyRecommendations", surveyRecommendations);
      if (LOGINSTATE) console.log("2g. overallData", overallData);
      if (LOGINSTATE) console.log("3g. segmentData", segmentData);

      if (paramPosition >= 0) {
        if (paramType === "OVERALL") {
          overallData[paramPosition]["from"] = formik.values.from;
          overallData[paramPosition]["to"] = formik.values.to;
          overallData[paramPosition]["recommendation"] = formik.values.recommendation;
          overallData[paramPosition]["note"] = formik.values.note;
          overallData[paramPosition]["segmentId"] = formik.values.segmentId;
          overallData[paramPosition]["segmentCode"] = formik.values.segmentCode;
          updateAndBack();
        } else if (paramType === "SEGMENT") {
          segmentData[paramSegmentId].recommendations[paramPosition]["from"] = formik.values.from;
          segmentData[paramSegmentId].recommendations[paramPosition]["to"] = formik.values.to;
          segmentData[paramSegmentId].recommendations[paramPosition]["recommendation"] = formik.values.recommendation;
          segmentData[paramSegmentId].recommendations[paramPosition]["note"] = formik.values.note;
          segmentData[paramSegmentId].recommendations[paramPosition]["segmentId"] = formik.values.segmentId;
          segmentData[paramSegmentId].recommendations[paramPosition]["segmentCode"] = formik.values.segmentCode;
          updateAndBack();
        } else {
          if (LOGINSTATE) console.log("update is failed dut to unavailable type");
        }
      } else {
        let temp = {};
        temp["from"] = formik.values.from;
        temp["to"] = formik.values.to;
        temp["recommendation"] = formik.values.recommendation;
        temp["note"] = formik.values.note;
        temp["segmentId"] = formik.values.segmentId;
        temp["segmentCode"] = formik.values.segmentCode;

        if (paramType === "OVERALL") {
          overallData.push(temp);
          updateAndBack();
        } else if (paramType === "SEGMENT") {
          if (segmentData[paramSegmentId].recommendations) {
            segmentData[paramSegmentId].recommendations.push(temp);
          } else {
            let arrayRecommendation = [temp];
            segmentData[paramSegmentId]["recommendations"] = arrayRecommendation;
            segmentData[paramSegmentId]["segmentId"] = segmentData[paramSegmentId]._id;
          }
          updateAndBack();
        } else {
          if (LOGINSTATE) console.log("new update is failed dut to unavailable type");
        }
      }
    },
  });

  const updateAndBack = () => {
    if (LOGINSTATE) console.log("formik recommendation", formik.values.recommendation);
    if (formik.values.recommendation === "" && formik.values.note === "") {
      // console.log("formik");
      validateRecommendation("");
    } else {
      surveyRecommendations["overall"]["recommendations"] = overallData;
      surveyRecommendations["segments"] = segmentData;
      localStorage.setItem(RECOMMENDATION_LOCAL, JSON.stringify(surveyRecommendations));
      dispatch({ type: RECOMMENDATION_LOCAL, payload: surveyRecommendations });
      window.history.back();
    }
  };

  useEffect(() => {
    try {
      let localRecommendations = [];
      let stateOverallData = [];
      let stateSegmentData = [];
      if (localStorage.getItem(RECOMMENDATION_LOCAL)) {
        localRecommendations = JSON.parse(localStorage.getItem(RECOMMENDATION_LOCAL));
        if (LOGINSTATE) console.log("0. Local variable: ", localRecommendations);

        // init the params
        if (localRecommendations && !Lodash.isEmpty(localRecommendations) && (localRecommendations?.overall?.recommendations || localRecommendations?.segments)) {
          if (LOGINSTATE) console.log("start init variables");
          setSurveyRecommendations(localRecommendations);
          setOverallData(localRecommendations?.overall?.recommendations);
          stateOverallData = localRecommendations?.overall?.recommendations;

          setSegmentData(localRecommendations?.segments);
          stateSegmentData = localRecommendations?.segments;
        } else {
          if (LOGINSTATE) console.log("0. Recommendation update is ignored for: ", localRecommendations);
        }
      }

      const stateType = props.location.state?.type;
      const stateSegmentId = props.location.state?.segmentId;
      const statePosition = props.location.state?.position;

      setParamType(props.location.state?.type);
      setParamSegmentId(props.location.state?.segmentId);
      setParamPosition(props.location.state?.position);

      if (Lodash.isNumber(statePosition) && statePosition >= 0) {
        const rowEntry = stateType === "OVERALL" ? stateOverallData[statePosition] : stateSegmentData[stateSegmentId].recommendations[statePosition];
        if (LOGINSTATE) console.log("row entry: ", rowEntry);
        if (rowEntry) {
          formik.values.from = rowEntry?.from ? rowEntry?.from : 0;
          formik.values.to = rowEntry.to ? rowEntry.to : 0;
          formik.values.recommendation = rowEntry?.recommendation ? rowEntry?.recommendation : "";
          formik.values.note = rowEntry?.note ? rowEntry?.note : "";
          formik.values.segmentId = rowEntry?.segmentId ? rowEntry?.segmentId : "";
          formik.values.segmentCode = rowEntry?.segmentCode ? rowEntry?.segmentCode : "";
        } else {
          if (LOGINSTATE) console.log("no data to update");
        }
      } else {
        if (LOGINSTATE) console.log("adding new entry");
        formik.values.from = 0;
        formik.values.to = 0;
        formik.values.recommendation = "";
        formik.values.note = "";
        formik.values.segmentId = paramType === "SEGMENT" ? paramSegmentId : "";
        formik.values.segmentCode = "";
      }
      setRenderKi(renderKi + 1);
    } catch (error) {
      if (LOGINSTATE) console.log("local recommendations: ", error);
    }

    return () => {};
  }, []);

  // information getting from redux
  const recommendationSaved = useSelector((state) => state.survey.localRecommendationTemplate);
  useEffect(() => {
    if (recommendationSaved !== undefined && recommendationSaved !== null && !Lodash.isEmpty(recommendationSaved)) {
      localStorage.setItem(RECOMMENDATION_LOCAL, JSON.stringify(recommendationSaved));
    }
  }, [recommendationSaved]);

  const validateRecommendation = (recommendation, flag) => {
    if (flag === true && recommendation) {
      formik.values.recommendationValidate = true;
    }

    if (flag === false && recommendation) {
      formik.values.noteValidate = true;
      formik.values.recommendationValidate = false;
    }

    if (!recommendation) {
      if (LOGINSTATE) console.log("validate recommendation", recommendation);
      formik.values.recommendationValidate = true;
    }
  };

  return (
    <>
      <PageHeader />
      {/* Page content */}
      <Container className="mt--7" fluid>
        {/* Table School */}
        <Row className="mt-5" id={renderKi}>
          <div className="col">
            <Card className="shadow">
              <CardHeader className="border-0">
                <h3 className="mb-0">Question Details</h3>
              </CardHeader>

              <Form role="form" onSubmit={formik.handleSubmit} onReset={formik.handleReset}>
                <CardBody>
                  <FormGroup className="mb-3">
                    <Grid container spacing={1} alignItems="center" style={ChsStyles.Grid}>
                      <Grid item xs={1} sm={1} className="px-3 text-right">
                        <i className="fas fa-ruler-vertical" />
                      </Grid>
                      <Grid item xs={4} sm={4} lg={2}>
                        <TextField
                          fullWidth
                          label="Score From"
                          variant="outlined"
                          value={formik.values.from}
                          onChange={formik.handleChange("from")}
                          error={formik.touched.from && Boolean(formik.errors.from)}
                          helperText={formik.touched.from && formik.errors.from}
                        />
                      </Grid>
                      <Grid item xs={4} sm={4} lg={2}>
                        <TextField
                          fullWidth
                          label="Score To"
                          variant="outlined"
                          value={formik.values.to}
                          onChange={formik.handleChange("to")}
                          error={formik.touched.to && Boolean(formik.errors.to)}
                          helperText={formik.touched.to && formik.errors.to}
                        />
                      </Grid>
                    </Grid>

                    <Grid container spacing={1} alignItems="center" style={ChsStyles.Grid}>
                      <Grid item xs={1} sm={1} className="px-3 text-right">
                        <i className="fas fa-pen-nib" />
                      </Grid>
                      <Grid item xs={10} sm={10}>
                        <TextField
                          fullWidth
                          label="Recommendation"
                          variant="outlined"
                          value={formik.values.recommendation}
                          onChange={formik.handleChange("recommendation")}
                          onBlur={(e) => validateRecommendation(formik.values.recommendation, true)}
                          multiline
                          rows={8}
                          error={formik.touched.recommendation && Boolean(formik.errors.recommendation)}
                          helperText={formik.touched.recommendation && formik.errors.recommendation}
                        />
                      </Grid>
                    </Grid>
                    <Grid container spacing={1} alignItems="center" style={ChsStyles.Grid}>
                      <Grid item xs={1} sm={1} className="px-3 text-right"></Grid>
                      <Grid item xs={10} sm={10}>
                        <div style={ChsStyles.QuickNote}>
                          This supports multiline text.
                        </div>
                      </Grid>
                    </Grid>
                    {/* <Grid container spacing={1} alignItems="center" style={ChsStyles.Grid}>
                      <Grid item xs={1} sm={1} className="px-3 text-right">
                        <i className="fas fa-paperclip" />
                      </Grid>
                      <Grid item xs={10} sm={10}>
                        <TextField
                          fullWidth
                          label="Note"
                          variant="outlined"
                          value={formik.values.note}
                          onChange={formik.handleChange("note")}
                          onBlur={(e) => validateRecommendation(formik.values.note, false)}
                          multiline
                          rows={4}
                          error={formik.touched.note && Boolean(formik.errors.note)}
                          helperText={formik.touched.note && formik.errors.note}
                        />
                      </Grid>
                    </Grid>
                    <Grid container spacing={1} alignItems="center" style={ChsStyles.Grid}>
                      <Grid item xs={1} sm={1} className="px-3 text-right"></Grid>
                      <Grid item xs={10} sm={10}>
                        <div style={ChsStyles.QuickNote}>
                          Note is added as an <i>italic</i> text.
                        </div>
                      </Grid>
                    </Grid> */}
                  </FormGroup>
                </CardBody>

                <CardFooter className="py-4">
                  <Row>
                    <Col lg="6" className="text-left">
                      <Button
                        style={ChsStyles.GeneralButton}
                        color="primary"
                        onClick={() => {
                          window.history.back();
                        }}
                      >
                        BACK
                      </Button>
                      <Button style={ChsStyles.GeneralButton} color="primary" type="submit">
                        DONE
                      </Button>
                    </Col>
                    <Col lg="6" className="text-right"></Col>
                  </Row>
                </CardFooter>
              </Form>
            </Card>
          </div>
        </Row>
      </Container>
    </>
  );
};

export default RecommendationDetailsEdit;
