import { fetchRecommendationsBySurveyId } from "actions/survey";

// core components
import PageHeader from "components/Headers/PageHeader.js";
import Config from "config/config.json";
import { LOGINSTATE } from "../../../config/config.json";
import Lodash from "lodash";
import Moment from "moment";
import React, { useEffect, useState } from "react";
import ReactDOM from "react-dom";
import { useDispatch, useSelector } from "react-redux";
import { useHistory, useLocation } from "react-router-dom";
// reactstrap components
import { Card, CardHeader, Container, Row, Table, Label, Col } from "reactstrap";
import { ChsStyles, GlobalStylesColor } from "constants/globalStyles";
import { confirmAlert } from "react-confirm-alert";
import "react-confirm-alert/src/react-confirm-alert.css"; // Import css
import { answer_status } from "helper.js";
import { Score, Visibility } from "@material-ui/icons";
import { initialValues_assessmentPage, roundValue } from "helper";


// export function
const AnswerPrintAssessment = (props) => {
  if (LOGINSTATE) console.log("props", props);
  // dispatch and history
  const dispatch = useDispatch();
  const history = useHistory();
  const location = useLocation();

  const [answer, setAnswer] = useState({});
  const [recommendation, setRecommendation] = useState({});
  const [survey, setSurvey] = useState({});
  const [surveyScore, setSurveyScore] = useState(0);
  const [isEnable, setIsEnable] = useState(false);

  useEffect(() => {
    if (props?.location?.state?.answer) {
      setAnswer(props?.location?.state?.answer);
    }
    if (props?.location?.state?.recommendation) {
      setRecommendation(props?.location?.state?.recommendation);
    }
    if (props?.location?.state?.survey) {
      setSurvey(props?.location?.state?.survey);
    }
    if (props?.location?.state?.score) {
      setSurveyScore(props?.location?.state?.score);
    }
  }, []);

  const segment = () => {
    for (let segmentData = 0; segmentData < props?.location?.state?.recommendation?.segments?.length; segmentData++) {
      const element = props?.location?.state?.recommendation?.segments[segmentData];
      <div>{element.title}</div>;
    }
  };

  const timeOut = () => {
    // let id = document.getElementById("print");
    // if (id.style.disabled === false) {
    //   id.style.disabled === true;
    // }
    // setTimeout(() => {
    //   setIsEnable(true);
    //   window.print();
    // }, 2000);
  };

  const PrintDocument = () => (
    <div>
      <div>
        <img alt="CHS" src={require("../../../assets/img/theme/img_logo.png").default} />
      </div>
      <br />
      <div>
        <h3 style={{ color: GlobalStylesColor.mbppBlue }}>
          Type of the Assessment :
          {props?.location?.state?.survey?.type === "CHSAT"
            ? "Core Humanitarian Standards Assessment Tool"
            : props?.location?.state?.survey?.type === "ICAT"
            ? "Institutional Capacity Assessment Tool"
            : "Tool for Institutional Engagement"}
        </h3>
        <h3 style={{ color: GlobalStylesColor.mbppBlue }}>Version of the Assessment : {props?.location?.state?.survey?.title}</h3>
      </div>

      <br />
      {/* <h3 style={{ color: GlobalStylesColor.mbppBlue }}>Segments:</h3>
      <p style={{ whiteSpace: "break-spaces" }}>
        {Object.values(props?.location?.state?.answer).map((segment, i) => {
          return segment.title + " - " + segment.segmentScore + "\n";
        })}
      </p>
      <br /> */}
      <h3 style={{ color: GlobalStylesColor.mbppBlue }}>
        <u>Overall details</u>
      </h3>

      <h3 style={{ color: GlobalStylesColor.mbppBlue }}>Total score : {props?.location?.state?.score}</h3>
      <h3 style={{ color: GlobalStylesColor.mbppBlue }}>Recommendation for the Total score :</h3>
      <div style={{ color: GlobalStylesColor.mbppBlue, whiteSpace: "break-spaces" }}>
        {props?.location?.state?.recommendation && !Lodash.isEmpty(props?.location?.state?.recommendation?.overall?.recommendations) ? (
          props?.location?.state?.recommendation?.overall?.recommendations.map((recom, i) => {
            return recom.from <= props?.location?.state?.score && props?.location?.state?.score <= recom.to ? <p key={i}>{recom.recommendation}</p> : null;
          })
        ) : (
          <p>NO RECOMENDATIONS</p>
        )}
      </div>
      <br />
      <h3 style={{ color: GlobalStylesColor.mbppBlue }}>
        <u>Segment details</u>
      </h3>
      <div style={{ whiteSpace: "break-spaces" }}>
        {Object.values(props?.location?.state?.answer).map((answer, i) => {
          return (
            <>
              <p key={i}>
                Title of the segment : {answer.title} {"\n"}Score of the segment : {answer.segmentScore} {"\n"}Recommendation :
              </p>
              {props?.location?.state?.recommendation &&
              props?.location?.state?.recommendation?.segments &&
              props?.location?.state?.recommendation?.segments[i] &&
              !Lodash.isEmpty(props?.location?.state?.recommendation?.segments[i]?.recommendations) ? (
                props?.location?.state?.recommendation?.segments[i]?.recommendations.map((recom, j) => {
                  return recom.from <= answer.segmentScore && answer.segmentScore <= recom.to ? (
                    <p key={j} style={{ color: GlobalStylesColor.mbppBlue, whiteSpace: "break-spaces" }}>
                      {recom.recommendation} {"\n"}
                      {"\n"}
                    </p>
                  ) : null;
                })
              ) : (
                <p>NO RECOMENDATIONS</p>
              )}
            </>
          );
        })}
      </div>
      <br />
      <Row>
        <Col lg="1">
          <button
            style={ChsStyles.GeneralButton}
            onClick={() => {
              timeOut();
            }}
            id="print"
            disabled={false}
          >
            PRINT
          </button>
        </Col>
      </Row>
    </div>
  );

  return ReactDOM.render(<PrintDocument />, document.getElementById("root"));
};

export default AnswerPrintAssessment;
