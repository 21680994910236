import { Avatar } from "@material-ui/core";
import { fetchAllProfiles, updateUser, fetchProfileOrgAsAdmin } from "actions/user";
// core components
import PageHeader from "components/Headers/PageHeader.js";
import Config from "config/config.json";
import { LOGINSTATE } from "../../../config/config.json";
import { useFormik } from "formik";
import { Camelize } from "helper.js";
import Lodash from "lodash";
import React, { useEffect, useState } from "react";
import { confirmAlert } from "react-confirm-alert";
import "react-confirm-alert/src/react-confirm-alert.css"; // Import css
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
// reactstrap components
import { Badge, Button, Card, CardBody, CardHeader, CardFooter, Col, Container, Media, Row, Table, FormGroup, InputGroup, InputGroupAddon, InputGroupText, Input } from "reactstrap";
import * as api from "../../../api/index.js";
import { Tooltip } from "@material-ui/core";
import { GlobalStylesColor } from "constants/globalStyles";
import Pagination from "react-responsive-pagination";

const User = () => {
  const dispatch = useDispatch();
  const history = useHistory();

  const [userDetails, setUserDetails] = useState({});
  const [userProfile, setUserProfile] = useState({});
  // Show User or edit profile
  const [editSection, setEditSection] = useState("USERS");
  // set state edit
  const [organizationUserAll, setOrganizationUserAll] = useState([]);

  // pagination
  const itemPageSize = Config.ITEM_PAGE_SIZE;
  const [itemCurrent, setItemCurrent] = useState(1);
  const [itemPageContent, setItemPageContent] = useState([]);
  const [itemPageCount, setItemPageCount] = useState(1);
  // filter
  const [txtFilter, setTxtFilter] = useState("");
  const [filterdContent, setFilterdContent] = useState([]);

  useEffect(() => {
    // fetching all user profiles
    if (LOGINSTATE) console.log("getting all the users");
    dispatch(fetchAllProfiles());
  }, []);

  // get state all user profiles
  const allUserProfiles = useSelector((state) => state.user.usersProfiles);
  useEffect(() => {
    if (LOGINSTATE) console.log("allUserProfiles: ", allUserProfiles);
    setOrganizationUserAll(Lodash.filter(allUserProfiles, { urole: "GENERAL" }));
  }, [allUserProfiles]);

  // process content with filter
  useEffect(() => {
    // 1. filter the users form the filter
    let updatedUsers = Lodash.filter(organizationUserAll, function (item) {
      return item.email.indexOf(txtFilter) > -1;
    });
    setItemCurrent(1)
    setFilterdContent(updatedUsers);
  }, [organizationUserAll, txtFilter]);

  // process the pagination with page content
  useEffect(() => {
    if (LOGINSTATE) console.log("filterdContent.length: ", filterdContent.length);
    // Set the pagination count
    let currentPageCount = Math.ceil(filterdContent.length / itemPageSize);
    setItemPageCount(currentPageCount);

    // Set the pagination content
    if (filterdContent && filterdContent.length > 0) {
      // make sure the page doesn't exceed the array endings
      let startCount = (itemCurrent - 1) * itemPageSize < 0 ? 0 : (itemCurrent - 1) * itemPageSize;
      let endCount = itemCurrent * itemPageSize > filterdContent.length ? filterdContent.length : itemCurrent * itemPageSize;

      let updatedArray = Lodash.slice(filterdContent, startCount, endCount);
      if (updatedArray && updatedArray.length > 0) {
        setItemPageContent(updatedArray);
      } else {
        setItemPageContent([]);
      }
    } else {
      setItemPageContent([]);
    }
  }, [itemCurrent, filterdContent]);

  const UserTableRow = ({ id, itemCount, itemPageSize, itemCurrent, email, role, status, profile }) => (
    <tr id={id} key={id}>
      {itemCount >= 0 ? <td>{itemPageSize * (itemCurrent - 1) + itemCount + 1}</td> : null}
      <td
        scope="row"
        style={
          {
            /*marginLeft: 0, paddingLeft: 0*/
          }
        }
      >
        <Media className="align-items-center">
          <Avatar
            className="purple"
            alt={profile ? profile.firstName : ""}
            src={profile && !Lodash.isEmpty(profile.profilePicture) ? Config.SERVER_API_URL + Config.PUBLIC_PROF_PIC + profile.profilePicture : null}
            style={{ marginRight: 5 }}
          >
            {profile && profile.firstName && profile.firstName.length > 0 ? profile.firstName.charAt(0).toUpperCase() : ""}
          </Avatar>
          <div>
            {email}
            <Media>
              <span className="mb-0 text-sm">{profile && !Lodash.isEmpty(profile.firstName) ? Camelize(profile.firstName + " " + profile.lastName) : ""}</span>
            </Media>
          </div>
        </Media>
      </td>
      {/* <td>{(profile && profile.employment && profile.employment.designation) ? profile.employment.designation : ""}</td> */}
      <td>{profile && profile.employment && profile.employment.organization && profile.employment.organization.name ? profile.employment.organization.name : ""}</td>
      <td>
        {
          {
            ACTIVE: (
              <Badge color="" className="badge-dot">
                <i className="bg-success" />
                ACTIVE
              </Badge>
            ),
            INACTIVE: (
              <Badge color="" className="badge-dot">
                <i className="bg-yellow" />
                INACTIVE
              </Badge>
            ),
            UNLINKED: (
              <Badge color="" className="badge-dot">
                <i className="bg-danger" />
                REMOVED
              </Badge>
            ),
          }[status] || (
            <Badge color="" className="badge-dot">
              <i className="bg-blue" />
              {status}
            </Badge>
          ) // default status
        }
      </td>
      <td /*className="text-left"*/ style={{ margin: 0, padding: 0 }}>
        <div
          className="navbar-toggler"
          style={{ cursor: "pointer" }}
          onClick={() => {
            if (LOGINSTATE) console.log("test profile ...", profile);
            setUserDetails({ user: { id: id, email: email, status: status, urole: role } });
            setUserProfile(profile);
          }}
        >
          <i className="fas fa-angle-right" />
        </div>
      </td>
    </tr>
  );

  // Profile edit start -------------------------------------------

  useEffect(() => {
    if (userProfile && Object.keys(userProfile).length > 0) {
      if (LOGINSTATE) console.log("userProfile: ", userProfile);

      formik.values.userId = userDetails?.user.id || "";
      formik.values.email = userDetails?.user.email || "";

      formik.values.profileId = userProfile?._id || "";
      formik.values.firstName = userProfile?.firstName || "";
      formik.values.lastName = userProfile?.lastName || "";
      formik.values.profilePicture = userProfile?.profilePicture || "";
      formik.values.profileThumbnail = userProfile?.profileThumbnail || "";

      formik.values.emp_organization_name = userProfile?.employment?.organization?.name || "";
      formik.values.emp_organization_type = userProfile?.employment?.organization?.type || "";
      // formik.values.emp_organization_legalStatus = userProfile?.employment?.organization?.legalStatus?.status || false;
      formik.values.emp_organization_geographicArea = userProfile?.employment?.organization?.geographicArea || "";
      formik.values.emp_organization_website = userProfile?.employment?.organization?.website || "";
      formik.values.emp_designation = userProfile?.employment?.designation || "";
    }
  }, [userProfile]);

  const changeUserStatus = async (userId, status) => {
    const params = { userId: userId, status: status };

    try {
      if (LOGINSTATE) console.log("params: ", params);
      const response = await api.changeStatus(params);
      if (LOGINSTATE) console.log("response: ", response);
      const { data } = response;
      if (LOGINSTATE) console.log("data: ", data);

      if (data?.status === "success") {
        dispatch(fetchAllProfiles());
        setEditSection("USERS");
        setUserProfile({});
      } else if (data?.status === "error") {
        if (LOGINSTATE) console.log(data);
        confirmAlert({ message: data?.message ? data.message : "User status change failed.", buttons: [{ label: "OK", onClick: () => { } }], closeOnEscape: false, closeOnClickOutside: false });
      }
    } catch (error) {
      if (LOGINSTATE) console.log("catch error", error.response);
      if (LOGINSTATE) console.log("status", error.response.data.status);
      if (LOGINSTATE) console.log("msg", error.response.data.message);

      // process the error to show proper error message in popup
      let errorTitle = error?.response?.data?.data ? error?.response?.data?.message : null;
      let errorMessge = error?.response?.data?.data ? error?.response?.data?.data : error?.response?.data?.message;
      errorMessge = Lodash.isEmpty(errorMessge) ? "Oops, something went wrong" : errorMessge;
      confirmAlert({ title: errorTitle, message: errorMessge, buttons: [{ label: "Ok", onClick: () => { } }], closeOnEscape: false, closeOnClickOutside: false });
    }
  };

  const formik = useFormik({
    initialValues: {
      userId: "",
      profileId: "",
      email: "",
      firstName: "",
      lastName: "",
      profilePicture: "",
      profileThumbnail: "",
      newProfilePicture: false,

      emp_organization_name: "",
      emp_designation: "",
      emp_organization_type: "",
      emp_organization_legalStatus: false,
      emp_organization_website: "",
      emp_organization_geographicArea: "",
    },
    // validationSchema: validationSchema,
    onSubmit: (values, onSubmitProps) => {
      values["update_by_admin"] = true;
      values["user"] = userDetails?.user?.id;
      if (LOGINSTATE) console.log("Update", values);
      dispatch(updateUser(values, false, history));
    },
  });

  // Profile edit end -------------------------------------------

  return (
    <>
      <PageHeader />
      {/* Page content */}
      <Container className="mt--7" fluid>
        <Row className="mt-5">
          {/* Right panel //////////////////////////////////////////////////////////////////////////////// */}
          <Col className="order-xl-2 mb-5 mb-xl-0" xl="4">
            {!Lodash.isEmpty(userProfile) && (
              <Card className="card-profile shadow">
                <Row className="justify-content-center">
                  <Col className="order-lg-2" lg="3" style={{ marginLeft: -20 }}>
                    <div className="card-profile-image">
                      <div className="rounded-circle" style={{ backgroundImage: 'url("' + Config.SERVER_API_URL + Config.PUBLIC_PROF_PIC + userProfile?.profilePicture + '")', backgroundColor: "#bdbdbd" }}>
                        {userProfile?.profilePicture || Lodash.isEmpty(userProfile?.firstName) ? "" : userProfile?.firstName.charAt(0).toUpperCase()}
                      </div>
                    </div>
                  </Col>
                </Row>
                <Row style={{ paddingTop: 130, margin: 10 }}>
                  <Col className="d-flex justify-content-between order-xl-2 mb-5 mb-xl-0" style={{ padding: 0 }}>
                    <div></div>
                    <Button
                      className="mr-4"
                      color="primary"
                      onClick={(e) => {
                        // setEditSection("PROFILE");
                        if (LOGINSTATE) console.log("userProfile: ", userProfile);
                        let params = {
                          user: {
                            userId: userDetails?.user?.id,
                            email: userDetails?.user?.email,
                            urole: userDetails.user?.urole,
                          },
                        };
                        dispatch(fetchProfileOrgAsAdmin(params.user));
                        history.push({ pathname: "/registered/user_profile_manage", state: { userDetails: params } });
                      }}
                      size="sm"
                      // style={{ minWidth: 130 }}
                      style={{ width: 120, paddingTop: 3, paddingBottom: 3 }}
                    >
                      View Profile
                    </Button>
                    <Button
                      className="float-right"
                      color="primary"
                      onClick={(e) => {
                        changeUserStatus(userDetails?.user?.id, userDetails?.user?.status === "ACTIVE" ? "INACTIVE" : "ACTIVE");
                      }}
                      size="sm"
                      style={{ width: 120, paddingTop: 3, paddingBottom: 3 }}
                    >
                      Change Status
                    </Button>
                    <div></div>
                  </Col>
                </Row>

                {userDetails?.user.urole === "ADMIN" ? (
                  <CardBody className="pt-0 pt-md-4">
                    <div className="text-center">
                      <h3> </h3>
                    </div>
                    <div className="text-center">
                      <h3>{Camelize(userProfile?.firstName + " " + userProfile?.lastName)}</h3>
                      <div className="h5 font-weight-300">{userDetails?.user?.email}</div>
                    </div>
                  </CardBody>
                ) : (
                  <CardBody className="pt-0 pt-md-4">
                    <div className="text-center">
                      <h3> </h3>
                    </div>
                    <div className="text-center">
                      <h3>{Camelize(userProfile?.firstName + " " + userProfile?.lastName)}</h3>
                      <div className="h5 font-weight-300">{userDetails?.user?.email}</div>
                      <br />

                      <div className="h5 font-weight-300 form-check-label">Designation</div>
                      <div>{userProfile?.employment?.designation}</div>
                      <br />

                      <div className="h5 font-weight-300 form-check-label">Organization</div>
                      <div>{userProfile?.employment?.organization?.name}</div>
                      <br />

                      <div className="h5 font-weight-300 form-check-label">Type</div>
                      <div>{userProfile?.employment?.organization?.type}</div>
                      <br />

                      <div className="h5 font-weight-300 form-check-label">Geographic Area</div>
                      <div>{userProfile?.employment?.organization?.geographicArea}</div>
                      <br />

                      <div className="h5 font-weight-300 form-check-label">Website</div>
                      <div>{userProfile?.employment?.organization?.website}</div>
                      <br />
                    </div>
                  </CardBody>
                )}
              </Card>
            )}
          </Col>

          {/* Left panel //////////////////////////////////////////////////////////////////////////////// */}

          {/* Table admin user details */}
          <Col className="order-xl-1" xl={Lodash.isEmpty(userProfile) ? "12" : "8"} style={{ display: editSection === "USERS" ? "block" : "none" }}>
            <Row>
              <div className="col">
                <Card className="shadow">
                  <CardHeader className="border-0">
                    <div className="row">
                      <div className="mb-xl-0 col-8">
                        <h3 className="mb-0">Admin User Profiles</h3>
                      </div>
                      <div className="mb-xl-0 col-4 text-right">
                        <Tooltip title="Add new admin user">
                          <div
                            className="navbar-toggler"
                            style={{ cursor: "pointer" }}
                            onClick={(e) => {
                              history.push({ pathname: "/registered/register_admin" });
                            }}
                          >
                            <i className="fas fa-plus-circle" style={{ fontSize: 30, color: GlobalStylesColor.mbppThemeBlue }} />
                          </div>
                        </Tooltip>
                      </div>
                    </div>
                  </CardHeader>
                  <Table className="align-items-center table-flush" responsive>
                    <thead className="thead-light">
                      <tr>
                        {/* <th scope="col" /> */}
                        <th scope="col">User (email&darr;)</th>
                        <th scope="col"></th>
                        <th scope="col">Status</th>
                        <th scope="col" />
                      </tr>
                    </thead>
                    <tbody>
                      {allUserProfiles.length > 0 &&
                        Object.values(Lodash.filter(allUserProfiles, { urole: "ADMIN" })).map((props, i) => {
                          if (LOGINSTATE) console.log("map profile", props);
                          return <UserTableRow key={i} id={props._id} email={props.email} role={props.urole} status={props.status.isActive} profile={props.profile ? props.profile : null} />;
                        })}
                    </tbody>
                  </Table>
                </Card>
              </div>
            </Row>

            <Row>
              <Col>
                <br />
                <br />
              </Col>
            </Row>

            {/* Table organization user details */}
            <Row>
              <div className="col">
                <Card className="shadow">
                  <CardHeader className="border-0">
                    <Row>
                      <Col md="6">
                        <h3 className="mb-0">Organization User Profiles</h3>
                      </Col>
                      <Col md="6">
                        <FormGroup style={{ marginBottom: 0 }}>
                          <InputGroup className="input-group-alternative">
                            <InputGroupAddon addonType="prepend">
                              <InputGroupText>
                                <i className="ni ni-zoom-split-in" />
                              </InputGroupText>
                            </InputGroupAddon>
                            <Input
                              className="form-control-alternative"
                              placeholder="By Email"
                              type="text"
                              onChange={(e) => {
                                if (LOGINSTATE) console.log(e.target.value);
                                setTxtFilter(e.target.value);
                              }}
                            />
                          </InputGroup>
                        </FormGroup>
                      </Col>
                    </Row>
                  </CardHeader>
                  <Table className="align-items-center table-flush" responsive>
                    <thead className="thead-light">
                      <tr>
                        <th scope="col" />
                        <th scope="col">User (email&darr;)</th>
                        {/* <th scope="col">Designation</th> */}
                        <th scope="col">Organization</th>
                        <th scope="col">Status</th>
                        <th scope="col" />
                      </tr>
                    </thead>
                    <tbody>
                      {itemPageContent &&
                        itemPageContent.length > 0 &&
                        itemPageContent.map((props, i) => {
                          if (LOGINSTATE) console.log(i, props);
                          return <UserTableRow key={i} itemCount={i} itemPageSize={itemPageSize} itemCurrent={itemCurrent} id={props._id} email={props.email} role={props.urole} status={props.status.isActive} profile={props.profile ? props.profile : null} />;
                        })}
                    </tbody>
                  </Table>

                  {/* pagination */}
                  <CardFooter>
                    <Row>
                      <Col lg="12">
                        {itemPageCount && itemPageCount > 0 ? (
                          <Pagination
                            current={itemCurrent}
                            total={itemPageCount}
                            onPageChange={(current) => {
                              setItemCurrent(current);
                            }}
                          />
                        ) : null}
                      </Col>
                    </Row>
                  </CardFooter>
                </Card>
              </div>
            </Row>
          </Col>
        </Row>
      </Container>
    </>
  );
};

export default User;
