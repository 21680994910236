import { fetchLandingContent } from 'actions/masterData';
import { LOGINSTATE } from "config/config.json";
import LandingFooter from "components/Footers/LandingFooter";
import LandingNavBar from "components/Navbars/LandingNavbar";
import React, { useEffect, useState, Suspense } from "react";
import { useDispatch, useSelector } from 'react-redux';
// reactstrap components
import {
  Container,
  Row,
  Col
} from "reactstrap";
import '../assets/css/chs-custom.css';
import { routesAdmin } from "routes.js";
import { Redirect, Route, Switch, useLocation } from "react-router-dom";
import { CircularProgress } from '@material-ui/core';
import '../assets/css/react-confirm-alert-custom.css'; // Import css
import Lodash from "lodash";
import ReactGa from "react-ga";
import { APP_HITCOUNTER } from "config/config.json";

const Landing = () => {
  const mainContent = React.useRef(null);

  const [showLoading, setShowLoading] = useState(false);

  useEffect(() => {
    ReactGa.initialize(APP_HITCOUNTER);

    ReactGa.pageview("/public");
  }, []);

  // manage loading indicator
  const loadingSpinner = useSelector((state) => state.dashboard.showLoading);
  useEffect(() => {
    if (LOGINSTATE) console.log("loadingSpinner: ", loadingSpinner);
    setShowLoading(Lodash.isBoolean(loadingSpinner) ? loadingSpinner : false);
  }, [loadingSpinner]);

  const getRoutes = (routes) => {
    return routes.map((prop, key) => {
      if (prop.layout === "/public") {
        return (
          <Route
            path={prop.layout + prop.path}
            component={prop.component}
            key={key}
          />
        );
      } else {
        return null;
      }
    });
  };

  return (
    <>
      {showLoading ?
        <div className="react-confirm-alert-overlay">
          <CircularProgress style={{ color: '#af1e23' }} />
        </div> : null
      }

      <div className="shadow" style={{ backgroundColor: "#fff" }}>
        <LandingNavBar />
      </div>

      <div className="main-content" ref={mainContent}>
          <Switch>
            {getRoutes(routesAdmin)}
            <Redirect from="*" to="/public/landing" />
          </Switch>
      </div>

      <div className="shadow" style={{ backgroundColor: "#fff" }}>
        <LandingFooter />
      </div>

    </>
  );
};

export default Landing;
