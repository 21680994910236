import React, { useEffect, useState } from "react";
import { Container, Row, Col } from "reactstrap"; // reactstrap components
import "../../../assets/css/chs-custom.css";
import { useTranslation, initReactI18next, Trans } from 'react-i18next';
import { SERVER_API_URL } from "config/config.json";


const Landing = (props) => {
  const mainContent = React.useRef(null);
  const { t, i18n } = useTranslation();
  const imgServer = `${SERVER_API_URL}uploads/conceptImage/${i18n.language}_logo.png`  // Server side concept image
  const imgLocal = require("../../../assets/img/theme/CHS_Diagram.png").default // Fallback concept image

  const [isImageFound, setIsImageFound] = useState(true)


  useEffect(() => {
    // i18n.changeLanguage('en');
  }, [])

  useEffect(() => {
    setIsImageFound(true) // Make sure first check the image in the server side
  }, [i18n.language])

  return (
    <>
      <div className="main-content" ref={mainContent}>
        <div>
          <Container>
            <Row>
              <Col>
                <br />
                <br />
                <h2 className="text-center" style={{ color: "#af1e23" }}>
                  {t('landingPage.section1.title')}
                </h2>
                <br />
                <p className="text-justify" style={{ color: "#af1e23" }}>
                  <b>
                    <Trans components>landingPage.section1.paragraph1</Trans>
                  </b>
                </p>

                <p className="text-justify" style={{ color: "#af1e23" }}>
                  <b>
                    <Trans components={{ bold: <b />, italic: <i /> }}>landingPage.section1.paragraph2</Trans>
                  </b>
                </p>
                <br />
              </Col>
              <Col md="12"></Col>
            </Row>
          </Container>
        </div>
        <div className="skew-c" />

        <div style={{ backgroundColor: "#af1e23", color: "white", marginTop: "50px" }}>
          <Container>
            <Row>
              <Col md="8">
                <br />
                <h2 className="text-center" style={{ color: "#fff" }}>
                  {t('landingPage.section2.title')}
                </h2>
                <br />
                <p className="text-justify">
                  <b>
                    <Trans components>landingPage.section2.paragraph1</Trans>
                  </b>
                </p>
              </Col>
              <Col md="4">
                <br />
                {isImageFound
                  ? <img
                    alt='CHS concept'
                    src={imgServer}
                    style={{ width: "100%", height: "auto" }}
                    onError={(e) => {
                      setIsImageFound(false)
                    }}
                  />
                  : <img
                    alt='CHS concept'
                    src={imgLocal}
                    style={{ width: "100%", height: "auto" }}
                  />
                }
                <br />
                <br />
              </Col>
            </Row>
          </Container>
        </div>
        <div className="skew-cc" />
        <div style={{ backgroundColor: "#f8f9fe", marginTop: "50px" }}>
          <Container>
            <Row>
              <Col>
                <br />
                <h2 className="text-center">
                  {t('landingPage.section3.title')}
                </h2>
                <br />
                <p className="text-justify">
                  <b>
                    <Trans components>landingPage.section3.paragraph1</Trans>
                  </b>
                </p>
              </Col>
            </Row>
            <Row>
              <Col md="4"></Col>
              <Col md="8">
                <img alt="app-tools-use" src={require("../../../assets/img/theme/home.png").default} style={{ width: "100%", height: "auto" }} />
              </Col>
            </Row>
            <Row>
              <Col md="4" style={{ alignContent: "center", textAlign: "center", marginBottom: 15, verticalAlign: "center" }}>
                <img alt="app" src={require("../../../assets/img/theme/home_app.png").default} style={{ maxHeight: 68, maxWidth: "100%" }} />
              </Col>
              <Col md="5" style={{ alignContent: "center", textAlign: "center", marginBottom: 15, verticalAlign: "center" }}>
                <img alt="bmg" src={require("../../../assets/img/theme/home_bmgf.png").default} style={{ maxHeight: 68, maxWidth: "100%" }} />
              </Col>

              <Col md="3" style={{ alignContent: "center", textAlign: "center", marginBottom: 15, verticalAlign: "center" }}>
                <img alt="adpc" src={require("../../../assets/img/theme/home_adpc.jpg").default} style={{ maxHeight: 68, maxWidth: "100%" }} />
              </Col>
            </Row>
          </Container>
        </div>
      </div>
    </>
  );
};

export default Landing;
