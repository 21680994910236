import React, { useEffect, useState, useRef } from "react";
import Lodash from "lodash";

import { Grid } from "@material-ui/core";

import { Formik } from "formik";
import * as Yup from "yup";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import { TextField } from "@material-ui/core";
import { Card, CardBody, CardFooter, CardHeader, Col, Container, Row, Form, Button } from "reactstrap";
import PageHeader from "components/Headers/PageHeader.js";
import { fetchMasterDataLanguageAll } from "actions/masterData";
import { LOGINSTATE } from "../../../config/config.json";
import { MbppStyles, ChsStyles } from "constants/globalStyles";
import { createResource, updateResource } from "actions/resource";
import { confirmAlert } from "react-confirm-alert";
import { SERVER_API_URL } from "config/config.json";

const reviewSchema = Yup.object({
  Category: Yup.string().required(),
  Language: Yup.string().required(),
});

const ResourceDetail = (props) => {
  const formRef = useRef();
  const history = useHistory();
  const dispatch = useDispatch();

  const [resource, setResource] = useState({});
  const [isEdit, setIsEdit] = useState(false); // For edit resource details passed by parent page

  const [languages, setLanguages] = useState([]);
  const [languagesLogo, setLanguagesLogo] = useState([]);
  const [languagesJSON, setLanguagesJSON] = useState([]);
  const [save, setSave] = useState(false);

  const [type, setType] = useState("");
  const [lang, setLang] = useState("");

  const language = useSelector((state) => state.masterData.languageAll);
  if (LOGINSTATE) console.log("language: ", language);

  useEffect(() => {
    const tempResource = props.location.state?.resource;
    const edit = props.location.state?.edit;
    console.log("tempResource", tempResource);
    if (tempResource && !Lodash.isEmpty(tempResource)) {
      setResource(tempResource);
      if (edit === true) {
        setIsEdit(true);
      }
    }

    dispatch(fetchMasterDataLanguageAll());
  }, []);

  useEffect(() => {
    if (formRef.current && resource && !Lodash.isEmpty(resource)) {
      formRef.current?.resetForm();
      formRef.current.setFieldValue("Title", resource.title, false);
      formRef.current.setFieldValue("Description", resource.description, false);
      formRef.current.setFieldValue("Category", resource.category, false);
      formRef.current.setFieldValue("Language", resource.Language, false);
      formRef.current.setFieldValue("File", resource.file, false);
      formRef.current.setFieldValue("type", resource.type, false);
      formRef.current.setFieldValue("json", resource.json, false);
      formRef.current.setFieldValue("Primer", resource.Primer, false);
      formRef.current.setFieldValue("Logo", resource.Logo, false);
    }
  }, [resource]);


  const checkLangues = async (language) => {

    let finalLang = language;
    let finalLangLogo = language;
    let finalLangJSON = language;
    let codePrimer = [];
    let codeLogo = [];
    let codeJSON = [];
    Object.values(resource).map((item, i) => {
      if (item.category === "Primer") codePrimer.push(item.Language);
      if (item.category === "Logo") codeLogo.push(item.Language);
      if (item.category === "Localization json") codeJSON.push(item.Language);
    });

    for (let index = 0; index < codePrimer.length; index++) {
      // Check whether resource is available
      const result = await fetch(`${SERVER_API_URL}uploads/primer/${codeLogo[index]}_primer.pdf`, { method: 'HEAD' });
      if (result.ok) {
        finalLang = Lodash.reject(finalLang, { code: codePrimer[index] });
      }
    }
    setLanguages(finalLang);
    if (LOGINSTATE) console.log("finalLang", finalLang);

    for (let index = 0; index < codeLogo.length; index++) {
      // Check whether resource is available
      const result = await fetch(`${SERVER_API_URL}uploads/conceptImage/${codeLogo[index]}_logo.png`, { method: 'HEAD' });
      if (result.ok) {
        finalLangLogo = Lodash.reject(finalLangLogo, { code: codeLogo[index] });
      }
    }
    setLanguagesLogo(finalLangLogo);
    if (LOGINSTATE) console.log("finalLangLogo", finalLangLogo);

    for (let index = 0; index < codeJSON.length; index++) {
      // Check whether resource is available
      const result = await fetch(`${SERVER_API_URL}uploads/json/${codeLogo[index]}_localization.json`, { method: 'HEAD' });
      if (result.ok) {
        finalLangJSON = Lodash.reject(finalLangJSON, { code: codeJSON[index] });
      }
    }
    setLanguagesJSON(finalLangJSON);
    if (LOGINSTATE) console.log("finalLangJSON", finalLangJSON);
  }

  useEffect(() => {
    if (language) {
      checkLangues(language)
    } else {
      setLanguages(language);
    }
  }, [resource, language]);

  // handle change event of the file
  const handleFileChangeJSON = async (e, language) => {
    try {
      const file = e.target.files[0];
      if (LOGINSTATE) console.log(file);
      if (formRef.current) {
        if (file.name === language + "_localization.json") {
          setSave(true);
          formRef.current.setFieldValue("json", file, false);
        } else {
          setSave(false);
          confirmAlert({
            title: "Error",
            message: "File name is incorrect!",
            buttons: [
              {
                label: "OK",
                onClick: () => { },
              },
            ],
            closeOnEscape: false,
            closeOnClickOutside: false,
          });
        }
      }
    } catch (err) {
      if (LOGINSTATE) console.log("file error", err);
      confirmAlert({
        title: "Error",
        message: err.message,
        buttons: [
          {
            label: "OK",
            onClick: () => { },
          },
        ],
        closeOnEscape: false,
        closeOnClickOutside: false,
      });
    }
  };

  // handle change event of the file
  const handleFileChangePrimer = async (e, language) => {
    try {
      // const files = e.target.files;
      const file = e.target.files[0];
      console.log(file);
      if (file) {
        if (file.name === language + "_primer.pdf") {
          setSave(true);
          formRef.current.setFieldValue("Primer", file, false);
        } else {
          setSave(false);
          confirmAlert({
            title: "Error",
            message: "File name is incorrect!",
            buttons: [
              {
                label: "OK",
                onClick: () => { },
              },
            ],
            closeOnEscape: false,
            closeOnClickOutside: false,
          });
        }
      }
    } catch (err) {
      if (LOGINSTATE) console.log("file error", err);
      confirmAlert({
        title: "Error",
        message: err.message,
        buttons: [
          {
            label: "OK",
            onClick: () => { },
          },
        ],
        closeOnEscape: false,
        closeOnClickOutside: false,
      });
    }
  };

  // handle change event of the file
  const handleFileChangeLogo = async (e, language) => {
    try {
      // const files = e.target.files;
      const file = e.target.files[0];
      if (LOGINSTATE) console.log(file);
      if (file) {
        if (file.name === language + "_logo.png") {
          setSave(true);
          formRef.current.setFieldValue("Logo", file, false);
        } else {
          setSave(false);
          confirmAlert({
            title: "Error",
            message: "File name is incorrect!",
            buttons: [
              {
                label: "OK",
                onClick: () => { window.location.reload(true); },
              },
            ],
            closeOnEscape: false,
            closeOnClickOutside: false,
          });
        }
      }
    } catch (err) {
      if (LOGINSTATE) console.log("file error", err);
      confirmAlert({
        title: "Error",
        message: err.message,
        buttons: [
          {
            label: "OK",
            onClick: () => { },
          },
        ],
        closeOnEscape: false,
        closeOnClickOutside: false,
      });
    }
  };

  return (
    <>
      <PageHeader />

      {/* Page content */}
      <Container className="mt--7" fluid>
        <Row className="mt-5">
          <div className="col">
            <Card className="shadow">
              <CardHeader className="border-0">
                <div className="row">
                  <div className="mb-xl-0 col-11">
                    <h3 className="mb-0">APP Resources</h3>
                    <h6 className="mb-0">Resources for tools and more</h6>
                  </div>
                  <div className="mb-xl-0"></div>
                </div>
              </CardHeader>
              <CardBody>
                <Formik
                  initialValues={{
                    Title: "",
                    Description: "",
                    Category: "",
                    Language: "",
                    Language: "",
                    File: "",
                    json: "",
                    Primer: "",
                    Logo: "",
                  }}
                  validationSchema={reviewSchema}
                  onSubmit={(values, actions) => {
                    console.log("formik values: ", values);
                    let params = {
                      title: values.Title,
                      description: values.Description,
                      category: values.Category,
                      file: values.File,
                      Language: values.Language,
                      json: values.json,
                      Primer: values.Primer,
                      Logo: values.Logo,
                    };
                    let param = {
                      title: values.Title,
                      description: values.Description,
                      category: values.Category,
                      file: values.File,
                      Language: values.Language,
                      json: values.json,
                      Primer: values.Primer,
                      Logo: values.Logo,
                      resourceId: resource._id,
                    };
                    if (resource.file) {
                      dispatch(updateResource(param, history));
                    } else {
                      dispatch(createResource(params, history));
                    }
                  }}
                  innerRef={formRef}
                >
                  {(props) => (
                    <Form role="form" style={{ marginTop: -25 }} onSubmit={props.handleSubmit}>
                      <Row>
                        <Col lg="4">
                          <TextField
                            select
                            fullWidth
                            label={"Resource Category*"}
                            variant="outlined"
                            value={props.values.Category}
                            onChange={props.handleChange("Category")}
                            onBlur={props.handleChange("Category")}
                            onClick={setType(props.values.Category)}
                            SelectProps={{ native: true }}
                            style={ChsStyles.Question}
                            className={"form-control-alternative form-control-edit"}
                            error={props.touched.Category && Boolean(props.errors.Category)}
                            helperText={props.touched.Category && props.errors.Category}
                            disabled={isEdit}
                          >
                            <option key="" value=""></option>
                            <option key="Localization json" value="Localization json">
                              Localization json
                            </option>
                            <option key="Primer" value="Primer">
                              Primer
                            </option>
                            <option key="Logo" value="Logo">
                              Logo
                            </option>
                          </TextField>
                        </Col>

                        {props.values.Category === "Primer" ? (
                          <Col lg="4">
                            <TextField
                              select
                              fullWidth
                              label={"Language*"}
                              variant="outlined"
                              value={props.values.Language}
                              onChange={props.handleChange("Language")}
                              onBlur={props.handleChange("Language")}
                              onClick={setLang(props.values.Language)}
                              SelectProps={{ native: true }}
                              style={ChsStyles.Question}
                              className={"form-control-alternative form-control-edit"}
                              error={props.touched.Language && Boolean(props.errors.Language)}
                              helperText={props.touched.Language && props.errors.Language}
                              disabled={isEdit}
                            >
                              <option key="" value=""></option>
                              {languages.map((item, i) => (
                                <option key={item.code} value={item.code}>
                                  {item.name}
                                </option>
                              ))}
                            </TextField>
                          </Col>
                        ) : (
                          ""
                        )}

                        {props.values.Category === "Logo" ? (
                          <Col lg="4">
                            <TextField
                              select
                              fullWidth
                              label={"Language*"}
                              variant="outlined"
                              value={props.values.Language}
                              onChange={props.handleChange("Language")}
                              onBlur={props.handleChange("Language")}
                              onClick={setLang(props.values.Language)}
                              SelectProps={{ native: true }}
                              style={ChsStyles.Question}
                              className={"form-control-alternative form-control-edit"}
                              error={props.touched.Language && Boolean(props.errors.Language)}
                              helperText={props.touched.Language && props.errors.Language}
                              disabled={isEdit}
                            >
                              <option key="" value=""></option>
                              {languagesLogo.map((item, i) => (
                                <option key={item.code} value={item.code}>
                                  {item.name}
                                </option>
                              ))}
                            </TextField>
                          </Col>
                        ) : (
                          ""
                        )}

                        {props.values.Category === "Localization json" ? (
                          <Col lg="4">
                            <TextField
                              select
                              fullWidth
                              label={"Language*"}
                              variant="outlined"
                              value={props.values.Language}
                              onChange={props.handleChange("Language")}
                              onBlur={props.handleChange("Language")}
                              onClick={setLang(props.values.Language)}
                              SelectProps={{ native: true }}
                              style={ChsStyles.Question}
                              className={"form-control-alternative form-control-edit"}
                              error={props.touched.Language && Boolean(props.errors.Language)}
                              helperText={props.touched.Language && props.errors.Language}
                              disabled={isEdit}
                            >
                              <option key="" value=""></option>
                              {languagesJSON.map((item, i) => (
                                <option key={item.code} value={item.code}>
                                  {item.name}
                                </option>
                              ))}
                            </TextField>
                          </Col>
                        ) : (
                          ""
                        )}
                      </Row>

                      {props.values.Category === "Localization json" && props.values.Language ? (
                        <Row>
                          <Col md="8">
                            {isEdit ? (
                              <TextField
                                fullWidth
                                id="JSON"
                                name="JSON"
                                variant="outlined"
                                label="JSON"
                                value={props.values.json}
                                style={ChsStyles.Question}
                                className={"form-control-alternative form-control-edit"}
                                error={props.touched.json && Boolean(props.errors.json)}
                                helperText={props.touched.json && props.errors.json}
                                disabled={isEdit}
                              />
                            ) : (
                              <TextField
                                fullWidth
                                id="JSON"
                                name="JSON"
                                type="file"
                                variant="outlined"
                                label="JSON"
                                multiple={false}
                                onChange={(e) => {
                                  handleFileChangeJSON(e, props.values.Language);
                                }}
                                InputLabelProps={{ shrink: true }}
                                style={ChsStyles.Question}
                                className={"form-control-alternative form-control-edit"}
                                error={props.touched.json && Boolean(props.errors.json)}
                                helperText={props.touched.json && props.errors.json}
                              />
                            )}
                          </Col>

                          <Grid container spacing={1} alignItems="left" style={{ marginLeft: 15, color: "#af1e23" }}>
                            <Grid item xs={11} sm={11}>
                              <p className="small mb-4">{`File name must be "<languageCode>_localization.json". As a example : ${props.values.Language}_localization.json`}</p>
                            </Grid>
                          </Grid>
                        </Row>
                      ) : (
                        ""
                      )}

                      {props.values.Category === "Primer" && props.values.Language ? (
                        <Row>
                          <Col md="8">
                            {isEdit ? (
                              <TextField
                                fullWidth
                                id="Primer"
                                name="Primer"
                                variant="outlined"
                                label="App Primer"
                                value={props.values.Primer}
                                style={ChsStyles.Question}
                                className={"form-control-alternative form-control-edit"}
                                error={props.touched.Primer && Boolean(props.errors.Primer)}
                                helperText={props.touched.Primer && props.errors.Primer}
                                disabled={isEdit}
                              />
                            ) : (
                              <TextField
                                fullWidth
                                id="Primer"
                                name="Primer"
                                type="file"
                                variant="outlined"
                                label="App Primer"
                                multiple={false}
                                onChange={(e) => {
                                  handleFileChangePrimer(e, props.values.Language);
                                }}
                                InputLabelProps={{ shrink: true }}
                                style={ChsStyles.Question}
                                className={"form-control-alternative form-control-edit"}
                                error={props.touched.Primer && Boolean(props.errors.Primer)}
                                helperText={props.touched.Primer && props.errors.Primer}
                              />
                            )}
                          </Col>

                          <Grid container spacing={1} alignItems="left" style={{ marginLeft: 15, color: "#af1e23" }}>
                            <Grid item xs={11} sm={11}>
                              <p className="small mb-4">{`File name must be "<languageCode>_primer.pdf". As a example : ${props.values.Language}_primer.pdf`}</p>
                            </Grid>
                          </Grid>
                        </Row>
                      ) : (
                        ""
                      )}

                      {props.values.Category === "Logo" && props.values.Language ? (
                        <Row>
                          <Col md="8">
                            {isEdit ? (
                              <TextField
                                fullWidth
                                id="Logo"
                                name="Logo"
                                variant="outlined"
                                label="Landing Cocept jpg"
                                value={props.values.Logo}
                                style={ChsStyles.Question}
                                className={"form-control-alternative form-control-edit"}
                                error={props.touched.Logo && Boolean(props.errors.Logo)}
                                helperText={props.touched.Logo && props.errors.Logo}
                                disabled={isEdit}
                              />
                            ) : (
                              <TextField
                                fullWidth
                                id="Logo"
                                name="Logo"
                                type="file"
                                variant="outlined"
                                label="Landing Cocept jpg"
                                multiple={false}
                                onChange={(e) => {
                                  handleFileChangeLogo(e, props.values.Language);
                                }}
                                InputLabelProps={{ shrink: true }}
                                style={ChsStyles.Question}
                                className={"form-control-alternative form-control-edit"}
                                error={props.touched.Logo && Boolean(props.errors.Logo)}
                                helperText={props.touched.Logo && props.errors.Logo}
                              />
                            )}
                          </Col>

                          <Grid container spacing={1} alignItems="left" style={{ marginLeft: 15, color: "#af1e23" }}>
                            <Grid item xs={11} sm={11}>
                              <p className="small mb-4">{`File name must be "<languageCode>_logo.png ". As a example : ${props.values.Language}_logo.png`}</p>
                            </Grid>
                          </Grid>
                        </Row>
                      ) : (
                        ""
                      )}
                    </Form>
                  )}
                </Formik>
              </CardBody>
              <CardFooter className="py-4">
                <Row>
                  <Col lg="6" className="text-left">
                    <Button
                      style={ChsStyles.GeneralButton}
                      color="primary"
                      onClick={() => {
                        history.goBack();
                      }}
                    >
                      BACK
                    </Button>
                    <Button
                      style={ChsStyles.GeneralButton}
                      disabled={!save}
                      color="primary"
                      onClick={() => {
                        if (formRef.current) {
                          formRef.current.handleSubmit();
                        }
                      }}
                    >
                      SAVE
                    </Button>
                  </Col>
                </Row>
              </CardFooter>
            </Card>
          </div>
        </Row>
      </Container>
    </>
  );
};

export default ResourceDetail;
