import { fetchSurveyAnswerByUser } from "actions/survey";

// core components
import PageHeader from "components/Headers/PageHeader.js";
import Lodash from "lodash";
import Moment from "moment";
import React, { useEffect, useState, useRef } from "react";
import Config from "config/config.json";
import { LOGINSTATE } from "../../../config/config.json";
import { useDispatch, useSelector } from "react-redux";
import { useHistory, useLocation } from "react-router-dom";
import { TextField } from "@material-ui/core";
import { Card, CardHeader, Container, CardFooter, Row, Col, Table, Form, CardBody, Button } from "reactstrap";

// import { ComponentToPrint } from "ComponentToPrint";

import { ChsStyles, GlobalStylesColor } from "constants/globalStyles";
import { Tooltip } from "@material-ui/core";
import TimelineOutlinedIcon from "@material-ui/icons/TimelineOutlined";
import { confirmAlert } from "react-confirm-alert";
import "react-confirm-alert/src/react-confirm-alert.css"; // Import css
import { answer_status } from "helper.js";

import { roundValue } from "helper";
import { useTranslation } from "react-i18next";

// Populate version row for each survey type
const AnswerRow = ({ answer, editClick, statusClick, printClick, graphClick }) => (
  // VERSION | TITLE | DESCRIPTION | STATUS
  <tr>
    {/* {console.log("survey answer: ", answer)} */}
    {/* <td style={{ whiteSpace: "break-spaces" }}>{Moment(answer.updatedAt).format("YYYY-MM-DD hh:mm A")}</td> */}
    <td style={{ whiteSpace: "break-spaces" }}>{answer.clientTimestamp}</td>
    <td style={{ whiteSpace: "break-spaces", color: GlobalStylesColor.mbppThemeBlue }}>{answer.type}</td>
    <td style={{ whiteSpace: "break-spaces" }}>{answer.title}</td>
    {answer?.status === answer_status[0] ? <td></td> : <td style={{ whiteSpace: "break-spaces" }}>{answer.surveyScore > 0 ? Math.round((answer.surveyScore + Number.EPSILON) * 100) / 100 : ""}</td>}

    <td className="text-right">
      <div className="row">
        {answer.status === answer_status[0] ? (
          <>
            <div
              className="navbar-toggler"
              style={{ cursor: "pointer" }}
              onClick={(e) => {
                editClick(e, answer);
              }}
            >
              <i className="far fa-edit" style={{ color: GlobalStylesColor.mbppIconColor }} />
            </div>
          </>
        ) : (
          <>
            <div
              className="navbar-toggler"
              style={{ cursor: "pointer" }}
              onClick={(e) => {
                statusClick(e, answer);
              }}
            >
              <Tooltip title="View answer">
                <i className="far fa-eye" style={{ color: GlobalStylesColor.mbppIconColor }} />
              </Tooltip>
            </div>

            <div
              className="navbar-toggler"
              style={{ cursor: "pointer" }}
              onClick={(e) => {
                graphClick(e, answer);
              }}
            >
              <Tooltip title="View scores">
                {/* <i className="far fa-edit" style={{ color: GlobalStylesColor.mbppIconColor }}/> */}
                <TimelineOutlinedIcon />
              </Tooltip>
            </div>

            <div
              className="navbar-toggler"
              style={{ cursor: "pointer" }}
              onClick={(e) => {
                printClick(e, answer, false);
              }}
            >
              <Tooltip title="Print Details">
                <i className="fas fa-print" style={{ color: GlobalStylesColor.mbppIconColor }} />
              </Tooltip>
            </div>
          </>
        )}
      </div>
    </td>
  </tr>
);

// export function
const ReportAllAnswersByUser = (props) => {
  if (LOGINSTATE) console.log("props", props);
  const formRef = useRef();
  // dispatch and history
  const dispatch = useDispatch();
  const history = useHistory();
  const location = useLocation();

  const componentRef = useRef(null);
  const { t, i18n } = useTranslation();

  // get forum topics
  const answerByUser = useSelector((state) => state.survey.answerByUser);

  useEffect(() => {
    if (LOGINSTATE) console.log("selected user: ", props.location.user);
    if (props.location.user && !Lodash.isEmpty(props.location.user)) {
      if (LOGINSTATE) console.log("populate answers");
      dispatch(fetchSurveyAnswerByUser({ userId: props.location.user }, history, t));
    }
  }, []);

  const handleStatusClick = (e, answer) => {
    e.preventDefault();
    if (LOGINSTATE) console.log("--------------------- local store saving: ", answer);

    history.push({ pathname: "/registered/assessment_answer_complete", state: { answer: answer, language: "en" } });
  };

  const handleGraphClick = (e, answer) => {
    let segScore = [];
    let segName = [];
    let segLongName = [];

    Object.values(answer.segments).map((segment, i) => segScore.push(roundValue(segment.segmentScore)));

    Object.values(answer.segments).map((segmentName, j) => segName.push(segmentName.segmentCode));

    Object.values(answer.segments).map((segmentLongName, j) => segLongName.push(segmentLongName.title));

    // setSegLongName(segLongNameTemp);
    if (LOGINSTATE) console.log("segLongName", segLongName);

    e.preventDefault();
    if (LOGINSTATE) console.log("--------------------- local store saving: ", answer);
    history.push({ pathname: "/registered/assessment_answer_graph", state: { segName: segName, segScore: segScore, segLongName: segLongName, answer: answer } });
  };

  const handlePrint = (e, answer, windowPrint) => {
    let segScore = [];
    let segName = [];
    let segLongName = [];

    Object.values(answer.segments).map((segment, i) => segScore.push(roundValue(segment.segmentScore)));

    Object.values(answer.segments).map((segmentName, j) => segName.push(segmentName.segmentCode));

    Object.values(answer.segments).map((segmentLongName, j) => segLongName.push(segmentLongName.title));

    // setSegLongName(segLongNameTemp);
    if (LOGINSTATE) console.log("segLongName", segLongName);

    e.preventDefault();
    if (LOGINSTATE) console.log("--------------------- local store saving: ", answer);

    // if (windowPrint) {
    // history.push({ pathname: "/registered/assessment_print_PDF", state: { segName: segName, segScore: segScore, segLongName: segLongName, answer: answer, language: jUser?.user?.language } });
    // } else {
    // history.push({ pathname: "/print_content/assessment_print_pdf", state: { segName: segName, segScore: segScore, segLongName: segLongName, answer: answer, language: jUser?.user?.language } });
    // }

    if (windowPrint) {
      history.push({ pathname: "/registered/assessment_print_PDF", state: { segName: segName, segScore: segScore, segLongName: segLongName, answer: answer, language: jUser?.user?.language } });
    } else {
      history.push({
        pathname: "/print_content/assessment_preview",
        state: {
          segName: segName,
          segScore: segScore,
          segLongName: segLongName,
          answer: answer,
          language: jUser?.user?.language
        }
      });
    }
  };

  const Example = () => { };

  let currentUser = localStorage.getItem("userDetails");
  let jUser = JSON.parse(currentUser);
  let uRole = currentUser && jUser && jUser.user && jUser.user.urole ? jUser.user.urole : "GENERAL";
  if (LOGINSTATE) console.log("uRole", currentUser);
  return (
    <>
      <PageHeader />

      {/* Page content */}
      <Container className="mt--7" fluid style={{ backgroundColor: '#FFF' }}>
        {Lodash.isEmpty(answerByUser)
          ? <>
            <Row className="mt-5">
              <div className="col">
                <Card className="shadow">
                  <CardHeader className="border-0">
                    <div className="row">
                      <div className="mb-xl-0 col-11">
                        <h3 className="mb-0">No Data</h3>
                        <h6 className="mb-0"></h6>
                      </div>
                    </div>
                  </CardHeader>
                </Card>
              </div>
            </Row>
          </>
          : Config.TOOLS.map((tool, i) => {
            return (
              <Row key={i} className="mt-5">
                <div className="col">
                  <Card className="shadow">
                    <CardHeader className="border-0">
                      <div className="row">
                        <div className="mb-xl-0 col-11">
                          <h3 className="mb-0">{tool.type}</h3>
                          <h6 className="mb-0">{tool.description}</h6>
                        </div>
                      </div>
                    </CardHeader>

                    <Table className="align-items-center table-flush" responsive>
                      <thead className="thead-light">
                        <tr>
                          <th scope="col">COMPLETED ON</th>
                          <th scope="col">TYPE</th>
                          <th scope="col">TITLE</th>
                          <th scope="col">SCORE</th>
                          <th scope="col">ACTIONS</th>
                        </tr>
                      </thead>
                      <tbody>
                        <tr></tr>
                        {/* status: "COMPLETE" */}
                        {answerByUser &&
                          Lodash.filter(Lodash.filter(answerByUser, { status: "COMPLETE" }), { type: tool.type }).map((answer, i) => {
                            return <AnswerRow key={i} answer={answer} statusClick={handleStatusClick} handlePrint={Example} componentRef={componentRef} graphClick={handleGraphClick} printClick={handlePrint} />;
                          })}
                      </tbody>
                    </Table>
                  </Card>
                </div>
              </Row>
            );
          })}
        <Card className="shadow">
          <CardFooter className="py-4">
            <Col lg="6" className="text-left">
              <Button
                style={ChsStyles.GeneralButton}
                color="primary"
                onClick={() => {
                  history.goBack();
                }}
              >
                BACK
              </Button>
            </Col>
          </CardFooter>
        </Card>
      </Container>
    </>
  );
};

export default ReportAllAnswersByUser;
