import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useLocation, useHistory } from "react-router-dom";
import { fetchFrmTopics, fetchFrmQus, deleteFrmAns } from "actions/forum";

// reactstrap components
import {
  // Badge,
  Card,
  CardHeader,
  Table,
  Container,
  Row,
  CardFooter,
  Col,
  Button,
} from "reactstrap";
// core components
import PageHeader from "components/Headers/PageHeader.js";
import { GlobalStylesColor, ChsStyles } from "constants/globalStyles";
import { Tooltip } from "@material-ui/core";
import { confirmAlert } from "react-confirm-alert";

const ForumTableAnswerRow = ({ questionId, answerId, answer, image, description, status, answerEdit, answerStatus, answerDelete }) => (
  <tr id={answerId}>
    <td style={{ whiteSpace: "break-spaces" }}>{answer}</td>
    <td style={{ whiteSpace: "break-spaces" }}>{description}</td>
    <td className="text-right">
      <div className="row">
        <Tooltip title="Edit" arrow>
          <div
            className="navbar-toggler"
            style={{ cursor: "pointer" }}
            onClick={(e) => {
              answerEdit(e, questionId, answerId);
            }}
          >
            <i className="far fa-edit" style={{ color: GlobalStylesColor.mbppIconColor }} />
          </div>
        </Tooltip>
        <Tooltip title="Delete" arrow>
          <div
            className="navbar-toggler"
            style={{ cursor: "pointer" }}
            onClick={(e) => {
              confirmAlert({
                title: 'Confirm delete',
                buttons: [
                  { label: "No", onClick: () => { } },
                  {
                    label: "Yes", onClick: () => {
                      answerDelete(e, questionId, answerId);
                    }
                  },
                ],
                closeOnEscape: false,
                closeOnClickOutside: false
              });
            }}
          >
            <i className="far fa-times-circle" style={{ color: GlobalStylesColor.mbppIconColor }} />
          </div>
        </Tooltip>
      </div>
    </td>
  </tr>
);

const ForumQuestion = (props) => {
  // dispatch and history
  const dispatch = useDispatch();
  const history = useHistory();
  // set state topic id
  const [topicId, setTopicId] = useState(null);
  // set state topic
  const [topic, setTopic] = useState(null);
  // set state topic description
  const [topicDescription, setTopicDescription] = useState(null);
  // set state sub topic id
  const [subTopicId, setSubTopicId] = useState(null);
  // set state sub topic
  const [subTopic, setSubTopic] = useState(null);
  // set state sub topic description
  const [subTopicDescription, setSubTopicDescription] = useState(null);
  // get topic details
  const selectFrmTopic = (state) => {
    const itemArray = state.forum.frmAllTopics?.filter((item) => {
      return item._id === props.location.state?.frmTopicId;
    });
    if (itemArray && itemArray[0]) {
      return itemArray[0];
    }
  };
  const topicDetails = useSelector(selectFrmTopic);
  // get sub topic details
  const selectFrmSubTopic = (topicDetails) => {
    const itemArray =
      topicDetails?.subTopic?.length > 0 &&
      topicDetails?.subTopic?.filter((item) => {
        return item._id === props.location.state?.frmSubTopicId;
      });
    if (itemArray && itemArray[0]) {
      return itemArray[0];
    }
  };
  const subTopicDetails = selectFrmSubTopic(topicDetails);
  // get Question details
  const selectFrmQuestion = (state) => {
    const itemArray = state.forum.frmAllQus?.filter((item) => {
      return item._id === props.location.state?.frmQuestionId;
    });
    if (itemArray && itemArray[0]) {
      return itemArray[0];
    }
  };
  const frmQuestion = useSelector(selectFrmQuestion);

  useEffect(() => {
    // fetching forum topics
    dispatch(fetchFrmTopics());
    // fetching forum Questions
    dispatch(fetchFrmQus());
  }, []);

  useEffect(() => {
    if (topicDetails && topicDetails._id) {
      setTopicId(topicDetails._id);
      setTopic(topicDetails.topic);
      setTopicDescription(topicDetails.description);
    }
    if (subTopicDetails && subTopicDetails._id) {
      setSubTopicId(subTopicDetails._id);
      setSubTopic(subTopicDetails.subTopic);
      setSubTopicDescription(subTopicDetails.description);
    }
  }, [topicDetails, subTopicDetails]);

  const handleStatusAnswerClick = (e, topicId, subTopicId, questionId) => {
    e.preventDefault();
    history.push({ pathname: "/registered/faq_question_form", state: { frmTopicId: topicId, frmSubTopicId: subTopicId, frmQuestionId: questionId } });
  };

  const handleAddAnswerClick = (e, questionId) => {
    e.preventDefault();
    history.push({ pathname: "/registered/faq_answer_form", state: { frmTopicId: props.location.state?.frmTopicId, frmSubTopicId: props.location.state?.frmSubTopicId, frmQuestionId: questionId } });
  };

  const handleEditAnswerClick = (e, questionId, answerId) => {
    e.preventDefault();
    history.push({ pathname: "/registered/faq_answer_form", state: { frmTopicId: props.location.state?.frmTopicId, frmSubTopicId: props.location.state?.frmSubTopicId, frmQuestionId: questionId, frmAnswerId: answerId } });
  };

  const handleDeleteAnswerClick = (e, questionId, answerId) => {
    e.preventDefault();
    let pathParam = {
      path: "/registered/faq_questions",
      state: { frmTopicId: props.location.state?.frmTopicId, frmSubTopicId: props.location.state?.frmSubTopicId },
    };
    let formData = { forumQuestionId: questionId, forumAnswerId: answerId };
    dispatch(deleteFrmAns(formData, history, pathParam));
  };

  return (
    <>
      <PageHeader />
      {/* Page content */}
      <Container className="mt--7" fluid>
        <Row className="mt-5">
          <Col>
            {frmQuestion && Object.keys(frmQuestion).length > 0 && (
              <Card className="shadow">
                <CardHeader className="border-0">
                  <div className="row">
                    <div className="mb-xl-0 col-10">
                      <small>
                        {topic && topic} &nbsp;&nbsp;&nbsp;&gt;&nbsp;&nbsp;&nbsp;{subTopic && subTopic}&nbsp;&nbsp;&nbsp;&gt;
                      </small>
                      <h3 className="mb-0" dangerouslySetInnerHTML={{ __html: frmQuestion.question }}></h3>
                      <h6 className="mb-0">{frmQuestion.description}</h6>
                    </div>
                    <div className="mb-xl-0 col-2 text-right">
                      <Tooltip title="Add Answer" arrow>
                        <div
                          className="navbar-toggler"
                          style={{ cursor: "pointer" }}
                          onClick={(e) => {
                            handleAddAnswerClick(e, frmQuestion._id);
                          }}
                        >
                          <i className="fas fa-plus-circle" style={{ fontSize: 30, color: GlobalStylesColor.mbppThemeBlue }} />
                        </div>
                      </Tooltip>
                    </div>
                  </div>
                </CardHeader>

                <Table className="align-items-center table-flush">
                  <thead className="thead-light">
                    <tr>
                      <th scope="col">Answers</th>
                      <th scope="col">Description</th>
                      <th scope="col">Actions</th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr></tr>
                    {frmQuestion.answer.length > 0 &&
                      Object.values(frmQuestion.answer).map((props, i) => {
                        return (
                          <ForumTableAnswerRow
                            key={i}
                            questionId={frmQuestion._id}
                            answerId={props._id}
                            answer={props.answer}
                            image={props.image}
                            description={props.description}
                            status={props.status}
                            answerEdit={handleEditAnswerClick}
                            answerStatus={handleStatusAnswerClick}
                            answerDelete={handleDeleteAnswerClick}
                          />
                        );
                      })}
                  </tbody>
                </Table>

                <CardFooter className="py-4">
                  <div>
                    <Col lg="6" className="text-left">
                      <Button
                        style={ChsStyles.GeneralButton}
                        color="primary"
                        onClick={() => {
                          history.push({pathname: "/registered/faq_management"});

                        }}
                      >
                        BACK
                      </Button>
                    </Col>
                  </div>
                  <div></div>
                </CardFooter>
              </Card>
            )}
          </Col>
        </Row>
      </Container>
    </>
  );
};

export default ForumQuestion;
