import { RtlTextField } from "../../../components/Custom/RTLTextField";
import { RtlPagination } from "../../../components/Custom/RTLPagination";

// core components
import PageHeader from "components/Headers/PageHeader.js";
import CommonHeader from "components/Headers/CommonHeader";
import Lodash from "lodash";
import React, { useEffect, useState, useRef } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import { confirmAlert } from "react-confirm-alert";
import "react-confirm-alert/src/react-confirm-alert.css"; // Import css

// reactstrap components
import { Card, CardBody, CardFooter, CardHeader, CardTitle, Col, Container, Row, Label, Input, Button } from "reactstrap";
import { fetchProfileOrg } from "actions/user";
import { fetchLocalizationBySurveyTemplate, updateSurveyAnswer } from "actions/survey";
import { fetchLocalizationContent, fetchMasterDataLanguage } from "actions/masterData";
import { LOGINSTATE } from "config/config.json";
import { Formik, Field, Form } from "formik";
import { ChsStyles, GlobalStylesColor } from "constants/globalStyles";
import { institutionalQuestions, initialValues_assessmentPage, roundValue, RTL_FLAG_RIGHT, SAVE_DATE_TIME_FORMAT } from "helper";
import Moment from "moment";

import { useTranslation } from "react-i18next";

var kI = 0;

const localStyle = { question: { fontWeight: "600" }, answer: { fontStyle: "italic", color: GlobalStylesColor.mbppBlue, marginBottom: 30 } };

const AnswerContinue = (props) => {
  if (LOGINSTATE) console.log("props", props);
  // dispatch and history
  const dispatch = useDispatch();
  const history = useHistory();

  const [surveyType, setSurveyType] = useState("");
  const [surveyLanguage, setSurveyLanguage] = useState("en");

  const [surveyActive, setSurveyActive] = useState({});
  const [segmentData, setSegmentData] = useState({});
  const [segmentCount, setSegmentCount] = useState(0); // To get the maximum number of segments
  const [segmentCounter, setSegmentCounter] = useState(0); // Current active segment
  const [navigationCounter, setNavigationCounter] = useState(0); // To support footer navigation
  const [surveyOrganization, setSurveyOrganization] = useState({}); // To hold organization Q&A

  const [surveyActiveByType, setSurveyActiveByType] = useState({});
  const [segmentDataLocale, setSegmentDataLocale] = useState({});
  const [systemContentLocale, setSystemContentLocale] = useState({});

  const [showPagination, setShowPagination] = useState(true);
  const [rtlAlignment, setRtlAlignment] = useState("left");

  const { t } = useTranslation();

  // To update fomik values
  const formRef = useRef();

  const userLanguage = useSelector((state) => state.masterData.languageByCode);
  console.log("userLanguage", userLanguage);

  // Data at the page loading only
  useEffect(() => {
    const progressSurvey = props.location.state?.answer;
    if (LOGINSTATE) console.log("progressSurvey: ", progressSurvey);
    if (progressSurvey && !Lodash.isEmpty(progressSurvey)) {
      setSurveyActiveByType(progressSurvey);
    }

    // Get profile data to fill organization questions
    dispatch(fetchProfileOrg());
    dispatch(fetchLocalizationContent());

    let params = {
      user: {
        code: jUser?.user?.language,
      },
    };
    dispatch(fetchMasterDataLanguage(params.user));
  }, []);

  useEffect(() => {
    userLanguage &&
      userLanguage.language &&
      Object.values(userLanguage.language).map((lang, i) => {
        if (lang && lang.dir === "rtl") {
          setRtlAlignment("right");
        }
      });
  }, [userLanguage]);

  let currentUser = localStorage.getItem("userDetails");
  let jUser = JSON.parse(currentUser);
  let uRole = currentUser && jUser && jUser.user && jUser.user.urole ? jUser.user.urole : "GENERAL";
  if (LOGINSTATE) console.log("uRole", currentUser);

  const userProfileOrg = useSelector((state) => state.user.userProfileOrg);
  useEffect(() => {
    if (userProfileOrg?.profile?.language && !Lodash.isEmpty(userProfileOrg?.profile?.language)) {
      setSurveyLanguage(userProfileOrg?.profile?.language);
      if (LOGINSTATE) console.log("user language: ", surveyLanguage);
    }

    const organization =
      userProfileOrg && userProfileOrg?.profile && userProfileOrg?.profile?.employment && userProfileOrg?.profile?.employment?.organization && !Lodash.isEmpty(userProfileOrg?.profile?.employment?.organization)
        ? userProfileOrg?.profile?.employment?.organization
        : {};
    if (LOGINSTATE) console.log("start populating organization: ", organization);

    if (!Lodash.isEmpty(organization)) {
      const city = Lodash.find(organization.city, { _id: userProfileOrg?.profile?.employment?.linkedCity });

      // values for the answers to be filled for the survey
      let params = {
        // overall
        country: organization?.country.code,
        language: userProfileOrg?.profile?.language,

        // organization
        organization: organization?.name,
        type: organization?.type,
        visionMission: organization?.visionMission,
        legalStatus: organization?.legalStatus,
        geographicArea: organization?.geographicArea,
        functionalStructure: organization?.functionalStructure,
        unitProject: organization?.unitProject,
        unitStakeholders: organization?.unitStakeholders,

        //branch
        drmActivities: organization?.drmActivities,
        numberOfEmployees: organization?.numberOfEmployees && Lodash.isNumber(organization?.numberOfEmployees) ? organization?.numberOfEmployees : 0,
      };
      setSurveyOrganization(params);
      setSurveyType(props.location.state?.answer?.type);

      // updating the formik init value using profile details. Since it's is validated during the institute, this is ignored
      if (formRef.current) {
        formRef.current?.resetForm();
        formRef.current.setFieldValue("organization", organization?.name, false);
        formRef.current.setFieldValue("type", organization?.type, false);
        formRef.current.setFieldValue("legalStatus", organization?.legalStatus.status === true ? "Yes" : "No", false);
        formRef.current.setFieldValue("legalMandate", organization?.legalStatus.mandate, false);

        formRef.current.setFieldValue("geographicArea", organization?.geographicArea, false);
        if (organization?.numberOfEmployees) formRef.current.setFieldValue("numberOfEmployees", organization?.numberOfEmployees && Lodash.isNumber(organization?.numberOfEmployees) ? organization?.numberOfEmployees : 0, false);
        formRef.current.setFieldValue("drmActivities", organization?.drmActivities, false);
        formRef.current.setFieldValue("visionMission", organization?.visionMission === true ? "Yes" : "No", false);
        formRef.current.setFieldValue("structure", organization?.structure === true ? "Yes" : "No", false);
        formRef.current.setFieldValue("unitProject", organization?.unitProject === true ? "Yes" : "No", false);
        formRef.current.setFieldValue("unitStakeholders", organization?.unitStakeholders === true ? "Yes" : "No", false);

        formRef.current.setFieldValue("country", organization?.country.code, false);
        formRef.current.setFieldValue("language", userProfileOrg?.profile?.language, false);
      } else {
        if (LOGINSTATE) console.log("in else");
      }
    }
    if (LOGINSTATE) console.log("userProfileOrg", userProfileOrg);
  }, [userProfileOrg]);

  // get system content
  const systemContentAll = useSelector((state) => state.masterData.localizationContentAll);
  useEffect(() => {
    if (LOGINSTATE) console.log("systemContent from server: ", systemContent);
    const systemContent = Lodash.find(systemContentAll, { languageCode: props?.location?.state?.language });
    if (systemContent && systemContent !== undefined && systemContent !== null) {
      setSystemContentLocale(systemContent.assessmentPage);
    } else {
      setSystemContentLocale(initialValues_assessmentPage);
    }
  }, [systemContentAll]);

  useEffect(() => {
    // Get the active survey if information is exist
    if (surveyType && surveyType.length >= 3 && surveyLanguage && surveyLanguage.length >= 2) {
      // get active survey details
      const params = { type: surveyType, languageCode: surveyLanguage };
      if (LOGINSTATE) console.log("fetching active survey with: ", params);
      // dispatch(fetchActiveSurveyByType(params));
    }
  }, [surveyLanguage, surveyType]);

  useEffect(() => {
    if (LOGINSTATE) console.log("surveyActiveByType: ", surveyActiveByType);

    // Check whether the data is available, then populate accordingly
    if (surveyActiveByType && surveyActiveByType && !Lodash.isEmpty(surveyActiveByType) && surveyActiveByType.segments && surveyActiveByType.segments[0]) {
      // get locales
      dispatch(fetchLocalizationBySurveyTemplate({ surveyId: surveyActiveByType.surveyId }, history));

      // Set basic data to use
      setSurveyActive(surveyActiveByType);
      setSegmentData(surveyActiveByType.segments);
      setSegmentCount(Lodash.isEmpty(surveyActiveByType.segments) ? 0 : surveyActiveByType.segments.length);
      setSegmentCounter(0);

      if (LOGINSTATE) console.log("segments available: ", surveyActiveByType);
    } else {
      if (LOGINSTATE) console.log("no segment data");
    }
  }, [surveyActiveByType]);

  // get the survey localizations
  const surveyLocalizationAll = useSelector((state) => state.survey.surveyLocalizationAll);
  useEffect(() => {
    if (LOGINSTATE) console.log("survey Locales : ", surveyLocalizationAll);
    const surveyLocaleForTemplate = Lodash.find(surveyLocalizationAll, { languageCode: props?.location?.state?.language });

    if (!Lodash.isEmpty(surveyLocaleForTemplate) && surveyLocaleForTemplate?.published && surveyLocaleForTemplate?.published === true) {
      const segmentsLocale = surveyLocaleForTemplate?.segments ? surveyLocaleForTemplate?.segments : segmentData;
      setSegmentDataLocale(segmentsLocale);
      if (LOGINSTATE) console.log("segmentDataLocale : ", segmentDataLocale);
    }
  }, [surveyLocalizationAll]);

  function handleChange1(e, props) {
    props.Formik.handleSubmit(e.target.value);
  }

  const AnswerUiModel = ({ item, order, itemLocale, rtlAlignment }) => (
    <>
      <Formik
        initialValues={{
          Score: Lodash.isNumber(item.score) && item.score >= 0 ? item.score : -1,
          Remark: item.remark ? item.remark : "",
        }}
        // validationSchema={{}}
        onSubmit={(values, actions) => { }}
      >
        {(props) => (
          <Form role="form">
            {rtlAlignment === RTL_FLAG_RIGHT ? (
              <Row style={{ marginBottom: 30 }}>
                <Col>
                  <Row>
                    <Col>
                      <Label check style={{ fontWeight: "600" }}>
                        {itemLocale && itemLocale.question ? itemLocale.question : item.question}
                      </Label>
                      <Label check style={{ fontStyle: "italic" }}>
                        {itemLocale && itemLocale.questionNote ? itemLocale.questionNote : item.questionNote}
                      </Label>
                    </Col>

                    <div style={{ marginRight: 15 }}>
                      <Label check style={{ fontWeight: "600" }}>
                        :{(segmentCounter + 1) + "." + (order + 1)}
                      </Label>
                    </div>
                  </Row>

                  <Row>
                    <Col lg="12" style={{ marginTop: 5, marginBottom: 5 }}>
                      <div style={(rtlAlignment === RTL_FLAG_RIGHT) ? { marginRight: -5 } : { marginLeft: 20 }}
                        onChange={props.handleChange("Score")}
                        onBlur={saveAnswer(item, order, props.values.Score, props.values.Remark)}>

                        <div>
                          {(rtlAlignment === RTL_FLAG_RIGHT) && (
                            <span>
                              <Label check style={{ fontWeight: "400", marginRight: 5 }}>
                                {systemContentLocale?.app_assessment_answer_opt2}
                              </Label>
                              <Label check style={{ fontWeight: "400", marginRight: 30 }}>
                                {".1"}
                              </Label>
                            </span>
                          )}
                          <Input type="radio" value={1} name="score" defaultChecked={1 === props.values.Score} />
                          {(rtlAlignment !== "right") && <Label check style={{ fontWeight: "400" }}>{" 1. " + systemContentLocale?.app_assessment_answer_opt2}</Label>}
                        </div>
                        <div>
                          {(rtlAlignment === RTL_FLAG_RIGHT) && (
                            <span>
                              <Label check style={{ fontWeight: "400", marginRight: 5 }}>
                                {systemContentLocale?.app_assessment_answer_opt3}
                              </Label>
                              <Label check style={{ fontWeight: "400", marginRight: 30 }}>
                                {".2"}
                              </Label>
                            </span>
                          )}
                          <Input type="radio" value={2} name="score" defaultChecked={2 === props.values.Score} />
                          {(rtlAlignment !== "right") && <Label check style={{ fontWeight: "400" }}>{" 2. " + systemContentLocale?.app_assessment_answer_opt3}</Label>}
                        </div>
                        <div>
                          {(rtlAlignment === RTL_FLAG_RIGHT) && (
                            <span>
                              <Label check style={{ fontWeight: "400", marginRight: 5 }}>
                                {systemContentLocale?.app_assessment_answer_opt4}
                              </Label>
                              <Label check style={{ fontWeight: "400", marginRight: 30 }}>
                                {".3"}
                              </Label>
                            </span>
                          )}
                          <Input type="radio" value={3} name="score" defaultChecked={3 === props.values.Score} />
                          {(rtlAlignment !== "right") && <Label check style={{ fontWeight: "400" }}>{" 3. " + systemContentLocale?.app_assessment_answer_opt4}</Label>}
                        </div>
                        <div>
                          {(rtlAlignment === RTL_FLAG_RIGHT) && (
                            <span>
                              <Label check style={{ fontWeight: "400", marginRight: 5 }}>
                                {systemContentLocale?.app_assessment_answer_opt5}
                              </Label>
                              <Label check style={{ fontWeight: "400", marginRight: 30 }}>
                                {".4"}
                              </Label>
                            </span>
                          )}
                          <Input type="radio" value={4} name="score" defaultChecked={4 === props.values.Score} />
                          {(rtlAlignment !== "right") && <Label check style={{ fontWeight: "400" }}>{" 4. " + systemContentLocale?.app_assessment_answer_opt5}</Label>}
                        </div>
                        <div>
                          {(rtlAlignment === RTL_FLAG_RIGHT) && (
                            <span>
                              <Label check style={{ fontWeight: "400", marginRight: 5 }}>
                                {systemContentLocale?.app_assessment_answer_opt6}
                              </Label>
                              <Label check style={{ fontWeight: "400", marginRight: 30 }}>
                                {".5"}
                              </Label>
                            </span>
                          )}
                          <Input type="radio" value={5} name="score" defaultChecked={5 === props.values.Score} />
                          {(rtlAlignment !== "right") && <Label check style={{ fontWeight: "400" }}>{" 5. " + systemContentLocale?.app_assessment_answer_opt6}</Label>}
                        </div>
                        <div>
                          {(rtlAlignment === RTL_FLAG_RIGHT) && <Label check style={{ fontWeight: "400", marginRight: 30 }}>{"  " + systemContentLocale?.app_assessment_answer_opt1}</Label>}
                          <Input type="radio" value={0} name="score" defaultChecked={0 === props.values.Score} />
                          {(rtlAlignment !== "right") && <Label check style={{ fontWeight: "400" }}>{systemContentLocale?.app_assessment_answer_opt1 + "  "}</Label>}
                        </div>

                      </div>
                    </Col>
                  </Row>
                  <Row>
                    <Col lg="12">
                      <RtlTextField
                        fullWidth
                        rtol={rtlAlignment === RTL_FLAG_RIGHT}
                        label={systemContentLocale.answer_remark}
                        variant="outlined"
                        multiline
                        rows={2}
                        value={props.values.Remark}
                        onChange={props.handleChange("Remark")}
                        onBlur={props.handleSubmit}
                        style={{ marginTop: 10, marginBottom: 10 }}
                      />
                    </Col>
                  </Row>
                </Col>
              </Row>
            ) : (
              <Row style={{ marginBottom: 30 }}>
                <div style={{ marginLeft: 10 }}>
                  <Label check style={{ fontWeight: "600" }}>
                    {segmentCounter + 1 + "." + (order + 1)}:
                  </Label>
                </div>
                <Col>
                  <Row>
                    <Col lg="12">
                      <Label check style={{ fontWeight: "600" }}>
                        {itemLocale && itemLocale.question ? itemLocale.question : item.question}
                      </Label>
                      <Label check style={{ fontStyle: "italic" }}>
                        {itemLocale && itemLocale.questionNote ? itemLocale.questionNote : item.questionNote}
                      </Label>
                    </Col>
                  </Row>

                  <Row>
                    <Col lg="12">
                      <div style={{ marginLeft: 20, marginTop: 5, marginBottom: 5 }} onChange={props.handleChange("Score")} onBlur={saveAnswer(item, order, props.values.Score, props.values.Remark)}>
                        <Label check style={{ fontWeight: "400" }}>
                          <Input type="radio" value={1} name="score" defaultChecked={1 === props.values.Score} />
                          {" 1. " + systemContentLocale?.app_assessment_answer_opt2}
                        </Label>
                        <br />
                        <Label check style={{ fontWeight: "400" }}>
                          <Input type="radio" value={2} name="score" defaultChecked={2 === props.values.Score} />
                          {" 2. " + systemContentLocale?.app_assessment_answer_opt3}
                        </Label>
                        <br />
                        <Label check style={{ fontWeight: "400" }}>
                          <Input type="radio" value={3} name="score" defaultChecked={3 === props.values.Score} />
                          {" 3. " + systemContentLocale?.app_assessment_answer_opt4}
                        </Label>
                        <br />
                        <Label check style={{ fontWeight: "400" }}>
                          <Input type="radio" value={4} name="score" defaultChecked={4 === props.values.Score} />
                          {" 4. " + systemContentLocale?.app_assessment_answer_opt5}
                        </Label>
                        <br />
                        <Label check style={{ fontWeight: "400" }}>
                          <Input type="radio" value={5} name="score" defaultChecked={5 === props.values.Score} />
                          {" 5. " + systemContentLocale?.app_assessment_answer_opt6}
                        </Label>
                        <br />
                        <Label check style={{ fontWeight: "400" }}>
                          <Input type="radio" value={0} name="score" defaultChecked={0 === props.values.Score} />
                          {"  " + systemContentLocale?.app_assessment_answer_opt1}
                        </Label>
                      </div>
                    </Col>
                  </Row>
                  <Row>
                    <Col lg="12">
                      <RtlTextField
                        fullWidth
                        rtol={rtlAlignment === RTL_FLAG_RIGHT}
                        label={systemContentLocale.answer_remark}
                        variant="outlined"
                        multiline
                        rows={2}
                        value={props.values.Remark}
                        onChange={props.handleChange("Remark")}
                        onBlur={props.handleSubmit}
                        style={{ marginTop: 10, marginBottom: 10 }}
                      />
                    </Col>
                  </Row>
                </Col>
              </Row>
            )}
          </Form>
        )}
      </Formik>
    </>
  );

  const saveAnswer = (item, order, score, remark) => {
    // Locally save the survey
    if (LOGINSTATE) console.log("save to local template @ [" + order + "] - score: " + score + " - remark: " + remark);

    let updatedItem = item;
    // item["score"] = parseInt(values.Score, 10);
    item["score"] = Number(score);
    item["remark"] = remark;

    updateLocalSurvey(updatedItem, order);
  };

  // save the survey's segment details locally
  const updateLocalSurvey = (item, order) => {
    // Locally save the survey
    let segments = segmentData;
    segments[segmentCounter].questions[order] = item;
    setSegmentData(segments);
  };

  const saveSurvey = (isCompleting) => {
    if (LOGINSTATE) console.log("saved surveyOrganization: ", surveyOrganization);
    if (LOGINSTATE) console.log("saved surveyActive: ", surveyActive);
    if (LOGINSTATE) console.log("saved segmentData: ", segmentData);

    if (isCompleting) {
      if (LOGINSTATE) console.log("--------------------------------------");
      // Calculation of scores
      let segmentScore = 0;
      let segmentCount = 0;
      let segmentStatus = [];

      for (const segment of surveyActive.segments) {
        let questionScore = 0;
        let questionCount = 0;
        let questionAttempt = 0;

        for (const question of segment.questions) {
          if (LOGINSTATE) console.log("....question: ", question);

          if (Lodash.isNumber(question?.score) && question?.score >= 0) {
            questionAttempt++;
            // question is relevant to the user
            if (question.score > 0) {
              questionScore = questionScore + question.score;
              questionCount++;
              if (LOGINSTATE) console.log("....questionScore: " + question.score + " with average: " + questionScore + " for " + questionCount);
            }
          }
        }

        let questionAvg = questionCount > 0 ? questionScore / questionCount : 0; // to avoid divide by zero
        segment["segmentScore"] = questionAvg;

        segmentScore = segmentScore + questionScore;
        segmentCount = segmentCount + questionCount;

        if (LOGINSTATE) console.log("....segmentScore: " + segmentScore + " segmentCount: " + segmentCount + "questionAvg: " + questionAvg + " questionScore: " + questionScore + " questionCount: " + questionCount);

        // Check whether all the questions had been answered
        if (questionAttempt < segment.questions.length) {
          // segmentStatus = segmentStatus + (Lodash.isEmpty(segmentStatus) ? segment.title : " | " + segment.title);
          if (!Lodash.isEmpty(segmentDataLocale)) {
            let locale = Lodash.find(segmentDataLocale, { segmentCode: segment.segmentCode });
            segmentStatus.push(locale.title);
            // console.log("locale", locale);
          } else {
            segmentStatus.push(segment.title);
          }

          // show error one by one, or use the total errors together at the end
          confirmAlert({
            title: systemContentLocale.app_assessment_incomplete_title,
            message: segmentStatus,
            buttons: [
              {
                label: systemContentLocale.app_ok,
                onClick: () => { },
              },
            ],
            closeOnEscape: false,
            closeOnClickOutside: false,
          });

          return;
        }
      }

      let segmentAvg = segmentCount > 0 ? segmentScore / segmentCount : 0; // to avoid divide by zero
      surveyActive["surveyScore"] = segmentAvg;
      if (LOGINSTATE) console.log("....surveyScore: " + segmentAvg);
      if (LOGINSTATE) console.log("....updated survey: ", surveyActive);
      if (LOGINSTATE) console.log("--------------------------------------");

      // there are incomplete segments, hence can't complete the survey
      // below shows all the segments. however there might not enough space
      if (!Lodash.isEmpty(segmentStatus)) {
        confirmAlert({
          title: systemContentLocale.app_assessment_incomplete_title,
          message: segmentStatus[0],
          buttons: [
            {
              label: systemContentLocale.app_ok,
              onClick: () => { },
            },
          ],
          closeOnEscape: false,
          closeOnClickOutside: false,
        });

        return;
      }
    }

    let insQuestions = [
      { code: "organization", question: institutionalQuestions.organization, answer: surveyOrganization?.organization, remark: "No" },
      { code: "type", question: institutionalQuestions.type, answer: surveyOrganization?.type, remark: "No" },
      { code: "legalStatus", question: institutionalQuestions.legalStatus, answer: surveyOrganization?.legalStatus?.status === true ? "Yes" : "No", remark: surveyOrganization?.legalStatus?.mandate },

      { code: "visionMission", question: institutionalQuestions.visionMission, answer: surveyOrganization?.visionMission?.status === true ? "Yes" : "No", remark: surveyOrganization?.visionMission?.remark },
      { code: "geographicArea", question: institutionalQuestions.geographicArea, answer: surveyOrganization?.geographicArea, remark: "No" },
      { code: "functionalStructure", question: institutionalQuestions.functionalStructure, answer: surveyOrganization?.functionalStructure?.status === true ? "Yes" : "No", remark: surveyOrganization?.functionalStructure?.remark },
      { code: "unitProject", question: institutionalQuestions.unitProject, answer: surveyOrganization?.unitProject?.status === true ? "Yes" : "No", remark: surveyOrganization?.unitProject?.remark },
      { code: "unitStakeholders", question: institutionalQuestions.unitStakeholders, answer: surveyOrganization?.unitStakeholders?.status === true ? "Yes" : "No", remark: surveyOrganization?.unitStakeholders?.remark },

      { code: "numberOfOrganizationEmployees", question: institutionalQuestions.numberOfEmployees, answer: surveyOrganization?.numberOfOrganizationEmployees?.number + "", remark: surveyOrganization?.numberOfOrganizationEmployees?.remark },
      { code: "drmActivities", question: institutionalQuestions.drmActivities, answer: surveyOrganization?.drmActivities, remark: "No" },
    ];

    let answer = {
      answerId: surveyActive._id,
      surveyId: surveyActive.surveyId,
      countryCode: surveyOrganization.country,
      languageCode: surveyOrganization.language,
      type: surveyActive.type,
      title: surveyActive.title,
      description: surveyActive.description,
      institutionalQuestions: insQuestions,
      segments: surveyActive.segments,
      status: isCompleting ? "COMPLETE" : "PROGRESS",
      clientTimestamp: Moment().format(SAVE_DATE_TIME_FORMAT) // Should not UTC
    };

    if (Lodash.isNumber(surveyActive.surveyScore)) answer["surveyScore"] = roundValue(surveyActive.surveyScore);

    if (!Lodash.isEmpty(answer)) {
      dispatch(updateSurveyAnswer(answer, history, systemContentLocale, t));
    }
  };

  const scrollToTop = () => {
    window.scrollTo(0, 0);
  };

  return (
    <>
      <CommonHeader hType="takeAssessment" />

      {/* Page content */}
      <Container className="mt--7" fluid style={{ textAlign: rtlAlignment }}>
        {/* Card stats */}
        <Row className="mt-5">
          <Col lg="6" xl="4" style={{ display: Lodash.isEmpty(surveyOrganization) ? "block" : "none" }}>
            <Card className="card-stats mb-4 mb-xl-0" onClick={(e) => { }}>
              <CardBody>
                <Row>
                  <div className="col">
                    {/* <CardTitle tag="h5" className="text-uppercase text-muted mb-0">
                      {systemContentLocale?.chs_tool}
                    </CardTitle> */}
                    <span className="h2 font-weight-bold mb-0">{systemContentLocale?.insitutional_details}</span>
                  </div>
                  <Col className="col-auto">
                    <div className="icon icon-shape bg-danger text-white rounded-circle shadow">
                      <i className="fas fa-clipboard-check" style={{ fontSize: 32, color: GlobalStylesColor.chsChsat }} />
                    </div>
                  </Col>
                </Row>
                <p className="mt-3 mb-0 text-muted text-sm">
                  <span className="">Institutional Details of the User</span>
                </p>
              </CardBody>
            </Card>
          </Col>

          <Col lg="6" xl="4" style={{ display: Lodash.isEmpty(surveyType) || surveyType === "CHSAT" ? "block" : "none" }}>
            <Card className="card-stats mb-4 mb-xl-0" onClick={(e) => { }} style={{ cursor: "pointer" }}>
              <CardBody>
                <Row>
                  <div className="col">
                    {/* <CardTitle tag="h5" className="text-uppercase text-muted mb-0">
                      {systemContentLocale.chs_tool}
                    </CardTitle> */}
                    <span className="h2 font-weight-bold mb-0">{systemContentLocale.chsat}</span>
                  </div>
                  <Col className="col-auto">
                    <div className="icon icon-shape text-white rounded-circle shadow" style={{ backgroundColor: GlobalStylesColor.chsChsat }}>
                      <i className="fas fa-clipboard-check" style={{ fontSize: 32 }} />
                    </div>
                  </Col>
                </Row>
                <p className="mt-3 mb-0 text-muted text-sm">
                  <span className="">{systemContentLocale.chs_short}</span>
                </p>
              </CardBody>
            </Card>
          </Col>
          <Col lg="6" xl="4" style={{ display: Lodash.isEmpty(surveyType) || surveyType === "ICAT" ? "block" : "none" }}>
            <Card className="card-stats mb-4 mb-xl-0" onClick={(e) => { }} style={{ cursor: "pointer" }}>
              <CardBody>
                <Row>
                  <div className="col">
                    {/* <CardTitle tag="h5" className="text-uppercase text-muted mb-0">
                      {systemContentLocale.chs_tool}
                    </CardTitle> */}
                    <span className="h2 font-weight-bold mb-0">{systemContentLocale.icat}</span>
                  </div>
                  <Col className="col-auto">
                    <div className="icon icon-shape text-white rounded-circle shadow" style={{ backgroundColor: GlobalStylesColor.chsIcat }}>
                      <i className="fas fa-university" style={{ fontSize: 32 }} />
                    </div>
                  </Col>
                </Row>
                <p className="mt-3 mb-0 text-muted text-sm">
                  <span className="">{systemContentLocale.icat_short} &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; </span>
                </p>
              </CardBody>
            </Card>
          </Col>
          <Col lg="6" xl="4" style={{ display: Lodash.isEmpty(surveyType) || surveyType === "TIE" ? "block" : "none" }}>
            <Card className="card-stats mb-4 mb-xl-0" onClick={(e) => { }} style={{ cursor: "pointer" }}>
              <CardBody>
                <Row>
                  <div className="col">
                    {/* <CardTitle tag="h5" className="text-uppercase text-muted mb-0">
                      {systemContentLocale.chs_tool}
                    </CardTitle> */}
                    <span className="h2 font-weight-bold mb-0">{systemContentLocale.tie}</span>
                  </div>
                  <Col className="col-auto">
                    <div className="icon icon-shape text-white rounded-circle shadow" style={{ backgroundColor: GlobalStylesColor.chsTie }}>
                      <i className="fas fa-hands-helping" style={{ fontSize: 32 }} />
                    </div>
                  </Col>
                </Row>
                <p className="mt-3 mb-0 text-muted text-sm">
                  <span className="">{systemContentLocale.tie_short}</span>
                </p>
              </CardBody>
            </Card>
          </Col>
        </Row>

        <br />

        {/* Ask to select a survey with current organization profile*/}
        <Row style={{ display: Lodash.isEmpty(surveyOrganization) ? "block" : "none" }}>
          <div className="col">
            <Card className="shadow">
              {/* survey header */}
              <CardHeader className="border-0">
                <div className="row">
                  <div className="mb-xl-0 col-10">
                    <small>{systemContentLocale.chs_app}</small>
                    <h3 className="mb-0">{systemContentLocale.insitutional_details}</h3>
                    <h6 className="mb-0">Below are the organization information for the survey. Update your profile to keep it up to date.</h6>
                  </div>
                  <div className="mb-xl-0 col-2">
                    <Button
                      style={{ ...ChsStyles.GeneralButton, marginBottom: 5, marginTop: 5 }}
                      color="primary"
                      onClick={(e) => {
                        e.preventDefault();
                        history.push({ pathname: "/registered/user_profile" });
                      }}
                    >
                      {systemContentLocale.update}
                    </Button>
                  </div>
                </div>
              </CardHeader>
              {/* survey content */}
              <CardBody>
                <Formik
                  initialValues={{
                    organization: "",
                    type: "",
                    legalStatus: "No",
                    legalMandate: "",
                    geographicArea: "",
                    numberOfEmployees: 0,
                    drmActivities: "",
                    visionMission: "No",
                    structure: "No",
                    unitProject: "No",
                    unitStakeholders: "No",
                    country: "",
                  }}
                  onSubmit={(values, actions) => {
                    if (LOGINSTATE) console.log("values: ", values);
                  }}
                  innerRef={formRef}
                >
                  {(props) => (
                    <Form role="form" onSubmit={props.handleSubmit}>
                      <div>
                        <Label check style={localStyle.question}>
                          {institutionalQuestions.organization}
                        </Label>
                        <br />
                        <Label check style={localStyle.remark}>
                          {props.values.organization}
                        </Label>
                      </div>
                      <div>
                        <Label check style={localStyle.question}>
                          {institutionalQuestions.type}
                        </Label>
                        <br />
                        <Label check style={localStyle.remark}>
                          {props.values.type}
                        </Label>
                      </div>
                      <div>
                        <Label check style={localStyle.question}>
                          {institutionalQuestions.legalStatus}
                        </Label>
                        <br />
                        <Label check style={localStyle.answer}>
                          answer: {props.values.legalStatus}
                        </Label>
                        <br />
                        <Label check style={localStyle.remark}>
                          mandate: {props.values.legalMandate}
                        </Label>
                      </div>

                      <div>
                        <Label check style={localStyle.question}>
                          {institutionalQuestions.geographicArea}
                        </Label>
                        <br />
                        <Label check style={localStyle.remark}>
                          {props.values.geographicArea}
                        </Label>
                      </div>
                      <div>
                        <Label check style={localStyle.question}>
                          {institutionalQuestions.numberOfEmployees}
                        </Label>
                        <br />
                        <Label check style={localStyle.answer}>
                          answer: {props.values.numberOfEmployees}
                        </Label>
                        <br />
                        <Label check style={localStyle.remark}>
                          remark: {props.values.numberOfEmployees_remark}
                        </Label>
                      </div>
                      <div>
                        <Label check style={localStyle.question}>
                          {institutionalQuestions.drmActivities}
                        </Label>
                        <br />
                        <Label check style={localStyle.remark}>
                          {props.values.drmActivities}
                        </Label>
                      </div>
                      <div>
                        <Label check style={localStyle.question}>
                          {institutionalQuestions.visionMission}
                        </Label>
                        <br />
                        <Label check style={localStyle.answer}>
                          answer: {props.values.visionMission}
                        </Label>
                        <br />
                        <Label check style={localStyle.remark}>
                          remark: {props.values.visionMission_remark}
                        </Label>
                      </div>
                      <div>
                        <Label check style={localStyle.question}>
                          {institutionalQuestions.functionalStructure}
                        </Label>
                        <br />
                        <Label check style={localStyle.answer}>
                          answer: {props.values.functionalStructure}
                        </Label>
                        <br />
                        <Label check style={localStyle.remark}>
                          remark: {props.values.functionalStructure_remark}
                        </Label>
                      </div>
                      <div>
                        <Label check style={localStyle.question}>
                          {institutionalQuestions.unitProject}
                        </Label>
                        <br />
                        <Label check style={localStyle.answer}>
                          answer: {props.values.unitProject}
                        </Label>
                        <br />
                        <Label check style={localStyle.remark}>
                          remark: {props.values.unitProject_remark}
                        </Label>
                      </div>
                      <div>
                        <Label check style={localStyle.question}>
                          {institutionalQuestions.unitStakeholders}
                        </Label>
                        <br />
                        <Label check style={localStyle.answer}>
                          answer: {props.values.unitStakeholders}
                        </Label>
                        <br />
                        <Label check style={localStyle.remark}>
                          remark: {props.values.unitStakeholders_remark}
                        </Label>
                      </div>
                    </Form>
                  )}
                </Formik>
              </CardBody>
              {/* card footer */}
              <CardFooter className="py-4">
                <div className="mb-xl-0 col-6">
                  <Button
                    style={{ ...ChsStyles.GeneralButton, marginBottom: 5, marginTop: 5 }}
                    color="primary"
                    onClick={() => {
                      formRef.current?.submitForm();
                    }}
                  >
                    {systemContentLocale.app_next}
                  </Button>
                </div>
              </CardFooter>
            </Card>
          </div>
        </Row>

        {/* survey answering */}
        <Row style={{ display: Lodash.isEmpty(surveyType) ? "none" : "block" }}>
          <div className="col">
            <Card className="shadow">
              {/* survey header */}
              <CardHeader className="border-0">
                {rtlAlignment === RTL_FLAG_RIGHT ? (
                  <div className="row">
                    <div className="mb-xl-0 col-12" >
                      <small>{segmentDataLocale && segmentDataLocale.title ? segmentDataLocale.title : surveyActive.title}</small>
                      <h3 className="mb-0">
                        {!Lodash.isEmpty(segmentData) ? (segmentDataLocale[segmentCounter] && segmentDataLocale[segmentCounter].title ? segmentDataLocale[segmentCounter].title : segmentData[segmentCounter].title) : ""}
                        {` .${segmentCounter + 1}`}
                      </h3>
                      <h6 className="mb-0">{systemContentLocale.assessment_answer}</h6>
                    </div>
                  </div>
                ) : (
                  <div className="row">
                    <div className="mb-xl-0 col-10">
                      <small>{segmentDataLocale && segmentDataLocale.title ? segmentDataLocale.title : surveyActive.title}</small>
                      <h3 className="mb-0">
                        {`${segmentCounter + 1}. `}
                        {!Lodash.isEmpty(segmentData) ? (segmentDataLocale[segmentCounter] && segmentDataLocale[segmentCounter].title ? segmentDataLocale[segmentCounter].title : segmentData[segmentCounter].title) : ""}
                      </h3>
                      <h6 className="mb-0">{systemContentLocale.assessment_answer}</h6>
                    </div>
                  </div>
                )}
              </CardHeader>

              {/* survey content */}
              <CardBody>
                {/* Update the code go get correct questionnaire */}
                {!Lodash.isEmpty(segmentData) &&
                  Object.values(segmentData[segmentCounter].questions).map((item, i) => {
                    return (
                      <AnswerUiModel
                        key={i}
                        item={item}
                        order={i}
                        itemLocale={
                          segmentDataLocale && segmentDataLocale[segmentCounter] && segmentDataLocale[segmentCounter]?.questions && segmentDataLocale[segmentCounter]?.questions[i] ? segmentDataLocale[segmentCounter]?.questions[i] : {}
                        }
                        rtlAlignment={rtlAlignment}
                      />
                    );
                  })}
              </CardBody>

              {/* survey footer */}
              <CardFooter className="py-4">
                <Row>
                  <Col lg="12" className="text-center">
                    <div aria-label="..." style={{ marginBottom: 5, marginTop: 5, overflowX: "auto" }}>
                      {showPagination && segmentCount && segmentCount > 0 ? (
                        <RtlPagination
                          rtol={rtlAlignment === RTL_FLAG_RIGHT}
                          current={segmentCounter + 1}
                          total={segmentCount}
                          onPageChange={(current) => {
                            setShowPagination(false);
                            setSegmentCounter(current - 1);
                            scrollToTop();
                            setShowPagination(true);
                          }}
                        />
                      ) : null}
                    </div>
                  </Col>
                </Row>
                <Row>
                  <Col lg="6" className="text-left" style={{ marginBottom: 10 }}>
                    <Button
                      style={ChsStyles.GeneralButton}
                      color="primary"
                      onClick={() => {
                        saveSurvey(false);
                      }}
                    >
                      {systemContentLocale.app_partly_save}
                    </Button>
                  </Col>
                  <Col lg="6" className="text-right" style={{ marginBottom: 10 }}>
                    {segmentCounter + 1 >= segmentCount ? (
                      <Button
                        style={ChsStyles.GeneralButton}
                        color="primary"
                        onClick={() => {
                          saveSurvey(true);
                        }}
                      >
                        {systemContentLocale.app_complete}
                      </Button>
                    ) : (
                      <Button
                        style={ChsStyles.GeneralButton}
                        color="primary"
                        onClick={() => {
                          setSegmentCounter(segmentCounter + 1);
                          scrollToTop();
                        }}
                      >
                        {systemContentLocale.app_next}
                      </Button>
                    )}
                  </Col>
                </Row>
              </CardFooter>
            </Card>
          </div>
        </Row>
      </Container>
    </>
  );
};

export default AnswerContinue;
