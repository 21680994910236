import { fetchRecommendationsBySurveyId, updateRecommendationById, deleteRecommendationById } from "actions/survey";
import { fetchMasterDataLanguageAll } from "actions/masterData";
import { RECOMMENDATION_LOCAL } from "constants/actionTypes";
// core components
import PageHeader from "components/Headers/PageHeader.js";
import { LOGINSTATE } from "../../../config/config.json";
import Lodash from "lodash";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useHistory, useLocation } from "react-router-dom";
// reactstrap components
import { Badge, Button, Card, CardHeader, Container, CardFooter, Col, Row, Table } from "reactstrap";
import { GlobalStylesColor, ChsStyles } from "constants/globalStyles";
import { confirmAlert } from "react-confirm-alert";
import "react-confirm-alert/src/react-confirm-alert.css"; // Import css
import { Tooltip } from "@material-ui/core";
import PublicOutlinedIcon from "@material-ui/icons/PublicOutlined";
import VpnLockOutlinedIcon from "@material-ui/icons/VpnLockOutlined";

// Populate version row for each survey type
const RecommendationRow = ({ recommendation, editClick, statusClick, deleteClick }) => (
  // VERSION | TITLE | DESCRIPTION | STATUS
  <tr>
    <td style={{ whiteSpace: "break-spaces" }}>{recommendation.languageCode}</td>
    <td style={{ whiteSpace: "break-spaces", color: GlobalStylesColor.mbppThemeBlue }}>{recommendation.title}</td>
    <td style={{ whiteSpace: "break-spaces" }}>{recommendation.description}</td>
    <td>
      {recommendation.published ? (
        <Badge color="" className="badge-dot">
          <i className="bg-success" />
          YES
        </Badge>
      ) : (
        <Badge color="" className="badge-dot mr-4">
          <i className="bg-warning" />
          NO
        </Badge>
      )}
    </td>
    <td className="text-right">
      <div className="row">
        <Tooltip title="Edit" arrow>
          <div
            className="navbar-toggler"
            style={{ cursor: "pointer" }}
            onClick={(e) => {
              editClick(e, recommendation);
            }}
          >
            <i className="far fa-edit" style={{ opacity: recommendation.published ? 0.3 : 1, color: GlobalStylesColor.mbppIconColor }} />
          </div>
        </Tooltip>
        <Tooltip title="Change publish status" arrow>
          <div
            className="navbar-toggler"
            style={{ cursor: "pointer" }}
            onClick={(e) => {
              statusClick(e, recommendation);
            }}
          >
            {recommendation.published ? <PublicOutlinedIcon style={{ color: GlobalStylesColor.mbppIconColorLight }} /> : <VpnLockOutlinedIcon style={{ color: GlobalStylesColor.mbppIconColorLight }} />}
          </div>
        </Tooltip>
        <Tooltip title="Delete" arrow>
          <div
            className="navbar-toggler"
            style={{ cursor: "pointer" }}
            onClick={(e) => {
              confirmAlert({
                title: 'Confirm delete',
                buttons: [
                  { label: "No", onClick: () => { } },
                  {
                    label: "Yes", onClick: () => {
                      deleteClick(e, recommendation);
                    }
                  },
                ],
                closeOnEscape: false,
                closeOnClickOutside: false
              });
            }}
          >
            <i className="far fa-times-circle" />
          </div>
        </Tooltip>
      </div>
    </td>
  </tr>
);

// export function
const RecommendationLocalization = (props) => {
  // dispatch and history
  const dispatch = useDispatch();
  const history = useHistory();
  const location = useLocation();

  const [languages, setLanguages] = useState([]);
  const [recommendationTemplateAll, setRecommendationTemplateAll] = useState([]);

  useEffect(() => {
    const survey = props.location.state?.survey;
    if (LOGINSTATE) console.log("use effect call", survey);
    if (survey && !Lodash.isEmpty(survey)) {
      dispatch(fetchRecommendationsBySurveyId({ surveyId: survey._id }, history));
    }
    dispatch(fetchMasterDataLanguageAll());
  }, []);

  // get recommendations
  const recommendationTemplateStateAll = useSelector((state) => state.survey.recommendationAll);

  const language = useSelector((state) => state.masterData.languageAll);

  useEffect(() => {
    if (LOGINSTATE) console.log("recommendationTemplateStateAll: ", recommendationTemplateStateAll);

    if (!Lodash.isEmpty(recommendationTemplateStateAll) && recommendationTemplateStateAll[0].surveyId && recommendationTemplateStateAll[0]?.surveyId === props.location.state?.survey?._id) {
      if (LOGINSTATE) console.log("recommendationTemplateStateAll surveyId", recommendationTemplateStateAll[0].surveyId);
      if (LOGINSTATE) console.log("survey id", props.location.state?.survey._id);

      setRecommendationTemplateAll(recommendationTemplateStateAll);
      recommendationTemplateStateAll.forEach((template) => {
        setLanguages([...languages, template.languageCode]);
      });
    } else {
      setRecommendationTemplateAll([]);
      setLanguages([]);
    }
  }, [recommendationTemplateStateAll]);

  const handleStatusClick = (e, recommendation) => {
    e.preventDefault();
    let params = {
      recommendationId: recommendation._id,
      published: !recommendation.published,
    };
    dispatch(updateRecommendationById(params, history));
  };

  const handleDeleteClick = (e, recommendation) => {
    e.preventDefault();
    let params = {
      recommendationId: recommendation._id,
    };
    confirmAlert({
      message: "Recommendation will be deleted.",
      buttons: [
        {
          label: "Ok",
          onClick: () => {
            dispatch(deleteRecommendationById(params, history));
          },
        },
        {
          label: "Cancel",
          onClick: () => { },
        },
      ],
    });

    // confirmAlert({
    //   customUI: ({ onClose }) => {
    //     return (
    //       <div className='custom-ui'>
    //         <h1>Are you sure?</h1>
    //         <p>You want to delete this file?</p>
    //         <button onClick={onClose}>No</button>
    //         <button
    //           onClick={() => {
    //             this.handleClickDelete();
    //             onClose();
    //           }}
    //         >
    //           Yes, Delete it!
    //         </button>
    //       </div>
    //     );
    //   }
    // });
  };

  const handleLocalizationClick = (e, recommendation) => {
    e.preventDefault();
  };

  // create survey from existing survey
  const handleEditClick = (e, recommendation, type) => {
    e.preventDefault();
    const survey = props.location.state?.survey;
    if (LOGINSTATE) console.log("--------------------- local store survey: ", survey);
    if (LOGINSTATE) console.log("--------------------- recommendation: ", recommendation);
    if (LOGINSTATE) console.log("--------------------- type: ", type);
    if (LOGINSTATE) console.log("--------------------- language: ", language);

    confirmAlert({
      title: "Warning",
      message: "This will reset currently editing recommendations, if exist. Do you want to proceed?",
      buttons: [
        {
          label: "Yes",
          onClick: () => {
            localStorage.setItem(RECOMMENDATION_LOCAL, JSON.stringify({}));
            dispatch({ type: RECOMMENDATION_LOCAL, payload: recommendation });
            history.push({ pathname: "/registered/assessment_recommendation_details", state: { survey: survey, languages: language } });
          },
        },
        { label: "No", onClick: () => { } },
      ],
      closeOnEscape: false, closeOnClickOutside: false,
    });
  };

  const handleCreateClick = (e, recommendation, type) => {
    e.preventDefault();
  };

  // create empty survey
  const handleCreateNewClick = (e, recommendation) => {
    e.preventDefault();
    const survey = props?.location?.state?.survey;
    if (LOGINSTATE) console.log("language: ", language);

    let finalLang = Lodash.reject(language, function (currentLang) {
      return Lodash.find(recommendationTemplateStateAll, { languageCode: currentLang.code });
    });
    if (LOGINSTATE) console.log("finalLang", finalLang);
    if (Lodash.isEmpty(finalLang)) {
      confirmAlert({
        title: "Error",
        message: "Localizations are exist for all languages.",
        buttons: [
          {
            label: "Ok",
            onClick: () => { },
          },
        ],
        closeOnEscape: false, closeOnClickOutside: false,
      });
    } else {
      confirmAlert({
        title: "Warning",
        message: "This will reset currently editing recommendations, if exist. Do you want to proceed?",
        buttons: [
          {
            label: "Yes",
            onClick: () => {
              localStorage.setItem(RECOMMENDATION_LOCAL, JSON.stringify({}));
              dispatch({ type: RECOMMENDATION_LOCAL, payload: {} });
              history.push({ pathname: "/registered/assessment_recommendation_details_create", state: { survey: survey, languages: finalLang } });
            },
          },
          { label: "No", onClick: () => { } },
        ],
        closeOnEscape: false, closeOnClickOutside: false,
      });
    }
  };

  return (
    <>
      <PageHeader />

      {/* Page content */}
      <Container className="mt--7" fluid>
        <Row className="mt-5">
          <div className="col">
            <Card className="shadow">
              <CardHeader className="border-0">
                <div className="row">
                  <div className="mb-xl-0 col-11">
                    <h3 className="mb-0">Assessment Recommendations</h3>
                    <h6 className="mb-0">With Localizations</h6>
                  </div>
                  <div className="mb-xl-0">
                    <Tooltip title="Create new recommendation locale" arrow>
                      <div
                        className="navbar-toggler"
                        style={{ cursor: "pointer" }}
                        onClick={(e) => {
                          handleCreateNewClick(e, "");
                        }}
                      >
                        <i className="fas fa-plus-circle" style={{ fontSize: 30, color: GlobalStylesColor.mbppThemeBlue }} />
                      </div>
                    </Tooltip>
                  </div>
                </div>
              </CardHeader>
              <Table className="align-items-center table-flush" responsive>
                <thead className="thead-light">
                  <tr>
                    <th scope="col">Language Code</th>
                    <th scope="col">Title</th>
                    <th scope="col">Description</th>
                    <th scope="col">Published</th>
                    <th scope="col">Actions</th>
                  </tr>
                </thead>
                <tbody>
                  <tr></tr>
                  {Lodash.filter(recommendationTemplateAll, {}).map((recommendation, i) => {
                    return <RecommendationRow
                      ey={i}
                      recommendation={recommendation}
                      editClick={handleEditClick}
                      statusClick={handleStatusClick}
                      localizationClick={handleLocalizationClick}
                      createClick={handleCreateClick}
                      deleteClick={handleDeleteClick}
                    />;
                  })}
                </tbody>
              </Table>
            </Card>
          </div>
        </Row>

        <Card className="shadow">
          <CardFooter className="py-4">
            <Row>
              <Col lg="6" className="text-left">
                <Button
                  style={ChsStyles.GeneralButton}
                  color="primary"
                  onClick={() => {
                    history.push({ pathname: "/registered/assessment_recommendations" });
                  }}
                >
                  BACK
                </Button>
              </Col>
              <Col lg="6" className="text-center"></Col>
            </Row>
          </CardFooter>
        </Card>
      </Container>
    </>
  );
};

export default RecommendationLocalization;
