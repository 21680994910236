import AuthFooter from "components/Footers/AuthFooter.js";
import React from "react";
import { Redirect, Route, Switch, useLocation } from "react-router-dom";
// reactstrap components
import { Col, Container, Row } from "reactstrap";
import { routes } from "routes.js";



const Auth = (props) => {
  const mainContent = React.useRef(null);
  const location = useLocation();

  React.useEffect(() => {
    document.body.classList.add("bg-default");
    return () => {
      document.body.classList.remove("bg-default");
    };
  }, []);
  
  React.useEffect(() => {
    document.documentElement.scrollTop = 0;
    document.scrollingElement.scrollTop = 0;
    mainContent.current.scrollTop = 0;
    // window.innerHeight
  }, [location]);

  const getRoutes = (routes) => {
    return routes.map((prop, key) => {
      if (prop.layout === "/auth") {
        return (
          <Route
            path={prop.layout + prop.path}
            component={prop.component}
            key={key}
          />
        );
      } else {
        return null;
      }
    });
  };

  return (
    <>
      <div className="main-content" ref={mainContent}>
        {/* ToDo: explore */}
        {/* <AuthNavbar /> */}
        <div className="header py-6 py-lg-6"
          style={{
            backgroundImage: "url(" + require("../assets/img/theme/img_bg.jpg").default + ")",
            backgroundSize: "cover",
            backgroundPosition: "center top",
          }}
        >
          {/* Mask */}
          {/*bg-gradient-yellow*/}
          <span className="mask opacity-2" />
          <Container>
            <div className="header-body text-center mb-5">
              <Row className="justify-content-center">
                <Col lg="5" md="6">
                  <img
                    style={{ width: '200px' }}
                    alt="..."
                    src={
                      require("../assets/img/brand/img_logo.png").default
                    }
                  />
                  <h1 className="" style={{ color: '#af1e23' }}>APP Self Assessment Tools</h1>
                  <p className="text-lead" style={{ color: '#af1e23' }}>
                    Tools for measuring progress of Institutionalization
                    For
                    Core Humanitarian Standard (CHS)
                  </p>
                </Col>
              </Row>
            </div>
          </Container>
          <div className="separator separator-bottom separator-skew zindex-100" style={{ marginBottom: '-2px', }}>
            <svg
              xmlns="http://www.w3.org/2000/svg"
              preserveAspectRatio="none"
              version="1.1"
              viewBox="0 0 2560 100"
              x="0"
              y="0"
            >
              <polygon
                className="fill-default"
                points="2560 0 2560 100 0 100"
              />
            </svg>
          </div>
        </div>
        {/* Page content */}
        <Container className="mt--7 pb-5">
          <Row className="justify-content-center">
            <Switch>
              {getRoutes(routes)}
              <Redirect from="*" to="/public/login" />
            </Switch>
          </Row>
        </Container>
      </div>

      <AuthFooter />
    </>
  );
};

export default Auth;
