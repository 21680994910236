import { TextField, Grid } from "@material-ui/core";
import AddCircleOutlineIcon from "@material-ui/icons/AddCircleOutline";
import PublicOutlinedIcon from "@material-ui/icons/PublicOutlined";
import VpnLockOutlinedIcon from "@material-ui/icons/VpnLockOutlined";
import CheckCircleIcon from "@material-ui/icons/CheckCircle";

// core components
import PageHeader from "components/Headers/PageHeader.js";
import { LOGINSTATE } from "../../../config/config.json";
import { fetchCountry, updateMasterData } from "actions/masterData";
import { app_country } from "helper";
import { GlobalStylesColor } from "constants/globalStyles";
import React, { useEffect, useState, useRef } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useHistory, useLocation } from "react-router-dom";
import Lodash from "lodash";
// reactstrap components
import { Card, CardBody, CardFooter, CardHeader, Col, Container, Row, Form, Button, Media, Table, FormGroup, InputGroup, InputGroupAddon, InputGroupText, Input } from "reactstrap";

import { ChsStyles } from "constants/globalStyles";
import { Tooltip } from "@material-ui/core";

import { Formik, useFormik } from "formik";
import * as Yup from "yup";
import { confirmAlert } from "react-confirm-alert";
import "react-confirm-alert/src/react-confirm-alert.css"; // Import css
import Pagination from "react-responsive-pagination";
import Config from "config/config.json";
import Moment from "moment";

const reviewSchema = Yup.object({
  countries: Yup.array().of(
    Yup.object({
      code: Yup.string("Enter code of country").min(2, "Code of country should be of minimum 2 characters length").max(2, "Code of country should be of maximum 2 characters length").required("Code of country is required"),
      name: Yup.string("Enter name of country").min(2, "Description should be of minimum 2 characters length").max(500, "Description should be of maximum 500 characters length").required("Country is required"),
    })
  ),
});

const SurveyCreateTemplate = (props) => {
  // dispatch and history
  const dispatch = useDispatch();
  const history = useHistory();

  const [currentCountries, setCurrentCountries] = useState([{ code: null, name: "" }]);
  const [selectedCountry, setSelectedCountry] = useState(null)

  // pagination
  const itemPageSize = Config.ITEM_PAGE_SIZE;
  const [itemCurrent, setItemCurrent] = useState(1);
  const [itemPageContent, setItemPageContent] = useState([]);
  const [itemPageCount, setItemPageCount] = useState(1);
  // filter
  const [txtFilter, setTxtFilter] = useState("");
  const [filteredContent, setFilteredContent] = useState([]);

  // To update fomik values
  const formRef = useRef();

  // get the details passed by the parameter to the page. Afterwards, process needed information
  useEffect(() => {
    if (LOGINSTATE) console.log("---------------> called effect1");
    dispatch(fetchCountry());
  }, []);

  const allCountry = useSelector((state) => state.masterData.countryAll);
  const allCountryLength = allCountry.length;
  if (LOGINSTATE) console.log("allCountry", allCountry);
  if (LOGINSTATE) console.log("allCountryLength", allCountryLength);

  useEffect(() => {
    setCurrentCountries(allCountry);
    if (LOGINSTATE) console.log("all countries: ", allCountry);
  }, [allCountry]);


  // process content with filter
  useEffect(() => {
    let updatedContent = Lodash.filter(currentCountries, function (item) {
      return (item.name.toLowerCase()).indexOf(txtFilter.toLowerCase()) > -1 || (item.code.toLowerCase()).indexOf(txtFilter.toLowerCase()) > -1;
    });
    setFilteredContent(updatedContent);
    if (formRef.current) {
      formRef.current.setFieldValue("countries", updatedContent, false);
    }
  }, [currentCountries, txtFilter]);

  // process the pagination with page content
  useEffect(() => {
    if (LOGINSTATE) console.log("filteredContent.length: ", filteredContent.length);
    // Set the pagination count
    let currentPageCount = Math.ceil(filteredContent.length / itemPageSize);
    setItemPageCount(currentPageCount);

    // Set the pagination content
    if (filteredContent && filteredContent.length > 0) {
      // make sure the page doesn't exceed the array endings
      let startCount = (itemCurrent - 1) * itemPageSize < 0 ? 0 : (itemCurrent - 1) * itemPageSize;
      let endCount = itemCurrent * itemPageSize > filteredContent.length ? filteredContent.length : itemCurrent * itemPageSize;

      let updatedArray = Lodash.slice(filteredContent, startCount, endCount);
      if (updatedArray && updatedArray.length > 0) {
        setItemPageContent(updatedArray);
      } else {
        setItemPageContent([]);
      }
    } else {
      setItemPageContent([]);
    }
  }, [itemCurrent, filteredContent]);

  // handle change event of comments
  const handleCountryChange = (e, index, country) => {
    const { name, value } = e.target;
    let selectedItem = undefined

    if (country?._id) {
      selectedItem = Lodash.find(currentCountries, { _id: country._id })
    }
    else {
      const selectedList = Lodash.filter(currentCountries, { code: country.code, temp: country.temp })
      selectedItem = selectedList[selectedList.length - 1]
    }

    if (selectedItem) {
      selectedItem[name] = (name === 'code') ? value.toUpperCase() : value
    }
    setCurrentCountries([...currentCountries]);

    if (formRef.current) {
      formRef.current.setFieldValue("countries", currentCountries, false);
    }

  };

  // handle click event of the Remove button of comment
  const handleCountryStatusClick = (index, country) => {
    let selectedItem = undefined

    if (country?._id) {
      selectedItem = Lodash.find(currentCountries, { _id: country._id })
    }
    else {
      const selectedList = Lodash.filter(currentCountries, { code: country.code, temp: country.temp })
      selectedItem = selectedList[selectedList.length - 1]
    }

    if (selectedItem) {
      selectedItem['status'] = !country.status
    }
    setCurrentCountries([...currentCountries]);

    if (formRef.current) {
      formRef.current.setFieldValue("countries", currentCountries, false);
      formRef.current.handleSubmit();
    }
  };

  // handle click event of the Add button of comment
  // Note: Button is not appearing when the countries exceed initial page. This not needed, since all the counties are adding
  const handleCountryAddClick = () => {
    setCurrentCountries((currentCountries) => [...currentCountries, { code: "", name: "", app_country: "No", internationalized: "No", status: false, temp: Moment().format('YYYYMMDDHHmmSSS') }]);
    if (LOGINSTATE) console.log("add line");

    if (formRef.current) {
      formRef.current.setFieldValue("countries", currentCountries, false);
    }
  };


  const handleLanguageSaveClick = (e, country) => {
    e.preventDefault()
    if (formRef.current) {
      formRef.current.handleSubmit()
    }
  }


  return (
    <>
      <PageHeader />

      {/* Page content */}
      <Container className="mt--7" fluid>
        {/* create survey questions */}
        <Row className="mt-5">
          <div className="col">
            <Card className="shadow">
              {/* survey header */}
              <CardHeader className="border-0">
                <div className="row">
                  <div className="mb-xl-0 col-10">
                    <h3 className="mb-0">Manage Regional Countries</h3>
                    <h6 className="mb-0">Countries for APP self assessment to be used</h6>
                  </div>
                </div>
              </CardHeader>

              {/* question content */}
              <CardBody style={{}}>
                {/* https://www.iso.org/publication/PUB500001.html */}
                <Row>
                  <Col md="6">
                    <Media className="align-items-center">
                      <a href="https://docs.oracle.com/cd/E13214_01/wli/docs92/xref/xqisocodes.html#wp1250799" target="_blank" rel="noreferrer">
                        <Media>
                          <span
                            className="mb-0 text-sm"
                            dangerouslySetInnerHTML={{
                              __html: "ISO 2 digits country codes (ISO 3166-1 Alpha-2 code)",
                            }}
                          ></span>
                        </Media>
                      </a>
                    </Media>
                  </Col>
                  <Col md="6">

                  </Col>

                </Row>

                <Formik
                  initialValues={{
                    countries: [
                      {
                        code: "",
                        name: "",
                        status: true,
                        app_country: "No",
                      },
                    ],
                  }}
                  validationSchema={reviewSchema}
                  onSubmit={(values, actions) => {
                    if (LOGINSTATE) console.log("values", values);
                    const selectedCountryId = Lodash.indexOf(values.countries, selectedCountry)

                    if (selectedCountryId > -1) {
                      if (!values.countries[selectedCountryId]?._id) {
                        let result = Lodash.filter(values.countries, { code: values.countries[selectedCountryId]?.code });
                        if (result.length > 1) {
                          confirmAlert({
                            title: "Error",
                            message: "Duplicate country code '" + values.countries[selectedCountryId]?.code + "'",
                            buttons: [{ label: "Ok", onClick: () => { } }],
                            closeOnEscape: false,
                            closeOnClickOutside: false,
                          });
                          return;
                        }
                      }

                      const updatingCountry = {
                        code: values.countries[selectedCountryId].code,
                        name: values.countries[selectedCountryId].name,
                        app_country: values.countries[selectedCountryId].app_country,
                        status: values.countries[selectedCountryId].status,
                      }

                      if (values.countries[selectedCountryId]?._id) {
                        updatingCountry['_id'] = values.countries[selectedCountryId]._id
                      }

                      console.log("!@# #### updateMasterData", updatingCountry);
                      dispatch(updateMasterData({ countries: [updatingCountry] }, history));
                    }

                  }}
                  innerRef={formRef}
                >
                  {(props) => (
                    <Form role="form"
                      style={{ marginTop: -25 }}
                      onSubmit={props.handleSubmit}
                      onReset={props.handleReset}>
                      <br />
                      <hr className="my-2" />
                      <Row>
                        <Col md="6">
                          <h6 className="heading-small mb-4" style={ChsStyles.SubTopic}>
                            ORDER BY: IS APP COUNTRY | COUNTRY NAME
                          </h6>
                        </Col>
                        <Col md="6">
                          <FormGroup style={{ marginBottom: 0 }}>
                            <InputGroup className="input-group-alternative">
                              <InputGroupAddon addonType="prepend">
                                <InputGroupText>
                                  <i className="ni ni-zoom-split-in" />
                                </InputGroupText>
                              </InputGroupAddon>
                              <Input
                                className="form-control-alternative"
                                placeholder="By Country Code or Name"
                                type="text"
                                onChange={(e) => {
                                  if (LOGINSTATE) console.log(e.target.value);
                                  setTxtFilter(e.target.value);
                                }}
                              />
                            </InputGroup>
                          </FormGroup>
                        </Col>
                      </Row>


                      <Table className="align-items-center table-flush" responsive>
                        <thead className="thead-light">
                          <tr>
                            <th scope="col" md={2}>ISO Code</th>
                            <th scope="col" md={4}>Country Name</th>
                            <th scope="col" md={2}>Is APP Country?</th>
                            {/* <th scope="col" md={2}>Internationalized?</th> */}
                            <th scope="col" md={1}>Actions</th>
                          </tr>

                          {itemPageContent &&
                            itemPageContent.length > 0 &&
                            itemPageContent.map((x, i) => {
                              return (
                                <>
                                  <tr>
                                    <td >
                                      <TextField
                                        fullWidth
                                        // label={"2 Digit Code"}
                                        name="code" // this is to pass name through event-->e
                                        variant="outlined"
                                        value={x.code}
                                        onChange={(e) => handleCountryChange(e, i, x)}
                                        onBlur={(e) => handleCountryChange(e, i, x)}
                                        style={ChsStyles.Question}
                                        className={"form-control-alternative form-control-edit"}
                                        error={props.errors.countries && props.touched.countries && props.touched.countries[i]?.code && Boolean(props.errors.countries[i]?.code)}
                                        helperText={props.errors.countries && props.touched.countries && props.touched.countries[i]?.code ? props.errors.countries[i]?.code : ""}
                                        disabled={allCountry.length > (i + (itemCurrent - 1) * itemPageSize)}
                                      />
                                    </td>
                                    <td >
                                      <TextField
                                        fullWidth
                                        // label={"Country Name"}
                                        name="name" // this is to pass name through event-->e
                                        variant="outlined"
                                        value={x.name}
                                        onChange={(e) => handleCountryChange(e, i, x)}
                                        onBlur={(e) => handleCountryChange(e, i, x)}
                                        style={ChsStyles.Question}
                                        className={"form-control-alternative form-control-edit"}
                                        error={props.errors.countries && props.touched.countries && props.touched.countries[i]?.name && Boolean(props.errors.countries[i]?.name)}
                                        helperText={props.errors.countries && props.touched.countries && props.touched.countries[i]?.name ? props.errors.countries[i]?.name : ""}
                                      />
                                    </td>
                                    <td >
                                      <TextField
                                        select
                                        fullWidth
                                        // label={"Is App Country"}
                                        name="app_country" // this is to pass name through event-->e
                                        variant="outlined"
                                        value={x.app_country}
                                        onChange={(e) => handleCountryChange(e, i, x)}
                                        onBlur={(e) => handleCountryChange(e, i, x)}
                                        style={x.app_country === 'Yes' ? { ...ChsStyles.Question, backgroundColor: '#FEE9E8' } : ChsStyles.Question}
                                        SelectProps={{
                                          native: true,
                                        }}
                                        className={"form-control-alternative form-control-edit"}
                                        error={props.errors.countries && props.touched.countries && props.touched.countries[i]?.app_country && Boolean(props.errors.countries[i]?.app_country)}
                                        helperText={props.errors.countries && props.touched.countries && props.touched.countries[i]?.app_country ? props.errors.countries[i]?.app_country : ""}
                                      >
                                        {app_country.map((item) => (
                                          <option key={item} value={item}>
                                            {item}
                                          </option>
                                        ))}
                                      </TextField>
                                    </td>
                                    <td >
                                      <div className="row">
                                        <Tooltip title="Change publish status" arrow>
                                          <div
                                            className="navbar-toggler"
                                            style={{ cursor: "pointer" }}
                                            onClick={(e) => {
                                              // setSelectedCountry(i + (itemCurrent - 1) * itemPageSize);
                                              setSelectedCountry(x)
                                              handleCountryStatusClick(i, x);
                                            }}
                                          >
                                            {x.status ? <PublicOutlinedIcon style={{ color: GlobalStylesColor.mbppIconColorLight }} /> : <VpnLockOutlinedIcon style={{ color: GlobalStylesColor.mbppIconColorLight }} />}
                                          </div>
                                        </Tooltip>
                                        <Tooltip title="Save details" arrow>
                                          <div
                                            className="navbar-toggler"
                                            style={{ cursor: "pointer" }}
                                            onClick={(e) => {
                                              // setSelectedCountry(i + (itemCurrent - 1) * itemPageSize)
                                              setSelectedCountry(x)
                                              handleLanguageSaveClick(e, x)
                                            }}
                                          >
                                            <CheckCircleIcon style={{ color: GlobalStylesColor.mbppIconColorLight }} />
                                          </div>

                                        </Tooltip>
                                        {/* Adding all the countries */}
                                        {currentCountries.length - 1 === (i + (itemCurrent - 1) * itemPageSize)
                                          && <Tooltip title="Add region" arrow>
                                            <div
                                              className="navbar-toggler"
                                              style={{ cursor: "pointer" }}
                                              onClick={(e) => {
                                                handleCountryAddClick()
                                              }}
                                            >
                                              <AddCircleOutlineIcon
                                                className="ma10"
                                              />
                                            </div>
                                          </Tooltip>
                                        }
                                      </div>
                                    </td>
                                  </tr>
                                </>
                              );
                            })}

                        </thead>
                      </Table>

                      <Row>
                        <Col lg="12" className="text-right">
                          {props.submitCount > 0 && !props.isValid ? (
                            <h6
                              small
                              style={{
                                fontWeight: "bold",
                                marginTop: 40,
                                marginBottom: -20,
                                color: "#af1e23",
                              }}
                            >
                              There are errors! Please correct and retry.
                            </h6>
                          ) : null}
                        </Col>
                      </Row>

                      {/* <Row>
                        <Col lg="6" className="text-left"></Col>
                        <Col lg="6" className="text-right">
                          <Button
                            type="submit"
                            style={ChsStyles.BottomButton}
                            color="primary"
                            onPress={formik.handleSubmit}
                          >
                            UPDATE ALL CHANGES
                          </Button>
                        </Col>
                      </Row> */}
                    </Form>
                  )}
                </Formik>
              </CardBody>

              {/* pagination */}
              <CardFooter>
                <Row>
                  <Col lg="12">
                    {itemPageCount && itemPageCount > 0 ? (
                      <Pagination
                        current={itemCurrent}
                        total={itemPageCount}
                        onPageChange={(current) => {
                          setItemCurrent(current);
                        }}
                      />
                    ) : null}
                  </Col>
                </Row>
              </CardFooter>

              {/* survey footer */}
              <CardFooter className="py-4">
                <Row>
                  <Col lg="6" className="text-left">
                    <Button
                      style={ChsStyles.GeneralButton}
                      color="primary"
                      onClick={() => {
                        // history.goBack();
                        window.history.back();
                      }}
                    >
                      BACK
                    </Button>
                  </Col>
                </Row>
              </CardFooter>
            </Card>
          </div>
        </Row>
      </Container >
    </>
  );
};

export default SurveyCreateTemplate;
