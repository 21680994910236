import Recommendation from "views/pages/recommendation/Recommendation";

export const CREATE = 'CREATE';
export const UPDATE = 'UPDATE';
export const DELETE = 'DELETE';
export const FETCH_ONE = 'FETCH_ONE';
export const FETCH_ALL = 'FETCH_ALL';
export const LIKE = 'LIKE';

// User Auth
export const AUTH = 'AUTH';
export const LOGOUT = 'LOGOUT';

// user
export const USER_FETCH_ONE = 'USER_FETCH_ONE';
export const USER_FETCH_ONE_ORG = 'USER_FETCH_ONE_ORG';
export const USER_FETCH_ALL = 'USER_FETCH_ALL';
export const USER_CREATE = 'USER_CREATE';
export const USER_UPDATE = 'USER_UPDATE';
export const USER_FETCH_BY_ADMIN = 'USER_FETCH_BY_ADMIN';
export const IS_HUMAN = "IS_HUMAN";

// Forum topic
export const FRM_TOPIC_FETCH_ALL = 'FRM_TOPIC_FETCH_ALL';
export const FRM_TOPIC_FETCH_ONE = 'FRM_TOPIC_FETCH_ONE';
export const FRM_TOPIC_CREATE = 'FRM_TOPIC_CREATE';
export const FRM_TOPIC_UPDATE = 'FRM_TOPIC_UPDATE';
// Forum sub topic
export const FRM_SUB_TOPIC_FETCH_ALL = 'FRM_SUB_TOPIC_FETCH_ALL';
export const FRM_SUB_TOPIC_FETCH_ONE = 'FRM_SUB_TOPIC_FETCH_ONE';
export const FRM_SUB_TOPIC_CREATE = 'FRM_SUB_TOPIC_CREATE';
export const FRM_SUB_TOPIC_UPDATE = 'FRM_SUB_TOPIC_UPDATE';
export const FRM_SUB_TOPIC_DELETE = 'FRM_SUB_TOPIC_DELETE';
// Forum Questions
export const FRM_QUS_FETCH_ONE = 'FRM_QUS_FETCH_ONE';
export const FRM_QUS_FETCH_ALL = 'FRM_QUS_FETCH_ALL';
export const FRM_QUS_TOPIC_FETCH_ALL = 'FRM_QUS_TOPIC_FETCH_ALL';
export const FRM_QUS_CREATE = 'FRM_QUS_CREATE';
export const FRM_QUS_UPDATE = 'FRM_QUS_UPDATE';
export const FRM_QUS_DELETE = 'FRM_QUS_DELETE';
// Forum Answers
export const FRM_ANS_FETCH_ALL = 'FRM_ANS_FETCH_ALL';
export const FRM_ANS_FETCH_ONE = 'FRM_ANS_FETCH_ONE';
export const FRM_ANS_CREATE = 'FRM_ANS_CREATE';
export const FRM_ANS_UPDATE = 'FRM_ANS_UPDATE';
export const FRM_ANS_DELETE = 'FRM_ANS_DELETE';

// Dashbord
export const STAT_SUMMERY_TOTAL = 'STAT_SUMMERY_TOTAL';
export const STAT_SUMMERY_CON_MON_FETCH = 'STAT_SUMMERY_CON_MON_FETCH';
export const STAT_SUMMERY_CON_WEK_FETCH = 'STAT_SUMMERY_CON_WEK_FETCH';
export const STAT_SUMMERY_REP_FETCH = 'STAT_SUMMERY_REP_FETCH';
// Loading Indicator
export const SHOW_LOADING = 'SHOW_LOADING';

//Survey
export const SURVEY_FETCH_ALL = 'SURVEY_FETCH_ALL';
export const SURVEY_FETCH_ACTIVE = 'SURVEY_FETCH_ACTIVE';
export const SURVEY_TEMPLATE_CREATE = 'SURVEY_TEMPLATE_CREATE';
export const SURVEY_TEMPLATE_FETCH_ALL = 'SURVEY_TEMPLATE_FETCH_ALL';
export const SURVEY_TEMPLATE_LOCAL = 'SURVEY_TEMPLATE_LOCAL';
export const SURVEY_TEMPLATE_LOCALE_LOCAL = 'SURVEY_TEMPLATE_LOCALE_LOCAL';
export const SURVEY_ACTIVE_LOCAL = 'SURVEY_ACTIVE_LOCAL';
export const SURVEY_LOCALIZATION_CREATE = 'SURVEY_LOCALIZATION_CREATE';
export const SURVEY_LOCALIZATION = 'SURVEY_LOCALIZATION';
export const LOCALIZATION_DELETE = "LOCALIZATION_DELETE";
//Answer
export const ANSWER_FETCH_BY_USER = 'ANSWER_FETCH_BY_USER';
export const ANSWER_FETCH_BY_ID = 'ANSWER_FETCH_BY_ID';
export const GRAPH_LOCAL_NAME = 'GRAPH_LOCAL_NAME';
export const GRAPH_LOCAL_SCORE = 'GRAPH_LOCAL_SCORE';
export const GRAPH_LOCAL_LONG_NAME = "GRAPH_LOCAL_LONG_NAME";
export const GRAPH_LOCAL_ANSWER = "GRAPH_LOCAL_ANSWER";
export const GRAPH_LOCAL_LOCALE = "GRAPH_LOCAL_LOCALE";
export const GRAPH_LOCAL_SYSTEM_CONTENT = "GRAPH_LOCAL_SYSTEM_CONTENT";
// Recommendation
export const RECOMMENDATION_CREATE = 'RECOMMENDATION_CREATE';
export const RECOMMENDATION_UPDATE = 'RECOMMENDATION_UPDATE';
export const RECOMMENDATION_FETCH_ALL = 'RECOMMENDATION_FETCH_ALL';
export const RECOMMENDATION_LOCAL = 'RECOMMENDATION_LOCAL';

//Organization
export const ORG_FETCH_ALL = 'ORG_FETCH_ALL';
export const ORG_UPDATE = 'ORG_UPDATE';
export const ORG_FETCH_ONE = 'ORG_FETCH_ONE';
export const ORG_CITY_STATUS_UPDATE = "ORG_CITY_STATUS_UPDATE";
export const ORG_FETCH_ALL_BY_COUNTRY_ID = "ORG_FETCH_ALL_BY_COUNTRY_ID";
export const ORGANIZATION_PROFILE_DELETE = "ORGANIZATION_PROFILE_DELETE";

// Master data
export const MASTERDATA_FETCH_LANGUAGE = 'MASTERDATA_FETCH_LANGUAGE';
export const MASTERDATA_FETCH_ONE_LANGUAGE = 'MASTERDATA_FETCH_ONE_LANGUAGE';
export const COUNTRY_FETCH_ALL = "COUNTRY_FETCH_ALL";
export const MASTER_DATA_UPDATE = "MASTER_DATA_UPDATE";
export const MASTER_DATA_LAN_UPDATE = "MASTER_DATA_LAN_UPDATE"
export const LANDING_FETCH = "LANDING_FETCH";
export const LANDING_UPDATE = "LANDING_UPDATE";
export const LOCALIZATION_FETCH = "LOCALIZATION_FETCH";

// Resources
export const RESOURCE_CREATE = "RESOURCE_CREATE";
export const RESOURCE_UPDATE = "RESOURCE_UPDATE";
export const RESOURCE_FETCH_ALL = "RESOURCE_FETCH_ALL";
export const RESOURCE_DELETE = "RESOURCE_DELETE";

// Report
export const REPORT_ANSWER_ALL = "REPORT_ANSWER_ALL";
export const REPORT_ANSWER_BY_USER = "REPORT_ANSWER_BY_USER";
export const REPORT_ANSWER_BY_COUNTRY = "REPORT_ANSWER_BY_COUNTRY";
export const REPORT_ANSWER_STAT = "REPORT_ANSWER_STAT";
export const REPORT_USER_STAT = "REPORT_USER_STAT";

//Language
export const LANGUAGE = "LANGUAGE";

