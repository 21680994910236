import { FormControl, Select, TextField as TextFieldDef, MenuItem, InputAdornment, IconButton } from "@material-ui/core";
import { TextField, RtlTextField, InputLabel } from "../../../components/Custom/RTLTextField";
import { changePassword, updateUser } from "actions/user";
import { updateOrganization } from "actions/organization";

import { fetchLocalizationContent, fetchMasterDataLanguageAll, fetchMasterDataLanguage } from "actions/masterData";
// core components
import UserHeader from "components/Headers/UserHeader.js";
import Config from "config/config.json";
import { LOGINSTATE } from "../../../config/config.json";
import { ChsStyles } from "constants/globalStyles";
import { useFormik } from "formik";
import { Camelize, CryptoPassword, emp_types, legal_entitys, geo_areas, drm_acts, unit, functional, stakeholder, vision, city_drm_acts, headoffice, RTL_FLAG_RIGHT, initialValues_assessmentPage } from "helper.js";
import Lodash from "lodash";
import React, { useEffect, useState, useRef } from "react";
// import { useAlert } from "react-alert";
import Resizer from "react-image-file-resizer";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import { Button, Card, CardBody, Col, Container, Form, Row } from "reactstrap";
import * as yup from "yup";
import { confirmAlert } from "react-confirm-alert";

import { useTranslation, initReactI18next, Trans } from "react-i18next";
import RTLFileUpload from "components/Custom/RTLFileUpload";

const Profile = () => {
  // dispatch and history
  const dispatch = useDispatch();
  const history = useHistory();

  // set state userDetails
  const userDetails = JSON.parse(localStorage.getItem("userDetails"));
  if (LOGINSTATE) console.log("userDetails: ", userDetails);

  const userProfileOrg1 = useSelector((state) => state.user.userProfileOrg);
  if (LOGINSTATE) console.log("userOrg: ", userProfileOrg1);

  const language = useSelector((state) => state.masterData.languageAll);
  if (LOGINSTATE) console.log("language: ", language);

  const afterUpdate = useSelector((state) => state.masterData.userProfileUpdate);
  if (LOGINSTATE) console.log("afterUpdate: ", afterUpdate);

  const [userProfile, setUserProfile] = useState({});
  const [userProfileOrg, setUserProfileOrg] = useState({});
  const [isAdmin, setIsAdmin] = useState(false);
  const [isOrgOwner, setIsOrgOwner] = useState(false);
  const [cityPosition, setCityPosition] = useState(0);
  const [isCityOwner, setIsCityOwner] = useState(false);
  const [refreshCounter, setRefreshCounter] = useState(0);
  const [orgCities, setOrgCities] = useState([]);
  const [systemContentLocale, setSystemContentLocale] = useState({});

  const [rtlAlignment, setRtlAlignment] = useState("left");

  // set state edit
  const [isEdit, setIsEdit] = useState(true);
  // Show edit profile or change password
  const [editSection, setEditSection] = useState("WELCOME");
  // Change the password text readability
  const [showPassword, setShowPassword] = useState(false);

  const hiddenProfileFileInputRef = useRef();

  // handle click event of togle Edit
  const switchMode = (e) => {
    e.preventDefault();
    setEditSection("PROFILE");
  };

  const userLang = useSelector((state) => state.masterData.languageByCode);
  if (LOGINSTATE) console.log("userLanguage", userLang);
  const { t, i18n } = useTranslation();

  useEffect(() => {
    i18n.changeLanguage(jUser?.user?.language);

    let params = {
      user: {
        code: jUser?.user?.language,
      },
    };

    const paramsLocal = { languageCode: jUser?.user?.language };
    dispatch(fetchMasterDataLanguage(params.user));
    dispatch(fetchLocalizationContent(paramsLocal, history));
  }, []);

  useEffect(() => {
    userLang &&
      userLang.language &&
      Object.values(userLang.language).map((lang, i) => {
        if (lang && lang.dir === "rtl") {
          setRtlAlignment("right");
        }
      });
  }, [userLang]);

  const systemContent = useSelector((state) => state.masterData.localizationContentAll);
  useEffect(() => {
    if (LOGINSTATE) console.log("systemContent from server: ", systemContent);
    if (systemContent !== undefined && systemContent !== null && Lodash.isArray(systemContent) && systemContent.length > 0) {
      setSystemContentLocale(systemContent[0].assessmentPage);
    } else {
      setSystemContentLocale(initialValues_assessmentPage);
    }
  }, [systemContent]);

  let currentUser = localStorage.getItem("userDetails");
  let jUser = JSON.parse(currentUser);

  useEffect(() => {
    if (LOGINSTATE) console.log("userProfileOrg", userProfileOrg);

    if (userProfileOrg1 && !Lodash.isEmpty(userProfileOrg1)) {
      // const profile1 = JSON.stringify(userProfileOrg1.profile);
      // const profile = JSON.parse(profile1);
      let userPro = userProfileOrg1.profile;

      setUserProfile(userPro);

      if (userDetails?.user?.urole === "ADMIN") {
        setIsAdmin(true);
      }

      let organization = userProfileOrg1.profile?.employment?.organization;
      setUserProfileOrg(organization);
      if (LOGINSTATE) console.log("userProfileOrg1", userProfileOrg1);

      if (organization && !Lodash.isEmpty(organization)) {
        // profile.employment["organization"] = profile.employment?.organization?._id;
        if (LOGINSTATE) console.log("userProfile?.user", userPro?.user);
        if (userPro?.user._id === organization.createdBy) {
          setIsOrgOwner(true);
          if (LOGINSTATE) console.log("Owner true", isOrgOwner);
        } else {
          setIsOrgOwner(false);
          if (LOGINSTATE) console.log("Owner false", isOrgOwner);
        }

        if (LOGINSTATE) console.log("Owner", isOrgOwner);

        /******************************** */
        let cities = organization.city;
        setOrgCities(cities);
        if (LOGINSTATE) console.log("city array", organization.city);
        organization.city.map((props, i) => {
          if (LOGINSTATE) console.log("linkeduser", props.linkedUser);
          if (LOGINSTATE) console.log("userId", userPro?.user);
          if (userPro?.user._id === props.linkedUser) {
            setCityPosition(i);
            setIsCityOwner(true);
            if (LOGINSTATE) console.log("City true", isCityOwner);
          }
        });
      }
    }

    dispatch(fetchMasterDataLanguageAll());
  }, [userProfileOrg1]);

  // handle change event of the profilePicture
  const handleFileChange = async (e) => {
    // Resize the image to generate thumbnail
    let image = null;
    try {
      const file = e.target.files[0];
      if (LOGINSTATE) console.log("selected file: ", file);
      if (LOGINSTATE) console.log("file size", file.size);

      if (!(file.type.match('jpg') || file.type.match('jpeg') || file.type.match('png'))) {
        confirmAlert({
          title: t("common.error"),
          message: "*.jpg, *.jpeg, *.png",
          buttons: [
            {
              label: t("assessmentPage.app_ok"),
              onClick: () => { },
            },
          ],
          closeOnEscape: false,
          closeOnClickOutside: false,
        });
        return;
      }


      if (file && file.size <= 2000000) {
        image = await resizeImage(file);
        // console.log(image);
        formik.values.profilePicture = file;
        formik.values.profileThumbnail = image;
        formik.values.newProfilePicture = true;
        formik.values.oldprofilePicture = userProfile?.profilePicture || "";
        formik.values.oldprofileThumbnail = userProfile?.profileThumbnail || "";
      } else {
        confirmAlert({
          title: t("assessmentPage.error"),
          message: t("popups.profilePicture"),
          buttons: [
            {
              label: t("assessmentPage.app_ok"),
              onClick: () => { },
            },
          ],
          closeOnEscape: false,
          closeOnClickOutside: false,
        });
        return
      }
    } catch (err) {
      if (LOGINSTATE) console.log(err);
    }
  };

  // handle change event of the file
  const handleFileChangeOrg = async (e) => {
    try {
      // const files = e.target.files;
      const file = e.target.files[0];
      if (LOGINSTATE) console.log(file);
      if (file) {
        formik1.values.organizationProfile = file;
      }
    } catch (err) {
      if (LOGINSTATE) console.log("file error", err);
    }
  };

  const resizeImage = (file) =>
    new Promise((resolve) => {
      Resizer.imageFileResizer(
        file,
        200,
        200,
        "JPEG",
        100,
        0,
        (uri) => {
          resolve(uri);
        },
        "file",
        200,
        200
      );
    });

  useEffect(() => {
    setEditSection("WELCOME");
    if (LOGINSTATE) console.log("userProfile", userProfile);

    if (userProfile && Object.keys(userProfile).length > 0) {
      formik.values.userId = userDetails?.user.id || "";
      formik.values.user = userDetails?.user || "";
      formik.values.profileId = userProfile?._id || "";
      formik.values.firstName = userProfile?.firstName || "";
      formik.values.lastName = userProfile?.lastName || "";
      formik.values.language = userProfile?.language || "";
      formik.values.email = userDetails?.user.email || "";
      formik.values.profilePicture = userProfile?.profilePicture || "";
      formik.values.profileThumbnail = userProfile?.profileThumbnail || "";
      formik.values.emp_designation = userProfile?.employment?.designation || "";
      formik.values.emp_linkedCity = userProfile?.employment?.linkedCity || "";
      formik.values.emp_organization = userProfile?.employment?.organization?._id || "";
    }
  }, [userProfile]);

  useEffect(() => {
    setEditSection("WELCOME");

    if (userDetails?.user?.urole === "GENERAL") {
      if (userProfileOrg && Object.keys(userProfileOrg).length > 0) {
        formik1.values.organizationId = userProfileOrg?._id || "";
        formik1.values.firstName = userProfileOrg?.firstName || "";
        formik1.values.lastName = userProfileOrg?.lastName || "";
        formik1.values.type = userProfileOrg?.type || "";
        formik1.values.legalStatus_status = userProfileOrg?.legalStatus?.status === true ? "Yes" : "No";
        formik1.values.legalStatus_mandate = userProfileOrg?.legalStatus?.mandate || "";
        formik1.values.drmActivities = userProfileOrg?.drmActivities || "";
        formik1.values.visionMission_status = userProfileOrg?.visionMission?.status === true ? "Yes" : "No";
        formik1.values.visionMission_remarks = userProfileOrg?.visionMission?.remark || "";
        formik1.values.functionalStructure_status = userProfileOrg?.functionalStructure?.status === true ? "Yes" : "No";
        formik1.values.functionalStructure_remarks = userProfileOrg?.functionalStructure?.remark || "";
        formik1.values.unitProject_status = userProfileOrg?.unitProject?.status === true ? "Yes" : "No";
        formik1.values.unitProject_remarks = userProfileOrg?.unitProject?.remark || "";
        formik1.values.unitStakeholders_status = userProfileOrg?.unitStakeholders?.status === true ? "Yes" : "No";
        formik1.values.unitStakeholders_remarks = userProfileOrg?.unitStakeholders?.remark || "";
        formik1.values.geographicArea = userProfileOrg?.geographicArea || "";
        formik1.values.organization = userProfileOrg?.name || "";
        formik1.values.createdBy = userProfileOrg?.createdBy || "";
        formik1.values.country = userProfileOrg?.country?.name || "";
        formik1.values.website = userProfileOrg?.website || "";
        formik1.values.numberOfOrganizationEmployees_number = userProfileOrg?.numberOfOrganizationEmployees?.number || 0;
        formik1.values.numberOfOrganizationEmployees_remark = userProfileOrg?.numberOfOrganizationEmployees?.remark || "";
        formik1.values.oldOrganizationProfile = userProfileOrg?.organizationProfile || "";
        formik1.values.type = userProfileOrg?.type || "";
        formik1.values.city_name = userProfileOrg?.city[cityPosition]?.name || "";
        formik1.values.city_linkedUser = userProfileOrg?.city[cityPosition]?.linkedUser || null;
        formik1.values.city_website = userProfileOrg?.city[cityPosition]?.website || "";
        formik1.values.city_email = userProfileOrg?.city[cityPosition]?.email || "";
        formik1.values.city_address = userProfileOrg?.city[cityPosition]?.address || "";
        formik1.values.cityId = userProfileOrg?.city[cityPosition]?._id || "";
        formik1.values.city_isHeadOffice = userProfileOrg?.city[cityPosition]?.isHeadOffice === true ? "Yes" : "No";
        formik1.values.city_numberOfEmployees_number = userProfileOrg?.city[cityPosition]?.numberOfEmployees?.number || 0;
        formik1.values.city_numberOfEmployees_remarks = userProfileOrg?.city[cityPosition]?.numberOfEmployees?.remark || "";
        formik1.values.city_drmActivities_performed = userProfileOrg?.city[cityPosition]?.drmActivities?.performed || "";
        formik1.values.city_drmActivities_remarks = userProfileOrg?.city[cityPosition]?.drmActivities?.remark || "";
        formik1.values.modification_modifiedBy = userDetails?.user.id || "";
        formik1.values.modification_modifiedRemark = userProfileOrg?.modification?.modifiedRemark || "";
      }
    }
  }, [userProfileOrg]);

  const formik = useFormik({
    initialValues: {
      firstName: "",
      lastName: "",
      profilePicture: "",
      oldprofilePicture: "",
      profileThumbnail: "",
      oldprofileThumbnail: "",
      language: "",
      emp_designation: "",
      emp_organization: "",
      emp_linkedCity: "",
      emp_institute: "",
      linkedCity: "",
    },
    // validationSchema: validationSchema,
    validationSchema: yup.object({
      firstName: yup.string(t('registerPage.section2.enterFirstName'))
        .max(255, t('registerPage.section2.parameterLengthMax'))
        .required(t('registerPage.section2.firstNameRequired')),
      lastName: yup.string(t('registerPage.section2.enterLastName'))
        .max(255, t('registerPage.section2.parameterLengthMax'))
        .required(t('registerPage.section2.lastNameRequired')),
    }),
    onSubmit: (values, onSubmitProps) => {
      if (LOGINSTATE) console.log("Update profile", values);
      dispatch(updateUser(values, true, history, systemContentLocale, t));
    },
  });

  const formik1 = useFormik({
    initialValues: {
      organizationId: "",
      country: "",
      organization: "",
      city_name: "",
      city_linkedUser: "",
      city_website: "",
      city_address: "",
      cityId: "",
      visionMission_status: "",
      visionMission_remarks: "",
      functionalStructure_status: "",
      functionalStructure_remarks: false,
      unitProject_status: "",
      unitProject_remarks: "",
      unitStakeholders_status: "",
      unitStakeholders_remarks: "",
      city_isHeadOffice: false,
      numberOfOrganizationEmployees_number: "",
      numberOfOrganizationEmployees_remark: "",
      city_numberOfEmployees_number: 0,
      city_drmActivities_performed: "",
      city_numberOfEmployees_remarks: "",
      city_drmActivities_remarks: "",
      type: "",
      legalStatus_status: "",
      legalStatus_mandate: "Not applicable",
      geographicArea: "",
      drmActivities: "",
      website: "",
      createdBy: "",
      modification_modifiedBy: "",
      modification_modifiedRemark: "",
      organizationProfile: "",
      oldOrganizationProfile: "",
    },
    // validationSchema: validationSchemaOrganization,
    validationSchema: yup.object({
      organization: yup.string().required(t('registerPage.section2.valueIsRequired')),
      type: yup.string().required(t('registerPage.section2.valueIsRequired')),
      legalStatus_status: yup.string().required(t('registerPage.section2.valueIsRequired')),
      geographicArea: yup.string().required(t('registerPage.section2.valueIsRequired')),
      numberOfEmployees_number: yup.number(t('registerPage.section2.valueIsRequired')),
      drmActivities: yup.string().required(t('registerPage.section2.valueIsRequired')),
      visionMission_status: yup.string().required(t('registerPage.section2.valueIsRequired')),
      functionalStructure_status: yup.string().required(t('registerPage.section2.valueIsRequired')),
      unitProject_status: yup.string().required(t('registerPage.section2.valueIsRequired')),
      unitStakeholders_status: yup.string().required(t('registerPage.section2.valueIsRequired')),
      country: yup.string().required(t('registerPage.section2.valueIsRequired')),
    }),
    onSubmit: (values) => {
      if (LOGINSTATE) console.log("Update organization", values);
      if (LOGINSTATE) console.log("city position on submit", cityPosition);
      let legal = {
        status: values.legalStatus_status === "Yes" ? true : false,
        mandate: values.legalStatus_mandate,
      };
      values["legalStatus"] = legal;
      if (LOGINSTATE) console.log("boolean", values.legalStatus_status);
      if (LOGINSTATE) console.log("legal", values);

      let numberOfOrganizationEmployees = {
        number: values.numberOfOrganizationEmployees_number,
        remark: values.numberOfOrganizationEmployees_remark,
      };
      values["numberOfOrganizationEmployees"] = numberOfOrganizationEmployees;

      let vision = {
        status: values.visionMission_status === "Yes" ? true : false,
        remark: values.visionMission_remarks,
      };
      values["visionMission"] = vision;
      if (LOGINSTATE) console.log("visio", vision);

      let functional = {
        status: values.functionalStructure_status === "Yes" ? true : false,
        remark: values.functionalStructure_remarks,
      };
      values["functionalStructure"] = functional;
      if (LOGINSTATE) console.log("functional", functional);

      let unit = {
        status: values.unitProject_status === "Yes" ? true : false,
        remark: values.unitProject_remarks,
      };
      values["unitProject"] = unit;
      if (LOGINSTATE) console.log("unit", unit);

      let stakeholder = {
        status: values.unitStakeholders_status === "Yes" ? true : false,
        remark: values.unitStakeholders_remarks,
      };
      values["unitStakeholders"] = stakeholder;
      if (LOGINSTATE) console.log("stakeholder", stakeholder);

      let modification = {
        modifiedBy: values.modification_modifiedBy,
        modifiedRemark: values.modification_modifiedRemark,
      };
      values["modification"] = modification;
      if (LOGINSTATE) console.log("modification", modification);

      if (LOGINSTATE) console.log("orgCities", orgCities);

      let uptedcity = orgCities;
      // {
      //   uptedcity[cityPosition]["name"] = values.city_name;
      //   uptedcity[cityPosition]["linkedUser"] = values.city_linkedUser;
      //   uptedcity[cityPosition]["website"] = values.city_website;
      //   uptedcity[cityPosition]["email"] = values.city_email;
      //   uptedcity[cityPosition]["address"] = values.city_address;
      //   uptedcity[cityPosition]["isHeadOffice"] = values.city_isHeadOffice === "Yes" ? true : false;
      //   uptedcity[cityPosition]["drmActivities"] = {
      //     performed: values.city_drmActivities_performed,
      //     remark: values.city_drmActivities_remarks,
      //   };
      //   uptedcity[cityPosition]["numberOfEmployees"] = {
      //     number: values.city_numberOfEmployees_number,
      //     remark: values.city_numberOfEmployees_remarks,
      //   };
      //   if (LOGINSTATE) console.log("uptedcity", uptedcity);
      // }

      values["city"] = uptedcity;
      if (LOGINSTATE) console.log("Update organization final update", values);
      // dispatch(updateOrganization(values, true, history, systemContentLocale));
      dispatch(updateOrganization(values, true, history, t));
    },
  });

  const formikChangePassword = useFormik({
    initialValues: {
      currentPassword: "",
      password: "",
      confirmPassword: "",
    },
    // validationSchema: validationSchemaChangePassword,
    validationSchema: yup.object({
      currentPassword: yup.string(t('profile.currentPassword'))
        .min(6, t('registerPage.section2.parameterLengthMin'))
        .required(t('registerPage.section2.passwordRequired')),
      password: yup.string(t('profile.newPassword'))
        .min(6, t('registerPage.section2.parameterLengthMin'))
        .required(t('registerPage.section2.passwordRequired')),
      confirmPassword: yup.string(t('profile.retypeNewPassword'))
        .test("passwords-match", t('registerPage.section2.passwordMismatch'), function (value) {
          return this.parent.password === value;
        }),
    }),
    onSubmit: (values, onSubmitProps) => {
      if (LOGINSTATE) console.log("Change password with values: ", values);
      values.oldpassword = CryptoPassword(values.currentPassword);
      values.newpassword = CryptoPassword(values.password);
      dispatch(changePassword(values, history, systemContentLocale));
      onSubmitProps.resetForm();
    },
  });

  return (
    <div id={refreshCounter}>
      <UserHeader />
      {/* Page content */}

      <Container className="mt--7" fluid>
        <Row className="mt-5">
          {/* Right profile update and change password */}
          {userProfile && (
            <Col className="order-xl-2 mb-5 mb-xl-0" xl="4">
              <Card className="card-profile shadow" style={{ textAlign: rtlAlignment }}>
                <Row className="justify-content-center">
                  <Col className="order-lg-2" lg="3" style={{ marginLeft: -20 }}>
                    <div className="card-profile-image">
                      <div
                        className="rounded-circle"
                        style={{
                          backgroundImage: 'url("' + Config.SERVER_API_URL + Config.PUBLIC_PROF_PIC + userProfile?.profilePicture + '")',
                          backgroundColor: "#bdbdbd",
                        }}
                      >
                        {userProfile?.profilePicture ? "" : userProfile && userProfile?.firstName && userProfile?.firstName.length > 0 ? userProfile?.firstName.charAt(0).toUpperCase() : ""}
                      </div>
                    </div>
                  </Col>
                </Row>
                <Row style={{ paddingTop: 130, margin: 10 }}>
                  <Col className="d-flex justify-content-between order-xl-2 mb-5 mb-xl-0" style={{ padding: 0 }}>
                    <div></div>
                    <Button
                      className="mr-4"
                      color="primary"
                      href="#chs"
                      onClick={(e) => {
                        switchMode(e);
                      }}
                      size="sm"
                      // style={{ minWidth: 130 }}
                      style={{ minWidth: 100, paddingTop: 3, paddingBottom: 3 }}
                    >
                      {t("profile.updateProfile")}
                    </Button>
                    <Button
                      className="float-right"
                      color="primary"
                      href="#chs"
                      onClick={(e) => {
                        setEditSection("PASSWORD");
                      }}
                      size="sm"
                      style={{ minWidth: 100, paddingTop: 3, paddingBottom: 3 }}
                    >
                      {t("profile.changePassword")}
                    </Button>
                    <div></div>
                  </Col>
                </Row>
                <CardBody className="pt-0 pt-md-4">
                  <div className="text-center">
                    <h3> </h3>
                  </div>
                  <div className="text-center">
                    <h3>{Camelize(userProfile?.firstName + " " + userProfile?.lastName)}</h3>
                    <div className="h5 font-weight-300">{userDetails?.user?.email}</div>
                    <br />
                  </div>
                </CardBody>
              </Card>
            </Col>
          )}

          {/* Left profile summary */}

          {/* Welcome message */}
          <Col className="order-xl-1" xl="8" style={{ display: editSection === "WELCOME" ? "block" : "none" }}>
            {/* <Card className="bg-secondary shadow" style={{ backgroundImage: "url(" + require("../../../assets/img/theme/img_bg.jpg").default + ")", backgroundPosition: "center", backgroundSize: "cover", }}> */}
            <Card>
              <CardBody className="d-flex align-items-center">
                {rtlAlignment === RTL_FLAG_RIGHT ? (
                  <Row style={{ textAlign: "right" }}>
                    <Col>
                      <h1 className="display-2" style={ChsStyles.Head}>
                        {t("profile.hello")} {Lodash.isEmpty(userProfile?.title) ? "" : (userProfile?.title ? userProfile?.title + ". " : "") + Camelize(userProfile?.firstName + " " + userProfile?.lastName)}
                      </h1>
                      <p className="mt-0 mb-5" style={ChsStyles.SubTopic}>
                        {t("profile.text")}
                      </p>
                    </Col>
                  </Row>
                ) : (
                  <Row>
                    <Col lg="10" md="10">
                      <h1 className="display-2" style={ChsStyles.Head}>
                        {t("profile.hello")} {Lodash.isEmpty(userProfile?.title) ? "" : (userProfile?.title ? userProfile?.title + ". " : "") + Camelize(userProfile?.firstName + " " + userProfile?.lastName)}
                      </h1>
                      <p className="mt-0 mb-5" style={ChsStyles.SubTopic}>
                        {t("profile.text")}
                      </p>
                    </Col>
                  </Row>
                )}
              </CardBody>
            </Card>
          </Col>

          {/* Change password */}
          <Col className="order-xl-1" xl="8" style={{ display: editSection === "PASSWORD" ? "block" : "none" }}>
            <Card style={{ textAlign: rtlAlignment }}>
              <CardBody>
                <Form role="form" onSubmit={formikChangePassword.handleSubmit} onReset={formikChangePassword.handleReset}>
                  <div className="heading-title mb-4" style={ChsStyles.Topic}>
                    {t("profile.changePassword")}
                  </div>

                  <br />
                  <hr className="my-2" />
                  {rtlAlignment === RTL_FLAG_RIGHT ? (
                    <Row>
                      <Col lg="6">
                        <FormControl fullWidth>
                          <label className="form-control-label" htmlFor="input-first-name">
                            {t("profile.currentPassword")}
                          </label>
                          <RtlTextField
                            style={ChsStyles.Input}
                            fullWidth
                            rtol={rtlAlignment === RTL_FLAG_RIGHT}
                            id="currentPassword"
                            name="currentPassword"
                            variant="outlined"
                            type={showPassword ? "text" : "password"}
                            value={formikChangePassword.values.currentPassword}
                            onChange={formikChangePassword.handleChange}
                            error={formikChangePassword.touched.currentPassword && Boolean(formikChangePassword.errors.currentPassword)}
                            helperText={formikChangePassword.touched.currentPassword && formikChangePassword.errors.currentPassword}
                            className={"form-control-alternative form-control-edit"}
                          />
                        </FormControl>
                      </Col>
                    </Row>
                  ) : (
                    <Row>
                      <Col lg="6">
                        <FormControl fullWidth>
                          <label className="form-control-label" htmlFor="input-first-name">
                            {t("profile.currentPassword")}
                          </label>
                          <RtlTextField
                            style={ChsStyles.Input}
                            fullWidth
                            rtol={rtlAlignment === RTL_FLAG_RIGHT}
                            id="currentPassword"
                            name="currentPassword"
                            variant="outlined"
                            type={showPassword ? "text" : "password"}
                            value={formikChangePassword.values.currentPassword}
                            onChange={formikChangePassword.handleChange}
                            error={formikChangePassword.touched.currentPassword && Boolean(formikChangePassword.errors.currentPassword)}
                            helperText={formikChangePassword.touched.currentPassword && formikChangePassword.errors.currentPassword}
                            className={"form-control-alternative form-control-edit"}
                          />
                        </FormControl>
                      </Col>
                    </Row>
                  )}
                  {rtlAlignment === RTL_FLAG_RIGHT ? (
                    <Row>
                      <Col lg="6">
                        <FormControl fullWidth>
                          <label className="form-control-label" htmlFor="input-first-name">
                            {t("profile.newPassword")}
                          </label>
                          <RtlTextField
                            style={ChsStyles.Input}
                            fullWidth
                            rtol={rtlAlignment === RTL_FLAG_RIGHT}
                            id="password"
                            name="password"
                            variant="outlined"
                            type={showPassword ? "text" : "password"}
                            value={formikChangePassword.values.password}
                            onChange={formikChangePassword.handleChange}
                            error={formikChangePassword.touched.password && Boolean(formikChangePassword.errors.password)}
                            helperText={formikChangePassword.touched.password && formikChangePassword.errors.password}
                            className={"form-control-alternative form-control-edit"}
                          />
                        </FormControl>
                      </Col>
                      <Col lg="6">
                        {/* {showPassword ? null : } */}
                        <FormControl fullWidth>
                          <label className="form-control-label" htmlFor="input-last-name">
                            {t("profile.retypeNewPassword")}
                          </label>
                          <RtlTextField
                            style={ChsStyles.Input}
                            fullWidth
                            rtol={rtlAlignment === RTL_FLAG_RIGHT}
                            id="confirmPassword"
                            name="confirmPassword"
                            variant="outlined"
                            type={showPassword ? "text" : "password"}
                            value={formikChangePassword.values.confirmPassword}
                            onChange={formikChangePassword.handleChange}
                            error={formikChangePassword.touched.confirmPassword && Boolean(formikChangePassword.errors.confirmPassword)}
                            helperText={formikChangePassword.touched.confirmPassword && formikChangePassword.errors.confirmPassword}
                            className={"form-control-alternative form-control-edit"}
                          />
                        </FormControl>
                      </Col>
                    </Row>
                  ) : (
                    <Row>
                      <Col lg="6">
                        <FormControl fullWidth>
                          <label className="form-control-label" htmlFor="input-first-name">
                            {t("profile.newPassword")}
                          </label>
                          <RtlTextField
                            style={ChsStyles.Input}
                            fullWidth
                            rtol={rtlAlignment === RTL_FLAG_RIGHT}
                            id="password"
                            name="password"
                            variant="outlined"
                            type={showPassword ? "text" : "password"}
                            value={formikChangePassword.values.password}
                            onChange={formikChangePassword.handleChange}
                            error={formikChangePassword.touched.password && Boolean(formikChangePassword.errors.password)}
                            helperText={formikChangePassword.touched.password && formikChangePassword.errors.password}
                            className={"form-control-alternative form-control-edit"}
                          />
                        </FormControl>
                      </Col>
                      <Col lg="6">
                        {/* {showPassword ? null : } */}
                        <FormControl fullWidth>
                          <label className="form-control-label" htmlFor="input-last-name">
                            {t("profile.retypeNewPassword")}
                          </label>
                          <RtlTextField
                            style={ChsStyles.Input}
                            fullWidth
                            rtol={rtlAlignment === RTL_FLAG_RIGHT}
                            id="confirmPassword"
                            name="confirmPassword"
                            variant="outlined"
                            type={showPassword ? "text" : "password"}
                            value={formikChangePassword.values.confirmPassword}
                            onChange={formikChangePassword.handleChange}
                            error={formikChangePassword.touched.confirmPassword && Boolean(formikChangePassword.errors.confirmPassword)}
                            helperText={formikChangePassword.touched.confirmPassword && formikChangePassword.errors.confirmPassword}
                            className={"form-control-alternative form-control-edit"}
                          />
                        </FormControl>
                      </Col>
                    </Row>
                  )}

                  <br />
                  <div className="text-center">
                    <Button variant="contained" color="primary" type="reset">
                      {t("profile.reset")}
                    </Button>
                    <Button variant="contained" color="primary" type="submit">
                      {t("profile.change")}
                    </Button>
                  </div>
                </Form>
              </CardBody>
            </Card>
          </Col>

          {/* Update profile */}
          <Col className="order-xl-1" xl="8" style={{ display: editSection === "PROFILE" ? "block" : "none" }}>
            <Card style={{ textAlign: rtlAlignment }}>
              <CardBody>
                <Form role="form" onSubmit={formik.handleSubmit} onReset={formik.handleReset}>
                  <div className="heading-title mb-4" style={ChsStyles.Topic}>
                    {t("profile.userProfile")}
                  </div>

                  <br />
                  <div className="pl-lg-4">
                    <hr className="my-2" />
                    <h6 className="heading-small mb-4" style={ChsStyles.SubTopic}>
                      {t("profile.personalDetails")}
                    </h6>

                    <Row>
                      {rtlAlignment === RTL_FLAG_RIGHT ? <Col md="4" /> : null}
                      <Col lg="8">
                        <RtlTextField
                          fullWidth
                          rtol={rtlAlignment === RTL_FLAG_RIGHT}
                          style={ChsStyles.Input}
                          id="email"
                          name="email"
                          variant="outlined"
                          label={t("profile.emailAddress")}
                          value={formik.values.email}
                          onChange={formik.handleChange}
                          error={formik.touched.email && Boolean(formik.errors.email)}
                          helperText={formik.touched.email && formik.errors.email}
                          className="form-control-alternative form-control-display form-control-css"
                          InputProps={{ readOnly: true, disabled: true }}
                        />
                      </Col>
                    </Row>

                    <Row>
                      <Col lg="6">
                        <RtlTextField
                          fullWidth
                          rtol={rtlAlignment === RTL_FLAG_RIGHT}
                          style={ChsStyles.Input}
                          id="firstName"
                          name="firstName"
                          variant="outlined"
                          label={t("profile.firstName")}
                          value={formik.values.firstName}
                          onChange={formik.handleChange}
                          error={formik.touched.firstName && Boolean(formik.errors.firstName)}
                          helperText={formik.touched.firstName && formik.errors.firstName}
                          className={isEdit ? "form-control-alternative form-control-edit" : "form-control-alternative form-control-display"}
                          InputProps={{
                            readOnly: !isEdit,
                            disabled: !isEdit,
                          }}
                        />
                      </Col>

                      <Col lg="6">
                        <RtlTextField
                          fullWidth
                          rtol={rtlAlignment === RTL_FLAG_RIGHT}
                          style={ChsStyles.Input}
                          id="lastName"
                          name="lastName"
                          variant="outlined"
                          label={t("profile.lastName")}
                          value={formik.values.lastName}
                          onChange={formik.handleChange}
                          error={formik.touched.lastName && Boolean(formik.errors.lastName)}
                          helperText={formik.touched.lastName && formik.errors.lastName}
                          className={isEdit ? "form-control-alternative form-control-edit" : "form-control-alternative form-control-display"}
                          InputProps={{
                            readOnly: !isEdit,
                            disabled: !isEdit,
                          }}
                        />
                      </Col>
                    </Row>

                    {isEdit && (
                      <Row>
                        <Col>
                          <RTLFileUpload
                            fullWidth
                            rtol={rtlAlignment}
                            style={ChsStyles.Input}
                            id="profilePicture"
                            name="profilePicture"
                            variant="outlined"
                            label={t("profile.profilePicture")}
                            value={formik.values.profilePicture}
                            className={"form-control-alternative form-control-edit"}
                            error={formik.touched.profilePicture && Boolean(formik.errors.profilePicture)}
                            helperText={formik.touched.profilePicture && formik.errors.profilePicture}
                            acceptFilter="image/png, image/jpeg"
                            acceptPattern={['jpg', 'jpeg', 'png']}
                            onChange={(e) => {
                              handleFileChange(e);
                            }}
                          />
                        </Col>
                      </Row>
                    )}

                    <Row>
                      {rtlAlignment === RTL_FLAG_RIGHT ? <Col md="4" /> : null}
                      <Col md="8">
                        <RtlTextField
                          select
                          rtol={rtlAlignment === RTL_FLAG_RIGHT}
                          style={ChsStyles.Input}
                          fullWidth
                          id="language"
                          name="language"
                          variant="outlined"
                          label={t("profile.language")}
                          value={formik.values.language}
                          onChange={formik.handleChange}
                          SelectProps={{
                            native: true,
                          }}
                          className={"form-control-alternative form-control-edit"}
                        >
                          <option></option>
                          {language.map((item) => (
                            <>
                              {/* option1: better */}
                              <option style={{ textAlign: rtlAlignment === RTL_FLAG_RIGHT ? "right" : "left" }} key={item.name} value={item.code}>
                                {item.name}
                              </option>
                              {/* option2 */}
                              {/* <MenuItem style={{ justifyContent: 'flex-end' }} key={item.name} value={item.code}>
                                  {item.name}
                                </MenuItem> */}
                            </>
                          ))}
                        </RtlTextField>
                      </Col>
                    </Row>

                    <Row>
                      {rtlAlignment === RTL_FLAG_RIGHT ? <Col md="4" /> : null}
                      <Col md="8">
                        <RtlTextField
                          fullWidth
                          rtol={rtlAlignment === RTL_FLAG_RIGHT}
                          style={ChsStyles.Input}
                          id="emp_designation"
                          name="emp_designation"
                          variant="outlined"
                          label={t("profile.designation")}
                          value={formik.values.emp_designation}
                          onChange={formik.handleChange}
                          SelectProps={{
                            native: true,
                          }}
                          className={"form-control-alternative form-control-edit"}
                          error={formik.touched.emp_designation && Boolean(formik.errors.emp_designation)}
                          helperText={formik.touched.emp_designation && formik.errors.emp_designation}
                        ></RtlTextField>
                      </Col>
                    </Row>

                    <div className="text-center">
                      <Button variant="contained" color="primary" type="reset">
                        {t("profile.reset")}
                      </Button>
                      <Button variant="contained" color="primary" type="submit">
                        {t("profile.update")}
                      </Button>
                    </div>
                  </div>
                </Form>
              </CardBody>
            </Card>

            <br />

            <Card
              style={{
                display: userDetails?.user?.urole === "GENERAL" ? "block" : "none",
                textAlign: rtlAlignment,
              }}
            >
              <CardBody>
                <Form role="form" onSubmit={formik1.handleSubmit} onReset={formik1.handleReset}>
                  {/* ORGANIZATIONAL PROFILE */}
                  <div className="heading-title mb-4" style={ChsStyles.Topic}>
                    {t("profile.organizationalProfile")}
                  </div>
                  <div className="pl-lg-4">
                    <hr className="my-2" />
                    <h6 className="heading-small mb-4" style={ChsStyles.SubTopic}>
                      {t("profile.organizationalDetails")}
                    </h6>
                    <Row>
                      {rtlAlignment === RTL_FLAG_RIGHT ? <Col md="4" /> : null}
                      <Col md="8">
                        <RtlTextField
                          fullWidth
                          rtol={rtlAlignment === RTL_FLAG_RIGHT}
                          id="org_country"
                          name="org_country"
                          variant="outlined"
                          label={t("profile.country")}
                          value={formik1.values.country}
                          onChange={formik1.handleChange}
                          SelectProps={{
                            native: true,
                          }}
                          className={"form-control-alternative form-control-edit"}
                          error={formik1.touched.country && Boolean(formik1.errors.country)}
                          helperText={formik1.touched.country && formik1.errors.country}
                          disabled={true}
                        ></RtlTextField>
                      </Col>
                    </Row>

                    <br />
                    <Row>
                      <Col md="12">
                        <RtlTextField
                          fullWidth
                          rtol={rtlAlignment === RTL_FLAG_RIGHT}
                          id="emp_institute"
                          name="emp_institute"
                          variant="outlined"
                          label={t("profile.organizationName")}
                          value={formik1.values.organization}
                          onChange={formik1.handleChange}
                          SelectProps={{
                            native: true,
                          }}
                          className={"form-control-alternative form-control-edit"}
                          error={formik1.touched.organization && Boolean(formik1.errors.organization)}
                          helperText={formik1.touched.organization && formik1.errors.organization}
                          disabled={true}
                        ></RtlTextField>
                      </Col>
                    </Row>
                    <br />
                    <hr className="my-2" />
                    <h6 className="heading-small mb-4" style={ChsStyles.SubTopic}>
                      {t("profile.insitutionalDetails")}
                    </h6>

                    <Row>
                      <Col md="6" style={{ marginBottom: 10 }}>
                        {/* type of organization */}
                        <RtlTextField
                          select
                          rtol={rtlAlignment === RTL_FLAG_RIGHT}
                          style={ChsStyles.Input}
                          fullWidth
                          id="type"
                          name="type"
                          variant="outlined"
                          label={t("profile.typeOfOrganization")}
                          value={formik1.values.type}
                          onChange={formik1.handleChange}
                          SelectProps={{
                            native: true,
                          }}
                          className={"form-control-alternative form-control-edit"}
                          error={formik1.touched.type && Boolean(formik1.errors.type)}
                          helperText={formik1.touched.type && formik1.errors.type}
                          disabled={!isOrgOwner}
                        >
                          {/* option1: better */}
                          <option></option>
                          {emp_types.map((item) => (
                            <option style={{ textAlign: rtlAlignment === RTL_FLAG_RIGHT ? "right" : "left" }} key={item} value={item}>
                              {t("profile." + item)}
                            </option>
                          ))}
                        </RtlTextField>
                      </Col>

                      <Col md="6" style={{ marginBottom: 10 }}>
                        {/* geographic area of operations */}
                        <RtlTextField
                          select
                          rtol={rtlAlignment === RTL_FLAG_RIGHT}
                          style={ChsStyles.Input}
                          fullWidth
                          id="geographicArea"
                          name="geographicArea"
                          variant="outlined"
                          label={t("profile.geoGraphicArea")}
                          value={formik1.values.geographicArea}
                          onChange={formik1.handleChange}
                          SelectProps={{
                            native: true,
                          }}
                          className={"form-control-alternative form-control-edit"}
                          error={formik1.touched.geographicArea && Boolean(formik1.errors.geographicArea)}
                          helperText={formik1.touched.geographicArea && formik1.errors.geographicArea}
                          disabled={!isOrgOwner}
                        >
                          <option></option>
                          {geo_areas.map((item) => (
                            <option style={{ textAlign: rtlAlignment === RTL_FLAG_RIGHT ? "right" : "left" }} key={item} value={item}>
                              {t("profile." + item)}
                            </option>
                          ))}
                        </RtlTextField>
                      </Col>
                    </Row>

                    <br />

                    <Row>
                      <Col md="12">
                        <FormControl fullWidth style={ChsStyles.Input}>
                          {/* legal entity */}
                          <label className="form-control-label" htmlFor="input-institute">
                            {t("profile.legalEntity")}
                          </label>
                        </FormControl>
                      </Col>
                      <Col md="3" style={{ marginBottom: 10 }}>
                        <RtlTextField
                          select
                          rtol={rtlAlignment === RTL_FLAG_RIGHT}
                          style={ChsStyles.Input}
                          fullWidth
                          id="legalStatus_status"
                          name="legalStatus_status"
                          variant="outlined"
                          label={t("profile.legalEntityAnswer")}
                          value={formik1.values.legalStatus_status}
                          onChange={formik1.handleChange}
                          SelectProps={{
                            native: true,
                          }}
                          className={"form-control-alternative form-control-edit"}
                          error={formik1.touched.legalStatus_status && Boolean(formik1.errors.legalStatus_status)}
                          helperText={formik1.touched.legalStatus_status && formik1.errors.legalStatus_status}
                          disabled={!isOrgOwner}
                        >
                          <option></option>
                          {legal_entitys.map((item) => (
                            <option style={{ textAlign: rtlAlignment === RTL_FLAG_RIGHT ? "right" : "left" }} key={item} value={item}>
                              {t("profile." + item)}
                            </option>
                          ))}
                        </RtlTextField>
                      </Col>

                      <Col md="9" style={{ marginBottom: 10 }}>
                        <RtlTextField
                          fullWidth
                          rtol={rtlAlignment === RTL_FLAG_RIGHT}
                          id="legalStatus_mandate"
                          name="legalStatus_mandate"
                          variant="outlined"
                          label={t("profile.legalEntityMandate")}
                          multiLine={true}
                          rows={4}
                          value={formik1.values.legalStatus_mandate}
                          onChange={formik1.handleChange}
                          className={"form-control-alternative form-control-edit"}
                          error={formik1.touched.legalStatus_mandate && Boolean(formik1.errors.legalStatus_mandate)}
                          helperText={formik1.touched.legalStatus_mandate && formik1.errors.legalStatus_mandate}
                          disabled={!isOrgOwner}
                        ></RtlTextField>
                      </Col>
                    </Row>
                    <br />

                    <Row>
                      <Col md="12">
                        <FormControl fullWidth>
                          {/* number of employees */}
                          <label className="form-control-label" htmlFor="input-institute">
                            {t("profile.numberOfEmployee")}
                          </label>
                        </FormControl>
                      </Col>
                      <Col md="3" style={{ marginBottom: 10 }}>
                        <RtlTextField
                          fullWidth
                          rtol={rtlAlignment === RTL_FLAG_RIGHT}
                          id="numberOfOrganizationEmployees_number"
                          name="numberOfOrganizationEmployees_number"
                          variant="outlined"
                          label={t("profile.answer")}
                          value={formik1.values.numberOfOrganizationEmployees_number}
                          onChange={formik1.handleChange}
                          className={"form-control-alternative form-control-edit"}
                          error={formik1.touched.numberOfOrganizationEmployees_number && Boolean(formik1.errors.numberOfOrganizationEmployees_number)}
                          helperText={formik1.touched.numberOfOrganizationEmployees_number && formik1.errors.numberOfOrganizationEmployees_number}
                          disabled={!isOrgOwner}
                        ></RtlTextField>
                      </Col>

                      <Col md="9" style={{ marginBottom: 10 }}>
                        <RtlTextField
                          fullWidth
                          rtol={rtlAlignment === RTL_FLAG_RIGHT}
                          id="numberOfOrganizationEmployees_remark"
                          name="numberOfOrganizationEmployees_remark"
                          variant="outlined"
                          label={t("profile.remark")}
                          multiLine={true}
                          rows={4}
                          value={formik1.values.numberOfOrganizationEmployees_remark}
                          onChange={formik1.handleChange}
                          SelectProps={{
                            native: true,
                          }}
                          className={"form-control-alternative form-control-edit"}
                          error={formik1.touched.numberOfOrganizationEmployees_remark && Boolean(formik1.errors.numberOfOrganizationEmployees_remark)}
                          helperText={formik1.touched.numberOfOrganizationEmployees_remark && formik1.errors.numberOfOrganizationEmployees_remark}
                          disabled={!isOrgOwner}
                        ></RtlTextField>
                      </Col>
                    </Row>
                    <br />

                    <Row>
                      {rtlAlignment === RTL_FLAG_RIGHT ? <Col md="6" style={{ marginBottom: 10 }}></Col> : null}
                      <Col md="6" style={{ marginBottom: 10 }}>
                        {/* DRM activities */}
                        <RtlTextField
                          select
                          rtol={rtlAlignment === RTL_FLAG_RIGHT}
                          style={ChsStyles.Input}
                          fullWidth
                          id="drmActivities"
                          name="drmActivities"
                          variant="outlined"
                          label={t("profile.drmActivities")}
                          value={formik1.values.drmActivities}
                          onChange={formik1.handleChange}
                          SelectProps={{
                            native: true,
                          }}
                          className={"form-control-alternative form-control-edit"}
                          error={formik1.touched.drmActivities && Boolean(formik1.errors.drmActivities)}
                          helperText={formik1.touched.drmActivities && formik1.errors.drmActivities}
                          disabled={!isOrgOwner}
                        >
                          <option></option>
                          {drm_acts.map((item) => (
                            <option style={{ textAlign: rtlAlignment === RTL_FLAG_RIGHT ? "right" : "left" }} key={item} value={item}>
                              {t("profile." + item)}
                            </option>
                          ))}
                        </RtlTextField>
                      </Col>
                    </Row>
                    <br />

                    <Row>
                      <Col md="12">
                        <FormControl fullWidth>
                          {/* vision mission */}
                          <label className="form-control-label" htmlFor="input-institute">
                            {t("profile.visionMission")}
                          </label>
                        </FormControl>
                      </Col>
                      <Col md="3" style={{ marginBottom: 10 }}>
                        <RtlTextField
                          select
                          rtol={rtlAlignment === RTL_FLAG_RIGHT}
                          style={ChsStyles.Input}
                          fullWidth
                          id="visionMission_status"
                          name="visionMission_status"
                          variant="outlined"
                          label={t("profile.answer")}
                          value={formik1.values.visionMission_status}
                          onChange={formik1.handleChange}
                          SelectProps={{
                            native: true,
                          }}
                          className={"form-control-alternative form-control-edit"}
                          error={formik1.touched.visionMission_status && Boolean(formik1.errors.visionMission_status)}
                          helperText={formik1.touched.visionMission_status && formik1.errors.visionMission_status}
                          disabled={!isOrgOwner}
                        >
                          <option></option>
                          {vision.map((item) => (
                            <option style={{ textAlign: rtlAlignment === RTL_FLAG_RIGHT ? "right" : "left" }} key={item} value={item}>
                              {t("profile." + item)}
                            </option>
                          ))}
                        </RtlTextField>
                      </Col>

                      <Col md="9" style={{ marginBottom: 10 }}>
                        <RtlTextField
                          fullWidth
                          rtol={rtlAlignment === RTL_FLAG_RIGHT}
                          id="visionMission_remarks"
                          name="visionMission_remarks"
                          variant="outlined"
                          label={t("profile.remark")}
                          multiLine={true}
                          rows={4}
                          value={formik1.values.visionMission_remarks}
                          onChange={formik1.handleChange}
                          SelectProps={{
                            native: true,
                          }}
                          className={"form-control-alternative form-control-edit"}
                          error={formik1.touched.visionMission_remarks && Boolean(formik1.errors.visionMission_remarks)}
                          helperText={formik1.touched.visionMission_remarks && formik1.errors.visionMission_remarks}
                          disabled={!isOrgOwner}
                        ></RtlTextField>
                      </Col>
                    </Row>
                    <br />

                    <Row>
                      <Col md="12">
                        <FormControl fullWidth>
                          {/* functional structure */}
                          <label className="form-control-label" htmlFor="input-institute">
                            {t("profile.functionalStucture")}
                          </label>
                        </FormControl>
                      </Col>
                      <Col md="3" style={{ marginBottom: 10 }}>
                        <RtlTextField
                          select
                          rtol={rtlAlignment === RTL_FLAG_RIGHT}
                          style={ChsStyles.Input}
                          fullWidth
                          id="functionalStructure_status"
                          name="functionalStructure_status"
                          variant="outlined"
                          label={t("profile.answer")}
                          value={formik1.values.functionalStructure_status}
                          onChange={formik1.handleChange}
                          SelectProps={{
                            native: true,
                          }}
                          className={"form-control-alternative form-control-edit"}
                          error={formik1.touched.functionalStructure_status && Boolean(formik1.errors.functionalStructure_status)}
                          helperText={formik1.touched.functionalStructure_status && formik1.errors.functionalStructure_status}
                          disabled={!isOrgOwner}
                        >
                          <option></option>
                          {functional.map((item) => (
                            <option style={{ textAlign: rtlAlignment === RTL_FLAG_RIGHT ? "right" : "left" }} key={item} value={item}>
                              {t("profile." + item)}
                            </option>
                          ))}
                        </RtlTextField>
                      </Col>

                      <Col md="9" style={{ marginBottom: 10 }}>
                        <RtlTextField
                          fullWidth
                          rtol={rtlAlignment === RTL_FLAG_RIGHT}
                          id="functionalStructure_remarks"
                          name="functionalStructure_remarks"
                          variant="outlined"
                          label={t("profile.remark")}
                          multiLine={true}
                          rows={4}
                          value={formik1.values.functionalStructure_remarks}
                          onChange={formik1.handleChange}
                          SelectProps={{
                            native: true,
                          }}
                          className={"form-control-alternative form-control-edit"}
                          error={formik1.touched.functionalStructure_remarks && Boolean(formik1.errors.functionalStructure_remarks)}
                          helperText={formik1.touched.functionalStructure_remarks && formik1.errors.functionalStructure_remarks}
                          disabled={!isOrgOwner}
                        ></RtlTextField>
                      </Col>
                    </Row>
                    <br />

                    <Row>
                      <Col md="12">
                        <FormControl fullWidth>
                          {/* monitoring and evaluation */}
                          <label className="form-control-label" htmlFor="input-institute">
                            {t("profile.unitProject")}
                          </label>
                        </FormControl>
                      </Col>
                      <Col md="3" style={{ marginBottom: 10 }}>
                        <RtlTextField
                          select
                          rtol={rtlAlignment === RTL_FLAG_RIGHT}
                          style={ChsStyles.Input}
                          fullWidth
                          id="unitProject_status"
                          name="unitProject_status"
                          variant="outlined"
                          label={t("profile.answer")}
                          value={formik1.values.unitProject_status}
                          onChange={formik1.handleChange}
                          SelectProps={{
                            native: true,
                          }}
                          className={"form-control-alternative form-control-edit"}
                          error={formik1.touched.unitProject_status && Boolean(formik1.errors.unitProject_status)}
                          helperText={formik1.touched.unitProject_status && formik1.errors.unitProject_status}
                          disabled={!isOrgOwner}
                        >

                          <option></option>
                          {unit.map((item) => (
                            <option style={{ textAlign: rtlAlignment === RTL_FLAG_RIGHT ? "right" : "left" }} key={item} value={item}>
                              {t("profile." + item)}
                            </option>
                          ))}
                        </RtlTextField>
                      </Col>

                      <Col md="9" style={{ marginBottom: 10 }}>
                        <RtlTextField
                          fullWidth
                          rtol={rtlAlignment === RTL_FLAG_RIGHT}
                          id="unitProject_remarks"
                          name="unitProject_remarks"
                          variant="outlined"
                          label={t("profile.remark")}
                          multiLine={true}
                          rows={4}
                          value={formik1.values.unitProject_remarks}
                          onChange={formik1.handleChange}
                          SelectProps={{
                            native: true,
                          }}
                          className={"form-control-alternative form-control-edit"}
                          error={formik1.touched.unitProject_remarks && Boolean(formik1.errors.unitProject_remarks)}
                          helperText={formik1.touched.unitProject_remarks && formik1.errors.unitProject_remarks}
                          disabled={!isOrgOwner}
                        ></RtlTextField>
                      </Col>
                    </Row>
                    <br />

                    <Row>
                      <Col md="12">
                        <FormControl fullWidth>
                          {/* identified stakeholders */}
                          <label className="form-control-label" htmlFor="input-institute">
                            {t("profile.stakeholder")}
                          </label>
                        </FormControl>
                      </Col>
                      <Col md="3" style={{ marginBottom: 10 }}>
                        <RtlTextField
                          select
                          rtol={rtlAlignment === RTL_FLAG_RIGHT}
                          style={ChsStyles.Input}
                          fullWidth
                          id="unitStakeholders_status"
                          name="unitStakeholders_status"
                          variant="outlined"
                          label={t("profile.answer")}
                          value={formik1.values.unitStakeholders_status}
                          onChange={formik1.handleChange}
                          SelectProps={{
                            native: true,
                          }}
                          className={"form-control-alternative form-control-edit"}
                          error={formik1.touched.unitStakeholders_status && Boolean(formik1.errors.unitStakeholders_status)}
                          helperText={formik1.touched.unitStakeholders_status && formik1.errors.unitStakeholders_status}
                          disabled={!isOrgOwner}
                        >
                          <option></option>
                          {stakeholder.map((item) => (
                            <option style={{ textAlign: rtlAlignment === RTL_FLAG_RIGHT ? "right" : "left" }} key={item} value={item}>
                              {t("profile." + item)}
                            </option>
                          ))}
                        </RtlTextField>
                      </Col>

                      <Col md="9" style={{ marginBottom: 10 }}>
                        <RtlTextField
                          fullWidth
                          rtol={rtlAlignment === RTL_FLAG_RIGHT}
                          id="unitStakeholders_remarks"
                          name="unitStakeholders_remarks"
                          variant="outlined"
                          label={t("profile.remark")}
                          multiLine={true}
                          rows={4}
                          value={formik1.values.unitStakeholders_remarks}
                          onChange={formik1.handleChange}
                          SelectProps={{
                            native: true,
                          }}
                          className={"form-control-alternative form-control-edit"}
                          error={formik1.touched.unitStakeholders_remarks && Boolean(formik1.errors.unitStakeholders_remarks)}
                          helperText={formik1.touched.unitStakeholders_remarks && formik1.errors.unitStakeholders_remarks}
                          disabled={!isOrgOwner}
                        ></RtlTextField>
                      </Col>
                    </Row>
                    <br />

                    <Row>
                      <Col md="12">
                        {/* organization website */}
                        <RtlTextField
                          fullWidth
                          rtol={rtlAlignment === RTL_FLAG_RIGHT}
                          id="website"
                          name="website"
                          variant="outlined"
                          label={t("profile.organizationWebsite")}
                          value={formik1.values.website}
                          onChange={formik1.handleChange}
                          className={"form-control-alternative form-control-edit"}
                          error={formik.touched.website && Boolean(formik.errors.website)}
                          helperText={formik.touched.website && formik.errors.website}
                          disabled={!isOrgOwner}
                        ></RtlTextField>
                      </Col>
                    </Row>
                    <br />

                    <Row>
                      <Col md="10">
                        {/* organization profile */}
                        {/* <RtlTextField
                          fullWidth
                          rtol={rtlAlignment === RTL_FLAG_RIGHT}
                          id="organizationProfile"
                          name="organizationProfile"
                          type="file"
                          variant="outlined"
                          label={t("profile.organizationProfile")}
                          multiple={false}
                          onChange={(e) => {
                            handleFileChangeOrg(e);
                          }}
                          InputLabelProps={{ shrink: true }}
                          style={ChsStyles.Question}
                          className={"form-control-alternative form-control-edit"}
                          error={formik1.touched.organizationProfile && Boolean(formik1.errors.organizationProfile)}
                          helperText={formik1.touched.organizationProfile && formik1.errors.organizationProfile}
                          disabled={!isOrgOwner}
                        /> */}
                        <RTLFileUpload
                          fullWidth
                          rtol={rtlAlignment}
                          style={ChsStyles.Question}
                          id="organizationProfile"
                          name="organizationProfile"
                          variant="outlined"
                          label={t("profile.organizationProfile")}
                          value={formik1.values.oldOrganizationProfile}
                          className={"form-control-alternative form-control-edit"}
                          error={formik1.touched.organizationProfile && Boolean(formik1.errors.organizationProfile)}
                          helperText={formik1.touched.organizationProfile && formik1.errors.organizationProfile}
                          acceptFilter="application/pdf"
                          acceptPattern={['pdf']}
                          onChange={(e) => {
                            handleFileChangeOrg(e);
                          }}
                          disabled={!isOrgOwner}
                        />
                      </Col>
                      <Col md="2">
                        <div>
                          {(!Lodash.isEmpty(formik1.values.oldOrganizationProfile)) ?
                            <IconButton
                              onClick={(e) => {
                                console.log("organizationProfile", formik1.values.oldOrganizationProfile);
                                e.preventDefault();
                                let pathToResource = Config.SERVER_API_URL + "uploads/organizationProfile/" + formik1.values.oldOrganizationProfile;
                                if (LOGINSTATE) console.log("pathToResource: ", pathToResource);
                                window.open(pathToResource, "_blank");
                              }}
                            >
                              <i className="far fa-eye" style={{}} />
                            </IconButton>
                            : null}
                        </div>
                      </Col>
                    </Row>
                    <br />

                    <hr className="my-2" />
                    {/* branch/location information */}
                    <h6 className="heading-small mb-4" style={ChsStyles.SubTopic}>
                      {t("profile.branchDetails")}
                    </h6>

                    <Row>
                      <Col md="12">
                        {/* location */}
                        <RtlTextField
                          fullWidth
                          rtol={rtlAlignment === RTL_FLAG_RIGHT}
                          id="city_name"
                          name="city_name"
                          variant="outlined"
                          label={t("profile.location")}
                          value={formik1.values.city_name}
                          onChange={formik1.handleChange}
                          SelectProps={{
                            native: true,
                          }}
                          className={"form-control-alternative form-control-edit"}
                          error={formik1.touched.city_name && Boolean(formik1.errors.city_name)}
                          helperText={formik1.touched.city_name && formik1.errors.city_name}
                          disabled={true}
                        ></RtlTextField>
                      </Col>
                    </Row>
                    <br />

                    <Row>
                      <Col md="12">
                        <FormControl fullWidth style={ChsStyles.Input}>
                          {/* number of employees */}
                          <label className="form-control-label" htmlFor="input-institute">
                            {t("profile.number")}
                          </label>
                        </FormControl>
                      </Col>
                      <Col md="3" style={{ marginBottom: 10 }}>
                        <RtlTextField
                          fullWidth
                          rtol={rtlAlignment === RTL_FLAG_RIGHT}
                          id="city_numberOfEmployees_number"
                          name="city_numberOfEmployees_number"
                          variant="outlined"
                          label={t("profile.answer")}
                          value={formik1.values.city_numberOfEmployees_number}
                          onChange={formik1.handleChange}
                          SelectProps={{
                            native: true,
                          }}
                          className={"form-control-alternative form-control-edit"}
                          error={formik1.touched.city_numberOfEmployees_number && Boolean(formik1.errors.city_numberOfEmployees_number)}
                          helperText={formik1.touched.city_numberOfEmployees_number && formik1.errors.city_numberOfEmployees}
                          disabled={!isCityOwner}
                        ></RtlTextField>
                      </Col>

                      <Col md="9" style={{ marginBottom: 10 }}>
                        <RtlTextField
                          fullWidth
                          rtol={rtlAlignment === RTL_FLAG_RIGHT}
                          id="city_numberOfEmployees_remarks"
                          name="city_numberOfEmployees_remarks"
                          variant="outlined"
                          label={t("profile.remark")}
                          multiLine={true}
                          rows={4}
                          value={formik1.values.city_numberOfEmployees_remarks}
                          onChange={formik1.handleChange}
                          SelectProps={{
                            native: true,
                          }}
                          className={"form-control-alternative form-control-edit"}
                          error={formik1.touched.city_numberOfEmployees_remarks && Boolean(formik1.errors.city_numberOfEmployees_remarks)}
                          helperText={formik1.touched.city_numberOfEmployees_remarks && formik1.errors.city_numberOfEmployees_remarks}
                          disabled={!isCityOwner}
                        ></RtlTextField>
                      </Col>
                    </Row>
                    <br />

                    <Row>
                      <Col md="12">
                        <FormControl fullWidth style={ChsStyles.Input}>
                          {/* DRM activities */}
                          <label className="form-control-label" htmlFor="input-institute">
                            {t("profile.drm")}
                          </label>
                        </FormControl>
                      </Col>
                      <Col md="4" style={{ marginBottom: 10 }}>
                        <RtlTextField
                          select
                          rtol={rtlAlignment === RTL_FLAG_RIGHT}
                          style={ChsStyles.Input}
                          fullWidth
                          id="city_drmActivities_performed"
                          name="city_drmActivities_performed"
                          label={t("profile.answer")}
                          variant="outlined"
                          value={formik1.values.city_drmActivities_performed}
                          onChange={formik1.handleChange}
                          SelectProps={{
                            native: true,
                          }}
                          className={"form-control-alternative form-control-edit"}
                          error={formik1.touched.city_drmActivities_performed && Boolean(formik1.errors.city_drmActivities_performed)}
                          helperText={formik1.touched.city_drmActivities_performed && formik1.errors.city_drmActivities_performed}
                          disabled={!isCityOwner}
                        >
                          <option></option>
                          {city_drm_acts.map((item) => (
                            <option style={{ textAlign: rtlAlignment === RTL_FLAG_RIGHT ? "right" : "left" }} key={item} value={item}>
                              {t("profile." + item)}
                            </option>
                          ))}
                        </RtlTextField>
                      </Col>

                      <Col md="8" style={{ marginBottom: 10 }}>
                        <RtlTextField
                          fullWidth
                          rtol={rtlAlignment === RTL_FLAG_RIGHT}
                          id="city_drmActivities_remarks"
                          name="city_drmActivities_remarks"
                          variant="outlined"
                          label={t("profile.remark")}
                          multiLine={true}
                          rows={4}
                          value={formik1.values.city_drmActivities_remarks}
                          onChange={formik1.handleChange}
                          SelectProps={{
                            native: true,
                          }}
                          className={"form-control-alternative form-control-edit"}
                          error={formik1.touched.city_drmActivities_remarks && Boolean(formik1.errors.city_drmActivities_remarks)}
                          helperText={formik1.touched.city_drmActivities_remarks && formik1.errors.city_drmActivities_remarks}
                          disabled={!isCityOwner}
                        ></RtlTextField>
                      </Col>
                    </Row>
                    <br />

                    <Row>
                      <Col md="6" style={{ marginBottom: 10 }}>
                        {/* is head-office/branch */}
                        <RtlTextField
                          select
                          rtol={rtlAlignment === RTL_FLAG_RIGHT}
                          style={ChsStyles.Input}
                          fullWidth
                          id="city_isHeadOffice"
                          name="city_isHeadOffice"
                          variant="outlined"
                          label={t("profile.istheheadoffice")}
                          value={formik1.values.city_isHeadOffice}
                          onChange={formik1.handleChange}
                          SelectProps={{
                            native: true,
                          }}
                          className={"form-control-alternative form-control-edit"}
                          error={formik1.touched.city_isHeadOffice && Boolean(formik1.errors.city_isHeadOffice)}
                          helperText={formik1.touched.city_isHeadOffice && formik1.errors.city_isHeadOffice}
                          disabled={!isCityOwner}
                        >
                          <option></option>
                          {headoffice.map((item) => (
                            <option style={{ textAlign: rtlAlignment === RTL_FLAG_RIGHT ? "right" : "left" }} key={item} value={item}>
                              {t("profile." + item)}
                            </option>
                          ))}
                        </RtlTextField>
                      </Col>

                      <Col md="6" style={{ marginBottom: 10 }}>
                        {/* office email */}
                        <RtlTextField
                          fullWidth
                          rtol={rtlAlignment === RTL_FLAG_RIGHT}
                          id="city_email"
                          name="city_email"
                          type="email"
                          variant="outlined"
                          label={t("profile.address")}
                          value={formik1.values.city_email}
                          onChange={formik1.handleChange}
                          className={"form-control-alternative form-control-edit"}
                          error={formik1.touched.city_email && Boolean(formik1.errors.city_email)}
                          helperText={formik1.touched.city_email && formik1.errors.city_email}
                          disabled={!isCityOwner}
                        ></RtlTextField>
                      </Col>
                    </Row>
                    <br />

                    <Row>
                      <Col md="12">
                        {/* head-office/branch website */}
                        <RtlTextField
                          fullWidth
                          rtol={rtlAlignment === RTL_FLAG_RIGHT}
                          id="city_website"
                          name="city_website"
                          variant="outlined"
                          label={t("profile.headOffice")}
                          value={formik1.values.city_website}
                          onChange={formik1.handleChange}
                          className={"form-control-alternative form-control-edit"}
                          error={formik1.touched.city_website && Boolean(formik1.errors.city_website)}
                          helperText={formik1.touched.city_website && formik1.errors.city_website}
                          disabled={!isCityOwner}
                        ></RtlTextField>
                      </Col>
                    </Row>
                    <br />

                    <Row>
                      <Col md="12">
                        {/* office address */}
                        <RtlTextField
                          fullWidth
                          rtol={rtlAlignment === RTL_FLAG_RIGHT}
                          id="city_address"
                          name="city_address"
                          variant="outlined"
                          label={t("profile.officeAddress")}
                          value={formik1.values.city_address}
                          multiLine={true}
                          rows={4}
                          onChange={formik1.handleChange}
                          className={"form-control-alternative form-control-edit"}
                          error={formik1.touched.city_address && Boolean(formik1.errors.city_address)}
                          helperText={formik1.touched.city_address && formik1.errors.city_address}
                          disabled={!isCityOwner}
                        ></RtlTextField>
                      </Col>
                    </Row>
                    {/* </div> */}

                    <br />
                    <hr className="my-2" />

                    <div className="pl-lg-4">
                      <p className="small mb-4">{t("profile.mandataryFields")}</p>
                    </div>

                    <br />

                    <div className="text-center">
                      <Button variant="contained" color="primary" type="reset">
                        {t("profile.reset")}
                      </Button>
                      <Button variant="contained" color="primary" type="submit">
                        {t("profile.update")}
                      </Button>
                    </div>
                  </div>
                </Form>
              </CardBody>
            </Card>
          </Col>
        </Row>
      </Container>
    </div>
  );
};

export default Profile;
