import { TextField } from "@material-ui/core";
import { createSurveyTemplate, updateSurveyTemplateById } from "actions/survey";
// core components
import PageHeader from "components/Headers/PageHeader.js";
import { SURVEY_TEMPLATE_LOCAL } from "constants/actionTypes";
import { ChsStyles, GlobalStylesColor } from "constants/globalStyles";
import { Formik } from "formik";
import { processArray, survey_status } from "helper";
import Lodash from "lodash";
import React, { useEffect, useRef, useState } from "react";
import { confirmAlert } from "react-confirm-alert";
import "react-confirm-alert/src/react-confirm-alert.css"; // Import css
import { useDispatch, useSelector } from "react-redux";
import Pagination from "react-responsive-pagination";
import { useHistory } from "react-router-dom";
// reactstrap components
import { Button, Card, CardBody, CardFooter, CardHeader, CardTitle, Col, Container, Form, Row } from "reactstrap";
import * as Yup from "yup";
import { LOGINSTATE } from "../../../config/config.json";

const reviewSchema = Yup.object({
  Title: Yup.string().required().min(3).max(1000),
  Code: Yup.string().required().min(2).max(10),
});

const reviewSurveySchema = Yup.object({
  Title: Yup.string().required().min(3).max(1000),
  Version: Yup.number().required(),
});

const SurveyCreateTemplate = (props) => {
  // dispatch and history
  const dispatch = useDispatch();
  const history = useHistory();

  const initSurvey = {
    version: 0.1,
    title: "",
    description: "",
    type: "",
    status: false,
    segments: [],
  };

  const initSegmentData = {
    title: "",
    description: "",
    segmentCode: "",
    segmentOrder: 0,
    questions: [],
  };

  const [surveyStarted, setSurveyStarted] = useState(false);
  const [surveyNew, setSurveyNew] = useState(initSurvey); // Create survey details
  const [segmentData, setSegmentData] = useState([]); // Segment details
  const [segmentCount, setSegmentCount] = useState(0); // To get the maximum number of segments
  const [segmentCounter, setSegmentCounter] = useState(0); // Current active segment
  const [navigationCounter, setNavigationCounter] = useState(0); // To support footer navigation
  const [showPagination, setShowPagination] = useState(true);

  // To update fomik values
  const formRef = useRef();
  const formSurveyRef = useRef();

  useEffect(() => {
    // Loading sequence for the data
    if (localStorage.getItem(SURVEY_TEMPLATE_LOCAL)) {
      let surveySaved = JSON.parse(localStorage.getItem(SURVEY_TEMPLATE_LOCAL));
      if (LOGINSTATE) console.log("--------------------- local store surveySaved: ", surveySaved);
      if (surveySaved !== undefined && surveySaved !== null && !Lodash.isEmpty(surveySaved)) {
        setInitSurvey(surveySaved);
      }
    }
  }, []);

  const surveySaved = useSelector((state) => state.survey.localSurveyTemplate);
  useEffect(() => {
    if (LOGINSTATE) console.log("---------------> called effect 2: start from local dispatch");
    if (LOGINSTATE) console.log("dispatch surveySaved: ", surveySaved);

    if (surveySaved !== undefined && surveySaved !== null && !Lodash.isEmpty(surveySaved)) {
      setInitSurvey(surveySaved);
    }
  }, [surveySaved]);

  const setInitSurvey = (surveySaved) => {
    if (LOGINSTATE) console.log("surveySaved: ", surveySaved);
    // get active survey details
    if (!Lodash.isEmpty(surveySaved) && surveySaved.segments && !Lodash.isEmpty(surveySaved.segments)) {
      if (LOGINSTATE) console.log("survey is updating...");
      setSurveyNew(surveySaved);
      setSegmentData(surveySaved.segments);
      setSegmentCount(surveySaved.segments.length);
      setSurveyStarted(true);
    } else if (!Lodash.isEmpty(surveySaved)) {
      setSurveyNew(surveySaved);
      setSurveyStarted(true);
    }
  };

  useEffect(() => {
    if (LOGINSTATE) console.log("---------------> called effect 3: populate the forms");
    if (LOGINSTATE) console.log("surveyNew: ", surveyNew);
    if (LOGINSTATE) console.log("segmentData: ", segmentData);

    if (formRef.current && !Lodash.isEmpty(segmentData)) {
      formRef.current?.resetForm();
      formRef.current.setFieldValue("Title", segmentData[segmentCounter].title, false);
      formRef.current.setFieldValue("Description", segmentData[segmentCounter].description, false);
      formRef.current.setFieldValue("Code", segmentData[segmentCounter].segmentCode, false);
    }

    if (formSurveyRef.current && !Lodash.isEmpty(surveyNew)) {
      formSurveyRef.current?.resetForm();
      formSurveyRef.current.setFieldValue("Version", surveyNew.version, false);
      formSurveyRef.current.setFieldValue("Title", surveyNew.title, false);
      formSurveyRef.current.setFieldValue("Description", surveyNew.description, false);
      formSurveyRef.current.setFieldValue("Type", surveyNew.type, false);
    }
  }, [segmentCounter, segmentData, surveyNew, segmentCount]);

  // Add new segment
  const handleNewSegmentClick = async (e) => {
    // If the segment is the first one, clear the formik
    if (formRef.current && segmentData.length === 0) {
      formRef.current?.resetForm();
      formRef.current.setFieldValue("Title", "", false);
      formRef.current.setFieldValue("Description", "", false);
      formRef.current.setFieldValue("Code", "", false);
    }
    let updatedSegments = await processArray(segmentData, "ADD", segmentCounter + 1, initSegmentData);
    setSegmentCount(updatedSegments.length);
    setSegmentData(updatedSegments);
    updateLocalSurvey(updatedSegments);
    if (LOGINSTATE) console.log("updatedSegments: ", updatedSegments);
  };

  const handleDeleteSegmentClick = async (e) => {
    let updatedSegments = await processArray(segmentData, "DELETE", segmentCounter, null);
    setSegmentCounter(segmentCounter <= 0 ? 0 : segmentCounter - 1);
    setSegmentCount(updatedSegments.length);
    setSegmentData(updatedSegments);
    setNavigationCounter(navigationCounter <= 0 ? 0 : navigationCounter - 1); // To manage the change of segment count visually
    updateLocalSurvey(updatedSegments);
    if (LOGINSTATE) console.log("updatedSegments: ", updatedSegments);
    if (LOGINSTATE) console.log("navigationCounter: ", navigationCounter);
  };

  const handleMoveUpSegmentClick = async (e) => {
    let updatedSegments = await processArray(segmentData, "MOVEUP", segmentCounter, null);
    if (!Lodash.isEmpty(updatedSegments)) {
      setSegmentCounter(segmentCounter + 1);
      setSegmentData(updatedSegments);
      updateLocalSurvey(updatedSegments);
    }
    if (LOGINSTATE) console.log("updatedSegments: ", updatedSegments);
  };

  const handleMoveDownSegmentClick = async (e) => {
    let updatedSegments = await processArray(segmentData, "MOVEDOWN", segmentCounter, null);
    if (!Lodash.isEmpty(updatedSegments)) {
      setSegmentCounter(segmentCounter - 1);
      setSegmentData(updatedSegments);
      updateLocalSurvey(updatedSegments);
    }
    if (LOGINSTATE) console.log("updatedSegments: ", updatedSegments);
  };

  const updateSegment = async (values) => {
    let updatedSegment = segmentData[segmentCounter];
    updatedSegment["title"] = values.Title;
    updatedSegment["description"] = values.Description;
    updatedSegment["segmentCode"] = values.Code;
    updatedSegment["segmentOrder"] = segmentCounter;

    let updatedSegments = await processArray(segmentData, "UPDATEAT", segmentCounter, updatedSegment);
    setSegmentData(updatedSegments);
    updateLocalSurvey(updatedSegments);
  };

  // save the survey's main details
  const updateSurveyDetails = async (values) => {
    let survey = surveyNew;
    survey["version"] = values.Version;
    survey["title"] = values.Title;
    survey["description"] = values.Description;
    // survey["type"] = values.Type;
    setSurveyNew(survey);
    dispatch({ type: SURVEY_TEMPLATE_LOCAL, payload: survey });
    localStorage.setItem(SURVEY_TEMPLATE_LOCAL, JSON.stringify(survey));
  };

  // save the survey's segment details
  const updateLocalSurvey = (updatedSegments) => {
    // Locally save the survey
    let survey = surveyNew;
    survey["segments"] = updatedSegments;
    setSurveyNew(survey);
    dispatch({ type: SURVEY_TEMPLATE_LOCAL, payload: survey });
    localStorage.setItem(SURVEY_TEMPLATE_LOCAL, JSON.stringify(survey));
  };

  // TODO: check whether its need to save the segment before saving questions.
  const handleQuestionsClick = (e) => {
    history.push({ pathname: "/registered/assessment_segment_question", state: { segmentCounter: segmentCounter, segmentData: segmentData } });
  };

  // TODO: properly save the survey. Currenly not validating the survey before it's save to the surver.
  const handleSurveySave = (isComplete) => {
    let surveySaved = null;

    if (localStorage.getItem(SURVEY_TEMPLATE_LOCAL)) {
      surveySaved = JSON.parse(localStorage.getItem(SURVEY_TEMPLATE_LOCAL));
      if (LOGINSTATE) console.log("local store surveySaved: ", surveySaved);
    }

    // Check whether data is available
    if (surveySaved !== undefined && surveySaved !== null && !Lodash.isEmpty(surveySaved)) {
      // Add a validation method

      if (!(surveySaved.segments && surveySaved.segments.length > 0)) {
        confirmAlert({ message: "Survey should contain at least one segment to be saved.", buttons: [{ label: "Ok", onClick: () => {} }],closeOnEscape: false, closeOnClickOutside: false });
      } else {
        surveySaved["status"] = isComplete ? survey_status.COMPLETE : survey_status.PROGRESS;

        // Basic validate
        //-----------------------------------

        let segmentStatus = true;

        if (surveySaved.version && Lodash.isNumber(surveySaved.version) && surveySaved.type && surveySaved.type.length > 2 && surveySaved.title && surveySaved.title.length > 2) {
          for (const segment of surveySaved.segments) {
            if (segment.title && !Lodash.isEmpty(segment.title)) {
              for (const question of segment.questions) {
                if (!(question.question && !Lodash.isEmpty(question.question))) {
                  confirmAlert({ title: "Error", message: "Question is missing, under: " + segment.title, buttons: [{ label: "Ok", onClick: () => {} }],closeOnEscape: false, closeOnClickOutside: false });
                  segmentStatus = false;
                  return;
                }
              }
            } else {
              confirmAlert({ title: "Error", message: "Segment title is missing.", buttons: [{ label: "Ok", onClick: () => {} }],closeOnEscape: false, closeOnClickOutside: false });
              segmentStatus = false;
              return;
            }
          }
        } else {
          segmentStatus = false;
        }

        if (surveySaved._id === null) {
          if (LOGINSTATE) console.log("creating the survey as: ", surveySaved);
          surveySaved["published"] = false;
          dispatch(createSurveyTemplate(surveySaved, history));
        } else {
          if (LOGINSTATE) console.log("updating the survey as: ", surveySaved);
          dispatch(updateSurveyTemplateById(surveySaved, history));
          // On success return, current local survey is removed
        }
      }
    } else {
      confirmAlert({ message: "Assessment don't contain enough information to be saved.", buttons: [{ label: "Ok", onClick: () => {} }],closeOnEscape: false, closeOnClickOutside: false });
    }
  };

  return (
    <>
      <PageHeader />

      {/* Page content */}
      <Container className="mt--7" fluid>
        {/* Custom cards for survey type */}

        {/* Card stats */}
        <Row className="mt-5">
          <Col lg="8">
            <Card className="card-stats mb-4 mb-xl-0">
              <CardBody>
                <Row>
                  <div className="col">
                    <CardTitle tag="h5" className="text-uppercase text-muted mb-0">
                      CHS Tool
                    </CardTitle>
                    <span className="h2 font-weight-bold mb-0">{surveyNew.version}: Current Assessment version</span>
                  </div>
                  <Col className="col-auto">
                    <div className="icon icon-shape bg-danger text-white rounded-circle shadow">
                      <i className="fas fa-clipboard-check" style={{ fontSize: 32 }} />
                    </div>
                  </Col>
                </Row>
              </CardBody>
              <CardBody>
                <Formik
                  initialValues={{
                    Title: "",
                    Description: "",
                    Version: 0.0,
                    Type: "",
                  }}
                  validationSchema={reviewSurveySchema}
                  onSubmit={(values, actions) => {
                    updateSurveyDetails(values);
                  }}
                  innerRef={formSurveyRef}
                >
                  {(props) => (
                    <Form role="form" style={{ marginTop: -25 }} onSubmit={props.handleSubmit}>
                      <Row>
                        <Col lg="4">
                          <TextField
                            fullWidth
                            label={"Assessment Version*"}
                            variant="outlined"
                            value={props.values.Version}
                            onChange={props.handleChange("Version")}
                            onBlur={props.handleSubmit}
                            style={ChsStyles.Question}
                            className={"form-control-alternative form-control-edit"}
                            error={props.touched.Version && Boolean(props.errors.Version)}
                            helperText={props.touched.Version && props.errors.Version}
                            // disabled={true}
                          />
                        </Col>
                      </Row>

                      <Row>
                        <Col lg="12">
                          <TextField
                            fullWidth
                            label={"Assessment Title*"}
                            variant="outlined"
                            value={props.values.Title}
                            onChange={props.handleChange("Title")}
                            onBlur={props.handleSubmit}
                            style={ChsStyles.Question}
                            className={"form-control-alternative form-control-edit"}
                            error={props.touched.Title && Boolean(props.errors.Title)}
                            helperText={props.touched.Title && props.errors.Title}
                          />
                        </Col>
                      </Row>

                      <Row>
                        <Col lg="12">
                          <TextField
                            fullWidth
                            label={"Assessment Description"}
                            variant="outlined"
                            value={props.values.Description}
                            onChange={props.handleChange("Description")}
                            onBlur={props.handleSubmit}
                            style={ChsStyles.Question}
                            className={"form-control-alternative form-control-edit"}
                            error={props.touched.Description && Boolean(props.errors.Description)}
                            helperText={props.touched.Description && props.errors.Description}
                          />
                        </Col>
                      </Row>
                    </Form>
                  )}
                </Formik>
              </CardBody>
            </Card>
          </Col>
        </Row>

        <br />

        {/* Ask to select a survey */}
        <Row style={{ display: surveyStarted ? "none" : "block" }}>
          <div className="col">
            <Card className="shadow">
              {/* survey header */}
              <CardHeader className="border-0">
                <div className="row">
                  <div className="mb-xl-0 col-11">
                    <small>APP Self Assessment Tools</small>
                    <h3 className="mb-0">IF YOU ARE STARING A NEW ASSESSMENT, USE THIS FACILITY. OTHERWISE, YOU CAN USE AN EXISTING VERSION TO CREATE A NEW VERSION.</h3>
                    <h6 className="mb-0"> </h6>
                  </div>
                </div>
                <Row>
                  <Col lg="12" className="text-center">
                    <Button
                      style={ChsStyles.BottomButton}
                      color="primary"
                      onClick={() => {
                        setSurveyStarted(true);
                      }}
                    >
                      OK
                    </Button>
                  </Col>
                </Row>
              </CardHeader>
              <CardFooter className="py-4"></CardFooter>
            </Card>
          </div>
        </Row>

        {/* create survey segments */}
        <Row style={{ display: surveyStarted ? "block" : "none" }}>
          <div className="col">
            <Card className="shadow">
              {/* survey header */}
              <CardHeader className="border-0">
                <Row>
                  <Col lg="9">
                    <small>{surveyNew.title}</small>

                    <h6 className="mb-0">Managing segments</h6>
                  </Col>

                  <Col lg="3" className="text-right">
                    <i
                      className="fas fa-minus-circle"
                      style={{ fontSize: 28, color: GlobalStylesColor.mbppThemeBlue, marginRight: 5, cursor: "pointer" }}
                      onClick={(e) => {
                        e.preventDefault();
                        handleDeleteSegmentClick(e);
                      }}
                    />
                    <span className="sr-only">Delete</span>

                    <i
                      className="fas fa-chevron-circle-up"
                      style={{ fontSize: 28, color: GlobalStylesColor.mbppThemeBlue, marginRight: 5, cursor: "pointer" }}
                      onClick={(e) => {
                        e.preventDefault();
                        handleMoveUpSegmentClick(e);
                      }}
                    />
                    <span className="sr-only">Move Up</span>

                    <i
                      className="fas fa-chevron-circle-down"
                      style={{ fontSize: 28, color: GlobalStylesColor.mbppThemeBlue, marginRight: 5, cursor: "pointer" }}
                      onClick={(e) => {
                        e.preventDefault();
                        handleMoveDownSegmentClick(e);
                      }}
                    />
                    <span className="sr-only">Move Down</span>

                    <i
                      className="fas fa-plus-circle"
                      style={{ fontSize: 28, color: GlobalStylesColor.mbppThemeBlue, marginRight: 5, cursor: "pointer" }}
                      onClick={(e) => {
                        e.preventDefault();
                        handleNewSegmentClick(e);
                      }}
                    />
                    <span className="sr-only">Add</span>
                  </Col>
                </Row>
              </CardHeader>

              {/* segment content */}
              <CardBody style={{ display: segmentData.length > 0 ? "block" : "none" }}>
                <Formik
                  initialValues={{
                    Title: "",
                    Description: "",
                    Code: "",
                  }}
                  validationSchema={reviewSchema}
                  onSubmit={(values, actions) => {
                    updateSegment(values);
                  }}
                  innerRef={formRef}
                >
                  {(props) => (
                    <Form role="form" onSubmit={props.handleSubmit} onReset={props.handleReset}>
                      <Row>
                        <Col lg="6">
                          <TextField
                            fullWidth
                            label={"Segment Code*"}
                            variant="outlined"
                            value={props.values.Code}
                            onChange={props.handleChange("Code")}
                            onBlur={props.handleSubmit}
                            style={ChsStyles.Question}
                            className={"form-control-alternative form-control-edit"}
                            error={props.touched.Code && Boolean(props.errors.Code)}
                            helperText={props.touched.Code && props.errors.Code}
                          />
                          <div style={ChsStyles.QuickNote}>This code should be unique to identify the segment for this assessment.</div>
                        </Col>
                      </Row>

                      <Row>
                        <Col lg="12">
                          <TextField
                            fullWidth
                            label={"Segment Title*"}
                            variant="outlined"
                            value={props.values.Title}
                            onChange={props.handleChange("Title")}
                            onBlur={props.handleSubmit}
                            style={ChsStyles.Question}
                            className={"form-control-alternative form-control-edit"}
                            error={props.touched.Title && Boolean(props.errors.Title)}
                            helperText={props.touched.Title && props.errors.Title}
                          />
                        </Col>
                      </Row>

                      <Row>
                        <Col lg="12">
                          <TextField
                            fullWidth
                            label={"Segment Description"}
                            variant="outlined"
                            value={props.values.Description}
                            onChange={props.handleChange("Description")}
                            onBlur={props.handleSubmit}
                            style={ChsStyles.Question}
                            className={"form-control-alternative form-control-edit"}
                            error={props.touched.Description && Boolean(props.errors.Description)}
                            helperText={props.touched.Description && props.errors.Description}
                          />
                        </Col>
                      </Row>

                      <Row>
                        <Col lg="6" className="text-left"></Col>
                        <Col lg="6" className="text-right"></Col>
                      </Row>
                    </Form>
                  )}
                </Formik>

                <Row>
                  <Col lg="12" style={{ marginTop: 25 }}>
                    {segmentData && !Lodash.isEmpty(segmentData) && !Lodash.isEmpty(segmentData[segmentCounter].questions) && segmentData[segmentCounter].questions.length > 0 ? (
                      <Row>
                        <p style={{ marginLeft: 10, marginRight: 10 }}>{segmentData[segmentCounter].questions.length + " questions."}</p>

                        <i
                          className="fas fa-plus-circle"
                          style={{ fontSize: 28, color: GlobalStylesColor.mbppThemeBlue, marginRight: 10, marginTop: -5, cursor: "pointer" }}
                          onClick={(e) => {
                            e.preventDefault();
                            handleQuestionsClick(e);
                          }}
                        />
                        <span className="sr-only">Add</span>

                        <p style={{ marginLeft: 5 }}>Click on plus icon to add or edit questions.</p>
                      </Row>
                    ) : (
                      <Row>
                        <p style={{ marginLeft: 10, marginRight: 10 }}>No questions yet!</p>

                        <i
                          className="fas fa-plus-circle"
                          style={{ fontSize: 28, color: GlobalStylesColor.mbppThemeBlue, marginRight: 10, marginTop: -5, cursor: "pointer" }}
                          onClick={(e) => {
                            e.preventDefault();
                            handleQuestionsClick(e);
                          }}
                        />
                        <span className="sr-only">Add</span>

                        <p style={{ marginLeft: 5 }}>Click on plus icon to add questions.</p>
                      </Row>
                    )}
                  </Col>
                </Row>
              </CardBody>
              <CardBody style={{ display: segmentData.length > 0 ? "none" : "block" }}>
                <Row>
                  <Col lg="12">
                    <Row>
                      <i
                        className="fas fa-plus-circle"
                        style={{ fontSize: 28, color: GlobalStylesColor.mbppThemeBlue, marginLeft: 10, marginRight: 10, marginTop: -5, cursor: "pointer" }}
                        onClick={(e) => {
                          e.preventDefault();
                          handleNewSegmentClick(e);
                        }}
                      />
                      <span className="sr-only">Add</span>

                      <p style={{ marginLeft: 5 }}>Click on plus icon to add a new segment</p>
                    </Row>
                  </Col>
                </Row>
              </CardBody>

              {/* survey footer */}
              <CardFooter className="py-4">
                <Row>
                  <Col lg="12" className="text-center">
                    <div aria-label="..." style={{ marginBottom: 5, marginTop: 5, overflowX: "auto" }}>
                      {showPagination && segmentCount && segmentCount > 0 ? (
                        <Pagination
                          current={segmentCounter + 1}
                          total={segmentCount}
                          onPageChange={(current) => {
                            setShowPagination(false);
                            setSegmentCounter(current - 1);

                            setShowPagination(true);
                          }}
                        ></Pagination>
                      ) : null}
                    </div>
                  </Col>
                </Row>
                <Row>
                  <Col lg="6" className="text-left">
                    <Button
                      style={ChsStyles.GeneralButton}
                      color="primary"
                      onClick={() => {
                        window.history.back();
                      }}
                    >
                      BACK
                    </Button>
                  </Col>
                  <Col lg="6" className="text-right">
                    <Button
                      style={ChsStyles.GeneralButton}
                      color="primary"
                      onClick={() => {
                        handleSurveySave(false);
                      }}
                    >
                      PARTLY SAVE
                    </Button>

                    {segmentCounter + 1 >= segmentCount ? (
                      <Button
                        style={ChsStyles.GeneralButton}
                        color="primary"
                        onClick={() => {
                          handleSurveySave(true);
                        }}
                      >
                        COMPLETE TEMPLATE
                      </Button>
                    ) : (
                      <Button
                        style={ChsStyles.GeneralButton}
                        color="primary"
                        onClick={() => {
                          setSegmentCounter(segmentCounter + 1);
                        }}
                      >
                        NEXT
                      </Button>
                    )}
                  </Col>
                </Row>
              </CardFooter>
            </Card>
          </div>
        </Row>
      </Container>
    </>
  );
};

export default SurveyCreateTemplate;
