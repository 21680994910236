import AdminFooter from "components/Footers/AdminFooter.js";
// core components
import AdminNavbar from "components/Navbars/AdminNavbar.js";
import { LOGINSTATE } from "config/config.json";
import Sidebar from "components/Sidebar/Sidebar.js";
import * as actionType from 'constants/actionTypes';
import decode from 'jwt-decode';
import React, { useEffect, useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Redirect, Route, Switch, useHistory, useLocation } from "react-router-dom";
// reactstrap components
import { Container } from "reactstrap";
import { routes, routesAdmin, routesUser } from "routes.js";
import Lodash from "lodash";
import { CircularProgress } from '@material-ui/core';
import '../assets/css/react-confirm-alert-custom.css'; // Import css

const Admin = (props) => {
  const userDetails = useSelector((state) => state.auth.authData);
  const mainContent = React.useRef(null);
  const location = useLocation();
  const dispatch = useDispatch();
  const history = useHistory();

  const [uRole, setURole] = useState("GENERAL");
  const [showLoading, setShowLoading] = useState(false);

  // handle click event of logout
  const logout = () => {
    dispatch({ type: actionType.LOGOUT });
    localStorage.clear();
    history.push('/public/login');
  };

  useEffect(() => {
    // Heartbeat to avoid keeping old UI when new user is logged from different browser tab
    // Check the validity of token
    setInterval(() => {
      const tokenMem = userDetails?.token;
      let currentUserLocal = localStorage.getItem('userDetails');
      let jUser = JSON.parse(currentUserLocal);
      const tokenLocal = jUser?.token;
      if (tokenLocal && tokenLocal.length > 3 && tokenLocal === tokenMem) {
        // tokens are same, hence same user session. ignore
      }
      else {
        window.location.reload();
      }
    }, 60000);
  }, [])


  // after render DOM. Manually check the validity of token. Can't relay
  useEffect(() => {
    if (userDetails && Object.keys(userDetails).length > 0) {
      if (userDetails?.token) {
        const token = userDetails?.token;
        const decodedToken = decode(token);

        if (decodedToken.exp * 1000 < new Date().getTime()) {
          logout();
        }
        else {
          setURole(userDetails?.user?.urole ? userDetails?.user?.urole : "GENERAL");
        }
      }
    }
    else {
      logout();
    }
  }, [userDetails]);

  const innerRouts = useRef([])

  useEffect(() => {
    document.documentElement.scrollTop = 0;
    document.scrollingElement.scrollTop = 0;
    mainContent.current.scrollTop = 0;

  }, [location]);

  // manage loading indicator
  const loadingSpinner = useSelector((state) => state.dashboard.showLoading);
  useEffect(() => {
    if(LOGINSTATE) console.log("loadingSpinner: ", loadingSpinner);
    setShowLoading(Lodash.isBoolean(loadingSpinner) ? loadingSpinner : false);
  }, [loadingSpinner]);

  const getRoutes = (routes) => {
    return routes.map((prop, key) => {
      if (prop.layout === "/registered") {
        return (
          <Route
            path={prop.layout + prop.path}
            component={prop.component}
            key={key}
          />
        );

      } else {
        return null;
      }
    });
  };
  const getInnerRoutes = (routes) => {
    routes.forEach((prop, key) => {
      prop.hasOwnProperty('innerlinks') && (prop.innerlinks).forEach((innerProp, innerKey) => {
        if (innerProp.layout === "/registered") {
          innerRouts.current.push(<Route
            path={innerProp.layout + innerProp.path}
            component={innerProp.component}
            key={key + innerKey}
          />)
        }
      });
    });
  };
  getInnerRoutes(uRole === "ADMIN" ? routesAdmin : routesUser);

  const getBrandText = (path) => {
    for (let i = 0; i < routes.length; i++) {
      if (path === (routes[i].layout + routes[i].path)) {
        return routes[i].name;
      }
      else if (routes[i].hasOwnProperty('innerlinks')) {
        for (let x = 0; x < routes[i].innerlinks.length; x++) {
          if (path === (routes[i].innerlinks[x].layout + routes[i].innerlinks[x].path))
            return routes[i].innerlinks[x].name;
        }
      }
    }
    return "Brand";
  };

  return (
    <>
      {showLoading ?
        <div className="react-confirm-alert-overlay">
          <CircularProgress style={{ color: '#af1e23' }} />
        </div> : null
      }

      {/* Left navigation content template */}
      <Sidebar
        {...props}
        // routes={routes}
        routes={uRole === "ADMIN" ? routesAdmin : routesUser}
        logo={{
          innerLink: "/registered/index",
          imgSrc: require("../assets/img/brand/img_logo.png").default,
          imgAlt: "APP Self Assessment Tools",
        }}
      />

      {/* Page write/main content template */}
      <div className="main-content" ref={mainContent}>
        <AdminNavbar
          {...props}
          brandText={getBrandText(props.location.pathname)}
        />
        <Switch>
          {(userDetails && Object.keys(userDetails).length > 0) ? (uRole === "ADMIN" ? getRoutes(routesAdmin) : getRoutes(routesUser)) : logout()}
          {(userDetails && Object.keys(userDetails).length > 0) ? innerRouts.current.map(routes => routes) : logout()}
          <Redirect from="*" to="/registered/index" />
        </Switch>

        <Container fluid>
          <AdminFooter />
        </Container>
      </div>

    </>
  );
};

export default Admin;
