import { combineReducers } from 'redux';
import auth from './auth';
import forum from './forum';
import survey from './survey';
import user from './user';
import organization from './organization';
import masterData from './masterData';
import resource from './resource';
import report from './report';
import dashboard from './dashboard';

export const reducers = combineReducers({
  auth,
  user,
  forum,
  survey,
  organization,
  masterData,
  resource,
  report,
  dashboard
});
