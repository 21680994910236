import Lodash from "lodash";
import * as api from "../api/index.js";
import { AUTH, USER_FETCH_ALL, USER_FETCH_ONE, USER_FETCH_ONE_ORG, USER_UPDATE, ORG_UPDATE, USER_FETCH_BY_ADMIN, IS_HUMAN, SHOW_LOADING } from "../constants/actionTypes";
import { LOGINSTATE } from "config/config.json";
import { confirmAlert } from "react-confirm-alert";
import "../assets/css/react-confirm-alert-custom.css"; /// Import css

// trans done
export const signin = (formData, router, systemContentLocale) => async (dispatch) => {
  try {
    dispatch({ type: SHOW_LOADING, payload: true });

    const response = await api.signIn(formData);
    // if(LOGINSTATE) console.log("sign in response", response);
    const { data } = response;

    if (data?.status === "success") {
      dispatch({ type: AUTH, payload: data?.data });
      localStorage.setItem("userDetails", JSON.stringify({ ...data?.data }));

      router.push("/registered/index");
    } else if (data?.status === "error") {
      if (LOGINSTATE) console.log("error: ", data);
      if (data?.message === "Password invalid.") {
        confirmAlert({ message: systemContentLocale.password_invalid, buttons: [{ label: systemContentLocale.app_ok, onClick: () => { } }], closeOnEscape: false, closeOnClickOutside: false });
      }
      else if (data?.message === "user not registered.") {
        confirmAlert({ message: systemContentLocale.user_not_register, buttons: [{ label: systemContentLocale.app_ok, onClick: () => { } }], closeOnEscape: false, closeOnClickOutside: false });
      }
      else if (data?.message === "User unlinked") {
        confirmAlert({ title: systemContentLocale.user_unlink, message: systemContentLocale.login_message, buttons: [{ label: systemContentLocale.app_ok, onClick: () => { } }], closeOnEscape: false, closeOnClickOutside: false });
      }
      else if (data?.message === "User deactivated") {
        confirmAlert({ title: systemContentLocale.user_deactivate, message: systemContentLocale.login_message, buttons: [{ label: systemContentLocale.app_ok, onClick: () => { } }], closeOnEscape: false, closeOnClickOutside: false });
      }
      else if (data?.message === "User is not completed") {
        confirmAlert({ title: systemContentLocale.user_not_create, message: systemContentLocale.login_message, buttons: [{ label: systemContentLocale.app_ok, onClick: () => { } }], closeOnEscape: false, closeOnClickOutside: false });
      }
      else if (data?.message === "Your organization deactivated") {
        confirmAlert({ title: systemContentLocale.organization_deactivate, message: systemContentLocale.login_message, buttons: [{ label: systemContentLocale.app_ok, onClick: () => { } }], closeOnEscape: false, closeOnClickOutside: false });
      }
      else if (data?.message === "Your location deactivated") {
        confirmAlert({ title: systemContentLocale.location_deactivate, message: systemContentLocale.login_message, buttons: [{ label: systemContentLocale.app_ok, onClick: () => { } }], closeOnEscape: false, closeOnClickOutside: false });
      }
      router.push("/auth/signin");
    }
  } catch (error) {
    console.log("catch error", error?.response);
    if (error?.response?.status && error?.response?.status === 401) {
      confirmAlert({ message: systemContentLocale.session_expired, buttons: [{ label: systemContentLocale.app_ok, onClick: () => { } }], closeOnEscape: false, closeOnClickOutside: false });
      router.push("/auth/signin");
    }
    else {
      // process the error to show proper error message in popup
      let errorTitle = error?.response?.data?.data ? error?.response?.data?.message : null;
      let errorMessge = error?.response?.data?.data ? error?.response?.data?.data : error?.response?.data?.message;
      errorMessge = Lodash.isEmpty(errorMessge) ? systemContentLocale.app_error : errorMessge;
      // confirmAlert({ title: errorTitle, message: errorMessge, buttons: [{ label: systemContentLocale.app_ok, onClick: () => { } }], closeOnEscape: false, closeOnClickOutside: false });
      // masking the error due to translation limitations
      confirmAlert({ title: systemContentLocale.app_sorry, message: systemContentLocale.app_error, buttons: [{ label: systemContentLocale.app_ok, onClick: () => { } }], closeOnEscape: false, closeOnClickOutside: false });
      router.push("/auth/signin");
    }
  } finally {
    dispatch({ type: SHOW_LOADING, payload: false });
  }
};

// trans done
// sign up with new account and new organization + new city
export const signUp = (formData, router, systemContentLocale) => async (dispatch) => {
  try {
    dispatch({ type: SHOW_LOADING, payload: true });
    // if(LOGINSTATE) console.log("sign up form data", formData);
    const { data } = await api.signUp(formData);

    if (data?.status === "success") {
      if (LOGINSTATE) console.log("req is success");
      confirmAlert({ title: systemContentLocale.app_success, message: systemContentLocale.user_successfully_created, buttons: [{ label: systemContentLocale.app_ok, onClick: () => { } }], closeOnEscape: false, closeOnClickOutside: false });
      router.push("/auth/signin");
    } else if (data?.status === "error") {
      if (LOGINSTATE) console.log("error: ", data);
      confirmAlert({ message: data?.message, buttons: [{ label: "Ok", onClick: () => { } }], closeOnEscape: false, closeOnClickOutside: false });
      router.push("/auth/signin");
    }
  } catch (error) {
    if (LOGINSTATE) console.log("catch error", error.response);
    if (error?.response?.status && error?.response?.status === 401) {
      confirmAlert({ message: systemContentLocale.session_expired, buttons: [{ label: systemContentLocale.app_ok, onClick: () => { } }], closeOnEscape: false, closeOnClickOutside: false });
      router.push("/auth/signin");
    } else {
      // process the error to show proper error message in popup
      if (LOGINSTATE) console.log(" error?.response", error?.response);
      let errorTitle = error?.response?.data?.data ? error?.response?.data?.message : null;
      let errorMessge = error?.response?.data?.data ? error?.response?.data?.data : error?.response?.data?.message;
      errorMessge = Lodash.isEmpty(errorMessge) ? systemContentLocale.app_error : errorMessge;
      // confirmAlert({ title: errorTitle, message: errorMessge, buttons: [{ label: systemContentLocale.app_ok, onClick: () => { } }], closeOnEscape: false, closeOnClickOutside: false });
      // masking the error due to translation limitations
      confirmAlert({ title: systemContentLocale.app_sorry, message: systemContentLocale.app_error, buttons: [{ label: systemContentLocale.app_ok, onClick: () => { } }], closeOnEscape: false, closeOnClickOutside: false });
      router.push("/auth/signin");
    }
    // }
  } finally {
    dispatch({ type: SHOW_LOADING, payload: false });
  }
};

// seems not using
// sign up with new account and organization + new city
export const signupalreadyregister = (formData, router) => async (dispatch) => {
  try {
    dispatch({ type: SHOW_LOADING, payload: true });
    // if(LOGINSTATE) console.log("sign up form data", formData);
    const { data } = await api.signupalreadyregister(formData);

    if (data?.status === "success") {
      if (LOGINSTATE) console.log("req is success");
      confirmAlert({ title: "Success", message: "User successfully created.", buttons: [{ label: "Ok", onClick: () => { } }], closeOnEscape: false, closeOnClickOutside: false });
      router.push("/auth/signin");
    } else if (data?.status === "error") {
      if (LOGINSTATE) console.log("error: ", data);
      confirmAlert({ message: data?.message, buttons: [{ label: "Ok", onClick: () => { } }], closeOnEscape: false, closeOnClickOutside: false });
      router.push("/auth/signin");
    }
  } catch (error) {
    if (LOGINSTATE) console.log("catch error", error.response);
    if (error?.response?.status && error?.response?.status === 401) {
      confirmAlert({ message: "Session expired!", buttons: [{ label: "Ok", onClick: () => { } }], closeOnEscape: false, closeOnClickOutside: false });
      router.push("/auth/signin");
    } else {
      // process the error to show proper error message in popup
      let errorTitle = error?.response?.data?.data ? error?.response?.data?.message : null;
      let errorMessge = error?.response?.data?.data ? error?.response?.data?.data : error?.response?.data?.message;
      errorMessge = Lodash.isEmpty(errorMessge) ? "Oops, something went wrong" : errorMessge;
      confirmAlert({ title: errorTitle, message: errorMessge, buttons: [{ label: "Ok", onClick: () => { } }], closeOnEscape: false, closeOnClickOutside: false });
      router.push("/auth/signin");
    }
    // }
  } finally {
    dispatch({ type: SHOW_LOADING, payload: false });
  }
};

// no need trans
// sign up an admin by another admin
export const signupadmin = (formData, router) => async (dispatch) => {
  try {
    dispatch({ type: SHOW_LOADING, payload: true });
    // if(LOGINSTATE) console.log("sign up form data", formData);
    const { data } = await api.signupadmin(formData);

    // if(LOGINSTATE) console.log("sign up response data", data);
    if (data?.status === "success") {
      confirmAlert({ title: "Success", message: "User successfully created.", buttons: [{ label: "Ok", onClick: () => { } }], closeOnEscape: false, closeOnClickOutside: false });
      router.push("/registered/user");
    } else if (data?.status === "error") {
      if (LOGINSTATE) console.log("error: ", data);
      confirmAlert({ message: data?.message, buttons: [{ label: "Ok", onClick: () => { } }], closeOnEscape: false, closeOnClickOutside: false });
    }
  } catch (error) {
    if (LOGINSTATE) console.log("catch error", error.response);
    if (error?.response?.status && error?.response?.status === 401) {
      confirmAlert({ message: "Session expired!", buttons: [{ label: "Ok", onClick: () => { } }], closeOnEscape: false, closeOnClickOutside: false });
      router.push("/auth/signin");
    } else {
      // if (error.response && error.response.status && error.response.status === 409 && error.response.data && error.response.data.code && error.response.data.code === "409") {
      // process the error to show proper error message in popup
      let errorTitle = error?.response?.data?.data ? error?.response?.data?.message : null;
      let errorMessge = error?.response?.data?.data ? error?.response?.data?.data : error?.response?.data?.message;
      errorMessge = Lodash.isEmpty(errorMessge) ? "Oops, something went wrong" : errorMessge;
      confirmAlert({ title: errorTitle, message: errorMessge, buttons: [{ label: "Ok", onClick: () => { } }], closeOnEscape: false, closeOnClickOutside: false });
      router.push("/registered/user");
    }
    // }
  } finally {
    dispatch({ type: SHOW_LOADING, payload: false });
  }
};

// seems not using
export const fetchProfile = (formData, t) => async (dispatch) => {
  // console.log("formData", formData);
  try {
    dispatch({ type: SHOW_LOADING, payload: true });
    const { data } = await api.fetchProfile(formData);
    if (LOGINSTATE) console.log("profile data: ", data);
    if (data?.status === "success") {
      dispatch({ type: USER_FETCH_ONE, payload: data?.data });
    }
    if (data?.status === "error") {
      if (LOGINSTATE) console.log(data);
      // confirmAlert({ message: data?.message, buttons: [{ label: "Ok", onClick: () => { } }], closeOnEscape: false, closeOnClickOutside: false });
      confirmAlert({ message: t('popups.somethingWentWrong'), buttons: [{ label: t('common.ok'), onClick: () => { } }], closeOnEscape: false, closeOnClickOutside: false });
    }
  } catch (error) {
    if (LOGINSTATE) console.log(error);
    confirmAlert({ message: error, buttons: [{ label: "Ok", onClick: () => { } }], closeOnEscape: false, closeOnClickOutside: false });
  } finally {
    dispatch({ type: SHOW_LOADING, payload: false });
  }
};

// no trans need
export const fetchProfileOrg = () => async (dispatch) => {
  try {
    dispatch({ type: SHOW_LOADING, payload: true });
    const { data } = await api.fetchProfileOrg();
    if (LOGINSTATE) console.log("profile data: ", data);
    if (data?.status === "success") {
      dispatch({ type: USER_FETCH_ONE_ORG, payload: data?.data });
    }
    if (data?.status === "error") {
      if (LOGINSTATE) console.log(data);
      confirmAlert({ message: "Oops!, something went wrong. Please try again.", buttons: [{ label: "Ok", onClick: () => { } }], closeOnEscape: false, closeOnClickOutside: false });
      return;
    }
  } catch (error) {
    if (LOGINSTATE) console.log(error);
    confirmAlert({ message: error, buttons: [{ label: "Ok", onClick: () => { } }], closeOnEscape: false, closeOnClickOutside: false });
  } finally {
    dispatch({ type: SHOW_LOADING, payload: false });
  }
};

// trans done
export const updateUser = (formData, needRefresh, history, systemContentLocale, t) => async (dispatch) => {
  if (LOGINSTATE) console.log("Updating the profile with: ", formData);

  // Check whether the profile picture is new.
  // If yes, upload it first. Otherwise update the profile Details.
  try {
    dispatch({ type: SHOW_LOADING, payload: true });

    if (formData.newProfilePicture) {
      let updatePicResult = await uploadProfileImage(formData, alert, history);

      // Profile picture update failed, stop the process;
      if (!updatePicResult) {
        confirmAlert({ message: t('popups.profilePictureUploadFail'), buttons: [{ label: t('common.ok'), onClick: () => { } }], closeOnEscape: false, closeOnClickOutside: false });
        return;
      }
    } else {
      if (LOGINSTATE) console.log("no new profile picture, continue with existing");
    }

    if (LOGINSTATE) console.log("Updating the profile + picture with: ", formData);

    const { data } = await api.updateUser(formData);
    if (LOGINSTATE) console.log("update data: ", data);

    if (data?.status === "success") {
      // Admin update is just value change in the database

      dispatch({ type: USER_UPDATE, payload: data });

      // Update local storage data
      let currentUser = localStorage.getItem("userDetails");
      if (LOGINSTATE) console.log("currentUser", currentUser);

      let jUser = JSON.parse(currentUser);
      let newUserUpdate = {
        token: jUser.token,
        user: {
          id: formData.userId,
          firstName: formData.firstName,
          lastName: formData.lastName,
          email: formData.email,
          urole: jUser.user.urole,
          profilePicture: formData.profilePicture,
          language: formData.language,
        },
      };

      let result = localStorage.setItem("userDetails", JSON.stringify(newUserUpdate));
      if (LOGINSTATE) console.log("newUserUpdate", newUserUpdate);

      confirmAlert({ title: systemContentLocale.app_success, buttons: [{ label: systemContentLocale.app_ok, onClick: () => { } }], closeOnEscape: false, closeOnClickOutside: false });
      if (needRefresh) history.go(0);
    } else if (data?.status === "error") {
      if (LOGINSTATE) console.log(data);
      // confirmAlert({ message: data?.message, buttons: [{ label: systemContentLocale.app_ok, onClick: () => { } }], closeOnEscape: false, closeOnClickOutside: false });
      confirmAlert({ message: systemContentLocale.app_error, buttons: [{ label: systemContentLocale.app_ok, onClick: () => { } }], closeOnEscape: false, closeOnClickOutside: false });
    }
  } catch (error) {
    if (LOGINSTATE) console.log("catch error in profile update", error);
    // let errorMessge = error?.response?.data?.message;
    // confirmAlert({ message: errorMessge, buttons: [{ label: "Ok", onClick: () => { } }], closeOnEscape: false, closeOnClickOutside: false });
    confirmAlert({ message: systemContentLocale.app_error, buttons: [{ label: systemContentLocale.app_ok, onClick: () => { } }], closeOnEscape: false, closeOnClickOutside: false });
    if (error?.response?.status && error?.response?.status === 401) {
      confirmAlert({ message: systemContentLocale.session_expired, buttons: [{ label: systemContentLocale.app_ok, onClick: () => { } }], closeOnEscape: false, closeOnClickOutside: false });
      history.push("/auth/signin");
    }
  } finally {
    dispatch({ type: SHOW_LOADING, payload: false });
  }
};

// no need i18next: seems not using
const uploadProfileImage = async (values, alert, router) => {
  try {
    if (values.profilePicture) {
      if (LOGINSTATE) console.log("picture data: ", values.profilePicture);
      // continue
    } else {
      if (LOGINSTATE) console.log("empty picture");

      return false;
    }

    if (LOGINSTATE) console.log("upload picture data");
    const params = new FormData();
    params.append("photo[]", values.profilePicture);
    params.append("photo[]", values.profileThumbnail);

    let res = await api.uploadProfileImage(params);
    // console.log("server returned: ", res);

    if (res && res.data && !Lodash.isEmpty(res.data.data) && res.data.data.length >= 2) {
      if (LOGINSTATE) console.log("server returned new file names: ", res.data.data);
      values["profilePicture"] = res.data.data[0].name;
      values["profileThumbnail"] = res.data.data[1].name;
    }

    return true;
  } catch (error) {
    // console.log("catch error in picture upload: ", error?.response?.data);
    if (error?.response?.status && error?.response?.status === 401) {
      confirmAlert({ message: "Session expired!", buttons: [{ label: "Ok", onClick: () => { } }], closeOnEscape: false, closeOnClickOutside: false });
      router.push("/auth/signin");
    } else {
      let errorMessge = error?.response?.data?.message;
      confirmAlert({ message: errorMessge, buttons: [{ label: "Yes", onClick: () => { } }], closeOnEscape: false, closeOnClickOutside: false });
      values.profilePicture = null;
      values.profileThumbnail = null;
    }
    return false;
  }
};

export const changePassword = (formData, router, systemContentLocale) => async (dispatch) => {
  try {
    dispatch({ type: SHOW_LOADING, payload: true });
    let res = await api.changePassword(formData);

    if (res && res.status === 200) {
      // confirmAlert({ title: systemContentLocale.app_success, message: "Password updated.", buttons: [{ label: systemContentLocale.app_yes, onClick: () => { } }], closeOnEscape: false, closeOnClickOutside: false });
      confirmAlert({ title: systemContentLocale.app_success, buttons: [{ label: systemContentLocale.app_yes, onClick: () => { } }], closeOnEscape: false, closeOnClickOutside: false });
    }
  } catch (error) {
    if (LOGINSTATE) console.log(error);
    if (error.response) {
      if (error.response.status === 400) {
        confirmAlert({ title: systemContentLocale.app_error, message: systemContentLocale.password_invalid, buttons: [{ label: systemContentLocale.app_yes, onClick: () => { } }], closeOnEscape: false, closeOnClickOutside: false });
      }
      if (error?.response?.status && error?.response?.status === 401) {
        confirmAlert({ message: systemContentLocale.session_expired, buttons: [{ label: "Ok", onClick: () => { } }], closeOnEscape: false, closeOnClickOutside: false });
        router.push("/auth/signin");
      }
    }
    else {
      confirmAlert({ message: systemContentLocale.app_error, buttons: [{ label: systemContentLocale.app_ok, onClick: () => { } }], closeOnEscape: false, closeOnClickOutside: false });
    }
  } finally {
    dispatch({ type: SHOW_LOADING, payload: false });
  }
};

// no need trans
export const fetchAllProfiles = () => async (dispatch) => {
  try {
    dispatch({ type: SHOW_LOADING, payload: true });
    const { data } = await api.fetchAllProfiles();
    if (data?.status === "success") {
      dispatch({ type: USER_FETCH_ALL, payload: data?.data });
    }
    if (data?.status === "error") {
      if (LOGINSTATE) console.log(data);
      confirmAlert({ message: "Oops!, something went wrong. Please try again.", buttons: [{ label: "Ok", onClick: () => { } }], closeOnEscape: false, closeOnClickOutside: false });
      return;
    }
  } catch (error) {
    if (LOGINSTATE) console.log(error);
    confirmAlert({ message: error, buttons: [{ label: "Ok", onClick: () => { } }], closeOnEscape: false, closeOnClickOutside: false });
  } finally {
    dispatch({ type: SHOW_LOADING, payload: false });
  }
};

// no need trans
export const fetchProfileOrgAsAdmin = (formData, router) => async (dispatch) => {
  try {
    dispatch({ type: SHOW_LOADING, payload: true });
    const response = await api.fetchProfileOrgAsAdmin(formData);
    const { data } = response;
    if (LOGINSTATE) console.log("profile data by admin: ", data);
    if (data?.status === "success") {
      let dispatchData = data?.data;
      dispatchData["user"] = { user: formData };
      if (LOGINSTATE) console.log("profile data by admin all: ", dispatchData);
      dispatch({ type: USER_FETCH_BY_ADMIN, payload: dispatchData });
    }
    if (data?.status === "error") {
      if (LOGINSTATE) console.log(data);
      confirmAlert({ message: "Oops!, something went wrong. Please try again.", buttons: [{ label: "Ok", onClick: () => { } }], closeOnEscape: false, closeOnClickOutside: false });
      return;
    }
  } catch (error) {
    if (LOGINSTATE) console.log(error);
    if (error?.response?.status && error?.response?.status === 401) {
      confirmAlert({ message: "Session expired!", buttons: [{ label: "Ok", onClick: () => { } }], closeOnEscape: false, closeOnClickOutside: false });
      router.push("/auth/signin");
    }
    else {
      confirmAlert({ message: "Oops!, something went wrong. Please try again.", buttons: [{ label: "Ok", onClick: () => { } }], closeOnEscape: false, closeOnClickOutside: false });
      return;
    }
  } finally {
    dispatch({ type: SHOW_LOADING, payload: false });
  }
};

// trans done
export const resetPassword = (formData, router, t) => async (dispatch) => {
  if (LOGINSTATE) console.log("reset password: ", formData);
  try {
    dispatch({ type: SHOW_LOADING, payload: true });
    const response = await api.resetPassword(formData);
    const { data } = response;
    if (LOGINSTATE) console.log("response data: ", data);
    if (data?.status === "success") {
      confirmAlert({
        title: t('resetPage.section1.resetSuccessTitle'),
        message: t('resetPage.section1.resetSuccessMessage'),
        buttons: [{ label: "Ok", onClick: () => { } }],
        closeOnEscape: false,
        closeOnClickOutside: false,
      });
      router.push("public/login");
    }
    else{
      // Including data?.status === "error"
      confirmAlert({ message: t('popups.somethingWentWrong'), buttons: [{ label: "Ok", onClick: () => { } }], closeOnEscape: false, closeOnClickOutside: false });
      return;
    }
  } catch (error) {
    if (LOGINSTATE) console.log("catch error", error);
    if (error?.response?.status && error?.response?.status === 401) {
      confirmAlert({ message: t('popups.sessionExpired'), buttons: [{ label: "Ok", onClick: () => { } }], closeOnEscape: false, closeOnClickOutside: false });
    }
    else {
      confirmAlert({ message: t('popups.somethingWentWrong'), buttons: [{ label: "Ok", onClick: () => { } }], closeOnEscape: false, closeOnClickOutside: false });
    }
    router.push("/login");
  } finally {
    dispatch({ type: SHOW_LOADING, payload: false });
  }

};

// seems not using
export const validateHuman = (formData, router, t) => async (dispatch) => {
  if (LOGINSTATE) console.log("formData re", formData);

  try {
    const response = await api.validateHuman(formData);
    const { data } = response;
    if (LOGINSTATE) console.log("human data: ", data);
    if (data?.status === "success") {
      dispatch({ type: IS_HUMAN, payload: data?.data });
    } else if (data?.status === "error") {
      if (LOGINSTATE) console.log(data);
      confirmAlert({ message: t('popups.recaptchaFail'), buttons: [{ label: "Ok", onClick: () => { } }], closeOnEscape: false, closeOnClickOutside: false });
    }
  } catch (error) {
    if (LOGINSTATE) console.log(error);
    if (error?.response?.status && error?.response?.status === 401) {
      confirmAlert({ message: t('popups.sessionExpired'), buttons: [{ label: "Ok", onClick: () => { } }], closeOnEscape: false, closeOnClickOutside: false });
      router.push("/auth/signin");
    } else {
      alert(error);
      confirmAlert({ message: t('popups.recaptchaError'), buttons: [{ label: "Ok", onClick: () => { } }], closeOnEscape: false, closeOnClickOutside: false });
    }
  } finally {
    dispatch({ type: SHOW_LOADING, payload: false });
  }
};
