import React from "react";
import { useTranslation } from "react-i18next";
import Moment from "moment";

import { Col, Container, Nav, NavItem, NavLink, Row } from "reactstrap";
import { useHistory, Link, NavLink as RectNavLink } from "react-router-dom";

const LandingFooter = () => {
  const { t, i18n } = useTranslation();

  return (
    <footer className="py-3">
      <Container>
        <Row className="align-items-center justify-content-xl-between">
          <Col xl="6">
            <div className="copyright text-center text-xl-left text-muted">
              {t('footer.Copyright') + " " + Moment().year()}
              <a className="font-weight-bold ml-1" href="/" target="_blank">
                {t('footer.App_name')}
              </a>
            </div>
          </Col>
          <Col xl="6" className="text-right">
            <Nav className="nav-footer justify-content-center justify-content-xl-end">
              <NavItem>
                <NavLink href="https://app.adpc.net/about-app/" target="_blank">
                  {t('footer.AboutUs')}
                </NavLink>
              </NavItem>
              <NavItem>
                <NavLink href="https://app.adpc.net/" target="_blank">
                  {t('footer.APP')}
                </NavLink>
              </NavItem>
              <NavItem>
                <NavLink href="https://www.adpc.net/" target="_blank">
                  {t('footer.adpc')}
                </NavLink>
              </NavItem>
              <NavItem>
                {/* <NavLink href="/public/privacy_policy_public">
                  {t('footer.privacyPolicy')}
                </NavLink> */}
                {/* <NavLink
                  className="nav-link-icon"
                  to="/public/privacy_policy_public"
                  tag={Link}
                >
                  <span className="nav-link-inner--text">
                    {t('footer.privacyPolicy')}
                  </span>
                </NavLink> */}
              </NavItem>
            </Nav>
          </Col>
        </Row>
      </Container>
    </footer>
  );
};

export default LandingFooter;
