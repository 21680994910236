import { fetchSurveyAnswerByUser } from "actions/survey";
import { fetchOrganizationAll } from "actions/organization";
import { fetchCountry } from "actions/masterData";

// core components
import PageHeader from "components/Headers/PageHeader.js";
import Lodash from "lodash";
import Moment from "moment";
import React, { useEffect, useState, useRef } from "react";
import Config from "config/config.json";
import { LOGINSTATE } from "../../../config/config.json";
import { useDispatch, useSelector } from "react-redux";
import { useHistory, useLocation } from "react-router-dom";
import { TextField } from "@material-ui/core";
import { Card, CardHeader, Container, Row, Col, Table, Form, CardBody } from "reactstrap";
import { GlobalStylesColor } from "constants/globalStyles";
import { confirmAlert } from "react-confirm-alert";
import "react-confirm-alert/src/react-confirm-alert.css"; // Import css
import { answer_status } from "helper.js";
import { Formik } from "formik";
import { useTranslation } from "react-i18next";

// Populate version row for each survey type
const AnswerRow = ({ answer, editClick, statusClick }) => (
  // VERSION | TITLE | DESCRIPTION | STATUS
  <tr>
    {/* {console.log("survey answer: ", answer)} */}
    {/* <td style={{ whiteSpace: "break-spaces" }}>{Moment(answer.updatedAt).format("YYYY-MM-DD hh:mm A")}</td> */}
    <td style={{ whiteSpace: "break-spaces" }}>{answer.clientTimestamp}</td>
    <td style={{ whiteSpace: "break-spaces", color: GlobalStylesColor.mbppThemeBlue }}>{answer.type}</td>
    <td style={{ whiteSpace: "break-spaces" }}>{answer.title}</td>
    {answer?.status === answer_status[0] ? <td></td> : <td style={{ whiteSpace: "break-spaces" }}>{answer.surveyScore > 0 ? Math.round((answer.surveyScore + Number.EPSILON) * 100) / 100 : ""}</td>}

    <td className="text-right">
      <div className="row">
        {answer.status === answer_status[0] ? (
          <div
            className="navbar-toggler"
            style={{ cursor: "pointer" }}
            onClick={(e) => {
              editClick(e, answer);
            }}
          >
            <i className="far fa-edit" style={{ color: GlobalStylesColor.mbppIconColor }} />
          </div>
        ) : (
          <div
            className="navbar-toggler"
            style={{ cursor: "pointer" }}
            onClick={(e) => {
              statusClick(e, answer);
            }}
          >
            <i className="far fa-eye" style={{ color: GlobalStylesColor.mbppIconColor }} />
          </div>
        )}
      </div>
    </td>
  </tr>
);

// export function
const ReportAllAnswersByUser = () => {
  const formRef = useRef();
  // dispatch and history
  const dispatch = useDispatch();
  const history = useHistory();
  const location = useLocation();

  const [selectedCountry, setSelectedCountry] = useState("");
  const [selectedOrganization, setSelectedOrganization] = useState({});
  const [selectedOrganizationCurr, setSelectedOrganizationCurr] = useState("");
  const [selectedCity, setSelectedCity] = useState("");
  const [selectedUser, setSelectedUser] = useState("");
  
  const { t, i18n } = useTranslation();

  useEffect(() => {
    // fetching all surveys
    dispatch(fetchCountry());
    dispatch(fetchOrganizationAll());
  }, []);

  const countriesAll = useSelector((state) => state.masterData.countryAll);
  const organizationAll = useSelector((state) => state.organization.organizationAll);

  useEffect(() => {
    if(LOGINSTATE) console.log("selected user: ", selectedUser);
    if (selectedUser && !Lodash.isEmpty(selectedUser)) {
      if(LOGINSTATE) console.log("populate answers");
      dispatch(fetchSurveyAnswerByUser({ userId: selectedUser }, history, t));
    }
    setSelectedCity(Lodash.find(countriesAll, { _id: selectedCountry }));
  }, [selectedUser]);

  // props pass another page
  const loadSurveys = (e) => {
    e.preventDefault();
    history.push({ pathname: "/registered/assessment_complete", user: e.target.value });
  };

  // handle change event of the file
  const handleFileChange = async (e, props) => {
    try {
      const file = props.value.Country;
      if(LOGINSTATE) console.log(file);
      if (formRef.current) {
        formRef.current.setFieldValue("Country", file, false);
      }
    } catch (err) {
      if(LOGINSTATE) console.log("file error", err);
    }
  };

  return (
    <>
      <PageHeader />

      {/* Page content */}
      <Container className="mt--7" fluid>
        <Row className="pt-0 pt-md-4">
          <div className="col">
            <Card className="shadow">
              <CardHeader className="border-0">
                <div className="row">
                  <div className="mb-xl-0 col-10">
                    <h3 className="mb-0">Completed Assessments in Detail</h3>
                    <h6 className="mb-0">Select options to check assessments</h6>
                  </div>
                  <div className="mb-xl-0 col-2 text-right"></div>
                </div>
              </CardHeader>
              <CardBody>
                <Formik
                  initialValues={{
                    Country: "",
                    Organization: "Org",
                    City: "",
                  }}
                  innerRef={formRef}
                >
                  {(props) => (
                    <Form role="form">
                      <Row>
                        <Col md="6">
                          <TextField
                            select
                            fullWidth
                            id="Country"
                            name="Country"
                            variant="outlined"
                            label="Country"
                            onChange={(e) => {
                              // console.log(e);
                              // console.log("country: ", e.target.value);
                              setSelectedCountry(e.target.value);
                              handleFileChange(e, props);
                            }}
                            SelectProps={{ native: true }}
                            className={"form-control-alternative form-control-edit"}
                          >
                            <option key="" value=""></option>
                            {countriesAll &&
                              countriesAll.map((item, i) => (
                                <option key={item._id} value={item._id}>
                                  {item.name}
                                </option>
                              ))}
                          </TextField>
                        </Col>
                      </Row>
                      <br />

                      <Row>
                        <Col md="12">
                          <TextField
                            select
                            fullWidth
                            id="Organization"
                            name="Organization"
                            variant="outlined"
                            label="Organization"
                            value={Formik.value}
                            onChange={(e) => {
                              // console.log("organization: ", e.target.value);
                              let organization = Lodash.find(organizationAll, { _id: e.target.value });
                              setSelectedOrganization(organization ? organization : {});
                              setSelectedOrganizationCurr(e.target.value);
                            }}
                            SelectProps={{ native: true }}
                            className={"form-control-alternative form-control-edit"}
                          >
                            <option key="" value=""></option>
                            {organizationAll &&
                              Lodash.filter(organizationAll, { country: { _id: selectedCountry } }).map((item, i) => (
                                <option key={item._id} value={item._id}>
                                  {i+1}. {item.name}
                                </option>
                              ))}
                          </TextField>
                        </Col>
                      </Row>
                      <br />

                      <Row>
                        <Col md="8">
                          <TextField
                            select
                            fullWidth
                            id="City"
                            name="City"
                            variant="outlined"
                            label="Location"
                            // value={formik.values.City}
                            onChange={(e) => {
                              // console.log("selected user: ", e.target.value);
                              setSelectedUser(e.target.value);
                              loadSurveys(e);
                            }}
                            SelectProps={{ native: true }}
                            className={"form-control-alternative form-control-edit"}
                          >
                            <option key="" value=""></option>
                            {!Lodash.isEmpty(selectedOrganization) &&
                              Lodash.isArray(selectedOrganization.city) &&
                              selectedOrganization.city.map((item, i) =>
                                // item.linkedUser ? ( // Removing cities without users
                                  <option key={item._id} value={item.linkedUser}>
                                    {item.name}
                                  </option>
                                // ) : null
                              )}
                          </TextField>
                        </Col>
                      </Row>
                      <br />
                    </Form>
                  )}
                </Formik>
              </CardBody>
            </Card>
          </div>
        </Row>
      </Container>
    </>
  );
};

export default ReportAllAnswersByUser;
