// core components
import PageHeader from "components/Headers/PageHeader.js";
import CommonHeader from "components/Headers/CommonHeader";
import Lodash from "lodash";
import React, { useEffect, useState, useRef } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import { confirmAlert } from "react-confirm-alert";
import "react-confirm-alert/src/react-confirm-alert.css"; // Import css
import NavigateNextIcon from "@material-ui/icons/NavigateNext";

// reactstrap components
import { Card, CardBody, CardFooter, CardHeader, CardTitle, Col, Container, DropdownItem, DropdownMenu, DropdownToggle, Media, Nav, Navbar, UncontrolledDropdown, Row, Label, Form, Button } from "reactstrap";

import { fetchProfileOrg } from "actions/user";
import { fetchLocalizationContent, fetchMasterDataLanguage } from "actions/masterData";
import { LOGINSTATE } from "../../../config/config.json";
import { Formik } from "formik";
import * as Yup from "yup";
import { ChsStyles, GlobalStylesColor } from "constants/globalStyles";
import { institutionalQuestions, initialValues_assessmentPage, RTL_FLAG_RIGHT } from "helper";
import { Tooltip } from "@material-ui/core";
import { t } from "i18next";

const localStyle = { question: { fontWeight: "600" }, answer: { color: GlobalStylesColor.mbppBlue, marginBottom: 4 }, remark: { color: GlobalStylesColor.mbppBlue, marginBottom: 30 } };

const reviewSchema = Yup.object({
  organization: Yup.string().required(),
  type: Yup.string().required(),
  legalStatus: Yup.string().required(),
  legalMandate: Yup.string(),
  geographicArea: Yup.string().required(),
  numberOfEmployees: Yup.number(),
  drmActivities: Yup.string().required(),
  visionMission: Yup.string().required(),
  functionalStructure: Yup.string().required(),
  unitProject: Yup.string().required(),
  unitStakeholders: Yup.string().required(),
  country: Yup.string().required(),
});

const AnswerInstitute = () => {
  // dispatch and history
  const dispatch = useDispatch();
  const history = useHistory();

  const [surveyLanguage, setSurveyLanguage] = useState("en");
  const [surveyOrganization, setSurveyOrganization] = useState({}); // To hold organization Q&A
  const [showDetails, setShowDetails] = useState(false);
  const [systemContentLocale, setSystemContentLocale] = useState({});

  const [rtlAlignment, setRtlAlignment] = useState("left");

  // To update fomik values
  const formRef = useRef();

  const userLanguage = useSelector((state) => state.masterData.languageByCode);
  if (LOGINSTATE) console.log("userLanguage", userLanguage);
  useEffect(() => {
    // Get profile data to fill organization questions
    dispatch(fetchProfileOrg());
    dispatch(fetchLocalizationContent());
    let params = {
      user: {
        code: jUser?.user?.language,
      },
    };
    dispatch(fetchMasterDataLanguage(params.user));
  }, []);

  useEffect(() => {
    userLanguage &&
      userLanguage.language &&
      Object.values(userLanguage.language).map((lang, i) => {
        if (lang && lang.dir === "rtl") {
          setRtlAlignment("right");
        }
      });
  }, [userLanguage]);

  const userProfileOrg = useSelector((state) => state.user.userProfileOrg);
  useEffect(() => {
    if (LOGINSTATE) console.log("userProfileOrg: ", userProfileOrg);
    if (LOGINSTATE) console.log("userProfileOrg1: ", userProfileOrg?.profile);
    if (LOGINSTATE) console.log("userProfileOrg2: ", userProfileOrg?.profile?.employment);
    if (LOGINSTATE) console.log("userProfileOrg3: ", userProfileOrg?.profile?.employment?.organization);

    if (userProfileOrg?.profile?.language && !Lodash.isEmpty(userProfileOrg?.profile?.language)) {
      setSurveyLanguage(userProfileOrg?.profile?.language);
      if (LOGINSTATE) console.log("user language: ", surveyLanguage);
    }

    const organization =
      userProfileOrg && userProfileOrg?.profile && userProfileOrg?.profile?.employment && userProfileOrg?.profile?.employment?.organization && !Lodash.isEmpty(userProfileOrg?.profile?.employment?.organization)
        ? userProfileOrg?.profile?.employment?.organization
        : {};
    if (LOGINSTATE) console.log("start populating organization: ", organization);

    // updating the formik init value using profile details
    if (formRef.current && !Lodash.isEmpty(organization)) {
      const city = Lodash.find(organization.city, { _id: userProfileOrg?.profile?.employment?.linkedCity });

      // formRef.current?.resetForm();
      formRef.current.setFieldValue("organization", organization?.name, false);
      formRef.current.setFieldValue("type", organization?.type, false);
      formRef.current.setFieldValue("legalStatus", organization?.legalStatus?.status === true ? t("profile.Yes") : t("profile.No"), false);
      formRef.current.setFieldValue("legalMandate", organization?.legalStatus?.mandate, false);

      formRef.current.setFieldValue("geographicArea", organization?.geographicArea, false);
      formRef.current.setFieldValue("visionMission", organization?.visionMission?.status === true ? t("profile.Yes") : t("profile.No"), false);
      formRef.current.setFieldValue("visionMission_remark", organization?.visionMission?.remark, false);
      formRef.current.setFieldValue("functionalStructure", organization?.functionalStructure?.status === true ? t("profile.Yes") : t("profile.No"), false);
      formRef.current.setFieldValue("functionalStructure_remark", organization?.functionalStructure?.remark, false);
      formRef.current.setFieldValue("unitProject", organization?.unitProject?.status === true ? t("profile.Yes") : t("profile.No"), false);
      formRef.current.setFieldValue("unitProject_remark", organization?.unitProject?.remark, false);
      formRef.current.setFieldValue("unitStakeholders", organization?.unitStakeholders?.status === true ? t("profile.Yes") : t("profile.No"), false);
      formRef.current.setFieldValue("unitStakeholders_remark", organization?.unitStakeholders?.remark, false);

      formRef.current.setFieldValue("country", organization?.country.code, false);
      formRef.current.setFieldValue("language", userProfileOrg?.profile?.language, false);

      formRef.current.setFieldValue(
        "numberOfEmployees",
        organization?.numberOfOrganizationEmployees?.number && Lodash.isNumber(organization?.numberOfOrganizationEmployees?.number) ? organization?.numberOfOrganizationEmployees?.number : 0,
        false
      );
      formRef.current.setFieldValue("numberOfEmployees_remark", organization?.numberOfOrganizationEmployees?.remark, false);
      formRef.current.setFieldValue("drmActivities", organization?.drmActivities, false);


    } else {
      if (LOGINSTATE) console.log("in else");
    }
  }, [userProfileOrg, t]);

  const systemContentAll = useSelector((state) => state.masterData.localizationContentAll);
  useEffect(() => {
    if (LOGINSTATE) console.log("systemContent from server: ", systemContent);
    const systemContent = Lodash.find(systemContentAll, { languageCode: jUser?.user?.language });
    if (systemContent && systemContent !== undefined && systemContent !== null) {
      setSystemContentLocale(systemContent.assessmentPage);
    } else {
      setSystemContentLocale(initialValues_assessmentPage);
    }
  }, [systemContentAll]);

  const formGoNext = () => {
    history.push({ pathname: "/registered/assessment_answer_create" });
  };

  let currentUser = localStorage.getItem("userDetails");
  let jUser = JSON.parse(currentUser);
  if (LOGINSTATE) console.log("uRole", currentUser);

  return (
    <>
      <CommonHeader hType="takeAssessment" />

      {/* Page content */}
      <Container className="mt--7" fluid style={{ textAlign: rtlAlignment }}>
        {/* Card stats */}
        <Row className="mt-5">
          <Col lg="6" xl="4" style={{ display: Lodash.isEmpty(surveyOrganization) ? "block" : "none", opacity: 1.0 }}>
            <Card className="card-stats mb-4 mb-xl-0" onClick={(e) => { }}>
              <CardBody style={{ opacity: 0.5 }}>
                <Row>
                  <div className="col">
                    {/* <CardTitle tag="h5" className="text-uppercase text-muted mb-0">
                      {systemContentLocale.app_tool}
                    </CardTitle> */}
                    <span className="h2 font-weight-bold mb-0">{systemContentLocale.chsat}</span>
                  </div>
                  <Col className="col-auto">
                    <div className="icon icon-shape text-white rounded-circle shadow" style={{ backgroundColor: GlobalStylesColor.chsChsat }}>
                      <i className="fas fa-clipboard-check" style={{ fontSize: 32 }} />
                    </div>
                  </Col>
                </Row>
                <br />
              </CardBody>
            </Card>
          </Col>

          <Col lg="6" xl="4" style={{ display: Lodash.isEmpty(surveyOrganization) ? "block" : "none", opacity: 1.0 }}>
            <Card className="card-stats mb-4 mb-xl-0" onClick={(e) => { }}>
              <CardBody style={{ opacity: 0.5 }}>
                <Row>
                  <div className="col">
                    <span className="h2 font-weight-bold mb-0">{systemContentLocale.icat}</span>
                    <span></span>
                  </div>
                  <Col className="col-auto">
                    <div className="icon icon-shape text-white rounded-circle shadow" style={{ backgroundColor: GlobalStylesColor.chsIcat }}>
                      <i className="fas fa-university" style={{ fontSize: 32 }} />
                    </div>
                  </Col>
                </Row>
                <br />
              </CardBody>
            </Card>
          </Col>

          <Col lg="6" xl="4" style={{ display: Lodash.isEmpty(surveyOrganization) ? "block" : "none", opacity: 1.0 }}>
            <Card className="card-stats mb-4 mb-xl-0" onClick={(e) => { }}>
              <CardBody style={{ opacity: 0.5 }}>
                <Row>
                  <div className="col">
                    <span className="h2 font-weight-bold mb-0">{systemContentLocale.tie}</span>
                  </div>
                  <Col className="col-auto">
                    <div className="icon icon-shape text-white rounded-circle shadow" style={{ backgroundColor: GlobalStylesColor.chsTie }}>
                      <i className="fas fa-hands-helping" style={{ fontSize: 32 }} />
                    </div>
                  </Col>
                </Row>
                <br />
              </CardBody>
            </Card>
          </Col>
        </Row>

        <br />

        {/* Ask to select a survey with current organization profile*/}
        <Row style={{ display: Lodash.isEmpty(surveyOrganization) ? "block" : "none" }}>
          <div className="col">
            <Card className="shadow">
              {/* survey header */}
              <CardHeader className="border-0">
                {rtlAlignment === RTL_FLAG_RIGHT ? (
                  <div className="row">
                    <div className="mb-xl-0 col-1">
                      <Tooltip title="Profile" arrow>
                        <div
                          className="navbar-toggler"
                          style={{ cursor: "pointer" }}
                          onClick={(e) => {
                            history.push({ pathname: "/registered/user_profile" });
                          }}
                        >
                          <i className="fas fa-user-circle" style={{ fontSize: 30, color: GlobalStylesColor.mbppThemeBlue }} />
                        </div>
                      </Tooltip>
                    </div>
                    <div className="mb-xl-0 col-11">
                      {/* <small>{systemContentLocale.chs_app}</small> */}
                      <h3 className="mb-0">{systemContentLocale.app_assessment_step_01_title}</h3>
                      <h6 className="mb-0">{systemContentLocale.app_assessment_step_01_message}</h6>
                    </div>
                  </div>
                ) : (
                  <div className="row">
                    <div className="mb-xl-0 col-11">
                      {/* <small>{systemContentLocale.chs_app}</small> */}
                      <h3 className="mb-0">{systemContentLocale.app_assessment_step_01_title}</h3>
                      <h6 className="mb-0">{systemContentLocale.app_assessment_step_01_message}</h6>
                    </div>
                    <div className="mb-xl-0">
                      <Tooltip title={t("insitutionalDetails.profile")} arrow>
                        <div
                          className="navbar-toggler"
                          style={{ cursor: "pointer" }}
                          onClick={(e) => {
                            history.push({ pathname: "/registered/user_profile" });
                          }}
                        >
                          <i className="fas fa-user-circle" style={{ fontSize: 30, color: GlobalStylesColor.mbppThemeBlue }} />
                        </div>
                      </Tooltip>
                    </div>
                  </div>
                )}
              </CardHeader>

              {/* survey content */}
              <CardBody>
                <Formik
                  initialValues={{
                    organization: "",
                    type: "",
                    legalStatus: "", // No
                    // legalStatus_remark: "",
                    legalMandate: "", // Not applicable
                    // legalMandate_remark: "",
                    geographicArea: "",
                    numberOfEmployees: 0,
                    numberOfEmployees_remark: "",
                    drmActivities: "",
                    visionMission: "",
                    visionMission_remark: "",
                    functionalStructure: "",
                    functionalStructure_remark: "",
                    unitProject: "",
                    unitProject_remark: "",
                    unitStakeholders: "",
                    unitStakeholders_remark: "",
                    country: "",
                  }}
                  validationSchema={reviewSchema}
                  onSubmit={(values, actions) => {
                    if (LOGINSTATE) console.log("values: ", values);
                    formGoNext();
                  }}
                  innerRef={formRef}
                >
                  {(props) => (
                    <Form role="form" onSubmit={props.handleSubmit}>
                      <div>
                        {systemContentLocale.app_assessment_sentence} <a href="/registered/user_profile"> {systemContentLocale.app_assessment_sentence_ref}</a>.
                      </div>

                      <br />
                      <div
                        onClick={(e) => {
                          setShowDetails(true);
                        }}
                        style={{ fontSize: 12, cursor: "pointer", display: showDetails ? "none" : "block" }}
                      >
                        {systemContentLocale.app_assessment_organization_details}
                      </div>

                      <div style={{ display: showDetails ? "block" : "none" }}>
                        {/* {console.log("formik values: ", props.values)} */}

                        <div>
                          <Label check style={localStyle.question}>
                            {t("insitutionalDetails.Name")}
                          </Label>
                          <br />
                          <Label check style={localStyle.remark}>
                            {props.values.organization}
                          </Label>
                        </div>
                        <div>
                          <Label check style={localStyle.question}>
                            {t("insitutionalDetails.Type")}
                          </Label>
                          <br />
                          <Label check style={localStyle.remark}>
                            {props.values.type}
                          </Label>
                        </div>
                        <div>
                          <Label check style={localStyle.question}>
                            {t("insitutionalDetails.LegalEntity")}
                          </Label>
                          <br />
                          <Label check style={localStyle.answer}>
                            {t("insitutionalDetails.answer")}: {props.values.legalStatus}
                          </Label>
                          <br />
                          <Label check style={localStyle.remark}>
                            {t("insitutionalDetails.mandate")}: {props.values.legalMandate}
                          </Label>
                        </div>

                        <div>
                          <Label check style={localStyle.question}>
                            {t("insitutionalDetails.GeographicArea")}
                          </Label>
                          <br />
                          <Label check style={localStyle.remark}>
                            {props.values.geographicArea}
                          </Label>
                        </div>
                        <div>
                          <Label check style={localStyle.question}>
                            {t("insitutionalDetails.NumberOfEmployees")}
                          </Label>
                          <br />
                          <Label check style={localStyle.answer}>
                            {t("insitutionalDetails.answer")}: {props.values.numberOfEmployees}
                          </Label>
                          <br />
                          <Label check style={localStyle.remark}>
                            {t("insitutionalDetails.remark")}: {props.values.numberOfEmployees_remark}
                          </Label>
                        </div>
                        <div>
                          <Label check style={localStyle.question}>
                            {t("insitutionalDetails.DRMActivities")}
                          </Label>
                          <br />
                          <Label check style={localStyle.remark}>
                            {props.values.drmActivities}
                          </Label>
                        </div>
                        <div>
                          <Label check style={localStyle.question}>
                            {t("insitutionalDetails.VisionMission")}
                          </Label>
                          <br />
                          <Label check style={localStyle.answer}>
                            {t("insitutionalDetails.answer")}: {props.values.visionMission}
                          </Label>
                          <br />
                          <Label check style={localStyle.remark}>
                            {t("insitutionalDetails.remark")}: {props.values.visionMission_remark}
                          </Label>
                        </div>
                        <div>
                          <Label check style={localStyle.question}>
                            {t("insitutionalDetails.FunctionalStructure")}
                          </Label>
                          <br />
                          <Label check style={localStyle.answer}>
                            {t("insitutionalDetails.answer")}: {props.values.functionalStructure}
                          </Label>
                          <br />
                          <Label check style={localStyle.remark}>
                            {t("insitutionalDetails.remark")}: {props.values.functionalStructure_remark}
                          </Label>
                        </div>
                        <div>
                          <Label check style={localStyle.question}>
                            {t("insitutionalDetails.UnitProject")}
                          </Label>
                          <br />
                          <Label check style={localStyle.answer}>
                            {t("insitutionalDetails.answer")}: {props.values.unitProject}
                          </Label>
                          <br />
                          <Label check style={localStyle.remark}>
                            {t("insitutionalDetails.remark")}: {props.values.unitProject_remark}
                          </Label>
                        </div>
                        <div>
                          <Label check style={localStyle.question}>
                            {t("insitutionalDetails.Stakeholders")}
                          </Label>
                          <br />
                          <Label check style={localStyle.answer}>
                            {t("insitutionalDetails.answer")}: {props.values.unitStakeholders}
                          </Label>
                          <br />
                          <Label check style={localStyle.remark}>
                            {t("insitutionalDetails.remark")}: {props.values.unitStakeholders_remark}
                          </Label>
                        </div>
                      </div>

                      <Row>
                        <Col lg="12" className="">
                          {(LOGINSTATE) ? console.log("Yup errors: ", props.errors) : null}
                          {props.submitCount > 0 && !props.isValid ? (
                            <h4 style={{ marginTop: 20, marginBottom: 0, color: GlobalStylesColor.mbppThemeBlue }}>{t("assessmentPage.profileIncomplete")}</h4>
                          ) : (
                            <h6> </h6>
                          )}
                        </Col>
                      </Row>
                    </Form>
                  )}
                </Formik>
              </CardBody>

              {/* card footer */}
              <CardFooter className="py-4">
                {rtlAlignment === RTL_FLAG_RIGHT ? (
                  <Row>
                    <Col lg="12" className="text-right" style={{ marginBottom: 10 }}>
                      <Button
                        style={ChsStyles.GeneralButton}
                        color="primary"
                        onClick={() => {
                          formRef.current?.submitForm();
                        }}
                      >
                        {systemContentLocale.app_next}
                      </Button>
                    </Col>
                  </Row>
                ) : (
                  <div className="mb-xl-0 col-6">
                    <Button
                      style={{ ...ChsStyles.GeneralButton, marginBottom: 5, marginTop: 5 }}
                      color="primary"
                      onClick={() => {
                        formRef.current?.submitForm();
                      }}
                    >
                      {systemContentLocale.app_next}
                    </Button>
                  </div>
                )}
              </CardFooter>
            </Card>
          </div>
        </Row>
      </Container>
    </>
  );
};

export default AnswerInstitute;
