import * as WebDataRocksReact from "assets/js/webdatarocks.react";
import PageHeader from "components/Headers/PageHeader.js";
import { LOGINSTATE } from "../../../config/config.json";
// reactstrap components
import { Card, CardBody, CardFooter, CardHeader, Col, Container, Row, Form, Button } from "reactstrap";
import React, { useEffect, useState, useRef } from "react";
import { useDispatch } from "react-redux";
import Lodash from "lodash";
import * as api from "api/index";
import { Formik } from "formik";
import { TextField } from "@material-ui/core";
import Moment from "moment";

const initReportConfiguration = {
  dataSource: {
    data: [],
  },
  slice: {
    drills: {
      drillAll: false,
    },
  },
  formats: [
    {
      name: "",
      thousandsSeparator: " ",
      decimalSeparator: ".",
      decimalPlaces: 2,
      nullValue: " ",
      infinityValue: "Infinity",
      divideByZeroValue: "Infinity",
    },
  ],
  options: {
    grid: {
      showGrandTotals: "off",
    },
  },
};

const ReportAllAnswersBySegmentByOrganization = (props) => {
  // For webdatarock values
  const reportRef = useRef();
  const formRef = useRef();
  const dispatch = useDispatch();

  const [reportConfiguration, setReportConfiguration] = useState(null);
  const [answerData, setAnswerData] = useState([]);

  useEffect(() => {
    fetchReportData();
  }, []);

  const fetchReportData = async () => {
    const params = { statType: "AssessmentDetail" };
    const { data } = await api.fetchAnswerStat(params);
    if (LOGINSTATE) console.log("pivot data: ", data);

    if (data && data.data && data.data.length > 0) {
      setAnswerData(data.data);
      if (LOGINSTATE) console.log("reportData: ", data.data);
      updateReport(data.data)
    }
  };

  const updateReport = (filteredData) => {
    setReportConfiguration(null);

    // if (country && organization && location && type && country.length > 0 && organization.length > 0 && location.length > 0 && type.length > 0) {
    let reportConfig = initReportConfiguration;
    // let filteredDataCustom = filteredData.map(({ date, organization_type, segment_code, segments_score, version }) => ({ date, organization_type, segment_code, segments_score, version }));
    let filteredDataCustom = filteredData.map(({ date, organization_type, segment_code, score, segments_score, version, country, user, organization }) => {
      let date_time = Moment.utc(date).format("YYYY-MM-DD hh:mm:ss A");
      date = Moment.utc(date).format("YYYY-MM-DD");
      // filteredDataCustom["segment_code"] = "Total";
      return { date, date_time, segment_code, score, segments_score, version, country, user, organization };
    });
    if (LOGINSTATE) console.log("filteredDataCustom: ", filteredDataCustom);

    reportConfig["dataSource"]["data"] = [
      {
        country: { type: "string" },
        date: { type: "string" },
        date_time: { type: "string" },
        organization: { type: "string" },
        score: { type: "string" },
        segment_code: { type: "string" },
        segments_score: { type: "number" },
        user: { type: "string" },
        version: { type: "string" },
      },
      ...filteredDataCustom,
    ];

    reportConfig["slice"] = {
      rows: [{ uniqueName: "country" },
      { uniqueName: "organization" },
      { uniqueName: "user" },
      { uniqueName: "date" }],
      columns: [{
        uniqueName: "segment_code",
        filter: {
          "members": [
            "segment_code.CH1",
            "segment_code.ICAT1",
            "segment_code.TIE 1"
          ]
        }
      }],
      measures: [{ uniqueName: "score", aggregation: "count" }],
      drills: { drillAll: true },
    };
    if (LOGINSTATE) console.log("report", reportConfig);

    setReportConfiguration(reportConfig);
    // } else {
    //   // Not showing the pivot, otherwise it's not refreshing
    // }
  };

  const reportComplete = () => {
    if (reportRef.current && reportRef.webdatarocks) {
      if (LOGINSTATE) console.log("report: ", reportRef.webdatarocks.getReport());
    }
  };

  const customizeToolbar = (toolbar) => {
    let tabs = toolbar.getTabs(); // get all tabs from the toolbar
    toolbar.getTabs = function () {
      delete tabs[0]; // delete the first tab
      delete tabs[1];
      delete tabs[2];
      delete tabs[4];
      delete tabs[6];
      return tabs;
    };
  };

  return (
    <>
      <PageHeader />

      {/* Page content */}
      <Container className="mt--7" fluid>
        {/* create survey questions */}
        <Row className="mt-5">
          <Col>
            <Card className="shadow">
              {/* survey header */}
              <CardHeader className="border-0">
                <div className="row">
                  <div className="mb-xl-0 col-10">
                    <h3 className="mb-0">Overall Assessment Attends</h3>
                    <h6 className="mb-0">Country wise organization assessment attends breakdown</h6>
                  </div>
                </div>
              </CardHeader>

              {/* question content */}
              <CardBody id="pivotCard">
                {reportConfiguration && !Lodash.isEmpty(reportConfiguration) ? (
                  <>
                    <WebDataRocksReact.Pivot ref={reportRef} D toolbar={true} beforetoolbarcreated={customizeToolbar} report={reportConfiguration} reportcomplete={reportComplete} />
                  </>
                ) : null}
              </CardBody>

              {/* survey footer */}
              <CardFooter className="py-4"></CardFooter>
            </Card>
          </Col>
        </Row>
      </Container>
    </>
  );
};

export default ReportAllAnswersBySegmentByOrganization;
