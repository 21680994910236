import React, { useState, useEffect } from "react";
import { useFormik } from "formik";
import * as yup from "yup";
import { useDispatch, useSelector } from "react-redux";
import { useLocation, useHistory } from "react-router-dom";
import { ChsStyles } from "constants/globalStyles";

// reactstrap components
import { Card, CardHeader, CardBody, CardFooter, Container, FormGroup, Form, Button, Row, Col } from "reactstrap";

import { TextField, FormControlLabel, Switch, Grid } from "@material-ui/core";
import { fetchFrmTopics, fetchFrmQus, createFrmQus, updateFrmQus } from "actions/forum";

// core components
import PageHeader from "components/Headers/PageHeader.js";
import { LOGINSTATE } from "../../../config/config.json";

const validationSchemaQuestion = yup.object({
  question: yup.string("Enter Question text").required("Question is required"),
  description: yup.string("Enter Question description").min(3, "Description should be of minimum 3 characters length").max(2000, "Description should be of maximum 2000 characters length"),
});

const ForumQuestionFrom = (props) => {
  // dispatch and history
  const dispatch = useDispatch();
  const history = useHistory();
  // set state topic id
  const [topicId, setTopicId] = useState(null);
  // set state topic
  const [topic, setTopic] = useState(null);
  // set state topic description
  const [topicDescription, setTopicDescription] = useState(null);
  // set state sub topic id
  const [subTopicId, setSubTopicId] = useState(null);
  // set state sub topic
  const [subTopic, setSubTopic] = useState(null);
  // set state sub topic description
  const [subTopicDescription, setSubTopicDescription] = useState(null);
  // get topic details
  const selectFrmTopic = (state) => {
    const itemArray = state.forum.frmAllTopics?.filter((item) => {
      return item._id === props.location.state?.frmTopicId;
    });
    if (itemArray && itemArray[0]) {
      return itemArray[0];
    }
  };
  const topicDetails = useSelector(selectFrmTopic);
  // get sub topic details
  const selectFrmSubTopic = (topicDetails) => {
    const itemArray =
      topicDetails?.subTopic?.length > 0 &&
      topicDetails?.subTopic?.filter((item) => {
        return item._id === props.location.state?.frmSubTopicId;
      });
    if (itemArray && itemArray[0]) {
      return itemArray[0];
    }
  };
  const subTopicDetails = selectFrmSubTopic(topicDetails);
  // get Question details
  const selectFrmQuestion = (state) => {
    const itemArray = state.forum.frmAllQus?.filter((item) => {
      return item._id === props.location.state?.frmQuestionId;
    });
    if (itemArray && itemArray[0]) {
      return itemArray[0];
    }
  };
  const frmQuestion = useSelector(selectFrmQuestion);

  useEffect(() => {
    // fetching forum topics
    dispatch(fetchFrmTopics());
    // fetching forum Questions
    dispatch(fetchFrmQus());
  }, []);

  useEffect(() => {
    if (topicDetails && topicDetails._id) {
      setTopicId(topicDetails._id);
      setTopic(topicDetails.topic);
      setTopicDescription(topicDetails.description);
    }
    if (subTopicDetails && subTopicDetails._id) {
      setSubTopicId(subTopicDetails._id);
      formik.values.subTopicId = subTopicDetails._id;
      setSubTopic(subTopicDetails.subTopic);
      setSubTopicDescription(subTopicDetails.description);
    }
    if (frmQuestion && frmQuestion._id) {
      formik.values.forumQuestionId = frmQuestion._id;
      formik.values.subTopicId = frmQuestion.subTopic;
      formik.values.question = frmQuestion.question;
      formik.values.keyWord = frmQuestion.keyWord;
      formik.values.description = frmQuestion.description;
      formik.values.status = frmQuestion.status;
      setImage(frmQuestion.image && frmQuestion.image.length !== 0 ? frmQuestion.image : [""]);
    }
  }, [topicDetails, subTopicDetails, frmQuestion]);

  // set state image list
  const [listImage, setImage] = useState([""]);

  const formik = useFormik({
    initialValues: {
      subTopicId: "",
      forumQuestionId: "",
      question: "",
      image: [],
      keyWord: [],
      description: "",
      status: true,
      redirect: "/registered/faq_questions",
    },
    validationSchema: validationSchemaQuestion,
    onSubmit: (values, onSubmitProps) => {
      values.image = listImage.filter((el) => {
        return el !== null && el !== "";
      });
      if (LOGINSTATE) console.log("values", values);
      if (values.subTopicId) {
        if (values.forumQuestionId) {
          let pathParam = {
            path: "/registered/faq_questions",
            state: { frmTopicId: props.location.state?.frmTopicId, frmSubTopicId: props.location.state?.frmSubTopicId, frmQuestionId: props.location.state?.frmQuestionId },
          };
          let formData = { forumQuestionId: values.forumQuestionId, description: values.description, question: values.question };
          dispatch(updateFrmQus(formData, history, pathParam));
        } else {
          let pathParam = {
            path: "/registered/faq_questions",
            state: { frmTopicId: props.location.state?.frmTopicId, frmSubTopicId: props.location.state?.frmSubTopicId, frmQuestionId: props.location.state?.frmQuestionId },
          };
          let formData = { subTopicId: props.location.state?.frmSubTopicId, forumQuestionId: values.forumQuestionId, description: values.description, question: values.question };
          dispatch(createFrmQus(formData, history, pathParam));
        }
      }
    },
    onReset: () => {
      setImage([""]);
    },
  });

  return (
    <>
      <PageHeader />
      {/* Page content */}
      <Container className="mt--7" fluid>
        {/* Table School */}
        <Row className="mt-5">
          <div className="col">
            <Card className="shadow">
              <CardHeader className="border-0">
                <h3 className="mb-0">Question Details</h3>
              </CardHeader>
              <Form role="form" onSubmit={formik.handleSubmit} onReset={formik.handleReset}>
                <CardBody>
                  <FormGroup className="mb-3">
                    <Grid container spacing={1} alignItems="center" style={ChsStyles.Grid}>
                      <Grid item xs={1} sm={1} className="px-3 text-right">
                        <i className="fas fa-question"></i>
                      </Grid>
                      <Grid item xs={10} sm={10}>
                        <TextField
                          fullWidth
                          id="question"
                          name="question"
                          label="Question"
                          variant="outlined"
                          value={formik.values.question}
                          onChange={formik.handleChange}
                          multiline
                          rows={8}
                          error={formik.touched.question && Boolean(formik.errors.question)}
                          helperText={formik.touched.question && formik.errors.question}
                        />
                      </Grid>
                    </Grid>

                    <Grid container spacing={1} alignItems="center" style={ChsStyles.Grid}>
                      <Grid item xs={1} sm={1} className="px-3 text-right">
                        <i className="fas fa-align-left" />
                      </Grid>
                      <Grid item xs={10} sm={10}>
                        <TextField
                          fullWidth
                          id="description"
                          name="description"
                          label="Description"
                          variant="outlined"
                          value={formik.values.description}
                          onChange={formik.handleChange}
                          multiline
                          rows={8}
                          error={formik.touched.description && Boolean(formik.errors.description)}
                          helperText={formik.touched.description && formik.errors.description}
                        />
                      </Grid>
                    </Grid>
                  </FormGroup>
                </CardBody>

                <CardFooter className="py-4">
                  <Row>
                    <Col lg="6" className="text-left">
                      <Button
                        style={ChsStyles.GeneralButton}
                        color="primary"
                        onClick={() => {
                          history.push({pathname: "/registered/faq_management"});
                        }}
                      >
                        BACK
                      </Button>
                    </Col>
                    <Col lg="6" className="text-right">
                      <Button style={ChsStyles.GeneralButton} color="primary" type="reset">
                        RESET
                      </Button>
                      <Button style={ChsStyles.GeneralButton} color="primary" type="submit">
                        {formik.values.forumQuestionId ? "UPDATE" : "SAVE"}
                      </Button>
                    </Col>
                  </Row>
                </CardFooter>
              </Form>
            </Card>
          </div>
        </Row>
      </Container>
    </>
  );
};

export default ForumQuestionFrom;
