import { GRAPH_LOCAL_ANSWER } from "constants/actionTypes";

import { useDispatch, useSelector } from "react-redux";

import { Badge, Card, CardHeader, Container, Row, Table, CardBody, CardFooter, Col, Button } from "reactstrap";

import CommonHeader from "components/Headers/CommonHeader";
import { fetchLocalizationBySurveyTemplate } from "actions/survey";
import { fetchLocalizationContent } from "actions/masterData";
import React, { useEffect, useState, useRef } from "react";
import Chart from "chart.js"; // javascipt plugin for creating charts. Does the style formatting
import { Bar, Line, Radar } from "react-chartjs-2";
import Lodash from "lodash";
import { ChsStyles, GlobalStylesColor } from "constants/globalStyles";
import { useHistory } from "react-router-dom";
import { LOGINSTATE } from "../../../config/config.json";
import { initialValues_assessmentPage } from "helper";

import { confirmAlert } from "react-confirm-alert";
import "../../../assets/css/react-confirm-alert-custom.css"; /// Import css

import AnswerPrintTemplateByHtmlExtend from "./AnswerPrintTemplateByHtmlExtend";

const AnswerPrint = (props) => {
  const history = useHistory();
  const dispatch = useDispatch();

  const [printing, setPrinting] = useState(false);

  const [systemContentLocale, setSystemContentLocale] = useState({});

  const locale = props?.location?.state;
  let answer = props?.location?.state?.answer;

  let focusCounter = 0;
  const focusDiv = useRef();

  if (LOGINSTATE) console.log("passed location param: ", props?.location?.state);
  const [fontSize, setFontSize] = useState(14);

  useEffect(() => {
    // Get the active survey if information is exist
    // Get system content for locale if exist

    if (LOGINSTATE) console.log("passed location param: ", locale);
    if (locale && locale?.answer) {
      // get active survey details
      const params = { type: answer?.type, languageCode: jUser?.user?.language };
      if (LOGINSTATE) console.log("fetching master data with: ", params);

      dispatch(fetchLocalizationContent(params));
    }
    dispatch(fetchLocalizationBySurveyTemplate({ surveyId: answer?.surveyId }, history));
  }, [locale]);

  // get system content
  const systemContent = useSelector((state) => state.masterData.localizationContentAll);
  const surveyLocalizationAll = useSelector((state) => state.survey.surveyLocalizationAll);
  useEffect(() => {
    if (LOGINSTATE) console.log("survey Locales : ", surveyLocalizationAll);
    const surveyLocaleForTemplate = Lodash.find(surveyLocalizationAll, { languageCode: jUser.user.language });

    if (LOGINSTATE) console.log("survey Locale for template: ", surveyLocaleForTemplate);

    const segmentsLocale = surveyLocaleForTemplate?.segments ? surveyLocaleForTemplate?.segments : answer?.segments;

    const segment = answer?.segments;

    if (LOGINSTATE) console.log("segmentDataLocale : ", segmentsLocale);

    if (LOGINSTATE) console.log("systemContent from server: ", systemContent);
    if (systemContent !== undefined && systemContent !== null && Lodash.isArray(systemContent) && systemContent.length > 0) {
      setSystemContentLocale(systemContent[0].assessmentPage);
    } else {
      setSystemContentLocale(initialValues_assessmentPage);
    }

    // let locale = segmentsLocale;

    let full = { locale, segmentsLocale, systemContentLocale, segment, fontSize };
    if (LOGINSTATE) console.log("full", full);
    localStorage.setItem(GRAPH_LOCAL_ANSWER, JSON.stringify(full));
    dispatch({ type: GRAPH_LOCAL_ANSWER, payload: full });
  }, [systemContent, surveyLocalizationAll]);

  const toPdf = (print) => {
    setPrinting(true);

    // start checking for focus state to see when is the print preview close, or close after 10 seconds
    focusCounter = 0;
    // const focusWatch = setInterval(() => {
    //   const ss = document.hasFocus();
    //   focusCounter++;
    //   if(focusCounter>20){
    //     clearInterval(focusWatch);
    //   }
    // }, 500);

    // give a time to re-render the layout again for print preview
    setTimeout(() => {
      window.print();
    }, 500);

    // give a time to re-set the layout after print preview is complete
    // setTimeout(() => {
    //   // clearInterval(focusWatch);
    //   setPrinting(false);
    // }, 5000);

  };

  const endPdf = () => {
    if (printing) {
      setPrinting(false);
    }
  }

  let currentUser = localStorage.getItem("userDetails");
  let jUser = JSON.parse(currentUser);
  let uRole = currentUser && jUser && jUser.user && jUser.user.urole ? jUser.user.urole : "GENERAL";
  if (LOGINSTATE) console.log("uRole", jUser);

  return (
    <>
      {/* {printing ? null : <CommonHeader hType="print" />} */}

      {/* Page content */}
      <div
        className={printing ? "" : "mt--7"}
        fluid
        style={{ margin: printing ? 0 : 35 }}
        onTouchStart={() => { endPdf() }}
        onMouseDown={() => { endPdf()}}
      >
      <CardHeader style={{ display: printing ? "none" : "block" }}>
        <Row>
          <Col md="10" style={{ marginBottom: 10 }}>
            <small>{systemContentLocale.self_assessment}</small>
            <h3 className="mb-0">{systemContentLocale.recommendations_self_assessment}</h3>

            <h6 className="mb-0">{systemContentLocale.pdf_version}</h6>
          </Col>
          <Col md="2">
            <div className="row" style={{ margin: 0 }}>
              <small>{systemContentLocale.font_size}</small>
            </div>
            <div>
              <div className="row" style={{ margin: 0 }}>
                <div
                  style={{ cursor: "pointer" }}
                  onClick={(e) => {
                    setFontSize(fontSize > 3 ? fontSize - 1 : 3);
                  }}
                >
                  <i className={"fas fa-chevron-circle-down"} style={{ fontSize: 24, color: GlobalStylesColor.mbppThemeBlue }} />
                </div>
                <h4 className="mb-0" style={{ marginLeft: 10, marginRight: 10 }}>
                  {fontSize}
                </h4>
                <div
                  style={{ cursor: "pointer" }}
                  onClick={(e) => {
                    setFontSize(fontSize + 1);
                  }}
                >
                  <i className={"fas fa-chevron-circle-up"} style={{ fontSize: 24, color: GlobalStylesColor.mbppThemeBlue }} />
                </div>
              </div>
            </div>
          </Col>
        </Row>
      </CardHeader>

      <div id="answerPrintTemplateByHtml" style={{ backgroundColor: "#fff", minWidth: printing ? 1000 : 200 }}>
        <AnswerPrintTemplateByHtmlExtend font={fontSize ? fontSize : 14} />
      </div>

      <CardFooter className="py-4" style={{ display: printing ? "none" : "block" }}>
        <Row>
          <Col xs="6" className="text-left">
            <Button
              style={ChsStyles.GeneralButton}
              color="primary"
              onClick={() => {
                history.goBack();
              }}
            >
              {systemContentLocale.app_back}
            </Button>
          </Col>
          <Col xs="6" className="text-right">
            <Button
              style={ChsStyles.GeneralButton}
              color="primary"
              onClick={(e) => {
                toPdf(true);
              }}
            >
              {systemContentLocale.print}
            </Button>
          </Col>
        </Row>
      </CardFooter>
    </div>
    </>
  );
};

export default AnswerPrint;
