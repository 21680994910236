import {
  REPORT_ANSWER_ALL,
  REPORT_ANSWER_BY_USER,
  REPORT_ANSWER_BY_COUNTRY,
  REPORT_ANSWER_STAT,
  REPORT_USER_STAT,
  SHOW_LOADING
} from 'constants/actionTypes';
import { LOGINSTATE } from "config/config.json";
import Lodash from "lodash";
import { confirmAlert } from 'react-confirm-alert';
import * as api from '../api/index.js';
// import 'react-confirm-alert/src/react-confirm-alert.css'; // Import css


export const fetchReportAnswerAll = () => async (dispatch) => {
  try {
    dispatch({ type: SHOW_LOADING, payload: true });
    const { data } = await api.fetchReportAnswerAll();
    if(LOGINSTATE) console.log("report data", data);

    if (data?.status === 'success') {
      dispatch({ type: REPORT_ANSWER_ALL, payload: data?.data });
    }
    else if (data?.status === 'error') {
      confirmAlert({ message: data?.message, buttons: [{ label: 'Ok', onClick: () => { } }] });
    }
  }
  catch (error) {
    if(LOGINSTATE) console.log("catch error", error.response);

    // process the error to show proper error message in popup
    let errorTitle = error?.response?.data?.data ? error?.response?.data?.message : null;
    let errorMessage = error?.response?.data?.data ? error?.response?.data?.data : error?.response?.data?.message;
    errorMessage = Lodash.isEmpty(errorMessage) ? "Oops, something went wrong" : errorMessage;
    confirmAlert({ title: errorTitle, message: errorMessage, buttons: [{ label: 'Ok', onClick: () => { } }] });
  }
  finally {
    dispatch({ type: SHOW_LOADING, payload: false });
  }

};


export const fetchReportAnswersByUser = () => async (dispatch) => {
  try {
    dispatch({ type: SHOW_LOADING, payload: true });
    const { data } = await api.fetchReportAnswersByUser();
    if(LOGINSTATE) console.log("report data", data);

    if (data?.status === 'success') {
      dispatch({ type: REPORT_ANSWER_BY_USER, payload: data?.data });
    }
    else if (data?.status === 'error') {
      confirmAlert({ message: data?.message, buttons: [{ label: 'Ok', onClick: () => { } }] });
    }
  }
  catch (error) {
    if(LOGINSTATE) console.log("catch error", error.response);


    // process the error to show proper error message in popup
    let errorTitle = error?.response?.data?.data ? error?.response?.data?.message : null;
    let errorMessage = error?.response?.data?.data ? error?.response?.data?.data : error?.response?.data?.message;
    errorMessage = Lodash.isEmpty(errorMessage) ? "Oops, something went wrong" : errorMessage;
    confirmAlert({ title: errorTitle, message: errorMessage, buttons: [{ label: 'Ok', onClick: () => { } }] });
  }
  finally {
    dispatch({ type: SHOW_LOADING, payload: false });
  }

};


export const fetchReportAnswerByCountry = () => async (dispatch) => {
  try {
    dispatch({ type: SHOW_LOADING, payload: true });
    const { data } = await api.fetchReportAnswerByCountry();
    if(LOGINSTATE) console.log("report data", data);

    if (data?.status === 'success') {
      dispatch({ type: REPORT_ANSWER_BY_COUNTRY, payload: data?.data });
    }
    else if (data?.status === 'error') {
      confirmAlert({ message: data?.message, buttons: [{ label: 'Ok', onClick: () => { } }] });
    }
  }
  catch (error) {
    if(LOGINSTATE) console.log("catch error", error.response);


    // process the error to show proper error message in popup
    let errorTitle = error?.response?.data?.data ? error?.response?.data?.message : null;
    let errorMessage = error?.response?.data?.data ? error?.response?.data?.data : error?.response?.data?.message;
    errorMessage = Lodash.isEmpty(errorMessage) ? "Oops, something went wrong" : errorMessage;
    confirmAlert({ title: errorTitle, message: errorMessage, buttons: [{ label: 'Ok', onClick: () => { } }] });
  }
  finally {
    dispatch({ type: SHOW_LOADING, payload: false });
  }

};


export const fetchAnswerStat = (formData, router) => async (dispatch) => {
  try {
    dispatch({ type: SHOW_LOADING, payload: true });
    const { data } = await api.fetchAnswerStat(formData);
    if(LOGINSTATE) console.log("report data", data);

    if (data?.status === 'success') {
      dispatch({ type: REPORT_ANSWER_STAT, payload: data?.data });
    }
    else {
      dispatch({ type: REPORT_ANSWER_STAT, payload: [] });
    }
  }
  catch (error) {
    if(LOGINSTATE) console.log("catch error", error.response);
    dispatch({ type: REPORT_ANSWER_STAT, payload: [] });
    if (error?.response?.status && error?.response?.status === 401) {
      confirmAlert({ message: "Session expired!", buttons: [{ label: "Ok", onClick: () => {} }], closeOnEscape: false, closeOnClickOutside: false });
      router.push("/auth/signin");
    }
  }
  finally {
    dispatch({ type: SHOW_LOADING, payload: false });
  }
};


export const fetchUserStat = () => async (dispatch) => {
  try {
    dispatch({ type: SHOW_LOADING, payload: true });
    const { data } = await api.fetchUserStat();
    if(LOGINSTATE) console.log("report data", data);

    if (data?.status === 'success') {
      dispatch({ type: REPORT_USER_STAT, payload: data?.data });
    }
    else {
      dispatch({ type: REPORT_USER_STAT, payload: [] });
    }
  }
  catch (error) {
    if(LOGINSTATE) console.log("catch error", error.response);
    dispatch({ type: REPORT_USER_STAT, payload: [] });
  }
  finally {
    dispatch({ type: SHOW_LOADING, payload: false });
  }
};



