import { ORG_FETCH_ALL, ORG_UPDATE, ORG_CITY_STATUS_UPDATE, ORG_FETCH_ALL_BY_COUNTRY_ID, SHOW_LOADING, ORGANIZATION_PROFILE_DELETE } from "constants/actionTypes";
import { LOGINSTATE } from "config/config.json";
import * as api from "../api/index.js";
import Lodash from "lodash";
import { confirmAlert } from "react-confirm-alert";
import "react-confirm-alert/src/react-confirm-alert.css"; // Import css

// Surveys

// no need trans
export const fetchOrganizationAll = (formData, router) => async (dispatch) => {
  try {
    dispatch({ type: SHOW_LOADING, payload: true });
    const { data } = await api.fetchOrganizationAll(formData);
    if (data?.status === "success") {
      dispatch({ type: ORG_FETCH_ALL, payload: data?.data });
    }
    if (data?.status === "error") {
      if (LOGINSTATE) console.log(data);
      confirmAlert({ message: "Oops!, something went wrong. Please try again.", buttons: [{ label: "Ok", onClick: () => { } }], closeOnEscape: false, closeOnClickOutside: false });
    }
  } catch (error) {
    if (error?.response?.status && error?.response?.status === 401) {
      confirmAlert({ message: "Session expired!", buttons: [{ label: "Ok", onClick: () => { } }], closeOnEscape: false, closeOnClickOutside: false });
      router.push("/auth/signin");
    } else {
      alert(error);
    }
    if (LOGINSTATE) console.log(error);
  } finally {
    dispatch({ type: SHOW_LOADING, payload: false });
  }
};

// trans done
export const fetchOrganizationAllByCountryId = (formData, router, t) => async (dispatch) => {
  try {
    dispatch({ type: SHOW_LOADING, payload: true });
    const { data } = await api.fetchOrganizationAllByCountryId(formData);
    if (data?.status === "success") {
      dispatch({ type: ORG_FETCH_ALL_BY_COUNTRY_ID, payload: data?.data });
    }
    if (data?.status === "error") {
      if (LOGINSTATE) console.log(data);
      // confirmAlert({ message: "Oops!, something went wrong. Please try again.", buttons: [{ label: "Ok", onClick: () => {} }], closeOnEscape: false, closeOnClickOutside: false });
      confirmAlert({ message: t('popups.somethingWentWrong'), buttons: [{ label: t('common.ok'), onClick: () => { } }], closeOnEscape: false, closeOnClickOutside: false });
    }
  } catch (error) {
    if (error?.response?.status && error?.response?.status === 401) {
      // confirmAlert({ message: "Session expired!", buttons: [{ label: "Ok", onClick: () => {} }], closeOnEscape: false, closeOnClickOutside: false });
      confirmAlert({ message: t('popups.sessionExpired'), buttons: [{ label: t('common.ok'), onClick: () => { } }], closeOnEscape: false, closeOnClickOutside: false });
      router.push("/auth/signin");
    } else {
      alert(error);
    }
    if (LOGINSTATE) console.log(error);
  } finally {
    dispatch({ type: SHOW_LOADING, payload: false });
  }
};

// trans done
export const updateOrganization = (formData, needRefresh, history, t) => async (dispatch) => {
  if (LOGINSTATE) console.log("Updating the organization with: ", formData);

  try {
    dispatch({ type: SHOW_LOADING, payload: true });

    let fileIsReady = "";

    if (formData.organizationProfile && formData.cityId) {
      fileIsReady = await uploadOrganization(formData, alert, history);
    }

    if (!Lodash.isEmpty(fileIsReady)) {
      // data.success or not test
      if(t){
        confirmAlert({ message: t('popups.noAttachment'), buttons: [{ label: t('common.ok'), onClick: () => {} }], closeOnEscape: false, closeOnClickOutside: false });
      }
      else{
        confirmAlert({ message: "No attachment, or attachment failed to upload. Please try again later.", buttons: [{ label: "Ok", onClick: () => { } }], closeOnEscape: false, closeOnClickOutside: false });
      }
      return;
    }

    const { data } = await api.updateOrganization(formData);
    if (LOGINSTATE) console.log("update data: ", data);

    if (data?.status === "success") {
      dispatch({ type: ORG_UPDATE, payload: data });
      // window.location.reload(); //indirect page update can be done using the dispatched value
      if(t){
        confirmAlert({ message: t('common.success'), buttons: [{ label: t('common.ok'), onClick: () => {} }], closeOnEscape: false, closeOnClickOutside: false });
      }
      else{
        confirmAlert({ title: "Success", message: "Organization updated.", buttons: [{ label: "Ok", onClick: () => { } }], closeOnEscape: false, closeOnClickOutside: false });
      }
      if (needRefresh) history.go(0);
    }
    if (data?.status === "error") {
      if (LOGINSTATE) console.log(data);
      if (t) {
        confirmAlert({ message: t('popups.somethingWentWrong'), buttons: [{ label: t('common.ok'), onClick: () => { } }], closeOnEscape: false, closeOnClickOutside: false });
      }
      else {
        confirmAlert({ title: "Error", message: data?.message, buttons: [{ label: "Ok", onClick: () => { } }], closeOnEscape: false, closeOnClickOutside: false });
      }
    }
  } catch (error) {
    if (LOGINSTATE) console.log("catch error", error);
    if (error?.response?.status && error?.response?.status === 401) {
      if (t) {
        confirmAlert({ message: t('popups.sessionExpired'), buttons: [{ label: t('common.ok'), onClick: () => { } }], closeOnEscape: false, closeOnClickOutside: false });
      }
      else {
        confirmAlert({ message: "Session expired!", buttons: [{ label: "Ok", onClick: () => { } }], closeOnEscape: false, closeOnClickOutside: false });
      }
      history.push("/auth/signin");
    } else {
      if (t) {
        confirmAlert({ message: t('popups.somethingWentWrong'), buttons: [{ label: t('common.ok'), onClick: () => { } }], closeOnEscape: false, closeOnClickOutside: false });
      }
      else {
        confirmAlert({ title: "Error", message: "Oops, something went wrong. Please try again later", buttons: [{ label: "Ok", onClick: () => { } }], closeOnEscape: false, closeOnClickOutside: false });
      }
    }
  } finally {
    dispatch({ type: SHOW_LOADING, payload: false });
  }
};

// no need
// seems not using
const uploadOrganization = async (values, history) => {
  try {
    if (values.organizationProfile && values.cityId) {
      if (LOGINSTATE) console.log("organization data: ", values.organizationProfile);
      // continue
    } else {
      if (LOGINSTATE) console.log("empty organization");
      return false;
    }

    const params = new FormData();
    params.append("organization", values.organizationProfile);
    params.append("organization", values.cityId);
    if (LOGINSTATE) console.log("upload picture data", params);

    let res = await api.uploadOrganization(params);
    if (LOGINSTATE) console.log("server returned: ", res);

    if (res && res.data && res.data.status === "success" && !Lodash.isEmpty(res.data.data)) {
      if (LOGINSTATE) console.log("server returned new file name: ", res.data.data);
      values["organizationProfile"] = res.data.data.name;

      return true;
    } else {
      return false;
    }
  } catch (error) {
    if (LOGINSTATE) console.log("catch error in file upload: ", error);
    if (error?.response?.status && error?.response?.status === 401) {
      confirmAlert({ message: "Session expired!", buttons: [{ label: "Ok", onClick: () => { } }], closeOnEscape: false, closeOnClickOutside: false });
      history.push("/auth/signin");
    } else {
      let errorMessage = error?.response?.data?.message;
      confirmAlert({ message: errorMessage, buttons: [{ label: "Yes", onClick: () => { } }], closeOnEscape: false, closeOnClickOutside: false });
    }

    return false;
  } finally {
  }
};

// no need trans
export const updateOrganizationCityStatus = (formData, history, pathParam) => async (dispatch) => {
  if (LOGINSTATE) console.log("Updating the organization with: ", formData);

  try {
    dispatch({ type: SHOW_LOADING, payload: true });

    const { data } = await api.updateCityStatus(formData);
    if (LOGINSTATE) console.log("update data: ", data);

    if (data?.status === "success") {
      dispatch({ type: ORG_CITY_STATUS_UPDATE, payload: data });
      // window.location.reload(); //indirect page update can be done using the dispatched value
      confirmAlert({ title: "Success", message: "Organization updated.", buttons: [{ label: "Ok", onClick: () => { } }], closeOnEscape: false, closeOnClickOutside: false });
      if (pathParam && !Lodash.isEmpty(pathParam?.path)) {
        history.push({ pathname: pathParam.path, state: pathParam.state });
      } else if (pathParam && Lodash.isBoolean(pathParam?.reload)) {
        history.go(0);
      }
    }
    if (data?.status === "error") {
      if (LOGINSTATE) console.log(data);
      confirmAlert({ title: "Error", message: data?.message, buttons: [{ label: "Ok", onClick: () => { } }], closeOnEscape: false, closeOnClickOutside: false });
    }
  } catch (error) {
    if (LOGINSTATE) console.log("catch error", error);
    if (error?.response?.status && error?.response?.status === 401) {
      confirmAlert({ message: "Session expired!", buttons: [{ label: "Ok", onClick: () => { } }], closeOnEscape: false, closeOnClickOutside: false });
      history.push("/auth/signin");
    } else {
      confirmAlert({ title: "Error", message: "Oops, something went wrong. Please try again later", buttons: [{ label: "Ok", onClick: () => { } }], closeOnEscape: false, closeOnClickOutside: false });
    }
  } finally {
    dispatch({ type: SHOW_LOADING, payload: false });
  }
};
