import React from "react";
import { Container } from "reactstrap"; // reactstrap components

const PageHeader = () => {
  return (
    <>
      <div className="header pb-8 pt-3 pt-md-6"
        style={{
          backgroundColor: '#af1e23',
          // backgroundImage: "url(" + require("../../assets/img/theme/img_bg.jpg").default + ")",
          // backgroundSize: "cover",
          // backgroundPosition: "center top",
        }}
      >

        {/* Mask */}
        {/* <span className="mask bg-gradient-default opacity-5" /> */}

        <Container fluid style={{ minHeight: 100 }}>
        </Container>

      </div>
    </>
  );
};

export default PageHeader;
