import * as WebDataRocksReact from "assets/js/webdatarocks.react";
import PageHeader from "components/Headers/PageHeader.js";
import { LOGINSTATE } from "../../../config/config.json";
// reactstrap components
import {
  Card,
  CardBody,
  CardFooter,
  CardHeader,
  Col,
  Container,
  Row
} from "reactstrap";
import React, { useEffect, useState, useRef } from "react";
import { useDispatch } from 'react-redux';
import Lodash from 'lodash';
import * as api from 'api/index';
import Moment from "moment";

const initReportConfiguration = {
  "dataSource": {
    "data": []
  },
  "slice": {
    "drills": {
      "drillAll": false
    }
  },
}

const Report = (props) => {

  // For webdatarock values
  const reportRef = useRef();
  const dispatch = useDispatch();

  const [reportConfiguration, setReportConfiguration] = useState(null);


  useEffect(() => {
    fetchReportData();
  }, []);

  const fetchReportData = async () => {
    const params = { statType: "AssessmentOverall" };
    const { data } = await api.fetchAnswerStat(params);
    if (LOGINSTATE) console.log("pivot data: ", data);

    if (data && data.data && data.data.length > 0) {
      let reportConfig = initReportConfiguration;

      let reportData = data.data.map(({ assessment, version, title, country, date, organization_type, status }) => {
        const date_time = Moment.utc(date).format("YYYY-MM-DD hh:mm:ss A")
        return { assessment, version, title, country, date_time, organization_type, status }
      })
      if (LOGINSTATE) console.log("reportData: ", reportData);

      // reportConfig["dataSource"]["data"] = reportData;
      reportConfig["dataSource"]["data"] = [
        {
          assessment: { type: "string" },
          version: { type: "number"},
          title: { type: "string" },
          country: { type: "string" },
          date_time: { type: "string" },
          organization_type: { type: "string" },
          status: { type: "string" }
        },
        ...reportData
      ];


      reportConfig["slice"] = {
        "rows": [
          { "uniqueName": "country", "sort": "asc" }
        ],
        "columns": [
          { "uniqueName": "status", "sort": "asc" }
        ],
        "measures": [
          { "uniqueName": "assessment", "aggregation": "count" }
        ],
        "drills": { "drillAll": false }
      }
      setReportConfiguration(reportConfig);
    }
  }

  const reportComplete = () => {
    if (reportRef.current && reportRef.current.webdatarocks) {
      if (LOGINSTATE) console.log("report: ", reportRef.current.webdatarocks.getReport());
    }
  }

  const customizeToolbar = (toolbar) => {
    let tabs = toolbar.getTabs(); // get all tabs from the toolbar
    toolbar.getTabs = function () {
      delete tabs[0]; // delete the first tab
      delete tabs[1];
      delete tabs[2];
      delete tabs[4];
      delete tabs[6];
      return tabs;
    }
  }

  return (
    <>
      <PageHeader />

      {/* Page content */}
      <Container className="mt--7" fluid>

        {/* create survey questions */}
        <Row className="mt-5">
          <Col>

            <Card className="shadow">

              {/* survey header */}
              <CardHeader className="border-0">
                <div className="row">
                  <div className="mb-xl-0 col-10">
                    <h3 className="mb-0">Summary By Country</h3>
                    <h6 className="mb-0">Overall assessment report</h6>
                  </div>
                </div>
              </CardHeader>

              {/* report content */}
              <CardBody style={{}}>

                {reportConfiguration && !Lodash.isEmpty(reportConfiguration) ?
                  <>
                    <WebDataRocksReact.Pivot
                      ref={reportRef}
                      toolbar={true}
                      beforetoolbarcreated={customizeToolbar}
                      report={reportConfiguration}
                      reportcomplete={reportComplete}
                    />
                  </>
                  : null
                }

              </CardBody>

              {/* survey footer */}
              <CardFooter className="py-4">

              </CardFooter>

            </Card>

          </Col>
        </Row>

      </Container>

    </>
  );
}


export default Report;
