import {
  RESOURCE_CREATE,
  RESOURCE_UPDATE,
  RESOURCE_FETCH_ALL,
  RESOURCE_DELETE,
  SHOW_LOADING
} from 'constants/actionTypes';
import { LOGINSTATE } from "config/config.json";
import * as api from '../api/index.js';
import Lodash from "lodash";
import { confirmAlert } from 'react-confirm-alert';
import 'react-confirm-alert/src/react-confirm-alert.css'; // Import css


export const fetchResourceAll = () => async (dispatch) => {
  try {
    dispatch({ type: SHOW_LOADING, payload: true });
    
    const { data } = await api.fetchResources();
    if (LOGINSTATE) console.log("resource data", data);
    if (data?.status === "success") {
      dispatch({ type: RESOURCE_FETCH_ALL, payload: data?.data });
    } else if (data?.status === "error") {
      if (LOGINSTATE) console.log(data);
      confirmAlert({ message: "Oops!, something went wrong. Please try again.", buttons: [{ label: "Ok", onClick: () => {} }], closeOnEscape: false, closeOnClickOutside: false, closeOnEscape: false, closeOnClickOutside: false });
    }
  } catch (error) {
    if (LOGINSTATE) console.log(error);
  } finally {
    dispatch({ type: SHOW_LOADING, payload: false });
  }
};

export const createResource = (formData, history) => async (dispatch) => {
  try {
    dispatch({ type: SHOW_LOADING, payload: true });
    let fileIsReady = false;
    let updatePicResult = false;
    let updatePicResultLogo = false;
    let updatePicResultJSON = false;
    let PrimerorLogo = false;

    if (formData.Primer) {
      PrimerorLogo = true;
      updatePicResult = await uploadPrimer(formData, alert, history);

      // Profile picture update failed, stop the process;
      if (!updatePicResult) {
        confirmAlert({ message: "Profile picture upload failed. Please try again later.", buttons: [{ label: "Ok", onClick: () => {} }], closeOnEscape: false, closeOnClickOutside: false });
        return;
      }
    } else {
      if (LOGINSTATE) console.log("no new profile picture, continue with existing");
    }

    if (formData.Logo) {
      PrimerorLogo = true;
      updatePicResultLogo = await uploadLogo(formData, alert, history);

      // Profile picture update failed, stop the process;
      if (!updatePicResultLogo) {
        confirmAlert({ message: "Profile picture upload failed. Please try again later.", buttons: [{ label: "Ok", onClick: () => {} }], closeOnEscape: false, closeOnClickOutside: false });
        return;
      }
    } else {
      if (LOGINSTATE) console.log("no new profile picture, continue with existing");
    }

    if (formData.json) {
      PrimerorLogo = true;
      updatePicResultJSON = await uploadJSON(formData, alert, history);

      // Profile picture update failed, stop the process;
      if (!updatePicResultJSON) {
        confirmAlert({ message: "Profile picture upload failed. Please try again later.", buttons: [{ label: "Ok", onClick: () => {} }], closeOnEscape: false, closeOnClickOutside: false });
        return;
      }
    } else {
      if (LOGINSTATE) console.log("no new profile picture, continue with existing");
    }

    if (PrimerorLogo === false) {
      if (formData.file) {
        fileIsReady = await uploadResource(formData, alert, history);
      }

      if (!fileIsReady) {
        confirmAlert({ message: "No attachment, or attachment failed to upload. Please try again later.", buttons: [{ label: "Ok", onClick: () => {} }], closeOnEscape: false, closeOnClickOutside: false });
        return;
      }
    }

    const { data } = await api.createResource(formData);
    if (LOGINSTATE) console.log("resource data", data);
    if (data?.status === "success") {
      confirmAlert({
        message: "Resource is saved.",
        buttons: [
          {
            label: "Ok",
            onClick: () => {
              history.goBack();
            },
          },
        ],
      });
    } else if (data?.status === "error") {
      if (LOGINSTATE) console.log(data);
      confirmAlert({
        message: "Resource couldn't save.",
        buttons: [
          {
            label: "Ok",
            onClick: () => {
              history.goBack();
            },
          },
        ],
      });
    }
  } catch (error) {
    if (LOGINSTATE) console.log(error);
    if (error?.response?.status && error?.response?.status === 401) {
      confirmAlert({ message: "Session expired!", buttons: [{ label: "Ok", onClick: () => {} }], closeOnEscape: false, closeOnClickOutside: false });
      history.push("/auth/signin");
    } else {
      confirmAlert({
        message: "Resource couldn't save.",
        buttons: [
          {
            label: "Ok",
            onClick: () => {
              history.goBack();
            },
          },
        ],
      });
    }
  } finally {
    dispatch({ type: SHOW_LOADING, payload: false });
  }
};

const uploadResource = async (values, history) => {
  try {
    if (values.file) {
      if (LOGINSTATE) console.log("resource data: ", values.file);
      // continue
    } else {
      if (LOGINSTATE) console.log("empty resource");
      return false;
    }

    if (LOGINSTATE) console.log("upload picture data");
    const params = new FormData();
    params.append("resource", values.file);

    let res = await api.uploadResource(params);
    if (LOGINSTATE) console.log("server returned: ", res);

    if (res && res.data && res.data.status === "success" && !Lodash.isEmpty(res.data.data)) {
      if (LOGINSTATE) console.log("server returned new file name: ", res.data.data);
      values["file"] = res.data.data.name;

      return true;
    } else {
      return false;
    }
  } catch (error) {
    if (LOGINSTATE) console.log("catch error in file upload: ", error);
    if (error?.response?.status && error?.response?.status === 401) {
      confirmAlert({ message: "Session expired!", buttons: [{ label: "Ok", onClick: () => {} }], closeOnEscape: false, closeOnClickOutside: false });
      history.push("/auth/signin");
    } else {
      let errorMessage = error?.response?.data?.message;
      confirmAlert({ message: errorMessage, buttons: [{ label: "Yes", onClick: () => {} }], closeOnEscape: false, closeOnClickOutside: false });
    }

    return false;
  } finally {
    // dispatch({ type: SHOW_LOADING, payload: false });
  }
};

export const updateResource = (formData, router) => async (dispatch) => {
  try {
    dispatch({ type: SHOW_LOADING, payload: true });

    if (formData.Primer) {
      let updatePicResult = await uploadPrimer(formData, alert, router);

      // Profile picture update failed, stop the process;
      if (!updatePicResult) {
        confirmAlert({ message: "Profile picture upload failed. Please try again later.", buttons: [{ label: "Ok", onClick: () => {} }], closeOnEscape: false, closeOnClickOutside: false });
        return;
      }
    } else {
      if (LOGINSTATE) console.log("no new profile picture, continue with existing");
    }

    if (formData.Logo) {
      let updatePicResult = await uploadLogo(formData, alert, router);

      // Profile picture update failed, stop the process;
      if (!updatePicResult) {
        confirmAlert({ message: "Profile picture upload failed. Please try again later.", buttons: [{ label: "Ok", onClick: () => {} }], closeOnEscape: false, closeOnClickOutside: false });
        return;
      }
    } else {
      if (LOGINSTATE) console.log("no new profile picture, continue with existing");
    }

    const response = await api.updateResource(formData);
    const { data } = response;
    if (LOGINSTATE) console.log("update resources: ", data);
    if (data?.status === "success") {
      dispatch({ type: RESOURCE_UPDATE, payload: dispatch });
      confirmAlert({
        message: "Resource updated.",
        buttons: [
          {
            label: "Ok",
            onClick: () => {
              window.location.reload(true);
            },
          },
        ],
      });
    }
    if (data?.status === "error") {
      if (LOGINSTATE) console.log(data);
      confirmAlert({ message: "Oops!, something went wrong. Please try again.", buttons: [{ label: "Ok", onClick: () => {} }], closeOnEscape: false, closeOnClickOutside: false });
    }
  } catch (error) {
    if (error?.response?.status && error?.response?.status === 401) {
      confirmAlert({ message: "Session expired!", buttons: [{ label: "Ok", onClick: () => {} }], closeOnEscape: false, closeOnClickOutside: false });
      router.push("/auth/signin");
    } else {
      let errorMessage = error?.response?.data?.message;
      confirmAlert({ message: errorMessage, buttons: [{ label: "Yes", onClick: () => {} }], closeOnEscape: false, closeOnClickOutside: false });
    }
    if (LOGINSTATE) console.log(error);

    // alert(error);
  } finally {
    dispatch({ type: SHOW_LOADING, payload: false });
  }
};

const uploadPrimer = async (values, alert, router) => {
  try {
    if (values.Primer) {
      console.log("picture data: ", values.Primer);
      // continue
    } else {
      console.log("empty picture");

      return false;
    }

    if (LOGINSTATE) console.log("upload picture data");
    const params = new FormData();
    params.append("Primer", values.Primer);
    // params.append("photo[]", values.profileThumbnail);

    let res = await api.uploadPrimer(params);
    console.log("server returned: ", res);

    if (res && res.data && res.data.status === "success" && !Lodash.isEmpty(res.data.data)) {
      console.log("server returned new file names: ", res.data.data);
      values["Primer"] = res.data.data.name;
      // values["profileThumbnail"] = res.data.data[1].name;
    }

    return true;
  } catch (error) {
    // console.log("catch error in picture upload: ", error?.response?.data);
    if (error?.response?.status && error?.response?.status === 401) {
      confirmAlert({ message: "Session expired!", buttons: [{ label: "Ok", onClick: () => {} }], closeOnEscape: false, closeOnClickOutside: false });
      router.push("/auth/signin");
    } else {
      let errorMessge = error?.response?.data?.message;
      confirmAlert({ message: errorMessge, buttons: [{ label: "Yes", onClick: () => {} }], closeOnEscape: false, closeOnClickOutside: false });
      values.Primer = null;
      // values.profileThumbnail = null;
    }
    return false;
  }
};

const uploadLogo = async (values, alert, router) => {
  try {
    if (values.Logo) {
      if (LOGINSTATE) console.log("picture data: ", values.Logo);
      // continue
    } else {
      if (LOGINSTATE) console.log("empty picture");

      return false;
    }

    if (LOGINSTATE) console.log("upload picture data");
    const params = new FormData();
    params.append("Logo", values.Logo);
    // params.append("photo[]", values.profileThumbnail);

    let res = await api.uploadLogo(params);
    console.log("server returned: ", res);

    if (res && res.data && res.data.status === "success" && !Lodash.isEmpty(res.data.data)) {
      if (LOGINSTATE) console.log("server returned new file names: ", res.data.data);
      values["Logo"] = res.data.data.name;
      // values["profileThumbnail"] = res.data.data[1].name;
    }

    return true;
  } catch (error) {
    // console.log("catch error in picture upload: ", error?.response?.data);
    if (error?.response?.status && error?.response?.status === 401) {
      confirmAlert({ message: "Session expired!", buttons: [{ label: "Ok", onClick: () => {} }], closeOnEscape: false, closeOnClickOutside: false });
      router.push("/auth/signin");
    } else {
      let errorMessge = error?.response?.data?.message;
      confirmAlert({ message: errorMessge, buttons: [{ label: "Yes", onClick: () => {} }], closeOnEscape: false, closeOnClickOutside: false });
      values.profilePicture = null;
      values.profileThumbnail = null;
    }
    return false;
  }
};

const uploadJSON = async (values, alert, router) => {
  try {
    if (values.json) {
      if (LOGINSTATE) console.log("picture data: ", values.json);
      // continue
    } else {
      if (LOGINSTATE) console.log("empty picture");

      return false;
    }

    if (LOGINSTATE) console.log("upload picture data");
    const params = new FormData();
    params.append("json", values.json);
    // params.append("photo[]", values.profileThumbnail);

    let res = await api.uploadJSON(params);
    console.log("server returned: ", res);

    if (res && res.data && res.data.status === "success" && !Lodash.isEmpty(res.data.data)) {
      if (LOGINSTATE) console.log("server returned new file names: ", res.data.data);
      values["json"] = res.data.data.name;
      // values["profileThumbnail"] = res.data.data[1].name;
    }

    return true;
  } catch (error) {
    // console.log("catch error in picture upload: ", error?.response?.data);
    if (error?.response?.status && error?.response?.status === 401) {
      confirmAlert({ message: "Session expired!", buttons: [{ label: "Ok", onClick: () => {} }], closeOnEscape: false, closeOnClickOutside: false });
      router.push("/auth/signin");
    } else {
      let errorMessge = error?.response?.data?.message;
      confirmAlert({ message: errorMessge, buttons: [{ label: "Yes", onClick: () => {} }], closeOnEscape: false, closeOnClickOutside: false });
      values.profilePicture = null;
      values.profileThumbnail = null;
    }
    return false;
  }
};

export const deleteResource = (formData, router) => async (dispatch) => {
  if (LOGINSTATE) console.log("formdata", formData);
  try {
    dispatch({ type: SHOW_LOADING, payload: true });
    const response = await api.deleteResource(formData);
    const { data } = response;
    if (LOGINSTATE) console.log("update resources: ", data);
    if (data?.status === "success") {
      dispatch({ type: RESOURCE_DELETE, payload: data });
      confirmAlert({
        message: "Resource deleted.",
        buttons: [
          {
            label: "Ok",
            onClick: () => {
              // window.location.reload(true);
            },
          },
        ],
      });
    }
    if (data?.status === "error") {
      if (LOGINSTATE) console.log(data);
      confirmAlert({ message: "Oops!, something went wrong. Please try again.", buttons: [{ label: "Ok", onClick: () => {} }], closeOnEscape: false, closeOnClickOutside: false });
    }
  } catch (error) {
    if (error?.response?.status && error?.response?.status === 401) {
      confirmAlert({ message: "Session expired!", buttons: [{ label: "Ok", onClick: () => {} }], closeOnEscape: false, closeOnClickOutside: false });
      router.push("/auth/signin");
    } else {
      let errorMessage = error?.response?.data?.message;
      confirmAlert({ message: errorMessage, buttons: [{ label: "Yes", onClick: () => {} }], closeOnEscape: false, closeOnClickOutside: false });
    }
    if (LOGINSTATE) console.log(error);

    // alert(error);
  } finally {
    dispatch({ type: SHOW_LOADING, payload: false });
  }
};
