import { createRecommendation, updateRecommendationById } from "actions/survey";
import { RECOMMENDATION_LOCAL } from "constants/actionTypes";
// core components
import PageHeader from "components/Headers/PageHeader.js";
import { LOGINSTATE } from "../../../config/config.json";
import Lodash from "lodash";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useHistory, useLocation } from "react-router-dom";
// reactstrap components
import { Badge, Button, Card, CardHeader, Container, CardFooter, Row, Col, Table } from "reactstrap";
import { GlobalStylesColor } from "constants/globalStyles";
import { confirmAlert } from "react-confirm-alert";
import "react-confirm-alert/src/react-confirm-alert.css"; // Import css
import { Tooltip } from "@material-ui/core";
import { ChsStyles } from "constants/globalStyles";
import { processArray } from "helper";

// Populate version row for each survey type
const RecommendationRow = ({ type, segmentId, position, recommendation, editClick, moveUpClick, moveDownClick, deleteClick }) => (
  // SCORE RANGE | RECOMMENDATIONS
  <tr>
    <td style={{ whiteSpace: "break-spaces" }}>
      {recommendation.from} - {recommendation.to}
    </td>
    <td style={{ whiteSpace: "break-spaces", color: GlobalStylesColor.mbppThemeBlue }}>{recommendation.recommendation ? recommendation.recommendation : recommendation.note}</td>
    <td>
      <div className="row">
        <Tooltip title="Edit" arrow>
          <div
            className="navbar-toggler"
            style={{ cursor: "pointer" }}
            onClick={(e) => {
              editClick(e, type, segmentId, position, recommendation);
            }}
          >
            <i className="far fa-edit" style={{ color: GlobalStylesColor.mbppIconColor }} />
          </div>
        </Tooltip>
        <Tooltip title="Move up" arrow>
          <div
            className="navbar-toggler"
            style={{ cursor: "pointer" }}
            onClick={(e) => {
              moveUpClick(e, type, segmentId, position, recommendation);
            }}
          >
            <i className="far fa-arrow-alt-circle-up" style={{ color: GlobalStylesColor.mbppIconColor }} />
          </div>
        </Tooltip>
        <Tooltip title="Move down" arrow>
          <div
            className="navbar-toggler"
            style={{ cursor: "pointer" }}
            onClick={(e) => {
              moveDownClick(e, type, segmentId, position, recommendation);
            }}
          >
            <i className="far fa-arrow-alt-circle-down" style={{ color: GlobalStylesColor.mbppIconColor }} />
          </div>
        </Tooltip>
        <Tooltip title="Delete" arrow>
          <div
            className="navbar-toggler"
            style={{ cursor: "pointer" }}
            onClick={(e) => {
              confirmAlert({
                title: 'Confirm delete',
                buttons: [
                  { label: "No", onClick: () => { } },
                  {
                    label: "Yes", onClick: () => {
                      deleteClick(e, type, segmentId, position, recommendation);
                    }
                  },
                ],
                closeOnEscape: false,
                closeOnClickOutside: false
              });
            }}
          >
            <i className="far fa-times-circle" style={{ color: GlobalStylesColor.mbppIconColor }} />
          </div>
        </Tooltip>
      </div>
    </td>
  </tr>
);

// export function
const Recommendation = (props) => {
  // dispatch and history
  const dispatch = useDispatch();
  const history = useHistory();
  const location = useLocation();

  const initSurveyRecommendations = {
    languageCode: "en",
    type: "",
    title: "",
    description: "",
    overall: {
      recommendations: [],
      status: true,
    },
    segments: [],
    status: "DEFAULT",
    published: false,
  };
  const [surveyRecommendations, setSurveyRecommendations] = useState(initSurveyRecommendations); // Full object
  const [overallData, setOverallData] = useState(initSurveyRecommendations?.overall?.recommendations); // Overall details
  const [segmentData, setSegmentData] = useState(initSurveyRecommendations?.segments); // Segment details
  const [isNew, setIsNew] = useState(true);
  const [refreshToken, setRefreshToken] = useState(0);

  // 1. get the survey. create the recommendation structure
  // 2. getting the recommendations for survey per language if exist
  // 3. populate the structure and save locally
  // 4. update the structure if local is changed

  useEffect(() => {
    if (LOGINSTATE) console.log("---------------> called effect 1: page load");
    // create the recommendation structure from passed survey
    const survey = props.location.state?.survey;
    if (survey && !Lodash.isEmpty(survey)) {
      if (LOGINSTATE) console.log("--------- survey: ", survey);
      setSurveyRecommendations({
        languageCode: survey.languageCode,
        type: survey.type,
        title: survey.title,
        description: survey.description,
        overall: {
          recommendations: [],
          status: true,
        },
        segments: [],
        status: "DEFAULT",
        published: false,
      });
      setSegmentData(survey?.segments);
    }

    // populate the variables from locally save data if exist
    if (localStorage.getItem(RECOMMENDATION_LOCAL)) {
      let localRecommendations = JSON.parse(localStorage.getItem(RECOMMENDATION_LOCAL));
      if (LOGINSTATE) console.log("update is called with init local: ", localRecommendations);
      if (LOGINSTATE) console.log("---------------> 2");
      updateByLocalSaveRecommendations(localRecommendations);
    }

    return () => { };
  }, []);

  // get recommendations from server
  const currentRecommendations = useSelector((state) => state.survey.recommendationAll);
  useEffect(() => {
    if (LOGINSTATE) console.log("---------------> called effect 2: get from server");
    if (LOGINSTATE) console.log("currentRecommendations in server: ", currentRecommendations);
    const language = props.location.state?.language;
    if (LOGINSTATE) console.log("language: ", language);
    const localeRecommendation = currentRecommendations ? currentRecommendations[0] : null; // since the parent is passing the language code, this should be one element
    if (LOGINSTATE) console.log("server Recommendation: ", localeRecommendation);
    if (localeRecommendation && !Lodash.isEmpty(localeRecommendation)) {
      if (LOGINSTATE) console.log("set locale Recommendation: ", localeRecommendation);
      localStorage.setItem(RECOMMENDATION_LOCAL, JSON.stringify(localeRecommendation));
      updateByLocalSaveRecommendations(localeRecommendation);
      setIsNew(false);
    }
  }, [currentRecommendations]);

  // information getting from redux
  const recommendationSaved = useSelector((state) => state.survey.localRecommendationTemplate);
  useEffect(() => {
    if (LOGINSTATE) console.log("---------------> called effect 3: start from local dispatch");
    if (LOGINSTATE) console.log("dispatch surveySaved: ", recommendationSaved);

    if (recommendationSaved !== undefined && recommendationSaved !== null && !Lodash.isEmpty(recommendationSaved)) {
      localStorage.setItem(RECOMMENDATION_LOCAL, JSON.stringify(recommendationSaved));
      updateByLocalSaveRecommendations(recommendationSaved);
    }
  }, [recommendationSaved]);

  const updateByLocalSaveRecommendations = (localRecommendations) => {
    if (LOGINSTATE) console.log("0. Recommendation update is called with: ", localRecommendations);
    // if all overall or segments exist, override the passed survey data
    if (localRecommendations && !Lodash.isEmpty(localRecommendations) && (localRecommendations?.overall?.recommendations || localRecommendations?.segments)) {
      setSurveyRecommendations(Lodash.isEmpty(localRecommendations) ? initSurveyRecommendations : localRecommendations);
      setOverallData(localRecommendations?.overall?.recommendations ? localRecommendations?.overall?.recommendations : []);
      setSegmentData(localRecommendations?.segments ? localRecommendations?.segments : []);
    } else {
      if (LOGINSTATE) console.log("0. Recommendation update is ignored for: ", localRecommendations);
    }

    if (LOGINSTATE) console.log("1. surveyRecommendations", surveyRecommendations);
    if (LOGINSTATE) console.log("2. overallData", overallData);
    if (LOGINSTATE) console.log("3. segmentData", segmentData);
  };

  const handleMoveUpClick = async (e, type, segmentId, position, recommendation) => {
    e.preventDefault();

    if (type === "OVERALL") {
      let updatedRecommendations = await processArray(overallData, "MOVEDOWN", position, null);
      if (LOGINSTATE) console.log("updatedRecommendations: ", updatedRecommendations);
      if (!Lodash.isEmpty(updatedRecommendations)) {
        setOverallData(updatedRecommendations);
      }
    } else if (type === "SEGMENT") {
      let updatedRecommendations = segmentData;
      updatedRecommendations[segmentId]["recommendations"] = await processArray(updatedRecommendations[segmentId].recommendations, "MOVEDOWN", position, null);
      if (LOGINSTATE) console.log("updatedRecommendations: ", updatedRecommendations);
      if (!Lodash.isEmpty(updatedRecommendations)) {
        setSegmentData(updatedRecommendations);
      }
    }

    updateLocalRecommendations();
  };

  // handle Click event of the survey. Navigate to the localization page. There will be auto english version for every version
  const handleMoveDownClick = async (e, type, segmentId, position, recommendation) => {
    e.preventDefault();

    if (type === "OVERALL") {
      let updatedRecommendations = await processArray(overallData, "MOVEUP", position, null);
      if (LOGINSTATE) console.log("updatedRecommendations: ", updatedRecommendations);
      if (!Lodash.isEmpty(updatedRecommendations)) {
        setOverallData(updatedRecommendations);
      }
    } else if (type === "SEGMENT") {
      let updatedRecommendations = segmentData;
      if (LOGINSTATE) console.log("updatedRecommendations: ", updatedRecommendations);
      if (LOGINSTATE) console.log("segmentId: ", segmentId);
      if (LOGINSTATE) console.log("position: ", position);
      updatedRecommendations[segmentId]["recommendations"] = await processArray(updatedRecommendations[segmentId].recommendations, "MOVEUP", position, null);
      if (LOGINSTATE) console.log("updatedRecommendations: ", updatedRecommendations);
      if (!Lodash.isEmpty(updatedRecommendations)) {
        setSegmentData(updatedRecommendations);
      }
    }

    updateLocalRecommendations();
  };

  // create survey from existing survey
  const handleDeleteClick = async (e, type, segmentId, position, recommendation) => {
    e.preventDefault();

    if (type === "OVERALL") {
      let updatedRecommendations = await processArray(overallData, "DELETE", position, null);
      if (LOGINSTATE) console.log('updatedRecommendations: ', updatedRecommendations);
      if (!Lodash.isEmpty(updatedRecommendations)) {
        setOverallData(updatedRecommendations);
      }
    } else if (type === "SEGMENT") {
      let updatedRecommendations = segmentData;
      updatedRecommendations[segmentId]["recommendations"] = await processArray(updatedRecommendations[segmentId].recommendations, "DELETE", position, null);
      if (LOGINSTATE) console.log('updatedRecommendations: ', updatedRecommendations);
      if (!Lodash.isEmpty(updatedRecommendations)) {
        setSegmentData(updatedRecommendations);
      }
    }

    updateLocalRecommendations();
  };

  // create survey from existing survey
  const handleEditClick = (e, type, segmentId, position, recommendation) => {
    e.preventDefault();
    surveyRecommendations["overall"]["recommendations"] = overallData;
    surveyRecommendations["segments"] = segmentData;
    localStorage.setItem(RECOMMENDATION_LOCAL, JSON.stringify(surveyRecommendations));
    dispatch({ type: RECOMMENDATION_LOCAL, payload: surveyRecommendations });
    history.push({ pathname: "/registered/assessment_recommendation_details_edit", state: { type: type, segmentId: segmentId, position: position } });
  };

  // create empty survey
  const handleCreateNewClick = (e, type, segmentId, position) => {
    e.preventDefault();
    surveyRecommendations["overall"]["recommendations"] = overallData;
    surveyRecommendations["segments"] = segmentData;
    localStorage.setItem(RECOMMENDATION_LOCAL, JSON.stringify(surveyRecommendations));
    dispatch({ type: RECOMMENDATION_LOCAL, payload: surveyRecommendations });
    history.push({ pathname: "/registered/assessment_recommendation_details_edit", state: { type: type, segmentId: segmentId, position: position } });
  };

  const updateLocalRecommendations = () => {
    surveyRecommendations["overall"]["recommendations"] = overallData;
    surveyRecommendations["segments"] = segmentData;
    if (LOGINSTATE) console.log("surveyRecommendations: ", surveyRecommendations);
    setSurveyRecommendations(surveyRecommendations);
    localStorage.setItem(RECOMMENDATION_LOCAL, JSON.stringify(surveyRecommendations));
    dispatch({ type: RECOMMENDATION_LOCAL, payload: surveyRecommendations });
    setRefreshToken(refreshToken + 1);
  };

  const handleRecommendationSave = () => {
    if (LOGINSTATE) console.log("is new: ", isNew);
    if (isNew) {
      dispatch(createRecommendation(surveyRecommendations, history));
    } else {
      surveyRecommendations["recommendationId"] = surveyRecommendations._id;
      dispatch(updateRecommendationById(surveyRecommendations, history));
    }
  };

  return (
    <>
      <PageHeader />

      {/* Page content */}
      <Container className="mt--7" fluid>
        <Row className="mt-5">
          <div className="col">
            <Card className="shadow">
              <CardHeader className="border-0">
                <div className="row">
                  <div className="mb-xl-0 col-11">
                    <h3 className="mb-0">{surveyRecommendations.title ? surveyRecommendations.title : "No data to show"}</h3>
                    <h6 className="mb-0">Overall Recommendations</h6>
                  </div>
                  <div className="mb-xl-0">
                    <Tooltip title="Add new recommendation" arrow>
                      <div
                        className="navbar-toggler"
                        style={{ cursor: "pointer" }}
                        onClick={(e) => {
                          handleCreateNewClick(e, "OVERALL", "", -1);
                        }}
                      >
                        <i className="fas fa-plus-circle" style={{ fontSize: 30, color: GlobalStylesColor.mbppThemeBlue }} />
                      </div>
                    </Tooltip>
                  </div>
                </div>
              </CardHeader>
              <Table className="align-items-center table-flush" responsive>
                <thead className="thead-light">
                  <tr>
                    <th scope="col">Score Range</th>
                    <th scope="col">Recommendations</th>
                    <th scope="col">Actions</th>
                  </tr>
                </thead>
                <tbody id={refreshToken}>
                  {overallData &&
                    overallData.map((recommendation, i) => {
                      return (
                        <RecommendationRow
                          key={i}
                          type={"OVERALL"}
                          segmentId={null}
                          position={i}
                          recommendation={recommendation}
                          editClick={handleEditClick}
                          moveUpClick={handleMoveUpClick}
                          moveDownClick={handleMoveDownClick}
                          deleteClick={handleDeleteClick}
                        />
                      );
                    })}
                </tbody>
              </Table>
            </Card>
          </div>
        </Row>

        {segmentData &&
          segmentData.length > 0 &&
          segmentData.map((segment, i) => {
            return (
              <Row key={i} className="mt-5">
                <div className="col">
                  <Card className="shadow">
                    <CardHeader className="border-0">
                      <div className="row">
                        <div className="mb-xl-0 col-11">
                          <h3 className="mb-0">{segment.title}</h3>
                          <h6 className="mb-0">Sector Recommendations</h6>
                        </div>
                        <div className="mb-xl-0">
                          <Tooltip title="Add new recommendation" arrow>
                            <div
                              className="navbar-toggler"
                              style={{ cursor: "pointer" }}
                              onClick={(e) => {
                                handleCreateNewClick(e, "SEGMENT", i, -1);
                              }}
                            >
                              <i className="fas fa-plus-circle" style={{ fontSize: 30, color: GlobalStylesColor.mbppThemeBlue }} />
                            </div>
                          </Tooltip>
                        </div>
                      </div>
                    </CardHeader>
                    <Table className="align-items-center table-flush" responsive>
                      <thead className="thead-light">
                        <tr>
                          <th scope="col">Score Range</th>
                          <th scope="col">Recommendations</th>
                          <th scope="col">Actions</th>
                        </tr>
                      </thead>
                      <tbody>
                        {segment?.recommendations &&
                          (segment?.recommendations).map((recommendation, j) => {
                            return (
                              <RecommendationRow
                                key={j}
                                type={"SEGMENT"}
                                segmentId={i}
                                position={j}
                                recommendation={recommendation}
                                editClick={handleEditClick}
                                moveUpClick={handleMoveUpClick}
                                moveDownClick={handleMoveDownClick}
                                deleteClick={handleDeleteClick}
                              />
                            );
                          })}
                      </tbody>
                    </Table>
                  </Card>
                </div>
              </Row>
            );
          })}

        {/* Recommendation footer */}
        <Card className="shadow">
          <CardFooter className="py-4">
            <Row id={refreshToken}>
              <Col lg="6" className="text-left">
                <Button
                  style={ChsStyles.GeneralButton}
                  color="primary"
                  onClick={() => {
                    localStorage.setItem(RECOMMENDATION_LOCAL, JSON.stringify({}));
                    window.history.back();
                  }}
                >
                  BACK
                </Button>

                <Button
                  style={ChsStyles.GeneralButton}
                  color="primary"
                  onClick={() => {
                    handleRecommendationSave();
                  }}
                >
                  SAVE
                </Button>
              </Col>
              <Col lg="6" className="text-center"></Col>
            </Row>
          </CardFooter>
        </Card>
      </Container>
    </>
  );
};

export default Recommendation;
