import {
  STAT_SUMMERY_CON_MON_FETCH,
  STAT_SUMMERY_CON_WEK_FETCH,
  STAT_SUMMERY_REP_FETCH,
  STAT_SUMMERY_TOTAL,
  SHOW_LOADING,
} from 'constants/actionTypes';

const initState = {
  statSumTotal: [],
  StatSumConMon: [],
  StatSumConWek: [],
  StatSumRpt: [],
  showLoading: false,
}

const dashboardReducer = (dashboard = initState, action) => {
  switch (action.type) {
    // stat summery 2
    case STAT_SUMMERY_TOTAL:
      return { ...dashboard, statSumTotal: action.payload };
    case STAT_SUMMERY_CON_MON_FETCH:
      return { ...dashboard, StatSumConMon: action.payload };
    case STAT_SUMMERY_CON_WEK_FETCH:
      return { ...dashboard, StatSumConWek: action.payload };
    case STAT_SUMMERY_REP_FETCH:
      return { ...dashboard, StatSumRpt: action.payload };

    case SHOW_LOADING:
      return { ...dashboard, showLoading: action.payload };

    default:
      return dashboard;
  }
};

export default dashboardReducer;