import { fetchSurveyAnswerByUser } from "actions/survey";
import { fetchMasterDataLanguage } from "actions/masterData";

// core components
import CommonHeader from "components/Headers/CommonHeader";
import Config from "config/config.json";
import { LOGINSTATE } from "../../../config/config.json";
import Lodash from "lodash";
import Moment from "moment";

import React, { useEffect, useState } from "react";

import { useDispatch, useSelector } from "react-redux";
import { useHistory, useLocation } from "react-router-dom";
import { Tooltip } from "@material-ui/core";
import TimelineOutlinedIcon from "@material-ui/icons/TimelineOutlined";
// reactstrap components
import { Card, CardHeader, Container, Row, Table, CardFooter, Col } from "reactstrap";
import { GlobalStylesColor } from "constants/globalStyles";

import "react-confirm-alert/src/react-confirm-alert.css"; // Import css
import { answer_status } from "helper.js";
import { roundValue } from "helper";

import { useTranslation, initReactI18next, Trans } from "react-i18next";
import Pagination from "react-responsive-pagination";

// Populate version row for each survey type
const AnswerRow = ({ answer, editClick, statusClick, printClick, graphClick, t }) => (
  // VERSION | TITLE | DESCRIPTION | STATUS
  <tr>
    {/* {console.log("survey answer: ", answer)} */}
    <td style={{ whiteSpace: "break-spaces" }}>
      {/* {Moment(answer.updatedAt).format("YYYY-MM-DD hh:mm ")}
      {t("dateAndTime." + Moment(answer.updatedAt).format("A"))} */}
      {answer.clientTimestamp}
    </td>
    <td style={{ whiteSpace: "break-spaces", color: GlobalStylesColor.mbppThemeBlue }}>{t(answer.type === 'CHSAT' ? 'barChartLabels.chsat' : answer.type === 'ICAT' ? 'barChartLabels.icat' : answer.type === 'TIE' ? 'barChartLabels.tie' : answer.type)}</td>
    <td style={{ whiteSpace: "break-spaces" }}>{answer.title}</td>
    {answer?.status === answer_status[0] ? <td></td> : <td style={{ whiteSpace: "break-spaces" }}>{answer.surveyScore > 0 ? Math.round((answer.surveyScore + Number.EPSILON) * 100) / 100 : ""}</td>}

    <td className="text-right">
      <div className="row">
        {answer.status === answer_status[0] ? (
          <div
            className="navbar-toggler"
            style={{ cursor: "pointer" }}
            onClick={(e) => {
              editClick(e, answer);
            }}
          >
            <Tooltip title={t("answerHistory.inprogress.edit")}>
              <i className="far fa-edit" style={{ color: GlobalStylesColor.mbppIconColor }} />
            </Tooltip>
          </div>
        ) : (
          <>
            <div
              className="navbar-toggler"
              style={{ cursor: "pointer" }}
              onClick={(e) => {
                statusClick(e, answer);
              }}
            >
              {/* tooltip: edit */}
              <Tooltip title={t("answerHistory.complete.view")}>
                <i className="far fa-eye" style={{ color: GlobalStylesColor.mbppIconColor }} />
              </Tooltip>
            </div>

            <div
              className="navbar-toggler"
              style={{ cursor: "pointer" }}
              onClick={(e) => {
                graphClick(e, answer);
              }}
            >
              <Tooltip title={t("answerHistory.complete.score")}>
                <TimelineOutlinedIcon />
              </Tooltip>
            </div>

            <div
              className="navbar-toggler"
              style={{ cursor: "pointer" }}
              onClick={(e) => {
                printClick(e, answer, false);
              }}
            >
              <Tooltip title={t("answerHistory.complete.print")}>
                <i className="fas fa-print" style={{ color: GlobalStylesColor.mbppIconColor }} />
              </Tooltip>
            </div>
          </>
        )}
      </div>
    </td>
  </tr>
);

// export function
const Survey = () => {
  // dispatch and history
  const dispatch = useDispatch();
  const history = useHistory();
  const location = useLocation();

  // get forum topics
  const answerByUser = useSelector((state) => state.survey.answerByUser);

  const [rtlAlignment, setRtlAlignment] = useState("");
  const [progressTable, setProgressTable] = useState([]);
  const [itemPageCount, setItemPageCount] = useState(1);
  const [itemCurrent, setItemCurrent] = useState(1);

  const { t, i18n } = useTranslation();

  const userLanguage = useSelector((state) => state.masterData.languageByCode);
  console.log("userLanguage", userLanguage);
  useEffect(() => {
    // fetching all surveys
    dispatch(fetchSurveyAnswerByUser(null, history, t));
    i18n.changeLanguage(jUser?.user?.language);
    let params = {
      user: {
        code: jUser?.user?.language,
      },
    };
    dispatch(fetchMasterDataLanguage(params.user));
  }, []);

  useEffect(() => {
    userLanguage &&
      userLanguage.language &&
      Object.values(userLanguage.language).map((lang, i) => {
        if (lang && lang.dir === "rtl") {
          setRtlAlignment("right");
        }
      });
  }, [userLanguage]);

  useEffect(() => {
    let progress = Lodash.filter(answerByUser, { status: answer_status[0] });
    setProgressTable(progress);
    console.log("progress", progress);
  }, [answerByUser]);

  useEffect(() => {
    // Set the pagination count
    let currentPageCount = Math.ceil(progressTable.length / 2);
    setItemPageCount(currentPageCount);
  }, [itemCurrent, progressTable]);

  const handleStatusClick = (e, answer) => {
    e.preventDefault();
    if (LOGINSTATE) console.log("--------------------- local store saving: ", answer);
    history.push({ pathname: "/registered/assessment_answer_complete", state: { answer: answer, language: jUser?.user?.language, user: jUser?.user?._id } });
  };

  // create survey from existing survey
  const handleEditClick = (e, answer) => {
    e.preventDefault();
    if (LOGINSTATE) console.log("--------------------- local store saving: ", answer);
    history.push({ pathname: "/registered/assessment_answer_continue", state: { answer: answer, language: jUser?.user?.language } });
  };

  // answer a new survey
  const handleCreateClick = (e) => {
    e.preventDefault();
    history.push({ pathname: "/registered/assessment_answer_institute" });
  };

  const handlePrint = (e, answer, windowPrint) => {
    let segScore = [];
    let segName = [];
    let segLongName = [];

    Object.values(answer.segments).map((segment, i) => segScore.push(roundValue(segment.segmentScore)));

    Object.values(answer.segments).map((segmentName, j) => segName.push(segmentName.segmentCode));

    Object.values(answer.segments).map((segmentLongName, j) => segLongName.push(segmentLongName.title));

    // setSegLongName(segLongNameTemp);
    if (LOGINSTATE) console.log("segLongName", segLongName);

    e.preventDefault();
    if (LOGINSTATE) console.log("--------------------- local store saving: ", answer);
    console.log('### print-preview-details: ', {
      segName: segName,
      segScore: segScore,
      segLongName: segLongName,
      answer: answer,
      language: jUser?.user?.language
    })
    if (windowPrint) {
      history.push({
        pathname: "/registered/assessment_print_PDF",
        state: {
          segName: segName,
          segScore: segScore,
          segLongName: segLongName,
          answer: answer,
          language: jUser?.user?.language
        }
      });
    } else {
      history.push({
        pathname: "/print_content/assessment_preview",
        state: {
          segName: segName,
          segScore: segScore,
          segLongName: segLongName,
          answer: answer,
          language: jUser?.user?.language
        }
      });
    }
  };

  const handleGraphClick = (e, answer) => {
    let segScore = [];
    let segName = [];
    let segLongName = [];

    Object.values(answer.segments).map((segment, i) => segScore.push(roundValue(segment.segmentScore)));

    Object.values(answer.segments).map((segmentName, j) => segName.push(segmentName.segmentCode));

    Object.values(answer.segments).map((segmentLongName, j) => segLongName.push(segmentLongName.title));

    // setSegLongName(segLongNameTemp);
    if (LOGINSTATE) console.log("segLongName", segLongName);

    e.preventDefault();
    if (LOGINSTATE) console.log("--------------------- local store saving: ", answer);
    history.push({ pathname: "/registered/assessment_answer_graph", state: { segName: segName, segScore: segScore, segLongName: segLongName, answer: answer } });
  };

  let currentUser = localStorage.getItem("userDetails");
  let jUser = JSON.parse(currentUser);
  let uRole = currentUser && jUser && jUser.user && jUser.user.urole ? jUser.user.urole : "GENERAL";
  if (LOGINSTATE) console.log("uRole", currentUser);

  return (
    <>
      <CommonHeader hType="takeAssessment" />

      {/* Page content */}
      <Container className="mt--7" fluid>
        <Row className="mt-5">
          <div className="col">
            <Card className="shadow">
              <CardHeader className="border-0" style={{ textAlign: rtlAlignment }}>
                <div className="row">
                  <div className="mb-xl-0 col-10">
                    <h3 className="mb-0">{t("answerHistory.inprogress.progress")}</h3>
                    <h6 className="mb-0">{t("answerHistory.inprogress.assessment")}</h6>
                  </div>
                  <Tooltip title={t("answerHistory.inprogress.newAssignment")}>
                    <div className="mb-xl-0 col-2 text-right">
                      <div
                        className="navbar-toggler"
                        style={{ cursor: "pointer" }}
                        onClick={(e) => {
                          handleCreateClick(e);
                        }}
                      >
                        <i className="fas fa-plus-circle" style={{ fontSize: 30, color: GlobalStylesColor.mbppThemeBlue }} />
                      </div>
                    </div>
                  </Tooltip>
                </div>
              </CardHeader>
              <Table className="align-items-center table-flush" responsive>
                <thead className="thead-light">
                  <tr>
                    <th scope="col">{t("answerHistory.inprogress.updatedOn")}</th>
                    <th scope="col">{t("answerHistory.inprogress.type")}</th>
                    <th scope="col">{t("answerHistory.inprogress.title")}</th>
                    <th scope="col"></th>
                    <th scope="col">{t("answerHistory.inprogress.action")}</th>
                  </tr>
                </thead>

                <tbody>
                  <tr></tr>
                  {Lodash.filter(answerByUser, { status: answer_status[0] }).map((answer, i) => {
                    return <AnswerRow key={i} answer={answer} editClick={handleEditClick} statusClick={handleStatusClick} createClick={handleCreateClick} t={t} />;
                  })}
                </tbody>
              </Table>
            </Card>
          </div>
        </Row>

        {!Lodash.isEmpty(answerByUser) && (
          <Row className="mt-5">
            <div className="col">
              <CardHeader className="border-0" style={{ textAlign: rtlAlignment }}>
                <div className="row">
                  <div className="mb-xl-0 col-10">
                    <h3 className="mb-0">{t("answerHistory.complete.completed")}</h3>
                    <h6 className="mb-0">{t("answerHistory.complete.assessment")}</h6>
                  </div>
                  <Tooltip title={t("answerHistory.inprogress.newAssignment")}>
                    <div className="mb-xl-0 col-2 text-right">
                      <div
                        className="navbar-toggler"
                        style={{ cursor: "pointer" }}
                        onClick={(e) => {
                          handleCreateClick(e);
                        }}
                      >
                        <i className="fas fa-plus-circle" style={{ fontSize: 30, color: GlobalStylesColor.mbppThemeBlue }} />
                      </div>
                    </div>
                  </Tooltip>
                </div>
              </CardHeader>

              {!Lodash.isEmpty(answerByUser) &&
                Config.TOOLS.map((tool, i) => {
                  return (
                    <>
                      <Card className="shadow">
                        <Table className="align-items-center table-flush" responsive>
                          <thead className="thead-light">
                            <tr>
                              <th scope="col">{t("answerHistory.complete.completedOn")}</th>
                              <th scope="col">{t("answerHistory.complete.type")}</th>
                              <th scope="col">{t("answerHistory.complete.title")}</th>
                              <th scope="col">{t("answerHistory.complete.score")}</th>
                              <th scope="col">{t("answerHistory.complete.action")}</th>
                            </tr>
                          </thead>
                          <tbody>
                            {Lodash.filter(answerByUser, { status: answer_status[1], type: tool.type }).map((answer, i) => {
                              return <AnswerRow key={i} answer={answer} editClick={handleEditClick} statusClick={handleStatusClick} createClick={handleCreateClick} printClick={handlePrint} graphClick={handleGraphClick} t={t} />;
                            })}
                          </tbody>
                        </Table>
                      </Card>
                      <br />
                    </>
                  );
                })}
            </div>
          </Row>
        )}
      </Container>
    </>
  );
};

export default Survey;
