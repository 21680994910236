import React, { useState, useEffect } from "react";
import { useFormik } from "formik";
import * as yup from "yup";
import { useDispatch, useSelector } from "react-redux";
import { useLocation, useHistory } from "react-router-dom";
import { ChsStyles } from "constants/globalStyles";

// reactstrap components
import { Card, CardHeader, CardBody, CardFooter, Container, FormGroup, Form, Button, Row, Col } from "reactstrap";

import { TextField, Grid } from "@material-ui/core";

import { fetchFrmQus, createFrmAns, updateFrmAns } from "actions/forum";

// core components
import PageHeader from "components/Headers/PageHeader.js";
import { LOGINSTATE } from "../../../config/config.json";

const validationSchemaAnswer = yup.object({
  answer: yup.string("Enter Answer text").required("Answer is required"),
  description: yup.string("Enter Answer description").min(3, "Description should be of minimum 3 characters length").max(2000, "Description should be of maximum 2000 characters length"),
  status: yup.boolean("Enter Answer Status"),
});

const ForumAnswerForm = (props) => {
  // dispatch and history
  const dispatch = useDispatch();
  const history = useHistory();
  // get Question details
  const selectFrmQuestion = (state) => {
    const itemArray = state.forum.frmAllQus?.filter((item) => {
      return item._id === props.location.state?.frmQuestionId;
    });
    if (itemArray && itemArray[0]) {
      return itemArray[0];
    }
  };
  const frmQuestion = useSelector(selectFrmQuestion);
  if(LOGINSTATE) console.log("props.location", props.location);
  // get Answer details
  const selectFrmAnswer = (frmQuestion) => {
    const itemArray =
      frmQuestion?.answer?.length > 0 &&
      frmQuestion?.answer?.filter((item) => {
        return item._id === props.location.state?.frmAnswerId;
      });
    if (itemArray && itemArray[0]) {
      return itemArray[0];
    }
  };
  const frmAnswer = selectFrmAnswer(frmQuestion);
  if(LOGINSTATE) console.log(props.location);
  if(LOGINSTATE) console.log(frmQuestion);
  if(LOGINSTATE) console.log(props.location.state?.frmAnswerId);
  if(LOGINSTATE) console.log(frmAnswer);
  useEffect(() => {
    // fetching forum Questions
    dispatch(fetchFrmQus());
  }, []);

  useEffect(() => {
    if (frmQuestion && frmQuestion._id) {
      formik.values.forumQuestionId = frmQuestion._id;
    }
    if (frmAnswer && frmAnswer._id) {
      formik.values.forumAnswerId = frmAnswer._id;
      formik.values.answer = frmAnswer.answer;
      formik.values.description = frmAnswer.description;
      formik.values.status = frmAnswer.status;
      // setImage(frmAnswer.image.length !== 0 ? frmAnswer.image : ['']);
    }
  }, [frmQuestion, frmAnswer]);

  // set state image list
  const [listImage, setImage] = useState([""]);

  // handle change event of the Image list
  const handleImageChange = (e, index) => {
    const list = [...listImage];
    let reader = new FileReader();
    formik.values.image[index] = e.target.files[0];
    reader.addEventListener("load", function (e) {
      list[index] = e.target.result;
    });
    reader.readAsDataURL(e.target.files[0]);
    setImage(list);
  };

  // handle click event of the Remove button of Image
  const handleRemoveImageClick = (index) => {
    const list = [...listImage];
    list.splice(index, 1);
    setImage(list);
  };

  // handle click event of the Add button of Image
  const handleAddImageClick = () => {
    setImage([...listImage, ""]);
  };

  const formik = useFormik({
    initialValues: {
      forumQuestionId: "",
      forumAnswerId: "",
      answer: "",
      image: [],
      description: "",
      status: true,
      redirect: "/registered/forum",
    },
    validationSchema: validationSchemaAnswer,
    onSubmit: (values, onSubmitProps) => {
      values.image = listImage.filter((el) => {
        return el !== null && el !== "";
      });
      if (values.forumQuestionId && values.forumAnswerId) {
        let pathParam = {
          path: "/registered/faq_questions",
          state: { frmTopicId: props.location.state?.frmTopicId, frmSubTopicId: props.location.state?.frmSubTopicId, forumQuestionId: props.location.state?.frmQuestionId },
        };
        let formData = { forumQuestionId: values.forumQuestionId, forumAnswerId: values.forumAnswerId, description: values.description, answer: values.answer };
        dispatch(updateFrmAns(formData, history, pathParam));
      } else {
        let pathParam = {
          path: "/registered/faq_questions",
          state: { frmTopicId: props.location.state?.frmTopicId, frmSubTopicId: props.location.state?.frmSubTopicId, forumQuestionId: props.location.state?.frmQuestionId },
        };
        let formData = { forumQuestionId: values.forumQuestionId, forumAnswerId: values.forumAnswerId, description: values.description, answer: values.answer };
        dispatch(createFrmAns(formData, history, pathParam));
      }
    },
    onReset: () => {
      setImage([""]);
    },
  });

  return (
    <>
      <PageHeader />
      {/* Page content */}
      <Container className="mt--7" fluid>
        {/* Table School */}
        <Row className="mt-5">
          <div className="col">
            <Card className="shadow">
              <CardHeader className="border-0">
                <h3 className="mb-0">Answer Details</h3>
              </CardHeader>
              <Form role="form" onSubmit={formik.handleSubmit} onReset={formik.handleReset}>
                <CardBody>
                  <FormGroup className="mb-3">
                    <Grid container spacing={1} alignItems="center" style={ChsStyles.Grid}>
                      <Grid item xs={1} sm={1} className="px-3 text-right"></Grid>
                      <Grid item xs={10} sm={10}>
                        <h2 dangerouslySetInnerHTML={{ __html: frmQuestion && frmQuestion?.question }}></h2>
                        <h3>{frmQuestion && frmQuestion?.description}</h3>
                      </Grid>
                    </Grid>
                    <Grid container spacing={1} alignItems="center" style={ChsStyles.Grid}>
                      <Grid item xs={1} sm={1} className="px-3 text-right">
                        <i className="fas fa-voicemail"></i>
                      </Grid>
                      <Grid item xs={10} sm={10}>
                        <TextField
                          fullWidth
                          id="answer"
                          name="answer"
                          label="Answer"
                          variant="outlined"
                          value={formik.values.answer}
                          onChange={formik.handleChange}
                          multiline
                          rows={8}
                          error={formik.touched.answer && Boolean(formik.errors.answer)}
                          helperText={formik.touched.answer && formik.errors.answer}
                        />
                      </Grid>
                    </Grid>
                    <Grid container spacing={1} alignItems="center" style={ChsStyles.Grid}>
                      <Grid item xs={1} sm={1} className="px-3 text-right">
                        <i className="fas fa-align-left" />
                      </Grid>
                      <Grid item xs={10} sm={10}>
                        <TextField
                          fullWidth
                          id="description"
                          name="description"
                          label="Description"
                          variant="outlined"
                          value={formik.values.description}
                          onChange={formik.handleChange}
                          multiline
                          rows={8}
                          error={formik.touched.description && Boolean(formik.errors.description)}
                          helperText={formik.touched.description && formik.errors.description}
                        />
                      </Grid>
                    </Grid>
                  </FormGroup>
                </CardBody>

                <CardFooter className="py-4">
                  <Row>
                    <Col lg="6" className="text-left">
                      <Button
                        style={ChsStyles.GeneralButton}
                        color="primary"
                        onClick={() => {
                          history.push({pathname: "/registered/faq_management"});
                        }}
                      >
                        BACK
                      </Button>
                    </Col>
                    <Col lg="6" className="text-right">
                      <Button style={ChsStyles.GeneralButton} color="primary" type="reset">
                        RESET
                      </Button>
                      <Button style={ChsStyles.GeneralButton} color="primary" type="submit">
                        {formik.values.forumQuestionId && formik.values.forumAnswerId ? "UPDATE" : "SAVE"}
                      </Button>
                    </Col>
                  </Row>
                </CardFooter>
              </Form>
            </Card>
          </div>
        </Row>
      </Container>
    </>
  );
};

export default ForumAnswerForm;
