import React, { useState, useEffect } from "react";

// reactstrap components
import { Card, Container, Row, Col, CardBody, CardHeader } from "reactstrap";
import { useDispatch, useSelector } from "react-redux";
// core components

import PageHeader from "components/Headers/PageHeader.js";
import CommonHeader from "components/Headers/CommonHeader";
import { fetchMasterDataLanguage, fetchLocalizationContent } from "actions/masterData";
import { LOGINSTATE } from "../../../config/config.json";
import { GlobalStylesColor } from "constants/globalStyles";
import { LANGUAGE } from "constants/actionTypes";
import { useHistory } from "react-router-dom";

import { confirmAlert } from "react-confirm-alert";

import { useTranslation, initReactI18next, Trans } from "react-i18next";
import { RTL_FLAG_RIGHT, RTL_FLAG_LEFT, SYS_DEFAULT_LANGUAGE_CODE } from "helper";

const Forum = () => {
  const dispatch = useDispatch();
  const history = useHistory();
  const mainContent = React.useRef(null);
  const [rtlAlignment, setRtlAlignment] = useState("left");

  const { t, i18n } = useTranslation();

  const userLang = useSelector((state) => state.masterData.languageByCode);
  if (LOGINSTATE) console.log("userLanguage: ", userLang);

  useEffect(() => {

    if (userLang && userLang.language && userLang.language.length > 0) {
      const lang = userLang.language[0]

      const params = { languageCode: lang.code };
      dispatch(fetchLocalizationContent(params, history));

      i18n.changeLanguage(lang.code);

      if (lang && lang.dir === "rtl") {
        setRtlAlignment(RTL_FLAG_RIGHT);
      }
      else {
        setRtlAlignment(RTL_FLAG_LEFT);
      }
    }
    else {
      // To fix location reload, may case issue with recaptcha re-render. test didn't show any issue
      const params = { languageCode: SYS_DEFAULT_LANGUAGE_CODE };
      dispatch(fetchLocalizationContent(params, history));
      i18n.changeLanguage(SYS_DEFAULT_LANGUAGE_CODE);
      setRtlAlignment(RTL_FLAG_LEFT);
    }

  }, [userLang]);


  return (
    <>
      <PageHeader />

      {/* Page content */}
      <div className="main-content" ref={mainContent}>
        <div>
          <Container className="mt--9">
            <Card className="mt-5">
              <CardHeader style={{ textAlign: rtlAlignment === RTL_FLAG_RIGHT ? "right" : "left" }}>
                <h3 style={{ color: GlobalStylesColor.mbppThemeBlue }}>{t("privacyPolicy.appCountry.header")}</h3>
              </CardHeader>

              {rtlAlignment === RTL_FLAG_RIGHT ? (
                <CardBody style={{ textAlign: "right" }}>
                  {/* app country - right to left */}
                  <Row style={{ margin: 5 }}>
                    <Col>
                      <h3> </h3>
                      <p>{t("privacyPolicy.appCountry.paragraph1")}</p>

                      <h3 style={{ color: GlobalStylesColor.mbppThemeBlue }}>{t("privacyPolicy.appCountry.title1")}</h3>
                      <p>{t("privacyPolicy.appCountry.paragraph2")}</p>

                      <p>{t("privacyPolicy.appCountry.title2")}</p>
                      <ul>
                        {t("privacyPolicy.appCountry.paragraph3") + " 1."}
                        <br />
                        {t("privacyPolicy.appCountry.paragraph4") + " 2."}
                      </ul>
                    </Col>
                  </Row>
                </CardBody>
              ) : (
                <CardBody style={{ textAlign: "justify" }}>
                  {/* app country - left to right */}
                  <Row style={{ margin: 5 }}>
                    <Col>
                      <h3> </h3>
                      <p>{t("privacyPolicy.appCountry.paragraph1")}</p>

                      <h3 style={{ color: GlobalStylesColor.mbppThemeBlue }}>{t("privacyPolicy.appCountry.title1")}</h3>
                      <p>{t("privacyPolicy.appCountry.paragraph2")}</p>

                      <p>{t("privacyPolicy.appCountry.title2")}</p>
                      <ul>
                        {"1. " + t("privacyPolicy.appCountry.paragraph3")}
                        <br />
                        {"2. " + t("privacyPolicy.appCountry.paragraph4")}
                      </ul>
                    </Col>
                  </Row>
                </CardBody>
              )}
            </Card>

            <Card className="mt-5">
              <CardHeader style={{ textAlign: rtlAlignment === RTL_FLAG_RIGHT ? "right" : "left" }}>
                <h3 style={{ color: GlobalStylesColor.mbppThemeBlue }}>{t("privacyPolicy.nonAppCountry.header")}</h3>
              </CardHeader>

              {rtlAlignment === RTL_FLAG_RIGHT ? (
                <CardBody style={{ textAlign: "right" }}>
                  {/* non app country - right to left */}
                  <Row style={{ margin: 5 }}>
                    <Col>
                      <h3> </h3>
                      <p>{t("privacyPolicy.nonAppCountry.paragraph1")}</p>

                      <h3 style={{ color: GlobalStylesColor.mbppThemeBlue }}>{t("privacyPolicy.nonAppCountry.title1")}</h3>
                      <p>{t("privacyPolicy.nonAppCountry.paragraph2")}</p>

                      <p>{t("privacyPolicy.nonAppCountry.title2")}</p>
                      <ul>
                        {t("privacyPolicy.nonAppCountry.paragraph3") + " 1."}
                        <br />
                        {t("privacyPolicy.nonAppCountry.paragraph4") + " 2."}
                      </ul>
                    </Col>
                  </Row>
                </CardBody>
              ) : (
                <CardBody style={{ textAlign: "justify" }}>
                  {/* non app country - left to right */}
                  <Row style={{ margin: 5 }}>
                    <Col>
                      <h3> </h3>
                      <p>{t("privacyPolicy.nonAppCountry.paragraph1")}</p>

                      <h3 style={{ color: GlobalStylesColor.mbppThemeBlue }}>{t("privacyPolicy.nonAppCountry.title1")}</h3>
                      <p>{t("privacyPolicy.nonAppCountry.paragraph2")}</p>

                      <p>{t("privacyPolicy.nonAppCountry.title2")}</p>
                      <ul>
                        {"1. " + t("privacyPolicy.nonAppCountry.paragraph3")}
                        <br />
                        {"2. " + t("privacyPolicy.nonAppCountry.paragraph4")}
                      </ul>
                    </Col>
                  </Row>
                </CardBody>
              )}
            </Card>
          </Container>
        </div>
      </div>
    </>
  );
};

export default Forum;
