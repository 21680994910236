// node.js library that concatenates classes (strings)
import classnames from "classnames";
import Header from "components/Headers/Header.js";
import AdminHeader from "components/Headers/AdminHeader.js";
import { LOGINSTATE } from "../config/config.json";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import { confirmAlert } from "react-confirm-alert";
// import { fetchStatSummery } from 'actions/analytics'
// reactstrap components
import { Card, CardBody, CardHeader, Col, Container, Nav, NavItem, NavLink, Row, Table, CardTitle, Button, CardFooter } from "reactstrap";

import Chart from "chart.js"; // javascipt plugin for creating charts. Does the style formatting
import { Bar, Line, Radar } from "react-chartjs-2"; // react plugin used to create charts
import { chartOptions, getLineChart, getBarChart, parseOptions, setInitData } from "variables/charts";
import { fetchAnswerStat, fetchUserStat } from "actions/report";
import { fetchMasterDataLanguage } from "actions/masterData";
import Lodash from "lodash";
import { roundValue } from "helper";
import { blue } from "@material-ui/core/colors";
import { TextField } from "@material-ui/core";
import { GlobalStylesColor } from "constants/globalStyles";
import { LANGUAGE } from "constants/actionTypes";
import Moment from "moment";

import { useTranslation, initReactI18next, Trans } from "react-i18next";

const Index = (props) => {
  const dispatch = useDispatch();
  const history = useHistory();

  const [lineChartDetail, setLineChartDetail] = useState({});
  const [lineChart, setLineChart] = useState({});
  const [lineChartType, setLineChartType] = useState("CHSAT");
  const [lineChartGroup, setLineChartGroup] = useState("2021-H2");
  const [lineChartValues, setLineChartValues] = useState([]);
  const [lineChartLabels, setLineChartLabels] = useState([]);
  const [lineChartDropDown, setLineChartDropDown] = useState([]);

  const [barChart, setBarChart] = useState({});
  const [barChartLabels, setBarChartLabels] = useState([]);

  const [linex, setLinex] = useState("");
  const [liney, setLiney] = useState("");
  const [barx, setBarx] = useState("");
  const [bary, setBary] = useState("");

  const [rtlAlignment, setRtlAlignment] = useState("left");

  /////////////////////////////////

  const [statType_CHSAT_COMPLETE, setStatType_CHSAT_COMPLETE] = useState("0");
  const [statType_ICAT_COMPLETE, setStatType_ICAT_COMPLETE] = useState("0");
  const [statType_TIE_COMPLETE, setStatType_TIE_COMPLETE] = useState("0");
  const [statType_CHSAT_PROGRESS, setStatType_CHSAT_PROGRESS] = useState("0");
  const [statType_ICAT_PROGRESS, setStatType_ICAT_PROGRESS] = useState("0");
  const [statType_TIE_PROGRESS, setStatType_TIE_PROGRESS] = useState("0");

  //// tile expand ///

  const [showChsatDetails, setShowChsatDetails] = useState(false);
  const [showIcatDetails, setShowIcatDetails] = useState(false);
  const [showTieDetails, setShowTieDetails] = useState(false);

  const optionsGrid = {
    scales: {
      xAxes: [
        {
          ticks: {
            fontColor: "#fff",
          },
          display: true,
          scaleLabel: {
            display: true,
            labelString: linex,
            fontColor: "#fff",
            fontStyle: "bold",
          },
        },
      ],
      yAxes: [
        {
          gridLines: {
            color: "#fff",
            zeroLineColor: "#fff",
          },
          ticks: {
            callback: function (value) {
              if (!(value % 1)) {
                return value;
              }
            },
            fontColor: "#fff",
            beginAtZero: true,
            steps: 1,
            stepValue: 1,
            max: 5.2,
          },
          scaleLabel: {
            display: true,
            labelString: liney,
            fontColor: "#fff",
            fontStyle: "bold",
          },
        },
      ],
    },
  };

  const optionsGridBarChart = {
    scales: {
      xAxes: [
        {
          display: true,
          scaleLabel: {
            display: true,
            labelString: barx,
            fontStyle: "bold",
          },
        },
      ],
      yAxes: [
        {
          gridLines: {
            color: "#212529",
            zeroLineColor: "#212529",
          },
          ticks: {
            callback: function (value) {
              if (!(value % 1)) {
                return value;
              }
            },
            beginAtZero: true,
            steps: 1,
            stepValue: 1,
            max: 5.2,
          },
          scaleLabel: {
            display: true,
            labelString: bary,
            fontStyle: "bold",
          },
        },
      ],
    },
  };


  const userLanguage = useSelector((state) => state.masterData.languageByCode);
  console.log("userLanguage", userLanguage);
  useEffect(() => {
    dispatch(fetchUserStat());
    let params = {
      user: {
        code: jUser?.user?.language,
      },
    };
    dispatch(fetchMasterDataLanguage(params.user));

  }, [jUser]);

  let currentUser = localStorage.getItem("userDetails");
  let jUser = JSON.parse(currentUser);
  let uRole = currentUser && jUser && jUser.user && jUser.user.urole ? jUser.user.urole : "GENERAL";
  console.log("uRole", currentUser);

  useEffect(() => {
    userLanguage &&
      userLanguage.language &&
      Object.values(userLanguage.language).map((lang, i) => {
        if (lang && lang.dir === "rtl") {
          setRtlAlignment("right");
        }
      });

    // localStorage.setItem(LANGUAGE, JSON.stringify(full));
  }, [userLanguage]);

  const { t, i18n } = useTranslation();

  const currentStat = useSelector((state) => state.report.userStat);
  useEffect(() => {
    if (LOGINSTATE) console.log("currentStat: ", currentStat);
    if (currentStat && !Lodash.isEmpty(currentStat)) {
      //Current stat is an array

      // populate tiles with type  ---------------------------------------
      let dataTiles = currentStat?.surveyTiles;
      if (dataTiles && !Lodash.isEmpty(dataTiles)) {
        setStatType_CHSAT_COMPLETE(dataTiles.chsat_complete);
        setStatType_ICAT_COMPLETE(dataTiles.icat_complete);
        setStatType_TIE_COMPLETE(dataTiles.tie_complete);
        setStatType_CHSAT_PROGRESS(dataTiles.chsat_progress);
        setStatType_ICAT_PROGRESS(dataTiles.icat_progress);
        setStatType_TIE_PROGRESS(dataTiles.tie_progress);
      }

      // populate bar chart with survey  ---------------------------------------
      let dataBarGraph = currentStat?.surveyLineGraph;
      if (dataBarGraph && !Lodash.isEmpty(dataBarGraph)) {
        setBarChart(dataBarGraph);
        let label = [];
        label.push(t("barChartLabels.chsat"))
        label.push(t("barChartLabels.icat"))
        label.push(t("barChartLabels.tie"));
        setBarChartLabels(label);
      }

      // populate line graph with survey  ---------------------------------------
      let dataLineGraph = currentStat?.surveyBarGraph;
      console.log("dataLineGraph", dataLineGraph?.intervalGroups);

      // Translate dropdown values to locale
      let tempDropDownItems = [];
      if (Lodash.isArray(dataLineGraph?.intervalGroups)) {
        setLineChartDetail(dataLineGraph.intervalValues);

        dataLineGraph?.intervalGroups.forEach(value => {
          let updatedItem = (value.slice()).replace('January to June', t("dateAndTime.January to June"))
          updatedItem = (updatedItem).replace('July to December', t("dateAndTime.July to December"))

          const valueItem = {
            value: value,
            item: updatedItem
          }
          // console.log("### valueItem: ", valueItem);
          tempDropDownItems.push(valueItem)
        });
      }
      // setLineChartDropDown(dataLineGraph);
      setLineChartDropDown(tempDropDownItems);
      setLineChartGroup(tempDropDownItems[0].value);

    }
  }, [currentStat, t]);

  useEffect(() => {
    let currentData = Lodash.find(lineChartDetail, { group: lineChartGroup, type: lineChartType });

    if (Lodash.isEmpty(currentData)) {
      setLineChartLabels([]);
      setLineChartValues([]);
    } else {
      // Need to update start and end moths with locale
      let updatedLabels = [...currentData.graphLabels];
      if (Lodash.isArray(updatedLabels) && !Lodash.isEmpty(updatedLabels) && updatedLabels[0].includes('-Jan')) updatedLabels[0] = updatedLabels[0].replace('Jan', t("dateAndTime.JAN"))
      if (Lodash.isArray(updatedLabels) && !Lodash.isEmpty(updatedLabels) && updatedLabels[0].includes('-Jul')) updatedLabels[0] = updatedLabels[0].replace('Jul', t("dateAndTime.JUL"))
      if (Lodash.isArray(updatedLabels) && !Lodash.isEmpty(updatedLabels) && updatedLabels[updatedLabels.length - 1].includes('-Jun')) updatedLabels[updatedLabels.length - 1] = updatedLabels[updatedLabels.length - 1].replace('Jun', t("dateAndTime.JUN"))
      if (Lodash.isArray(updatedLabels) && !Lodash.isEmpty(updatedLabels) && updatedLabels[updatedLabels.length - 1].includes('-Dec')) updatedLabels[updatedLabels.length - 1] = updatedLabels[updatedLabels.length - 1].replace('Dec', t("dateAndTime.DEC"))

      setLineChartLabels(updatedLabels);
      // setLineChartLabels(currentData.graphLabels);
      setLineChartValues(currentData.graphValues);
      console.log("lineChartLabels", updatedLabels);
      console.log("graphValues", currentData.graphValues);
    }
  }, [lineChartDetail, lineChartGroup, lineChartType, t]);

  if (window.Chart) {
    parseOptions(Chart, chartOptions());
  }

  useEffect(() => {
    setLiney(t("dashboard.lineGraph.assessmentCompletion"));
    setLinex(t("dashboard.lineGraph.totalScore"));

    setBarx(t("dashboard.barGraph.type"));
    setBary(t("dashboard.barGraph.totalScore"));
  }, [t]);

  return (
    <>
      {/* Header content */}
      <AdminHeader />

      {/* Page content */}
      <Container className="mt--5" fluid style={{ textAlign: rtlAlignment }}>
        <div className="header-body">
          {/* Card stats */}
          <Row>
            <Col lg="6" xl="4">
              <Card className="card-stats mb-4 mb-xl-0">
                <CardBody>
                  <div
                  // onClick={(e) => {
                  //   history.push({ pathname: "/registered/assessment_answers" });
                  // }}
                  // style={{ cursor: "pointer" }}
                  >
                    <Row>
                      <div className="col">
                        <CardTitle tag="h5" className="text-uppercase text-muted mb-0">
                          <b>{t("dashboard.chsat.title")}</b> <br />
                          <br />
                          {t("dashboard.chsat.completion")}
                        </CardTitle>
                        <span className="h2 font-weight-bold mb-0">{statType_CHSAT_COMPLETE}</span>
                        <p className="mt-3 mb-0 text-muted text-sm">
                          <span className="text-danger mr-2">{statType_CHSAT_PROGRESS}</span> <span className="text-nowrap">{t("dashboard.chsat.ongoing")}</span>
                        </p>
                      </div>
                      <Col className="col-auto">
                        <div className="icon icon-shape text-white rounded-circle shadow" style={{ backgroundColor: GlobalStylesColor.chsChsat }}>
                          <i className="fas fa-clipboard-check" style={{ fontSize: 32 }} />
                        </div>
                      </Col>
                    </Row>
                    {/* <p className="mt-3 mb-0 text-muted text-sm">
                      <span className="text-danger mr-2">{statType_CHSAT_PROGRESS}</span> <span className="text-nowrap">{t("dashboard.chsat.ongoing")}</span>
                    </p> */}
                  </div>

                  <Row>
                    <Col className="text-right">
                      <div
                        style={{ cursor: "pointer" }}
                        onClick={(e) => {
                          setShowChsatDetails(!showChsatDetails);
                        }}
                      >
                        <i className={showChsatDetails ? "fas fa-chevron-circle-up" : "fas fa-chevron-circle-right"} style={{ fontSize: 24, color: GlobalStylesColor.mbppThemeBlue }} />
                      </div>
                    </Col>
                  </Row>
                  {showChsatDetails ? (
                    <Row style={{ marginTop: 15, fontSize: 12 }}>
                      <Col>
                        <span>{t("dashboard.chsat.description")}</span>
                      </Col>
                    </Row>
                  ) : null}
                </CardBody>
              </Card>
            </Col>

            <Col lg="6" xl="4">
              <Card className="card-stats mb-4 mb-xl-0">
                <CardBody>
                  <div
                  // onClick={(e) => {
                  //   history.push({ pathname: "/registered/assessment_answers" });
                  // }}
                  // style={{ cursor: "pointer" }}
                  >
                    <Row>
                      <div className="col">
                        <CardTitle tag="h5" className="text-uppercase text-muted mb-0">
                          <b>{t("dashboard.icat.title")}</b> <br />
                          <br /> {t("dashboard.icat.completion")}
                        </CardTitle>
                        <span className="h2 font-weight-bold mb-0">{statType_ICAT_COMPLETE}</span>
                        <p className="mt-3 mb-0 text-muted text-sm">
                          <span className="text-danger mr-2">{statType_ICAT_PROGRESS}</span> <span className="text-nowrap">{t("dashboard.icat.ongoing")}</span>
                        </p>
                      </div>
                      <Col className="col-auto">
                        <div className="icon icon-shape text-white rounded-circle shadow" style={{ backgroundColor: GlobalStylesColor.chsIcat }}>
                          <i className="fas fa-university" style={{ fontSize: 32 }} />
                        </div>
                      </Col>
                    </Row>
                    {/* <p className="mt-3 mb-0 text-muted text-sm">
                      <span className="text-danger mr-2">{statType_ICAT_PROGRESS}</span> <span className="text-nowrap">{t("dashboard.icat.ongoing")}</span>
                    </p> */}
                  </div>

                  <Row>
                    <Col className="text-right">
                      <div
                        style={{ cursor: "pointer" }}
                        onClick={(e) => {
                          setShowIcatDetails(!showIcatDetails);
                        }}
                      >
                        <i className={showIcatDetails ? "fas fa-chevron-circle-up" : "fas fa-chevron-circle-right"} style={{ fontSize: 24, color: GlobalStylesColor.mbppThemeBlue }} />
                      </div>
                    </Col>
                  </Row>
                  {showIcatDetails ? (
                    <Row style={{ marginTop: 15, fontSize: 12 }}>
                      <Col>
                        <span>{t("dashboard.icat.description")}</span>
                      </Col>
                    </Row>
                  ) : null}
                </CardBody>
              </Card>
            </Col>

            <Col lg="6" xl="4">
              <Card className="card-stats mb-4 mb-xl-0">
                <CardBody>
                  <div
                  // onClick={(e) => {
                  //   history.push({ pathname: "/registered/assessment_answers" });
                  // }}
                  // style={{ cursor: "pointer" }}
                  >
                    <Row>
                      <div className="col">
                        <CardTitle tag="h5" className="text-uppercase text-muted mb-0">
                          <b>{t("dashboard.tie.title")}</b> <br />
                          <br /> {t("dashboard.tie.completion")}
                        </CardTitle>
                        <span className="h2 font-weight-bold mb-0">{statType_TIE_COMPLETE}</span>
                        <p className="mt-3 mb-0 text-muted text-sm">
                          <span className="text-warning mr-2">{statType_TIE_PROGRESS}</span> <span className="text-nowrap">{t("dashboard.tie.ongoing")}</span>
                        </p>
                      </div>
                      <Col className="col-auto">
                        <div className="icon icon-shape text-white rounded-circle shadow" style={{ backgroundColor: GlobalStylesColor.chsTie }}>
                          <i className="fas fa-hands-helping" style={{ fontSize: 32 }} />
                        </div>
                      </Col>
                    </Row>
                    {/* <p className="mt-3 mb-0 text-muted text-sm">
                      <span className="text-warning mr-2">{statType_TIE_PROGRESS}</span> <span className="text-nowrap">{t("dashboard.tie.ongoing")}</span>
                    </p> */}
                  </div>

                  <Row>
                    <Col className="text-right">
                      <div
                        style={{ cursor: "pointer" }}
                        onClick={(e) => {
                          setShowTieDetails(!showTieDetails);
                        }}
                      >
                        <i className={showTieDetails ? "fas fa-chevron-circle-up" : "fas fa-chevron-circle-right"} style={{ fontSize: 24, color: GlobalStylesColor.mbppThemeBlue }} />
                      </div>
                    </Col>
                  </Row>
                  {showTieDetails ? (
                    <Row style={{ marginTop: 15, fontSize: 12 }}>
                      <Col>
                        <span>{t("dashboard.tie.description")}</span>
                      </Col>
                    </Row>
                  ) : null}
                </CardBody>
              </Card>
            </Col>
          </Row>
        </div>
        <br />

        {/* Top charts */}
        <Row>
          <Col className="mb-5 mb-xl-0" xl="8">
            <Card className="bg-gradient-default shadow">
              <CardHeader className="bg-transparent">
                <Row className="align-items-center">
                  <Col>
                    <h6 className="text-uppercase text-light ls-1 mb-1">{t("dashboard.lineGraph.overview")}</h6>
                    <h2 className="text-white mb-0">{t("dashboard.lineGraph.progress")}</h2>
                  </Col>
                  <Col className="text-right">
                    <Button
                      style={{ backgroundColor: lineChartType === "CHSAT" ? "white" : GlobalStylesColor.chsChsat, color: lineChartType === "CHSAT" ? GlobalStylesColor.chsChsat : "white", marginRight: 5, marginBottom: 5 }}
                      color={lineChartType === "CHSAT" ? GlobalStylesColor.chsChsat : GlobalStylesColor.chsChsat}
                      onClick={() => {
                        setLineChartType("CHSAT");
                      }}
                    >
                      {t("dashboard.lineGraph.chsat")}
                    </Button>
                    <Button
                      style={{ backgroundColor: lineChartType === "ICAT" ? "white" : GlobalStylesColor.chsIcat, color: lineChartType === "ICAT" ? GlobalStylesColor.chsChsat : "white", marginRight: 5, marginBottom: 5 }}
                      color={lineChartType === "ICAT" ? GlobalStylesColor.chsIcat : GlobalStylesColor.chsIcat}
                      onClick={() => {
                        setLineChartType("ICAT");
                      }}
                    >
                      {t("dashboard.lineGraph.icat")}
                    </Button>
                    <Button
                      style={{ backgroundColor: lineChartType === "TIE" ? "white" : GlobalStylesColor.chsTie, color: lineChartType === "TIE" ? GlobalStylesColor.chsChsat : "white", marginRight: 5, marginBottom: 5 }}
                      color={lineChartType === "TIE" ? GlobalStylesColor.chsTie : GlobalStylesColor.chsTie}
                      onClick={() => {
                        setLineChartType("TIE");
                      }}
                    >
                      {t("dashboard.lineGraph.tie")}
                    </Button>
                  </Col>
                </Row>
              </CardHeader>
              <CardBody>
                {/* Chart */}
                {lineChartValues.length > 0 && (
                  <div className="chart">
                    <Line
                      data={{
                        labels: lineChartLabels,
                        datasets: [
                          {
                            data: lineChartValues,
                            label: t("dashboard.lineGraph.score"),
                            fill: false,
                            backgroundColor: "#f27b90",
                            borderColor: "#f27b90",
                            pointBorderWidth: 5,

                            pointBorderColor: "white",
                            pointStyle: "circle",
                            pointRadius: 1,
                            pointBackgroundColor: "rgb(255, 255, 255)",
                            borderWidth: 0,
                          },
                        ],
                      }}
                      options={optionsGrid}
                      getDatasetAtEvent={(e) => console.log(e)}
                    />
                  </div>
                )}
              </CardBody>
              <CardFooter>
                {lineChartDropDown && lineChartDropDown.length > 0 && (
                  <TextField
                    select
                    fullWidth
                    label={t("dashboard.lineGraph.timeinterval")}
                    variant="outlined"
                    value={lineChartGroup}
                    onChange={(e) => {
                      setLineChartGroup(e.target.value);
                    }}
                    SelectProps={{ native: true }}
                    className={"form-control-alternative form-control-edit"}
                  >
                    {lineChartDropDown.map((valueItem) => (
                      <option key={valueItem.value} value={valueItem.value}>
                        {valueItem.item}
                      </option>
                    ))}
                  </TextField>
                )}
              </CardFooter>
            </Card>
          </Col>

          <Col xl="4">
            <Card className="shadow">
              <CardHeader className="bg-transparent">
                <Row className="align-items-center">
                  <div className="col">
                    <h6 className="text-uppercase text-muted ls-1 mb-1">{t("dashboard.barGraph.summary")}</h6>
                    <h2 className="mb-0">{t("dashboard.barGraph.score")}</h2>
                  </div>
                </Row>
              </CardHeader>
              <CardBody>
                {/* Chart */}
                {Object.keys(barChart).length > 0 && (
                  <div className="chart">
                    {console.log("barChartLabels", barChartLabels)}
                    <Bar
                      data={{
                        labels: barChartLabels,
                        datasets: [
                          {
                            label: "",
                            data: barChart.data,
                            backgroundColor: "#8f140b",
                            borderColor: "#ae0f0a",
                          },
                        ],
                      }}
                      options={optionsGridBarChart}
                    />
                  </div>
                )}
              </CardBody>
            </Card>
          </Col>
        </Row>
      </Container>
    </>
  );
};

export default Index;
