import { fetchRecommendationsBySurveyId } from "actions/survey";
import { LOGINSTATE } from "../../../config/config.json";
import { fetchMasterDataLanguage } from "actions/masterData";
import { initialValues_assessmentPage, roundValue } from "helper";
import Lodash from "lodash";
import Moment from "moment";
import React, { useEffect, useState } from "react";
import { Radar } from "react-chartjs-2";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import { Col, Row, Button } from "reactstrap";
import { ChsStyles } from "constants/globalStyles";
import { RTL_FLAG_RIGHT } from "helper";

const AnswerPrintTemplateByHtml = (font) => {
  // console.log("fontSize", fontSize);
  const history = useHistory();
  // const [fontSize, setFontSize] = useState(14);
  const dispatch = useDispatch();
  if (LOGINSTATE) console.log("font", font);

  const [systemContentLocale, setSystemContentLocale] = useState({});
  const [surveyRecommendations, setSurveyRecommendations] = useState({});

  const [refreshCounter, setRefreshCounter] = useState(0);
  const [refreshCount, setRefreshCount] = useState(0);

  const [segName, setSegName] = useState();
  const [segScore, setSegScore] = useState();
  const [organizatioName, setOrganizationName] = useState(" ");
  const [version, setVersion] = useState("");
  const [answer, setAnswer] = useState({});
  // const [fontSize, setFontSize] = useState(14);
  const [userLanguage, setUserLanguage] = useState("en");
  const [locale, setLocale] = useState({});
  const [score, setScore] = useState({});

  const [rtlAlignment, setRtlAlignment] = useState("left");

  // Get pre-values to create the locale report, which are user answers, recommendations and the locale template.
  const answerAll = useSelector((state) => state.survey.answerAll);
  const surveyRecommendationAll = useSelector((state) => state.survey.recommendationAll);

  const userLang = useSelector((state) => state.masterData.languageByCode);
  if (LOGINSTATE) console.log("userLanguage", userLang);
  useEffect(() => {
    // Get profile data to fill organization questions

    let params = {
      user: {
        code: jUser?.user?.language,
      },
    };
    dispatch(fetchMasterDataLanguage(params.user));
  }, []);

  useEffect(() => {
    userLang &&
      userLang.language &&
      Object.values(userLang.language).map((lang, i) => {
        if (lang && lang.dir === "rtl") {
          setRtlAlignment("right");
        }
      });
  }, [userLang]);

  if (LOGINSTATE) console.log("answerAll", answerAll);
  useEffect(() => {
    setRefreshCount(refreshCount + 1);
  }, [font]);

  useEffect(() => {
    let recommendationsLocale = Lodash.find(surveyRecommendationAll, { languageCode: userLanguage });

    // if the relevant locale recommendations are not available, take the default recommendations
    if (!recommendationsLocale) {
      recommendationsLocale = Lodash.find(surveyRecommendationAll, { languageCode: "en" });
    }

    if (recommendationsLocale) {
      setSurveyRecommendations(recommendationsLocale);
    } else {
      setSurveyRecommendations({});
    }
  }, [surveyRecommendationAll]);

  useEffect(() => {
    // check answer is available or exit;
    if (LOGINSTATE) console.log("answerAll: ", answerAll);

    let score = answerAll?.locale?.segScore;
    setSegScore(score ? score : "");

    let name = answerAll?.locale?.segName;
    // setSegName(name ? name : []); // Originail name, contain assessment code

    let tempNames = []
    if (name && Lodash.isArray(name) && !Lodash.isEmpty(name)) {
      name.forEach((name, i) => {
        tempNames.push(`#${i + 1}`)
      })
    }
    setSegName(tempNames);

    let organization = answerAll?.locale?.answer?.institutionalQuestions[0].answer;
    setOrganizationName(organization);

    let versionNumber = answerAll?.locale?.answer.title;
    setVersion(versionNumber ? versionNumber : "");

    let survey = answerAll?.locale?.answer;
    setAnswer(survey ? survey : {});

    let font = answerAll?.frontSize;
    // setFontSize(font ? font : 14);

    if (!Lodash.isEmpty(answerAll?.segmentsLocale)) {
      let local = answerAll?.segmentsLocale;
      if (LOGINSTATE) console.log("local", local);
      setLocale(local);
    } else {
      setLocale(answerAll?.locale?.answer?.segments);
    }

    let segAndQuestionScore = answerAll?.locale?.answer?.segments;
    setScore(segAndQuestionScore);

    if (!Lodash.isEmpty(answerAll?.systemContentLocale)) {
      let systemContentLocal = answerAll?.systemContentLocale;
      setSystemContentLocale(systemContentLocal);
      if (LOGINSTATE) console.log("systemContentLocal", systemContentLocal);
    } else {
      setSystemContentLocale(initialValues_assessmentPage);
    }

    dispatch(fetchRecommendationsBySurveyId({ surveyId: survey?.surveyId }, history));

    let lang = answerAll?.locale?.language;
    setUserLanguage(lang);
  }, [answerAll]);

  useEffect(() => {
    setTimeout(() => {
      setRefreshCounter(refreshCounter + 1);
    }, 2000);
  }, [segName, segScore]);

  let currentUser = localStorage.getItem("userDetails");
  let jUser = JSON.parse(currentUser);
  let uRole = currentUser && jUser && jUser.user && jUser.user.urole ? jUser.user.urole : "GENERAL";
  if (LOGINSTATE) console.log("uRole", currentUser);

  return (
    <>
      <br />
      <br />
      <Row style={styles.page} id={refreshCount}>
        <Col style={styles.section}>
          <Row>
            <Col style={{ textAlign: "right" }}>
              <span className="mb-0" style={{ fontWeight: 700, fontSize: font ? font?.font + 2 : 16 }}>
                {organizatioName}
              </span>
              <br />
              <span className="mb-0" style={{ fontSize: font ? font?.font : 14 }}>
                {/* {Moment(answerAll?.locale?.answer?.updatedAt).format("YYYY-MM-DD hh:mm A")} */}
                {answerAll?.locale?.answer?.clientTimestamp}
              </span>
            </Col>
          </Row>

          <Row className="text-center" style={{ fontSize: font ? font?.font : 14 }}>
            <Col>
              <div style={styles.image}>
                <img alt="logo" src={require("../../../assets/img/brand/img_logo.png").default} style={{ width: 200, height: "auto" }} />
              </div>
            </Col>
          </Row>

          <Row className="text-center" style={{ fontWeight: 700, fontSize: font ? font?.font + 2 : 16 }}>
            <Col style={{ ...styles.title }}>
              <span>{answerAll?.locale?.answer?.type === "CHSAT" ? systemContentLocale.chsat : answerAll?.locale?.answer?.type === "ICAT" ? systemContentLocale.icat : systemContentLocale.tie}</span>
            </Col>
          </Row>

          <Row className="text-center" style={{ fontSize: font ? font?.font : 14 }}>
            <Col style={{ ...styles.author }}>
              <span>
                {systemContentLocale.version} : {version}
              </span>
            </Col>
          </Row>
          <Row id={refreshCounter} style={{ fontSize: font ? font?.font : 14 }}>
            {/* <CardHeader className="bg-transparent">{refreshCounter}</CardHeader> */}
            <Col>
              <div className="chart" style={{ marginLeft: 25, marginRight: 25, width: 1000, height: 500 }}>
                <Radar
                  data={{
                    labels: segName,
                    datasets: [
                      {
                        label: systemContentLocale.segment_score,
                        xlabel: segName,
                        data: segScore,
                        fill: true,
                        backgroundColor: "rgba(175, 30, 35, 0.5)",
                        borderColor: "#af1e23",
                        pointRadius: "1",
                        pointBackgroundColor: "#fff",
                        pointBorderColor: "#fff",
                        pointHoverBackgroundColor: "#fff",
                        pointHoverBorderColor: "rgb(255, 99, 132)",
                      },
                    ],
                  }}
                  options={{
                    tooltips: {
                      enabled: true,
                      rtl: false,
                      callbacks: {
                        title: function (item, data) {
                          return data.labels[item[0]?.index] && item.length === 1 ? data.labels[item[0]?.index] : null;
                        },
                      },
                    },
                    // legend: {display: true },
                    scale: {
                      ticks: {
                        beginAtZero: true,
                        max: 5,
                        min: 0,
                        stepSize: 1,
                      },
                    },
                  }}
                // getDatasetAtEvent={(e) => console.log(e)}
                />
              </div>
              <br />
              <br />
              {answerAll?.segmentsLocale
                ? (answerAll?.segmentsLocale).map((segment, i) => (
                  <Row style={{ textAlign: rtlAlignment }}>
                    <Col style={{ marginLeft: 12, marginRight: 25, marginBottom: 5, fontSize: font ? font?.font - 2 : 12, color: "black" }}>
                      {rtlAlignment === RTL_FLAG_RIGHT
                        ? <span>{segment.title} .#{i + 1}</span>
                        : <span>#{i + 1}. {segment.title}</span>
                      }
                    </Col>
                  </Row>
                ))
                : answerAll?.locale?.answer?.segments.map((segment, i) => (
                  <Row style={{ textAlign: rtlAlignment }}>
                    <Col style={{ marginLeft: 12, marginRight: 25, marginBottom: 5, fontSize: font ? font?.font - 2 : 12, color: "black" }}>
                      {rtlAlignment === RTL_FLAG_RIGHT
                        ? <span>{segment.title} .#{i + 1}</span>
                        : <span>#{i + 1}. {segment.title}</span>
                      }
                    </Col>
                  </Row>
                ))}
            </Col>
          </Row>
          <br />
          <br />
          <Row>
            <Col lg="6" className="text-left" style={{ marginBottom: 10 }}>
              <Button
                style={ChsStyles.GeneralButton}
                color="primary"
                onClick={() => {
                  history.goBack();
                }}
              >
                {systemContentLocale.app_back}
              </Button>
            </Col>
          </Row>
        </Col>
      </Row>
    </>
  );
};

// Create styles
const styles = {
  page: {
    // paddingTop: 35,
    // paddingBottom: 65,
    // paddingHorizontal: 35,
  },
  section: {
    margin: 0,
    padding: 35,
  },
  h3: {
    color: "#af1e23",
    marginLeft: 12,
    // fontSize: 14,
    textAlign: "justify",
    fontWeight: "bold",
  },
  row: {
    flexGrow: 1,
    flexDirection: "row",
  },
  title: {
    // fontSize: 24,
    textAlign: "center",
  },
  author: {
    // fontSize: 12,
    textAlign: "center",
    marginBottom: 40,
  },
  subtitle: {
    // fontSize: 18,
    margin: 12,
    fontWeight: "bold",
  },
  segment: {
    // color: "#af1e23",
    fontWeight: "bold",
    fontSize: 15,
    margin: 12,
  },
  text: {
    margin: 12,
    // fontSize: 14,
    textAlign: "justify",
  },
  textRecommendation: {
    color: "#af1e23",
    margin: 12,
    marginBottom: 5,
    // fontSize: 14,
    textAlign: "justify",
  },
  question: {
    margin: 12,
    marginBottom: 12,
    // fontSize: 14,
    textAlign: "justify",
    marginLeft: 30,
  },
  answer: {
    fontSize: 15,
    marginLeft: 30,
  },
  textNote: {
    margin: 12,
    marginBottom: 5,
    fontSize: 14,
    textAlign: "justify",
  },
  image: {
    marginVertical: 5,
    marginHorizontal: 150,
    alignContent: "center",
    textAlign: "center",
  },
  pageNumber: {
    position: "absolute",
    fontSize: 12,
    bottom: 30,
    left: 0,
    right: 0,
    textAlign: "center",
    color: "grey",
  },
  dateAndTime: {
    position: "absolute",
    fontSize: 12,
    bottom: 30,
    right: 35,
    textAlign: "right",
    color: "grey",
  },
  recommendation: {
    marginBottom: 12,
  },
};

export default AnswerPrintTemplateByHtml;
