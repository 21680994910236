import { Grid, TextField } from "@material-ui/core";
import AddCircleOutlineIcon from "@material-ui/icons/AddCircleOutline";
import CheckCircleIcon from "@material-ui/icons/CheckCircle";
import { fetchMasterDataLanguageAll, updateMasterDataLanguage } from "actions/masterData";
// core components
import PageHeader from "components/Headers/PageHeader.js";
import { LOGINSTATE } from "../../../config/config.json";
import { ChsStyles } from "constants/globalStyles";
import { Formik } from "formik";
import React, { useEffect, useRef, useState } from "react";
import "react-confirm-alert/src/react-confirm-alert.css"; // Import css
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import Lodash from "lodash";
// reactstrap components
import { Button, Card, CardBody, CardFooter, CardHeader, Col, Container, Form, FormGroup, Input, InputGroup, InputGroupAddon, InputGroupText, Media, Row, Table } from "reactstrap";
import { confirmAlert } from "react-confirm-alert";
import * as yup from "yup";
import { int_language } from "helper";
import Config from "config/config.json";
import Pagination from "react-responsive-pagination";
import { Tooltip } from "@material-ui/core";
import { GlobalStylesColor } from "constants/globalStyles";
import Moment from "moment";

const reviewSchema = yup.object({
  languages: yup.array().of(
    yup.object({
      code: yup.string("Enter code of language").min(2, "Code of language should be of minimum 2 characters length").max(10, "Code of language should be of maximum 10 characters length").required("Code of language is required"),
      name: yup.string("Enter name of language").min(2, "Description should be of minimum 2 characters length").max(500, "Description should be of maximum 500 characters length").required("Language is required"),
    })
  ),
});

const SurveyCreateTemplate = (props) => {
  // dispatch and history
  const dispatch = useDispatch();
  const history = useHistory();

  const [currentLanguages, setCurrentLanguages] = useState([{ code: null, name: "" }]);
  const [selectedLanguage, setSelectedLanguage] = useState(-1)

  // filter
  const [txtFilter, setTxtFilter] = useState("");
  const [filteredContent, setFilteredContent] = useState([]);

  // To update fomik values
  const formRef = useRef();

  // get the details passed by the parameter to the page. Afterwards, process needed information
  useEffect(() => {
    if (LOGINSTATE) console.log("---------------> called effect1");
    dispatch(fetchMasterDataLanguageAll());
  }, []);

  const allLanguage = useSelector((state) => state.masterData.languageAll);
  if (LOGINSTATE) console.log("allLanguage", allLanguage);

  useEffect(() => {
    setCurrentLanguages(allLanguage);
  }, [allLanguage]);

  // process content with filter. Ignore locale
  useEffect(() => {
    let updatedContent = Lodash.filter(currentLanguages, function (item) {
      return ((item.name.toLowerCase()).indexOf(txtFilter.toLowerCase()) > -1 || (item.code.toLowerCase()).indexOf(txtFilter.toLowerCase()) > -1);
    });
    setFilteredContent(updatedContent);
    if (formRef.current) {
      formRef.current.setFieldValue("languages", updatedContent, false);
    }
  }, [currentLanguages, txtFilter]);

  // handle change event of comments
  const handleLanguageChange = (e, index, language) => {
    if (LOGINSTATE) console.log("e target", e.target);
    console.log('!@# ### currentLanguages: ', currentLanguages)
    const { name, value } = e.target;

    let selectedItem = undefined

    if (language?._id) {
      selectedItem = Lodash.find(currentLanguages, { _id: language._id })
    }
    else {
      const selectedList = Lodash.filter(currentLanguages, { code: language.code, temp: language.temp })
      selectedItem = selectedList[selectedList.length - 1]
    }

    if (selectedItem) {
      selectedItem[name] = value
    }
    setCurrentLanguages([...currentLanguages]);

    if (formRef.current) {
      formRef.current.setFieldValue("languages", currentLanguages, false);
    }
  };

  // handle click event of the Add button of comment
  const handleLanguageAddClick = () => {
    setCurrentLanguages([...currentLanguages, { code: "", name: "", dir: "ltr", int: "No", temp: Moment().format('YYYYMMDDHHmmSSS') }]);

    if (formRef.current) {
      formRef.current.setFieldValue("languages", currentLanguages, false);
    }
  };

  const handleLanguageSaveClick = (e, x) => {
    e.preventDefault()
    if (formRef.current) {
      formRef.current.handleSubmit();
    }
  }

  return (
    <>
      <PageHeader />

      {/* Page content */}
      <Container className="mt--7" fluid>
        {/* create survey questions */}
        <Row className="mt-5">
          <div className="col">
            <Card className="shadow">
              {/* survey header */}
              <CardHeader className="border-0">
                <div className="row">
                  <div className="mb-xl-0 col-10">
                    <h3 className="mb-0">Manage Languages</h3>
                    <h6 className="mb-0">Languages for APP self assessment to be used</h6>
                  </div>
                </div>
              </CardHeader>

              {/* question content */}
              <CardBody style={{}}>
                {/* https://www.iso.org/publication/PUB500001.html */}
                <Row>
                  <Col>
                    <Media className="align-items-center">
                      <a href="https://docs.oracle.com/cd/E13214_01/wli/docs92/xref/xqisocodes.html#wp1252447" target="_blank" rel="noreferrer">
                        <Media>
                          <span
                            className="mb-0 text-sm"
                            dangerouslySetInnerHTML={{
                              __html: "ISO language codes (ISO 3166-1 Alpha-2 code)",
                            }}
                          ></span>
                        </Media>
                      </a>
                    </Media>
                  </Col>
                  <Col>
                    <Media className="align-items-center">
                      <a href="https://developers.google.com/recaptcha/docs/language" target="_blank" rel="noreferrer">
                        <Media>
                          <span
                            className="mb-0 text-sm"
                            dangerouslySetInnerHTML={{
                              __html: "reCAPTCHA Language Codes",
                            }}
                          ></span>
                        </Media>
                      </a>
                    </Media>
                    <h6>reCAPTCHA are supported for languages as per 2022-August</h6>
                  </Col>
                </Row>

                <Formik
                  initialValues={{
                    languages: [{ code: "", name: "", dir: "", int: "", temp: "" }],
                  }}
                  validationSchema={reviewSchema}
                  onSubmit={(values, actions) => {
                    console.log("values", values);

                    if (selectedLanguage > -1) {
                      if (!values.languages[selectedLanguage]?._id) {
                        let result = Lodash.filter(values, { code: values.languages[selectedLanguage]?.code });
                        if (result.length > 1) {
                          confirmAlert({
                            title: "Error",
                            message: "Duplicate country code '" + values.languages[selectedLanguage]?.code + "'",
                            buttons: [{ label: "Ok", onClick: () => { } }],
                            closeOnEscape: false,
                            closeOnClickOutside: false,
                          });
                          return;
                        }
                      }

                      const updatingLanguage = {
                        code: values.languages[selectedLanguage].code,
                        dir: values.languages[selectedLanguage].dir,
                        int: values.languages[selectedLanguage].int,
                        name: values.languages[selectedLanguage].name,
                      }

                      if (values.languages[selectedLanguage]?._id) {
                        updatingLanguage['_id'] = values.languages[selectedLanguage]._id
                      }

                      console.log("!@# #### updateMasterData", updatingLanguage);
                      dispatch(updateMasterDataLanguage({ languages: [updatingLanguage] }, history));
                    }

                  }}
                  innerRef={formRef}
                >
                  {(props) => (
                    <Form role="form" onSubmit={props.handleSubmit} onReset={props.handleReset}>
                      <br />
                      <hr className="my-2" />
                      <div>
                        <h5>Attend below items to have full localized experience:</h5>
                        <h6>
                          <ul>
                            <li>Add localization for ‘Assessment Management’</li>
                            <li>Add localization for ‘Recommendations’</li>
                            <li>Add localization in ‘Master Data’</li>
                            <li>Add internationalization resources in ‘Local Resources’</li>
                          </ul>
                        </h6>
                      </div>
                      <hr className="my-2" />
                      <Row>
                        <Col md="6">
                          <h6 className="heading-small mb-4" style={ChsStyles.SubTopic}>
                          ORDER BY: LANGUAGE CODE
                          </h6>
                        </Col>
                        <Col md="6">
                          <FormGroup style={{ marginBottom: 0 }}>
                            <InputGroup className="input-group-alternative">
                              <InputGroupAddon addonType="prepend">
                                <InputGroupText>
                                  <i className="ni ni-zoom-split-in" />
                                </InputGroupText>
                              </InputGroupAddon>
                              <Input
                                className="form-control-alternative"
                                placeholder="By Language Code or Name"
                                type="text"
                                onChange={(e) => {
                                  if (LOGINSTATE) console.log(e.target.value);
                                  setTxtFilter(e.target.value);
                                }}
                              />
                            </InputGroup>
                          </FormGroup>
                        </Col>
                      </Row>

                      <Table className="align-items-center table-flush" responsive>
                        <thead className="thead-light">
                          <tr>
                            <th scope="col">Language Code</th>
                            <th scope="col">Language Name</th>
                            <th scope="col">Direction</th>
                            <th scope="col">Internationalized?</th>
                            <th scope="col">Actions</th>
                          </tr>

                          {/* {currentLanguages.map((x, i) => { */}
                          {filteredContent.map((x, i) => {
                            return (
                              <tr>
                                <td >
                                  <TextField
                                    fullWidth
                                    // label={"2 Digit Code"}
                                    placeholder="Language Code"
                                    name="code" // this is to pass name through event-->e
                                    variant="outlined"
                                    value={x.code}
                                    onChange={(e) => handleLanguageChange(e, i, x)}
                                    onBlur={(e) => handleLanguageChange(e, i, x)}
                                    style={ChsStyles.Question}
                                    className={"form-control-alternative form-control-edit"}
                                    error={props.errors.languages && props.touched.languages && props.touched.languages[i]?.code && Boolean(props.errors.languages[i]?.code)}
                                    helperText={props.errors.languages && props.touched.languages && props.touched.languages[i]?.code ? props.errors.languages[i]?.code : ""}
                                    disabled={allLanguage.length > i}
                                  />
                                </td>
                                <td >
                                  <TextField
                                    fullWidth
                                    // label={"Language Name"}
                                    placeholder="Language Name"
                                    name="name" // this is to pass name through event-->e
                                    variant="outlined"
                                    value={x.name}
                                    onChange={(e) => handleLanguageChange(e, i, x)}
                                    onBlur={(e) => handleLanguageChange(e, i, x)}
                                    style={ChsStyles.Question}
                                    className={"form-control-alternative form-control-edit"}
                                    error={props.errors.languages && props.touched.languages && props.touched.languages[i]?.name && Boolean(props.errors.languages[i]?.name)}
                                    helperText={props.errors.languages && props.touched.languages && props.touched.languages[i]?.name ? props.errors.languages[i]?.name : ""}
                                  />
                                </td>
                                <td >
                                  <TextField
                                    select
                                    fullWidth
                                    // label={"Writing direction"}
                                    name="dir" // this is to pass name through event-->e
                                    variant="outlined"
                                    value={x.dir}
                                    onChange={(e) => handleLanguageChange(e, i, x)}
                                    onBlur={(e) => handleLanguageChange(e, i, x)}
                                    style={ChsStyles.Question}
                                    SelectProps={{
                                      native: true,
                                    }}
                                    className={"form-control-alternative form-control-edit"}
                                    error={props.errors.languages && props.touched.languages && props.touched.languages[i]?.dir && Boolean(props.errors.languages[i]?.dir)}
                                    helperText={props.errors.languages && props.touched.languages && props.touched.languages[i]?.dir ? props.errors.languages[i]?.dir : ""}
                                  >
                                    <option key={"ltr"} value={"ltr"}>
                                      Left to Right
                                    </option>
                                    <option key={"rtl"} value={"rtl"}>
                                      Right to Left
                                    </option>
                                  </TextField>
                                </td>
                                <td >
                                  <TextField
                                    select
                                    fullWidth
                                    // label={"Localized?"}
                                    name="int" // this is to pass name through event-->e
                                    variant="outlined"
                                    value={x.int}
                                    onChange={(e) => handleLanguageChange(e, i, x)}
                                    onBlur={(e) => handleLanguageChange(e, i, x)}
                                    style={x.int === 'Yes' ? { ...ChsStyles.Question, backgroundColor: '#FEE9E8' } : ChsStyles.Question}
                                    SelectProps={{
                                      native: true,
                                    }}
                                    className={"form-control-alternative form-control-edit"}
                                    error={props.errors.languages && props.touched.languages && props.touched.languages[i]?.int && Boolean(props.errors.languages[i]?.int)}
                                    helperText={props.errors.languages && props.touched.languages && props.touched.languages[i]?.int ? props.errors.languages[i]?.int : ""}
                                  >
                                    {int_language.map((item) => (
                                      <option key={item} value={item}>
                                        {item}
                                      </option>
                                    ))}
                                  </TextField>
                                </td>
                                <td >
                                  <div className="row">
                                    <Tooltip title="Save details" arrow>
                                      <div
                                        className="navbar-toggler"
                                        style={{ cursor: "pointer" }}
                                        onClick={(e) => {
                                          setSelectedLanguage(i)
                                          handleLanguageSaveClick(e, x)
                                        }}
                                      >
                                        <CheckCircleIcon style={{ color: GlobalStylesColor.mbppIconColorLight }} />
                                      </div>
                                    </Tooltip>
                                    {currentLanguages.length - 1 === i &&
                                      <Tooltip title="Add new language" arrow>
                                        <div
                                          className="navbar-toggler"
                                          style={{ cursor: "pointer" }}
                                          onClick={(e) => { handleLanguageAddClick() }}
                                        >
                                          <AddCircleOutlineIcon style={{ color: GlobalStylesColor.mbppIconColorLight }} />
                                        </div>
                                      </Tooltip>
                                    }
                                  </div>
                                </td>
                              </tr>
                            );
                          })}
                        </thead>
                      </Table>

                      <Row>
                        <Col lg="12" className="text-right">
                          {props.submitCount > 0 && !props.isValid ? (
                            <h6
                              small
                              style={{
                                fontWeight: "bold",
                                marginTop: 40,
                                marginBottom: -20,
                                color: "#af1e23",
                              }}
                            >
                              There are errors! Please correct and retry.
                            </h6>
                          ) : null}
                        </Col>
                      </Row>

                    </Form>
                  )}
                </Formik>
              </CardBody>

              {/* survey footer */}
              <CardFooter className="py-4">
                <Row>
                  <Col lg="6" className="text-left">
                    <Button
                      style={ChsStyles.GeneralButton}
                      color="primary"
                      onClick={() => {
                        window.history.back();
                      }}
                    >
                      BACK
                    </Button>
                  </Col>
                </Row>
              </CardFooter>
            </Card>
          </div>
        </Row>
      </Container>
    </>
  );
};

export default SurveyCreateTemplate;
