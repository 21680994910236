// Option 2
import { TextField, InputLabel, RtlTextField } from "../../../components/Custom/RTLTextField";

import { Grid, IconButton, InputAdornment, FormControl, Select, TextField as TextFieldDef, MenuItem } from "@material-ui/core";
import Visibility from "@material-ui/icons/Visibility";
import VisibilityOff from "@material-ui/icons/VisibilityOff";
import { signin, signUp } from "actions/user";
import { fetchOrganizationAllByCountryId } from "actions/organization";
import { fetchCountry } from "actions/masterData";
import { MbppStyles, ChsStyles } from "constants/globalStyles";
import { useFormik } from "formik";
import { countries } from "helper";
import { fetchLocalizationContent, fetchMasterDataLanguage } from "actions/masterData";
import { LANGUAGE } from "constants/actionTypes";
import { CryptoPassword, initialValues_assessmentPage, RTL_FLAG_RIGHT, RTL_FLAG_LEFT, SYS_DEFAULT_LANGUAGE_CODE } from "helper.js";
import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useTranslation, initReactI18next, Trans } from "react-i18next";
import { useHistory } from "react-router-dom";
import { confirmAlert } from "react-confirm-alert";
import "react-confirm-alert/src/react-confirm-alert.css"; // Import css
// reactstrap components
import { Button, Card, CardBody, CardFooter, Col, Form, FormGroup, Row, Pagination, PaginationItem, PaginationLink, Container } from "reactstrap";
import * as yup from "yup";
import Lodash from "lodash";
import PageHeader from "components/Headers/PageHeader.js";
import ReCaptchaV2 from "react-google-recaptcha";
import { APP_RECAPTCHA, LOGINSTATE } from "../../../config/config.json";
import { Warning } from "@material-ui/icons";
import { RECAPTCHA_LANG_LIST } from "helper";

// const validationSchemaSignUp = yup.object({
//   email: yup.string("Enter your email").email("Enter a valid email").required("Email is required"),
//   password: yup.string("Enter your password").min(3, "Password should be of minimum 8 characters length").required("Password is required"),
//   confirmPassword: yup.string("Passwords must match").test("passwords-match", "Passwords must match", function (value) {
//     return this.parent.password === value;
//   }),
//   firstname: yup.string("Enter your first name").max(255, "First name should be of maximum 255 characters length").required("First name is required"),
//   lastname: yup.string("Enter your last name").max(255, "Last name should be of maximum 255 characters length").required("Last name is required"),
//   country: yup.string("Enter the country of the organization").required("Country is required"),
//   organizationId: yup.string().when("organizationIdExist", {
//     is: true,
//     then: yup.string().required("Organization is required"),
//   }),
//   name: yup.string().when("nameExist", {
//     is: true,
//     then: yup.string().required("Organization is required"),
//   }),
//   cityId: yup.string().when("cityIdExist", {
//     is: true,
//     then: yup.string().required("Location is required"),
//   }),
//   city: yup.string().when("cityExist", {
//     is: true,
//     then: yup.string().required("Location is required"),
//   }),
// });

const Register = () => {
  // dispatch and history
  const dispatch = useDispatch();
  const history = useHistory();
  // set state contact number list
  const [contactList, setContactList] = useState([{ index: 0, contactno: "" }]);
  // set state signup /signin
  const [isSignup, setIsSignup] = useState(true);

  const [rtlAlignment, setRtlAlignment] = useState(RTL_FLAG_LEFT);

  const { t, i18n } = useTranslation();

  // handle click event of password visible and password dotted
  const [showPassword, setShowPassword] = useState(false);
  const handleShowPassword = () => setShowPassword(!showPassword);

  const [isNewOrganization, setIsNewOrganization] = useState(false);
  const [isNewCity, setIsNewCity] = useState(false);
  const [cityArray, setCityArray] = useState([]);
  const [isRegEnable, setIsRegEnable] = useState(false);
  const [isRegToken, setIsRegToken] = useState("");
  const [refreshCounter, setRefreshCounter] = useState(0);
  const [refresh, setRefresh] = useState(0);

  const [systemContentLocale, setSystemContentLocale] = useState({});
  const [recaptchaLanguage, setRecaptchaLanguage] = useState(null);

  const allOrg = useSelector((state) => state.organization.organizationAllByCountryId);
  if (LOGINSTATE) console.log("allOrg", allOrg);

  const allCountry = useSelector((state) => state.masterData.countryAll);
  if (LOGINSTATE) console.log("allCountry", allCountry);

  useEffect(() => {
    dispatch(fetchCountry());
    setRefreshCounter(refreshCounter + 1);
  }, []);

  // const languageFromLocal = useSelector((state) => state.masterData.localLanguage);
  const userLang = useSelector((state) => state.masterData.languageByCode);
  if (LOGINSTATE) console.log("userLanguage", userLang);

  useEffect(() => {
    if (userLang && userLang.language && userLang.language.length > 0) {
      const lang = userLang.language[0]

      const params = { languageCode: lang.code };
      dispatch(fetchLocalizationContent(params, history));

      i18n.changeLanguage(lang.code);
      // reCAPTCHA languages as per 2022-August
      setRecaptchaLanguage(Lodash.indexOf(RECAPTCHA_LANG_LIST, lang.code) >= 0 ? lang.code : SYS_DEFAULT_LANGUAGE_CODE);

      if (lang && lang.dir === "rtl") {
        setRtlAlignment(RTL_FLAG_RIGHT);
      } else {
        setRtlAlignment(RTL_FLAG_LEFT);
      }
    }
    else {
      // To fix location reload, may case issue with recaptcha re-render. test didn't show any issue
      i18n.changeLanguage(SYS_DEFAULT_LANGUAGE_CODE);
      setRecaptchaLanguage(SYS_DEFAULT_LANGUAGE_CODE);
      setRtlAlignment(RTL_FLAG_LEFT);
    }

  }, [userLang]);

  const systemContent = useSelector((state) => state.masterData.localizationContentAll);
  useEffect(() => {
    if (LOGINSTATE) console.log("systemContent from server: ", systemContent);
    if (systemContent !== undefined && systemContent !== null && Lodash.isArray(systemContent) && systemContent.length > 0) {
      setSystemContentLocale(systemContent[0].assessmentPage);
    } else {
      setSystemContentLocale(initialValues_assessmentPage);
    }
  }, [systemContent]);

  const handleOrganizationClick = (e, countryId) => {
    if (LOGINSTATE) console.log("countryId", e.target.value);
    const country = Lodash.find(allCountry, { _id: e.target.value });
    if (LOGINSTATE) console.log("country", country);
    if (country && country.status === false) {
      e.preventDefault();
      confirmAlert({
        title: t("assessmentPage.error"),
        message: t("popups.countryUnavailable"),
        buttons: [
          {
            label: t("assessmentPage.app_ok"),
            onClick: () => { },
          },
        ],
        closeOnEscape: false,
        closeOnClickOutside: false,
      });
    } else if (country) {
      formik.handleChange(e);
      dispatch(fetchOrganizationAllByCountryId({ countryId: e.target.value }, history, t));
      if (LOGINSTATE) console.log("dispatch call");
      if (LOGINSTATE) console.log("allOrg dispatch", allOrg);
    }
  };

  const charactersCheck = (e) => {
    const re = /^[a-z\d\-_\s]+$/i;
    if (!re.test(e.key)) {
      e.preventDefault();
    }
  };

  const handleExpire = () => {
    setIsRegEnable(false);
  };

  const handleCity = (e, organizationId) => {
    if (LOGINSTATE) console.log("cityId", e.target.value);

    const org = Lodash.find(allOrg, { _id: organizationId });

    const city = Lodash.find(org.city, { _id: e.target.value });

    if (!city?.linkedUser) {
      if (LOGINSTATE) console.log("linkedUser");
      formik.values.cityIdExist = true;
      formik.handleChange(e);
      // formik.values.cityId = cityId;
    } else {
      e.preventDefault();
      formik.values.cityIdExist = false;
      confirmAlert({
        title: t("assessmentPage.error"),
        message: t("popups.registeredLocation"),
        buttons: [
          {
            label: t("assessmentPage.app_ok"),
            onClick: () => { },
          },
        ],
        closeOnEscape: false,
        closeOnClickOutside: false,
      });
    }
  };

  function handleChange1(e) {
    if (LOGINSTATE) console.log("handleChange1", e.target.value);

    formik.values.organizationId = e.target.value;
    const org = Lodash.find(allOrg, { _id: e.target.value });
    if (LOGINSTATE) console.log("organization", org);
    if (org && org.status === true) {
      formik.values.organizationIdExist = true;
      formik.handleChange(e);
      setCityArray(org.city);
      if (LOGINSTATE) console.log("org.city", org.city);
      if (LOGINSTATE) console.log("cityArray", cityArray);
    } else {
      e.preventDefault();
      formik.values.organizationIdExist = false;
      confirmAlert({
        title: t("assessmentPage.error"),
        message: t("popups.organizationUnavailable"),
        buttons: [
          {
            label: t("assessmentPage.app_ok"),
            onClick: () => { },
          },
        ],
        closeOnEscape: false,
        closeOnClickOutside: false,
      });
    }
  }

  const WarningMsg = () => {
    confirmAlert({
      title: t('assessmentPage.note'),
      message: t("popups.note"),
      buttons: [
        {
          label: t("assessmentPage.app_ok"),
          onClick: () => { },
        },
      ],
      closeOnEscape: false,
      closeOnClickOutside: false,
    });
  };

  const formik = useFormik({
    initialValues: {
      organizationId: "",
      firstname: "",
      lastname: "",
      email: "",
      password: "",
      confirmPassword: "",
      emp_designation: "",
      name: "",
      organizationIdExist: false,
      nameExist: false,
      language: userLang && userLang.language && userLang.language.length > 0 ? userLang.language[0].code : SYS_DEFAULT_LANGUAGE_CODE,
      emp_address: "",

      country: "",
      city: "",
      cityId: "",
      cityIdExist: false,
      cityExist: false,
      recapture: "",
    },
    validationSchema: yup.object({
      email: yup.string(t("registerPage.section2.enterEmail"))
        .email(t("registerPage.section2.emailInvalid"))
        .required(t("registerPage.section2.emailRequired")),
      password: yup.string(t("registerPage.section2.enterPassword"))
        .min(6, t("registerPage.section2.parameterLengthMin"))
        .required(t("registerPage.section2.passwordRequired")),
      confirmPassword: yup.string(t("registerPage.section2.enterPassword"))
        .test("passwords-match", t("registerPage.section2.passwordMismatch"), function (value) {
          return this.parent.password === value;
        }),
      firstname: yup.string(t("registerPage.section2.enterFirstName"))
        .max(255, t("registerPage.section2.parameterLengthMax"))
        .required(t("registerPage.section2.firstNameRequired")),
      lastname: yup.string(t("registerPage.section2.enterLastName"))
        .max(255, t("registerPage.section2.parameterLengthMax"))
        .required(t("registerPage.section2.lastNameRequired")),
      country: yup.string(t("registerPage.section2.enterCountry"))
        .required(t("registerPage.section2.countryRequired")),
      organizationId: yup.string().when("organizationIdExist", {
        is: true,
        then: yup.string().required(t("registerPage.section2.organizationRequired")),
      }),
      name: yup.string().when("nameExist", {
        is: true,
        then: yup.string().required(t("registerPage.section2.organizationRequired")),
      }),
      cityId: yup.string().when("cityIdExist", {
        is: true,
        then: yup.string().required(t("registerPage.section2.locationRequired")),
      }),
      city: yup.string().when("cityExist", {
        is: true,
        then: yup.string().required(t("registerPage.section2.locationRequired")),
      }),
    }),
    onSubmit: (values, onSubmitProps) => {
      const valuesParam = { ...values }
      if (LOGINSTATE) console.log("valuesParam", valuesParam);
      if (isSignup) {
        valuesParam.password = CryptoPassword(valuesParam.password);

        if (LOGINSTATE) console.log("isNewOrganization", isNewOrganization);
        if (LOGINSTATE) console.log("isNewCity", isNewCity);

        if (isNewOrganization === false && isNewCity === false && valuesParam.cityId && !Lodash.isEmpty(valuesParam.cityId)) {
          // unlinked user, cityId should exist
          if (LOGINSTATE) console.log("city id", valuesParam.cityId);
          let id = valuesParam.cityId;
          let updateCityArray = Lodash.find(cityArray, {
            _id: valuesParam.cityId,
          });
          updateCityArray["linkedUser"] = id;
          if (LOGINSTATE) console.log("updateCityArray", updateCityArray);

          valuesParam["city"] = cityArray;
          if (LOGINSTATE) console.log("city array", cityArray);
          dispatch(signUp(valuesParam, history, systemContentLocale));

        } else if (isNewOrganization === false && isNewCity === true) {
          if (LOGINSTATE) console.log("city name", valuesParam.city);

          let duplicate = Lodash.findIndex(cityArray, function (chr) {
            return chr.name.toLocaleLowerCase() === valuesParam.city.toLocaleLowerCase();
          });
          if (LOGINSTATE) console.log("Duplicate city: ", duplicate);
          if (duplicate === -1) {
            let param = [...cityArray, { name: valuesParam.city }];
            valuesParam["city"] = param;

            if (LOGINSTATE) console.log("valuesProcess", valuesParam);
            dispatch(signUp(valuesParam, history, systemContentLocale));
          } else {
            if (LOGINSTATE) console.log("Duplicate city", duplicate);
            confirmAlert({
              title: t("assessmentPage.error"),
              message: t("popups.registeredLocation2"),
              buttons: [
                {
                  label: t("assessmentPage.app_ok"),
                  onClick: () => {
                    window.location.reload(true);
                  },
                },
              ],
              closeOnEscape: false,
              closeOnClickOutside: false,
            });
          }
        } else if (isNewOrganization === true && isNewCity === true) {
          if (LOGINSTATE) console.log("New user");

          let duplicate = Lodash.findIndex(allOrg, function (chr) {
            return chr.name.toLocaleLowerCase() === valuesParam.name.toLocaleLowerCase();
          });
          if (LOGINSTATE) console.log("Duplicate city ****** ", duplicate);
          if (duplicate === -1) {
            let uptedcity = [
              {
                name: valuesParam.city,
              },
            ];
            valuesParam["city"] = uptedcity;
            dispatch(signUp(valuesParam, history, systemContentLocale));
          } else {
            if (LOGINSTATE) console.log("Duplicate ", duplicate);
            confirmAlert({
              title: t("assessmentPage.error"),
              message: t('popups.organizationExist'),
              buttons: [
                {
                  label: t("assessmentPage.app_ok"),
                  onClick: () => {
                    window.location.reload(true);
                  },
                },
              ],
              closeOnEscape: false,
              closeOnClickOutside: false,
            });
          }
        } else {
          if (LOGINSTATE) console.log("else con");
        }
      } else {
        valuesParam.password = CryptoPassword(valuesParam.password);
        dispatch(signin(valuesParam, history, systemContentLocale));
      }
      if (LOGINSTATE) console.log(valuesParam);
    },
    onReset: () => { },
  });

  return (
    <>
      <PageHeader />
      {/* Page content */}
      <Container className="mt--7" fluid>
        <Row className="justify-content-center pb-5">
          <Col lg={isSignup ? "6" : "5"} md={isSignup ? "8" : "7"}>
            <Card>
              <CardBody className="px-lg-4 py-lg-4">
                {rtlAlignment === RTL_FLAG_RIGHT ? (
                  <div className="heading-title text-center mb-4" style={{ color: "#af1e23", fontWeight: "bold", textAlign: "right" }}>
                    <span>{isSignup ? t("registerPage.section1.title") : t("registerPage.section1.title")}</span>
                  </div>
                ) : (
                  <div className="heading-title text-center mb-4" style={MbppStyles.Topic}>
                    <span>{isSignup ? t("registerPage.section1.title") : t("registerPage.section1.title")}</span>
                  </div>
                )}
                <br />

                <Form role="form" onSubmit={formik.handleSubmit} onReset={formik.handleReset}>
                  <FormGroup className="mb-3 ">
                    {rtlAlignment === RTL_FLAG_RIGHT
                      ? isSignup && (
                        <>
                          <hr className="my-2" />
                          <h6 className="heading-small mb-4" style={{ color: "#fdba2b", marginTop: "16px", marginBottom: "32px", fontWeight: "900!important", textAlign: "right" }}>
                            {t("registerPage.section1.title1")}
                          </h6>
                        </>
                      )
                      : isSignup && (
                        <>
                          <hr className="my-2" />
                          <h6 className="heading-small mb-4" style={{ color: "#fdba2b", marginTop: "16px", marginBottom: "32px", fontWeight: "900!important", textAlign: "left" }}>
                            {t("registerPage.section1.title1")}
                          </h6>
                        </>
                      )}
                    <Grid container spacing={1} alignItems="center" style={MbppStyles.Grid}>
                      <Grid item xs={1} sm={1}>
                        <i className="ni ni-email-83" />
                      </Grid>
                      <Grid item xs={11} sm={11}>
                        <RtlTextField
                          fullWidth
                          rtol={rtlAlignment === RTL_FLAG_RIGHT}
                          id="firstname"
                          name="firstname"
                          label={t("registerPage.section1.firstName") + " *"}
                          variant="outlined"
                          value={formik.values.firstname}
                          onChange={formik.handleChange}
                          className={"form-control-alternative form-control-edit"}
                          error={formik.touched.firstname && Boolean(formik.errors.firstname)}
                          helperText={formik.touched.firstname && formik.errors.firstname}
                        />
                      </Grid>
                    </Grid>
                    <Grid container spacing={1} alignItems="center" style={MbppStyles.Grid}>
                      <Grid item xs={1} sm={1}>
                        <i className="ni ni-lock-circle-open" />
                      </Grid>
                      <Grid item xs={11} sm={11}>
                        <RtlTextField
                          fullWidth
                          rtol={rtlAlignment === RTL_FLAG_RIGHT}
                          id="lastname"
                          name="lastname"
                          label={t("registerPage.section1.lastName") + " *"}
                          variant="outlined"
                          value={formik.values.lastname}
                          onChange={formik.handleChange}
                          className={"form-control-alternative form-control-edit"}
                          error={formik.touched.lastname && Boolean(formik.errors.lastname)}
                          helperText={formik.touched.lastname && formik.errors.lastname}
                        />
                      </Grid>
                    </Grid>
                  </FormGroup>

                  <FormGroup className="mb-3 ">
                    {rtlAlignment === RTL_FLAG_RIGHT
                      ? isSignup && (
                        <>
                          <hr className="my-2" />
                          <h6 className="heading-small mb-4" style={{ color: "#fdba2b", marginTop: "16px", marginBottom: "32px", fontWeight: "900!important", textAlign: "right" }}>
                            {t("registerPage.section1.title2")}
                          </h6>
                        </>
                      )
                      : isSignup && (
                        <>
                          <hr className="my-2" />
                          <h6 className="heading-small mb-4" style={{ color: "#fdba2b", marginTop: "16px", marginBottom: "32px", fontWeight: "900!important", textAlign: "left" }}>
                            {t("registerPage.section1.title2")}
                          </h6>
                        </>
                      )}
                    <Grid container spacing={1} alignItems="center" style={MbppStyles.Grid}>
                      <Grid item xs={1} sm={1}>
                        <i className="ni ni-email-83" />
                      </Grid>
                      <Grid item xs={11} sm={11}>
                        <RtlTextField
                          fullWidth
                          rtol={rtlAlignment === RTL_FLAG_RIGHT}
                          id="email"
                          name="email"
                          label={t("registerPage.section1.email") + " *"}
                          variant="outlined"
                          value={formik.values.email}
                          onChange={formik.handleChange}
                          className={"form-control-alternative form-control-edit"}
                          error={formik.touched.email && Boolean(formik.errors.email)}
                          helperText={formik.touched.email && formik.errors.email}
                        />
                      </Grid>
                    </Grid>
                    <Grid container spacing={1} alignItems="center" style={MbppStyles.Grid}>
                      <Grid item xs={1} sm={1}>
                        <i className="ni ni-lock-circle-open" />
                      </Grid>
                      <Grid item xs={11} sm={11}>
                        <RtlTextField
                          fullWidth
                          rtol={rtlAlignment === RTL_FLAG_RIGHT}
                          id="password"
                          name="password"
                          label={t("registerPage.section1.password") + " *"}
                          variant="outlined"
                          type={showPassword ? "text" : "password"}
                          value={formik.values.password}
                          onChange={formik.handleChange}
                          length={10}
                          className={"form-control-alternative form-control-edit"}
                          error={formik.touched.password && Boolean(formik.errors.password)}
                          helperText={formik.touched.password && formik.errors.password}
                          InputProps={
                            showPassword !== null && rtlAlignment === RTL_FLAG_RIGHT
                              ? {
                                startAdornment: (
                                  <InputAdornment position="start">
                                    <IconButton onClick={handleShowPassword}>{showPassword ? <Visibility /> : <VisibilityOff />}</IconButton>
                                  </InputAdornment>
                                ),
                              }
                              : showPassword !== null && rtlAlignment === RTL_FLAG_LEFT
                                ? {
                                  endAdornment: (
                                    <InputAdornment position="end">
                                      <IconButton onClick={handleShowPassword}>{showPassword ? <Visibility /> : <VisibilityOff />}</IconButton>
                                    </InputAdornment>
                                  ),
                                }
                                : null
                          }
                        />
                      </Grid>
                    </Grid>
                    {isSignup && (
                      <>
                        <Grid container spacing={1} alignItems="center" style={MbppStyles.Grid}>
                          <Grid item xs={1} sm={1}>
                            <i className="ni ni-lock-circle-open" />
                          </Grid>
                          <Grid item xs={11} sm={11}>
                            <RtlTextField
                              fullWidth
                              rtol={rtlAlignment === RTL_FLAG_RIGHT}
                              id="confirmPassword"
                              name="confirmPassword"
                              label={t("registerPage.section1.confirmPassword") + " *"}
                              variant="outlined"
                              type={showPassword ? "text" : "password"}
                              value={formik.values.confirmPassword}
                              onChange={formik.handleChange}
                              className={"form-control-alternative form-control-edit"}
                              error={formik.touched.confirmPassword && Boolean(formik.errors.confirmPassword)}
                              helperText={formik.touched.confirmPassword && formik.errors.confirmPassword}
                            />
                          </Grid>
                        </Grid>
                        <Grid container spacing={1} alignItems="center" style={MbppStyles.Grid}>
                          <Grid item xs={1} sm={1}></Grid>
                          <Grid item xs={11} sm={11}>
                            <p className="small mb-4">{t("registerPage.section1.section2")}</p>
                          </Grid>
                        </Grid>
                      </>
                    )}
                  </FormGroup>

                  {isSignup && (
                    <>
                      <br />
                      <hr className="my-2" />
                      {rtlAlignment === RTL_FLAG_RIGHT ? (
                        <h6 className="heading-small mb-4" style={{ color: "#fdba2b", marginTop: "16px", marginBottom: "32px", fontWeight: "900!important", textAlign: "right" }}>
                          {t("registerPage.section1.title3")}
                        </h6>
                      ) : (
                        <h6 className="heading-small mb-4" style={MbppStyles.SubTopic}>
                          {t("registerPage.section1.title3")}
                        </h6>
                      )}
                      <FormGroup className="mb-3">
                        <>
                          <Grid container spacing={1} alignItems="center" style={{}}>
                            <Grid item xs={1} sm={1}>
                              <i className="fa fa-globe-asia" />
                            </Grid>
                            <Grid item xs={11} sm={11} style={{ textAlign: "right" }}>
                              <RtlTextField
                                select
                                rtol={rtlAlignment === RTL_FLAG_RIGHT}
                                style={ChsStyles.Input}
                                fullWidth
                                id="country"
                                name="country"
                                label={t("registerPage.section1.country") + " *"}
                                variant="outlined"
                                value={formik.values.country}
                                onClick={(e) => (formik.values.organizationId = "")}
                                onChange={(e) => {
                                  handleOrganizationClick(e, formik.values.country);
                                }}
                                SelectProps={{
                                  native: true,
                                }}
                                className={"form-control-alternative form-control-edit"}
                                error={formik.touched.country && Boolean(formik.errors.country)}
                                helperText={formik.touched.country && formik.errors.country}
                                InputProps={
                                  rtlAlignment === RTL_FLAG_RIGHT
                                    ? {
                                      startAdornment: (
                                        <InputAdornment position="start">
                                        </InputAdornment>
                                      ),
                                    }
                                    : null
                                }
                              >
                                <option></option>
                                {Lodash.filter(allCountry, { status: true }).map((key) => (
                                  <option style={{ textAlign: rtlAlignment === RTL_FLAG_RIGHT ? "right" : "left" }} key={key._id} value={key._id}>
                                    {key.name}
                                  </option>
                                ))}
                              </RtlTextField>
                            </Grid>
                          </Grid>
                          <Grid container spacing={1} alignItems="center" style={MbppStyles.Grid}>
                            <Grid item xs={1} sm={1}></Grid>
                            <Grid item xs={11} sm={11}>
                              <p className="small">{t("registerPage.section1.countryNote")}</p>
                            </Grid>
                          </Grid>
                        </>

                        {/* if country is selected, list down the organizations for it */}
                        {Lodash.isEmpty(formik.values.country) ? null : (
                          <>
                            <>
                              <Grid container spacing={1} alignItems="center" style={{}}>
                                <Grid item xs={1} sm={1}>
                                  <i className="fa fa-archway" />
                                </Grid>
                                <Grid
                                  item
                                  xs={11}
                                  sm={11}
                                  style={{
                                    display: isNewOrganization ? "none" : "block",
                                    textAlign: "right",
                                  }}
                                >
                                  <RtlTextField
                                    select
                                    rtol={rtlAlignment === RTL_FLAG_RIGHT}
                                    style={ChsStyles.Input}
                                    fullWidth
                                    id="organizationId"
                                    name="organizationId"
                                    label={t("registerPage.section1.organization") + " *"}
                                    variant="outlined"
                                    value={formik.values.organizationId}
                                    onChange={(e) => handleChange1(e)}
                                    SelectProps={{
                                      native: true,
                                    }}
                                    className={"form-control-alternative form-control-edit"}
                                    error={formik.touched.organizationId && Boolean(formik.errors.organizationId)}
                                    helperText={formik.touched.organizationId && formik.errors.organizationId}
                                    InputProps={
                                      rtlAlignment === RTL_FLAG_RIGHT
                                        ? {
                                          startAdornment: (
                                            <InputAdornment position="start">
                                            </InputAdornment>
                                          ),
                                        }
                                        : null
                                    }
                                  >
                                    <option></option>
                                    {allOrg.map((key) => (
                                      <option style={{ textAlign: rtlAlignment === RTL_FLAG_RIGHT ? "right" : "left" }} key={key._id} value={key._id}>
                                        {key.name}
                                      </option>
                                    ))}
                                  </RtlTextField>
                                </Grid>

                                <Grid
                                  item
                                  xs={11}
                                  sm={11}
                                  style={{
                                    display: isNewOrganization ? "block" : "none",
                                  }}
                                >
                                  <RtlTextField
                                    fullWidth
                                    rtol={rtlAlignment === RTL_FLAG_RIGHT}
                                    id="name"
                                    name="name"
                                    label={t("registerPage.section1.organization") + " *"}
                                    variant="outlined"
                                    value={formik.values.name}
                                    onChange={formik.handleChange}
                                    onKeyPress={(e) => charactersCheck(e)}
                                    className={"form-control-alternative form-control-edit"}
                                    error={formik.touched.name && Boolean(formik.errors.name)}
                                    helperText={formik.touched.name && formik.errors.name}
                                  />
                                </Grid>
                              </Grid>

                              <Grid container spacing={1} alignItems="center" style={{}}>
                                <Grid item xs={1} sm={1}></Grid>
                                <Grid item xs={11} sm={11}>
                                  <p className="small" style={{ marginBottom: 5 }}>
                                    {t("registerPage.section1.section3")}
                                  </p>
                                </Grid>
                              </Grid>
                              <Grid container spacing={1} alignItems="center" style={MbppStyles.Grid}>
                                <Grid item xs={1} sm={1}></Grid>
                                <Grid
                                  item
                                  xs={11}
                                  sm={11}
                                  style={{
                                    display: isNewOrganization ? "none" : "block",
                                  }}
                                >
                                  <Pagination className="pagination justify-content-left mb-0" listClassName="justify-content-end mb-0">
                                    <PaginationItem style={{ marginTop: -5 }}>
                                      <PaginationLink
                                        href="#"
                                        onClick={(e) => {
                                          e.preventDefault();
                                          setIsNewOrganization(true);
                                          setIsNewCity(true);
                                          formik.values.organizationIdExist = false;
                                          formik.values.organizationId = "";
                                          formik.values.nameExist = true;
                                          formik.values.cityExist = true;
                                          WarningMsg();
                                        }}
                                      >
                                        <i className="fas fa-plus" />
                                        <span className="sr-only">Add</span>
                                      </PaginationLink>
                                    </PaginationItem>
                                    <PaginationItem style={{ marginLeft: 5 }}>{t("registerPage.section1.addOrganization")}</PaginationItem>
                                  </Pagination>
                                </Grid>
                              </Grid>
                            </>
                          </>
                        )}

                        {/* {Lodash.isEmpty(formik.values.organizationId) ? null : ( */}

                        <>
                          <Grid container spacing={1} alignItems="center" style={{}}>
                            <Grid item xs={1} sm={1}>
                              <i className="fa fa-city" />
                            </Grid>

                            <Grid
                              item
                              xs={11}
                              sm={11}
                              style={{
                                display: isNewCity ? "none" : "block",
                                textAlign: "right",
                              }}
                            >
                              <RtlTextField
                                select
                                rtol={rtlAlignment === RTL_FLAG_RIGHT}
                                style={ChsStyles.Input}
                                fullWidth
                                id="cityId"
                                name="cityId"
                                label={t("registerPage.section1.location") + " *"}
                                variant="outlined"
                                value={formik.values.cityId}
                                onChange={(e) => handleCity(e, formik.values.organizationId)}
                                SelectProps={{
                                  native: true,
                                }}
                                className={"form-control-alternative form-control-edit"}
                                error={formik.touched.cityId && Boolean(formik.errors.cityId)}
                                helperText={formik.touched.cityId && formik.errors.cityId}
                                InputProps={
                                  rtlAlignment === RTL_FLAG_RIGHT
                                    ? {
                                      startAdornment: (
                                        <InputAdornment position="start">
                                        </InputAdornment>
                                      ),
                                    }
                                    : null
                                }
                              >
                                <option></option>
                                {allOrg &&
                                  formik.values.organizationId &&
                                  Lodash.find(allOrg, { _id: formik.values.organizationId }).city.map((key, i) => (
                                    <option style={{ textAlign: rtlAlignment === RTL_FLAG_RIGHT ? "right" : "left" }} key={key._id} value={key._id}>
                                      {key.name}
                                    </option>
                                  ))}
                              </RtlTextField>
                            </Grid>

                            <Grid
                              item
                              xs={11}
                              sm={11}
                              style={{
                                display: isNewCity ? "block" : "none",
                              }}
                            >
                              <RtlTextField
                                fullWidth
                                rtol={rtlAlignment === RTL_FLAG_RIGHT}
                                id="city"
                                name="city"
                                label={t("registerPage.section1.location") + " *"}
                                variant="outlined"
                                value={formik.values.city}
                                onChange={formik.handleChange}
                                onKeyPress={(e) => charactersCheck(e)}
                                className={"form-control-alternative form-control-edit"}
                                error={formik.touched.city && Boolean(formik.errors.city)}
                                helperText={formik.touched.city && formik.errors.city}
                              />
                            </Grid>
                          </Grid>
                          <Grid container spacing={1} alignItems="center" style={{}}>
                            <Grid item xs={1} sm={1}></Grid>
                            <Grid item xs={11} sm={11}>
                              <p className="small" style={{ marginBottom: 5 }}>
                                {t("registerPage.section1.section4")}
                              </p>
                            </Grid>
                          </Grid>
                          <Grid container spacing={1} alignItems="center" style={MbppStyles.Grid}>
                            <Grid item xs={1} sm={1}></Grid>
                            <Grid
                              item
                              xs={11}
                              sm={11}
                              style={{
                                display: isNewCity ? "none" : "block",
                              }}
                            >
                              <Pagination className="pagination justify-content-left mb-0" listClassName="justify-content-end mb-0">
                                <PaginationItem style={{ marginTop: -5 }}>
                                  <PaginationLink
                                    href="#"
                                    onClick={(e) => {
                                      e.preventDefault();
                                      setIsNewCity(true);
                                      formik.values.cityIdExist = false;
                                      formik.values.cityId = "";
                                      formik.values.cityExist = true;
                                      WarningMsg();
                                    }}
                                  >
                                    <i className="fas fa-plus" />
                                    <span className="sr-only">Add</span>
                                  </PaginationLink>
                                </PaginationItem>
                                <PaginationItem style={{ marginLeft: 5 }}>{t("registerPage.section1.addLocation")}</PaginationItem>
                              </Pagination>
                            </Grid>
                          </Grid>
                        </>
                      </FormGroup>
                    </>
                  )}

                  <br />
                  <hr className="my-2" />
                  <p className="text-center small" style={{ marginBottom: 20, color: "#af1e23" }}>
                    {t("registerPage.section1.note")}
                  </p>

                  <div className="text-center" style={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                    {recaptchaLanguage
                      && <ReCaptchaV2
                        sitekey={APP_RECAPTCHA.SITE_KEY}
                        onChange={(e) => {
                          // if condition to check e null or not, if it is null msg
                          setIsRegEnable(true);
                          setIsRegToken(e);
                          formik.values.recapture = e;
                        }}
                        hl={recaptchaLanguage}
                        render="explicit"
                        onExpired={handleExpire}
                      />}
                  </div>

                  <CardFooter className="py-4">
                    <Row>
                      <Col lg="12" className={rtlAlignment === RTL_FLAG_RIGHT ? "text-right" : "text-left"}>
                        {formik.submitCount - 4 > 0 && formik.isValid ? (
                          <h4
                            // small
                            style={{
                              fontWeight: "bold",
                              marginTop: 40,
                              marginBottom: -10,
                              color: "#af1e23",
                            }}
                          >
                            {/* There are errors! Please correct and retry. */}
                            * {t('registerPage.section2.valueIsRequired')}
                          </h4>
                        ) : null}
                      </Col>
                    </Row>
                    <br />
                    <Row>
                      <Col lg="12" className="text-center">
                        <Button
                          style={ChsStyles.GeneralButton}
                          color="primary"
                          variant="contained"
                          type="reset"
                          onClick={() => {
                            window.location.reload(true);
                          }}
                        >
                          {t("registerPage.section1.reset")}
                        </Button>

                        <Button style={ChsStyles.GeneralButton} color="primary" variant="contained" type="submit" id="123" disabled={!isRegEnable}>
                          {t("registerPage.section1.register")}
                        </Button>
                      </Col>
                    </Row>
                  </CardFooter>
                </Form>
              </CardBody>
            </Card>

          </Col>
        </Row>
      </Container>
    </>
  );
};

export default Register;
