// node.js library that concatenates classes (strings)
import classnames from "classnames";
import Header from "components/Headers/Header.js";
import AdminHeader from "components/Headers/AdminHeader.js";
import { LOGINSTATE } from "../config/config.json";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
// import { fetchStatSummery } from 'actions/analytics'
// reactstrap components
import { Card, CardBody, CardHeader, Col, Container, Nav, NavItem, NavLink, Row, Table, CardTitle } from "reactstrap";

import Chart from "chart.js"; // javascipt plugin for creating charts. Does the style formatting
import { Bar, Line, HorizontalBar } from "react-chartjs-2"; // react plugin used to create charts
import { chartOptions, getLineChart, getBarChart, parseOptions, setInitData } from "variables/charts";
import { fetchAnswerStat } from "actions/report";
import { fetchCountry } from "actions/masterData";
import Lodash from "lodash";
import { roundValue } from "helper";
import { GlobalStylesColor } from "constants/globalStyles";

const Index = (props) => {
  const dispatch = useDispatch();
  const history = useHistory();

  const [barChart, setBarChart] = useState({});

  /////////////////////////////////
  const [statOrganization, setStatOrganization] = useState([]);
  const [statYear, setStatYear] = useState([]);

  const [statType_CHSAT_COMPLETE, setStatType_CHSAT_COMPLETE] = useState("");
  const [statType_ICAT_COMPLETE, setStatType_ICAT_COMPLETE] = useState("");
  const [statType_TIE_COMPLETE, setStatType_TIE_COMPLETE] = useState("");
  const [statType_CHSAT_PROGRESS, setStatType_CHSAT_PROGRESS] = useState("");
  const [statType_ICAT_PROGRESS, setStatType_ICAT_PROGRESS] = useState("");
  const [statType_TIE_PROGRESS, setStatType_TIE_PROGRESS] = useState("");

  const optionsGrid = {
    options: {
      indexAxis: "x",
    },
    scales: {
      xAxes: [
        {
          gridLines: {
            color: "#212529",
            zeroLineColor: "#212529",
          },
          ticks: {
            callback: function (value) {
              if (!(value % 1)) {
                return value;
              }
            },
          },
        },
      ],
    },
  };

  useEffect(() => {
    let params = { statType: "StatDashboard" };
    dispatch(fetchAnswerStat(params));
  }, []);

  const currentStat = useSelector((state) => state.report.answerStat);
  useEffect(() => {
    if (LOGINSTATE) console.log("currentStat: ", currentStat);
    if (currentStat && currentStat.length > 0 && !Lodash.isEmpty(currentStat[0])) {
      //Current stat is an array
      let data = currentStat[0];

      // populate tiles with type  ---------------------------------------
      setStatType_CHSAT_COMPLETE(data.surveyTiles.chsat_complete);
      setStatType_ICAT_COMPLETE(data.surveyTiles.icat_complete);
      setStatType_TIE_COMPLETE(data.surveyTiles.tie_complete);
      setStatType_CHSAT_PROGRESS(data.surveyTiles.chsat_progress);
      setStatType_ICAT_PROGRESS(data.surveyTiles.icat_progress);
      setStatType_TIE_PROGRESS(data.surveyTiles.tie_progress);

      // Draw graph with year data  ---------------------------------------
      setBarChart(data.surveyGraph[0].chart ? data.surveyGraph[0].chart : {});
      if (LOGINSTATE) console.log("barchart", data.surveyGraph);

      // populate table with year data  ---------------------------------------
      setStatYear(data.surveyYear);
      if (LOGINSTATE) console.log("year data: ", data.surveyYear);

      // populate table with organization data  ---------------------------------------
      setStatOrganization(data.surveyOrganization);
    }
  }, [currentStat]);

  if (window.Chart) {
    parseOptions(Chart, chartOptions());
  }

  // const toggleNavs = (e, index) => {
  //   e.preventDefault();
  //   console.log("###### index: ", index);

  //   setActiveNav(index);
  //   setChart1Data("data" + index);
  // };
  return (
    <>
      {/* Header content */}
      <AdminHeader />

      {/* Page content */}
      <Container className="mt--7" fluid>
        <div className="header-body">
          {/* Card stats */}
          <br />
          <br />
          <br />
          <Row>
            <Col lg="6" xl="4">
              <Card
                className="card-stats mb-4 mb-xl-0"
                onClick={(e) => {
                  history.push({ pathname: "/registered/assessment_answers" });
                }}
                style={{ cursor: "pointer" }}
              >
                <CardBody>
                  <Row>
                    <div className="col">
                      <CardTitle tag="h5" className="text-uppercase text-muted mb-0">
                        <b>Core Humanitarian Standard Assessment Tool</b> <br />
                        <br />
                        COMPLETED TEST
                      </CardTitle>
                      <span className="h2 font-weight-bold mb-0">{statType_CHSAT_COMPLETE}</span>
                    </div>
                    <Col className="col-auto">
                      <div className="icon icon-shape text-white rounded-circle shadow" style={{ backgroundColor: GlobalStylesColor.chsChsat }}>
                        <i className="fas fa-clipboard-check" style={{ fontSize: 32 }} />
                      </div>
                    </Col>
                  </Row>
                  <p className="mt-3 mb-0 text-muted text-sm">
                    <span className="text-danger mr-2">{statType_CHSAT_PROGRESS}</span> <span className="text-nowrap">On going</span>
                  </p>
                </CardBody>
              </Card>
            </Col>

            <Col lg="6" xl="4">
              <Card
                className="card-stats mb-4 mb-xl-0"
                onClick={(e) => {
                  history.push({ pathname: "/registered/assessment_answers" });
                }}
                style={{ cursor: "pointer" }}
              >
                <CardBody>
                  <Row>
                    <div className="col">
                      <CardTitle tag="h5" className="text-uppercase text-muted mb-0">
                        <b>Institutional Capacity Assessment Tool</b> <br />
                        <br /> COMPLETED TEST
                      </CardTitle>
                      <span className="h2 font-weight-bold mb-0">{statType_ICAT_COMPLETE}</span>
                    </div>
                    <Col className="col-auto">
                      <div className="icon icon-shape text-white rounded-circle shadow" style={{ backgroundColor: GlobalStylesColor.chsIcat }}>
                        <i className="fas fa-university" style={{ fontSize: 32 }} />
                      </div>
                    </Col>
                  </Row>
                  <p className="mt-3 mb-0 text-muted text-sm">
                    <span className="text-danger mr-2">{statType_ICAT_PROGRESS}</span> <span className="text-nowrap">On going</span>
                  </p>
                </CardBody>
              </Card>
            </Col>

            <Col lg="6" xl="4">
              <Card
                className="card-stats mb-4 mb-xl-0"
                onClick={(e) => {
                  history.push({ pathname: "/registered/assessment_answers" });
                }}
                style={{ cursor: "pointer" }}
              >
                <CardBody>
                  <Row>
                    <div className="col">
                      <CardTitle tag="h5" className="text-uppercase text-muted mb-0">
                        <b>Tool for Institutional Engagement</b> <br />
                        <br /> COMPLETED TEST
                      </CardTitle>
                      <span className="h2 font-weight-bold mb-0">{statType_TIE_COMPLETE}</span>
                    </div>
                    <Col className="col-auto">
                      <div className="icon icon-shape text-white rounded-circle shadow" style={{ backgroundColor: GlobalStylesColor.chsTie }}>
                        <i className="fas fa-hands-helping" style={{ fontSize: 32 }} />
                      </div>
                    </Col>
                  </Row>
                  <p className="mt-3 mb-0 text-muted text-sm">
                    <span className="text-warning mr-2">{statType_TIE_PROGRESS}</span> <span className="text-nowrap">On going</span>
                  </p>
                </CardBody>
              </Card>
            </Col>
          </Row>
        </div>
        <br />
        <br />

        {/* Top charts */}
        <Row>
          <Col className="mb-5 mb-xl-0" xl="12">
            <Card className="bg-gradient-default shadow">
              <CardHeader className="bg-transparent">
                <Row className="align-items-center">
                  <div className="col">
                    <h6 className="text-uppercase text-light ls-1 mb-1">Overview</h6>
                    <h2 className="text-white mb-0">This Year Completion</h2>
                  </div>
                  <div className="col">
                    <Nav className="justify-content-end" pills>
                      <NavItem style={{ marginRight: 5 }}>
                        <NavLink className="py-2 px-3" onClick={(e) => {}} style={{ color: "#fff", backgroundColor: GlobalStylesColor.chsChsat }}>
                          <span className="">CHSAT</span>
                        </NavLink>
                      </NavItem>
                      <NavItem style={{ marginRight: 5 }}>
                        <NavLink className="py-2 px-3" onClick={(e) => {}} style={{ color: "#fff", backgroundColor: GlobalStylesColor.chsIcat }}>
                          <span className="">ICAT</span>
                        </NavLink>
                      </NavItem>
                      <NavItem style={{ marginRight: 5 }}>
                        <NavLink className="py-2 px-3" onClick={(e) => {}} style={{ color: "#fff", backgroundColor: GlobalStylesColor.chsTie }}>
                          <span className="">TIE</span>
                        </NavLink>
                      </NavItem>
                    </Nav>
                  </div>
                </Row>
              </CardHeader>
              <CardBody>
                {/* Chart */}
                {/* {console.log("lineChart: ", lineChart)} */}

                <div className="chart" style={{height: '500px'}}>
                  <HorizontalBar
                    data={{
                      labels: barChart.label,
                      datasets: [
                        {
                          label: "CHSAT",
                          data: barChart.chsat,
                          fill: false,
                          backgroundColor: GlobalStylesColor.chsChsat,
                          borderColor: GlobalStylesColor.chsChsat,
                        },
                        {
                          label: "ICAT",
                          data: barChart.icat,
                          fill: false,
                          backgroundColor: GlobalStylesColor.chsIcat,
                          borderColor: GlobalStylesColor.chsIcat,
                        },
                        {
                          label: "TIE",
                          data: barChart.tie,
                          fill: false,
                          backgroundColor: GlobalStylesColor.chsTie,
                          borderColor: GlobalStylesColor.chsTie,
                        },
                      ],
                    }}
                    options={optionsGrid}
                    getDatasetAtEvent={(e) => console.log(e)}
                  />
                </div>
              </CardBody>
            </Card>
          </Col>
        </Row>

        {/* Bottom detail tables */}
        <Row className="mt-5">
          <Col className="mb-5 mb-xl-0" xl="8">
            <Card className="shadow">
              <CardHeader className="border-0">
                <Row className="align-items-center">
                  <div className="col">
                    <h3 className="mb-0">All Assessment Completion By Organization Type</h3>
                  </div>
                </Row>
              </CardHeader>
              <Table className="align-items-center table-flush" responsive>
                <thead className="thead-light">
                  <tr>
                    <th scope="col">Country</th>
                    <th scope="col">Government</th>
                    <th scope="col">Non-Government</th>
                    <th scope="col">Private Sector</th>
                    <th scope="col">Other</th>
                  </tr>
                </thead>
                <tbody>
                  {statOrganization.map((item, i) => {
                    return (
                      <tr key={i}>
                        <td>{item.country}</td>
                        <td>{item.government?.count}</td>
                        <td>{item.nonGovernment?.count}</td>
                        <td>{item.private?.count}</td>
                        <td>{item.other?.count}</td>
                      </tr>
                    );
                  })}
                </tbody>
              </Table>
            </Card>
          </Col>
          <Col xl="4">
            <Card className="shadow">
              <CardHeader className="border-0">
                <Row className="align-items-center">
                  <div className="col">
                    <h3 className="mb-0">All Completed Assessments</h3>
                  </div>
                </Row>
              </CardHeader>
              <Table className="align-items-center table-flush" responsive>
                <thead className="thead-light">
                  <tr>
                    <th scope="col" style={{ paddingRight: 0 }}>
                      Country
                    </th>
                    <th scope="col" style={{ paddingRight: 0 }}>
                      CHSAT
                    </th>
                    <th scope="col" style={{ paddingRight: 0 }}>
                      ICAT
                    </th>
                    <th scope="col">TIE</th>
                  </tr>
                </thead>
                <tbody>
                  {statYear &&
                    statYear.map((item, i) => {
                      return (
                        <tr key={i}>
                          <td>{item.country}</td>
                          <td>{item.chsat}</td>
                          <td>{item.icat}</td>
                          <td>{item.tie}</td>
                        </tr>
                      );
                    })}
                </tbody>
              </Table>
            </Card>
          </Col>
        </Row>
      </Container>
    </>
  );
};

export default Index;
