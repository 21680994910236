import { MASTERDATA_FETCH_LANGUAGE, MASTERDATA_FETCH_ONE_LANGUAGE, COUNTRY_FETCH_ALL, MASTER_DATA_UPDATE, MASTER_DATA_LAN_UPDATE, LANDING_FETCH, LANDING_UPDATE, LOCALIZATION_FETCH, SHOW_LOADING } from "constants/actionTypes";
import { LOGINSTATE } from "config/config.json";
import * as api from "../api/index.js";
import { confirmAlert } from "react-confirm-alert";
import "react-confirm-alert/src/react-confirm-alert.css"; // Import css
import Lodash from "lodash";

// Language
export const fetchMasterDataLanguageAll = () => async (dispatch) => {
  try {
    dispatch({ type: SHOW_LOADING, payload: true });
    const response = await api.fetchMasterDataLanguageAll();
    const { data } = response;
    // if(LOGINSTATE) console.log("response data: ", data);
    if (data?.status === "success") {
      dispatch({ type: MASTERDATA_FETCH_LANGUAGE, payload: data?.data });
    }
    if (data?.status === "error") {
      if (LOGINSTATE) console.log("data error: ", data?.message);
      confirmAlert({ message: "Oops!, something went wrong. Please try again.", buttons: [{ label: "Ok", onClick: () => {} }], closeOnEscape: false, closeOnClickOutside: false });
    }
  } catch (error) {
    if (LOGINSTATE) console.log("catch error", error);
    alert(error);
  } finally {
    dispatch({ type: SHOW_LOADING, payload: false });
  }
};

export const fetchMasterDataLanguage = (formData) => async (dispatch) => {
  console.log("formData", formData);
  try {
    dispatch({ type: SHOW_LOADING, payload: true });
    const response = await api.fetchMasterDataLanguage(formData);
    const { data } = response;
    // if(LOGINSTATE) console.log("response data: ", data);
    if (data?.status === "success") {
      dispatch({ type: MASTERDATA_FETCH_ONE_LANGUAGE, payload: data?.data });
    }
    if (data?.status === "error") {
      if (LOGINSTATE) console.log("data error: ", data?.message);
      confirmAlert({ message: "Oops!, something went wrong. Please try again.", buttons: [{ label: "Ok", onClick: () => {} }], closeOnEscape: false, closeOnClickOutside: false });
    }
  } catch (error) {
    if (LOGINSTATE) console.log("catch error", error);
    alert(error);
  } finally {
    dispatch({ type: SHOW_LOADING, payload: false });
  }
};

export const fetchCountry = () => async (dispatch) => {
  try {
    dispatch({ type: SHOW_LOADING, payload: true });
    const { data } = await api.fetchCountry();
    if (LOGINSTATE) console.log("country data", data);
    if (data?.status === "success") {
      dispatch({ type: COUNTRY_FETCH_ALL, payload: data?.data });
    }
    if (data?.status === "error") {
      if (LOGINSTATE) console.log(data);
      confirmAlert({ message: "Oops!, something went wrong. Please try again.", buttons: [{ label: "Ok", onClick: () => {} }], closeOnEscape: false, closeOnClickOutside: false });
    } else {
      if (LOGINSTATE) console.log("else");
    }
  } catch (error) {
    if (LOGINSTATE) console.log(error);
    alert(error);
  } finally {
    dispatch({ type: SHOW_LOADING, payload: false });
  }
};

export const updateMasterData = (formData, router) => async (dispatch) => {
  if (LOGINSTATE) console.log("Updating the master data with: ", formData);
  try {
    dispatch({ type: SHOW_LOADING, payload: true });
    const { data } = await api.updateMasterDataCountry(formData);
    dispatch({ type: MASTER_DATA_UPDATE, payload: data });
    if (data?.status === "success") {
      // alert(data?.message);
      dispatch(fetchCountry());
      formData.redirect && router.push(formData.redirect);
      confirmAlert({
        title: "Success",
        buttons: [
          {
            label: "Ok",
            onClick: () => {
              router.push({ pathname: "/registered/master_regions" });
            },
          },
        ],
      });
    }
    if (data?.status === "error") {
      if (LOGINSTATE) console.log(data);
      confirmAlert({ message: "Oops!, something went wrong. Please try again.", buttons: [{ label: "Ok", onClick: () => {} }], closeOnEscape: false, closeOnClickOutside: false });
    }
  } catch (error) {
    console.error("********", error?.response);
    if (error?.response?.status && error?.response?.status === 401) {
      confirmAlert({ message: "Session expired!", buttons: [{ label: "Ok", onClick: () => {} }], closeOnEscape: false, closeOnClickOutside: false });
      router.push("/auth/signin");
    }
    // let errorTitle = error?.response?.data?.data ? error?.response?.data?.message : null;
    // let errorMessge = error?.response?.data?.data ? error?.response?.data?.data : error?.response?.data?.message;
    // errorMessge = Lodash.isEmpty(errorMessge) ? "Oops, something went wrong" : errorMessge;
    // confirmAlert({ title: errorTitle, message: errorMessge, buttons: [{ label: "Ok", onClick: () => {} }], closeOnEscape: false, closeOnClickOutside: false });
    // alert(error);
  } finally {
    dispatch({ type: SHOW_LOADING, payload: false });
  }
};

export const updateMasterDataLanguage = (formData, router) => async (dispatch) => {
  if (LOGINSTATE) console.log("Updating the master data with: ", formData);
  try {
    dispatch({ type: SHOW_LOADING, payload: true });
    const { data } = await api.updateMasterDataLanguage(formData);
    dispatch({ type: MASTER_DATA_LAN_UPDATE, payload: data });
    if (data?.status === "success") {
      // alert(data?.message);
      formData.redirect && router.push(formData.redirect);
      confirmAlert({ title: "Success", buttons: [{ label: "Ok", onClick: () => {} }], closeOnEscape: false, closeOnClickOutside: false });
    }
    if (data?.status === "error") {
      if (LOGINSTATE) console.log(data);
      confirmAlert({ message: "Oops!, something went wrong. Please try again.", buttons: [{ label: "Ok", onClick: () => {} }], closeOnEscape: false, closeOnClickOutside: false });
    }
  } catch (error) {
    if (LOGINSTATE) console.log("********", error?.response);
    if (error?.response?.status && error?.response?.status === 401) {
      confirmAlert({ message: "Session expired!", buttons: [{ label: "Ok", onClick: () => {} }], closeOnEscape: false, closeOnClickOutside: false });
      router.push("/auth/signin");
    } else {
      let errorTitle = error?.response?.data?.data ? error?.response?.data?.message : null;
      let errorMessge = error?.response?.data?.data ? error?.response?.data?.data : error?.response?.data?.message;
      errorMessge = Lodash.isEmpty(errorMessge) ? "Oops, something went wrong" : errorMessge;
      confirmAlert({ title: errorTitle, message: errorMessge, buttons: [{ label: "Ok", onClick: () => {} }], closeOnEscape: false, closeOnClickOutside: false });
    }
    // alert(error);
  } finally {
    dispatch({ type: SHOW_LOADING, payload: false });
  }
};

export const updateLandingContent = (formData, router) => async (dispatch) => {
  try {
    dispatch({ type: SHOW_LOADING, payload: true });
    const { data } = await api.updateLandingContent(formData);
    if (data?.status === "success") {
      if (LOGINSTATE) console.log("landing content: ", data?.data);
      confirmAlert({ title: "Success", buttons: [{ label: "Ok", onClick: () => {} }], closeOnEscape: false, closeOnClickOutside: false });

      dispatch(fetchLandingContent());
    }
    if (data?.status === "error") {
      if (LOGINSTATE) console.log("data error");
      confirmAlert({ message: "Oops!, something went wrong. Please try again.", buttons: [{ label: "Ok", onClick: () => {} }], closeOnEscape: false, closeOnClickOutside: false });
    }
  } catch (error) {
    if (LOGINSTATE) console.log("catch error: ", error);
    if (error?.response?.status && error?.response?.status === 401) {
      confirmAlert({ message: "Session expired!", buttons: [{ label: "Ok", onClick: () => {} }], closeOnEscape: false, closeOnClickOutside: false });
      router.push("/auth/signin");
    } else {
      alert(error);
    }
  } finally {
    dispatch({ type: SHOW_LOADING, payload: false });
  }
};

export const fetchLandingContent = (formData, router) => async (dispatch) => {
  try {
    dispatch({ type: SHOW_LOADING, payload: true });
    const { data } = await api.fetchLandingContent(formData);
    if (data?.status === "success") {
      if (LOGINSTATE) console.log("landing content: ", data?.data);
      dispatch({ type: LANDING_FETCH, payload: data?.data });
    }
    if (data?.status === "error") {
      if (LOGINSTATE) console.log("data error");
      confirmAlert({ message: "Oops!, something went wrong. Please try again.", buttons: [{ label: "Ok", onClick: () => {} }], closeOnEscape: false, closeOnClickOutside: false });
    }
  } catch (error) {
    if (LOGINSTATE) console.log("catch error: ", error);
    if (error?.response?.status && error?.response?.status === 401) {
      confirmAlert({ message: "Session expired!", buttons: [{ label: "Ok", onClick: () => {} }], closeOnEscape: false, closeOnClickOutside: false });
      router.push("/auth/signin");
    } else {
      alert(error);
    }
  } finally {
    dispatch({ type: SHOW_LOADING, payload: false });
  }
};

// system localization

export const createLocalizationContent = (formData, router) => async (dispatch) => {
  try {
    dispatch({ type: SHOW_LOADING, payload: true });
    const { data } = await api.createLocalizationContent(formData);

    if (formData.assessmentPage.Primer) {
      let updatePicResult = await uploadPrimer(formData, alert, router);

      // Profile picture update failed, stop the process;
      if (!updatePicResult) {
        confirmAlert({ message: "Profile picture upload failed. Please try again later.", buttons: [{ label: "Ok", onClick: () => {} }], closeOnEscape: false, closeOnClickOutside: false });
        return;
      }
    } else {
      if (LOGINSTATE) console.log("no new profile picture, continue with existing");
    }

    if (formData.assessmentPage.Logo) {
      let updatePicResult = await uploadLogo(formData, alert, router);

      // Profile picture update failed, stop the process;
      if (!updatePicResult) {
        confirmAlert({ message: "Profile picture upload failed. Please try again later.", buttons: [{ label: "Ok", onClick: () => {} }], closeOnEscape: false, closeOnClickOutside: false });
        return;
      }
    } else {
      if (LOGINSTATE) console.log("no new profile picture, continue with existing");
    }

    if (LOGINSTATE) console.log("localization content: ", data);

    if (data?.status === "success") {
      confirmAlert({ title: "Success", buttons: [{ label: "Ok", onClick: () => {} }], closeOnEscape: false, closeOnClickOutside: false });
      dispatch(fetchLocalizationContent());
    } else if (data?.status === "error") {
      confirmAlert({ title: "Error", message: data?.message, buttons: [{ label: "Ok", onClick: () => {} }], closeOnEscape: false, closeOnClickOutside: false });
    }
  } catch (error) {
    if (LOGINSTATE) console.log("catch error: ", error);
    if (error?.response?.status && error?.response?.status === 401) {
      confirmAlert({ message: "Session expired!", buttons: [{ label: "Ok", onClick: () => {} }], closeOnEscape: false, closeOnClickOutside: false });
      router.push("/auth/signin");
    } else {
      confirmAlert({ title: "Error", message: error?.response?.data?.message, buttons: [{ label: "Ok", onClick: () => {} }], closeOnEscape: false, closeOnClickOutside: false });
    }
  } finally {
    dispatch({ type: SHOW_LOADING, payload: false });
  }
};

export const updateLocalizationContent = (formData, router) => async (dispatch) => {
  console.log("formData", formData);
  try {
    dispatch({ type: SHOW_LOADING, payload: true });
    const { data } = await api.updateLocalizationContent(formData);
    if (LOGINSTATE) console.log("localization content: ", data);

    if (formData.assessmentPage.Primer) {
      let updatePicResult = await uploadPrimer(formData, alert, router);

      // Profile picture update failed, stop the process;
      if (!updatePicResult) {
        confirmAlert({ message: "Profile picture upload failed. Please try again later.", buttons: [{ label: "Ok", onClick: () => {} }], closeOnEscape: false, closeOnClickOutside: false });
        return;
      }
    } else {
      if (LOGINSTATE) console.log("no new profile picture, continue with existing");
    }

    if (formData.assessmentPage.Logo) {
      let updatePicResult = await uploadLogo(formData, alert, router);

      // Profile picture update failed, stop the process;
      if (!updatePicResult) {
        confirmAlert({ message: "Profile picture upload failed. Please try again later.", buttons: [{ label: "Ok", onClick: () => {} }], closeOnEscape: false, closeOnClickOutside: false });
        return;
      }
    } else {
      if (LOGINSTATE) console.log("no new profile picture, continue with existing");
    }

    if (data?.status === "success") {
      confirmAlert({ title: "Success", buttons: [{ label: "Ok", onClick: () => {} }], closeOnEscape: false, closeOnClickOutside: false });
      dispatch(fetchLocalizationContent());
    } else if (data?.status === "error") {
      confirmAlert({ title: "Error", message: data?.message, buttons: [{ label: "Ok", onClick: () => {} }], closeOnEscape: false, closeOnClickOutside: false });
    }
  } catch (error) {
    if (LOGINSTATE) console.log("catch error: ", error);
    if (error?.response?.status && error?.response?.status === 401) {
      confirmAlert({ message: "Session expired!", buttons: [{ label: "Ok", onClick: () => {} }], closeOnEscape: false, closeOnClickOutside: false });
      router.push("/auth/signin");
    } else {
      confirmAlert({ title: "Error", message: "Request failed with status code " + error.response.status, buttons: [{ label: "Ok", onClick: () => {} }], closeOnEscape: false, closeOnClickOutside: false });
    }
  } finally {
    dispatch({ type: SHOW_LOADING, payload: false });
  }
};

const uploadPrimer = async (values, alert, router) => {
  try {
    if (values.assessmentPage.Primer) {
      console.log("picture data: ", values.assessmentPage.Primer);
      // continue
    } else {
      console.log("empty picture");

      return false;
    }

    if (LOGINSTATE) console.log("upload picture data");
    const params = new FormData();
    params.append("Primer", values.assessmentPage.Primer);
    // params.append("photo[]", values.profileThumbnail);

    let res = await api.uploadPrimer(params);
    console.log("server returned: ", res);

    if (res && res.data && !Lodash.isEmpty(res.data.data) && res.data.data.length >= 2) {
      console.log("server returned new file names: ", res.data.data);
      values["assessmentPage.Primer"] = res.data.data[0].name;
      // values["profileThumbnail"] = res.data.data[1].name;
    }

    return true;
  } catch (error) {
    // console.log("catch error in picture upload: ", error?.response?.data);
    if (error?.response?.status && error?.response?.status === 401) {
      confirmAlert({ message: "Session expired!", buttons: [{ label: "Ok", onClick: () => {} }], closeOnEscape: false, closeOnClickOutside: false });
      router.push("/auth/signin");
    } else {
      let errorMessge = error?.response?.data?.message;
      confirmAlert({ message: errorMessge, buttons: [{ label: "Yes", onClick: () => {} }], closeOnEscape: false, closeOnClickOutside: false });
      values.profilePicture = null;
      values.profileThumbnail = null;
    }
    return false;
  }
};

const uploadLogo = async (values, alert, router) => {
  try {
    if (values.assessmentPage.Logo) {
      if (LOGINSTATE) console.log("picture data: ", values.assessmentPage.Logo);
      // continue
    } else {
      if (LOGINSTATE) console.log("empty picture");

      return false;
    }

    if (LOGINSTATE) console.log("upload picture data");
    const params = new FormData();
    params.append("Logo", values.assessmentPage.Logo);
    // params.append("photo[]", values.profileThumbnail);

    let res = await api.uploadLogo(params);
    // console.log("server returned: ", res);

    if (res && res.data && !Lodash.isEmpty(res.data.data) && res.data.data.length >= 2) {
      if (LOGINSTATE) console.log("server returned new file names: ", res.data.data);
      values["Logo"] = res.data.data[0].name;
      values["profileThumbnail"] = res.data.data[1].name;
    }

    return true;
  } catch (error) {
    // console.log("catch error in picture upload: ", error?.response?.data);
    if (error?.response?.status && error?.response?.status === 401) {
      confirmAlert({ message: "Session expired!", buttons: [{ label: "Ok", onClick: () => {} }], closeOnEscape: false, closeOnClickOutside: false });
      router.push("/auth/signin");
    } else {
      let errorMessge = error?.response?.data?.message;
      confirmAlert({ message: errorMessge, buttons: [{ label: "Yes", onClick: () => {} }], closeOnEscape: false, closeOnClickOutside: false });
      values.profilePicture = null;
      values.profileThumbnail = null;
    }
    return false;
  }
};

export const fetchLocalizationContent = (formData, router) => async (dispatch) => {
  try {
    dispatch({ type: SHOW_LOADING, payload: true });
    const { data } = await api.fetchLocalizationContent(formData);
    if (LOGINSTATE) console.log("localization content: ", data);

    if (data?.status === "success") {
      dispatch({ type: LOCALIZATION_FETCH, payload: data?.data });
    } else if (data?.status === "error") {
      confirmAlert({ title: "Error", message: data?.message, buttons: [{ label: "Ok", onClick: () => {} }], closeOnEscape: false, closeOnClickOutside: false });
    }
  } catch (error) {
    if (LOGINSTATE) console.log("catch error: ", error);
    if (error?.response?.status && error?.response?.status === 401) {
      confirmAlert({ message: "Session expired!", buttons: [{ label: "Ok", onClick: () => {} }], closeOnEscape: false, closeOnClickOutside: false });
      router.push("/auth/signin");
    } else {
      confirmAlert({ title: "Error", message: "Request failed with status code " + error.response.status, buttons: [{ label: "Ok", onClick: () => {} }], closeOnEscape: false, closeOnClickOutside: false });
    }
  } finally {
    dispatch({ type: SHOW_LOADING, payload: false });
  }
};
