import React, { Suspense } from "react";
import ReactDOM from "react-dom";

import { BrowserRouter, Route, Switch, Redirect } from "react-router-dom";

import { Provider } from 'react-redux';
import thunk from 'redux-thunk';
import { createStore, applyMiddleware, compose } from 'redux';
import { composeWithDevTools } from 'redux-devtools-extension';
import { reducers } from './reducers';


import i18next from './i18n';
import { I18nextProvider } from "react-i18next";

import { CircularProgress } from '@material-ui/core';
import './assets/css/react-confirm-alert-custom.css'; // Import css

// import { transitions, positions, Provider as AlertProvider } from 'react-alert'
// import AlertTemplate from 'react-alert-template-mui'

import "assets/plugins/nucleo/css/nucleo.css";
import "assets/scss/argon-dashboard-react.scss";
import "assets/css/argon-local-customizations.css";
import "@fortawesome/fontawesome-free/css/all.min.css";


import AdminLayout from "layouts/Admin.js";
import AuthLayout from "layouts/Auth.js";
import LandingLayout from "layouts/Landing.js";
import PrintLayout from "layouts/Print.js";






// const store = createStore(reducers, compose(applyMiddleware(thunk)));

const store = createStore(reducers, composeWithDevTools(
  applyMiddleware(thunk),
  // other store enhancers if any
));

// Alert options
// const options = {
//   position: positions.BOTTOM_CENTER,
//   // timeout: 5000,
//   offset: '30px',
//   transition: transitions.SCALE
// }

ReactDOM.render(
  <Provider store={store}>
    <I18nextProvider i18n={i18next}>
      <Suspense
        fallback={
          <div style={{ width: window.innerWidth, height: window.innerHeight, textAlign: 'center' }}>
            <CircularProgress style={{ color: '#af1e23', marginTop: (window.innerHeight / 2) }} />
          </div>
        }
      >
        {/* <AlertProvider template={AlertTemplate} {...options}> */}
        <BrowserRouter>
          <Switch>
            <Route path="/public" render={(props) => <LandingLayout {...props} />} />
            <Route path="/auth" render={(props) => <AuthLayout {...props} />} />
            {/* <Route path="/print" render={(props) => <PrintLayout {...props} />} /> */}
            <Route path="/print_content" render={(props) => <PrintLayout {...props} />} />
            <Route path="/registered" render={(props) => <AdminLayout {...props} />} />
            {/* <Redirect from="/" to="/registered/index" /> */}
            <Redirect from="/" to="/public" />
          </Switch>
        </BrowserRouter>
        {/* </AlertProvider> */}
      </Suspense>
    </I18nextProvider>
  </Provider>,
  document.getElementById("root")
);
