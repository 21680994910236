import {
  SURVEY_FETCH_ALL,
  SURVEY_FETCH_ACTIVE,
  SURVEY_TEMPLATE_CREATE,
  SURVEY_TEMPLATE_FETCH_ALL,
  SURVEY_TEMPLATE_LOCAL,
  SURVEY_TEMPLATE_LOCALE_LOCAL,
  SURVEY_LOCALIZATION,
  ANSWER_FETCH_BY_USER,
  ANSWER_FETCH_BY_ID,
  RECOMMENDATION_CREATE,
  RECOMMENDATION_FETCH_ALL,
  RECOMMENDATION_LOCAL,
  RECOMMENDATION_UPDATE,
  GRAPH_LOCAL_ANSWER,
} from "constants/actionTypes";
import { LOGINSTATE } from "config/config.json";

const initState = {
  surveyAll: [],
  questionnaireAll: [],
  surveyActive: {},
  surveyTemplateAll: [],
  createSurveyTemplate: {},
  localSurveyTemplate: {},
  surveyLocalizationAll: [],
  answerById: {},
  answerByUser: [],
  createRecommendation: {},
  updateRecommendation: {},
  recommendationAll: [],
};

const surveyReducer = (survey = initState, action) => {
  if (LOGINSTATE) console.log("Dispatch is called for: ", action.type);
  if (LOGINSTATE) console.log("Dispatch is called with: ", action.payload);
  switch (action.type) {
    case SURVEY_FETCH_ALL:
      return { ...survey, surveyAll: action.payload };
    case SURVEY_FETCH_ACTIVE:
      return { ...survey, surveyActive: action.payload };
    case SURVEY_TEMPLATE_CREATE:
      return { ...survey, createSurveyTemplate: action.payload };
    case SURVEY_TEMPLATE_FETCH_ALL:
      return { ...survey, surveyTemplateAll: action.payload };
    case SURVEY_TEMPLATE_LOCAL:
      return { ...survey, localSurveyTemplate: action.payload };
    case SURVEY_TEMPLATE_LOCALE_LOCAL:
      return { ...survey, localSurveyTemplateLocale: action.payload };
    case SURVEY_LOCALIZATION:
      return { ...survey, surveyLocalizationAll: action.payload };
    case ANSWER_FETCH_BY_USER:
      return { ...survey, answerByUser: action.payload };
    case ANSWER_FETCH_BY_ID:
      return { ...survey, answerById: action.payload };
    case RECOMMENDATION_CREATE:
      return { ...survey, createRecommendation: action.payload };
    case RECOMMENDATION_FETCH_ALL:
      return { ...survey, recommendationAll: action.payload };
    case RECOMMENDATION_LOCAL:
      return { ...survey, localRecommendationTemplate: action.payload };
    case RECOMMENDATION_UPDATE:
      return { ...survey, updateRecommendation: action.payload };
    case GRAPH_LOCAL_ANSWER:
      return { ...survey, answerAll: action.payload };
    default:
      return survey;
  }
};

export default surveyReducer;
