import axios from 'axios';
import Config from "config/config.json";


// const API = axios.create({ baseURL: 'http://127.0.0.1:3000/' });
const API = axios.create({ baseURL: Config.SERVER_API_URL });

API.interceptors.request.use((req) => {
  if (localStorage.getItem('userDetails')) {
    req.headers["x-access-token"] = `${JSON.parse(localStorage.getItem('userDetails')).token}`;
  }
  return req;
});

// AUTH
export const signIn = (formData) => API.post('/api/user/auth', formData);
export const signUp = (formData) => API.post('/api/user/registerUserAndOrganization', formData);
export const signupadmin = (formData) => API.post('/api/user/register', formData);
export const signupalreadyregister = (formData) => API.post('/api/user/registerUserAndUpdateOrganization', formData);

// user
export const fetchProfile = () => API.post('/api/user/profile/find');
export const fetchProfileOrg = () => API.post('/api/user/profile/findUser');
export const fetchProfileOrgAsAdmin = (formData) => API.post('/api/user/profile/findUserAsAdmin', formData);
export const fetchAllProfiles = () => API.get('/api/user/all');
export const updateProfile = (formData) => API.post('/api/user/profile/update', formData);
export const updateUser = (formData) => API.post('/api/user/profile/updateUser', formData);
export const uploadProfileImage = (formData) => API.post('/api/uploads/saveImageProfile', formData);
export const changePassword = (formData) => API.post('/api/user/auth/change_password', formData);
export const changeStatus = (formData) => API.post('/api/user/status', formData);
export const fetchUserTemplateAll = (formData) => API.post('/api/user/userNew', formData);
export const resetPassword = (formData) => API.post('/api/user/reset', formData);
export const validateHuman = (formData) => API.post('/api/user/validateHuman', formData);

// forum
// forum topics
export const fetchFrmTopics = () => API.get('/api/forum/gettopics');
export const fetchFrmTopicById = (formData) => API.post('/api/forum/gettopicbyid', formData);
export const createFrmTopic = (formData) => API.post('/api/forum/createtopic', formData);
export const updateFrmTopic = (formData) => API.put('/api/forum/updatetopic', formData);
// forum sub topics
export const fetchFrmSubTopics = (formData) => API.post('/api/forum/getsubtopics', formData);
export const fetchFrmSubTopicById = (formData) => API.post('/api/forum/getsubtopicbyid', formData);
export const createFrmSubTopic = (formData) => API.post('/api/forum/createsubtopic', formData);
export const updateFrmSubTopic = (formData) => API.put('/api/forum/updatesubtopic', formData);
export const deleteFrmSubTopic = (formData) => API.put('/api/forum/deletesubtopic', formData);
// forum Questions
export const fetchFrmQus = () => API.get('/api/forum/getquestions');
export const fetchFrmQusbyTopics = (formData) => API.post('/api/forum/getquestionsbysubtopic', formData);
export const fetchFrmQusbyId = (formData) => API.post('/api/forum/getquestionbyid', formData);
export const createFrmQus = (formData) => API.post('/api/forum/createquestion', formData);
export const updateFrmQus = (formData) => API.put('/api/forum/updatequestion', formData);
export const deleteFrmQus = (formData) => API.put('/api/forum/deletequestionanswer', formData);
// forum Answers
export const fetchFrmAns = (formData) => API.post('/api/forum/getanswers', formData);
export const fetchFrmAnsById = (formData) => API.post('/api/forum/getanswerbyid', formData);
export const createFrmAns = (formData) => API.post('/api/forum/createanswer', formData);
export const updateFrmAns = (formData) => API.put('/api/forum/updateanswer', formData);
export const deleteFrmAns = (formData) => API.put('/api/forum/deletequestionanswer', formData); //same as delete question


// Survey
export const fetchSurveyAll = (formData) => API.post('/api/survey/findAll', formData);
export const fetchSurveyById = (formData) => API.post('/api/survey/findById', formData);
export const fetchActiveSurveyByType = (formData) => API.post('/api/survey/findActiveSurveyTemplateByType', formData);
export const fetchQuestionnaireAll = (formData) => API.post('/api/survey/questionnaire/findAll', formData);
export const fetchQuestionnaireById = (formData) => API.post('/api/survey/questionnaire/findById', formData);
export const createSurveyTemplate = (formData) => API.post('/api/survey/createTemplate', formData);
export const fetchSurveyTemplateAll = (formData) => API.post('/api/survey/findSurveyTemplateAll', formData);
export const fetchActiveSurveyTemplateByType = (formData) => API.post('/api/survey/findActiveSurveyTemplateByType', formData);
export const updateSurveyTemplateStatusById = (formData) => API.post('/api/survey/updateSurveyTemplateStatusById', formData);
export const updateSurveyTemplateById = (formData) => API.post('/api/survey/updateSurveyTemplateById', formData);
export const updateSurveyTemplatePublishById = (formData) => API.post('/api/survey/updateSurveyTemplatePublishById', formData);
// Localization
export const createLocalizationBySurveyTemplate = (formData) => API.post('/api/survey/createSurveyLocalization', formData);
export const updateLocalizationBySurveyTemplate = (formData) => API.post('/api/survey/updateSurveyLocalization', formData);
export const fetchLocalizationBySurveyTemplate = (formData) => API.post('/api/survey/fetchLocalizationBySurveyTemplate', formData);
export const deleteSurveyLocalization = (formData) => API.post('/api/survey/deleteSurveyLocalization', formData);
// Answer
export const createSurveyAnswer = (formData) => API.post('/api/survey/answer/create', formData);
export const updateSurveyAnswerById = (formData) => API.post('/api/survey/answer/update', formData);
export const fetchSurveyAnswerById = (formData) => API.post('/api/survey/answer/byId', formData);
export const fetchSurveyAnswerByUser = (formData) => API.post('/api/survey/answer/byUser', formData);
// Recommendation
export const createRecommendation = (formData) => API.post('/api/survey/recommendation/create', formData);
export const updateRecommendationById = (formData) => API.post('/api/survey/recommendation/update', formData);
export const deleteRecommendationById = (formData) => API.post('/api/survey/recommendation/delete', formData);
export const fetchRecommendationsBySurveyId = (formData) => API.post('/api/survey/recommendation/findBySurveyId', formData);

//Organization
export const updateOrganization = (formData) => API.post('/api/organization/updateDetails', formData);
export const fetchOrganizationAll = (formData) => API.post('/api/organization/findAll', formData);
export const fetchOrganizationAllByCountryId = (formData) => API.post('/api/organization/findAllByCountry', formData);
export const updateCityStatus = (formData) => API.post('api/organization/updateCityStatus', formData);
export const uploadOrganization = (formData) => API.post('/api/uploads/saveOrganization', formData);
export const deleteOrganizationProfileById = (formData) => API.post('/api/organization/deleteOrganizationProfileById', formData);

// Master data
export const fetchMasterDataLanguageAll = (formData) => API.post('/api/masterdata/language/findAll', formData);
export const fetchMasterDataLanguage = (formData) => API.post('/api/masterdata/language/find', formData);
export const fetchCountry = (formData) => API.post('/api/masterdata/country/findAll', formData);
export const updateMasterDataCountry = (formData) => API.post('/api/masterdata/country/updateOrCreate', formData);
export const updateMasterDataLanguage = (formData) => API.post('/api/masterdata/language/updateOrCreate', formData);
export const fetchLandingContent = (formData) => API.post('/api/masterdata/public/findLandingContent', formData);
export const updateLandingContent = (formData) => API.post('/api/masterdata/public/updateLandingContent', formData);
export const fetchLocalizationContent = (formData) => API.post('/api/masterdata/public/findLocalizationContent', formData);
export const createLocalizationContent = (formData) => API.post('/api/masterdata/public/createLocalizationContent', formData);
export const updateLocalizationContent = (formData) => API.post('/api/masterdata/public/updateLocalizationContent', formData);
// export const uploadPrimer = (formData) => API.post('/api/uploads/savePrimer', formData);
// export const uploadLogo = (formData) => API.post('/api/uploads/saveLogo', formData);

// Resource
export const fetchResources = (formData) => API.post('/api/resource/findAll', formData);
export const createResource = (formData) => API.post('/api/resource/create', formData);
export const updateResource = (formData) => API.post('/api/resource/update', formData);
export const deleteResource = (formData) => API.post('/api/resource/deleteById', formData);
export const uploadResource = (formData) => API.post('/api/uploads/saveResource', formData);
export const uploadPrimer = (formData) => API.post('/api/uploads/savePrimer', formData);
export const uploadLogo = (formData) => API.post('/api/uploads/saveLogo', formData);
export const uploadJSON = (formData) => API.post('/api/uploads/saveJSON', formData);

// Reports
export const fetchReportAnswerAll = (formData) => API.post('/api/report/answer/all', formData);
export const fetchReportAnswersByUser = (formData) => API.post('/api/report/answer/byUser', formData);
export const fetchReportAnswerByCountry = (formData) => API.post('/api/report/answer/summaryByCountry', formData);
export const fetchAnswerStat = (formData) => API.post('/api/report/answer/findStats', formData);
export const fetchUserStat = (formData) => API.post('/api/report/answer/findUserStats', formData);

