import { fetchSurveyTemplateAll, updateSurveyTemplatePublishById } from "actions/survey";

// core components
import PageHeader from "components/Headers/PageHeader.js";
import Config from "config/config.json";
import Lodash from "lodash";
import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useHistory, useLocation } from "react-router-dom";
// reactstrap components
import { Badge, Card, CardHeader, Container, Row, Table } from "reactstrap";
import { GlobalStylesColor } from "constants/globalStyles";
import { LOGINSTATE } from "../../../config/config.json";
import { confirmAlert } from "react-confirm-alert";
import "react-confirm-alert/src/react-confirm-alert.css"; // Import css
import { Tooltip } from "@material-ui/core";
import TranslateOutlinedIcon from "@material-ui/icons/TranslateOutlined";

// Populate version row for each survey type
const SurveyRow = ({ survey, localizationClick }) => (
  // VERSION | TITLE | DESCRIPTION | STATUS
  <tr>
    <td style={{ whiteSpace: "break-spaces" }}>{survey.version}</td>
    <td style={{ whiteSpace: "break-spaces", color: GlobalStylesColor.mbppThemeBlue }}>{survey.title}</td>
    <td style={{ whiteSpace: "break-spaces" }}>{survey.description}</td>
    <td style={{ whiteSpace: "break-spaces" }}>{survey.status}</td>
    <td>
      {survey.published ? (
        <Badge color="" className="badge-dot">
          <i className="bg-success" />
          YES
        </Badge>
      ) : (
        <Badge color="" className="badge-dot mr-4">
          <i className="bg-warning" />
          NO
        </Badge>
      )}
    </td>
    <td className="text-right">
      <div className="row">
        <Tooltip title="Localizations" arrow>
          <div
            className="navbar-toggler"
            style={{ cursor: "pointer" }}
            onClick={(e) => {
              localizationClick(e, survey);
            }}
          >
            <TranslateOutlinedIcon />
          </div>
        </Tooltip>
      </div>
    </td>
  </tr>
);

// export function
const Recommendation = () => {
  // dispatch and history
  const dispatch = useDispatch();
  const history = useHistory();
  const location = useLocation();

  // get forum topics
  const surveyTemplateAll = useSelector((state) => state.survey.surveyTemplateAll);

  useEffect(() => {
    // fetching all surveys
    dispatch(fetchSurveyTemplateAll());
  }, []);

  const handleStatusClick = (e, survey) => {
    e.preventDefault();
    dispatch(updateSurveyTemplatePublishById({ surveyId: survey._id }, history));
  };

  // handle Click event of the survey. Navigate to the localization page. There will be auto english version for every version
  const handleLocalizationClick = (e, survey) => {
    e.preventDefault();
    if (LOGINSTATE) console.log("survey", survey);
    history.push({ pathname: "/registered/assessment_recommendation_localization", state: { survey: survey } });
  };

  // create survey from existing survey
  const handleEditClick = (e, survey, type) => {
    e.preventDefault();
  };

  return (
    <>
      <PageHeader />

      {/* Page content */}
      <Container className="mt--7" fluid>
        {!Lodash.isEmpty(surveyTemplateAll) &&
          Config.TOOLS.map((tool, i) => {
            return (
              <Row className="mt-5" key={i}>
                <div className="col">
                  <Card className="shadow">
                    <CardHeader className="border-0">
                      <div className="row">
                        <div className="mb-xl-0 col-11">
                          <h3 className="mb-0">{tool.title}</h3>
                          <h6 className="mb-0">{tool.description}</h6>
                        </div>
                        <div className="mb-xl-0"></div>
                      </div>
                    </CardHeader>
                    <Table className="align-items-center table-flush" responsive>
                      <thead className="thead-light">
                        <tr>
                          <th scope="col">Version</th>
                          <th scope="col">Title</th>
                          <th scope="col">Description</th>
                          <th scope="col">Status</th>
                          <th scope="col">Published</th>
                          <th scope="col">Actions</th>
                        </tr>
                      </thead>
                      <tbody>
                        <tr></tr>
                        {Lodash.filter(surveyTemplateAll, { type: tool.type }).map((survey, i) => {
                          
                          return <SurveyRow key={i} survey={survey} editClick={handleEditClick} statusClick={handleStatusClick} localizationClick={handleLocalizationClick} />;
                        })}
                      </tbody>
                    </Table>
                  </Card>
                </div>
              </Row>
            );
          })}
      </Container>
    </>
  );
};

export default Recommendation;
