import * as WebDataRocksReact from "assets/js/webdatarocks.react";
import PageHeader from "components/Headers/PageHeader.js";
import { LOGINSTATE } from "../../../config/config.json";
// reactstrap components
import { Card, CardBody, CardFooter, CardHeader, Col, Container, Row, Form, Button } from "reactstrap";
import React, { useEffect, useState, useRef } from "react";
import { useDispatch } from "react-redux";
import Lodash from "lodash";
import * as api from "api/index";
import { Formik } from "formik";
import { TextField } from "@material-ui/core";
import Moment from "moment";

const initReportConfiguration = {
  dataSource: {
    data: [],
  },
  slice: {
    drills: {
      drillAll: false,
    },
  },
  formats: [
    {
      name: "",
      thousandsSeparator: " ",
      decimalSeparator: ".",
      decimalPlaces: 2,
      nullValue: " ",
      infinityValue: "Infinity",
      divideByZeroValue: "Infinity",
    },
  ],
  options: {
    grid: {
      showGrandTotals: "off",
    },
  },
};

const ReportAllAnswersBySegmentByOrganization = (props) => {
  // For webdatarock values
  const reportRef = useRef();
  const formRef = useRef();
  const dispatch = useDispatch();

  const [reportConfiguration, setReportConfiguration] = useState(null);
  const [answerData, setAnswerData] = useState([]);

  useEffect(() => {
    fetchReportData();
  }, []);

  const fetchReportData = async () => {
    const params = { statType: "AssessmentDetail" };
    const { data } = await api.fetchAnswerStat(params);
    if (LOGINSTATE) console.log("pivot data: ", data);

    if (data && data.data && data.data.length > 0) {
      setAnswerData(data.data);
      if (LOGINSTATE) console.log("reportData: ", data.data);
    }
  };

  const updateReport = (country, organization, location, type) => {
    setReportConfiguration(null);

    if (country && organization && location && type && country.length > 0 && organization.length > 0 && location.length > 0 && type.length > 0) {
      let reportConfig = initReportConfiguration;

      let filteredData = Lodash.filter(answerData, { country: country, organization: organization, location: location, assessment: type });
      if (LOGINSTATE) console.log("filtered data", filteredData);
      // let filteredDataCustom = filteredData.map(({ date, organization_type, segment_code, segments_score, version }) => ({ date, organization_type, segment_code, segments_score, version }));
      let filteredDataCustom = filteredData.map(({ date, organization_type, segment_code, score, segments_score, version }) => {
        let date_time = Moment.utc(date).format("YYYY-MM-DD hh:mm:ss A");
        // filteredDataCustom["segment_code"] = "Total";
        // console.log("filteredDataCustom11", filteredDataCustom);
        return { date_time, segment_code, score, segments_score, version };
      });
      if (LOGINSTATE) console.log("filteredDataCustom11", filteredDataCustom);
      // filteredDataCustom["segment_code"] = "Total";
      let dates = filteredDataCustom.map((item) => item.date_time).filter((value, index, self) => self.indexOf(value) === index);

      dates.map((item, i) => (filteredDataCustom = [...filteredDataCustom, { date_time: item, segment_code: "Total", segments_score: Lodash.find(filteredDataCustom, { date_time: item }).score, version: Lodash.find(filteredDataCustom, { date_time: item }).version }]));
      let filteredDataCustomTwo = filteredDataCustom.map(({ date_time, segment_code, segments_score, version }) => {

        // filteredDataCustom["segment_code"] = "Total";
        // console.log("filteredDataCustom11", filteredDataCustom);
        const score = segments_score
        return { date_time, segment_code, score, version };
      });
      reportConfig["dataSource"]["data"] = [
        // {
        //   processedDate: { type: "date" },
        //   organization_type: { type: "string" },
        //   segment_code: { type: "string" },
        //   version: { type: "string" },
        //   segments_score: { type: "number" }
        // },
        ...filteredDataCustomTwo,
      ];

      if (LOGINSTATE) console.log("filteredDataCustom", filteredDataCustom);
      reportConfig["slice"] = {
        rows: [{ uniqueName: "date_time" }],
        columns: [{ uniqueName: "segment_code" }],
        measures: [{ uniqueName: "score", aggregation: "average" }],
        drills: { drillAll: false },
      };
      if (LOGINSTATE) console.log("report", reportConfig);

      setReportConfiguration(reportConfig);
    } else {
      // Not showing the pivot, otherwise it's not refreshing
    }
  };

  const scrollToPivot = () => {
    // var titleElement = document.getElementById('pivotCard')
    // titleElement.scrollIntoView({ behavior: 'smooth' })
  };

  const reportComplete = () => {
    if (reportRef.current && reportRef.webdatarocks) {
      if (LOGINSTATE) console.log("report: ", reportRef.webdatarocks.getReport());
    }
  };

  const customizeToolbar = (toolbar) => {
    let tabs = toolbar.getTabs(); // get all tabs from the toolbar
    toolbar.getTabs = function () {
      delete tabs[0]; // delete the first tab
      delete tabs[1];
      delete tabs[2];
      delete tabs[4];
      delete tabs[6];
      return tabs;
    };
  };

  return (
    <>
      <PageHeader />

      {/* Page content */}
      <Container className="mt--7" fluid>
        {/* create survey questions */}
        <Row className="mt-5">
          <Col>
            <Card className="shadow">
              {/* survey header */}
              <CardHeader className="border-0">
                <div className="row">
                  <div className="mb-xl-0 col-10">
                    <h3 className="mb-0">Assessments By Organizations With Segments</h3>
                    <h6 className="mb-0">Organization assessment report</h6>
                  </div>
                </div>
              </CardHeader>

              <CardBody>
                <Formik
                  initialValues={{
                    Country: "",
                    Organization: "Org",
                    Location: "",
                    Type: "",
                  }}
                  innerRef={formRef}
                  onSubmit={(values, actions) => {
                    updateReport(values.Country, values.Organization, values.Location, values.Type);
                  }}
                >
                  {(props) => (
                    <Form role="form">
                      <Row>
                        <Col md="6">
                          <TextField
                            select
                            fullWidth
                            id="Country"
                            name="Country"
                            variant="outlined"
                            label="Country"
                            value={props.values.Country}
                            onChange={(e) => {
                              // console.log("country: ", e.target.value);
                              props.handleChange(e, "Country");
                              // updateReport(e.target.value, props.values.Organization, props.values.Location, props.values.Type);
                            }}
                            SelectProps={{ native: true }}
                            className={"form-control-alternative form-control-edit"}
                          >
                            <option key="" value=""></option>
                            {!Lodash.isEmpty(answerData) &&
                              answerData
                                .map((item) => item.country)
                                .filter((value, index, self) => self.indexOf(value) === index)
                                .map((item, i) => (
                                  <option key={item} value={item}>
                                    {item}
                                  </option>
                                ))}
                          </TextField>
                        </Col>
                      </Row>
                      <br />

                      <Row>
                        <Col md="12">
                          <TextField
                            select
                            fullWidth
                            id="Organization"
                            name="Organization"
                            variant="outlined"
                            label="Organization"
                            value={props.values.Organization}
                            onChange={(e) => {
                              // console.log("organization: ", e.target.value);
                              props.handleChange(e, "Organization");
                              // updateReport(props.values.Country, e.target.value, props.values.Location, props.values.Type);
                            }}
                            SelectProps={{ native: true }}
                            className={"form-control-alternative form-control-edit"}
                          >
                            <option key="" value=""></option>
                            {!Lodash.isEmpty(answerData) &&
                              props.values.Country &&
                              Lodash.filter(answerData, { country: props.values.Country })
                                .map((item) => item.organization)
                                .filter((value, index, self) => self.indexOf(value) === index)
                                .map((item, i) => (
                                  <option key={item} value={item}>
                                    {i+1}. {item}
                                  </option>
                                ))}
                          </TextField>
                        </Col>
                      </Row>
                      <br />

                      <Row>
                        <Col md="7" style={{ marginBottom: 10 }}>
                          <TextField
                            select
                            fullWidth
                            id="Location"
                            name="Location"
                            variant="outlined"
                            label="Location"
                            value={props.values.Location}
                            onChange={(e) => {
                              // console.log("selected user: ", e.target.value);
                              props.handleChange(e, "Location");
                              // updateReport(props.values.Country, props.values.Organization, e.target.value, props.values.Type);
                            }}
                            SelectProps={{ native: true }}
                            className={"form-control-alternative form-control-edit"}
                          >
                            <option key="" value=""></option>
                            {!Lodash.isEmpty(answerData) &&
                              props.values.Country &&
                              props.values.Organization &&
                              Lodash.filter(answerData, { country: props.values.Country, organization: props.values.Organization })
                                .map((item) => item.location)
                                .filter((value, index, self) => self.indexOf(value) === index)
                                .map((item, i) => (
                                  <option key={item} value={item}>
                                    {item}
                                  </option>
                                ))}
                          </TextField>
                        </Col>

                        <Col md="5" style={{ marginBottom: 10 }}>
                          <TextField
                            select
                            fullWidth
                            id="Type"
                            name="Type"
                            variant="outlined"
                            label="Type"
                            value={props.values.Type}
                            onChange={(e) => {
                              // console.log("selected user: ", e.target.value);
                              props.handleChange(e, "Type");
                              // updateReport(props.values.Country, props.values.Organization, props.values.Location, e.target.value);
                            }}
                            SelectProps={{ native: true }}
                            className={"form-control-alternative form-control-edit"}
                          >
                            <option key="" value=""></option>
                            <option key="CHSAT" value="CHSAT">
                              CHSAT
                            </option>
                            <option key="ICAT" value="ICAT">
                              ICAT
                            </option>
                            <option key="TIE" value="TIE">
                              TIE
                            </option>
                          </TextField>
                        </Col>
                      </Row>
                      <br />
                      <Row>
                        <Col className="text-right">
                          <Button
                            style={{ marginTop: 15, marginBottom: 5 }}
                            color="primary"
                            onClick={() => {
                              props.submitForm();
                              scrollToPivot();
                            }}
                          >
                            Update Results
                          </Button>
                        </Col>
                      </Row>
                    </Form>
                  )}
                </Formik>
              </CardBody>

              {/* question content */}
              <CardBody id="pivotCard">
                {reportConfiguration && !Lodash.isEmpty(reportConfiguration) ? (
                  <>
                    <WebDataRocksReact.Pivot ref={reportRef} D toolbar={true} beforetoolbarcreated={customizeToolbar} report={reportConfiguration} reportcomplete={reportComplete} />
                  </>
                ) : null}
              </CardBody>

              {/* survey footer */}
              <CardFooter className="py-4"></CardFooter>
            </Card>
          </Col>
        </Row>
      </Container>
    </>
  );
};

export default ReportAllAnswersBySegmentByOrganization;
