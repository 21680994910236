import { fetchRecommendationsBySurveyId } from "actions/survey";
import { fetchMasterDataLanguage } from "actions/masterData";
import { LOGINSTATE } from "../../../config/config.json";
import { initialValues_assessmentPage, roundValue, RTL_FLAG_RIGHT } from "helper";
import Lodash from "lodash";
import Moment from "moment";
import React, { useEffect, useState } from "react";
import { Radar } from "react-chartjs-2";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
// import { Col, Row } from "reactstrap";
import { Col, Row, Label } from "reactstrap";
import { isNamespaceExport } from "typescript";

const AnswerPrintTemplateByHtml = (font) => {
  // console.log("fontSize", fontSize);
  const history = useHistory();
  // const [fontSize, setFontSize] = useState(14);
  const dispatch = useDispatch();
  if (LOGINSTATE) console.log("font", font);

  const [systemContentLocale, setSystemContentLocale] = useState({});
  const [surveyRecommendations, setSurveyRecommendations] = useState({});

  const [refreshCounter, setRefreshCounter] = useState(0);
  const [refreshCount, setRefreshCount] = useState(0);

  const [segName, setSegName] = useState();
  const [segScore, setSegScore] = useState();
  const [organizatioName, setOrganizationName] = useState(" ");
  const [version, setVersion] = useState("");
  const [answer, setAnswer] = useState({});
  const [segment, setSegment] = useState({});
  const [userLanguage, setUserLanguage] = useState("en");
  const [locale, setLocale] = useState({});
  const [score, setScore] = useState({});

  const [rtlAlignment, setRtlAlignment] = useState("left");

  // Get pre-values to create the locale report, which are user answers, recommendations and the locale template.
  const answerAll = useSelector((state) => state.survey.answerAll);
  const surveyRecommendationAll = useSelector((state) => state.survey.recommendationAll);

  const handleResize = () => {
    // Set window width/height to state
    if (LOGINSTATE) console.log("Window width", window.innerWidth);
  };

  const userLang = useSelector((state) => state.masterData.languageByCode);
  if (LOGINSTATE) console.log("userLanguage", userLang);
  useEffect(() => {
    let params = {
      user: {
        code: jUser?.user?.language,
      },
    };
    dispatch(fetchMasterDataLanguage(params.user));
    // Handler to call on window resize

    // Add event listener
    window.addEventListener("resize", handleResize);
    // Call handler right away so state gets updated with initial window size
    handleResize();
    // Remove event listener on cleanup
    return () => window.removeEventListener("resize", handleResize);
  }, []); // Empty array ensures that effect is only run on mount

  useEffect(() => {
    userLang &&
      userLang.language &&
      Object.values(userLang.language).map((lang, i) => {
        if (lang && lang.dir === "rtl") {
          setRtlAlignment("right");
        }
      });
  }, [userLang]);

  if (LOGINSTATE) console.log("answerAll", answerAll);
  useEffect(() => {
    setRefreshCount(refreshCount + 1);
  }, [font]);

  useEffect(() => {
    let recommendationsLocale = Lodash.find(surveyRecommendationAll, { languageCode: userLanguage });

    // if the relevant locale recommendations are not available, take the default recommendations
    if (!recommendationsLocale) {
      recommendationsLocale = Lodash.find(surveyRecommendationAll, { languageCode: "en" });
    }

    if (recommendationsLocale) {
      setSurveyRecommendations(recommendationsLocale);
    } else {
      setSurveyRecommendations({});
    }
  }, [surveyRecommendationAll]);

  useEffect(() => {
    // check answer is available or exit;
    if (LOGINSTATE) console.log("#########: ", answerAll);

    let score = answerAll?.locale?.segScore;
    setSegScore(score ? score : "");

    let name = answerAll?.locale?.segName;
    // setSegName(name ? name : []); // Originail name, contain assessment code
    
    let tempNames = []
    if (name && Lodash.isArray(name) && !Lodash.isEmpty(name)) {
      name.forEach((name, i) => {
        tempNames.push(`#${i + 1}`)
      })
    }   
    setSegName(tempNames);

    let organization = answerAll?.locale?.answer?.institutionalQuestions[0].answer;
    setOrganizationName(organization);

    let versionNumber = answerAll?.locale?.answer.title;
    setVersion(versionNumber ? versionNumber : "");

    let survey = answerAll?.locale?.answer;
    setAnswer(survey ? survey : {});

    let segment = answerAll?.segment;
    setSegment(segment);

    if (!Lodash.isEmpty(answerAll?.segmentsLocale)) {
      let local = answerAll?.segmentsLocale;
      if (LOGINSTATE) console.log("local", local);
      setLocale(local);
    } else {
      setLocale(answerAll?.locale?.answer?.segments);
    }

    let segAndQuestionScore = answerAll?.locale?.answer?.segments;
    setScore(segAndQuestionScore);

    if (!Lodash.isEmpty(answerAll?.systemContentLocale)) {
      let systemContentLocal = answerAll?.systemContentLocale;
      setSystemContentLocale(systemContentLocal);
      if (LOGINSTATE) console.log("systemContentLocal", systemContentLocal);
    } else {
      setSystemContentLocale(initialValues_assessmentPage);
    }

    dispatch(fetchRecommendationsBySurveyId({ surveyId: survey?.surveyId }, history));

    let lang = answerAll?.locale?.language;
    setUserLanguage(lang);
  }, [answerAll]);

  useEffect(() => {
    setTimeout(() => {
      setRefreshCounter(refreshCounter + 1);
    }, 2000);
  }, [segName, segScore]);

  let currentUser = localStorage.getItem("userDetails");
  let jUser = JSON.parse(currentUser);

  const AnswerUiModel = ({ id, item, order, itemLocale, rtlAlignment }) => (
    <>
      {/* {console.log('item: ', item)}
      {console.log('item-score: ', item.score)} */}

      {rtlAlignment === RTL_FLAG_RIGHT ? (
        <Row style={{ marginBottom: 30, pointerEvents: "none", userSelect: "none", textAlign: rtlAlignment }}>
          <Col>
            <Row>
              <Col>
                <Label check style={{ fontWeight: "600", fontSize: font ? font?.font : 14 }}>
                  {itemLocale && itemLocale.question ? itemLocale.question : item.question}
                </Label>
                <Label check style={{ fontStyle: "italic", fontSize: font ? font?.font : 14 }}>
                  {itemLocale && itemLocale.questionNote ? itemLocale.questionNote : item.questionNote}
                </Label>
              </Col>
            </Row>

            <Row>
              <Col>
                <span style={{ color: "#000", fontSize: font ? font?.font : 14 }}>
                  {item.score === 0
                    ? systemContentLocale?.app_assessment_answer_opt1
                    : item.score === 1
                      ? systemContentLocale?.app_assessment_answer_opt2
                      : item.score === 2
                        ? systemContentLocale?.app_assessment_answer_opt3
                        : item.score === 3
                          ? systemContentLocale?.app_assessment_answer_opt4
                          : item.score === 4
                            ? systemContentLocale?.app_assessment_answer_opt5
                            : item.score === 5
                              ? systemContentLocale?.app_assessment_answer_opt6
                              : ""}
                  {" : "}{systemContentLocale?.answer}
                </span>
              </Col>
            </Row>

            <Row>
              <Col>
                <span style={{ color: "#000", fontSize: font ? font?.font : 14 }}>
                  {(item.remark && item.remark.length > 0) ? item.remark : systemContentLocale?.no_remark}
                  {" : "}{systemContentLocale?.answer_remark}
                </span>
              </Col>
            </Row>
            {/* <Row>
            <Col lg="12">
              <TextField
                fullWidth
                label={systemContentLocale?.answer_remark}
                variant="outlined"
                multiline
                rows={2}
                value={props.values.Remark}
                onChange={props.handleChange("Remark")}
                onBlur={props.handleSubmit}
                style={{ marginTop: 10, marginBottom: 10 }}
                // disabled={true}
              />
            </Col>
          </Row> */}
          </Col>
          <div style={{ marginRight: 30 }}>
            <Label check style={{ fontWeight: "600", fontSize: font ? font?.font : 14 }}>
              :{(order + 1) + "." + (id + 1)}
            </Label>
          </div>
        </Row>
      ) : (
        <Row style={{ marginBottom: 30, pointerEvents: "none", userSelect: "none", textAlign: rtlAlignment }}>
          <div style={{ marginLeft: 30 }}>
            <Label check style={{ fontWeight: "600", fontSize: font ? font?.font : 14 }}>
              {id + 1 + "." + (order + 1)}:
            </Label>
          </div>
          <Col>
            <Row>
              <Col lg="12">
                <Label check style={{ fontWeight: "600", fontSize: font ? font?.font : 14 }}>
                  {itemLocale && itemLocale.question ? itemLocale.question : item.question}
                </Label>
                <Label check style={{ fontStyle: "italic", fontSize: font ? font?.font : 14 }}>
                  {itemLocale && itemLocale.questionNote ? itemLocale.questionNote : item.questionNote}
                </Label>
              </Col>
            </Row>

            <Row>
              <Col lg="12">
                <span style={{ color: "#000", fontSize: font ? font?.font : 14 }}>
                  {systemContentLocale?.answer} :{" "}
                  {item.score === 0
                    ? systemContentLocale?.app_assessment_answer_opt1
                    : item.score === 1
                      ? systemContentLocale?.app_assessment_answer_opt2
                      : item.score === 2
                        ? systemContentLocale?.app_assessment_answer_opt3
                        : item.score === 3
                          ? systemContentLocale?.app_assessment_answer_opt4
                          : item.score === 4
                            ? systemContentLocale?.app_assessment_answer_opt5
                            : item.score === 5
                              ? systemContentLocale?.app_assessment_answer_opt6
                              : ""}
                </span>
              </Col>
            </Row>

            <Row>
              <Col lg="12">
                <span style={{ color: "#000", fontSize: font ? font?.font : 14 }}>
                  {systemContentLocale?.answer_remark} : {" "}
                  {(item.remark && item.remark.length > 0) ? item.remark : systemContentLocale?.no_remark}
                </span>
              </Col>
            </Row>
            {/* <Row>
              <Col lg="12">
                <TextField
                  fullWidth
                  label={systemContentLocale?.answer_remark}
                  variant="outlined"
                  multiline
                  rows={2}
                  value={props.values.Remark}
                  onChange={props.handleChange("Remark")}
                  onBlur={props.handleSubmit}
                  style={{ marginTop: 10, marginBottom: 10 }}
                  // disabled={true}
                />
              </Col>
            </Row> */}
          </Col>
        </Row>
      )}
    </>
  );

  return (
    <>
      <Row style={{ textAlign: rtlAlignment }} id={refreshCount}>
        <Col style={styles.section}>
          <Row>
            <Col style={{ textAlign: "right" }}>
              <span className="mb-0" style={{ fontWeight: 700, fontSize: font ? font?.font + 2 : 16 }}>
                {organizatioName}
              </span>
              <br />
              <span className="mb-0" style={{ fontSize: font ? font?.font : 14 }}>
                {/* {Moment(answerAll?.locale?.answer?.updatedAt).format("YYYY-MM-DD hh:mm A")} */}
                {answerAll?.locale?.answer?.clientTimestamp}
              </span>
            </Col>
          </Row>

          <Row className="text-center" style={{ fontSize: font ? font?.font : 14 }}>
            <Col>
              <div style={styles.image}>
                <img alt="logo" src={require("../../../assets/img/brand/img_logo.png").default} style={{ width: 200, height: "auto" }} />
              </div>
            </Col>
          </Row>

          <Row className="text-center" style={{ fontWeight: 700, fontSize: font ? font?.font + 2 : 16 }}>
            <Col style={{ ...styles.title }}>
              <span>{answerAll?.locale?.answer?.type === "CHSAT" ? systemContentLocale.chsat : answerAll?.locale?.answer?.type === "ICAT" ? systemContentLocale.icat : systemContentLocale.tie}</span>
            </Col>
          </Row>

          <Row className="text-center" style={{ fontSize: font ? font?.font : 14 }}>
            <Col style={{ ...styles.author }}>
              <span>
                {systemContentLocale.version} : {version}
              </span>
            </Col>
          </Row>
          <Row style={{ fontSize: font ? font?.font : 14 }}>
            {/* <CardHeader className="bg-transparent">{refreshCounter}</CardHeader> */}
            <Col>
              {/* <div className="chart" style={{ marginLeft: 100, marginRight: 25, maxWidth:1000 , height: 500 }}> */}
              <div>
                <Radar
                  id={refreshCounter}
                  style={{ height: window.innerWidth, maxHeight: 500 }}
                  data={{
                    labels: segName,
                    datasets: [
                      {
                        label: systemContentLocale.segment_score,
                        xlabel: segName,
                        data: segScore,
                        fill: true,
                        backgroundColor: "rgba(175, 30, 35, 0.5)",
                        borderColor: "#af1e23",
                        pointRadius: "1",
                        pointBackgroundColor: "#fff",
                        pointBorderColor: "#fff",
                        pointHoverBackgroundColor: "#fff",
                        pointHoverBorderColor: "rgb(255, 99, 132)",
                      },
                    ],
                  }}
                  options={{
                    tooltips: {
                      enabled: true,
                      rtl: false,
                      callbacks: {
                        title: function (item, data) {
                          return data.labels[item[0]?.index] && item.length === 1 ? data.labels[item[0]?.index] : null;
                        },
                      },
                    },
                    // legend: {display: true },
                    scale: {
                      ticks: {
                        beginAtZero: true,
                        max: 5,
                        min: 0,
                        stepSize: 1,
                      },
                    },
                    responsive: true,
                    maintainAspectRatio: true,
                  }}
                // getDatasetAtEvent={(e) => console.log(e)}
                />
              </div>
              <br />
              <br />

              {/* legend of the graph */}
              {answerAll?.segmentsLocale
                ? (answerAll?.segmentsLocale).map((segment, i) => (
                  <Row>
                    {/* Graph legend for internationalization */}
                    <Col style={{ marginLeft: 12, marginRight: 25, marginBottom: 5, fontSize: font ? font?.font - 2 : 12, color: "black" }}>
                      {rtlAlignment === RTL_FLAG_RIGHT
                        ? <span>{segment.title} .#{i + 1}</span>
                        : <span>#{i + 1}. {segment.title}</span>
                      }
                    </Col>
                  </Row>
                ))
                : answerAll?.locale?.answer?.segments.map((segment, i) => (
                  <Row>
                    <Col style={{ marginLeft: 12, marginRight: 25, marginBottom: 5, fontSize: font ? font?.font - 2 : 12, color: "black" }}>
                      {rtlAlignment === RTL_FLAG_RIGHT
                        ? <span>{segment.title} .#{i + 1}</span>
                        : <span>#{i + 1}. {segment.title}</span>
                      }
                    </Col>
                  </Row>
                ))}
            </Col>
          </Row>
          <br />
          <br />

          {/* answers and recommendations */}
          <Row>
            <Col style={{ ...styles.h3, fontSize: font ? font?.font + 4 : 18, textAlign: rtlAlignment }}>
              <span>{systemContentLocale.overall}</span>
            </Col>
          </Row>

          <Row>
            <Col style={{ ...styles.h3, fontSize: font ? font?.font : 14, textAlign: rtlAlignment }}>
              <span>
                {systemContentLocale.total_score} : {answer?.surveyScore}
              </span>
            </Col>
          </Row>

          {/* Recommendation print */}
          {surveyRecommendations && !Lodash.isEmpty(surveyRecommendations?.overall?.recommendations) ? (
            surveyRecommendations?.overall?.recommendations.map((recom, i) => {
              return recom.from <= answer?.surveyScore && answer?.surveyScore <= recom.to ? (
                <Row style={styles.recommendation}>
                  <Col style={{ ...styles.textRecommendation, fontSize: font ? font?.font : 14 }}>
                    <span>{recom.recommendation ? recom.recommendation : null}</span>
                  </Col>
                </Row>
              ) : null;
            })
          ) : (
            <Row style={styles.recommendation}>
              <Col style={{ ...styles.textRecommendation, fontSize: font ? font?.font : 14 }}>
                <span>{systemContentLocale.no_recommendation}</span>
              </Col>
            </Row>
          )}
          <br />
          <br />
          <Row>
            <Col style={{ ...styles.h3, fontSize: font ? font?.font + 4 : 18, textAlign: rtlAlignment }}>
              <span>{systemContentLocale.segment}</span>
            </Col>
          </Row>

          {segment && !Lodash.isEmpty(segment)
            ? Object.values(segment).map((segmentAnswer, i) => {
              return (
                <>
                  {/* {console.log("locale", locale)} */}
                  <Row>
                    <Col style={{ ...styles.segment, fontSize: font ? font?.font + 1 : 15, textAlign: rtlAlignment }}>
                      {/* Segment title print */}
                      <span>{locale && !Lodash.isEmpty(locale) ? locale[i]?.title : surveyRecommendations?.segments[i].title}</span>
                    </Col>
                  </Row>

                  <Row>
                    <Col style={{ ...styles.h3, fontSize: font ? font?.font : 14, textAlign: rtlAlignment }}>
                      {/* segment score print */}
                      <span>
                        {systemContentLocale.segment_score} : {locale[i].segmentScore ? roundValue(locale[i].segmentScore) : segScore[i]}
                      </span>
                    </Col>
                  </Row>

                  {surveyRecommendations && surveyRecommendations?.segments && surveyRecommendations?.segments[i] && !Lodash.isEmpty(surveyRecommendations?.segments[i]?.recommendations) ? (
                    surveyRecommendations?.segments[i]?.recommendations.map((recom, j) => {
                      return recom.from <= segmentAnswer.segmentScore && segmentAnswer.segmentScore <= recom.to ? (
                        <>
                          <Row style={styles.recommendation}>
                            <Col style={{ ...styles.textRecommendation, fontSize: font ? font?.font : 14 }}>
                              {/* Segment recommendation print */}
                              <span>{recom.recommendation}</span>
                            </Col>
                          </Row>
                        </>
                      ) : null;
                    })
                  ) : (
                    <Row style={styles.recommendation}>
                      <Col style={{ ...styles.textRecommendation, fontSize: font ? font?.font : 14 }}>
                        {/* Segment no recommendation print */}
                        <span>{systemContentLocale.no_recommendation}</span>
                      </Col>
                    </Row>
                  )}

                  {!Lodash.isEmpty(segment) &&
                    Object.values(segment[i].questions).map((item, j) => {
                      return <AnswerUiModel id={i} key={j} item={item} order={j} itemLocale={locale && locale[i] && locale[i]?.questions && locale[i]?.questions[j] ? locale[i]?.questions[j] : {}} rtlAlignment={rtlAlignment} />;
                    })}
                </>
              );
            })
            : " "}
        </Col>
      </Row >
    </>
  );
};

// Create styles
const styles = {
  page: {
    // paddingTop: 35,
    // paddingBottom: 65,
    // paddingHorizontal: 35,
  },
  section: {
    margin: 0,
    padding: 35,
  },
  h3: {
    color: "#af1e23",
    marginLeft: 12,
    marginRight: 12,
    // fontSize: 14,
    textAlign: "justify",
    fontWeight: "bold",
  },
  row: {
    flexGrow: 1,
    flexDirection: "row",
  },
  title: {
    // fontSize: 24,
    textAlign: "center",
  },
  author: {
    // fontSize: 12,
    textAlign: "center",
    marginBottom: 40,
  },
  subtitle: {
    // fontSize: 18,
    margin: 12,
    fontWeight: "bold",
  },
  segment: {
    // color: "#af1e23",
    fontWeight: "bold",
    fontSize: 15,
    // margin: 12,
    marginLeft: 12,
    marginRight: 12,
  },
  text: {
    margin: 12,
    // fontSize: 14,
    textAlign: "justify",
  },
  textRecommendation: {
    color: "#af1e23",
    margin: 12,
    marginBottom: 5,
    // fontSize: 14,
    // textAlign: "justify",
  },
  question: {
    margin: 12,
    marginBottom: 12,
    // fontSize: 14,
    textAlign: "justify",
    marginLeft: 30,
  },
  answer: {
    fontSize: 15,
    marginLeft: 30,
  },
  textNote: {
    margin: 12,
    marginBottom: 5,
    fontSize: 14,
    textAlign: "justify",
  },
  image: {
    marginVertical: 5,
    marginHorizontal: 150,
    alignContent: "center",
    textAlign: "center",
  },
  pageNumber: {
    position: "absolute",
    fontSize: 12,
    bottom: 30,
    left: 0,
    right: 0,
    textAlign: "center",
    color: "grey",
  },
  dateAndTime: {
    position: "absolute",
    fontSize: 12,
    bottom: 30,
    right: 35,
    textAlign: "right",
    color: "grey",
  },
  recommendation: {
    // marginBottom: 12,
  },
};

export default AnswerPrintTemplateByHtml;
