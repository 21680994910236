import { SURVEY_TEMPLATE_LOCALE_LOCAL } from "constants/actionTypes";

import { FormControl, TextField } from "@material-ui/core";

// core components
import PageHeader from "components/Headers/PageHeader.js";
import Lodash from "lodash";
import React, { useEffect, useState, useRef } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router-dom";

// reactstrap components
import { Card, CardBody, CardFooter, CardHeader, CardTitle, Col, Container, Row, Form, Button } from "reactstrap";
import Pagination from "react-responsive-pagination";

import { ChsStyles, GlobalStylesColor } from "constants/globalStyles";
import { LOGINSTATE } from "../../../config/config.json";
import { Formik } from "formik";
import * as Yup from "yup";
import { confirmAlert } from "react-confirm-alert";
import "react-confirm-alert/src/react-confirm-alert.css"; // Import css

import { createLocalizationBySurveyTemplate, updateLocalizationBySurveyTemplate } from "actions/survey";
import { fetchMasterDataLanguageAll } from "actions/masterData";
import { survey_status } from "helper";

const reviewSchema = Yup.object({
  Title: Yup.string().required().min(4).max(1000),
  Code: Yup.string().required().min(2).max(10),
});

const reviewSurveySchema = Yup.object({
  Title: Yup.string().required().min(4).max(1000),
  Version: Yup.number().required(),
  LanguageCode: Yup.string().required("Language is a required field").min(2),
});

const SurveyLocalizationLanguage = (props) => {
  // dispatch and history
  const dispatch = useDispatch();
  const history = useHistory();

  const initSurvey = {
    version: 0.0,
    title: "CHSAT",
    description: "Survey description",
    type: "TIE",
    status: false,
    segments: [],
  };

  const initSegmentData = {
    title: "",
    description: "",
    segmentCode: "",
    segmentOrder: 0,
    questions: [],
  };

  const [surveyStarted, setSurveyStarted] = useState(false);
  const [surveyNew, setSurveyNew] = useState(initSurvey); // Create survey details
  const [segmentData, setSegmentData] = useState([]); // Segment details
  const [segmentCount, setSegmentCount] = useState(0); // To get the maximum number of segments
  const [segmentCounter, setSegmentCounter] = useState(0); // Current active segment
  const [navigationCounter, setNavigationCounter] = useState(0); // To support footer navigation
  const [isNew, setIsNew] = useState(false);
  const [chsLanguages, setChsLanguages] = useState([]); // For translations
  const [showPagination, setShowPagination] = useState(true);
  
  // To update fomik values
  const formRef = useRef();
  const formSurveyRef = useRef();

  useEffect(() => {
    if (LOGINSTATE) console.log("---------------> called effect1");

    // Loading sequence for the data
    if (localStorage.getItem(SURVEY_TEMPLATE_LOCALE_LOCAL)) {
      let surveySaved = JSON.parse(localStorage.getItem(SURVEY_TEMPLATE_LOCALE_LOCAL));
      if (LOGINSTATE) console.log("--------------------- local store surveySaved: ", surveySaved);
      if (surveySaved !== undefined || surveySaved !== null) {
        setInitSurvey(surveySaved);
      }
    }

    if (props.location.state?.survey && !Lodash.isEmpty(props.location.state?.survey)) {
      setSurveyStarted(true);
    }

    dispatch(fetchMasterDataLanguageAll());
  }, []);

  const surveySaved = useSelector((state) => state.survey.localSurveyTemplateLocale);
  useEffect(() => {
    if (LOGINSTATE) console.log("---------------> called effect2");
    if (LOGINSTATE) console.log("dispatch surveySaved: ", surveySaved);

    if (surveySaved !== undefined && surveySaved !== null) {
      setInitSurvey(surveySaved);
    }
  }, [surveySaved]);

  const languageAll = useSelector((state) => state.masterData.languageAll);
  useEffect(() => {
    if (LOGINSTATE) console.log("languageAll: ", props.location.state?.survey.languageCode);

    if (languageAll !== undefined && languageAll !== null) {
      setChsLanguages(props.location.state?.languages);
    }
  }, [languageAll]);

  const setInitSurvey = (surveySaved) => {
    if (LOGINSTATE) console.log("surveySaved: ", surveySaved);
    // get active survey details
    if (!Lodash.isEmpty(surveySaved) && surveySaved.segments && !Lodash.isEmpty(surveySaved.segments)) {
      if (LOGINSTATE) console.log("survey is updating...");
      setSurveyNew(surveySaved);
      setSegmentData(surveySaved.segments);
      setSegmentCount(surveySaved.segments.length);
      if (Lodash.has(surveySaved, "languageCode") && !Lodash.isEmpty(surveySaved.languageCode)) {
        if (LOGINSTATE) console.log("language code exist: ", surveySaved.languageCode);
        setIsNew(false);
      } else {
        setIsNew(true);
      }
    }
  };

  useEffect(() => {
    if (LOGINSTATE) console.log("---------------> called effect3");

    if (formRef.current && !Lodash.isEmpty(segmentData)) {
      formRef.current?.resetForm();
      formRef.current.setFieldValue("Title", segmentData[segmentCounter].title, false);
      formRef.current.setFieldValue("Description", segmentData[segmentCounter].description, false);
      formRef.current.setFieldValue("Code", segmentData[segmentCounter].segmentCode, false);
    }

    if (formSurveyRef.current && !Lodash.isEmpty(surveyNew)) {
      if (LOGINSTATE) console.log("survey details");
      formSurveyRef.current.setFieldValue("Version", surveyNew.version, false);
      formSurveyRef.current.setFieldValue("Title", surveyNew.title, false);
      formSurveyRef.current.setFieldValue("Description", surveyNew.description, false);
      formSurveyRef.current.setFieldValue("LanguageCode", surveyNew.languageCode, false);
    }
  }, [segmentCounter, segmentData]);

  // Process array actions
  const processArray = async (array, process, position, value) => {
    let deletedElements = null;
    if (process === "ADD") {
      deletedElements = array.splice(position, 0, value);
      return array;
    } else if (process === "DELETE") {
      deletedElements = array.splice(position, 1);
      return array;
    } else if (process === "MOVEUP") {
      if (array.length > position + 1) {
        let temp = array[position];
        array[position] = array[position + 1];
        array[position + 1] = temp;
        return array;
      } else {
        return [];
      }
    } else if (process === "MOVEDOWN") {
      if (0 <= position - 1) {
        let temp = array[position];
        array[position] = array[position - 1];
        array[position - 1] = temp;
        return array;
      } else {
        return [];
      }
    } else if (process === "UPDATEAT") {
      array[position] = value;
      return array;
    } else {
      return array;
    }
  };

  const updateSegment = async (values) => {
    let updatedSegment = segmentData[segmentCounter];
    updatedSegment["title"] = values.Title;
    updatedSegment["description"] = values.Description;
    updatedSegment["segmentCode"] = values.Code;
    updatedSegment["segmentOrder"] = segmentCounter;

    let updatedSegments = await processArray(segmentData, "UPDATEAT", segmentCounter, updatedSegment);
    setSegmentData(updatedSegments);
    updateLocalSurvey(updatedSegments);
  };

  // save the survey's main details
  const updateSurveyDetails = async (values) => {
    let survey = surveyNew;
    survey["version"] = values.Version;
    survey["title"] = values.Title;
    survey["description"] = values.Description;
    survey["languageCode"] = values.LanguageCode;
    setSurveyNew(survey);
    dispatch({ type: SURVEY_TEMPLATE_LOCALE_LOCAL, payload: survey });
    localStorage.setItem(SURVEY_TEMPLATE_LOCALE_LOCAL, JSON.stringify(survey));
  };

  // save the survey's segment details
  const updateLocalSurvey = (updatedSegments) => {
    // Locally save the survey
    let survey = surveyNew;
    survey["segments"] = updatedSegments;
    setSurveyNew(survey);
    dispatch({ type: SURVEY_TEMPLATE_LOCALE_LOCAL, payload: survey });
    localStorage.setItem(SURVEY_TEMPLATE_LOCALE_LOCAL, JSON.stringify(survey));
  };

  // TODO: check whether its need to save the segment before saving questions.
  const handleQuestionsClick = (e) => {
    history.push({ pathname: "/registered/assessment_localization_language_question", state: { segmentCounter: segmentCounter, segmentData: segmentData } });
  };

  // TODO: properly save the survey. Currenly not validating the survey before it's save to the surver.
  const handleSurveySave = async (isComplete) => {
    let languages = ["en", ...props.location.state?.languages];

    let surveySaved = null;
    if (localStorage.getItem(SURVEY_TEMPLATE_LOCALE_LOCAL)) {
      surveySaved = JSON.parse(await localStorage.getItem(SURVEY_TEMPLATE_LOCALE_LOCAL));
      if (LOGINSTATE) console.log("local store surveySaved: ", surveySaved);
    }

    if (surveySaved !== undefined && surveySaved !== null && !Lodash.isEmpty(surveySaved)) {
      // language code is mandatory
      if (!(surveySaved.languageCode && surveySaved.languageCode.length >= 2)) {
        confirmAlert({ title: "Error", message: "'Language Code*' is unavailable. Please update the locale.", buttons: [{ label: "Ok", onClick: () => {} }],closeOnEscape: false, closeOnClickOutside: false });
        return;
      }
      // language locale should be unique. if a new locale, check it.
      else if (!surveySaved.languageCode && languages.includes(surveySaved.languageCode)) {
        confirmAlert({ title: "Error", message: "Locale already exists.", buttons: [{ label: "Ok", onClick: () => {} }], closeOnEscape: false, closeOnClickOutside: false });
        return;
      } else {
        // Add a validation method

        surveySaved["status"] = isComplete ? survey_status.COMPLETE : survey_status.PROGRESS;

        if (isNew) {
          if (LOGINSTATE) console.log("create the locale with: ", surveySaved);
          surveySaved["surveyId"] = surveySaved._id;
          surveySaved["published"] = false;
          dispatch(createLocalizationBySurveyTemplate(surveySaved, history));
        } else {
          if (LOGINSTATE) console.log("update the locale with: ", surveySaved);
          surveySaved["localizationId"] = surveySaved._id;
          dispatch(updateLocalizationBySurveyTemplate(surveySaved, history));
        }
      }
    } else {
      return confirmAlert({ title: "Error", message: "Assessment locale error. Please try again.", buttons: [{ label: "Ok", onClick: () => {} }],closeOnEscape: false, closeOnClickOutside: false });
    }
  };

  return (
    <>
      <PageHeader />

      {/* Page content */}
      <Container className="mt--7" fluid>
        {/* Custom cards for survey type */}

        {/* Card stats */}
        <Row className="mt-5">
          <Col lg="8">
            <Card className="card-stats mb-4 mb-xl-0">
              <CardBody>
                <Row>
                  <div className="col">
                    <CardTitle tag="h5" className="text-uppercase text-muted mb-0">
                      CHS Tool
                    </CardTitle>
                    <span className="h2 font-weight-bold mb-0">{surveyNew.version}: Current locale version</span>
                  </div>
                  <Col className="col-auto">
                    <div className="icon icon-shape bg-danger text-white rounded-circle shadow">
                      <i className="fas fa-clipboard-check" style={{ fontSize: 32 }} />
                    </div>
                  </Col>
                </Row>
              </CardBody>
              <CardBody>
                <Formik
                  initialValues={{
                    Title: "",
                    Description: "",
                    Version: 0.0,
                    LanguageCode: "",
                  }}
                  validationSchema={reviewSurveySchema}
                  onSubmit={(values, actions) => {
                    updateSurveyDetails(values);
                  }}
                  innerRef={formSurveyRef}
                >
                  {(props) => (
                    <Form role="form" style={{ marginTop: -25 }} onSubmit={props.handleSubmit}>
                      <Row>
                        <Col lg="4">
                          <TextField
                            fullWidth
                            label={"Assessment Version*"}
                            variant="outlined"
                            value={props.values.Version}
                            onChange={props.handleChange("Version")}
                            onBlur={props.handleSubmit}
                            style={ChsStyles.Question}
                            className={"form-control-alternative form-control-edit"}
                            error={props.touched.Version && Boolean(props.errors.Version)}
                            helperText={props.touched.Version && props.errors.Version}
                            disabled={true}
                          />
                        </Col>

                        <Col lg="4">
                          <FormControl fullWidth style={ChsStyles.Input}>
                            <TextField
                              select
                              fullWidth
                              label={"Language*"}
                              variant="outlined"
                              value={props.values.LanguageCode}
                              onChange={props.handleChange("LanguageCode")}
                              onBlur={props.handleSubmit}
                              style={ChsStyles.Question}
                              SelectProps={{ native: true }}
                              className={"form-control-alternative form-control-edit"}
                              error={props.touched.LanguageCode && Boolean(props.errors.LanguageCode)}
                              helperText={props.touched.LanguageCode && props.errors.LanguageCode}
                              disabled={!isNew}
                            >
                              <option key="" value=""></option>
                              {chsLanguages.map((key, i) => {
                                return (
                                  <option key={i} value={key.code}>
                                    {key.name}
                                  </option>
                                );
                              })}
                            </TextField>
                          </FormControl>
                        </Col>
                      </Row>

                      <Row>
                        <Col lg="12">
                          <TextField
                            fullWidth
                            label={"Assessment Title*"}
                            variant="outlined"
                            value={props.values.Title}
                            onChange={props.handleChange("Title")}
                            onBlur={props.handleSubmit}
                            style={ChsStyles.Question}
                            className={"form-control-alternative form-control-edit"}
                            error={props.touched.Title && Boolean(props.errors.Title)}
                            helperText={props.touched.Title && props.errors.Title}
                          />
                        </Col>
                      </Row>

                      <Row>
                        <Col lg="12">
                          <TextField
                            fullWidth
                            label={"Assessment Description"}
                            variant="outlined"
                            value={props.values.Description}
                            onChange={props.handleChange("Description")}
                            onBlur={props.handleSubmit}
                            style={ChsStyles.Question}
                            className={"form-control-alternative form-control-edit"}
                            error={props.touched.Description && Boolean(props.errors.Description)}
                            helperText={props.touched.Description && props.errors.Description}
                          />
                        </Col>
                      </Row>
                    </Form>
                  )}
                </Formik>
              </CardBody>
            </Card>
          </Col>
        </Row>

        <br />

        {/* Ask to select a survey */}
        <Row style={{ display: surveyStarted ? "none" : "block" }}>
          <div className="col">
            <Card className="shadow">
              {/* survey header */}
              <CardHeader className="border-0">
                <div className="row">
                  <div className="mb-xl-0 col-11">
                    <small>APP Self Assessment Tools</small>
                    <h3 className="mb-0"></h3>
                    <h6 className="mb-0"> </h6>
                  </div>
                </div>
                <Row>
                  <Col lg="12" className="text-center">
                    <Button
                      style={ChsStyles.BottomButton}
                      color="primary"
                      onClick={() => {
                        setSurveyStarted(true);
                      }}
                    >
                      OK
                    </Button>
                  </Col>
                </Row>
              </CardHeader>
              <CardFooter className="py-4"></CardFooter>
            </Card>
          </div>
        </Row>

        {/* create survey segments */}
        <Row style={{ display: surveyStarted ? "block" : "none" }}>
          <div className="col">
            <Card className="shadow">
              {/* survey header */}
              <CardHeader className="border-0">
                <Row>
                  <Col lg="9">
                    <small>{surveyNew.title}</small>
                    {/* {console.log("$segmentCounter", segmentCounter)}
                    {console.log("$segmentData", segmentData)} */}
                    <h6 className="mb-0">Managing segments</h6>
                  </Col>
                </Row>
              </CardHeader>

              {/* segment content */}
              <CardBody style={{ display: segmentData.length > 0 ? "block" : "none" }}>
                <Formik
                  initialValues={{
                    Title: "",
                    Description: "",
                    Code: "",
                  }}
                  validationSchema={reviewSchema}
                  onSubmit={(values, actions) => {
                    updateSegment(values);
                  }}
                  innerRef={formRef}
                >
                  {(props) => (
                    <Form role="form" onSubmit={props.handleSubmit} onReset={props.handleReset}>
                      <Row>
                        <Col lg="6">
                          <TextField
                            fullWidth
                            label={"Segment Code*"}
                            variant="outlined"
                            value={props.values.Code}
                            onChange={props.handleChange("Code")}
                            onBlur={props.handleSubmit}
                            style={ChsStyles.Question}
                            className={"form-control-alternative form-control-edit"}
                            error={props.touched.Code && Boolean(props.errors.Code)}
                            helperText={props.touched.Code && props.errors.Code}
                            disabled={true}
                          />
                          <div style={ChsStyles.QuickNote}>This code should be unique to identify the segment for this assessment.</div>
                        </Col>
                      </Row>

                      <Row>
                        <Col lg="12">
                          <TextField
                            fullWidth
                            label={"Segment Title*"}
                            variant="outlined"
                            value={props.values.Title}
                            onChange={props.handleChange("Title")}
                            onBlur={props.handleSubmit}
                            style={ChsStyles.Question}
                            className={"form-control-alternative form-control-edit"}
                            error={props.touched.Title && Boolean(props.errors.Title)}
                            helperText={props.touched.Title && props.errors.Title}
                          />
                        </Col>
                      </Row>

                      <Row>
                        <Col lg="12">
                          <TextField
                            fullWidth
                            label={"Segment Description"}
                            variant="outlined"
                            value={props.values.Description}
                            onChange={props.handleChange("Description")}
                            onBlur={props.handleSubmit}
                            style={ChsStyles.Question}
                            className={"form-control-alternative form-control-edit"}
                            error={props.touched.Description && Boolean(props.errors.Description)}
                            helperText={props.touched.Description && props.errors.Description}
                          />
                        </Col>
                      </Row>

                      <Row>
                        <Col lg="6" className="text-left"></Col>
                        <Col lg="6" className="text-right"></Col>
                      </Row>
                    </Form>
                  )}
                </Formik>

                <Row>
                  <Col lg="12" style={{ marginTop: 25 }}>
                    {segmentData && !Lodash.isEmpty(segmentData) && !Lodash.isEmpty(segmentData[segmentCounter].questions) && segmentData[segmentCounter].questions.length > 0 ? (
                      <Row>
                        <p style={{ marginLeft: 10, marginRight: 10 }}>{segmentData[segmentCounter].questions.length + " questions."}</p>

                        <i
                          className="fas fa-plus-circle"
                          style={{ fontSize: 28, color: GlobalStylesColor.mbppThemeBlue, marginRight: 10, marginTop: -5 }}
                          onClick={(e) => {
                            e.preventDefault();
                            handleQuestionsClick(e);
                          }}
                        />
                        <span className="sr-only">Add</span>
                        <p style={{ marginLeft: 5 }}>Click on plus icon to edit questions.</p>
                      </Row>
                    ) : (
                      <>
                        <p style={{ marginRight: 10 }}>No questions.</p>
                      </>
                    )}
                  </Col>
                </Row>
              </CardBody>
              <CardBody style={{ display: segmentData.length > 0 ? "none" : "block" }}>
                <Row>
                  <Col lg="12">
                    <p style={{ marginLeft: 5 }}>No segments.</p>
                  </Col>
                </Row>
              </CardBody>

              {/* survey footer */}
              <CardFooter className="py-4">
                <Row>
                  <Col lg="12" className="text-center">
                  <div aria-label="..." style={{ marginBottom: 5, marginTop: 5, overflowX: "auto" }}>
                      {showPagination && segmentCount && segmentCount > 0 ? (
                        <Pagination
                          current={segmentCounter + 1}
                          total={segmentCount}
                          onPageChange={(current) => {
                            setShowPagination(false);
                            setSegmentCounter(current - 1);

                            setShowPagination(true);
                          }}
                        ></Pagination>
                      ) : null}
                    </div>
                  </Col>
                </Row>
                <Row style={{ marginTop: 10 }}>
                  <Col lg="6">
                    <Button
                      style={ChsStyles.GeneralButton}
                      color="primary"
                      onClick={() => {
                        history.goBack();
                      }}
                    >
                      BACK
                    </Button>
                  </Col>

                  <Col lg="6" className="text-right">
                    <Button
                      style={ChsStyles.GeneralButton}
                      color="primary"
                      onClick={() => {
                        handleSurveySave(false);
                      }}
                    >
                      PARTLY SAVE
                    </Button>

                    {segmentCounter + 1 === segmentCount ? (
                      <Button
                        style={ChsStyles.GeneralButton}
                        color="primary"
                        onClick={() => {
                          handleSurveySave(true);
                        }}
                      >
                        COMPLETE LOCALE
                      </Button>
                    ) : (
                      <Button
                        style={ChsStyles.GeneralButton}
                        color="primary"
                        onClick={() => {
                          setSegmentCounter(segmentCounter + 1);
                        }}
                      >
                        NEXT
                      </Button>
                    )}
                  </Col>
                </Row>
              </CardFooter>
            </Card>
          </div>
        </Row>
      </Container>
    </>
  );
};

export default SurveyLocalizationLanguage;
