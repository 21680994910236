import {
  USER_FETCH_ALL,
  USER_FETCH_ONE,
  USER_UPDATE,
  USER_FETCH_ONE_ORG,
  COUNTRY_FETCH_ALL,
  IS_HUMAN,
  USER_FETCH_BY_ADMIN,
} from 'constants/actionTypes';

const initState = {
  userProfile: [],
  usersProfiles: [],
  userProfileUpdate: [],
  userProfileOrg: {},
  countryAll: [],
  userProfileOrgByAdmin:{},
  isHuman: {},
}

const userReducer = (user = initState, action) => {
  switch (action.type) {
    case USER_FETCH_ONE:
      return { ...user, userProfile: action.payload };
    case USER_FETCH_ONE_ORG:
      return { ...user, userProfileOrg: action.payload };
    case USER_FETCH_ALL:
      return { ...user, usersProfiles: action.payload };
    case USER_UPDATE:
      return { ...user, userProfileUpdate: action.payload };
    case USER_FETCH_BY_ADMIN:
      return { ...user, userProfileOrgByAdmin: action.payload };
    case COUNTRY_FETCH_ALL:
      return { ...user, countryAll: action.payload };
    case IS_HUMAN:
      return { ...user, isHuman: action.payload };
    default:
      return user;
  }
};

export default userReducer;
