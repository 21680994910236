import { Grid, IconButton, InputAdornment, TextField } from "@material-ui/core";
import Visibility from "@material-ui/icons/Visibility";
import VisibilityOff from "@material-ui/icons/VisibilityOff";
import { signupadmin } from "actions/user";
import { ChsStyles, MbppStyles } from "constants/globalStyles";
import { useFormik } from "formik";
import { CryptoPassword } from "helper.js";
import React, { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import { confirmAlert } from "react-confirm-alert";
import "react-confirm-alert/src/react-confirm-alert.css"; // Import css
import PageHeader from "components/Headers/PageHeader.js";
import ReCaptchaV2 from "react-google-recaptcha";
import { APP_RECAPTCHA, LOGINSTATE } from "../../../config/config.json";
// reactstrap components
import { Button, Card, CardBody, Col, Form, FormGroup, Row, Container } from "reactstrap";
import * as yup from "yup";

const validationSchema1 = yup.object({
  email: yup.string("Enter your email").email("Enter a valid email").required("Email is required"),
  password: yup.string("Enter your password").min(6, "6 or more characters").required("Password is required"),
  confirmPassword: yup.string("Passwords must match").test("passwords-match", "Those passwords didn’t match. Try again.", function (value) {
    return this.parent.password === value;
  }),
});

const Register = () => {
  // dispatch and history
  const dispatch = useDispatch();
  const history = useHistory();
  // set state contact number list
  const [contactList, setContactList] = useState([{ index: 0, contactno: "" }]);
  // set state signup /signin
  const [isSignup, setIsSignup] = useState(true);
  const [isRegEnable, setIsRegEnable] = useState(false);
  const [isRegToken, setIsRegToken] = useState("");

  const switchMode = (e) => {
    e.preventDefault();
    setIsSignup((prevIsSignin) => !prevIsSignin);
  };

  // handle click event of password visible and password dotted
  const [showPassword, setShowPassword] = useState(false);
  const handleShowPassword = () => setShowPassword(!showPassword);

  const allOrg = useSelector((state) => state.organization.organizationAll);
  if (LOGINSTATE) console.log("allOrg", allOrg);

  const allCountry = useSelector((state) => state.user.countryAll);
  if (LOGINSTATE) console.log("allCountry", allCountry);

  const formik = useFormik({
    initialValues: {
      firstname: "",
      lastname: "",
      email: "",
      password: "",
      confirmPassword: "",
      urole: "ADMIN",
      emp_designation: "",
      emp_institute: "",
      language: "",
      recapture: "",
    },
    validationSchema: validationSchema1,
    onSubmit: (values, onSubmitProps) => {
      if (LOGINSTATE) console.log("values", values);

      values.password = CryptoPassword(values.password);
      values["urole"] = "ADMIN";

      dispatch(signupadmin(values, history));
      if (LOGINSTATE) console.log("New admin");

      onSubmitProps.resetForm();
    },
    onReset: () => {
      setContactList([{ index: 0, contactno: "" }]);
    },
  });

  const handleExpire = () => {
    setIsRegEnable(false);
  };

  return (
    <>
      <PageHeader />
      {/* Page content */}
      <Container className="mt--7" fluid>
        <Row className="mt-5" align="center">
          <Col lg="3"></Col>
          <Col lg="6">
            <Card className="bg-secondary shadow border-1">
              <CardBody className="px-lg-4 py-lg-4">
                <div className="heading-title text-center mb-4" style={MbppStyles.Topic}>
                  <span>{"Admin Register"}</span>
                </div>
                <br />

                <Form role="form" onSubmit={formik.handleSubmit} onReset={formik.handleReset}>
                  <FormGroup className="mb-3 ">
                    <>
                      <hr className="my-2" />
                      <h6 className="heading-small mb-4" style={MbppStyles.SubTopic}>
                        Personal Details
                      </h6>
                    </>

                    <Grid container spacing={1} alignItems="center" style={MbppStyles.Grid}>
                      <Grid item xs={12} sm={12}>
                        <TextField
                          fullWidth
                          id="firstname"
                          name="firstname"
                          label="Firstname*"
                          variant="outlined"
                          value={formik.values.firstname}
                          onChange={formik.handleChange}
                          className={"form-control-alternative form-control-edit"}
                          error={formik.touched.firstname && Boolean(formik.errors.firstname)}
                          helperText={formik.touched.firstname && formik.errors.firstname}
                        />
                      </Grid>
                    </Grid>
                    <Grid container spacing={1} alignItems="center" style={MbppStyles.Grid}>
                      <Grid item xs={12} sm={12}>
                        <TextField
                          fullWidth
                          id="lastname"
                          name="lastname"
                          label="Lastname*"
                          variant="outlined"
                          value={formik.values.lastname}
                          onChange={formik.handleChange}
                          className={"form-control-alternative form-control-edit"}
                          error={formik.touched.lastname && Boolean(formik.errors.lastname)}
                          helperText={formik.touched.lastname && formik.errors.lastname}
                        />
                      </Grid>
                    </Grid>
                  </FormGroup>

                  <FormGroup className="mb-3 ">
                    {isSignup && (
                      <>
                        <hr className="my-2" />
                        <h6 className="heading-small mb-4" style={MbppStyles.SubTopic}>
                          Authentication Details
                        </h6>
                      </>
                    )}
                    <Grid container spacing={1} alignItems="center" style={MbppStyles.Grid}>
                      <Grid item xs={1} sm={1}>
                        <i className="ni ni-email-83" />
                      </Grid>
                      <Grid item xs={11} sm={11}>
                        <TextField
                          fullWidth
                          id="email"
                          name="email"
                          label="Email address*"
                          variant="outlined"
                          value={formik.values.email}
                          onChange={formik.handleChange}
                          className={"form-control-alternative form-control-edit"}
                          error={formik.touched.email && Boolean(formik.errors.email)}
                          helperText={formik.touched.email && formik.errors.email}
                        />
                      </Grid>
                    </Grid>
                    <Grid container spacing={1} alignItems="center" style={MbppStyles.Grid}>
                      <Grid item xs={1} sm={1}>
                        <i className="ni ni-lock-circle-open" />
                      </Grid>
                      <Grid item xs={11} sm={11}>
                        <TextField
                          fullWidth
                          id="password"
                          name="password"
                          label="Password*"
                          variant="outlined"
                          type={showPassword ? "text" : "password"}
                          value={formik.values.password}
                          onChange={formik.handleChange}
                          className={"form-control-alternative form-control-edit"}
                          error={formik.touched.password && Boolean(formik.errors.password)}
                          helperText={formik.touched.password && formik.errors.password}
                          InputProps={
                            showPassword !== null
                              ? {
                                endAdornment: (
                                  <InputAdornment position="end">
                                    <IconButton onClick={handleShowPassword}>{showPassword ? <Visibility /> : <VisibilityOff />}</IconButton>
                                  </InputAdornment>
                                ),
                              }
                              : null
                          }
                        />
                      </Grid>
                    </Grid>

                    <>
                      <Grid container spacing={1} alignItems="center" style={MbppStyles.Grid}>
                        <Grid item xs={1} sm={1}>
                          <i className="ni ni-lock-circle-open" />
                        </Grid>
                        <Grid item xs={11} sm={11}>
                          <TextField
                            fullWidth
                            id="confirmPassword"
                            name="confirmPassword"
                            label="Confirm Password*"
                            variant="outlined"
                            type={showPassword ? "text" : "password"}
                            value={formik.values.confirmPassword}
                            onChange={formik.handleChange}
                            className={"form-control-alternative form-control-edit"}
                            error={formik.touched.confirmPassword && Boolean(formik.errors.confirmPassword)}
                            helperText={formik.touched.confirmPassword && formik.errors.confirmPassword}
                          />
                        </Grid>
                      </Grid>

                      <Grid container spacing={1} alignItems="center" style={MbppStyles.Grid}>
                        <Grid item xs={1} sm={1}></Grid>
                        <Grid item xs={11} sm={11}>
                          <p className="small mb-4">If you have used your email for a previous account under any admin or organization, the registration process will fail.</p>
                        </Grid>
                      </Grid>
                    </>
                  </FormGroup>

                  <br />
                  <hr className="my-2" />
                  <p className="text-center small" style={{ marginBottom: 20, color: "#af1e23" }}>
                    User have to update the user profile after the registration is successful.
                  </p>

                  <div className="text-center" style={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                    <ReCaptchaV2
                      sitekey={APP_RECAPTCHA.SITE_KEY}
                      onChange={(e) => {
                        // if condition to chck e null or not, if it is null msg
                        setIsRegEnable(true);
                        setIsRegToken(e);
                        formik.values.recapture = e;
                      }}
                      onExpired={handleExpire}
                    />
                  </div>

                  <br />
                  <Row>
                    <Col lg="1" />
                    <Col lg="3" className="text-left">
                      <Button
                        style={ChsStyles.GeneralButton}
                        color="primary"
                        onClick={() => {
                          window.history.back();
                        }}
                      >
                        BACK
                      </Button>
                    </Col>
                    <Col lg="7" className="text-right">
                      {isSignup && (
                        <Button variant="contained" color="primary" type="reset">
                          RESET
                        </Button>
                      )}
                      <Button variant="contained" color="primary" type="submit" disabled={!isRegEnable}>
                        REGISTER
                      </Button>
                    </Col>
                    <Col lg="1" />
                  </Row>
                </Form>
              </CardBody>
            </Card>
            <Row className="mt-3">
              <Col xs="6">
                {!isSignup && (
                  <a className="text-light" href="#chs" onClick={(e) => e.preventDefault()}>
                    <small>Forgot password?</small>
                  </a>
                )}
              </Col>
              <Col className="text-right" xs="6">
                <a className="text-light" href="login">
                  <small>{isSignup ? "Already have an account? Login" : "Create new account"}</small>
                </a>
              </Col>
            </Row>
          </Col>
        </Row>
      </Container>
    </>
  );
};

export default Register;
