import {
  SURVEY_FETCH_ALL,
  SURVEY_FETCH_ACTIVE,
  SURVEY_TEMPLATE_FETCH_ALL,
  SURVEY_TEMPLATE_LOCAL,
  SURVEY_LOCALIZATION,
  LOCALIZATION_DELETE,
  SURVEY_ACTIVE_LOCAL,
  ANSWER_FETCH_BY_USER,
  ANSWER_FETCH_BY_ID,
  RECOMMENDATION_FETCH_ALL,
  SHOW_LOADING,
  GRAPH_LOCAL_ANSWER,
} from "constants/actionTypes";
import { LOGINSTATE, SUPPORT_EMAIL } from "config/config.json";
import * as api from "../api/index.js";
import { confirmAlert } from "react-confirm-alert";
import "react-confirm-alert/src/react-confirm-alert.css"; // Import css
import Lodash from "lodash";

// Surveys

// no need
// seems not using
export const fetchSurveyAll = () => async (dispatch) => {
  try {
    dispatch({ type: SHOW_LOADING, payload: true });
    const { data } = await api.fetchSurveyAll();
    if (data?.status === "success") {
      dispatch({ type: SURVEY_FETCH_ALL, payload: data?.data });
    }
    if (data?.status === "error") {
      if (LOGINSTATE) console.log(data);
      confirmAlert({ message: "Oops!, something went wrong. Please try again.", buttons: [{ label: "Ok", onClick: () => { } }], closeOnEscape: false, closeOnClickOutside: false });
    }
  } catch (error) {
    alert(error);
    if (LOGINSTATE) console.log(error);
  } finally {
    dispatch({ type: SHOW_LOADING, payload: false });
  }
};

// trans done
export const fetchActiveSurveyByType = (formData, router, t) => async (dispatch) => {
  try {
    dispatch({ type: SHOW_LOADING, payload: true });
    const { data } = await api.fetchActiveSurveyByType(formData);
    if (LOGINSTATE) console.log("server response data: ", data);
    if (data?.status === "success") {
      dispatch({ type: SURVEY_FETCH_ACTIVE, payload: data?.data });
      localStorage.setItem(SURVEY_ACTIVE_LOCAL, "{}");
    }

    if (data?.status === "error") {
      if (LOGINSTATE) console.log("data error");
      // confirmAlert({ message: "Oops!, something went wrong. Please try again.", buttons: [{ label: "Ok", onClick: () => {} }], closeOnEscape: false, closeOnClickOutside: false });
      confirmAlert({ message: t('popups.somethingWentWrong'), buttons: [{ label: t('common.ok'), onClick: () => { } }], closeOnEscape: false, closeOnClickOutside: false });
    }
  } catch (error) {
    // alert(error);
    if (LOGINSTATE) console.log("catch error:", error);
    if (error?.response?.status && error?.response?.status === 401) {
      // confirmAlert({ message: "Session expired!", buttons: [{ label: "Ok", onClick: () => {} }], closeOnEscape: false, closeOnClickOutside: false });
      confirmAlert({ message: t('popups.sessionExpired'), buttons: [{ label: t('common.ok'), onClick: () => { } }], closeOnEscape: false, closeOnClickOutside: false });
      router.push("/auth/signin");
    }
  } finally {
    dispatch({ type: SHOW_LOADING, payload: false });
  }
};

// no need trans
export const createSurveyTemplate = (formData, history) => async (dispatch) => {
  try {
    dispatch({ type: SHOW_LOADING, payload: true });
    const { data } = await api.createSurveyTemplate(formData);
    if (data?.status === "success") {
      confirmAlert({ title: "Success", message: "Assessment created.", buttons: [{ label: "Ok", onClick: () => { } }], closeOnEscape: false, closeOnClickOutside: false });
      history.push({ pathname: "/registered/assessment" });
    }
    if (data?.status === "error") {
      if (LOGINSTATE) console.log("error in data: ", data);
      confirmAlert({ title: "Error", message: "Survey create failed. Please try again later.", buttons: [{ label: "Ok", onClick: () => { } }], closeOnEscape: false, closeOnClickOutside: false });
    }
  } catch (error) {
    alert(error);
    if (LOGINSTATE) console.log("catch error: ", error);
    if (error?.response?.status && error?.response?.status === 401) {
      confirmAlert({ message: "Session expired!", buttons: [{ label: "Ok", onClick: () => { } }], closeOnEscape: false, closeOnClickOutside: false });
      history.push("/auth/signin");
    }
  } finally {
    dispatch({ type: SHOW_LOADING, payload: false });
  }
};

// no need trans
export const fetchSurveyTemplateAll = () => async (dispatch) => {
  try {
    dispatch({ type: SHOW_LOADING, payload: true });
    const { data } = await api.fetchSurveyTemplateAll();
    if (LOGINSTATE) console.log("data fetchSurveyTemplateAll: ", data);
    if (data?.status === "success") {
      dispatch({ type: SURVEY_TEMPLATE_FETCH_ALL, payload: data?.data });
    }
    if (data?.status === "error") {
      if (LOGINSTATE) console.log(data);
      confirmAlert({ message: "Oops!, something went wrong. Please try again.", buttons: [{ label: "Ok", onClick: () => { } }], closeOnEscape: false, closeOnClickOutside: false });
    }
  } catch (error) {
    alert(error);
    if (LOGINSTATE) console.log(error);
  } finally {
    dispatch({ type: SHOW_LOADING, payload: false });
  }
};

// no need
// seems not using
export const fetchActiveSurveyTemplateByType = (formData, router) => async (dispatch) => {
  try {
    dispatch({ type: SHOW_LOADING, payload: true });
    const { data } = await api.fetchActiveSurveyTemplateByType(formData);
    if (LOGINSTATE) console.log("server response data: ", data);
    if (data?.status === "success") {
      dispatch({ type: SURVEY_FETCH_ACTIVE, payload: data?.data });
      localStorage.setItem(SURVEY_TEMPLATE_LOCAL, "{}");
    }
    if (data?.status === "error") {
      if (LOGINSTATE) console.log(data);
      confirmAlert({ message: "Oops!, something went wrong. Please try again.", buttons: [{ label: "Ok", onClick: () => { } }], closeOnEscape: false, closeOnClickOutside: false });
    }
  } catch (error) {
    alert(error);
    if (LOGINSTATE) console.log(error);
    if (error?.response?.status && error?.response?.status === 401) {
      confirmAlert({ message: "Session expired!", buttons: [{ label: "Ok", onClick: () => { } }], closeOnEscape: false, closeOnClickOutside: false });
      router.push("/auth/signin");
    }
  } finally {
    dispatch({ type: SHOW_LOADING, payload: false });
  }
};

// no need
// seems not using
export const fetchGraph = (formData, router) => async (dispatch) => {
  try {
    dispatch({ type: SHOW_LOADING, payload: true });
    const data = await JSON.parse(localStorage.getItem(GRAPH_LOCAL_ANSWER));;
    console.log("data", data);
    if (data) {
      dispatch({ type: GRAPH_LOCAL_ANSWER, payload: data });
    }
  } catch (error) {
    alert(error);
    if (LOGINSTATE) console.log(error);
    if (error?.response?.status && error?.response?.status === 401) {
      confirmAlert({ message: "Session expired!", buttons: [{ label: "Ok", onClick: () => { } }], closeOnEscape: false, closeOnClickOutside: false });
      router.push("/auth/signin");
    }
  } finally {
    dispatch({ type: SHOW_LOADING, payload: false });
  }
};

// no need trans
export const createLocalizationBySurveyTemplate = (formData, history) => async (dispatch) => {
  try {
    dispatch({ type: SHOW_LOADING, payload: true });
    const response = await api.createLocalizationBySurveyTemplate(formData);

    const { data } = response;
    if (data?.status === "success") {
      confirmAlert({ message: "Assessment localization is created.", buttons: [{ label: "Ok", onClick: () => { } }], closeOnEscape: false, closeOnClickOutside: false });
      if (data?.data?.surveyId) {
        history.push({ pathname: "/registered/assessment", state: { survey: { _id: data?.data?.surveyId } } });
      }
    }
    if (data?.status === "error") {
      if (LOGINSTATE) console.log(data);
      confirmAlert({ title: "Error", message: data?.message, buttons: [{ label: "Ok", onClick: () => { } }], closeOnEscape: false, closeOnClickOutside: false });
    }
  } catch (error) {
    alert(error);
    if (LOGINSTATE) console.log("error: ", error);
    if (error?.response?.status && error?.response?.status === 401) {
      confirmAlert({ message: "Session expired!", buttons: [{ label: "Ok", onClick: () => { } }], closeOnEscape: false, closeOnClickOutside: false });
      history.push("/auth/signin");
    }
  } finally {
    dispatch({ type: SHOW_LOADING, payload: false });
  }
};

// no need trans
export const updateLocalizationBySurveyTemplate = (formData, history) => async (dispatch) => {
  try {
    dispatch({ type: SHOW_LOADING, payload: true });
    const { data } = await api.updateLocalizationBySurveyTemplate(formData);

    if (data?.status === "success") {
      confirmAlert({ message: "Assessment locale is updated.", buttons: [{ label: "Ok", onClick: () => { } }], closeOnEscape: false, closeOnClickOutside: false });
      if (data?.data?.surveyId) {
        history.push({ pathname: "/registered/assessment", state: { survey: { _id: data?.data?.surveyId } } });
      }
    }
    if (data?.status === "error") {
      if (LOGINSTATE) console.log(data);
      confirmAlert({ message: "Oops!, something went wrong. Please try again.", buttons: [{ label: "Ok", onClick: () => { } }], closeOnEscape: false, closeOnClickOutside: false });
    }
  } catch (error) {
    alert(error);
    if (LOGINSTATE) console.log(error);
    if (error?.response?.status && error?.response?.status === 401) {
      confirmAlert({ message: "Session expired!", buttons: [{ label: "Ok", onClick: () => { } }], closeOnEscape: false, closeOnClickOutside: false });
      history.push("/auth/signin");
    }
  } finally {
    dispatch({ type: SHOW_LOADING, payload: false });
  }
};

// need #####
export const fetchLocalizationBySurveyTemplate = (formData, router) => async (dispatch) => {
  if (LOGINSTATE) console.log("formData", formData);
  try {
    dispatch({ type: SHOW_LOADING, payload: true });
    const { data } = await api.fetchLocalizationBySurveyTemplate(formData);
    if (LOGINSTATE) console.log("server response data action: ", data);
    if (data?.status === "success") {
      dispatch({ type: SURVEY_LOCALIZATION, payload: data?.data });
    }
    if (data?.status === "error") {
      if (LOGINSTATE) console.log(data);
      confirmAlert({ message: "Oops!, something went wrong. Please try again.", buttons: [{ label: "Ok", onClick: () => { } }], closeOnEscape: false, closeOnClickOutside: false });
    }
  } catch (error) {
    alert(error);
    if (LOGINSTATE) console.log(error);
    if (error?.response?.status && error?.response?.status === 401) {
      confirmAlert({ message: "Session expired!", buttons: [{ label: "Ok", onClick: () => { } }], closeOnEscape: false, closeOnClickOutside: false });
      router.push("/auth/signin");
    }
  } finally {
    dispatch({ type: SHOW_LOADING, payload: false });
  }
};

// no need trans
export const deleteSurveyLocalization = (formData, router) => async (dispatch) => {
  if (LOGINSTATE) console.log("formdata", formData);
  try {
    dispatch({ type: SHOW_LOADING, payload: true });
    const response = await api.deleteSurveyLocalization(formData);
    const { data } = response;
    if (LOGINSTATE) console.log("delete localization: ", data);
    if (data?.status === "success") {
      dispatch({ type: LOCALIZATION_DELETE, payload: dispatch });
      confirmAlert({
        message: "Localization deleted.",
        buttons: [
          {
            label: "Ok",
            onClick: () => {
              window.location.reload(true);
            },
          },
        ],
      });
    }
    if (data?.status === "error") {
      if (LOGINSTATE) console.log(data);
      confirmAlert({ message: "Oops!, something went wrong. Please try again.", buttons: [{ label: "Ok", onClick: () => { } }], closeOnEscape: false, closeOnClickOutside: false });
    }
  } catch (error) {
    if (error?.response?.status && error?.response?.status === 401) {
      confirmAlert({ message: "Session expired!", buttons: [{ label: "Ok", onClick: () => { } }], closeOnEscape: false, closeOnClickOutside: false });
      router.push("/auth/signin");
    } else {
      let errorMessage = error?.response?.data?.message;
      confirmAlert({ message: errorMessage, buttons: [{ label: "Yes", onClick: () => { } }], closeOnEscape: false, closeOnClickOutside: false });
    }

    if (LOGINSTATE) console.log(error);
  } finally {
    dispatch({ type: SHOW_LOADING, payload: false });
  }
};

// not need trans
export const updateSurveyTemplatePublishById = (formData, router) => async (dispatch) => {
  try {
    dispatch({ type: SHOW_LOADING, payload: true });
    const { data } = await api.updateSurveyTemplatePublishById(formData);

    if (LOGINSTATE) console.log("server response data: ", data);
    if (data?.status === "success") {
      confirmAlert({ message: "Assessment is published.", buttons: [{ label: "Ok", onClick: () => { } }], closeOnEscape: false, closeOnClickOutside: false });
      dispatch(fetchSurveyTemplateAll());
    } else if (data?.status === "error") {
      if (LOGINSTATE) console.log("error: ", data);
      confirmAlert({ message: "Status update is failed.", buttons: [{ label: "Ok", onClick: () => { } }], closeOnEscape: false, closeOnClickOutside: false });
    }
  } catch (error) {
    if (LOGINSTATE) console.log("catch error: ", error);
    if (error?.response?.status && error?.response?.status === 401) {
      confirmAlert({ message: "Session expired!", buttons: [{ label: "Ok", onClick: () => { } }], closeOnEscape: false, closeOnClickOutside: false });
      router.push("/auth/signin");
    } else {
      confirmAlert({ message: "Status update is failed.", buttons: [{ label: "Ok", onClick: () => { } }], closeOnEscape: false, closeOnClickOutside: false });
    }
  } finally {
    dispatch({ type: SHOW_LOADING, payload: false });
  }
};

// no need trans
export const updateSurveyTemplateById = (formData, history) => async (dispatch) => {
  try {
    dispatch({ type: SHOW_LOADING, payload: true });
    formData["surveyId"] = formData["_id"];
    const { data } = await api.updateSurveyTemplateById(formData);
    if (LOGINSTATE) console.log("server response data: ", data);
    if (data?.status === "success") {
      confirmAlert({ message: "Success", buttons: [{ label: "Ok", onClick: () => { } }], closeOnEscape: false, closeOnClickOutside: false });
      history.push({ pathname: "/registered/assessment" });
    } else if (data?.status === "error") {
      if (LOGINSTATE) console.log("error: ", data);
      confirmAlert({ message: "Update failed.", buttons: [{ label: "Yes", onClick: () => { } }], closeOnEscape: false, closeOnClickOutside: false });
    }
  } catch (error) {
    if (LOGINSTATE) console.log("catch error: ", error);
    if (error?.response?.status && error?.response?.status === 401) {
      confirmAlert({ message: "Session expired!", buttons: [{ label: "Ok", onClick: () => { } }], closeOnEscape: false, closeOnClickOutside: false });
      history.push("/auth/signin");
    } else {
      confirmAlert({ message: "Update failed.", buttons: [{ label: "Yes", onClick: () => { } }] });
    }
  } finally {
    dispatch({ type: SHOW_LOADING, payload: false });
  }
};

// Answer

// trans done
export const createSurveyAnswer = (formData, history, systemContentLocale, t) => async () => {
  if (LOGINSTATE) console.log("formData create", formData);
  try {
    const { data } = await api.createSurveyAnswer(formData, systemContentLocale);
    if (data?.status === "success") {
      if (LOGINSTATE) console.log("Assessment answer created: ", data?.data);
      if (data.data?.status === "PROGRESS") {
        confirmAlert({
          message: systemContentLocale.app_success,
          buttons: [
            {
              label: systemContentLocale.app_ok,
              onClick: () => {
                history.push({ pathname: "/registered/assessment_answers" });
              },
            },
          ],
          closeOnEscape: false,
          closeOnClickOutside: false,
        });
      } else {
        confirmAlert({
          message: systemContentLocale.assessment_completion + " " + SUPPORT_EMAIL,
          buttons: [
            {
              label: systemContentLocale.app_ok,
              onClick: () => {
                history.push({ pathname: "/registered/assessment_answer_complete", state: { answer: formData, language: data.data?.languageCode } });
              },
            },
          ],
          closeOnEscape: false,
          closeOnClickOutside: false,
        });
      }
      //Localize
      if (data?.data?.surveyId) {
        // history.push({ pathname: '/registered/survey_answers'});
      }
    }
    if (data?.status === "error") {
      if (LOGINSTATE) console.log("data error");
      confirmAlert({ message: systemContentLocale.app_assessment_incomplete_title, buttons: [{ label: systemContentLocale.app_ok, onClick: () => { } }], closeOnEscape: false, closeOnClickOutside: false });
    }
  } catch (error) {
    if (LOGINSTATE) console.log("catch error: ", error);
    if (error?.response?.status && error?.response?.status === 401) {
      // confirmAlert({ message: "Session expired!", buttons: [{ label: "Ok", onClick: () => { } }], closeOnEscape: false, closeOnClickOutside: false });
      confirmAlert({ message: t('popups.sessionExpired'), buttons: [{ label: t('common.ok'), onClick: () => {} }], closeOnEscape: false, closeOnClickOutside: false });
      history.push("/auth/signin");
    } else {
      confirmAlert({ message: systemContentLocale.app_error, buttons: [{ label: systemContentLocale.app_ok, onClick: () => { } }], closeOnEscape: false, closeOnClickOutside: false });
    }
  } finally {
  }
};

// trans done
export const updateSurveyAnswer = (formData, history, systemContentLocale, t) => async () => {
  if (LOGINSTATE) console.log("formData continue", formData);
  try {
    const { data } = await api.updateSurveyAnswerById(formData);
    if (data?.status === "success") {
      if (LOGINSTATE) console.log("Assessment answer updated: ", data?.data);
      if (data.data?.status === "PROGRESS") {
        confirmAlert({
          message: systemContentLocale.app_success,
          buttons: [
            {
              label: systemContentLocale.app_ok,
              onClick: () => {
                history.push({ pathname: "/registered/assessment_answers" });
              },
            },
          ],
        });
      } else {
        confirmAlert({
          message: systemContentLocale.assessment_completion + " " + SUPPORT_EMAIL,
          buttons: [
            {
              label: systemContentLocale.app_ok,
              onClick: () => {
                history.push({ pathname: "/registered/assessment_answer_complete", state: { answer: formData, language: data.data?.languageCode } });
              },
            },
          ],
        });
      }
      if (data?.data?.surveyId) {
        // history.push({ pathname: '/registered/survey_answers'});
      }
    }
    if (data?.status === "error") {
      // if(LOGINSTATE) console.log('data error');

      confirmAlert({ message: systemContentLocale.app_assessment_incomplete_title, buttons: [{ label: systemContentLocale.app_ok, onClick: () => { } }], closeOnEscape: false, closeOnClickOutside: false });
    }
  } catch (error) {
    // if(LOGINSTATE) console.log('catch error: ', error);
    if (error?.response?.status && error?.response?.status === 401) {
      // confirmAlert({ message: "Session expired!", buttons: [{ label: "Ok", onClick: () => { } }], closeOnEscape: false, closeOnClickOutside: false });
      confirmAlert({ message: t('popups.sessionExpired'), buttons: [{ label: t('common.ok'), onClick: () => {} }], closeOnEscape: false, closeOnClickOutside: false });
      history.push("/auth/signin");
    } else {
      confirmAlert({ message: systemContentLocale.app_error, buttons: [{ label: systemContentLocale.app_ok, onClick: () => { } }], closeOnEscape: false, closeOnClickOutside: false });
    }
  } finally {
  }
};

// no need
// seems not using
export const fetchSurveyAnswerById = (formData, router) => async (dispatch) => {
  try {
    dispatch({ type: SHOW_LOADING, payload: true });
    const { data } = await api.fetchSurveyAnswerById(formData);
    if (data?.status === "success") {
      // if(LOGINSTATE) console.log('survey answer updated: ', data?.data)
      dispatch({ type: ANSWER_FETCH_BY_ID, payload: data?.data });
    }
    if (data?.status === "error") {
      // if(LOGINSTATE) console.log('data error');
      confirmAlert({ message: "Oops!, something went wrong. Please try again.", buttons: [{ label: "Ok", onClick: () => { } }], closeOnEscape: false, closeOnClickOutside: false });
    }
  } catch (error) {
    // if(LOGINSTATE) console.log('catch error: ', error);
    if (error?.response?.status && error?.response?.status === 401) {
      confirmAlert({ message: "Session expired!", buttons: [{ label: "Ok", onClick: () => { } }] });
      router.push("/auth/signin");
    } else {
      confirmAlert({ message: "Oops!, something went wrong. Please try again.", buttons: [{ label: "Ok", onClick: () => { } }], closeOnEscape: false, closeOnClickOutside: false });
    }
  } finally {
    dispatch({ type: SHOW_LOADING, payload: false });
  }
};

// may ####
export const fetchSurveyAnswerByUser = (formData, router, t) => async (dispatch) => {
  if (LOGINSTATE) console.log("formdata", formData);
  try {
    dispatch({ type: SHOW_LOADING, payload: true });
    const { data } = await api.fetchSurveyAnswerByUser(formData);
    if (data?.status === "success") {
      if (LOGINSTATE) console.log("Assessment answer updated: ", data?.data);
      dispatch({ type: ANSWER_FETCH_BY_USER, payload: data?.data });
    }
    if (data?.status === "error") {
      if (LOGINSTATE) console.log("data error");
      confirmAlert({ message: "Oops!, something went wrong. Please try again.", buttons: [{ label: "Ok", onClick: () => { } }], closeOnEscape: false, closeOnClickOutside: false });
    }
  } catch (error) {
    if (LOGINSTATE) console.log("catch error: ", error);
    if (error?.response?.status && error?.response?.status === 401) {
      confirmAlert({ message: "Session expired!", buttons: [{ label: "Ok", onClick: () => { } }], closeOnEscape: false, closeOnClickOutside: false });
      router.push("/auth/signin");
    } else {
      confirmAlert({ message: t('assessmentPage.removedUserReports'), buttons: [{ label: "Ok", onClick: () => { } }], closeOnEscape: false, closeOnClickOutside: false });
    }
  } finally {
    dispatch({ type: SHOW_LOADING, payload: false });
  }
};

// Recommendation

// no need trans
export const createRecommendation = (formData, router) => async (dispatch) => {
  try {
    dispatch({ type: SHOW_LOADING, payload: true });
    const { data } = await api.createRecommendation(formData);
    if (data?.status === "success") {
      // if(LOGINSTATE) console.log('survey answer created: ', data?.data)

      confirmAlert({
        message: "Recommendation is created.",
        buttons: [
          {
            label: "Ok",
            onClick: () => {
              let params = {
                _id: data?.data?.surveyId,
              };
              // router.push({ pathname: '/registered/assessment_recommendation_localization', state: { survey: params } });
              router.push({ pathname: "/registered/assessment_recommendations" });
            },
          },
        ],
      });
    }
    if (data?.status === "error") {
      if (LOGINSTATE) console.log("data error");
      confirmAlert({ message: "Oops!, something went wrong. Please try again.", buttons: [{ label: "Ok", onClick: () => { } }], closeOnEscape: false, closeOnClickOutside: false });
    }
  } catch (error) {
    if (LOGINSTATE) console.log("catch error: ", error);
    if (error?.response?.status && error?.response?.status === 401) {
      confirmAlert({ message: "Session expired!", buttons: [{ label: "Ok", onClick: () => { } }], closeOnEscape: false, closeOnClickOutside: false });
      router.push("/auth/signin");
    } else {
      confirmAlert({ message: "Oops!, something went wrong. Please try again.", buttons: [{ label: "Ok", onClick: () => { } }], closeOnEscape: false, closeOnClickOutside: false });
    }
  } finally {
    dispatch({ type: SHOW_LOADING, payload: false });
  }
};

// no need trans
export const updateRecommendationById = (formData, router) => async (dispatch) => {
  try {
    dispatch({ type: SHOW_LOADING, payload: true });
    const { data } = await api.updateRecommendationById(formData);
    if (data?.status === "success") {
      if (LOGINSTATE) console.log("survey answer updated: ", data?.data);
      confirmAlert({
        message: "Recommendation is updated.",
        buttons: [
          {
            label: "Ok",
            onClick: () => {
              let params = {
                _id: data?.data?.surveyId,
              };
              // router.push({ pathname: '/registered/assessment_recommendation_localization', state: { survey: params } })
              router.push({ pathname: "/registered/assessment_recommendations" });
            },
          },
        ],
      });
    }
    if (data?.status === "error") {
      if (LOGINSTATE) console.log("data error");
      confirmAlert({ message: "Oops!, something went wrong. Please try again.", buttons: [{ label: "Ok", onClick: () => { } }], closeOnEscape: false, closeOnClickOutside: false });
    }
  } catch (error) {
    if (LOGINSTATE) console.log("catch error: ", error);
    if (error?.response?.status && error?.response?.status === 401) {
      confirmAlert({ message: "Session expired!", buttons: [{ label: "Ok", onClick: () => { } }], closeOnEscape: false, closeOnClickOutside: false });
      router.push("/auth/signin");
    } else {
      alert(error);
    }
  } finally {
    dispatch({ type: SHOW_LOADING, payload: false });
  }
};

// no need trans
export const deleteRecommendationById = (formData, router) => async (dispatch) => {
  try {
    dispatch({ type: SHOW_LOADING, payload: true });
    const { data } = await api.deleteRecommendationById(formData);
    if (data?.status === "success") {
      if (LOGINSTATE) console.log("survey answer deleted: ", data?.data);
      confirmAlert({
        message: "Recommendation is deleted.",
        buttons: [
          {
            label: "Ok",
            onClick: () => {
              let params = {
                _id: data?.data?.surveyId,
              };
              router.push({ pathname: "/registered/assessment_recommendations" });
            },
          },
        ],
      });
    }
    if (data?.status === "error") {
      if (LOGINSTATE) console.log("data error");
      confirmAlert({ message: "Oops!, something went wrong. Please try again.", buttons: [{ label: "Ok", onClick: () => { } }], closeOnEscape: false, closeOnClickOutside: false });
    }
  } catch (error) {
    if (LOGINSTATE) console.log("catch error: ", error);
    if (error?.response?.status && error?.response?.status === 401) {
      confirmAlert({ message: "Session expired!", buttons: [{ label: "Ok", onClick: () => { } }], closeOnEscape: false, closeOnClickOutside: false });
      router.push("/auth/signin");
    } else {
      alert(error);
    }
  } finally {
    dispatch({ type: SHOW_LOADING, payload: false });
  }
};

// may ####
export const fetchRecommendationsBySurveyId = (formData, router) => async (dispatch) => {
  try {
    dispatch({ type: SHOW_LOADING, payload: true });
    const { data } = await api.fetchRecommendationsBySurveyId(formData);
    if (data?.status === "success") {
      // console.log("survey recommendations: ", data?.data);
      dispatch({ type: RECOMMENDATION_FETCH_ALL, payload: data?.data });
    }
    if (data?.status === "error") {
      if (LOGINSTATE) console.log("data error");
      confirmAlert({ message: "Oops!, something went wrong. Please try again.", buttons: [{ label: "Ok", onClick: () => { } }] });
    }
  } catch (error) {
    if (LOGINSTATE) console.log("catch error: ", error);
    if (error?.response?.status && error?.response?.status === 401) {
      confirmAlert({ message: "Session expired!", buttons: [{ label: "Ok", onClick: () => { } }] });
      router.push("/auth/signin");
    } else {
      alert(error);
    }
  } finally {
    dispatch({ type: SHOW_LOADING, payload: false });
  }
};
