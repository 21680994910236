import { fetchSurveyTemplateAll, updateSurveyTemplatePublishById } from "actions/survey";
import { SURVEY_TEMPLATE_LOCAL } from "constants/actionTypes";
// core components
import PageHeader from "components/Headers/PageHeader.js";
import Config from "config/config.json";
import { LOGINSTATE } from "../../../config/config.json";
import Lodash from "lodash";
import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useHistory, useLocation } from "react-router-dom";
// reactstrap components
import { Badge, Card, CardHeader, Container, Row, Table } from "reactstrap";
import { GlobalStylesColor } from "constants/globalStyles";
import { confirmAlert } from "react-confirm-alert";
import "react-confirm-alert/src/react-confirm-alert.css"; // Import css
import { Tooltip } from "@material-ui/core";
import TranslateOutlinedIcon from "@material-ui/icons/TranslateOutlined";
import PublicOutlinedIcon from "@material-ui/icons/PublicOutlined";
import VpnLockOutlinedIcon from "@material-ui/icons/VpnLockOutlined";

// Populate version row for each survey type
const SurveyRow = ({ survey, editClick, statusClick, localizationClick, createClick }) => (
  // VERSION | TITLE | DESCRIPTION | STATUS
  <tr>
    <td style={{ whiteSpace: "break-spaces" }}>{parseFloat(survey.version).toFixed(1)}</td>
    <td style={{ whiteSpace: "break-spaces", color: GlobalStylesColor.mbppThemeBlue }}>{survey.title}</td>
    <td style={{ whiteSpace: "break-spaces" }}>{survey.description}</td>
    <td style={{ whiteSpace: "break-spaces" }}>{survey.status}</td>
    <td>
      {survey.published ? (
        <Badge color="" className="badge-dot">
          <i className="bg-success" />
          YES
        </Badge>
      ) : (
        <Badge color="" className="badge-dot mr-4">
          <i className="bg-warning" />
          NO
        </Badge>
      )}
    </td>
    <td className="text-right">
      <div className="row">
        <Tooltip title="Edit" arrow>
          <div
            className="navbar-toggler"
            style={{ cursor: "pointer" }}
            onClick={(e) => {
              editClick(e, survey);
            }}
          >
            <i className="far fa-edit" style={{ opacity: survey.published ? 0.3 : 1, color: GlobalStylesColor.mbppIconColor }} />
          </div>
        </Tooltip>
        <Tooltip title="Change publish status" arrow>
          <div
            className="navbar-toggler"
            style={{ cursor: "pointer" }}
            onClick={(e) => {
              statusClick(e, survey);
            }}
          >
            {survey.published ? <PublicOutlinedIcon style={{ color: GlobalStylesColor.mbppIconColorLight }} /> : <VpnLockOutlinedIcon style={{ color: GlobalStylesColor.mbppIconColorLight }} />}
          </div>
        </Tooltip>
        <Tooltip title="Create a new template using this version" arrow>
          <div
            className="navbar-toggler"
            style={{ cursor: "pointer" }}
            onClick={(e) => {
              createClick(e, survey, survey.type);
            }}
          >
            <i className="far fa-plus-square" style={{ color: GlobalStylesColor.mbppIconColor }} />
            {/* <VisibilityOutlinedIcon/> */}
          </div>
        </Tooltip>
        <Tooltip title="Localizations" arrow>
          <div
            className="navbar-toggler"
            style={{ cursor: "pointer" }}
            onClick={(e) => {
              localizationClick(e, survey);
            }}
          >
            <TranslateOutlinedIcon style={{ color: GlobalStylesColor.mbppIconColor }} />
          </div>
        </Tooltip>
      </div>
    </td>
  </tr>
);

// export function
const Survey = () => {
  // dispatch and history
  const dispatch = useDispatch();
  const history = useHistory();
  const location = useLocation();

  // get forum topics
  const surveyTemplateAll = useSelector((state) => state.survey.surveyTemplateAll);

  useEffect(() => {
    // fetching all surveys
    dispatch(fetchSurveyTemplateAll());
  }, []);

  const handleStatusClick = (e, survey) => {
    e.preventDefault();
    if (LOGINSTATE) console.log("survey status", survey);
    if (survey.segments && !Lodash.isEmpty(survey.segments)) {
      dispatch(updateSurveyTemplatePublishById({ surveyId: survey._id }, history));
    }
  };

  // handle Click event of the survey. Navigate to the localization page. There will be auto english version for every version
  const handleLocalizationClick = (e, survey) => {
    e.preventDefault();
    history.push({ pathname: "/registered/assessment_localization", state: { survey: survey } });
  };

  // create survey from existing survey
  const handleEditClick = (e, survey, type) => {
    e.preventDefault();
    if (LOGINSTATE) console.log("--------------------- local store saving: ", survey);
    if (LOGINSTATE) console.log("--------------------- local store saving: ", type);
    dispatch({ type: SURVEY_TEMPLATE_LOCAL, payload: survey });
    confirmAlert({
      title: "Warning",
      message: "This will reset currently editing assessment, if exist. Do you want to proceed?",
      buttons: [
        {
          label: "Yes",
          onClick: () => {
            localStorage.setItem(SURVEY_TEMPLATE_LOCAL, JSON.stringify(survey));

            history.push({ pathname: "/registered/assessment_create", state: { survey: survey, type: type } });
          },
        },
        { label: "No", onClick: () => {} },
      ],
      closeOnEscape: false, closeOnClickOutside: false,
    });
  };

  // create survey from existing survey
  const handleCreateClick = (e, survey, type) => {
    e.preventDefault();
    survey["_id"] = null;
    if (LOGINSTATE) console.log("--------------------- local store saving: ", survey);
    if (LOGINSTATE) console.log("--------------------- local store saving: ", type);
    dispatch({ type: SURVEY_TEMPLATE_LOCAL, payload: survey });
    confirmAlert({
      title: "Warning",
      message: "This will reset currently editing assessment, if exist. Do you want to proceed?",
      buttons: [
        {
          label: "Yes",
          onClick: () => {
            localStorage.setItem(SURVEY_TEMPLATE_LOCAL, JSON.stringify(survey));
            history.push({ pathname: "/registered/assessment_create", state: { survey: survey, type: type } });
          },
        },
        { label: "No", onClick: () => {} },
      ],
      closeOnEscape: false, closeOnClickOutside: false,
    });
  };

  // create empty survey
  const handleCreateNewClick = (e, type) => {
    e.preventDefault();
    const survey = {
      _id: null,
      version: 1,
      title: type,
      description: "",
      type: type,
      status: false,
      segments: [],
    };
    if (LOGINSTATE) console.log("--------------------- local store saving: ", survey);
    if (LOGINSTATE) console.log("--------------------- local store saving: ", type);
    dispatch({ type: SURVEY_TEMPLATE_LOCAL, payload: survey });

    confirmAlert({
      title: "Warning",
      message: "This will reset currently editing assessment, if exist. Do you want to proceed?",
      buttons: [
        {
          label: "Yes",
          onClick: () => {
            localStorage.setItem(SURVEY_TEMPLATE_LOCAL, JSON.stringify(survey));
            history.push({ pathname: "/registered/assessment_create", state: { survey: survey, type: type } });
          },
        },
        { label: "No", onClick: () => {} },
      ],
      closeOnEscape: false, closeOnClickOutside: false,
    });
  };

  return (
    <>
      <PageHeader />

      {/* Page content */}
      <Container className="mt--7" fluid>
        {!Lodash.isEmpty(surveyTemplateAll) &&
          Config.TOOLS.map((tool, i) => {
            return (
              <Row key={i} className="mt-5">
                <div className="col">
                  <Card className="shadow">
                    <CardHeader className="border-0">
                      <div className="row">
                        <div className="mb-xl-0 col-11">
                          <h3 className="mb-0">{tool.title}</h3>
                          <h6 className="mb-0">{tool.description}</h6>
                        </div>
                        <div className="mb-xl-0">
                          <Tooltip title="Create new assessment template" arrow>
                            <div
                              className="navbar-toggler"
                              style={{ cursor: "pointer" }}
                              onClick={(e) => {
                                handleCreateNewClick(e, tool.type);
                              }}
                            >
                              <i className="fas fa-plus-circle" style={{ fontSize: 30, color: GlobalStylesColor.mbppThemeBlue }} />
                            </div>
                          </Tooltip>
                        </div>
                      </div>
                    </CardHeader>
                    <Table className="align-items-center table-flush" responsive>
                      <thead className="thead-light">
                        <tr>
                          <th scope="col">Version</th>
                          <th scope="col">Title</th>
                          <th scope="col">Description</th>
                          <th scope="col">Status</th>
                          <th scope="col">Published</th>
                          <th scope="col">Actions</th>
                        </tr>
                      </thead>
                      <tbody>
                        <tr></tr>
                        {Lodash.filter(surveyTemplateAll, { type: tool.type }).map((survey, i) => {
                          if (LOGINSTATE) console.log("survey", survey);

                          return <SurveyRow key={i} survey={survey} editClick={handleEditClick} statusClick={handleStatusClick} localizationClick={handleLocalizationClick} createClick={handleCreateClick} />;
                        })}
                      </tbody>
                    </Table>
                  </Card>
                </div>
              </Row>
            );
          })}
      </Container>
    </>
  );
};

export default Survey;
