/*eslint-disable*/
import { Avatar } from "@material-ui/core";
import Config from "config/config.json";
import { LOGINSTATE } from "config/config.json";
import * as actionType from "constants/actionTypes";
import { ChsStyles } from "constants/globalStyles";
import { fetchProfileOrg } from "actions/user";
// nodejs library to set properties for components
import { PropTypes } from "prop-types";
import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link, NavLink as NavLinkRRD, useHistory } from "react-router-dom";
// reactstrap components
import { Col, Collapse, Container, DropdownItem, DropdownMenu, DropdownToggle, Media, Nav, Navbar, NavbarBrand, NavItem, NavLink, Row, UncontrolledDropdown } from "reactstrap";
import { useTranslation, initReactI18next, Trans } from "react-i18next";

const Sidebar = (props) => {
  const userDetails = useSelector((state) => state.auth.authData);
  const dispatch = useDispatch();
  const history = useHistory();
  const [collapseOpen, setCollapseOpen] = useState();
  const [userProfileOrg, setUserProfileOrg] = useState("");
  // verifies if routeName is the one active (in browser input)
  const activeRoute = (routeName) => {
    return props.location.pathname.indexOf(routeName) > -1 ? "active" : "active";
  };
  // toggles collapse between opened and closed (true/false)
  const toggleCollapse = () => {
    setCollapseOpen((data) => !data);
  };
  // closes the collapse
  const closeCollapse = () => {
    setCollapseOpen(false);
  };

  // handle click event of logout
  const logout = () => {
    dispatch({ type: actionType.LOGOUT });
    localStorage.clear();
    history.push("/public/login");
  };

  const { t, i18n } = useTranslation();

  const userProfileOrg1 = useSelector((state) => state.user.userProfileOrg);

  useEffect(() => {
    i18n.changeLanguage(jUser?.user?.language);
    dispatch(fetchProfileOrg());
  }, []);

  useEffect(() => {
    let country = userProfileOrg1.profile?.employment?.organization?.country?.app_country;
    setUserProfileOrg(country);
  }, [userProfileOrg1]);

  // href={Config.SERVER_API_URL + "uploads/primer/" + jUser.user.language + "_primer.pdf"}
  const showGuide = async (e) => {
    e.preventDefault();
    const result = await fetch(`${Config.SERVER_API_URL}uploads/primer/${jUser.user.language}_primer.pdf`, { method: 'HEAD' });
    if (result.ok) {
      let pathToResource = `${Config.SERVER_API_URL}uploads/primer/${jUser.user.language}_primer.pdf`;
      window.open(pathToResource, "_blank");
    }
    else {
      let pathToResource = `${Config.SERVER_API_URL}uploads/primer/en_primer.pdf`;
      window.open(pathToResource, "_blank");
    }
  }

  let currentUser = localStorage.getItem("userDetails");
  let jUser = JSON.parse(currentUser);
  let uRole = currentUser && jUser && jUser.user && jUser.user.urole ? jUser.user.urole : "GENERAL";

  // creates the links that appear in the left-menu / Sidebar
  const createLinks = (routes, location) => {
    return routes.map((prop, key) => {
      if (prop.location === location) {
        if (prop.hasOwnProperty("innerlinks")) {
          // Navigation items with sub menu
          return (
            <UncontrolledDropdown nav key={key}>
              <DropdownToggle nav className="nav-link-icon" caret>
                <i className={prop.icon} />
                {t("navigation." + prop.name)}
                {console.log("items with sub menu 1 ------------> ", prop.name)}
              </DropdownToggle>

              <DropdownMenu aria-labelledby="navbar-default_dropdown_1" className="dropdown-menu-arrow" right>
                {prop.innerlinks.map((innerprop, innerkey) => (
                  <NavLink
                    key={innerkey}
                    to={innerprop.layout + innerprop.path}
                    tag={NavLinkRRD}
                    onClick={closeCollapse}
                    activeClassName="active" // Active item should be the parent item
                  >
                    <i className={innerprop.icon} />
                    {innerprop.name}
                    {/* {console.log("items of sub menu 2 ------------> ", innerprop.name)} */}
                  </NavLink>
                ))}
              </DropdownMenu>
            </UncontrolledDropdown>
          );
        } else {
          return (
            <NavItem key={key}>
              <NavLink to={prop.layout + prop.path} tag={NavLinkRRD} onClick={closeCollapse} activeClassName="active">
                <i className={prop.icon} />
                {t("navigation." + prop.name)}
                {console.log("single menu items 3 ------------> ", prop.name)}
              </NavLink>
            </NavItem>
          );
        }
      }
    });
  };

  const { bgColor, routes, logo, logoText } = props;
  let navbarBrandProps;
  if (logo && logo.innerLink) {
    navbarBrandProps = {
      to: logo.innerLink,
      tag: Link,
    };
  } else if (logo && logo.outterLink) {
    navbarBrandProps = {
      href: logo.outterLink,
      target: "_blank",
    };
  }
  if (logoText && logoText.innerLink) {
    navbarBrandProps = {
      to: logoText.innerLink,
      tag: Link,
    };
  } else if (logoText && logoText.outterLink) {
    navbarBrandProps = {
      href: logoText.outterLink,
      target: "_blank",
    };
  }

  return (
    <Navbar className="navbar-vertical fixed-left navbar-light bg-white" expand="md" id="sidenav-main">
      <Container fluid>
        {/* Toggler */}
        <button className="navbar-toggler" type="button" onClick={toggleCollapse}>
          <span className="navbar-toggler-icon" />
        </button>

        {/* Brand - Top logo of the navigation for full web*/}
        {logo ? (
          <NavbarBrand className="pt-0" {...navbarBrandProps}>
            <img alt={logo.imgAlt} className="navbar-brand-img" src={logo.imgSrc} />
          </NavbarBrand>
        ) : null}

        {logoText ? (
          <NavbarBrand>
            <div style={ChsStyles.LogoText}>{logoText}</div>
          </NavbarBrand>
        ) : null}

        {/* User */}
        {userDetails?.user ? (
          <Nav className="align-items-center d-md-none">
            {/* User options for the mobile/responsive user */}
            <UncontrolledDropdown nav>
              <DropdownToggle nav>
                <Media className="align-items-center">
                  <span className="avatar avatar-sm rounded-circle">
                    <Avatar className="purple" alt={userDetails?.user.firstName} src={userDetails?.user.profilePicture ? Config.SERVER_API_URL + Config.PUBLIC_PROF_PIC + userDetails?.user.profilePicture : null}>
                      {userDetails && userDetails?.user.firstName && userDetails?.user.firstName.length > 0 ? userDetails?.user.firstName.charAt(0).toUpperCase() : ""}
                    </Avatar>
                  </span>
                </Media>
              </DropdownToggle>

              <DropdownMenu className="dropdown-menu-arrow" right>
                <DropdownItem className="noti-title" header tag="div">
                  <h6 className="text-overflow m-0">Welcome!</h6>
                </DropdownItem>
                <DropdownItem to="/registered/user_profile" tag={Link}>
                  <i className="ni ni-single-02" />
                  <span>My profile</span>
                </DropdownItem>
                <DropdownItem divider />
                <DropdownItem onClick={logout}>
                  <i className="fas fa-power-off" />
                  <span>Logout</span>
                </DropdownItem>
              </DropdownMenu>
            </UncontrolledDropdown>
          </Nav>
        ) : null}

        {/* Collapse left navigation */}
        <Collapse navbar isOpen={collapseOpen}>
          {/* Collapse header */}
          {/* Left menu header for the mobile/responsive user */}
          <div className="navbar-collapse-header d-md-none">
            <Row>
              {logo ? (
                <Col className="collapse-brand" xs="6">
                  {logo.innerLink ? (
                    <Link to={logo.innerLink}>
                      <img alt={logo.imgAlt} src={logo.imgSrc} />
                    </Link>
                  ) : (
                    <a href={logo.outterLink}>
                      <img alt={logo.imgAlt} src={logo.imgSrc} />
                    </a>
                  )}
                </Col>
              ) : null}
              {logoText ? (
                <Col className="collapse-brand" xs="6">
                  {logoText.innerLink ? (
                    <Link to={logo.innerLink}>
                      <div style={ChsStyles.LogoText}>{logoText}</div>
                    </Link>
                  ) : (
                    <a href={logoText.outterLink}>
                      <div style={ChsStyles.LogoText}>{logoText}</div>
                    </a>
                  )}
                </Col>
              ) : null}

              {/* Navigator close button */}
              <Col className="collapse-close" xs="6">
                <button className="navbar-toggler" type="button" onClick={toggleCollapse}>
                  <span />
                  <span />
                </button>
              </Col>
            </Row>
          </div>

          {/* Main navigation */}
          <Nav navbar>
            {createLinks(routes, "admin")}

            <NavItem>
              <a className="nav-link" target="_blank" href="https://app.adpc.net/resourcesapp/">
                <i className="fas fa-book text-gray" />
                {t("navigation.Resources")}
              </a>
            </NavItem>

            <NavItem>
              <a className="nav-link" target="_blank" href="#"
                onClick={showGuide}
              >
                <i className="fas fa-file-alt text-gray" />
                {t("navigation.Guideline to Take Assessment")}
              </a>
            </NavItem>
          </Nav>

          {/* Divider */}
          {/* <hr className="my-3" /> */}
        </Collapse>
      </Container>
    </Navbar>
  );
};

Sidebar.defaultProps = {
  routes: [{}],
};

Sidebar.propTypes = {
  // links that will be displayed inside the component
  routes: PropTypes.arrayOf(PropTypes.object),
  logo: PropTypes.shape({
    // innerLink is for links that will direct the user within the app
    // it will be rendered as <Link to="...">...</Link> tag
    innerLink: PropTypes.string,
    // outterLink is for links that will direct the user outside the app
    // it will be rendered as simple <a href="...">...</a> tag
    outterLink: PropTypes.string,
    // the image src of the logo
    imgSrc: PropTypes.string.isRequired,
    // the alt for the img
    imgAlt: PropTypes.string.isRequired,
  }),
  logoText: PropTypes.string,
};

export default Sidebar;
