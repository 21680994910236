import { fetchLocalizationBySurveyTemplate, updateLocalizationBySurveyTemplate, deleteSurveyLocalization } from "actions/survey";
import { fetchMasterDataLanguageAll } from "actions/masterData";

// core components
import PageHeader from "components/Headers/PageHeader.js";
import { LOGINSTATE } from "../../../config/config.json";
import Lodash from "lodash";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useHistory, useLocation } from "react-router-dom";
// reactstrap components
import { Badge, Button, Card, CardHeader, Container, CardFooter, Col, Row, Table } from "reactstrap";
import { GlobalStylesColor, ChsStyles } from "constants/globalStyles";
import { Tooltip } from "@material-ui/core";
import { SURVEY_TEMPLATE_LOCALE_LOCAL } from "constants/actionTypes";
import { survey_status } from "helper";
import "react-confirm-alert/src/react-confirm-alert.css"; //Import css
import { confirmAlert } from "react-confirm-alert";
import PublicOutlinedIcon from "@material-ui/icons/PublicOutlined";
import VpnLockOutlinedIcon from "@material-ui/icons/VpnLockOutlined";

// Populate version row for each survey type
const SurveyRow = ({ survey, isLast, editClick, statusClick, deleteClick }) => (
  // Version | Language Code | Title | Status
  <tr>
    <td style={{ whiteSpace: "break-spaces" }}>{survey.languageCode}</td>
    <td style={{ whiteSpace: "break-spaces", color: GlobalStylesColor.mbppThemeBlue }}>{survey.title}</td>
    <td style={{ whiteSpace: "break-spaces" }}>{survey.status}</td>
    <td>
      {survey.published ? (
        <Badge color="" className="badge-dot">
          <i className="bg-success" />
          YES
        </Badge>
      ) : (
        <Badge color="" className="badge-dot mr-4">
          <i className="bg-warning" />
          NO
        </Badge>
      )}
    </td>
    <td className="text-right">
      <div className="row">
        <Tooltip title="Edit" arrow>
          <div
            className="navbar-toggler"
            style={{ cursor: "pointer" }}
            onClick={(e) => {
              editClick(e, survey);
            }}
          >
            <i className="far fa-edit" style={{ opacity: survey.published ? 0.3 : 1 }} />
          </div>
        </Tooltip>

        <Tooltip title="Change publish status" arrow>
          <div
            className="navbar-toggler"
            style={{ cursor: "pointer" }}
            onClick={(e) => {
              statusClick(e, survey);
            }}
          >
            {survey.published ? <PublicOutlinedIcon style={{ color: GlobalStylesColor.mbppIconColorLight }} /> : <VpnLockOutlinedIcon style={{ color: GlobalStylesColor.mbppIconColorLight }} />}
          </div>
        </Tooltip>

        <Tooltip title="Delete" arrow>
          <div
            className="navbar-toggler"
            style={{ cursor: "pointer" }}
            onClick={(e) => {
              confirmAlert({
                title: 'Confirm delete',
                buttons: [
                  { label: "No", onClick: () => { } },
                  {
                    label: "Yes", onClick: () => {
                      deleteClick(e, survey);
                    }
                  },
                ],
                closeOnEscape: false,
                closeOnClickOutside: false
              });
            }}
          >
            <i className="fa fa-minus-circle" />
          </div>
        </Tooltip>
      </div>
    </td>
  </tr>
);

// export function
const SurveyLocalization = (props) => {
  // dispatch and history
  const dispatch = useDispatch();
  const history = useHistory();
  const location = useLocation();

  const [surveryTitle, setSurveryTitle] = useState("");
  const [surveyVersion, setSurveyVersion] = useState("");

  useEffect(() => {
    // fetching the localizations for the survey
    const survey = props.location.state?.survey;
    if (survey && !Lodash.isEmpty(survey)) {
      dispatch(fetchLocalizationBySurveyTemplate({ surveyId: survey._id }, history));
      setSurveryTitle(survey.title);
      setSurveyVersion(survey.version);
    }
    dispatch(fetchMasterDataLanguageAll());
  }, []);

  // get forum topics
  const surveyLocalizationAll = useSelector((state) => state.survey.surveyLocalizationAll);
  useEffect(() => {
    if (LOGINSTATE) console.log("surveyLocalizationAll: ", surveyLocalizationAll);
    if (LOGINSTATE) console.log("surveyLocalization languages: ", Lodash.get(surveyLocalizationAll, "languageCode"));
  }, [surveyLocalizationAll]);

  // handle Click event of the survey. Navigate to the localization page. There will be auto english version for every version
  const handleEditClick = (e, surveyLocale) => {
    e.preventDefault();
    if (LOGINSTATE) console.log("current surveyLocale: ", surveyLocale);
    dispatch({ type: SURVEY_TEMPLATE_LOCALE_LOCAL, payload: surveyLocale });
    localStorage.setItem(SURVEY_TEMPLATE_LOCALE_LOCAL, JSON.stringify(surveyLocale));
    history.push({ pathname: "/registered/assessment_localization_language", state: { survey: surveyLocale, languages: language } });
  };

  const handleStatusClick = (e, surveyLocale) => {
    e.preventDefault();
    if (surveyLocale.status === survey_status.COMPLETE) {
      surveyLocale["published"] = !surveyLocale.published;
      surveyLocale["localizationId"] = surveyLocale._id;
      dispatch(updateLocalizationBySurveyTemplate(surveyLocale, history));
    } else {
      confirmAlert({ title: "Status Error!", message: "Please complete the assessment to be published.", buttons: [{ label: "Ok", onClick: () => {} }],closeOnEscape: false, closeOnClickOutside: false });
    }
  };

  const language = useSelector((state) => state.masterData.languageAll);

  const handleCreateClick = (e) => {
    e.preventDefault();

    let locales = surveyLocalizationAll.map((survey, i) => {
      return survey;
    });
    locales.push({languageCode: "en"});
    if (LOGINSTATE) console.log("locales: ", locales);
    const survey = props.location.state?.survey;
    if (LOGINSTATE) console.log("--------------------- local store saving: ", survey);
    dispatch({ type: SURVEY_TEMPLATE_LOCALE_LOCAL, payload: survey });
    localStorage.setItem(SURVEY_TEMPLATE_LOCALE_LOCAL, JSON.stringify(survey));

    let finalLang = Lodash.reject(language, function (currentLang) {
      return Lodash.find(locales, { languageCode: currentLang.code });
    });
    if (LOGINSTATE) console.log("finalLang", finalLang);
    if (Lodash.isEmpty(finalLang)) {
      confirmAlert({
        title: "Error",
        message: "Assessment localizations are exist for all languages.",
        buttons: [
          {
            label: "Ok",
            onClick: () => {},
          },
        ],
        closeOnEscape: false, closeOnClickOutside: false,
      });
    } else {
      history.push({ pathname: "/registered/assessment_localization_language", state: { survey: survey, languages: finalLang } });
    }
  };

  const handleDeleteClick = (e, survey) => {
    if (LOGINSTATE) console.log("survey", survey);
    e.preventDefault();
    let values = {
      localizationId: survey._id,
    };
    dispatch(deleteSurveyLocalization(values, history));
  };

  return (
    <>
      <PageHeader />

      {/* Page content */}
      <Container className="mt--7" fluid>
        <Row className="mt-5">
          <div className="col">
            <Card className="shadow">
              <CardHeader className="border-0">
                <div className="row">
                  <div className="mb-xl-0 col-10">
                    <h3 className="mb-0">{surveryTitle}</h3>
                    <h6 className="mb-0">{surveyVersion}</h6>
                  </div>
                  <div className="mb-xl-0 col-2 text-right">
                    <div
                      className="navbar-toggler"
                      style={{ cursor: "pointer" }}
                      onClick={(e) => {
                        handleCreateClick(e);
                      }}
                    >
                      <i className="fas fa-plus-circle" style={{ fontSize: 30, color: GlobalStylesColor.mbppThemeBlue }} />
                    </div>
                  </div>
                </div>
              </CardHeader>
              <Table className="align-items-center table-flush" responsive>
                <thead className="thead-light">
                  <tr>
                    <th scope="col">LANGUAGE CODE</th>
                    <th scope="col">Title</th>
                    <th scope="col">STATUS</th>
                    <th scope="col">PUBLISHED</th>
                    <th scope="col">Actions</th>
                  </tr>
                  <tr>
                    <td style={{ whiteSpace: "break-spaces" }}>en</td>
                    <td style={{ whiteSpace: "break-spaces", color: GlobalStylesColor.mbppThemeBlue }}>English locale (default)</td>
                    <td style={{ whiteSpace: "break-spaces" }}>COMPLETE</td>
                    <td style={{ whiteSpace: "break-spaces" }}>
                      <Badge color="" className="badge-dot">
                        <i className="bg-success" />
                        YES
                      </Badge>
                    </td>
                    <td style={{ whiteSpace: "break-spaces" }}>
                      <div className="row">
                        <Tooltip title="You can't edit the default language" arrow>
                          <div className="navbar-toggler" style={{ cursor: "pointer" }}>
                            <i className="far fa-edit" style={{ opacity: 0.3, color: GlobalStylesColor.mbppIconColor }} />
                          </div>
                        </Tooltip>
                        <Tooltip title="You can't change the publish status" arrow>
                          <div className="navbar-toggler" style={{ cursor: "pointer" }}>
                            <PublicOutlinedIcon style={{ opacity: 0.3, color: GlobalStylesColor.mbppIconColor }} />
                          </div>
                        </Tooltip>
                      </div>
                    </td>
                  </tr>
                </thead>
                <tbody>
                  <tr></tr>
                  {surveyLocalizationAll &&
                    !Lodash.isEmpty(surveyLocalizationAll) &&
                    surveyLocalizationAll.map((survey, i) => {
                      return <SurveyRow key={i} survey={survey} isLast={i === surveyLocalizationAll.length - 1} editClick={handleEditClick} statusClick={handleStatusClick} deleteClick={handleDeleteClick} />;
                    })}
                </tbody>
              </Table>
            </Card>
          </div>
        </Row>

        <Card className="shadow">
          <CardFooter className="py-4">
            <Row>
              <Col lg="6" className="text-left">
                <Button
                  style={ChsStyles.GeneralButton}
                  color="primary"
                  onClick={() => {
                    window.history.back();
                  }}
                >
                  BACK
                </Button>
              </Col>
              <Col lg="6" className="text-center"></Col>
            </Row>
          </CardFooter>
        </Card>
      </Container>
    </>
  );
};

export default SurveyLocalization;
