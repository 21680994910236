import { Grid, TextField as TextFieldDef } from "@material-ui/core";
import { withStyles, MuiThemeProvider, createMuiTheme } from "@material-ui/core";
// Option 2
import { TextField, RtlTextField } from "../../../components/Custom/RTLTextField";

import { MbppStyles } from "constants/globalStyles";
import { fetchMasterDataLanguage } from "actions/masterData";
import { LANGUAGE } from "constants/actionTypes";
import { useFormik } from "formik";
import React, { useState, useRef, createRef, useEffect } from "react";
import "react-confirm-alert/src/react-confirm-alert.css"; // Import css
import { useDispatch, useSelector } from "react-redux";
import { useHistory, useLocation } from "react-router-dom";
// reactstrap components
import { Button, Card, CardBody, Col, Container, Form, FormGroup, Row } from "reactstrap";
import * as yup from "yup";
import { resetPassword } from "actions/user";
import ReCaptchaV2 from "react-google-recaptcha";
import { APP_RECAPTCHA, LOGINSTATE } from "../../../config/config.json";

import { useTranslation, initReactI18next, Trans } from "react-i18next";
import { RTL_FLAG_RIGHT, RTL_FLAG_LEFT, SYS_DEFAULT_LANGUAGE_CODE } from "helper";
import Lodash from "lodash";
import { RECAPTCHA_LANG_LIST } from "helper";

// const validationSchemaSignin = yup.object({
//   email: yup.string("Enter your email").email("Enter a valid email").required("Email is required"),
// });

const Login = () => {
  // dispatch and history
  const dispatch = useDispatch();
  const history = useHistory();
  const location = useLocation();
  const recaptchaRef = useRef();

  const [isRegEnable, setIsRegEnable] = useState(false);
  const [isRegToken, setIsRegToken] = useState("");
  const [rtlAlignment, setRtlAlignment] = useState(RTL_FLAG_LEFT);
  const [recaptchaLanguage, setRecaptchaLanguage] = useState(null);

  const { t, i18n } = useTranslation();

  const userLang = useSelector((state) => state.masterData.languageByCode);
  if (LOGINSTATE) console.log("Reset password userLanguageByCode: ", userLang);

  const languageFromStore = localStorage.getItem(LANGUAGE)
  if (LOGINSTATE) console.log("Reset password languageFromLocalStore2: ", languageFromStore);

  useEffect(() => {

    if (userLang && userLang.language && userLang.language.length > 0) {
      const lang = userLang.language[0]

      // dispatch(fetchMasterDataLanguage(lang.code));
      i18n.changeLanguage(lang.code);
      // reCAPTCHA languages as per 2022-August
      setRecaptchaLanguage(Lodash.indexOf(RECAPTCHA_LANG_LIST, lang.code) >= 0 ? lang.code : SYS_DEFAULT_LANGUAGE_CODE);

      if (lang && lang.dir === "rtl") {
        setRtlAlignment(RTL_FLAG_RIGHT);
      }
      else {
        setRtlAlignment(RTL_FLAG_LEFT);
      }
    }
    else {
      // To fix location reload, may case issue with recaptcha re-render. test didn't show any issue
      i18n.changeLanguage(SYS_DEFAULT_LANGUAGE_CODE);
      setRecaptchaLanguage(SYS_DEFAULT_LANGUAGE_CODE);
      setRtlAlignment(RTL_FLAG_LEFT);
    }
  }, [i18n, userLang]);

  const formik = useFormik({
    initialValues: {
      email: "",
      recapture: "",
    },
    // validationSchema: validationSchemaSignin,
    validationSchema: yup.object({
      email: yup.string(t('registerPage.section2.enterEmail'))
        .email(t('registerPage.section2.emailInvalid'))
        .required(t('registerPage.section2.emailRequired')),
    }),
    onSubmit: (values, onSubmitProps) => {
      if (LOGINSTATE) console.log("### values: ", values);
      dispatch(resetPassword(values, history, t));
    },
    onReset: () => { },
  });

  return (
    <>
      {/* <MuiThemeProvider theme={createMuiTheme({ direction: "rtl" })}> */}

      <div className="main-content" style={{ backgroundColor: "#af1e23" }}>
        {/* ToDo: explore */}
        {/* <AuthNavbar /> */}
        <div
          className="header py-6 py-lg-6"
          style={{
            backgroundImage: "url(" + require("../../../assets/img/theme/img_bg.jpg").default + ")",
            backgroundSize: "cover",
            backgroundPosition: "center top",
          }}
        >
          {/* Mask */}
          {/*bg-gradient-yellow*/}
          <span className="mask opacity-2" />
          <Container>
            <div className="header-body text-center mb-5">
              <Row className="justify-content-center">
                <Col lg="5" md="6">
                  <br />
                  <br />
                </Col>
              </Row>
            </div>
          </Container>
          <div className="separator separator-bottom separator-skew zindex-100" style={{ marginBottom: "-2px" }}>
            <svg xmlns="http://www.w3.org/2000/svg" preserveAspectRatio="none" version="1.1" viewBox="0 0 2560 100" x="0" y="0">
              <polygon className="fill-default" points="2560 0 2560 100 0 100" />
            </svg>
          </div>
        </div>
        {/* Page content */}
        <Container className="mt--7 pb-5">
          <Row className="justify-content-center">
            <Col lg="5" md="6">
              <Card className="bg-secondary shadow border-1">
                <CardBody className="px-lg-4 py-lg-4">

                  <div className="heading-title text-center mb-4" style={MbppStyles.Topic}>
                    <span>{t("resetPage.section1.title")}</span>
                  </div>

                  <Form role="form" onSubmit={formik.handleSubmit} onReset={formik.handleReset}>
                    <FormGroup className="mb-3 ">
                      <Grid container spacing={1} style={MbppStyles.Grid}>
                        <Grid item xs={1} sm={1}>
                          <i className="ni ni-email-83" />
                        </Grid>
                        <Grid item xs={11} sm={11}>
                          <RtlTextField
                            fullWidth
                            rtol={rtlAlignment === RTL_FLAG_RIGHT}
                            id="email"
                            name="email"
                            label={t("resetPage.section1.email")}
                            variant="outlined"
                            value={formik.values.email}
                            onChange={formik.handleChange}
                            className={"form-control-alternative form-control-edit"}
                            error={formik.touched.email && Boolean(formik.errors.email)}
                            helperText={formik.touched.email && formik.errors.email}
                          />
                        </Grid>
                      </Grid>
                    </FormGroup>

                    <div className="text-center" style={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                      {recaptchaLanguage
                        && <ReCaptchaV2
                          className="text-center"
                          ref={recaptchaRef}
                          sitekey={APP_RECAPTCHA.SITE_KEY}
                          hl={recaptchaLanguage}
                          onChange={(e) => {
                            // if condition to check e null or not, if it is null msg
                            setIsRegEnable(true);
                            setIsRegToken(e);
                            formik.values.recapture = e;
                          }}
                        />}
                    </div>

                    <div className="text-center">
                      <br />
                      <Button variant="contained" color="primary" type="submit" disabled={!isRegEnable}>
                        {t("resetPage.section1.reset")}
                      </Button>
                    </div>
                  </Form>
                </CardBody>
              </Card>

              <Row className="justify-content-center">
                <Col lg="5" md="6">
                  <br />
                  <br />
                </Col>
              </Row>

            </Col>
          </Row>
        </Container>
      </div>

      {/* </MuiThemeProvider> */}
    </>
  );
};

export default Login;
