import { MASTERDATA_FETCH_LANGUAGE, MASTERDATA_FETCH_ONE_LANGUAGE, MASTER_DATA_UPDATE, COUNTRY_FETCH_ALL, MASTER_DATA_LAN_UPDATE, LANDING_FETCH, LANDING_UPDATE, LOCALIZATION_FETCH, LANGUAGE } from "constants/actionTypes";
import { LOGINSTATE } from "config/config.json";

const initState = {
  languageAll: [],
  languageByCode: {},
  updateMasterData: [],
  countryAll: [],
  updateMasterDataLan: [],
  fetchedLandingContent: {},
  updatedLandingContent: {},
  localizationContentAll: [],
};

const masterDataReducer = (masterData = initState, action) => {
  if (LOGINSTATE) console.log("Dispatch is called for: ", action.type);
  if (LOGINSTATE) console.log("Dispatch is called with: ", action.payload);
  switch (action.type) {
    // Get all the languages
    case MASTERDATA_FETCH_LANGUAGE:
      return { ...masterData, languageAll: action.payload };
    case MASTERDATA_FETCH_ONE_LANGUAGE:
      return { ...masterData, languageByCode: action.payload };
    case MASTER_DATA_UPDATE:
      return { ...masterData, updateMasterData: action.payload };
    case MASTER_DATA_LAN_UPDATE:
      return { ...masterData, updateMasterDataLan: action.payload };

    case COUNTRY_FETCH_ALL:
      return { ...masterData, countryAll: action.payload };

    case LANDING_FETCH:
      return { ...masterData, fetchedLandingContent: action.payload };
    case LANDING_UPDATE:
      return { ...masterData, updatedLandingContent: action.payload };

    case LOCALIZATION_FETCH:
      return { ...masterData, localizationContentAll: action.payload };

    case LANGUAGE:
      return { ...masterData, localLanguage: action.payload };

    default:
      return masterData;
  }
};

export default masterDataReducer;
