import React, { useEffect, useState } from "react";
import { Link, NavLink } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import {
  Container, Nav, Navbar, NavItem, Col, Row,
  DropdownItem, DropdownMenu, DropdownToggle, Media, UncontrolledDropdown, Avatar
} from "reactstrap"; // reactstrap components
import "../../assets/css/chs-custom.css";
import { useHistory, useLocation } from "react-router-dom";
import { MASTERDATA_FETCH_ONE_LANGUAGE } from "constants/actionTypes";

import { useTranslation } from "react-i18next";
import { fetchMasterDataLanguageAll } from "../../actions/masterData";
import { LOGINSTATE } from "../../config/config.json";
import { LANGUAGE } from "constants/actionTypes";
import Lodash from "lodash";

import i18next from "i18next";
import Backend from "i18next-http-backend";

import TranslateOutlinedIcon from "@material-ui/icons/TranslateOutlined";

const LandingNavBar = () => {
  const dispatch = useDispatch();
  const history = useHistory();

  const [language, setLanguage] = useState({});
  const [currentLanguage, setCurrentLanguage] = useState(null)
  const [languageDisabled, setLanguageDisabled] = useState(false)

  const { t, i18n } = useTranslation();

  const languageAll = useSelector((state) => state.masterData.languageAll);
  if (LOGINSTATE) console.log("languageAll: ", languageAll);

  // Fetch the saved single language
  // To set language when a page is open in a new tab. By this time i18next will show the content on default locale
  const userLang = useSelector((state) => state.masterData.languageByCode);
  if (LOGINSTATE) console.log("userLang: ", userLang);

  const pathname = window.location.pathname

  useEffect(() => {
    dispatch(fetchMasterDataLanguageAll());
  }, []);

  // Select only internationalized language
  useEffect(() => {
    let internationalizedLanguage = Lodash.filter(languageAll, { int: "Yes" });
    setLanguage(internationalizedLanguage);
  }, [languageAll]);

  // Select the dropdown language based on saved data
  useEffect(() => {
    const dropdownLanguage = currentLanguage
      ? currentLanguage
      : (userLang && userLang.language && userLang.language[0] && userLang.language[0].code)
        ? userLang.language[0].code
        : 'en'
    setCurrentLanguage(dropdownLanguage)
  }, [currentLanguage, userLang])

  useEffect(() => {
    if (LOGINSTATE) console.log("i18Language changed to: ", i18next.language)
  }, [i18next.language])

  // Set the dropdown enable for selected pages only
  useEffect(() => {
    const currentURL = window.location.href // Current url

    // no need to show the language drop down for registration and password reset
    if (pathname.includes('/register') || pathname.includes('/reset')) {
      setLanguageDisabled(true)
    }
    else {
      setLanguageDisabled(false)
    }
  }, [pathname]);

  const onChangeLanguage = (event) => {
    const selectedLanguage = event.target.value

    // If currently in FAQs page, bring the new content for the changed language
    // Below or: if (history.pathname === "/public/faq_public") {
    if (pathname.includes('/faq_public')) {
      history.push({ pathname: "/public/faq_public", state: { languageCode: selectedLanguage } });
    }

    // Set the public page contents to changed language
    i18n.changeLanguage(selectedLanguage);
    setCurrentLanguage(selectedLanguage)

    // localStorage.setItem(LANGUAGE, JSON.stringify(selectedLanguage));
    const selectLanguage = Lodash.filter(language, { code: selectedLanguage })
    dispatch({ type: MASTERDATA_FETCH_ONE_LANGUAGE, payload: { language: selectLanguage } });
  };


  return (
    <>
      <Navbar className="navbar-top navbar-dark" expand="md" style={{ padding: 0 }}>
        <Container style={{ backgroundColor: "#fff" }}>

          <Nav className="collapse-brand xs">
            <img alt="APP-Tool" src={require("../../assets/img/brand/img_logo.png").default} style={{ maxHeight: 75, width: "auto" }} />
          </Nav>

          <Nav>
            {!Lodash.isEmpty(Object.values(language)) ?
              <div style={{ backgroundColor: '#af1e23' }}>
                <TranslateOutlinedIcon style={{ backgroundColor: '#af1e23', color: 'white', height: 30, paddingLeft: 3 }} />
                <select
                  name="language"
                  value={currentLanguage}
                  onChange={onChangeLanguage}
                  disabled={languageDisabled}
                  style={{ border: 'solid', borderColor: '#af1e23', verticalAlign: 'middle' }}
                >
                  {Object.values(language).map((key, i) => {
                    return <option value={key.code}>{key.name}</option>;
                  })}
                </select>
              </div>
              : null
            }
          </Nav>

          {/* PC navigation */}
          <Nav className="align-items-center d-none d-md-flex" style={{}}>
            <NavItem style={{ marginRight: 20 }}>
              <NavLink
                className="nav-link-icon"
                to="/public/home"
                tag={Link}
                style={{}}
                activeStyle={{ color: "#57514d" }}
              >
                <span className="nav-link-inner--text">
                  <b>{t('landingNavBar.Home')}</b>
                </span>
              </NavLink>
            </NavItem>
            <NavItem style={{ marginRight: 20 }}>
              <NavLink
                className="nav-link-icon"
                to="/public/faq_public"
                tag={Link}
                style={{}}
                activeStyle={{ color: "#57514d" }}
              >
                <span className="nav-link-inner--text">
                  <b>{t('landingNavBar.FAQ')}</b>
                </span>
              </NavLink>
            </NavItem>
            <NavItem style={{ marginRight: 5 }}>
              <NavLink
                className="nav-link-icon"
                to="/public/login"
                tag={Link}
                style={{}}
                onClick={(e) => {
                  // reset language after clear local cache
                  const currentLanguage = localStorage.getItem(LANGUAGE);
                  localStorage.clear();
                  localStorage.setItem(LANGUAGE, currentLanguage);
                }}
                // activeClassName="text-grey"
                activeStyle={{ color: "#57514d" }}
              >
                <span className="nav-link-inner--text">
                  <b>{t('landingNavBar.Login')}</b>
                </span>
              </NavLink>
            </NavItem>
          </Nav>

          {/* mobile navigation */}
          <Nav className="align-items-center d-md-none" navbar>
            <UncontrolledDropdown nav>
              <DropdownToggle className="pr-0" nav>
                <Media className="align-items-center">
                  <span className="navbar-toggler-icon" style={{ backgroundColor: '#af1e23', minHeight: '40px', marginRight: '2px' }} />
                </Media>
              </DropdownToggle>

              <DropdownMenu className="dropdown-menu-arrow" right>
                <DropdownItem>
                  <NavLink
                    className="nav-link-icon"
                    to="/public/home"
                    tag={Link}
                    style={{}}
                    activeStyle={{ color: "#57514d" }}
                  >
                    <span className="nav-link-inner--text">
                      <b>{t('landingNavBar.Home')}</b>
                    </span>
                  </NavLink>
                </DropdownItem>
                <DropdownItem>
                  <NavLink
                    className="nav-link-icon"
                    to="/public/faq_public"
                    tag={Link}
                    style={{}}
                    activeStyle={{ color: "#57514d" }}
                  >
                    <span className="nav-link-inner--text">
                      <b>{t('landingNavBar.FAQ')}</b>
                    </span>
                  </NavLink>
                </DropdownItem>
                <DropdownItem>
                  <NavLink
                    className="nav-link-icon"
                    to="/public/login"
                    tag={Link}
                    style={{}}
                    onClick={(e) => {
                      // reset language after clear local cache
                      const currentLanguage = localStorage.getItem(LANGUAGE);
                      localStorage.clear();
                      localStorage.setItem(LANGUAGE, currentLanguage);
                    }}
                    activeStyle={{ color: "#57514d" }}
                  >
                    <span className="nav-link-inner--text">
                      <b>{t('landingNavBar.Login')}</b>
                    </span>
                  </NavLink>
                </DropdownItem>
              </DropdownMenu>

            </UncontrolledDropdown>
          </Nav>

        </Container>
      </Navbar>
    </>
  );
};

export default LandingNavBar;
