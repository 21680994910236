import React, { useState, useEffect } from "react";

// reactstrap components
import { Card, Container, Row, Col, CardBody, CardHeader } from "reactstrap";
import { useDispatch, useSelector } from "react-redux";
// core components

import CommonHeader from "components/Headers/CommonHeader";
import { fetchMasterDataLanguage } from "actions/masterData";
import { fetchProfileOrg } from "actions/user";
import { LOGINSTATE } from "../../../config/config.json";
import { GlobalStylesColor } from "constants/globalStyles";

import { confirmAlert } from "react-confirm-alert";

import { useTranslation, initReactI18next, Trans } from "react-i18next";
import { RTL_FLAG_RIGHT } from "helper";

const Forum = () => {
  const dispatch = useDispatch();
  const [rtlAlignment, setRtlAlignment] = useState("left");
  const [isAppCountry, setIsAppCountry] = useState("No")

  const { t, i18n } = useTranslation();

  const userLang = useSelector((state) => state.masterData.languageByCode);
  if (LOGINSTATE) console.log("userLanguage", userLang);
  useEffect(() => {
    i18n.changeLanguage(jUser?.user?.language);

    dispatch(fetchProfileOrg());

    let params = {
      user: {
        code: jUser?.user?.language,
      },
    };
    dispatch(fetchMasterDataLanguage(params.user));
  }, []);

  useEffect(() => {
    userLang &&
      userLang.language &&
      Object.values(userLang.language).map((lang, i) => {
        if (lang && lang.dir === "rtl") {
          setRtlAlignment("right");
        }
      });
  }, [userLang]);

  const currentUserProfileOrg = useSelector((state) => state.user.userProfileOrg);
  if (LOGINSTATE) console.log("userOrg: ", currentUserProfileOrg);

  useEffect(() => {
    let appCountry = currentUserProfileOrg.profile?.employment?.organization?.country?.app_country;
    setIsAppCountry(appCountry);
  }, [currentUserProfileOrg]);

  let currentUser = localStorage.getItem("userDetails");
  let jUser = JSON.parse(currentUser);

  return (
    <>
      <CommonHeader hType="resources" />

      {/* Page content */}
      <Container className="mt--7" fluid>
        {isAppCountry === "Yes"
          ?
          <Card className="mt-5">
            <CardHeader style={{ textAlign: rtlAlignment === RTL_FLAG_RIGHT ? "right" : "left" }}>
              <h3 style={{ color: GlobalStylesColor.mbppThemeBlue }}>{t("privacyPolicy.appCountry.header")}</h3>
            </CardHeader>

            {rtlAlignment === RTL_FLAG_RIGHT ? (
              <CardBody style={{ textAlign: "right" }}>
                {/* app country - right to left */}
                <Row style={{ margin: 5 }}>
                  <Col>
                    <h3> </h3>
                    <p>{t("privacyPolicy.appCountry.paragraph1")}</p>

                    <h3 style={{ color: GlobalStylesColor.mbppThemeBlue }}>{t("privacyPolicy.appCountry.title1")}</h3>
                    <p>{t("privacyPolicy.appCountry.paragraph2")}</p>

                    <p>{t("privacyPolicy.appCountry.title2")}</p>
                    <ul>
                      {t("privacyPolicy.appCountry.listItem1")}
                      <br />
                      {t("privacyPolicy.appCountry.listItem2")}
                    </ul>
                  </Col>
                </Row>
              </CardBody>
            ) : (
              <CardBody style={{ textAlign: "justify" }}>
                {/* app country - left to right */}
                <Row style={{ margin: 5 }}>
                  <Col>
                    <h3> </h3>
                    <p>{t("privacyPolicy.appCountry.paragraph1")}</p>

                    <h3 style={{ color: GlobalStylesColor.mbppThemeBlue }}>{t("privacyPolicy.appCountry.title1")}</h3>
                    <p>{t("privacyPolicy.appCountry.paragraph2")}</p>

                    <p>{t("privacyPolicy.appCountry.title2")}</p>
                    <ul>
                      {t("privacyPolicy.appCountry.listItem1")}
                      <br />
                      {t("privacyPolicy.appCountry.listItem2")}
                    </ul>
                  </Col>
                </Row>
              </CardBody>
            )}
          </Card>
          :
          <Card>
            <CardHeader style={{ textAlign: rtlAlignment === RTL_FLAG_RIGHT ? "right" : "left" }}>
              <h3 style={{ color: GlobalStylesColor.mbppThemeBlue }}>{t("privacyPolicy.nonAppCountry.header")}</h3>
            </CardHeader>

            {rtlAlignment === RTL_FLAG_RIGHT ? (
              <CardBody style={{ textAlign: "right" }}>
                {/* non app country - right to left */}
                <Row style={{ margin: 5 }}>
                  <Col>
                    <h3> </h3>
                    <p>{t("privacyPolicy.nonAppCountry.paragraph1")}</p>

                    <h3 style={{ color: GlobalStylesColor.mbppThemeBlue }}>{t("privacyPolicy.nonAppCountry.title1")}</h3>
                    <p>{t("privacyPolicy.nonAppCountry.paragraph2")}</p>

                    {/* <p>{t("privacyPolicy.nonAppCountry.title2")}</p>
                    <ul>
                      {t("privacyPolicy.nonAppCountry.listItem1")}
                      <br />
                      {t("privacyPolicy.nonAppCountry.listItem2")}
                    </ul> */}
                  </Col>
                </Row>
              </CardBody>
            ) : (
              <CardBody style={{ textAlign: "justify" }}>
                {/* non app country - left to right */}
                <Row style={{ margin: 5 }}>
                  <Col>
                    <h3> </h3>
                    <p>{t("privacyPolicy.nonAppCountry.paragraph1")}</p>

                    <h3 style={{ color: GlobalStylesColor.mbppThemeBlue }}>{t("privacyPolicy.nonAppCountry.title1")}</h3>
                    <p>{t("privacyPolicy.nonAppCountry.paragraph2")}</p>

                    {/* <p>{t("privacyPolicy.nonAppCountry.title2")}</p>
                    <ul>
                      {t("privacyPolicy.nonAppCountry.listItem1")}
                      <br />
                      {t("privacyPolicy.nonAppCountry.listItem2")}
                    </ul> */}
                  </Col>
                </Row>
              </CardBody>
            )}
          </Card>
        }
      </Container>
    </>
  );
};

export default Forum;
