import { fetchOrganizationAll, updateOrganization } from "actions/organization";

// core components
import PageHeader from "components/Headers/PageHeader.js";
import Config from "config/config.json";
import { LOGINSTATE } from "../../../config/config.json";
import Lodash from "lodash";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useHistory, useLocation } from "react-router-dom";
// reactstrap components
import { Card, CardHeader, Container, Row, Col, Table, CardFooter, FormGroup, InputGroup, InputGroupAddon, InputGroupText, Input } from "reactstrap";
import { GlobalStylesColor } from "constants/globalStyles";
import { confirmAlert } from "react-confirm-alert";
import "react-confirm-alert/src/react-confirm-alert.css"; // Import css
import Pagination from "react-responsive-pagination";
import { Tooltip } from "@material-ui/core";
import PublicOutlinedIcon from "@material-ui/icons/PublicOutlined";
import VpnLockOutlinedIcon from "@material-ui/icons/VpnLockOutlined";

// Populate version row for each survey type
const OrganizationRow = ({ itemCount, itemPageSize, itemCurrent, organization, editClick, statusClick }) => (
  // VERSION | TITLE | DESCRIPTION | STATUS
  <tr>
    <td style={{ whiteSpace: "break-spaces" }}>{itemPageSize * (itemCurrent - 1) + itemCount + 1}</td>
    <td style={{ whiteSpace: "break-spaces" }}>{organization?.country?.name}</td>
    <td style={{ whiteSpace: "break-spaces", color: GlobalStylesColor.mbppBlue }}>{organization?.name}</td>
    <td style={{ whiteSpace: "break-spaces" }}>{organization?.type}</td>
    {/* <td style={{ whiteSpace: "break-spaces" }}>{organization?.geographicArea}</td> */}
    <td style={{ whiteSpace: "break-spaces" }}>{organization?.legalStatus?.status ? "YES" : "NO"}</td>
    <td className="text-right">
      <div className="row">
        <Tooltip title="Change publish status" arrow>
          <div
            className="navbar-toggler"
            style={{ cursor: "pointer" }}
            onClick={(e) => {
              editClick(e, organization);
            }}
          >
            <i className="far fa-eye" style={{ color: GlobalStylesColor.mbppIconColor }} />
          </div>
        </Tooltip>
        <Tooltip title="Change publish status" arrow>
          <div
            className="navbar-toggler"
            style={{ cursor: "pointer" }}
            onClick={(e) => {
              let params = {
                organizationId: organization._id,
                status: !organization.status,
              };
              statusClick(e, params);
            }}
          >
            {organization.status ? <PublicOutlinedIcon style={{ color: GlobalStylesColor.mbppIconColorLight }} /> : <VpnLockOutlinedIcon style={{ color: GlobalStylesColor.mbppIconColorLight }} />}
          </div>
        </Tooltip>
      </div>
    </td>
  </tr>
);

// export function
const Survey = () => {
  // dispatch and history
  const dispatch = useDispatch();
  const history = useHistory();
  const location = useLocation();

  // pagination
  const itemPageSize = Config.ITEM_PAGE_SIZE;
  const [itemCurrent, setItemCurrent] = useState(1);
  const [itemPageContent, setItemPageContent] = useState([]);
  const [itemPageCount, setItemPageCount] = useState(1);
  // filter
  const [txtFilter, setTxtFilter] = useState("");
  const [filterdContent, setFilterdContent] = useState([]);

  // organization update
  const updatedOrganization = useSelector((state) => state.organization.orgProfileUpdate);

  useEffect(() => {
    // fetching all surveys
    dispatch(fetchOrganizationAll());
  }, [updatedOrganization]);

  // get organizations all
  const organizationAll = useSelector((state) => state.organization.organizationAll);

  // process content with filter
  useEffect(() => {
    // 1. filter the organizations form the filter
    let updatedOrgs = Lodash.filter(organizationAll, function (item) {
      return ((item.name.toLowerCase()).indexOf(txtFilter.toLowerCase()) > -1 || (item?.country?.name.toLowerCase()).indexOf(txtFilter.toLowerCase()) > -1);
    });

    // 2. Order the organizations on name
    const updatedOrgsOrdered = Lodash.orderBy(updatedOrgs, [updatedOrg => updatedOrg.name.toLowerCase()], ['asc'])
    setItemCurrent(1)
    setFilterdContent(updatedOrgsOrdered);
  }, [organizationAll, txtFilter]);

  // process the pagination with page content
  useEffect(() => {
    if (LOGINSTATE) console.log("filterdContent.length: ", filterdContent.length);
    // Set the pagination count
    let currentPageCount = Math.ceil(filterdContent.length / itemPageSize);
    setItemPageCount(currentPageCount);

    // Set the pagination content
    if (filterdContent && filterdContent.length > 0) {
      // make sure the page doesn't exceed the array endings
      let startCount = (itemCurrent - 1) * itemPageSize < 0 ? 0 : (itemCurrent - 1) * itemPageSize;
      let endCount = itemCurrent * itemPageSize > filterdContent.length ? filterdContent.length : itemCurrent * itemPageSize;

      let updatedArray = Lodash.slice(filterdContent, startCount, endCount);
      if (updatedArray && updatedArray.length > 0) {
        setItemPageContent(updatedArray);
      } else {
        setItemPageContent([]);
      }
    } else {
      setItemPageContent([]);
    }
  }, [itemCurrent, filterdContent]);

  const handleStatusClick = (e, organization) => {
    e.preventDefault();
    dispatch(updateOrganization(organization, false, history));
  };

  const handleEditClick = (e, organization, type) => {
    e.preventDefault();
    history.push({ pathname: "/registered/organizational_details", state: { org: organization } });
  };

  const handleLocalizationClick = (e, survey) => {
    e.preventDefault();
  };

  const handleCreateClick = (e, survey, type) => {
    e.preventDefault();
  };

  return (
    <>
      <PageHeader />

      {/* Page content */}
      <Container className="mt--7" fluid>
        <Row className="mt-4">
          <Col>
            <Card className="shadow">
              <CardHeader className="border-0">
                <Row>
                  <Col md="6">
                    <h3 className="mb-0">REGISTERED ORGANIZATIONS</h3>
                    <h6 className="mb-0">Institutional profile of organizations</h6>
                  </Col>
                  <Col md="6">
                    <FormGroup style={{ marginBottom: 0 }}>
                      <InputGroup className="input-group-alternative">
                        <InputGroupAddon addonType="prepend">
                          <InputGroupText>
                            <i className="ni ni-zoom-split-in" />
                          </InputGroupText>
                        </InputGroupAddon>
                        <Input
                          className="form-control-alternative"
                          placeholder="By Name or Country"
                          type="text"
                          onChange={(e) => {
                            // console.log(e.target.value);
                            setTxtFilter(e.target.value);
                          }}
                        />
                      </InputGroup>
                    </FormGroup>
                  </Col>
                </Row>
              </CardHeader>
              {/* <CardBody> */}
              <Table className="align-items-center table-flush" responsive>
                <thead className="thead-light">
                  <tr>
                    <th scope="col"></th>
                    <th scope="col">Country</th>
                    <th scope="col">Name</th>
                    <th scope="col">Type</th>
                    {/* <th scope="col">Geographic Area</th> */}
                    <th scope="col">Legal Entity</th>
                    <th scope="col">Actions</th>
                  </tr>
                </thead>
                <tbody>
                  {itemPageContent &&
                    itemPageContent.length > 0 &&
                    itemPageContent.map((organization, i) => {
                      {/* console.log(i, organization); */ }
                      return <OrganizationRow key={i} itemCount={i} itemPageSize={itemPageSize} itemCurrent={itemCurrent} organization={organization} editClick={handleEditClick} statusClick={handleStatusClick} localizationClick={handleLocalizationClick} createClick={handleCreateClick} />;
                    })}
                </tbody>
              </Table>
              {/* </CardBody> */}
              <CardFooter>
                <Row>
                  <Col lg="12">
                    {itemPageCount && itemPageCount > 0 ?
                      <Pagination
                        current={itemCurrent}
                        total={itemPageCount}
                        onPageChange={(current) => {
                          setItemCurrent(current);
                        }}
                      />
                      : null}
                  </Col>
                </Row>
              </CardFooter>
            </Card>
          </Col>
        </Row>
      </Container>
    </>
  );
};

export default Survey;
