const Grid = {
  marginBottom: "20px"
}

const GridEmpty = {
  // marginBottom: "20px"
}

const Input = {
  marginBottom: "20px",
}

const Head = {
  // color: '#525F7F',
  color: '#af1e23',
  fontWeight: "bold",
}

const Topic = {
  color: '#af1e23',
  fontWeight: "bold",
}

const SubTopic = {
  color: '#fdba2b',
  marginTop: "16px",
  marginBottom: "32px",
  fontWeight: "900!important",
}

const LogoText = {
  color: '#142237',
  fontWeight: "900!important",
}

const Question = {
  marginTop: 10,
  marginBottom: 10,
  fontWeight: '600!important'
}

const QuestionNote = {
  marginTop: 10,
  marginBottom: 10,
  fontStyle: 'italic'
}

const FeedbackError = {
  fontSize: 10,
  color: 'red'
}

const QuickNote = {
  fontSize: 10,
  marginTop: -10,
  marginBottom: 10,
}

const BottomButton = {
  minWidth: '30%',
  marginTop: 40
}

const GeneralButton = {
  minWidth: '30%',
  marginBottom: 5
}

export const ChsStyles = {
  Input: Input,
  Grid: Grid,
  GridEmpty: GridEmpty,
  SubTopic: SubTopic,
  Topic: Topic,
  Head: Head,
  LogoText: LogoText,
  Question: Question,
  QuestionNote: QuestionNote,
  FeedbackError: FeedbackError,
  QuickNote: QuickNote,
  BottomButton: BottomButton,
  GeneralButton: GeneralButton,
}

export const MbppStyles = {
  Input: Input,
  Grid: Grid,
  GridEmpty: GridEmpty,
  SubTopic: SubTopic,
  Topic: Topic,
  Head: Head,
  LogoText: LogoText,
}

// Got from react native's argonTheme.COLORS
export const GlobalStylesColor = {
  mbppBlue: '#af1e23',
  mbppThemeBlue: '#af1e23',
  mbppThemeYellow: '#fdba2b',
  mbppText: '#525f7f',
  mbppLabel: '#fbb61a',
  mbppDisable: '#142237',
  mbppInputEnable: '#525F7F',
  mbppInputDisable: '#ADB5BD',
  mbppIconColor: '#525f7f',
  mbppIconColorLight: '#6d7893',
  // chsChsat: '#c03e3a',
  // chsIcat: '#d37775',
  // chsTie: '#e6b0af',
  // chsChsat: '#AE0F0A',
  // chsIcat: '#8B0C08',
  // chsTie: '#BE3E3A',
  chsChsat: '#9f120b',
  chsIcat: '#70150b',
  chsTie: '#BE3E3A',
}
