import React, { useEffect } from "react";
import { useFormik } from "formik";
import * as yup from "yup";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import { ChsStyles } from "constants/globalStyles";

// reactstrap components
import { Card, CardHeader, CardBody, CardFooter, Container, FormGroup, Form, Button, Row } from "reactstrap";

import { TextField, Grid } from "@material-ui/core";

import { fetchFrmTopics, createFrmTopic, updateFrmTopic } from "actions/forum";

// core components
import PageHeader from "components/Headers/PageHeader.js";
import { LOGINSTATE } from "../../../config/config.json";

const validationSchemaTopic = yup.object({
  topic: yup.string("Enter contact title").min(3, "Description should be of minimum 3 characters length").max(2000, "Contact title should be of maximum 2000 characters length").required("Contact title is required"),
  description: yup.string("Enter contact description").min(5, "Description should be of minimum 5 characters length").max(2000, "Description should be of maximum 2000 characters length"),
  status: yup.boolean("Enter contact Status"),
});

const ForumTopicForm = (props) => {
  // dispatch and history
  const dispatch = useDispatch();
  const history = useHistory();
  // get state topic details
  const selectFrmTopic = (state) => {
    const itemArray = state.forum.frmAllTopics?.filter((item) => {
      return item._id === props.location.state?.frmTopicId;
    });
    if (itemArray && itemArray[0]) {
      return itemArray[0];
    }
  };
  const topicdetails = useSelector(selectFrmTopic);

  useEffect(() => {
    // fetching forum topics
    dispatch(fetchFrmTopics());
  }, []);

  useEffect(() => {
    if (topicdetails && topicdetails._id) {
      formik.values.forumTopicId = topicdetails._id;
      formik.values.topic = topicdetails.topic;
      formik.values.description = topicdetails.description;
      formik.values.status = topicdetails.status;
    }
    if(LOGINSTATE) console.log(formik.values);
  }, [topicdetails]);

  const formik = useFormik({
    initialValues: {
      forumTopicId: "",
      topic: "",
      description: "",
      status: true,
      redirect: "/registered/forum",
    },
    validationSchema: validationSchemaTopic,
    onSubmit: (values, onSubmitProps) => {
      if (values.forumTopicId) {
        dispatch(updateFrmTopic(values, history));
      } else {
        dispatch(createFrmTopic(values, history));
      }
      if (LOGINSTATE) console.log(values);
      onSubmitProps.setSubmitting(false);
      onSubmitProps.resetForm();
    },
  });

  return (
    <>
      <PageHeader />
      {/* Page content */}
      <Container className="mt--7" fluid>
        {/* Table School */}
        <Row className="mt-5">
          <div className="col">
            <Card className="shadow">
              <CardHeader className="border-0">
                <h3 className="mb-0">Topic Details</h3>
              </CardHeader>
              <Form role="form" onSubmit={formik.handleSubmit} onReset={formik.handleReset}>
                <CardBody>
                  <FormGroup className="mb-3">
                    <Grid container spacing={1} alignItems="center" style={ChsStyles.Grid}>
                      <Grid item xs={1} sm={1} className="px-3 text-right">
                        <i className="fas fa-heading" />
                      </Grid>
                      <Grid item xs={10} sm={10}>
                        <TextField
                          fullWidth
                          id="topic"
                          name="topic"
                          label="Topic"
                          variant="outlined"
                          value={formik.values.topic}
                          onChange={formik.handleChange}
                          multiline
                          rows={3}
                          error={formik.touched.topic && Boolean(formik.errors.topic)}
                          helperText={formik.touched.topic && formik.errors.topic}
                        />
                      </Grid>
                    </Grid>
                    <Grid container spacing={1} alignItems="center" style={ChsStyles.Grid}>
                      <Grid item xs={1} sm={1} className="px-3 text-right">
                        <i className="ni ni-align-left-2" />
                      </Grid>
                      <Grid item xs={10} sm={10}>
                        <TextField
                          fullWidth
                          id="description"
                          name="description"
                          label="Description"
                          variant="outlined"
                          value={formik.values.description}
                          onChange={formik.handleChange}
                          multiline
                          rows={4}
                          error={formik.touched.description && Boolean(formik.errors.description)}
                          helperText={formik.touched.description && formik.errors.description}
                        />
                      </Grid>
                    </Grid>
                  </FormGroup>
                </CardBody>
                <CardFooter className="py-2 d-flex justify-content-between">
                  <div>
                    <Button
                      style={ChsStyles.GeneralButton}
                      className="my-2"
                      variant="contained"
                      color="success"
                      onClick={() => {
                        window.history.back();
                      }}
                    >
                      BACK
                    </Button>
                  </div>
                  <div>
                    <Button className="my-2" variant="contained" color="primary" type="reset" style={ChsStyles.GeneralButton}>
                      RESET
                    </Button>
                    <Button className="my-2" variant="contained" color="primary" type="submit" style={ChsStyles.GeneralButton}>
                      {formik.values.frmTopicId ? "Update" : "Save"}
                    </Button>
                  </div>
                </CardFooter>
              </Form>
            </Card>
          </div>
        </Row>
      </Container>
    </>
  );
};

export default ForumTopicForm;
