import { fetchProfileOrg } from 'actions/user';
import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { Container } from "reactstrap"; // reactstrap components

const UserHeader = () => {
  // dispatch and history
  const dispatch = useDispatch();
  const history = useHistory();
  const userProfile = useSelector((state) => state.user.userProfile.profile);

  useEffect(() => {
    // fetching user's profile
    dispatch(fetchProfileOrg());
  }, [])

  return (
    <>
    <div className="header pb-8 pt-3 pt-md-6"
        style={{
          backgroundColor: "#af1e23",
          backgroundImage: "url(" + require("../../assets/img/theme/dashboardBg.png").default + ")",
          backgroundSize: "cover",
          backgroundPosition: "center top",
        }}
    >
        {/* <div
          className="header pb-8 pt-3 pt-md-8"
          style={{
            backgroundImage: "url(" + require("../../assets/img/theme/dashboard.png").default + ")",
            backgroundSize: "contain",
            backgroundRepeat: "no-repeat",
            backgroundPosition: "center top",
          }}
        > */}
        {/* Mask */}
        {/* <span className="mask bg-gradient-default opacity-6" /> */}

        <Container fluid style={{ minHeight: 100 }}>
        </Container>

      {/* </div> */}
    </div>
    </>
  );
};

export default UserHeader;
