import Index from "views/Index";
import IndexUser from "views/IndexUser";

import Forum from "views/pages/forum/Forum";
// import FAQ from "views/pages/forum/Faq";
import FAQRegistered from "views/pages/forum/FaqRegistered";
import FAQPublic from "views/pages/forum/FaqPublic";
import ForumSubTopic from "views/pages/forum/ForumSubTopic";
import ForumQuestion from "views/pages/forum/ForumQuestion";
import ForumTopicForm from "views/pages/forum/ForumTopicForm";
import ForumSubTopicForm from "views/pages/forum/ForumSubTopicForm";
import ForumQuestionFrom from "views/pages/forum/ForumQuestionFrom";
import ForumAnswerForm from "views/pages/forum/ForumAnswerForm";

import User from "views/pages/user/User";
import Login from "views/pages/user/Login";
import Profile from "views/pages/user/Profile";
import ProfileByAdmin from "views/pages/user/ProfileByAdmin";
import Register from "views/pages/user/Register";
import RegisterAdmin from "views/pages/user/RegisterAdmin";
import Landing from "views/pages/user/Landing";
// import Terms from "views/pages/user/Terms";
import PrivacyPolicy from "views/pages/user/PrivacyPolicy";
import PrivacyPolicyPublic from "views/pages/user/PublicUserPrivacyPolicy";
import ResetPassword from "views/pages/user/ResetPassword";

import Survey from "views/pages/survey/Survey";
import SurveyCreateTemplate from "views/pages/survey/SurveyCreateTemplate";
// import SurveyEditTemplate from "views/pages/survey/SurveyEditTemplate";
import SurveyCreateTemplateQuestion from "views/pages/survey/SurveyCreateTemplateQuestion";
import SurveyLocalization from "views/pages/survey/SurveyLocalization";
import SurveyLocalizationLanguage from "views/pages/survey/SurveyLocalizationLanguage";
import SurveyLocalizationLanguageQuestion from "views/pages/survey/SurveyLocalizationLanguageQuestion";

import AnswerInstitute from "views/pages/survey/AnswerInstitute";
import AnswerCreate from "views/pages/survey/AnswerCreate";
import AnswerContinue from "views/pages/survey/AnswerContinue";
import AnswerComplete from "views/pages/survey/AnswerComplete";
import AnswerHistory from "views/pages/survey/AnswerHistory";
import GeneratePdf from "views/pages/survey/AnswerPrintAssessment";
// import AnswerPrint from "views/pages/survey/AnswerPrint";
import PrintAnswersAndGraph from "views/pages/survey/GraphAnswerPrint";
import GraphPrint from "views/pages/survey/GraphPrint";
import Graph from "views/pages/survey/Graph";
import PrintFull from "views/pages/survey/AnswerPrintTemplateByHtmlExtend";

import Recommendation from "views/pages/recommendation/Recommendation";
import RecommendationLocalization from "views/pages/recommendation/RecommendationLocalization";
import RecommendationDetails from "views/pages/recommendation/RecommendationDetails";
import RecommendationDetailsCreate from "views/pages/recommendation/RecommendationDetailsCreate";
import RecommendationDetailsEdit from "views/pages/recommendation/RecommendationDetailsEdit";

import Organization from "views/pages/organization/Organization";
import OrganizationDetails from "views/pages/organization/OrganizationDetails";

import Resource from "views/pages/resources/Resource";
import ResourceDetails from "views/pages/resources/ResourceDetails";
import ResourceDetailsi18 from "views/pages/resources/ResourceDetailsi18";

import ReportAllAnswers from "views/pages/report/ReportAllAnswers";
import ReportAllAnswersByUser from "views/pages/report/ReportAllAnswersByUser";
// import ReportAllAnswersByCountry from "views/pages/report/ReportAllAnswersByCountry";
// import ReportAllAnswersBySegment from "views/pages/report/ReportAllAnswersBySegment";
import ReportAllAnswersBySegmentByOrganization from "views/pages/report/ReportAllAnswersBySegmentByOrganization";
import ReportAllOrganizationsByCountry from "views/pages/report/ReportAllOrganizationsByCountry";
import ReportAllAttemptedOrganizationsByCountry from "views/pages/report/ReportAllAttemptedOrganizationsByCountry";
import AnswerCompleteInReport from "views/pages/report/ReportSurveyCompleted";
import ReportAllAnswersCustom from "views/pages/report/ReportAllAnswersCustom";

import Regions from "views/pages/masterData/Regions";
import Languages from "views/pages/masterData/Languages";
// import LandingPage from "views/pages/masterData/LandingPage";
import SystemLocalization from "views/pages/masterData/SystemLocalization";

// import Tables from "views/pages/Tables";
// import Icons from "views/pages/Icons";

let currentUser = localStorage.getItem("userDetails");
let jUser = JSON.parse(currentUser);
let uRole = currentUser && jUser && jUser.user && jUser.user.urole ? jUser.user.urole : "GENERAL";

export const routesAdmin = [
  {
    path: "/index",
    name: "Dashboard",
    icon: "fas fa-chalkboard-teacher text-gray", // Showing
    component: Index,
    layout: "/registered",
    location: "admin",
  },
  {
    path: "/faq",
    name: "FAQ",
    icon: "fas fa-comment-dots text-gray", // Showing
    layout: "/registered",
    location: "admin",
    innerlinks: [
      {
        path: "/faq_registered",
        name: "Frequently Asked Questions (FAQs)",
        icon: "fas fa-user",
        component: FAQRegistered,
        layout: "/registered",
        location: "admin",
      },
      {
        path: "/faq_management",
        name: "FAQ Management",
        icon: "fas fa-comments text-gray", // Showing
        component: Forum,
        layout: "/registered",
        location: "admin",
      },
    ],
  },
  // {
  //   path: "/faq_registered",
  //   name: "Frequently Asked Questions (FAQs)",
  //   icon: "fas fa-user",
  //   component: FAQRegistered,
  //   layout: "/registered",
  //   location: "admin",
  // },
  {
    path: "/faq_public",
    name: "FAQ Public",
    icon: "fas fa-comment-dots text-gray",
    component: FAQPublic,
    layout: "/public",
    location: "land",
  },
  {
    path: "/privacy_policy_public",
    name: "Privacy Policy",
    icon: "fas fa-file-signature text-gray",
    component: PrivacyPolicyPublic,
    layout: "/public",
    location: "land",
  },
  // {
  //   path: "/survey_answer_institute",
  //   name: "Take A Assessment",
  //   icon: "fas fa-file-signature text-gray", // Showing
  //   component: AnswerInstitute,
  //   layout: "/registered",
  //   location: "admin",
  // },
  // {
  //   path: "/survey_answers",
  //   name: "Assessment History",
  //   icon: "fas fa-archive text-gray", // Showing
  //   component: Answer,
  //   layout: "/registered",
  //   location: "admin",
  // },
  /// -------------------------------------
  /////////////////////////////////////////
  // {
  //   path: "/faq",
  //   name: "FAQ",
  //   icon: "fas fa-comment-dots text-gray", // Showing
  //   component: FAQ,
  //   layout: "/registered",
  //   location: "admin",
  // },
  // {
  //   path: "/faq_management",
  //   name: "FAQ Management",
  //   icon: "fas fa-comments text-gray", // Showing
  //   component: Forum,
  //   layout: "/registered",
  //   location: "admin",
  // },
  {
    path: "/faq_questions",
    name: "FAQ Questions",
    icon: "fas fa-comments text-blue",
    component: ForumSubTopic,
    layout: "/registered",
    location: "forum",
  },
  {
    path: "/faq_answers",
    name: "FAQ Answers",
    icon: "fas fa-comments text-blue",
    component: ForumQuestion,
    layout: "/registered",
    location: "forum",
  },
  {
    path: "/faq_topic_form",
    name: "FAQ Topic Details",
    icon: "fas fa-comments text-blue",
    component: ForumTopicForm,
    layout: "/registered",
    location: "forum",
  },
  {
    path: "/faq_sub_topic_form",
    name: "FAQ Topic Details",
    icon: "fas fa-comments text-blue",
    component: ForumSubTopicForm,
    layout: "/registered",
    location: "forum",
  },
  {
    path: "/faq_question_form",
    name: "FAQ Question Details",
    icon: "fas fa-comments text-blue",
    component: ForumQuestionFrom,
    layout: "/registered",
    location: "forum",
  },
  {
    path: "/faq_answer_form",
    name: "FAQ Answer Details",
    icon: "fas fa-comments text-blue",
    component: ForumAnswerForm,
    layout: "/registered",
    location: "forum",
  },
  /////////////////////////////////////////
  {
    path: "/organization",
    name: "Organizations",
    icon: "fas fa-building text-gray", // Showing
    component: Organization,
    layout: "/registered",
    location: "admin",
  },
  {
    path: "/organizational_details",
    name: "Organizational Details",
    icon: "fas fa-comments text-blue",
    component: OrganizationDetails,
    layout: "/registered",
    location: "organization",
  },
  /////////////////////////////////////////
  {
    path: "/assessment",
    name: "Assessment Management",
    icon: "fas fa-layer-group text-gray", // Showing
    component: Survey,
    layout: "/registered",
    location: "admin",
  },
  {
    path: "/assessment_create",
    name: "Assessment create Template",
    icon: "fas fa-user-alt text-gray",
    component: SurveyCreateTemplate,
    layout: "/registered",
    location: "survey",
  },
  // {
  //   path: "/survey_edit",
  //   name: "Assessment Edit Template",
  //   icon: "fas fa-user-alt text-gray",
  //   component: SurveyEditTemplate,
  //   layout: "/registered",
  //   location: "survey",
  // },
  {
    path: "/assessment_localization",
    name: "Assessment Localization",
    icon: "fas fa-user-alt text-gray",
    component: SurveyLocalization,
    layout: "/registered",
    location: "survey",
  },
  {
    path: "/assessment_localization_language",
    name: "Assessment Localization Language",
    icon: "fas fa-user-alt text-gray",
    component: SurveyLocalizationLanguage,
    layout: "/registered",
    location: "survey",
  },
  {
    path: "/assessment_localization_language_question",
    name: "Assessment Localization Language",
    icon: "fas fa-user-alt text-gray",
    component: SurveyLocalizationLanguageQuestion,
    layout: "/registered",
    location: "survey",
  },
  {
    path: "/assessment_segment_question",
    name: "Assessment Segment Questions",
    icon: "fas fa-user-alt text-gray",
    component: SurveyCreateTemplateQuestion,
    layout: "/registered",
    location: "survey",
  },
  /////////////////////////////////////////
  // {
  //   path: "/survey_answer_institute",
  //   name: "Take A Assessment",
  //   icon: "fas fa-file-signature text-gray", // Showing
  //   component: AnswerInstitute,
  //   layout: "/registered",
  //   location: "admin",
  // },
  {
    path: "/assessment_answer_create",
    name: "Take A Assessment",
    icon: "fas fa-file-signature text-gray",
    component: AnswerCreate,
    layout: "/registered",
    location: "survey",
  },
  // {
  //   path: "/survey_answers",
  //   name: "Assessment History",
  //   icon: "fas fa-archive text-gray", // Showing
  //   component: Answer,
  //   layout: "/registered",
  //   location: "admin",
  // },
  {
    path: "/assessment_answer_continue",
    name: "Answer Continue",
    icon: "fas fa-file-signature text-gray",
    component: AnswerContinue,
    layout: "/registered",
    location: "survey",
  },
  {
    path: "/assessment_answer_complete",
    name: "Answer Completed",
    icon: "fas fa-file-signature text-gray",
    component: AnswerComplete,
    layout: "/registered",
    location: "survey",
  },
  // {
  //   path: "/assessment_recommendation_print",
  //   name: "Answer Print",
  //   icon: "fas fa-file-signature text-gray",
  //   component: AnswerPrint,
  //   layout: "/registered",
  //   location: "survey",
  // },
  {
    path: "/assessment_print_PDF",
    name: "Print",
    icon: "fas fa-file-signature text-gray",
    component: PrintAnswersAndGraph,
    layout: "/registered",
    location: "survey",
  },
  // {
  //   path: "/assessment_print_pdf",
  //   name: "Print",
  //   icon: "fas fa-file-signature text-gray",
  //   component: PrintAnswersAndGraph,
  //   layout: "/print_content",
  //   location: "survey",
  // },
  {
    path: "/assessment_answer_graph",
    name: "Graph Print",
    icon: "fas fa-file-signature text-gray",
    component: GraphPrint,
    layout: "/registered",
    location: "survey",
  },
  {
    path: "/assessment_graph",
    name: "Graph",
    icon: "fas fa-file-signature text-gray",
    component: Graph,
    layout: "/registered",
    location: "survey",
  },
  {
    path: "/assessment_full_graph",
    name: "Graph",
    icon: "fas fa-file-signature text-gray",
    component: PrintFull,
    layout: "/registered",
    location: "survey",
  },
  /////////////////////////////////////////
  {
    path: "/assessment_recommendations",
    name: "Recommendations",
    icon: "fas fa-user-tie text-gray", // Showing
    component: Recommendation,
    layout: "/registered",
    location: "admin",
  },
  {
    path: "/assessment_recommendation_localization",
    name: "Recommendations Localization",
    icon: "fas fa-user-tie text-gray",
    component: RecommendationLocalization,
    layout: "/registered",
    location: "recommendation",
  },
  {
    path: "/assessment_recommendation_details",
    name: "Recommendation Details",
    icon: "fas fa-user-tie text-gray",
    component: RecommendationDetails,
    layout: "/registered",
    location: "recommendation",
  },
  {
    path: "/assessment_recommendation_details_create",
    name: "Recommendation Details Create",
    icon: "fas fa-user-tie text-gray",
    component: RecommendationDetailsCreate,
    layout: "/registered",
    location: "recommendation",
  },
  {
    path: "/assessment_recommendation_details_edit",
    name: "Recommendation Details Edit",
    icon: "fas fa-user-tie text-gray",
    component: RecommendationDetailsEdit,
    layout: "/registered",
    location: "recommendation",
  },
  /////////////////////////////////////////
  {
    path: "/user",
    name: "User Management",
    icon: "fas fa-users text-gray", // Showing
    component: User,
    layout: "/registered",
    location: "admin",
  },
  {
    path: "/user_profile_manage",
    name: "Manage User Profile",
    icon: "fas fa-user-alt text-gray",
    component: ProfileByAdmin,
    layout: "/registered",
    location: "user",
  },
  {
    path: "/user_profile",
    name: "User Profile",
    icon: "fas fa-user-alt text-gray",
    component: Profile,
    layout: "/registered",
    location: "user",
  },
  /////////////////////////////////////////
  {
    name: "Reports",
    icon: "fas fa-chart-line text-gray",
    location: "admin",
    innerlinks: [
      {
        path: "/report_answers_country",
        name: "Attempts",
        icon: "fas fa-chart-line",
        component: ReportAllAnswers,
        layout: "/registered",
        location: "admin",
      },
      {
        path: "/report_organization_country",
        name: "Organizations",
        icon: "fas fa-chart-line",
        component: ReportAllOrganizationsByCountry,
        layout: "/registered",
        location: "admin",
      },
      {
        path: "/report_organization_attempts_country",
        name: "Participation",
        icon: "fas fa-chart-line",
        component: ReportAllAttemptedOrganizationsByCountry,
        layout: "/registered",
        location: "admin",
      },
      {
        path: "/report_answers_user",
        name: "Answers",
        icon: "fas fa-chart-line", // Showing
        component: ReportAllAnswersByUser,
        layout: "/registered",
        location: "admin",
      },
      // {
      //   path: "/report_answers_organization",
      //   name: "For Organizations",
      //   icon: "fas fa-chart-line",
      //   component: ReportAllAnswersByCountry,
      //   layout: "/registered",
      //   location: "admin",
      // },
      // {
      //   path: "/report_answers_segment",
      //   name: "With Segments",
      //   icon: "fas fa-chart-line",
      //   component: ReportAllAnswersBySegment,
      //   layout: "/registered",
      //   location: "admin",
      // },
      {
        path: "/report_answers_segment_organization",
        name: "Segments",
        icon: "fas fa-chart-line",
        component: ReportAllAnswersBySegmentByOrganization,
        layout: "/registered",
        location: "admin",
      },
      {
        path: "/report_answers_custom",
        name: "Custom",
        icon: "fas fa-chart-line",
        component: ReportAllAnswersCustom,
        layout: "/registered",
        location: "admin",
      },
    ],
  },
  {
    path: "/assessment_complete",
    name: "Organization answer",
    icon: "fas fa-file-signature text-gray",
    component: AnswerCompleteInReport,
    layout: "/registered",
    location: "report",
  },
  /////////////////////////////////////////////
  {
    path: "/resource",
    name: "Local Resources",
    icon: "fas fa-book text-gray", // Showing
    component: Resource,
    layout: "/registered",
    location: "admin",
  },
  {
    path: "/resource_details",
    name: "Resource Details",
    icon: "fas fa-book text-gray", // Showing
    component: ResourceDetails,
    layout: "/registered",
    location: "resource",
  },
  {
    path: "/resource_details_i18",
    name: "Resource Details Internationalized",
    icon: "fas fa-book text-gray", // Showing
    component: ResourceDetailsi18,
    layout: "/registered",
    location: "resource",
  },
  {
    name: "Master Data",
    icon: "fas fa-cogs text-gray",
    location: "admin",
    innerlinks: [
      {
        path: "/master_regions",
        name: "Regions",
        icon: "fas fa-map-marker-alt",
        component: Regions,
        layout: "/registered",
        location: "admin",
      },
      {
        path: "/master_languages",
        name: "Languages",
        icon: "fas fa-heading",
        component: Languages,
        layout: "/registered",
        location: "admin",
      },
      {
        path: "/master_localization",
        name: "Localization",
        icon: "fas fa-language",
        component: SystemLocalization,
        layout: "/registered",
        location: "admin",
      },
      // {
      //   path: "/master_languages",
      //   name: "Landing Page",
      //   icon: "fas fa-globe-asia",
      //   component: Languages,
      //   layout: "/registered",
      //   location: "admin",
      // },
      // ,
      // {
      //   path: "/master_landing",
      //   name: "Landing Page",
      //   icon: "fas fa-globe-asia",
      //   component: LandingPage,
      //   layout: "/registered",
      //   location: "admin",
      // }
    ],
  },
  /////////////////////////////////////////
  {
    path: "/register_admin",
    name: "Register Admin",
    icon: "fas fa-user-tie text-gray",
    component: RegisterAdmin,
    layout: "/registered",
    location: "user",
  },
  {
    path: "/register", // this pathname is used in navigator to hide language dropdown
    name: "Register",
    icon: "ni ni-key-25 text-blue",
    component: Register,
    layout: "/public",
    location: "user",
  },
  {
    path: "/login",
    name: "Login",
    icon: "ni ni-key-25 text-blue",
    component: Login,
    layout: "/public",
    location: "auth",
  },
  {
    path: "/reset", // this pathname is used in navigator to hide language dropdown
    name: "Reset",
    icon: "ni ni-key-25 text-blue",
    component: ResetPassword,
    layout: "/public",
    location: "auth",
  },
  /////////////////////////////////////////
  {
    path: "/",
    name: "Landing",
    icon: "ni ni-key-25 text-blue",
    component: Landing,
    layout: "/public",
    location: "land",
  },
  // {
  //   path: "/terms",
  //   name: "Terms",
  //   icon: "fas fa-file-signature text-gray",
  //   component: PrivacyPolicy,
  //   layout: "/registered",
  //   location: "report",
  // },
  {
    path: "/privacy_policy",
    name: "Privacy Policy",
    icon: "fas fa-lock text-gray",
    component: PrivacyPolicy,
    layout: "/registered",
    location: "admin",
  },
];

export const routesUser = [
  {
    path: "/index",
    name: "Dashboard",
    icon: "fas fa-chalkboard-teacher text-gray",
    component: IndexUser,
    layout: "/registered",
    location: "admin",
  },
  /////////////////////////////////////////
  {
    path: "/faq",
    name: "Frequently Asked Questions (FAQs)",
    icon: "fas fa-comment-dots text-gray",
    component: FAQRegistered,
    layout: "/registered",
    location: "admin",
  },
  // {
  //   path: "/faq_public",
  //   name: "FAQ Public",
  //   icon: "fas fa-comment-dots text-gray", // Showing
  //   component: FAQPublic,
  //   layout: "/registered",
  //   location: "landing",
  // },
  /////////////////////////////////////////
  {
    path: "/assessment_answer_institute",
    name: "Take An Assessment",
    icon: "fas fa-file-signature text-gray", // Showing
    component: AnswerInstitute,
    layout: "/registered",
    location: "admin",
  },
  {
    path: "/assessment_answer_create",
    name: "Take An Assessment",
    icon: "fas fa-file-signature text-gray",
    component: AnswerCreate,
    layout: "/registered",
    location: "survey",
  },
  {
    path: "/assessment_answers",
    name: "Assessment History",
    icon: "fas fa-archive text-gray",
    component: AnswerHistory,
    layout: "/registered",
    location: "admin",
  },
  {
    path: "/assessment_answer_continue",
    name: "Answer Continue",
    icon: "fas fa-file-signature text-gray",
    component: AnswerContinue,
    layout: "/registered",
    location: "survey",
  },
  {
    path: "/assessment_answer_complete",
    name: "Answer Completed",
    icon: "fas fa-file-signature text-gray",
    component: AnswerComplete,
    layout: "/registered",
    location: "survey",
  },
  {
    path: "/assessment_answer_pdf",
    name: "Answer Completed",
    icon: "fas fa-file-signature text-gray",
    component: GeneratePdf,
    layout: "/registered",
    location: "survey",
  },
  // {
  //   path: "/assessment_recommendation_print",
  //   name: "Answer Print",
  //   icon: "fas fa-file-signature text-gray",
  //   component: AnswerPrint,
  //   layout: "/registered",
  //   location: "survey",
  // },
  {
    path: "/assessment_print_PDF",
    name: "Print",
    icon: "fas fa-file-signature text-gray",
    component: PrintAnswersAndGraph,
    layout: "/registered",
    location: "survey",
  },
  {
    path: "/assessment_answer_graph",
    name: "Graph Print",
    icon: "fas fa-file-signature text-gray",
    component: GraphPrint,
    layout: "/registered",
    location: "survey",
  },
  {
    path: "/assessment_graph",
    name: "Graph",
    icon: "fas fa-file-signature text-gray",
    component: Graph,
    layout: "/registered",
    location: "survey",
  },
  {
    path: "/assessment_full_graph",
    name: "Full Graph",
    icon: "fas fa-file-signature text-gray",
    component: PrintFull,
    layout: "/registered",
    location: "survey",
  },
  /////////////////////////////////////////
  // {
  //   path: "/survey_recommendations",
  //   name: "Recommendations",
  //   icon: "fas fa-user-tie text-gray",
  //   component: Recommendation,
  //   layout: "/registered",
  //   location: "admin",
  // },
  /////////////////////////////////////////
  // {
  //   path: "/resource",
  //   name: "Resources",
  //   icon: "fas fa-book text-gray", // Showing
  //   component: Resource,
  //   layout: "/registered",
  //   location: "admin",
  // },
  {
    path: "/login",
    name: "Login",
    icon: "ni ni-key-25 text-blue",
    component: Login,
    layout: "/public",
    location: "auth",
  },
  {
    path: "/register", // this pathname is used in navigator to hide language dropdown
    name: "Register",
    icon: "ni ni-key-25 text-blue",
    component: Register,
    layout: "/public",
    location: "user",
  },
  {
    path: "/user_profile",
    name: "User Profile",
    icon: "fas fa-user-alt text-gray",
    component: Profile,
    layout: "/registered",
    location: "user",
  },
  {
    path: "/reset", // this pathname is used in navigator to hide language dropdown
    name: "Reset",
    icon: "ni ni-key-25 text-blue",
    component: ResetPassword,
    layout: "/public",
    location: "auth",
  },
  {
    path: "/",
    name: "Landing",
    icon: "ni ni-key-25 text-blue",
    component: Landing,
    layout: "/public",
    location: "user",
  },
  // {
  //   path: "/terms",
  //   name: "Terms",
  //   icon: "fas fa-file-signature text-gray",
  //   component: PrivacyPolicy,
  //   layout: "/registered",
  //   location: "report",
  // },
  {
    path: "/privacy_policy",
    name: "Privacy Policy",
    icon: "fas fa-lock text-gray",
    component: PrivacyPolicy,
    layout: "/registered",
    location: "admin",
  },
];

export const routes = uRole === "ADMIN" ? routesAdmin : routesUser;

// var routes = { "ADMIN": routesAdmin, "GENERAL": routesUser };
// export default routes;
