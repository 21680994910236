import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useLocation, useHistory } from "react-router-dom";
import { fetchFrmTopics } from "actions/forum";
import { fetchMasterDataLanguageAll } from "actions/masterData";

// reactstrap components
import { Card, CardHeader, Media, Table, Container, Row, Button } from "reactstrap";
// core components
import PageHeader from "components/Headers/PageHeader.js";
import { LOGINSTATE } from "../../../config/config.json";
import { GlobalStylesColor } from "constants/globalStyles";
import { Tooltip } from "@material-ui/core";
import NavigateNextIcon from "@material-ui/icons/NavigateNext";
import PublicOutlinedIcon from "@material-ui/icons/PublicOutlined";
import VpnLockOutlinedIcon from "@material-ui/icons/VpnLockOutlined";
import { updateFrmSubTopic } from "actions/forum";
import Lodash from "lodash";
import { confirmAlert } from "react-confirm-alert";

const ForumTableSubTopicRow = ({ topicId, subTopicId, subTopic, coverpic, description, status, subTopicClick, subTopicEdit, languageProp, statusClick, language }) => (
  <tr id={subTopicId}>
    <th scope="row" style={{ whiteSpace: "break-spaces" }}>
      <Media className="align-items-center">
        <a
          href="#"
          onClick={(e) => {
            subTopicClick(e, topicId, subTopicId);
          }}
        >
          {coverpic ? (
            <div className="avatar rounded-circle mr-3">
              <img alt={subTopic} src={coverpic} />
            </div>
          ) : null}
          <Media>
            <span className="mb-0 text-sm">{Lodash.find(language, { code: subTopic })?.name}</span>
          </Media>
        </a>
      </Media>
    </th>
    <td></td>
    <td style={{ whiteSpace: "break-spaces" }}>{description}</td>
    <td className="text-right">
      <div className="row">
        <Tooltip title="Edit" arrow>
          <div
            className="navbar-toggler"
            style={{ cursor: "pointer" }}
            onClick={(e) => {
              subTopicEdit(e, topicId, subTopicId);
            }}
          >
            <i className="far fa-edit" style={{ color: GlobalStylesColor.mbppIconColor }} />
          </div>
        </Tooltip>
        <Tooltip title="Change publish status" arrow>
          <div
            className="navbar-toggler"
            style={{ cursor: "pointer" }}
            onClick={(e) => {
              statusClick(e, topicId, subTopicId, status);
            }}
          >
            {status ? <PublicOutlinedIcon style={{ color: GlobalStylesColor.mbppIconColorLight }} /> : <VpnLockOutlinedIcon style={{ color: GlobalStylesColor.mbppIconColorLight }} />}
          </div>
        </Tooltip>
        <Tooltip title="FAQs" arrow>
          <div
            className="navbar-toggler"
            style={{ cursor: "pointer" }}
            onClick={(e) => {
              subTopicClick(e, topicId, subTopicId);
            }}
          >
            <NavigateNextIcon style={{ color: GlobalStylesColor.mbppIconColor }} />
          </div>
        </Tooltip>
      </div>
    </td>
  </tr>
);

const Forum = () => {
  // dispatch and history
  const dispatch = useDispatch();
  const history = useHistory();
  const location = useLocation();
  // get forum topics
  const forumalltopics = useSelector((state) => state.forum.frmAllTopics);
  console.log("forumalltopics", forumalltopics);
  const language = useSelector((state) => state.masterData.languageAll);
  if (LOGINSTATE) console.log("language: ", language);

  useEffect(() => {
    // fetching forum topics
    dispatch(fetchFrmTopics());
    dispatch(fetchMasterDataLanguageAll());
  }, []);

  const frmSubTopicUpdate = useSelector((state) => state.forum.frmSubTopicUpdate);
  useEffect(() => {
    if (frmSubTopicUpdate && !Lodash.isEmpty(frmSubTopicUpdate)) {
      dispatch(fetchFrmTopics());
    }
  }, [frmSubTopicUpdate]);

  // handle Click event of the SubTopic
  const handleSubTopicClick = (e, topicId, subTopicId) => {
    e.preventDefault();
    history.push({ pathname: "/registered/faq_questions", state: { frmTopicId: topicId, frmSubTopicId: subTopicId } });
  };

  // handle click event of add sub topic
  const handleAddSubTopicClick = (e, topicId, subTopic) => {
    e.preventDefault();
    console.log("subTopic", subTopic);

    let finalLang = Lodash.reject(language, function (currentLang) {
      return Lodash.find(subTopic, { subTopic: currentLang.code });
    });
    if (LOGINSTATE) console.log("finalLang", finalLang);
    if (Lodash.isEmpty(finalLang)) {
      confirmAlert({
        title: "Error",
        message: "FAQ titles are exist for all languages.",
        buttons: [
          {
            label: "Ok",
            onClick: () => {},
          },
        ],
        closeOnEscape: false,
        closeOnClickOutside: false,
      });
    } else {
      history.push({ pathname: "/registered/faq_sub_topic_form", state: { frmTopicId: topicId, language: finalLang } });
    }
  };

  // handle click event of edit sub topic
  const handleEditSubTopicClick = (e, topicId, subTopicId) => {
    e.preventDefault();
    history.push({ pathname: "/registered/faq_sub_topic_form", state: { frmTopicId: topicId, frmSubTopicId: subTopicId, language: language } });
  };

  const handleStatusSubTopicClick = (e, topicId, subTopicId, status) => {
    e.preventDefault();
    dispatch(updateFrmSubTopic({ forumTopicId: topicId, forumSubTopicId: subTopicId, publish: !status }, history));
  };

  const handleAddQuestionClick = (e, topicId, subTopicId) => {
    e.preventDefault();
    history.push({ pathname: "/registered/faq_question_form", state: { frmTopicId: topicId, frmSubTopicId: subTopicId } });
  };

  return (
    <>
      <PageHeader />
      {/* Page content */}
      <Container className="mt--7" fluid>
        {forumalltopics && forumalltopics.length > 0 ?
          <div className="col">
            <Card className="shadow">
              <CardHeader className="border-0">
                <div className="row">
                  <div className="mb-xl-0 col-11">
                    <h3 className="mb-0">{forumalltopics[1].topic}</h3>
                    {/* <h6 className="mb-0">{props.description}</h6> */}
                  </div>
                  <div className="mb-xl-0">
                    <Tooltip title="Create new">
                      <div
                        className="navbar-toggler"
                        style={{ cursor: "pointer" }}
                        onClick={(e) => {
                          handleAddSubTopicClick(e, forumalltopics[1]._id, forumalltopics[1].subTopic, language);
                        }}
                      >
                        <i
                          className="fas fa-plus-circle"
                          style={{
                            fontSize: 30,
                            color: GlobalStylesColor.mbppThemeBlue,
                          }}
                        />
                      </div>
                    </Tooltip>
                  </div>
                </div>
              </CardHeader>
              <Table className="align-items-center table-flush" responsive>
                <thead className="thead-light">
                  <tr>
                    <th scope="col">Language</th>
                    <th scope="col"></th>
                    <th scope="col">Description</th>
                    <th scope="col">Actions</th>
                  </tr>
                </thead>
                <tbody>
                  <tr></tr>
                  {Object.values(forumalltopics[1].subTopic).map((subprops, i) => {
                    return (
                      <ForumTableSubTopicRow
                        key={i}
                        topicId={forumalltopics[1]._id}
                        subTopicId={subprops._id}
                        subTopic={subprops.subTopic}
                        description={subprops.description}
                        status={subprops.status}
                        subTopicClick={handleSubTopicClick}
                        subTopicEdit={handleEditSubTopicClick}
                        addQuestionClick={handleAddQuestionClick}
                        statusClick={handleStatusSubTopicClick}
                        language={language}
                      />
                    );
                  })}
                </tbody>
              </Table>
            </Card>
          </div>
          : null}
      </Container>
    </>
  );
};

export default Forum;
