import * as actionType from 'constants/actionTypes';

const authData = JSON.parse(localStorage.getItem('userDetails'))

const initState = { authData: authData || {}, loading: true, errors: null }

const authReducer = (state = initState, action) => {
  switch (action.type) {
    case actionType.AUTH:
      return { ...state, authData: action.payload, loading: false, errors: null };
    case actionType.LOGOUT:
      return { ...state, authData: null, loading: false, errors: null };
    default:
      return state;
  }
};

export default authReducer;
