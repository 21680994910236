import { withStyles, TextField as TextFieldDef } from "@material-ui/core";

const TextField = withStyles({
  root: {
    "& label": {
      transformOrigin: "top right",
      right: 28,
      left: "auto",
    },
    "& legend": {
      textAlign: "right",
    },
    "& input": {
      textAlign: "right",
    },
  }
})(TextFieldDef);

export const RtlTextField = (props) => {
  return (
    !!props.rtol || (props.rtol && props.rtol === true)
      ? <TextField {...props} />
      : <TextFieldDef {...props} />
  )
}
