import { RtlTextField } from "../../../components/Custom/RTLTextField";
import { RtlPagination } from "../../../components/Custom/RTLPagination";

// core components
import PageHeader from "components/Headers/PageHeader.js";
import CommonHeader from "components/Headers/CommonHeader";
import Lodash from "lodash";
import React, { useEffect, useState, useRef } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import { confirmAlert } from "react-confirm-alert";
import { Bar, Line, Radar } from "react-chartjs-2";
import "react-confirm-alert/src/react-confirm-alert.css"; // Import css

// reactstrap components
import { Card, CardBody, CardFooter, CardHeader, CardTitle, Col, Container, Row, Label, Form, Input, Button } from "reactstrap";
import { fetchLocalizationBySurveyTemplate, fetchRecommendationsBySurveyId } from "actions/survey";
import { fetchLocalizationContent, fetchMasterDataLanguage } from "actions/masterData";
import { fetchProfileOrg } from "actions/user";
import Config from "config/config.json";
import { LOGINSTATE } from "../../../config/config.json";
import { Formik } from "formik";
import { ChsStyles, GlobalStylesColor } from "constants/globalStyles";
import { institutionalQuestions, initialValues_assessmentPage, roundValue, RTL_FLAG_RIGHT } from "helper";
import { useTranslation, initReactI18next, Trans } from "react-i18next";

var kI = 0;

const localStyle = { number: { fontWeight: "600", width: 25, marginLeft: 15 }, question: { fontWeight: "600" }, answer: { marginBottom: 20 } };

const AnswerComplete = (props) => {
  // dispatch and history
  const dispatch = useDispatch();
  const history = useHistory();

  const [surveyType, setSurveyType] = useState("");
  const [surveyLanguage, setSurveyLanguage] = useState("");

  const [segmentData, setSegmentData] = useState({});
  const [segmentCount, setSegmentCount] = useState(0); // To get the maximum number of segments
  const [segmentCounter, setSegmentCounter] = useState(0); // Current active segment
  const [navigationCounter, setNavigationCounter] = useState(0); // To support footer navigation

  const [surveyCompletedTemplate, setSurveyCompletedTemplate] = useState({});
  const [segmentDataLocale, setSegmentDataLocale] = useState({});
  const [systemContentLocale, setSystemContentLocale] = useState({});
  const [surveyRecommendations, setSurveyRecommendations] = useState({});

  const [surveyScore, setSurveyScore] = useState("");
  const [showRecomm, setshowRecomm] = useState(true);
  const [showAnswers, setShowAnswers] = useState(false);
  const [refreshToken, setRefreshToken] = useState(0);
  const [refreshCounter, setRefreshCounter] = useState(0);
  const [showPagination, setShowPagination] = useState(true);

  const [rtlAlignment, setRtlAlignment] = useState("");

  // To update fomik values
  const formRef = useRef();
  const language = props?.location?.state?.language;
  if (LOGINSTATE) console.log("language", language);

  // Data at the page loading only

  const user = useSelector((state) => state.user.userProfileOrg);
  const userLanguage = useSelector((state) => state.masterData.languageByCode);
  console.log("userLanguage", userLanguage);
  const { t, i18n } = useTranslation();

  useEffect(() => {
    setSurveyRecommendations({});
    setSegmentDataLocale({});

    const completedSurvey = props?.location?.state?.answer;
    const userId = props?.location?.state?.user;

    if (LOGINSTATE) console.log("completedSurvey: ", completedSurvey);
    if (completedSurvey && !Lodash.isEmpty(completedSurvey)) {
      setSurveyType(completedSurvey.type);
      setSurveyLanguage(language);
      setSurveyCompletedTemplate(completedSurvey);
    }
    dispatch(fetchProfileOrg(userId));

    i18n.changeLanguage(jUser?.user?.language);

    let params = {
      user: {
        code: jUser?.user?.language,
      },
    };
    dispatch(fetchMasterDataLanguage(params.user));
  }, []);

  useEffect(() => {
    userLanguage &&
      userLanguage.language &&
      Object.values(userLanguage.language).map((lang, i) => {
        if (lang && lang.dir === "rtl") {
          setRtlAlignment("right");
        }
      });
  }, [userLanguage]);

  useEffect(() => {
    if (LOGINSTATE) console.log("surveyCompletedTemplate: ", surveyCompletedTemplate);

    // Check whether the data is available, then populate accordingly
    if (surveyCompletedTemplate && surveyCompletedTemplate && !Lodash.isEmpty(surveyCompletedTemplate) && surveyCompletedTemplate.segments && surveyCompletedTemplate.segments[0]) {
      // get locales
      if (LOGINSTATE) console.log("surveyId: ", surveyCompletedTemplate.surveyId);
      dispatch(fetchLocalizationBySurveyTemplate({ surveyId: surveyCompletedTemplate.surveyId }, history));
      dispatch(fetchRecommendationsBySurveyId({ surveyId: surveyCompletedTemplate.surveyId }, history));
      dispatch(fetchLocalizationContent());

      // Set basic data to use
      setSegmentData(surveyCompletedTemplate.segments);
      setSegmentCount(Lodash.isEmpty(surveyCompletedTemplate.segments) ? 0 : surveyCompletedTemplate.segments.length);
      setSegmentCounter(0);
      setSurveyScore(roundValue(surveyCompletedTemplate.surveyScore, true));
      setRefreshToken(refreshToken + 1);

      if (LOGINSTATE) console.log("segments available: ", surveyCompletedTemplate);
    } else {
      if (LOGINSTATE) console.log("no segment data");
    }
  }, [surveyCompletedTemplate]);

  let segScore = [];
  let segName = [];
  useEffect(() => {
    Object.values(segmentData).map((segment, i) => segScore.push(segment.segmentScore));

    Object.values(segmentData).map((segmentName, j) => segName.push(segmentName.segmentCode));
    console.log("segScore", segScore);
  }, [segmentData]);

  useEffect(() => {
    setRefreshCounter(refreshCounter + 1);
  }, [segmentCount, systemContentLocale]);

  // get system content
  const systemContentAll = useSelector((state) => state.masterData.localizationContentAll);
  useEffect(() => {
    if (LOGINSTATE) console.log("systemContent from server: ", systemContentAll);
    const systemContent = Lodash.find(systemContentAll, { languageCode: language });
    if (LOGINSTATE) console.log("systemContent from server: ", systemContent);
    if (systemContent && systemContent !== undefined && systemContent !== null) {
      setSystemContentLocale(systemContent.assessmentPage);
    } else {
      setSystemContentLocale(initialValues_assessmentPage);
    }
  }, [systemContentAll]);

  // get the survey localizations
  const surveyLocalizationAll = useSelector((state) => state.survey.surveyLocalizationAll);
  useEffect(() => {
    if (LOGINSTATE) console.log("survey Locales : ", surveyLocalizationAll);
    const surveyLocaleForTemplate = Lodash.find(surveyLocalizationAll, { languageCode: language });
    if (LOGINSTATE) console.log("survey Locale for template: ", surveyLocaleForTemplate);
    if (!Lodash.isEmpty(surveyLocaleForTemplate)) {
      const segmentsLocale = surveyLocaleForTemplate?.segments ? surveyLocaleForTemplate?.segments : segmentData;
      setSegmentDataLocale(segmentsLocale);
      if (LOGINSTATE) console.log("segmentDataLocale : ", segmentsLocale);
    }
  }, [surveyLocalizationAll]);

  // Get recommendations to show for the survey
  const surveyRecommendationAll = useSelector((state) => state.survey.recommendationAll);
  useEffect(() => {
    let recommendationsLocale = Lodash.find(surveyRecommendationAll, { languageCode: language });

    // if the relevant locale recommendations are not available, take the default recommendations
    if (Lodash.isEmpty(recommendationsLocale)) {
      recommendationsLocale = Lodash.find(surveyRecommendationAll, { languageCode: "en" });
    }

    if (recommendationsLocale) {
      setSurveyRecommendations(recommendationsLocale);
    } else {
      setSurveyRecommendations({});
    }
  }, [surveyRecommendationAll]);

  // Assessment answers
  const AnswerUiModel = ({ item, order, itemLocale, rtlAlignment }) => (
    <>

      <Formik
        initialValues={{
          Score: Lodash.isNumber(item.score) && item.score >= 0 ? item.score : -1,
          Remark: item.remark ? item.remark : "",
        }}
        // validationSchema={{}}
        onSubmit={(values, actions) => {
          let updatedItem = item;
          item["score"] = Number(values.Score);
          item["remark"] = values.Remark;

          updateLocalSurvey(updatedItem, order);
        }}
      >
        {(props) => (
          <Form role="form" onSubmit={props.handleSubmit}>
            {/* Segment answer rows */}
            {rtlAlignment === RTL_FLAG_RIGHT ? (
              <Row style={{ marginBottom: 30, pointerEvents: "none", userSelect: "none" }}>
                <Col>
                  <Row>
                    <Col>
                      <Label check style={{ fontWeight: "600" }}>
                        {itemLocale && itemLocale.question ? itemLocale.question : item.question}
                      </Label>
                      <Label check style={{ fontStyle: "italic" }}>
                        {itemLocale && itemLocale.questionNote ? itemLocale.questionNote : item.questionNote}
                      </Label>
                    </Col>
                    <div style={{ marginRight: 15 }}>
                      <Label check style={{ fontWeight: "600" }}>
                        :{order + 1 + "." + (segmentCounter + 1)}
                      </Label>
                    </div>
                  </Row>

                  <Row>
                    <Col lg="12" style={{ marginTop: 5, marginBottom: 5 }}>
                      <div style={rtlAlignment === RTL_FLAG_RIGHT ? { marginRight: -5 } : { marginLeft: 20 }} onChange={props.handleChange("Score")} onBlur={props.handleSubmit}>
                        <div>
                          {rtlAlignment === RTL_FLAG_RIGHT && (
                            <span>
                              <Label check style={{ fontWeight: "400", marginRight: 5 }}>
                                {systemContentLocale?.app_assessment_answer_opt2}
                              </Label>
                              <Label check style={{ fontWeight: "400", marginRight: 30 }}>
                                {".1"}
                              </Label>
                            </span>
                          )}
                          <Input type="radio" value={1} name="score" defaultChecked={1 === props.values.Score} />
                          {rtlAlignment !== "right" && (
                            <Label check style={{ fontWeight: "400" }}>
                              {" 1. " + systemContentLocale?.app_assessment_answer_opt2}
                            </Label>
                          )}
                        </div>
                        <div>
                          {rtlAlignment === RTL_FLAG_RIGHT && (
                            <span>
                              <Label check style={{ fontWeight: "400", marginRight: 5 }}>
                                {systemContentLocale?.app_assessment_answer_opt3}
                              </Label>
                              <Label check style={{ fontWeight: "400", marginRight: 30 }}>
                                {".2"}
                              </Label>
                            </span>
                          )}
                          <Input type="radio" value={2} name="score" defaultChecked={2 === props.values.Score} />
                          {rtlAlignment !== "right" && (
                            <Label check style={{ fontWeight: "400" }}>
                              {" 2. " + systemContentLocale?.app_assessment_answer_opt3}
                            </Label>
                          )}
                        </div>
                        <div>
                          {rtlAlignment === RTL_FLAG_RIGHT && (
                            <span>
                              <Label check style={{ fontWeight: "400", marginRight: 5 }}>
                                {systemContentLocale?.app_assessment_answer_opt4}
                              </Label>
                              <Label check style={{ fontWeight: "400", marginRight: 30 }}>
                                {".3"}
                              </Label>
                            </span>
                          )}
                          <Input type="radio" value={3} name="score" defaultChecked={3 === props.values.Score} />
                          {rtlAlignment !== "right" && (
                            <Label check style={{ fontWeight: "400" }}>
                              {" 3. " + systemContentLocale?.app_assessment_answer_opt4}
                            </Label>
                          )}
                        </div>
                        <div>
                          {rtlAlignment === RTL_FLAG_RIGHT && (
                            <span>
                              <Label check style={{ fontWeight: "400", marginRight: 5 }}>
                                {systemContentLocale?.app_assessment_answer_opt5}
                              </Label>
                              <Label check style={{ fontWeight: "400", marginRight: 30 }}>
                                {".4"}
                              </Label>
                            </span>
                          )}
                          <Input type="radio" value={4} name="score" defaultChecked={4 === props.values.Score} />
                          {rtlAlignment !== "right" && (
                            <Label check style={{ fontWeight: "400" }}>
                              {" 4. " + systemContentLocale?.app_assessment_answer_opt5}
                            </Label>
                          )}
                        </div>
                        <div>
                          {rtlAlignment === RTL_FLAG_RIGHT && (
                            <span>
                              <Label check style={{ fontWeight: "400", marginRight: 5 }}>
                                {systemContentLocale?.app_assessment_answer_opt6}
                              </Label>
                              <Label check style={{ fontWeight: "400", marginRight: 30 }}>
                                {".5"}
                              </Label>
                            </span>
                          )}
                          <Input type="radio" value={5} name="score" defaultChecked={5 === props.values.Score} />
                          {rtlAlignment !== "right" && (
                            <Label check style={{ fontWeight: "400" }}>
                              {" 5. " + systemContentLocale?.app_assessment_answer_opt6}
                            </Label>
                          )}
                        </div>
                        <div>
                          {rtlAlignment === RTL_FLAG_RIGHT && (
                            <Label check style={{ fontWeight: "400", marginRight: 30 }}>
                              {"  " + systemContentLocale?.app_assessment_answer_opt1}
                            </Label>
                          )}
                          <Input type="radio" value={0} name="score" defaultChecked={0 === props.values.Score} />
                          {rtlAlignment !== "right" && (
                            <Label check style={{ fontWeight: "400" }}>
                              {systemContentLocale?.app_assessment_answer_opt1 + "  "}
                            </Label>
                          )}
                        </div>
                      </div>
                    </Col>
                  </Row>
                  <Row>
                    <Col lg="12">
                      <RtlTextField
                        fullWidth
                        rtol={rtlAlignment === RTL_FLAG_RIGHT}
                        label={systemContentLocale?.answer_remark}
                        variant="outlined"
                        multiline
                        rows={2}
                        value={props.values.Remark}
                        onChange={props.handleChange("Remark")}
                        onBlur={props.handleSubmit}
                        style={{ marginTop: 10, marginBottom: 10 }}
                      // disabled={true}
                      />
                    </Col>
                  </Row>
                </Col>
              </Row>
            ) : (
              <Row style={{ marginBottom: 30, pointerEvents: "none", userSelect: "none" }}>
                <div style={{ marginLeft: 10 }}>
                  <Label check style={{ fontWeight: "600" }}>
                    {segmentCounter + 1 + "." + (order + 1)}:
                  </Label>
                </div>
                <Col>
                  <Row>
                    <Col lg="12">
                      <Label check style={{ fontWeight: "600" }}>
                        {itemLocale && itemLocale.question ? itemLocale.question : item.question}
                      </Label>
                      <Label check style={{ fontStyle: "italic" }}>
                        {itemLocale && itemLocale.questionNote ? itemLocale.questionNote : item.questionNote}
                      </Label>
                    </Col>
                  </Row>

                  <Row>
                    <Col lg="12">
                      <div style={{ marginLeft: 20, marginTop: 5, marginBottom: 5 }} onChange={props.handleChange("Score")} onBlur={props.handleSubmit}>
                        <Label check style={{ fontWeight: "400" }}>
                          <Input type="radio" value={1} name="score" defaultChecked={1 === props.values.Score} />
                          {" 1. " + systemContentLocale?.app_assessment_answer_opt2}
                        </Label>
                        <br />
                        <Label check style={{ fontWeight: "400" }}>
                          <Input type="radio" value={2} name="score" defaultChecked={2 === props.values.Score} />
                          {" 2. " + systemContentLocale?.app_assessment_answer_opt3}
                        </Label>
                        <br />
                        <Label check style={{ fontWeight: "400" }}>
                          <Input type="radio" value={3} name="score" defaultChecked={3 === props.values.Score} />
                          {" 3. " + systemContentLocale?.app_assessment_answer_opt4}
                        </Label>
                        <br />
                        <Label check style={{ fontWeight: "400" }}>
                          <Input type="radio" value={4} name="score" defaultChecked={4 === props.values.Score} />
                          {" 4. " + systemContentLocale?.app_assessment_answer_opt5}
                        </Label>
                        <br />
                        <Label check style={{ fontWeight: "400" }}>
                          <Input type="radio" value={5} name="score" defaultChecked={5 === props.values.Score} />
                          {" 5. " + systemContentLocale?.app_assessment_answer_opt6}
                        </Label>
                        <br />
                        <Label check style={{ fontWeight: "400" }}>
                          <Input type="radio" value={0} name="score" defaultChecked={0 === props.values.Score} />
                          {"  " + systemContentLocale?.app_assessment_answer_opt1}
                        </Label>
                      </div>
                    </Col>
                  </Row>
                  <Row>
                    <Col lg="12">
                      <RtlTextField
                        fullWidth
                        rtol={rtlAlignment === RTL_FLAG_RIGHT}
                        label={systemContentLocale?.answer_remark}
                        variant="outlined"
                        multiline
                        rows={2}
                        value={props.values.Remark}
                        onChange={props.handleChange("Remark")}
                        onBlur={props.handleSubmit}
                        style={{ marginTop: 10, marginBottom: 10 }}
                      // disabled={true}
                      />
                    </Col>
                  </Row>
                </Col>
              </Row>
            )}
          </Form>
        )}
      </Formik>
    </>
  );

  const optionsGrid = {
    scales: {
      xAxes: [
        {
          ticks: {
            fontColor: "#fff",
          },
          display: true,
          scaleLabel: {
            display: true,
            labelString: "Assessment Completion",
            fontColor: "#fff",
            fontStyle: "bold",
          },
        },
      ],
      yAxes: [
        {
          gridLines: {
            color: "#fff",
            zeroLineColor: "#fff",
          },
          ticks: {
            callback: function (value) {
              if (!(value % 1)) {
                return value;
              }
            },
            fontColor: "#fff",
            beginAtZero: true,
            steps: 1,
            stepValue: 1,
            max: 5.2,
          },
          scaleLabel: {
            display: true,
            labelString: "Total Score",
            fontColor: "#fff",
            fontStyle: "bold",
          },
        },
      ],
    },
  };

  // save the survey's segment details locally
  const updateLocalSurvey = (item, order) => {
    // Locally save the survey
    let segments = segmentData;
    segments[segmentCounter].questions[order] = item;
    setSegmentData(segments);
  };

  const handlePrint1 = (e, answer, windowPrint) => {
    let segScore = [];
    let segName = [];
    let segLongName = [];

    Object.values(answer.segments).map((segment, i) => segScore.push(roundValue(segment.segmentScore)));

    Object.values(answer.segments).map((segmentName, j) => segName.push(segmentName.segmentCode));

    Object.values(answer.segments).map((segmentLongName, j) => segLongName.push(segmentLongName.title));

    // setSegLongName(segLongNameTemp);
    if (LOGINSTATE) console.log("segLongName", segLongName);

    e.preventDefault();
    if (LOGINSTATE) console.log("--------------------- local store saving: ", answer);
    console.log('### print-preview-details: ', {
      segName: segName,
      segScore: segScore,
      segLongName: segLongName,
      answer: answer,
      language: jUser?.user?.language
    })
    history.push({
      pathname: "/print_content/assessment_preview",
      state: {
        segName: segName,
        segScore: segScore,
        segLongName: segLongName,
        answer: answer,
        language: jUser?.user?.language
      }
    });
  };

  const scrollToTop = () => {
    window.scrollTo(0, 0);
  };

  let currentUser = localStorage.getItem("userDetails");
  let jUser = JSON.parse(currentUser);
  let uRole = currentUser && jUser && jUser.user && jUser.user.urole ? jUser.user.urole : "GENERAL";
  if (LOGINSTATE) console.log("uRole", currentUser);

  return (
    <div>
      <CommonHeader hType="takeAssessment" />

      {/* Page content */}
      <Container className="mt--7" fluid id={refreshCounter} style={{ textAlign: rtlAlignment }}>
        {/* Card stats */}
        <Row className="mt-5">
          {/* Top CHSAT block */}
          <Col lg="6" xl="4" style={{ display: Lodash.isEmpty(surveyType) || surveyType !== "CHSAT" ? "none" : "block" }}>
            <Card className="card-stats mb-4 mb-xl-0" onClick={(e) => { }} style={{ cursor: "pointer" }}>
              {rtlAlignment === RTL_FLAG_RIGHT ? (
                <CardBody>
                  <Row>
                    <div className="col">
                      <CardTitle tag="h5" className="text-uppercase text-muted mb-0">
                        {systemContentLocale.chs_short}
                      </CardTitle>
                      <span className="h2 font-weight-bold mb-0">
                        {systemContentLocale.total_score}: {surveyScore}
                      </span>
                      <medium>
                        {showRecomm === true || showAnswers === false ? (
                          ""
                        ) : (
                          <div>
                            {systemContentLocale.segment_score}: {segmentData && roundValue(segmentData[segmentCounter]?.segmentScore, true)}
                          </div>
                        )}
                      </medium>
                    </div>
                    <Col className="col-auto">
                      <div className="icon icon-shape text-white rounded-circle shadow" style={{ backgroundColor: GlobalStylesColor.chsChsat }}>
                        <i className="fas fa-clipboard-check" style={{ fontSize: 32 }} />
                      </div>
                    </Col>
                  </Row>

                  <p className="mt-3 mb-0 text-muted text-sm">
                    <span className="">{systemContentLocale.chsat}</span>
                  </p>
                </CardBody>
              ) : (
                <CardBody>
                  <Row>
                    <div className="col">
                      <CardTitle tag="h5" className="text-uppercase text-muted mb-0">
                        {systemContentLocale.chs_short}
                      </CardTitle>
                      <span className="h2 font-weight-bold mb-0">
                        {systemContentLocale.total_score}: {surveyScore}
                      </span>
                    </div>
                    <Col className="col-auto">
                      <div className="icon icon-shape text-white rounded-circle shadow" style={{ backgroundColor: GlobalStylesColor.chsChsat }}>
                        <i className="fas fa-clipboard-check" style={{ fontSize: 32 }} />
                      </div>
                    </Col>
                  </Row>
                  <Row>
                    <div className="col">
                      <medium>
                        {showRecomm === true || showAnswers === false ? (
                          ""
                        ) : (
                          <div>
                            {systemContentLocale.segment_score}: {segmentData && roundValue(segmentData[segmentCounter]?.segmentScore, true)}
                          </div>
                        )}
                      </medium>
                    </div>
                  </Row>
                  <p className="mt-3 mb-0 text-muted text-sm">
                    <span className="">{systemContentLocale.chsat}</span>
                  </p>
                </CardBody>
              )}
            </Card>
          </Col>
          {/* Top ICAT block */}
          <Col lg="6" xl="4" style={{ display: Lodash.isEmpty(surveyType) || surveyType !== "ICAT" ? "none" : "block" }}>
            <Card className="card-stats mb-4 mb-xl-0" onClick={(e) => { }} style={{ cursor: "pointer" }}>
              {rtlAlignment === RTL_FLAG_RIGHT ? (
                <CardBody>
                  <Row>
                    <div className="col">
                      <CardTitle tag="h5" className="text-uppercase text-muted mb-0">
                        {systemContentLocale.icat_short}
                      </CardTitle>
                      <span className="h2 font-weight-bold mb-0">
                        {systemContentLocale.total_score}: {surveyScore}
                      </span>
                      <medium>
                        {showRecomm === true || showAnswers === false ? (
                          ""
                        ) : (
                          <div>
                            {systemContentLocale.segment_score}: {segmentData && roundValue(segmentData[segmentCounter]?.segmentScore, true)}
                          </div>
                        )}
                      </medium>
                    </div>
                    <Col className="col-auto">
                      <div className="icon icon-shape text-white rounded-circle shadow" style={{ backgroundColor: GlobalStylesColor.chsIcat }}>
                        <i className="fas fa-university" style={{ fontSize: 32 }} />
                      </div>
                    </Col>
                  </Row>

                  <p className="mt-3 mb-0 text-muted text-sm">
                    <span className="">{systemContentLocale.icat} &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; </span>
                  </p>
                </CardBody>
              ) : (
                <CardBody>
                  <Row>
                    <div className="col">
                      <CardTitle tag="h5" className="text-uppercase text-muted mb-0">
                        {systemContentLocale.icat_short}
                      </CardTitle>
                      <span className="h2 font-weight-bold mb-0">
                        {systemContentLocale.total_score}: {surveyScore}
                      </span>
                    </div>
                    <Col className="col-auto">
                      <div className="icon icon-shape text-white rounded-circle shadow" style={{ backgroundColor: GlobalStylesColor.chsIcat }}>
                        <i className="fas fa-university" style={{ fontSize: 32 }} />
                      </div>
                    </Col>
                  </Row>
                  <Row>
                    <div className="col">
                      <medium>
                        {showRecomm === true || showAnswers === false ? (
                          ""
                        ) : (
                          <div>
                            {systemContentLocale.segment_score}: {segmentData && roundValue(segmentData[segmentCounter]?.segmentScore, true)}
                          </div>
                        )}
                      </medium>
                    </div>
                  </Row>
                  <p className="mt-3 mb-0 text-muted text-sm">
                    <span className="">{systemContentLocale.icat} &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; </span>
                  </p>
                </CardBody>
              )}
            </Card>
          </Col>
          {/* Top TIE block */}
          <Col lg="6" xl="4" style={{ display: Lodash.isEmpty(surveyType) || surveyType !== "TIE" ? "none" : "block" }}>
            <Card className="card-stats mb-4 mb-xl-0" onClick={(e) => { }} style={{ cursor: "pointer" }}>
              {rtlAlignment === RTL_FLAG_RIGHT ? (
                <CardBody>
                  <Row>
                    <div className="col">
                      <CardTitle tag="h5" className="text-uppercase text-muted mb-0">
                        {systemContentLocale.tie_short}
                      </CardTitle>
                      <span className="h2 font-weight-bold mb-0">
                        {systemContentLocale.total_score}: {surveyScore}
                      </span>
                      <medium style={{ color: GlobalStylesColor.mbppBlue }}>
                        {showRecomm === true || showAnswers === false ? (
                          ""
                        ) : (
                          <div>
                            {systemContentLocale.segment_score}: {segmentData && roundValue(segmentData[segmentCounter]?.segmentScore, true)}
                          </div>
                        )}
                      </medium>
                    </div>
                    <Col className="col-auto">
                      <div className="icon icon-shape text-white rounded-circle shadow" style={{ backgroundColor: GlobalStylesColor.chsTie }}>
                        <i className="fas fa-hands-helping" style={{ fontSize: 32 }} />
                      </div>
                    </Col>
                  </Row>

                  <p className="mt-3 mb-0 text-muted text-sm">
                    <span className="">{systemContentLocale.tie}</span>
                  </p>
                </CardBody>
              ) : (
                <CardBody>
                  <Row>
                    <div className="col">
                      <CardTitle tag="h5" className="text-uppercase text-muted mb-0">
                        {systemContentLocale.tie_short}
                      </CardTitle>
                      <span className="h2 font-weight-bold mb-0">
                        {systemContentLocale.total_score}: {surveyScore}
                      </span>
                    </div>
                    <Col className="col-auto">
                      <div className="icon icon-shape text-white rounded-circle shadow" style={{ backgroundColor: GlobalStylesColor.chsTie }}>
                        <i className="fas fa-hands-helping" style={{ fontSize: 32 }} />
                      </div>
                    </Col>
                  </Row>
                  <Row>
                    <div className="col">
                      <medium style={{ color: GlobalStylesColor.mbppBlue }}>
                        {showRecomm === true || showAnswers === false ? (
                          ""
                        ) : (
                          <div>
                            {systemContentLocale.segment_score}: {segmentData && roundValue(segmentData[segmentCounter]?.segmentScore, true)}
                          </div>
                        )}
                      </medium>
                    </div>
                  </Row>
                  <p className="mt-3 mb-0 text-muted text-sm">
                    <span className="">{systemContentLocale.tie}</span>
                  </p>
                </CardBody>
              )}
            </Card>
          </Col>
        </Row>

        <br />

        {/* Overall recommendations for the assessment*/}
        <Row style={{ display: showAnswers ? "none" : "block" }}>
          <div className="col">

            {/* institute details */}
            <Card className="shadow" style={{ display: showRecomm ? "none" : "block" }}>
              {/* survey header */}
              <CardHeader className="border-0">
                <div className="row">
                  <div className="mb-xl-0 col-12">
                    <small>{systemContentLocale.chs_app}</small>
                    <h3 className="mb-0">{systemContentLocale.insitutional_details}</h3>
                    <h6 className="mb-0">{systemContentLocale.app_assessment_step_01_message}</h6>
                  </div>
                </div>
              </CardHeader>

              {/* survey content */}
              <CardBody>
                {surveyCompletedTemplate &&
                  !Lodash.isEmpty(surveyCompletedTemplate?.institutionalQuestions) &&
                  surveyCompletedTemplate?.institutionalQuestions.map((iQuestion, i) => {
                    if (LOGINSTATE) console.log("surveyCompletedTemplate?.institutionalQuestions", surveyCompletedTemplate?.institutionalQuestions);
                    return (
                      <Row style={{ marginBottom: 30, pointerEvents: "none", userSelect: "none" }}>

                        {rtlAlignment !== RTL_FLAG_RIGHT ? (
                          <>
                            <Col style={{ textAlign: 'right' }}>
                              <Label check style={localStyle.question}>
                                {t("insitutionalDetailsComplete." + iQuestion.code)}
                              </Label>
                              <br />
                              <Label check style={{ marginLeft: 5, color: GlobalStylesColor.mbppBlue }}>
                                {iQuestion.answer}{" :"}
                              </Label>
                              <Label check style={(localStyle.question, { color: GlobalStylesColor.mbppBlue })}>
                                {t("insitutionalDetailsComplete.answer")}
                              </Label>
                              <br />
                              <Label check style={{ marginLeft: 5, color: GlobalStylesColor.mbppBlue }}>
                                {iQuestion.remark}{" :"}
                              </Label>
                              <Label check style={(localStyle.question, { color: GlobalStylesColor.mbppBlue })}>
                                {t("insitutionalDetailsComplete.remark")}
                              </Label>
                            </Col>

                            <div style={{ marginRight: 15 }}>
                              <Label check style={{ fontWeight: "600" }}>
                                .{i + 1}
                              </Label>
                            </div>
                          </>
                        ) : (
                          <>
                            <div style={{ marginLeft: 10 }}>
                              <Label check style={{ fontWeight: "600" }}>
                                {i + 1}.
                              </Label>
                            </div>
                            <Col>
                              <Label check style={localStyle.question}>
                                {t("insitutionalDetailsComplete." + iQuestion.code)}
                              </Label>
                              <br />
                              <Label check style={(localStyle.question, { color: GlobalStylesColor.mbppBlue })}>
                                {t("insitutionalDetailsComplete.answer")}:{ }
                              </Label>
                              <Label check style={{ marginLeft: 5, color: GlobalStylesColor.mbppBlue }}>
                                {iQuestion.answer}
                              </Label>
                              <br />
                              <Label check style={(localStyle.question, { color: GlobalStylesColor.mbppBlue })}>
                                {t("insitutionalDetailsComplete.remark")}:{" "}
                              </Label>
                              <Label check style={{ marginLeft: 5, color: GlobalStylesColor.mbppBlue }}>
                                {iQuestion.remark}
                              </Label>
                            </Col>
                          </>
                        )}
                      </Row>
                    );
                  })}
              </CardBody>

              {/* card footer */}
              <CardFooter className="py-4">
                <Row>
                  <Col lg="6" className="text-left" style={{ marginBottom: 10 }}>
                    <Button
                      style={ChsStyles.GeneralButton}
                      color="primary"
                      onClick={() => {
                        history.goBack();
                      }}
                    >
                      {systemContentLocale.app_back}
                    </Button>

                    <Button
                      style={ChsStyles.GeneralButton}
                      color="primary"
                      onClick={() => {
                        setSurveyType(surveyCompletedTemplate.type);
                        setShowAnswers(true);
                        scrollToTop();
                      }}
                    >
                      {systemContentLocale.app_next}
                    </Button>
                  </Col>

                  <Col lg="6" className="text-right" style={{ marginBottom: 10 }}>
                    <Button
                      style={ChsStyles.GeneralButton}
                      color="primary"
                      onClick={(e) => {
                        handlePrint1(e, surveyCompletedTemplate);
                      }}
                    >
                      {systemContentLocale.print_preview}
                    </Button>
                  </Col>
                </Row>
              </CardFooter>
            </Card>

            {/* Overall recommendations */}
            <Card className="shadow" style={{ display: showRecomm ? "block" : "none" }}>
              <CardHeader className="border-0">
                <div className="heading-title" style={{ ...ChsStyles.Topic, marginBottom: -80 }}>
                  {systemContentLocale.overall}
                </div>
              </CardHeader>

              <CardBody>
                <hr className="my-4" style={{ paddingTop: -10 }} />

                {surveyRecommendations && surveyRecommendations?.published === true && !Lodash.isEmpty(surveyRecommendations?.overall?.recommendations) ? (
                  surveyRecommendations?.overall?.recommendations.map((recom, i) => {
                    return recom.from <= surveyScore && surveyScore <= recom.to ? (
                      <Row key={i} className="text-justify">
                        <Col lg="12" style={{ ...ChsStyles.Question, color: GlobalStylesColor.mbppBlue, whiteSpace: "break-spaces" }}>
                          {recom.recommendation}
                        </Col>

                        {/* <Col lg="12" style={{ ...ChsStyles.Question, color: GlobalStylesColor.mbppBlue, whiteSpace: "break-spaces", fontStyle: "italic" }}>
                          {recom.note}
                        </Col> */}
                      </Row>
                    ) : null;
                  })
                ) : (
                  <p style={{ color: GlobalStylesColor.mbppBlue, fontSize: ChsStyles.Topic }}>{systemContentLocale.no_recommendation}</p>
                )}
                {console.log("segmentData", segmentData)}
              </CardBody>

              {/* card footer */}
              <CardFooter className="py-4">
                <Row>
                  <Col lg="6" className="text-left" style={{ marginBottom: 10 }}>
                    <Button
                      style={ChsStyles.GeneralButton}
                      color="primary"
                      onClick={() => {
                        history.goBack();
                      }}
                    >
                      {systemContentLocale.app_back}
                    </Button>
                    <Button
                      style={ChsStyles.GeneralButton}
                      color="primary"
                      onClick={() => {
                        setshowRecomm(false); // Close overall recommendations and show institute details
                        setShowAnswers(true); // Show answers
                        scrollToTop();
                      }}
                    >
                      {systemContentLocale.app_next}
                    </Button>
                  </Col>

                  <Col lg="6" className="text-right" style={{ marginBottom: 10 }}>
                    <Button
                      style={ChsStyles.GeneralButton}
                      color="primary"
                      onClick={(e) => {
                        handlePrint1(e, surveyCompletedTemplate);
                      }}
                    >
                      {systemContentLocale.print_preview}
                    </Button>
                  </Col>
                </Row>
              </CardFooter>
            </Card>
          </div>
        </Row>

        {/* survey answering with recommendations */}
        <Row style={{ display: showAnswers && !Lodash.isEmpty(surveyType) ? "block" : "none" }}>
          <div className="col">
            <Card className="shadow">
              {/* survey header */}
              <CardHeader className="border-0">
                {rtlAlignment === RTL_FLAG_RIGHT ? (
                  <div className="row">
                    <div className="mb-xl-0 col-12">
                      <h3 className="mb-0">
                        {!Lodash.isEmpty(segmentData) ? (segmentDataLocale[segmentCounter] && segmentDataLocale[segmentCounter].title ? segmentDataLocale[segmentCounter].title : segmentData[segmentCounter].title) : ""}
                        {` .${segmentCounter + 1}`}
                      </h3>
                      <h6 className="mb-0">{systemContentLocale.assessment_answer}</h6>
                    </div>
                  </div>
                ) : (
                  <div className="row">
                    <div className="mb-xl-0 col-10">
                      <h3 className="mb-0">
                        {`${segmentCounter + 1}. `}
                        {!Lodash.isEmpty(segmentData) ? (segmentDataLocale[segmentCounter] && segmentDataLocale[segmentCounter].title ? segmentDataLocale[segmentCounter].title : segmentData[segmentCounter].title) : ""}
                      </h3>
                      <h6 className="mb-0">{systemContentLocale.assessment_answer}</h6>
                    </div>
                  </div>
                )}
              </CardHeader>

              {/* survey recommendations */}
              <CardBody>
                {/* <hr className="my-2" /> */}
                <div className="mb-0" style={ChsStyles.Topic}>
                  {systemContentLocale.segment}: {segmentData && roundValue(segmentData[segmentCounter]?.segmentScore, true)}
                </div>
                <br />
                {/* {console.log("surveyRecommendations", surveyRecommendations)} */}
                {surveyRecommendations &&
                  surveyRecommendations?.published === true &&
                  surveyRecommendations?.segments &&
                  surveyRecommendations?.segments[segmentCounter] &&
                  !Lodash.isEmpty(surveyRecommendations?.segments[segmentCounter]?.recommendations) ? (
                  surveyRecommendations?.segments[segmentCounter]?.recommendations.map((recom, i) => {
                    return recom.from <= segmentData[segmentCounter]?.segmentScore && segmentData[segmentCounter]?.segmentScore <= recom.to ? (
                      <Row className="text-justify">
                        <Col lg="12" style={{ ...ChsStyles.Question, color: GlobalStylesColor.mbppBlue, whiteSpace: "break-spaces" }}>
                          {recom.recommendation}
                        </Col>
                        {/* 
                        <i key={i} style={{ color: GlobalStylesColor.mbppBlue, whiteSpace: "break-spaces" }}>
                          {recom.note}
                        </i> */}
                      </Row>
                    ) : null;
                  })
                ) : (
                  <p style={{ color: GlobalStylesColor.mbppBlue }}>{systemContentLocale.no_recommendation}</p>
                )}
              </CardBody>

              {/* survey content with Q&A */}
              <CardBody>
                <hr className="my2" />
                {!Lodash.isEmpty(segmentData) &&
                  Object.values(segmentData[segmentCounter].questions).map((item, i) => {
                    return (
                      <AnswerUiModel
                        id={refreshToken}
                        key={i}
                        item={item}
                        order={i}
                        itemLocale={
                          segmentDataLocale && segmentDataLocale[segmentCounter] && segmentDataLocale[segmentCounter]?.questions && segmentDataLocale[segmentCounter]?.questions[i] ? segmentDataLocale[segmentCounter]?.questions[i] : {}
                        }
                        rtlAlignment={rtlAlignment}
                      />
                    );
                  })}
              </CardBody>

              {/* survey footer */}
              <CardFooter className="py-4">
                <Row>
                  <Col lg="12">
                    <div aria-label="..." style={{ marginBottom: 5, marginTop: 5, overflowX: "auto" }}>
                      {showPagination && segmentCount && segmentCount > 0 && showAnswers && !Lodash.isEmpty(surveyType) ? (
                        <RtlPagination
                          rtol={rtlAlignment === RTL_FLAG_RIGHT}
                          current={segmentCounter + 1}
                          total={segmentCount}
                          onPageChange={(current) => {
                            setShowPagination(false);
                            setSegmentCounter(current - 1);
                            scrollToTop();
                            setShowPagination(true);
                          }}
                        />
                      ) : null}
                    </div>
                  </Col>
                </Row>

                <Row>
                  <Col lg="6" className="text-left" style={{ marginBottom: 10 }}>
                    <Button
                      style={ChsStyles.GeneralButton}
                      color="primary"
                      onClick={() => {
                        history.goBack();
                      }}
                    >
                      {systemContentLocale.app_back}
                    </Button>
                    {segmentCounter + 1 >= segmentCount ? null : (
                      <Button
                        style={ChsStyles.GeneralButton}
                        color="primary"
                        onClick={() => {
                          setShowPagination(false);
                          setSegmentCounter(segmentCounter + 1);
                          scrollToTop();
                          setShowPagination(true);
                        }}
                      >
                        {systemContentLocale.app_next}
                      </Button>
                    )}
                  </Col>

                  <Col lg="6" className="text-right" style={{ marginBottom: 10 }}>
                    <Button
                      style={ChsStyles.GeneralButton}
                      color="primary"
                      onClick={(e) => {
                        handlePrint1(e, surveyCompletedTemplate);
                      }}
                    >
                      {systemContentLocale.print_preview}
                    </Button>
                  </Col>
                </Row>
              </CardFooter>
            </Card>
          </div>
        </Row>
      </Container>
    </div>
  );
};

export default AnswerComplete;
