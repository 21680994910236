import {
  ORG_FETCH_ALL,
  ORG_UPDATE,
  ORG_FETCH_ONE,
  ORG_CITY_STATUS_UPDATE,
  ORG_FETCH_ALL_BY_COUNTRY_ID,
  ORGANIZATION_PROFILE_DELETE,
} from 'constants/actionTypes';
import { LOGINSTATE } from "config/config.json";

const initState = {
  organizationAll: [],
  userProfileOrg: [],
  orgProfileUpdate: [],
  updateOrgCityStatus: [],
  organizationAllByCountryId: [],
  deleteOrganization : [],
}

const organizationReducer = (organization = initState, action) => {
  if(LOGINSTATE) console.log("Dispatch is called for: ", action.type);
  if(LOGINSTATE) console.log("Dispatch is called with: ", action.payload);
  switch (action.type) {
    case ORG_UPDATE:
      return {...organization, orgProfileUpdate : action.payload};
    case ORG_FETCH_ONE:
        return {...organization, userProfileOrg: action.payload };
    // All organizations
    case ORG_FETCH_ALL:
      return { ...organization, organizationAll: action.payload };
    case ORG_CITY_STATUS_UPDATE:
      return { ...organization, updateOrgCityStatus: action.payload };
    case ORG_FETCH_ALL_BY_COUNTRY_ID:
      return { ...organization, organizationAllByCountryId: action.payload };
    case ORGANIZATION_PROFILE_DELETE:
      return { ...organization, deleteOrganization: action.payload };
    default:
      return organization;
  }
};

export default organizationReducer;
