import { Avatar } from "@material-ui/core";
import Config from "config/config.json";
import * as actionType from "constants/actionTypes";
import { fetchMasterDataLanguage } from "actions/masterData";
import { Camelize } from "helper.js";
import { t } from "i18next";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link, useHistory } from "react-router-dom";

import { useTranslation, initReactI18next, Trans } from "react-i18next";

// reactstrap components
import { Container, DropdownItem, DropdownMenu, DropdownToggle, Media, Nav, Navbar, UncontrolledDropdown } from "reactstrap";

const AdminNavbar = (props) => {
  console.log("brand text", props);
  const userDetails = useSelector((state) => state.auth.authData);
  const dispatch = useDispatch();
  const history = useHistory();

  const { t, i18n } = useTranslation();

  const [rtlAlignment, setRtlAlignment] = useState("left");

  const userLanguage = useSelector((state) => state.masterData.languageByCode);
  console.log("userLanguage", userLanguage);
  useEffect(() => {
    i18n.changeLanguage(jUser?.user?.language);

    let params = {
      user: {
        code: jUser?.user?.language,
      },
    };
    dispatch(fetchMasterDataLanguage(params.user));
  }, []);

  useEffect(() => {
    userLanguage &&
      userLanguage.language &&
      Object.values(userLanguage.language).map((lang, i) => {
        if (lang && lang.dir === "rtl") {
          setRtlAlignment("right");
        }
      });
  }, [userLanguage]);

  let currentUser = localStorage.getItem("userDetails");
  let jUser = JSON.parse(currentUser);
  let uRole = currentUser && jUser && jUser.user && jUser.user.urole ? jUser.user.urole : "GENERAL";
  // if (LOGINSTATE) console.log("uRole", currentUser);

  // handle click event of logout
  const logout = () => {
    dispatch({ type: actionType.LOGOUT });
    localStorage.clear();
    history.push("/public/login");
  };

  return (
    <>
      <Navbar className="navbar-top navbar-dark" expand="md" id="navbar-main">
        <Container fluid>
          {uRole === "GENERAL" ? (
            <Link className="h4 mb-0 text-white text-uppercase d-none d-lg-inline-block">{t("navigation." + props.brandText)}</Link>
          ) : (
            <Link className="h4 mb-0 text-white text-uppercase d-none d-lg-inline-block">{props.brandText}</Link>
          )}
          {userDetails?.user ? (
            <Nav className="align-items-center d-none d-md-flex" navbar>
              <UncontrolledDropdown nav>
                {/* Horizontal top navigation profile */}
                <DropdownToggle className="pr-0" nav>
                  <Media className="align-items-center">
                    <span className="avatar avatar-sm rounded-circle">
                      <Avatar className="purple" alt={userDetails?.user.firstName} src={userDetails?.user.profilePicture ? Config.SERVER_API_URL + Config.PUBLIC_PROF_PIC + userDetails?.user.profilePicture : null}>
                        {userDetails && userDetails?.user.firstName && userDetails?.user.firstName.length > 0 ? userDetails?.user.firstName.charAt(0).toUpperCase() : ""}
                      </Avatar>
                    </span>
                    <Media className="ml-2 d-none d-lg-block">
                      <span className="mb-0 text-sm font-weight-bold">{Camelize(userDetails?.user.firstName + " " + userDetails?.user.lastName)}</span>
                    </Media>
                  </Media>
                </DropdownToggle>

                {/* Horizontal top navigation's drop down options */}
                {rtlAlignment === "right" ? (
                  <DropdownMenu className="dropdown-menu-arrow" right>
                    <DropdownItem className="noti-title" header tag="div" style={{ textAlign: "right", marginRight: 20}}>
                      <h6 className="text-overflow m-0">{t("profile.welcome")}</h6>
                    </DropdownItem>
                    <DropdownItem to="/registered/user_profile" tag={Link} style={{ textAlign: "right"}}>
                      <span style={{ marginRight: 10 }}>{t("profile.myProfile")}</span>
                      <i className="ni ni-single-02" />
                    </DropdownItem>

                    <DropdownItem divider />
                    <DropdownItem onClick={logout} style={{ textAlign: "right"}}>
                      <span style={{ marginRight: 10 }}>{t("profile.logout")}</span>
                      <i className="fas fa-power-off" />
                    </DropdownItem>
                  </DropdownMenu>
                ) : (
                  <DropdownMenu className="dropdown-menu-arrow" right>
                    <DropdownItem className="noti-title" header tag="div">
                      <h6 className="text-overflow m-0">{t("profile.welcome")}</h6>
                    </DropdownItem>
                    <DropdownItem to="/registered/user_profile" tag={Link}>
                      <i className="ni ni-single-02" />
                      <span>{t("profile.myProfile")}</span>
                    </DropdownItem>

                    <DropdownItem divider />
                    <DropdownItem onClick={logout}>
                      <i className="fas fa-power-off" />
                      <span>{t("profile.logout")}</span>
                    </DropdownItem>
                  </DropdownMenu>
                )}
              </UncontrolledDropdown>
            </Nav>
          ) : null}
        </Container>
      </Navbar>
    </>
  );
};

export default AdminNavbar;
