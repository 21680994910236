import i18next from "i18next";
import HttpApi from 'i18next-http-backend';
import ChainedBackend from "i18next-chained-backend";
import resourcesToBackend from "i18next-resources-to-backend";
import { initReactI18next } from "react-i18next";
import { SERVER_API_URL } from "config/config.json";


// -------------------------------------------------------------------
// # load translation resources locally #

// import transitionEn from "./assets/locales/en/translation.json";
// import transitionFr from "./assets/locales/fr/translation.json";
// import transitionSi from "./assets/locales/si/translation.json";

// const transitionEn = {
//   welcome: "welcome",
//   sample: "sample",
//   changed: "count is"
// };

// const transitionFr = {
//   welcome: "Bienvenue",
//   sample: "goûter",
//   changed: "modifié"
// };

// i18next
//   .use(initReactI18next)
//   .init({
//     debug: true,
//     interpolation: { escapeValue: false },  // React already does escaping
//     lng: 'en',
//     fallbackLng: 'en',                         // language to use
//     resources: {
//       en: { translation: transitionEn },
//       fr: { translation: transitionFr },
//       si: { translation: transitionSi },
//     },
//   });



// -------------------------------------------------------------------
// # load translation resources over http #
// // const apiUrl = 'locales/{{lng}}/{{ns}}.json';
// // const apiUrl = SERVER_API_URL+ 'uploads/locales/{{lng}}/{{ns}}.json';
// const apiUrl = SERVER_API_URL+ 'uploads/resource/{{lng}}_{{ns}}.json';
// i18next
//   .use(HttpApi )
//   .init({
//     lng: 'en',
//     fallbackLng: 'en',
//     crossDomain: false,
//     backend: {
//       loadPath: apiUrl
//     }
//   });
  


// -------------------------------------------------------------------
// # load translation resources over http, otherwise refer local default #
i18next
  .use(ChainedBackend )
  .init({
    lng: 'en',
    fallbackLng: 'en',
    crossDomain: false,
    backend: {
      backends: [
        HttpApi,
        resourcesToBackend((lng, ns, clb) => {
          import(`./assets/locales/${lng}_localization.json`)
                .then((json) => clb(null, json))
                .catch(clb)
        })
      ],
      backendOptions: [{
        loadPath: SERVER_API_URL+ 'uploads/json/{{lng}}_localization.json'
      },{
        loadPath: './assets/locales/{{lng}}_localization.json'
      }]
    }
  });


export default i18next;
