import * as actionType from "constants/actionTypes";
import { fetchMasterDataLanguage } from "actions/masterData";
import { t } from "i18next";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
// reactstrap components
import { Card, CardBody, CardTitle, Col, Container, Row } from "reactstrap";

import { useTranslation, initReactI18next, Trans } from "react-i18next";

const Header = () => {
  const dispatch = useDispatch();
  const { t, i18n } = useTranslation();

  const [rtlAlignment, setRtlAlignment] = useState("left");

  const userLanguage = useSelector((state) => state.masterData.languageByCode);
  console.log("userLanguage", userLanguage);
  useEffect(() => {
    i18n.changeLanguage(jUser?.user?.language);
    console.log("language", jUser?.user);

    let params = {
      user: {
        code: jUser?.user?.language,
      },
    };
    dispatch(fetchMasterDataLanguage(params.user));
  }, []);

  useEffect(() => {
    userLanguage &&
      userLanguage.language &&
      Object.values(userLanguage.language).map((lang, i) => {
        if (lang && lang.dir === "rtl") {
          setRtlAlignment("right");
        }
      });
  }, [userLanguage]);

  let currentUser = localStorage.getItem("userDetails");
  let jUser = JSON.parse(currentUser);
  let uRole = currentUser && jUser && jUser.user && jUser.user.urole ? jUser.user.urole : "GENERAL";
  // if (LOGINSTATE) console.log("uRole", currentUser);

  return (
    <>
      <div
        style={{
          backgroundColor: "#af1e23",
          backgroundImage: "url(" + require("../../assets/img/theme/dashboardBg.png").default + ")",
          backgroundSize: "cover",
          backgroundPosition: "center top",
        }}
      >
        <div
          className="header pb-8 pt-3 pt-md-8"
          style={{
            backgroundImage: "url(" + require("../../assets/img/theme/dashboard.png").default + ")",
            backgroundSize: "contain",
            backgroundRepeat: "no-repeat",
            backgroundPosition: "center top",
          }}
        >
          <Container fluid>
            <div className="header-body">
              {/* Card stats */}
              {rtlAlignment === "right" ? (
                <Row className="text-right">
                  <Col lg="12">
                    <h3 className="text-right" style={{ color: "white", fontWeight: "bold" }}>
                      {t("dashboard.header")}
                    </h3>

                    <span style={{ color: "white" }}>{t("dashboard.paragraph1")}</span>
                    <br />
                    <span style={{ color: "white" }}>
                      {t("dashboard.paragraph2")}
                      <a className="text-white" target="_blank" rel="noreferrer" href="https://app.adpc.net/resourcesapp/">
                        <u>
                          <b>{t("dashboard.appTool")}</b>
                        </u>
                      </a>
                    </span>
                  </Col>
                </Row>
              ) : (
                <Row className="text-justify">
                  <Col lg="12">
                    <h3 className="text-left" style={{ color: "white", fontWeight: "bold" }}>
                      {t("dashboard.header")}
                    </h3>

                    <span style={{ color: "white" }}>{t("dashboard.paragraph1")}</span>
                    <br />
                    <span style={{ color: "white" }}>
                      {t("dashboard.paragraph2")}
                      <a className="text-white" target="_blank" rel="noreferrer" href="https://app.adpc.net/resourcesapp/">
                        <u>
                          <b>{t("dashboard.appTool")}</b>
                        </u>
                      </a>
                    </span>
                  </Col>
                </Row>
              )}
              {/* <br />
            <br /> */}
              {/* <Row className="text-justify">
              <Col lg="4">
                <h4 style={{ color: "white" }}>The Core Humanitarian Standards Assessment Tool (CHSAT)</h4>
                <span style={{ color: "white" }}>
                  The CHSAT seeks to support the institutionalization of the Core Humanitarian Standard (CHS) among local humanitarian organizations. The tool intends to assist local actors to identify gaps in their adherence to the CHS as
                  well as in designing and implementing capacity-building interventions to improve their provision of humanitarian assistance to populations affected by disasters
                </span>
              </Col>

              <Col lg="4">
                <h4 style={{ color: "white" }}>Institutional Capacity Assessment Tool (ICAT)</h4>
                <span style={{ color: "white" }}>
                  The ICAT aims to assist local actors to comply with the commitments enshrined in the CHS towards preventing and alleviating human suffering during emergencies. The tool seeks to support local humanitarian organizations to
                  identify gaps in their institutional and operational capacities as well as to prioritize capacity-building interventions to strengthen their capacities in compliance with the tenets of the CHS.
                </span>
              </Col>

              <Col lg="4">
                <h4 style={{ color: "white" }}>Tool for Institutional Engagement (TIE)</h4>
                <span style={{ color: "white" }}>
                  The TIE enables organizations in the national ecosystems to evaluate and review the level of their contribution in the different phases of the disaster risk management (DRM) continuum. The tool is anchored on the concept
                  of social responsibility that pertains to the duty of individuals and institutions to act in the best interests of the organization, environment, and society as a whole. It highlights the interventions that need to be
                  implemented to enhance their institutional and operational engagement before, during, and after disasters.
                </span>
              </Col>
            </Row> */}
            </div>
          </Container>
        </div>
      </div>
    </>
  );
};

export default Header;
