import AdminFooter from "components/Footers/AdminFooter.js";
// core components
import AdminNavbar from "components/Navbars/AdminNavbar.js";
import { LOGINSTATE } from "config/config.json";
import Sidebar from "components/Sidebar/Sidebar.js";
import * as actionType from 'constants/actionTypes';
import decode from 'jwt-decode';
import React, { useEffect, useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Redirect, Route, Switch, useHistory, useLocation } from "react-router-dom";
// reactstrap components
import { Container } from "reactstrap";
import { routes, routesAdmin, routesUser } from "routes.js";
import Lodash from "lodash";
import { CircularProgress } from '@material-ui/core';
import '../assets/css/react-confirm-alert-custom.css'; // Import css

import PrintAnswersAndGraph from "views/pages/survey/GraphAnswerPrint";

const Print = (props) => {
  const userDetails = useSelector((state) => state.auth.authData);
  const mainContent = React.useRef(null);
  const location = useLocation();
  const dispatch = useDispatch();
  const history = useHistory();

  const [uRole, setURole] = useState("GENERAL");
  const [showLoading, setShowLoading] = useState(false);


  // handle click event of logout
  const logout = () => {
    dispatch({ type: actionType.LOGOUT });
    localStorage.clear();
    history.push('/public/login');
  };

  useEffect(() => {
    // Heartbeat to avoid keeping old UI when new user is logged from different browser tab
    // Check the validity of token
    setInterval(() => {
      const tokenMem = userDetails?.token;
      let currentUserLocal = localStorage.getItem('userDetails');
      let jUser = JSON.parse(currentUserLocal);
      const tokenLocal = jUser?.token;
      if (tokenLocal && tokenLocal.length > 3 && tokenLocal === tokenMem) {
        // tokens are same, hence same user session. ignore
      }
      else {
        window.location.reload();
      }
    }, 60000);
  }, [])


  // after render DOM. Manually check the validity of token. Can't relay
  useEffect(() => {
    if (userDetails && Object.keys(userDetails).length > 0) {
      if (userDetails?.token) {
        const token = userDetails?.token;
        const decodedToken = decode(token);

        if (decodedToken.exp * 1000 < new Date().getTime()) {
          logout();
        }
        else {
          setURole(userDetails?.user?.urole ? userDetails?.user?.urole : "GENERAL");
        }
      }
    }
    else {
      logout();
    }
  }, [userDetails]);

  const innerRouts = useRef([])

  useEffect(() => {
    document.documentElement.scrollTop = 0;
    document.scrollingElement.scrollTop = 0;
    mainContent.current.scrollTop = 0;

  }, [location]);

  // manage loading indicator
  const loadingSpinner = useSelector((state) => state.dashboard.showLoading);
  useEffect(() => {
    if (LOGINSTATE) console.log("loadingSpinner: ", loadingSpinner);
    setShowLoading(Lodash.isBoolean(loadingSpinner) ? loadingSpinner : false);
  }, [loadingSpinner]);

  const getInnerRoutes = (routes) => {
    routes.forEach((prop, key) => {
      prop.hasOwnProperty('innerlinks') && (prop.innerlinks).forEach((innerProp, innerKey) => {
        if (innerProp.layout === "/print_content") {
          innerRouts.current.push(<Route
            path={innerProp.layout + innerProp.path}
            component={innerProp.component}
            key={key + innerKey}
          />)
        }
      });
    });
  };
  getInnerRoutes(uRole === "ADMIN" ? routesAdmin : routesUser);

  return (
    <>
      {showLoading ?
        <div className="react-confirm-alert-overlay">
          <CircularProgress style={{ color: '#af1e23' }} />
        </div> : null
      }

      {/* Page write/main content template */}
      <div className="main-content" ref={mainContent}>

        <Switch>
          <Route
            path="/print_content/assessment_preview"
            component={
              PrintAnswersAndGraph
            }
            key="1" />
        </Switch>

      </div>

    </>
  );
};

export default Print;
