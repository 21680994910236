import { makeStyles } from "@material-ui/core/styles";
import CryptoJS from "crypto-js";
import { SUPPORT_EMAIL } from "./config/config.json";

export const phoneRegExp = /^((\\+[1-9]{1,4}[ \\-]*)|(\\([0-9]{2,3}\\)[ \\-]*)|([0-9]{2,4})[ \\-]*)*?[0-9]{3,4}?[ \\-]*[0-9]{3,4}?$/;

export const FILE_IMG_SIZE = 1024 * 720;

export const SUPPORTED_IMG_FORMATS = ["image/jpg", "image/jpeg", "image/gif", "image/png"];

export const ages = ["0-15", "16-21", "22-35", "36-50", "51-75", "75+"];

export const genders = ["Male", "Female", "Other"];

export const titles = ["Mr", "Mrs", "Ms", "Dr", "Rev", ""];

export const emp_types = ["Government", "Non-government", "Private sector", "Other"];

export const countries = ["", "Cambodia", "Nepal", "Philippines", "Pakistan", "Myanmar", "Sri Lanka"];

export const Contactgroup = ["CMC", "Construction", "School", "Other"]; // ["School", "Construction", "Genaral", "Construction Site", "Other"]

export const legal_entitys = ["Yes", "No"];

export const vision = ["Yes", "No"];

export const unit = ["Yes", "No"];

export const functional = ["Yes", "No"];

export const stakeholder = ["Yes", "No"];

export const headoffice = ["Yes", "No"];

export const geo_areas = ["National", "Provincial", "District", "City/Municipality", "Village"];

export const drm_acts = ["All phases of DRM", "Before disasters", "During disasters", "After disasters"];

export const city_drm_acts = ["All phases of DRM", "Before disasters", "During disasters", "After disasters"];

export const user_role = ["GENERAL", "ADMIN"];

export const emp_insitutes = ["", "ADPC", "Other"];

export const ins_cities = ["Bankok", "Other"];

export const app_country = ["Yes", "No"];

export const int_language = ["Yes", "No"];

export const internationalized = ["Yes", "No"];

export const RTL_FLAG_RIGHT = "right";

export const RTL_FLAG_LEFT = "left";

export const SYS_DEFAULT_LANGUAGE_CODE = "en";

export const institutionalQuestions = {
  organization: "Name of the organization:",
  type: "Type of organization:",
  legalStatus: "Are you a legal entity under the Laws of your country?",

  geographicArea: "Geographic area of operations:",
  numberOfEmployees: "Number of employees:",
  numberOfEmployees_remark: "Remark of number of employees",
  drmActivities: "DRM activities performed:",
  visionMission: "Does your organization have vision and mission statements?",
  visionMission_remark: "Remark of vision mission",
  functionalStructure: "Does your organization have a functional organizational structure?",
  functionalStructure_remark: "Remark of functional structure",
  unitProject: "Is there a dedicated unit(s) charged with project formulation, Project implementation, Project monitoring and evaluation for preparedness planning and emergency response?",
  unitProject_remark: "Remark of unit project",
  unitStakeholders: "Is there a dedicated unit charged with coordinating identified stakeholders for emergency response?",
  unitStakeholders_remark: "Remark of unitstakeholder",
};

export const answer_status = ["PROGRESS", "COMPLETE"];
export const survey_status = { PROGRESS: "PROGRESS", COMPLETE: "COMPLETE" };

export const initialValues_assessmentPage = {
  languageCode: "",
  app_ok: "Ok",
  app_yes: "Yes",
  app_no: "No",
  app_next: "NEXT",
  app_back: "BACK",
  app_success: "Success",
  app_error: "Something went wrong",
  app_partly_save: "PARTLY SAVE",
  app_complete: "COMPLETE",
  app_sorry: "Sorry",
  app_no_active_survey: "No active assessment",
  app_download: "Download PDF",
  assessment_completion: "Successfully saved your answer. Please send us your valuable feed back to" + SUPPORT_EMAIL,
  chs_short: "CHSAT",
  icat_short: "ICAT",
  tie_short: "TIE",
  app_assessment_switch_title: "Confirm to switch assessments",
  app_assessment_switch_message: "Are you sure to do this? You will lose unsaved answers.",
  app_assessment_step_01: "STEP 1: ORGANIZATION DETAILS",
  app_assessment_step_01_message_01: "Are you sure to do this? You will lose unsaved answers.",
  app_assessment_step_02: "STEP 2: PLEASE SELECT ONE OF THE ABOVE TO BEGIN AN ASSESSMENT.",
  app_assessment_step_02_message_02: "For more information, please refer resources section",
  app_assessment_incomplete_title: "Assessment is incomplete. Please complete the segment.",
  app_assessment_institute_details: "Institutional Details of the User",
  app_assessment_step_01_title: "STEP 1: ORGANIZATION DETAILS",
  app_assessment_step_01_message: "Below are the organization information for the assessment. Update your profile to keep it up to date.",
  app_assessment_sentence: "Your organization's details are used for the institutional profile. If it's not up to the date,",
  app_assessment_sentence_ref: "please update now",
  app_assessment_organization_details: "Show organization details ...",
  app_assessment_answer_opt1: "Not applicable",
  app_assessment_answer_opt2: "Achievements are negligible or minor",
  app_assessment_answer_opt3: "Achievements are incomplete",
  app_assessment_answer_opt4: "Achievements are moderate",
  app_assessment_answer_opt5: "Substantial achievement",
  app_assessment_answer_opt6: "Comprehensive achievement",
  chsat: "Core Humanitarian Standard Assessment Tool",
  icat: "Institutional Capacity Assessment Tool",
  tie: "Tool for Institutional Engagement",
  answer_remark: "Remark",
  answer: "Answer",
  no_remark: "No remark",
  chs_app: "APP Self Assessment Tool",
  password_invalid: "Password invalid.",
  user_not_register: "User not registered",
  user_unlink: "User removed",
  user_deactivate: "User deactivated",
  user_not_create: "User is not completed",
  organization_deactivate: "Your organization deactivated",
  location_deactivate: "Your location deactivated",
  login_message: "Please contact administrator by sending an email to " + SUPPORT_EMAIL,
  user_successfully_created: "User successfully created.",
  session_expired: "Session expired!",
  print_preview: "Print Full Recommendation",
  segment_score: "Segment score",
  total_score: "Total score",
  version: "Version of the assessment",
  font_size: "Default font Size",
  self_assessment: "Self Assessment",
  recommendations_self_assessment: "Recommendations for the self assessment",
  pdf_version: "PDF Version",
  print: "Print",
  app_tool: "APP Tool",
  insitutional_details: "Institutional details",
  overall: "Overall recommendation",
  segment: "Segment recommendation",
  assessment_answer: "Assessment answers",
  no_recommendation: "No recommendations",
  update: "Update",
};

// camel case
export const Camelize = (str) => {
  return str ? str.replace(/\b(\w)/g, (s) => s.toUpperCase()) : "";
};

//password encryption
export const CryptoPassword = (value) => {
  return CryptoJS.SHA256(value).toString();
};

export const useMultiSelectStyles = makeStyles((theme) => ({
  formControl: {
    margin: theme.spacing(1),
    width: 300,
  },
  indeterminateColor: {
    color: "#f50057",
  },
  selectAllText: {
    fontWeight: 500,
  },
  selectedAll: {
    backgroundColor: "rgba(0, 0, 0, 0.08)",
    "&:hover": {
      backgroundColor: "rgba(0, 0, 0, 0.08)",
    },
  },
}));

export const roundValue = (value, toString) => {
  if (toString) {
    return value > 0 ? Math.round((value + Number.EPSILON) * 100) / 100 + "" : "";
  } else {
    return Math.round((value + Number.EPSILON) * 100) / 100;
  }
};

// Process array actions
export const processArray = async (array, process, position, value) => {
  let deletedElements = null;
  if (process === "ADD") {
    deletedElements = array.splice(position, 0, value);
    return array;
  } else if (process === "DELETE") {
    deletedElements = array.splice(position, 1);
    return array;
  } else if (process === "MOVEUP") {
    if (array.length > position + 1) {
      let temp = array[position];
      array[position] = array[position + 1];
      array[position + 1] = temp;
      return array;
    } else {
      return array;
    }
  } else if (process === "MOVEDOWN") {
    if (0 <= position - 1) {
      let temp = array[position];
      array[position] = array[position - 1];
      array[position - 1] = temp;
      return array;
    } else {
      return array;
    }
  } else if (process === "UPDATEAT") {
    array[position] = value;
    return array;
  } else {
    return array;
  }
};

export const SAVE_DATE_TIME_FORMAT = "YYYY-MM-DD HH:mm:ss"

// reCAPTCHA languages as per 2022-August
export const RECAPTCHA_LANG_LIST = ["ar",
  "af",
  "am",
  "hy",
  "az",
  "eu",
  "bn",
  "bg",
  "ca",
  "zh-HK",
  "zh-CN",
  "zh-TW",
  "hr",
  "cs",
  "da",
  "nl",
  "en-GB",
  "en",
  "et",
  "fil",
  "fi",
  "fr",
  "fr-CA",
  "gl",
  "Value",
  "ka",
  "de",
  "de-AT",
  "de-CH",
  "el",
  "gu",
  "iw",
  "hi",
  "hu",
  "is",
  "id",
  "it",
  "ja",
  "kn",
  "ko",
  "lo",
  "lv",
  "lt",
  "ms",
  "ml",
  "mr",
  "mn",
  "no",
  "fa",
  "Value",
  "pl",
  "pt",
  "pt-BR",
  "pt-PT",
  "ro",
  "ru",
  "sr",
  "si",
  "sk",
  "sl",
  "es",
  "es-419",
  "sw",
  "sv",
  "ta",
  "te",
  "th",
  "tr",
  "uk",
  "ur",
  "vi",
  "zu"]
