import {
  FRM_TOPIC_FETCH_ALL,
  FRM_TOPIC_FETCH_ONE,
  FRM_TOPIC_CREATE,
  FRM_TOPIC_UPDATE,
  FRM_SUB_TOPIC_FETCH_ALL,
  FRM_SUB_TOPIC_FETCH_ONE,
  FRM_SUB_TOPIC_CREATE,
  FRM_SUB_TOPIC_UPDATE,
  FRM_QUS_FETCH_ONE,
  FRM_QUS_FETCH_ALL,
  FRM_QUS_TOPIC_FETCH_ALL,
  FRM_QUS_CREATE,
  FRM_QUS_UPDATE,
  FRM_ANS_FETCH_ALL,
  FRM_ANS_FETCH_ONE,
  FRM_ANS_CREATE,
  FRM_ANS_UPDATE,
  SHOW_LOADING,
} from "constants/actionTypes";
import { LOGINSTATE } from "config/config.json";
import * as api from "../api/index.js";
import Lodash from "lodash";
import { confirmAlert } from "react-confirm-alert";
import "react-confirm-alert/src/react-confirm-alert.css"; // Import css

// forum Topics

// trans done
export const fetchFrmTopics = (history, t) => async (dispatch) => {
  try {
    dispatch({ type: SHOW_LOADING, payload: true });
    const { data } = await api.fetchFrmTopics();
    if (data?.status === "success") {
      dispatch({ type: FRM_TOPIC_FETCH_ALL, payload: data?.data });
    }
    if (data?.status === "error") {
      if (LOGINSTATE) console.log(data);
      if (t) {
        confirmAlert({ message: t('popups.somethingWentWrong'), buttons: [{ label: t('common.ok'), onClick: () => { } }], closeOnEscape: false, closeOnClickOutside: false });
      }
      else {
        confirmAlert({ message: "Oops!, something went wrong. Please try again.", buttons: [{ label: "Ok", onClick: () => { } }], closeOnEscape: false, closeOnClickOutside: false });
      }
    }
  } catch (error) {
    if (LOGINSTATE) console.log(error);
    if (t) {
      confirmAlert({ message: t('popups.somethingWentWrong'), buttons: [{ label: t('common.ok'), onClick: () => { } }], closeOnEscape: false, closeOnClickOutside: false });
    }
    else {
      confirmAlert({ message: "#1 Server is busy at the moment. Please retry again.", buttons: [{ label: "Ok", onClick: () => { } }], closeOnEscape: false, closeOnClickOutside: false });
    }
    history.push("/public");
  } finally {
    dispatch({ type: SHOW_LOADING, payload: false });
  }
};

// no need trans
// seems not using
export const fetchFrmTopicById = (formData, router) => async (dispatch) => {
  try {
    dispatch({ type: SHOW_LOADING, payload: true });
    const { data } = await api.fetchFrmTopicById(formData);
    if (data?.status === "success") {
      dispatch({ type: FRM_TOPIC_FETCH_ONE, payload: data?.data });
      formData.redirect && router.push(formData.redirect);
    }
    if (data?.status === "error") {
      if (LOGINSTATE) console.log(data);
      confirmAlert({ message: "Oops!, something went wrong. Please try again.", buttons: [{ label: "Ok", onClick: () => { } }], closeOnEscape: false, closeOnClickOutside: false });
    }
  } catch (error) {
    if (LOGINSTATE) console.log(error);
    if (error?.response?.status && error?.response?.status === 401) {
      confirmAlert({ message: "Session expired!", buttons: [{ label: "Ok", onClick: () => { } }], closeOnEscape: false, closeOnClickOutside: false });
      router.push("/auth/signin");
    } else {
      alert(error);
    }
  } finally {
    dispatch({ type: SHOW_LOADING, payload: false });
  }
};

// no need trans
export const createFrmTopic = (formData, router) => async (dispatch) => {
  try {
    dispatch({ type: SHOW_LOADING, payload: true });
    const { data } = await api.createFrmTopic(formData);
    dispatch({ type: FRM_TOPIC_CREATE, payload: data });
    if (data?.status === "success") {
      confirmAlert({ title: "Success", message: "successfully created.", buttons: [{ label: "Ok", onClick: () => { } }], closeOnEscape: false, closeOnClickOutside: false });
    }
    if (data?.status === "error") {
      if (LOGINSTATE) console.log(data);
      confirmAlert({ message: "Oops!, something went wrong. Please try again.", buttons: [{ label: "Ok", onClick: () => { } }], closeOnEscape: false, closeOnClickOutside: false });
    }
  } catch (error) {
    if (LOGINSTATE) console.log(error);
    if (error?.response?.status && error?.response?.status === 401) {
      confirmAlert({ message: "Session expired!", buttons: [{ label: "Ok", onClick: () => { } }], closeOnEscape: false, closeOnClickOutside: false });
      router.push("/auth/signin");
    } else {
      alert(error);
    }
  } finally {
    dispatch({ type: SHOW_LOADING, payload: false });
  }
};

// no need trans
export const updateFrmTopic = (formData, router) => async (dispatch) => {
  try {
    dispatch({ type: SHOW_LOADING, payload: true });
    const { data } = await api.updateFrmTopic(formData);
    dispatch({ type: FRM_TOPIC_UPDATE, payload: data });
    if (data?.status === "success") {
      confirmAlert({ title: "Success", message: "successfully updated.", buttons: [{ label: "Ok", onClick: () => { } }], closeOnEscape: false, closeOnClickOutside: false });
      formData.redirect && router.push(formData.redirect);
    }
    if (data?.status === "error") {
      if (LOGINSTATE) console.log(data);
      confirmAlert({ message: "Oops!, something went wrong. Please try again.", buttons: [{ label: "Ok", onClick: () => { } }], closeOnEscape: false, closeOnClickOutside: false });
    }
  } catch (error) {
    if (LOGINSTATE) console.log(error);
    if (error?.response?.status && error?.response?.status === 401) {
      confirmAlert({ message: "Session expired!", buttons: [{ label: "Ok", onClick: () => { } }], closeOnEscape: false, closeOnClickOutside: false });
      router.push("/auth/signin");
    } else {
      alert(error);
    }
  } finally {
    dispatch({ type: SHOW_LOADING, payload: false });
  }
};

// forum Sub Topics

// no need trans
// seems not using
export const fetchFrmSubTopics = (formData, router) => async (dispatch) => {
  try {
    dispatch({ type: SHOW_LOADING, payload: true });
    const { data } = await api.fetchFrmSubTopics(formData);
    if (data?.status === "success") {
      dispatch({ type: FRM_SUB_TOPIC_FETCH_ALL, payload: data?.data });
    }
    if (data?.status === "error") {
      if (LOGINSTATE) console.log(data);
      confirmAlert({ message: "Oops!, something went wrong. Please try again.", buttons: [{ label: "Ok", onClick: () => { } }] });
    }
  } catch (error) {
    if (LOGINSTATE) console.log(error);
    if (error?.response?.status && error?.response?.status === 401) {
      confirmAlert({ message: "Session expired!", buttons: [{ label: "Ok", onClick: () => { } }], closeOnEscape: false, closeOnClickOutside: false });
      router.push("/auth/signin");
    } else {
      alert(error);
    }
  } finally {
    dispatch({ type: SHOW_LOADING, payload: false });
  }
};

// no need trans
// seems not using
export const fetchFrmSubTopicById = (formData, router) => async (dispatch) => {
  try {
    dispatch({ type: SHOW_LOADING, payload: true });
    const { data } = await api.fetchFrmSubTopicById(formData);
    if (data?.status === "success") {
      dispatch({ type: FRM_SUB_TOPIC_FETCH_ONE, payload: data?.data });
      formData.redirect && router.push(formData.redirect);
    }
    if (data?.status === "error") {
      if (LOGINSTATE) console.log(data);
      confirmAlert({ message: "Oops!, something went wrong. Please try again.", buttons: [{ label: "Ok", onClick: () => { } }], closeOnEscape: false, closeOnClickOutside: false });
    }
  } catch (error) {
    if (LOGINSTATE) console.log(error);
    if (error?.response?.status && error?.response?.status === 401) {
      confirmAlert({ message: "Session expired!", buttons: [{ label: "Ok", onClick: () => { } }], closeOnEscape: false, closeOnClickOutside: false });
      router.push("/auth/signin");
    } else {
      alert(error);
    }
  } finally {
    dispatch({ type: SHOW_LOADING, payload: false });
  }
};

// no need trans
export const createFrmSubTopic = (formData, router) => async (dispatch) => {
  try {
    dispatch({ type: SHOW_LOADING, payload: true });
    const { data } = await api.createFrmSubTopic(formData);
    dispatch({ type: FRM_SUB_TOPIC_CREATE, payload: data });
    if (data?.status === "success") {
      confirmAlert({ title: "Success", message: "FAQ topic successfully created.", buttons: [{ label: "Ok", onClick: () => { } }], closeOnEscape: false, closeOnClickOutside: false });
      router.push("/registered/faq_management");
    }
    if (data?.status === "error") {
      if (LOGINSTATE) console.log(data);
      confirmAlert({ message: "Oops!, something went wrong. Please try again.", buttons: [{ label: "Ok", onClick: () => { } }], closeOnEscape: false, closeOnClickOutside: false });
    }
  } catch (error) {
    if (LOGINSTATE) console.log(error);
    if (error?.response?.status && error?.response?.status === 401) {
      confirmAlert({ message: "Session expired!", buttons: [{ label: "Ok", onClick: () => { } }], closeOnEscape: false, closeOnClickOutside: false });
      router.push("/auth/signin");
    } else {
      alert(error);
    }
  } finally {
    dispatch({ type: SHOW_LOADING, payload: false });
  }
};

// no need trans
export const updateFrmSubTopic = (formData, router) => async (dispatch) => {
  try {
    dispatch({ type: SHOW_LOADING, payload: true });
    const { data } = await api.updateFrmSubTopic(formData);
    dispatch({ type: FRM_SUB_TOPIC_UPDATE, payload: data });
    if (data?.status === "success") {
      confirmAlert({ title: "Success", message: "FAQ status successfully updated.", buttons: [{ label: "Ok", onClick: () => { } }], closeOnEscape: false, closeOnClickOutside: false });
      router.push("/registered/faq_management");
    }
    if (data?.status === "error") {
      if (LOGINSTATE) console.log(data);
      confirmAlert({ message: "Oops!, something went wrong. Please try again.", buttons: [{ label: "Ok", onClick: () => { } }], closeOnEscape: false, closeOnClickOutside: false });
    }
  } catch (error) {
    if (LOGINSTATE) console.log(error);
    if (error?.response?.status && error?.response?.status === 401) {
      confirmAlert({ message: "Session expired!", buttons: [{ label: "Ok", onClick: () => { } }], closeOnEscape: false, closeOnClickOutside: false });
      router.push("/auth/signin");
    } else {
      alert(error);
    }
  } finally {
    dispatch({ type: SHOW_LOADING, payload: false });
  }
};

// forum Questions

// trans done
export const fetchFrmQus = (history, t) => async (dispatch) => {
  console.log("#### t", t);
  try {
    dispatch({ type: SHOW_LOADING, payload: true });
    const { data } = await api.fetchFrmQus();
    if (data?.status === "success") {
      dispatch({ type: FRM_QUS_FETCH_ALL, payload: data?.data });
    }
    if (data?.status === "error") {
      if (LOGINSTATE) console.log(data);
      if (t) {
        confirmAlert({ message: t('popups.somethingWentWrong'), buttons: [{ label: t('common.ok'), onClick: () => { } }], closeOnEscape: false, closeOnClickOutside: false });
      }
      else {
        confirmAlert({ message: "Oops!, something went wrong. Please try again.", buttons: [{ label: "Ok", onClick: () => { } }], closeOnEscape: false, closeOnClickOutside: false });
      }
    }
  } catch (error) {
    if (LOGINSTATE) console.log(error);
    if (t) {
      confirmAlert({ message: t('popups.somethingWentWrong'), buttons: [{ label: t('common.ok'), onClick: () => { } }], closeOnEscape: false, closeOnClickOutside: false });
    }
    else {
      confirmAlert({ message: "#1 Server is busy at the moment. Please retry again.", buttons: [{ label: "Ok", onClick: () => { } }], closeOnEscape: false, closeOnClickOutside: false });
    }
    history.push("/public");
  } finally {
    dispatch({ type: SHOW_LOADING, payload: false });
  }
};

// no need trans
// seems not using
export const fetchFrmQusbyTopics = (formData, router) => async (dispatch) => {
  try {
    dispatch({ type: SHOW_LOADING, payload: true });
    const { data } = await api.fetchFrmQusbyTopics(formData);
    if (data?.status === "success") {
      dispatch({ type: FRM_QUS_TOPIC_FETCH_ALL, payload: data?.data });
      router.push("/registered/faq_questions");
    }
    if (data?.status === "error") {
      if (LOGINSTATE) console.log(data);
      confirmAlert({ message: "Oops!, something went wrong. Please try again.", buttons: [{ label: "Ok", onClick: () => { } }], closeOnEscape: false, closeOnClickOutside: false });
    }
  } catch (error) {
    if (LOGINSTATE) console.log(error);
    if (error?.response?.status && error?.response?.status === 401) {
      confirmAlert({ message: "Session expired!", buttons: [{ label: "Ok", onClick: () => { } }], closeOnEscape: false, closeOnClickOutside: false });
      router.push("/auth/signin");
    } else {
      alert(error);
    }
  } finally {
    dispatch({ type: SHOW_LOADING, payload: false });
  }
};

// no need trans
// seems not using
export const fetchFrmQusbyId = (formData, router) => async (dispatch) => {
  try {
    dispatch({ type: SHOW_LOADING, payload: true });
    const { data } = await api.fetchFrmQusbyId(formData);
    if (data?.status === "success") {
      dispatch({ type: FRM_QUS_FETCH_ONE, payload: data?.data });
      formData.redirect && router.push(formData.redirect);
    }
    if (data?.status === "error") {
      if (LOGINSTATE) console.log(data);
      confirmAlert({ message: "Oops!, something went wrong. Please try again.", buttons: [{ label: "Ok", onClick: () => { } }], closeOnEscape: false, closeOnClickOutside: false });
    }
  } catch (error) {
    if (LOGINSTATE) console.log(error);
    if (error?.response?.status && error?.response?.status === 401) {
      confirmAlert({ message: "Session expired!", buttons: [{ label: "Ok", onClick: () => { } }], closeOnEscape: false, closeOnClickOutside: false });
      router.push("/auth/signin");
    } else {
      alert(error);
    }
  } finally {
    dispatch({ type: SHOW_LOADING, payload: false });
  }
};

// no need trans
export const createFrmQus = (formData, history, pathParam) => async (dispatch) => {
  if (LOGINSTATE) console.log(formData);
  try {
    dispatch({ type: SHOW_LOADING, payload: true });
    const { data } = await api.createFrmQus(formData);
    dispatch({ type: FRM_QUS_CREATE, payload: data });
    if (data?.status === "success") {
      confirmAlert({ title: "Success", message: "Question successfully created.", buttons: [{ label: "Ok", onClick: () => { } }], closeOnEscape: false, closeOnClickOutside: false });
      if (pathParam && !Lodash.isEmpty(pathParam?.path)) {
        history.push({ pathname: pathParam.path, state: pathParam.state });
      } else if (pathParam && Lodash.isBoolean(pathParam?.reload)) {
        history.go(0);
      }
    }
    if (data?.status === "error") {
      if (LOGINSTATE) console.log(data);
      confirmAlert({ message: "Oops!, something went wrong. Please try again.", buttons: [{ label: "Ok", onClick: () => { } }], closeOnEscape: false, closeOnClickOutside: false });
    }
  } catch (error) {
    if (LOGINSTATE) console.log("catch error: ", error);
    if (error?.response?.status && error?.response?.status === 401) {
      confirmAlert({ message: "Session expired!", buttons: [{ label: "Ok", onClick: () => { } }], closeOnEscape: false, closeOnClickOutside: false });
      history.push("/auth/signin");
    } else {
      confirmAlert({ message: "Oops!, something went wrong. Please try again.", buttons: [{ label: "Ok", onClick: () => { } }], closeOnEscape: false, closeOnClickOutside: false });
    }
  } finally {
    dispatch({ type: SHOW_LOADING, payload: false });
  }
};

// no need trans
export const updateFrmQus = (formData, history, pathParam) => async (dispatch) => {
  try {
    dispatch({ type: SHOW_LOADING, payload: true });
    const { data } = await api.updateFrmQus(formData);
    dispatch({ type: FRM_QUS_UPDATE, payload: data });
    if (data?.status === "success") {
      confirmAlert({ title: "Success", message: "Question successfully updated.", buttons: [{ label: "Ok", onClick: () => { } }], closeOnEscape: false, closeOnClickOutside: false });
      if (pathParam && !Lodash.isEmpty(pathParam?.path)) {
        history.push({ pathname: pathParam.path, state: pathParam.state });
      } else if (pathParam && Lodash.isBoolean(pathParam?.reload)) {
        history.go(0);
      }
    }
    if (data?.status === "error") {
      if (LOGINSTATE) console.log(data);
      confirmAlert({ message: "Oops!, something went wrong. Please try again.", buttons: [{ label: "Ok", onClick: () => { } }], closeOnEscape: false, closeOnClickOutside: false });
    }
  } catch (error) {
    // if(LOGINSTATE) console.log(error);
    if (error?.response?.status && error?.response?.status === 401) {
      confirmAlert({ message: "Session expired!", buttons: [{ label: "Ok", onClick: () => { } }], closeOnEscape: false, closeOnClickOutside: false });
      history.push("/auth/signin");
    } else {
      confirmAlert({ message: "Oops!, something went wrong. Please try again.", buttons: [{ label: "Ok", onClick: () => { } }], closeOnEscape: false, closeOnClickOutside: false });
    }
  } finally {
    dispatch({ type: SHOW_LOADING, payload: false });
  }
};

// forum Answers

// no need trans
// seems not using
export const fetchFrmAns = (formData, router) => async (dispatch) => {
  try {
    dispatch({ type: SHOW_LOADING, payload: true });
    const { data } = await api.fetchFrmAns(formData);
    if (data?.status === "success") {
      dispatch({ type: FRM_ANS_FETCH_ALL, payload: data?.data });
    }
    if (data?.status === "error") {
      if (LOGINSTATE) console.log(data);
      confirmAlert({ message: "Oops!, something went wrong. Please try again.", buttons: [{ label: "Ok", onClick: () => { } }], closeOnEscape: false, closeOnClickOutside: false });
    }
  } catch (error) {
    if (LOGINSTATE) console.log(error);
    if (error?.response?.status && error?.response?.status === 401) {
      confirmAlert({ message: "Session expired!", buttons: [{ label: "Ok", onClick: () => { } }], closeOnEscape: false, closeOnClickOutside: false });
      router.push("/auth/signin");
    } else {
      alert(error);
    }
  } finally {
    dispatch({ type: SHOW_LOADING, payload: false });
  }
};

// no need trans
// seems not using
export const fetchFrmAnsById = (formData, router) => async (dispatch) => {
  try {
    dispatch({ type: SHOW_LOADING, payload: true });
    const { data } = await api.fetchFrmAnsById(formData);
    if (data?.status === "success") {
      dispatch({ type: FRM_ANS_FETCH_ONE, payload: data?.data });
      formData.redirect && router.push(formData.redirect);
    }
    if (data?.status === "error") {
      if (LOGINSTATE) console.log(data);
      confirmAlert({ message: "Oops!, something went wrong. Please try again.", buttons: [{ label: "Ok", onClick: () => { } }], closeOnEscape: false, closeOnClickOutside: false });
    }
  } catch (error) {
    if (LOGINSTATE) console.log(error);
    if (error?.response?.status && error?.response?.status === 401) {
      confirmAlert({ message: "Session expired!", buttons: [{ label: "Ok", onClick: () => { } }], closeOnEscape: false, closeOnClickOutside: false });
      router.push("/auth/signin");
    } else {
      alert(error);
    }
  } finally {
    dispatch({ type: SHOW_LOADING, payload: false });
  }
};

// no need trans
export const createFrmAns = (formData, history, pathParam) => async (dispatch) => {
  try {
    dispatch({ type: SHOW_LOADING, payload: true });
    const { data } = await api.createFrmAns(formData);
    dispatch({ type: FRM_ANS_CREATE, payload: data });
    if (data?.status === "success") {
      confirmAlert({ title: "Success", message: "Answer successfully created.", buttons: [{ label: "Ok", onClick: () => { } }], closeOnEscape: false, closeOnClickOutside: false });
      if (pathParam && !Lodash.isEmpty(pathParam?.path)) {
        history.push({ pathname: pathParam.path, state: pathParam.state });
      } else if (pathParam && Lodash.isBoolean(pathParam?.reload)) {
        history.go(0);
      }
    }
    if (data?.status === "error") {
      if (LOGINSTATE) console.log(data);
      confirmAlert({ message: "Oops!, something went wrong. Please try again.", buttons: [{ label: "Ok", onClick: () => { } }], closeOnEscape: false, closeOnClickOutside: false });
    }
  } catch (error) {
    if (LOGINSTATE) console.log(error);
    if (error?.response?.status && error?.response?.status === 401) {
      confirmAlert({ message: "Session expired!", buttons: [{ label: "Ok", onClick: () => { } }], closeOnEscape: false, closeOnClickOutside: false });
      history.push("/auth/signin");
    } else {
      alert(error);
    }
  } finally {
    dispatch({ type: SHOW_LOADING, payload: false });
  }
};

// no need trans
export const updateFrmAns = (formData, history, pathParam) => async (dispatch) => {
  if (LOGINSTATE) console.log("formData", formData);
  if (LOGINSTATE) console.log("pathParam", pathParam);

  try {
    dispatch({ type: SHOW_LOADING, payload: true });
    const { data } = await api.updateFrmAns(formData);
    dispatch({ type: FRM_ANS_UPDATE, payload: data });
    if (data?.status === "success") {
      confirmAlert({
        title: "Success",
        message: "Answer successfully updated.",
        buttons: [
          {
            label: "Ok",
            onClick: () => {
              if (pathParam && !Lodash.isEmpty(pathParam?.path)) {
                history.push({ pathname: pathParam.path, state: pathParam.state });
              } else if (pathParam && Lodash.isBoolean(pathParam?.reload)) {
                history.go(0);
              }
            },
          },
        ],
      });
    }
    if (data?.status === "error") {
      if (LOGINSTATE) console.log(data);
      confirmAlert({ message: "Oops!, something went wrong. Please try again.", buttons: [{ label: "Ok", onClick: () => { } }], closeOnEscape: false, closeOnClickOutside: false });
    }
  } catch (error) {
    if (LOGINSTATE) console.log(error);
    if (error?.response?.status && error?.response?.status === 401) {
      confirmAlert({ message: "Session expired!", buttons: [{ label: "Ok", onClick: () => { } }], closeOnEscape: false, closeOnClickOutside: false });
      history.push("/auth/signin");
    } else {
      alert(error);
    }
  } finally {
    dispatch({ type: SHOW_LOADING, payload: false });
  }
};

export const deleteFrmAns = (formData, history, pathParam) => async (dispatch) => {
  if (LOGINSTATE) console.log("delete function is called");
  try {
    dispatch({ type: SHOW_LOADING, payload: true });
    const { data } = await api.deleteFrmAns(formData);
    if (LOGINSTATE) console.log("delete response data: ", data);

    if (data?.status === "success") {
      confirmAlert({
        title: "Success",
        /*message: data?.message,*/ buttons: [
          {
            label: "Ok",
            onClick: () => {
              // redirect to relevant page with state parameters (to populate the page), otherwise go back
              if (pathParam && !Lodash.isEmpty(pathParam?.path)) {
                history.push({ pathname: pathParam.path, state: pathParam.state });
              } else if (pathParam && Lodash.isBoolean(pathParam?.reload)) {
                history.go(0);
              }
            },
          },
        ],
      });
    } else if (data?.status === "error") {
      // if(LOGINSTATE) console.log(data);

      confirmAlert({ title: "Error", message: "Couldn't perform the task.", buttons: [{ label: "Ok", onClick: () => { } }], closeOnEscape: false, closeOnClickOutside: false });
    }
  } catch (error) {
    // if(LOGINSTATE) console.log(error);
    if (error?.response?.status && error?.response?.status === 401) {
      confirmAlert({ message: "Session expired!", buttons: [{ label: "Ok", onClick: () => { } }], closeOnEscape: false, closeOnClickOutside: false });
      history.push("/auth/signin");
    } else {
      confirmAlert({ title: "Error", message: "Couldn't perform the task.", buttons: [{ label: "Ok", onClick: () => { } }], closeOnEscape: false, closeOnClickOutside: false });
    }
  } finally {
    dispatch({ type: SHOW_LOADING, payload: false });
  }
};
