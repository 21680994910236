import { FormControl, TextField } from "@material-ui/core";
import IconButton from "@material-ui/core/IconButton";
import InputAdornment from "@material-ui/core/InputAdornment";
import Lodash from "lodash";
import PublicOutlinedIcon from "@material-ui/icons/PublicOutlined";
import VpnLockOutlinedIcon from "@material-ui/icons/VpnLockOutlined";
import PersonIcon from "@material-ui/icons/Person";
import { confirmAlert } from "react-confirm-alert";
import "react-confirm-alert/src/react-confirm-alert.css";
import PersonAddDisabledIcon from "@material-ui/icons/PersonAddDisabled";
import AccountCircleIcon from "@material-ui/icons/AccountCircle";
import { Tooltip } from "@material-ui/core";

// core components
import PageHeader from "components/Headers/UserHeader.js";
import { ChsStyles, GlobalStylesColor } from "constants/globalStyles";
import React, { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { useHistory } from "react-router-dom";
import { Button, Card, CardBody, Col, Container, Form, Row, Table } from "reactstrap";
import { updateOrganizationCityStatus } from "actions/organization";
import { fetchProfileOrgAsAdmin } from "actions/user";
import Config from "config/config.json";
import { LOGINSTATE } from "../../../config/config.json";
import { LocalDrinkSharp } from "@material-ui/icons";

const Organization = (prop) => {
  if (LOGINSTATE) console.log("prop", prop);
  const [props, setProps] = useState({});

  //useEffect
  useEffect(() => {
    const props1 = prop.location.state.org;
    setProps(props1);
    if (LOGINSTATE) console.log("props1", props1);
    if (LOGINSTATE) console.log("props", props);
  }, []);

  // dispatch and history
  const dispatch = useDispatch();
  const history = useHistory();

  const [editSection, setEditSection] = useState("FALSE");
  const [currentCityClick, setCurrentCityClick] = useState({});
  const [insideCity, setInsideCity] = useState("FALSE");

  const statusClick = (e, params) => {
    e.preventDefault();
    if (params.status === false) {
      let values = {
        organizationId: params.orgId,
        cityId: params.cityId,
        cityStatus: true,
        cityLinkedUser: params.linkedUser,
      };
      let pathParam = {
        path: "/registered/organization",
        state: { organizationId: prop.location.state?.org._id },
      };
      // let formData = { forumQuestionId: values.forumQuestionId, description: values.description, question: values.question};
      dispatch(updateOrganizationCityStatus(values, history, pathParam));
    } else {
      let values = {
        organizationId: params.orgId,
        cityId: params.cityId,
        cityStatus: false,
        cityLinkedUser: params.linkedUser,
      };
      let pathParam = {
        path: "/registered/organization",
        state: { organizationId: prop.location.state?.org._id },
      };
      dispatch(updateOrganizationCityStatus(values, history, pathParam));
    }
  };

  const linkUserClick = (e, params) => {
    if (LOGINSTATE) console.log("params: ", params);
    // e.preventDefault();
    let user = params.linkedUser;
    if (LOGINSTATE) console.log("user", user);
    if ((params.linkedUser = !null)) {
      let values = {
        organizationId: params.orgId,
        cityId: params.cityId,
        cityStatus: params.status,
        unlinkedUser: user,
        cityLinkedUser: null,
      };
      confirmAlert({
        title: "Remove User",
        message: "This is irreversible action. Do you want to continue?",
        buttons: [
          {
            label: "Yes",
            onClick: () => {
              if (LOGINSTATE) console.log("values", values);
              let pathParam = {
                path: "/registered/organization",
                state: { reload: true },
              };
              dispatch(updateOrganizationCityStatus(values, history, pathParam));
            },
          },
          { label: "No", onClick: () => {} },
        ],
        closeOnEscape: false,
        closeOnClickOutside: false,
      });
    }
  };

  const userClick = (e, params) => {
    if (LOGINSTATE) console.log("User");
    // e.preventDefault();
    if (params.linkedUser) {
      let user = {
        userId: params.linkedUser,
      };
      if (LOGINSTATE) console.log("values", user);
      dispatch(fetchProfileOrgAsAdmin(user, history));
      history.push({ pathname: "/registered/user_profile_manage", state: { userDetails: user } });
    }
  };

  const ViewClick = (e, organizationProfile) => {
    console.log("organizationProfile", organizationProfile);
    e.preventDefault();
    if (!Lodash.isEmpty(organizationProfile)) {
      let pathToResource = Config.SERVER_API_URL + "uploads/organizationProfile/" + organizationProfile;
      if (LOGINSTATE) console.log("pathToResource: ", pathToResource);
      window.open(pathToResource, "_blank");
    }
  };

  return (
    <>
      <PageHeader />

      {/* Page content */}
      <Container className="mt--7" fluid>
        <Row className="mt-4">
          <Col>
            <Card>
              <CardBody>
                <Form role="form">
                  <div className="heading-title mb-4" style={ChsStyles.Topic}>
                    Organizational Profile
                  </div>
                  <div className="pl-lg-4">
                    <hr className="my-2" />
                    <Col
                      style={{
                        display: editSection === "FALSE" ? "block" : "none",
                      }}
                    >
                      <h6 className="heading-small mb-4" style={ChsStyles.SubTopic}>
                        Organizational Details
                      </h6>

                      <Row>
                        <Col md="6">
                          <FormControl fullWidth style={ChsStyles.Input}>
                            {/* <label className="form-control-label" htmlFor="input-country">
                              Country*
                            </label> */}
                            <TextField
                              fullWidth
                              label="Country*"
                              id="org_country"
                              name="org_country"
                              variant="outlined"
                              value={props?.country?.name}
                              SelectProps={{
                                native: true,
                              }}
                              className={"form-control-alternative form-control-edit"}
                              disabled={true}
                            ></TextField>
                            {/* <p className="small">
                                If the picture is not square shape, image may stretch to fit.
                              </p> */}
                          </FormControl>
                        </Col>

                        <Col md="6">
                          <FormControl fullWidth style={ChsStyles.Input}>
                            {/* <label className="form-control-label" htmlFor="input-institute">
                              Name of the Organization*
                            </label> */}
                            <TextField
                              fullWidth
                              label="Name of the Organization*"
                              id="emp_institute"
                              name="emp_institute"
                              variant="outlined"
                              value={props?.name}
                              SelectProps={{
                                native: true,
                              }}
                              className={"form-control-alternative form-control-edit"}
                              disabled={true}
                            ></TextField>
                          </FormControl>
                        </Col>
                      </Row>

                      <hr className="my-2" />
                      <h6 className="heading-small mb-4" style={ChsStyles.SubTopic}>
                        Institutional Details
                      </h6>
                      <Row>
                        <Col md="6">
                          <FormControl fullWidth style={ChsStyles.Input}>
                            {/* <label className="form-control-label" htmlFor="input-institute">
                              Type of organization*
                            </label> */}
                            <TextField
                              fullWidth
                              label="Type of organization*"
                              id="type"
                              name="type"
                              variant="outlined"
                              value={props?.type}
                              SelectProps={{
                                native: true,
                              }}
                              className={"form-control-alternative form-control-edit"}
                              disabled={true}
                            ></TextField>
                          </FormControl>
                        </Col>

                        <Col md="6">
                          <FormControl fullWidth style={ChsStyles.Input}>
                            {/* <label className="form-control-label" htmlFor="input-institute">
                              Geographic areas of operations*
                            </label> */}
                            <TextField
                              fullWidth
                              label="Geographic areas of operations*"
                              id="geo_area"
                              name="geo_area"
                              variant="outlined"
                              value={props?.geographicArea}
                              SelectProps={{
                                native: true,
                              }}
                              className={"form-control-alternative form-control-edit"}
                              disabled={true}
                            ></TextField>
                          </FormControl>
                        </Col>
                      </Row>

                      <Row>
                        <Col md="6">
                          <FormControl fullWidth style={ChsStyles.Input}>
                            {/* <label className="form-control-label" htmlFor="input-institute">
                              Organization Website
                            </label> */}
                            <TextField
                              fullWidth
                              label=" Organization Website"
                              id="emp_address"
                              name="emp_address"
                              variant="outlined"
                              value={props?.website}
                              className={"form-control-alternative form-control-edit"}
                              disabled={true}
                            ></TextField>
                          </FormControl>
                        </Col>

                        <Col lg="6">
                          <FormControl fullWidth style={ChsStyles.Input}>
                            {/* <label className="form-control-label" htmlFor="input-institute">
                              Organization Profile
                            </label> */}
                            <TextField
                              fullWidth
                              label="Organization Profile"
                              id="emp_address"
                              name="emp_address"
                              variant="outlined"
                              value={props?.organizationProfile}
                              className={"form-control-alternative form-control-edit"}
                              InputProps={{
                                endAdornment: (
                                  <InputAdornment>
                                    <IconButton
                                      onClick={(e) => {
                                        ViewClick(e, props?.organizationProfile);
                                      }}
                                    >
                                      <i className="far fa-eye" style={{ color: GlobalStylesColor.mbppIconColor, marginRight: -20 }} />
                                    </IconButton>
                                  </InputAdornment>
                                ),
                              }}
                              disabled={true}
                            ></TextField>
                          </FormControl>
                        </Col>
                      </Row>
                      <br />

                      <Row>
                        <Col lg="12">
                          <FormControl fullWidth style={{ margin: 0, padding: 0 }}>
                            <label className="form-control-label" htmlFor="input-institute">
                              Are you legal entity under laws of your country?*
                            </label>
                          </FormControl>
                        </Col>

                        <Col md="2" style={ChsStyles.Input}>
                          <TextField
                            fullWidth
                            id="legalStatus_status"
                            name="legalStatus_status"
                            variant="outlined"
                            label="Answer"
                            value={props?.legalStatus?.status === true ? "Yes" : "No"}
                            SelectProps={{
                              native: true,
                            }}
                            className={"form-control-alternative form-control-edit"}
                            disabled={true}
                          ></TextField>
                        </Col>

                        <Col md="10">
                          <TextField
                            fullWidth
                            id="legalStatus_mandate"
                            name="legalStatus_mandate"
                            variant="outlined"
                            label="Mandate"
                            multiline
                            rows={4}
                            value={props?.legalStatus?.mandate}
                            className={"form-control-alternative form-control-edit"}
                            disabled={true}
                          ></TextField>
                        </Col>
                      </Row>
                      <br />

                      <Row>
                        <Col lg="12">
                          <FormControl fullWidth>
                            <label className="form-control-label" htmlFor="input-institute">
                              Number of employees*
                            </label>
                          </FormControl>
                        </Col>
                        <Col md="2" style={ChsStyles.Input}>
                          <TextField
                            fullWidth
                            id="numberOfOrganizationEmployees"
                            name="numberOfOrganizationEmployees"
                            variant="outlined"
                            label="Answer"
                            value={props?.numberOfOrganizationEmployees?.number}
                            className={"form-control-alternative form-control-edit"}
                            disabled={true}
                          ></TextField>
                        </Col>
                        <Col md="10">
                          <TextField
                            fullWidth
                            id="numberOfOrganizationEmployees_remark"
                            name="numberOfOrganizationEmployees_remark"
                            variant="outlined"
                            label="Remark"
                            multiline
                            rows={4}
                            value={props?.numberOfOrganizationEmployees?.remark}
                            className={"form-control-alternative form-control-edit"}
                            disabled={true}
                          ></TextField>
                        </Col>
                      </Row>
                      <br />

                      <Row>
                        <Col md="12">
                          <FormControl fullWidth style={ChsStyles.Input}>
                            <label className="form-control-label" htmlFor="input-institute">
                              DRM activities performed*
                            </label>

                            <TextField
                              fullWidth
                              id="drmActivities"
                              name="drmActivities"
                              variant="outlined"
                              value={props?.drmActivities}
                              SelectProps={{
                                native: true,
                              }}
                              className={"form-control-alternative form-control-edit"}
                              disabled={true}
                            ></TextField>
                          </FormControl>
                        </Col>
                      </Row>
                      <br />

                      <Row>
                        <Col lg="12">
                          <FormControl fullWidth>
                            <label className="form-control-label" htmlFor="input-institute">
                              Does your organization have vision and mission statements?
                            </label>
                          </FormControl>
                        </Col>

                        <Col md="2" style={ChsStyles.Input}>
                          <TextField
                            fullWidth
                            id="visionMission_status"
                            name="visionMission_status"
                            variant="outlined"
                            label="Answer"
                            value={props?.visionMission?.status === true ? "Yes" : "No"}
                            SelectProps={{
                              native: true,
                            }}
                            className={"form-control-alternative form-control-edit"}
                            disabled={true}
                          ></TextField>
                        </Col>

                        <Col md="10">
                          <TextField
                            fullWidth
                            id="visionMission_remarks"
                            name="visionMission_remarks"
                            variant="outlined"
                            label="Remark"
                            multiline
                            rows={4}
                            value={props?.visionMission?.remark}
                            SelectProps={{
                              native: true,
                            }}
                            className={"form-control-alternative form-control-edit"}
                            disabled={true}
                          ></TextField>
                        </Col>
                      </Row>
                      <br />

                      <Row>
                        <Col lg="12">
                          <FormControl fullWidth>
                            <label className="form-control-label" htmlFor="input-institute">
                              Does your organization have a functional organizational structure?
                            </label>
                          </FormControl>
                        </Col>

                        <Col md="2" style={ChsStyles.Input}>
                          <TextField
                            fullWidth
                            id="functionalStructure_status"
                            name="functionalStructure_status"
                            variant="outlined"
                            label="Answer"
                            value={props?.functionalStructure?.status === true ? "Yes" : "No"}
                            SelectProps={{
                              native: true,
                            }}
                            className={"form-control-alternative form-control-edit"}
                            disabled={true}
                          ></TextField>
                        </Col>

                        <Col md="10">
                          <TextField
                            fullWidth
                            id="functionalStructure_remarks"
                            name="functionalStructure_remarks"
                            variant="outlined"
                            label="Remark"
                            multiline
                            rows="4"
                            value={props?.functionalStructure?.remark}
                            SelectProps={{
                              native: true,
                            }}
                            className={"form-control-alternative form-control-edit"}
                            disabled={true}
                          ></TextField>
                        </Col>
                      </Row>
                      <br />

                      <Row>
                        <Col lg="12">
                          <FormControl fullWidth>
                            <label className="form-control-label" htmlFor="input-institute">
                              Is there a dedicated unit(s) charged with project formulation, Project implementation, Project monitoring and evaluation for preparedness planning and emergency response?
                            </label>
                          </FormControl>
                        </Col>
                        <br />

                        <Col md="2" style={ChsStyles.Input}>
                          <TextField
                            fullWidth
                            id="unitProject_status"
                            name="unitProject_status"
                            label="Answer"
                            variant="outlined"
                            value={props?.unitProject?.status === true ? "Yes" : "No"}
                            SelectProps={{
                              native: true,
                            }}
                            className={"form-control-alternative form-control-edit"}
                            disabled={true}
                          ></TextField>
                        </Col>

                        <Col md="10">
                          <TextField
                            fullWidth
                            id="unitProject_remarks"
                            name="unitProject_remarks"
                            label="Remark"
                            multiline
                            rows={4}
                            variant="outlined"
                            value={props?.unitProject?.remark}
                            SelectProps={{
                              native: true,
                            }}
                            className={"form-control-alternative form-control-edit"}
                            disabled={true}
                          ></TextField>
                        </Col>
                      </Row>

                      <br />

                      <Row>
                        <Col lg="12">
                          <FormControl fullWidth>
                            <label className="form-control-label" htmlFor="input-institute">
                              Is there a dedicated unit charged with coordinating identified stakeholders for emergency response?
                            </label>
                          </FormControl>
                        </Col>

                        <Col md="2" style={ChsStyles.Input}>
                          <TextField
                            fullWidth
                            id="unitStakeholders_status"
                            name="unitStakeholders_status"
                            variant="outlined"
                            label="Answer"
                            value={props?.unitStakeholders?.status === true ? "Yes" : "No"}
                            SelectProps={{
                              native: true,
                            }}
                            className={"form-control-alternative form-control-edit"}
                            disabled={true}
                          ></TextField>
                        </Col>

                        <Col md="10">
                          <TextField
                            fullWidth
                            id="unitStakeholders_remarks"
                            name="unitStakeholders_remarks"
                            variant="outlined"
                            label="Remark"
                            multiline
                            rows={4}
                            value={props?.unitStakeholders?.remark}
                            SelectProps={{
                              native: true,
                            }}
                            className={"form-control-alternative form-control-edit"}
                            disabled={true}
                          ></TextField>
                        </Col>
                      </Row>
                      <br />

                      <hr className="my-2" />
                      <h6 className="heading-small mb-4" style={ChsStyles.SubTopic}>
                        Branch Details
                      </h6>

                      <Row style={{ paddingTop: 0, marginLeft: 0 }}>
                        <Table className="align-items-center table-flush" responsive>
                          <thead className="thead-light">
                            <tr>
                              <th scope="col">Location</th>
                              <th scope="col">Office Email</th>
                              <th scope="col">Status</th>
                              <th scope="col">Action</th>
                              {/* <th scope="col">Status</th> */}
                            </tr>
                          </thead>
                          {props?.city?.map((item, j) => (
                            <tbody>
                              <tr>
                                <td
                                  style={{
                                    whiteSpace: "break-spaces",
                                    color: GlobalStylesColor.mbppBlue,
                                  }}
                                >
                                  {item.name}
                                </td>
                                <td style={{ whiteSpace: "break-spaces" }}>{item.email}</td>
                                <td style={{ whiteSpace: "break-spaces" }}>{item.status === true ? "Yes" : "No"}</td>
                                <td
                                  style={{
                                    whiteSpace: "break-spaces",
                                    color: GlobalStylesColor.mbppBlue,
                                  }}
                                >
                                  <div className="row">
                                    <div
                                      className="navbar-toggler"
                                      style={{ cursor: "pointer", color: GlobalStylesColor.mbppIconColorLight }}
                                      onClick={() => {
                                        setEditSection("TRUE");
                                        setCurrentCityClick(item);
                                        setInsideCity("TRUE");
                                      }}
                                    >
                                      <i className="far fa-eye" />
                                    </div>

                                    <Tooltip title="Change publish status" arrow>
                                      <div className="navbar-toggler" style={{ cursor: "pointer" }}>
                                        {item.status ? (
                                          <PublicOutlinedIcon
                                            style={{
                                              color: GlobalStylesColor.mbppIconColorLight,
                                            }}
                                            onClick={(e) => {
                                              let params = {
                                                orgId: props._id,
                                                cityId: item._id,
                                                status: item.status,
                                                linkedUser: item.linkedUser,
                                              };
                                              statusClick(e, params);
                                            }}
                                          />
                                        ) : (
                                          <VpnLockOutlinedIcon
                                            style={{
                                              color: GlobalStylesColor.mbppIconColorLight,
                                            }}
                                            onClick={(e) => {
                                              let params = {
                                                orgId: props._id,
                                                cityId: item._id,
                                                status: item.status,
                                                linkedUser: item.linkedUser,
                                              };
                                              statusClick(e, params);
                                            }}
                                          />
                                        )}
                                      </div>
                                    </Tooltip>

                                    <Tooltip title="Remove user" arrow>
                                      <div className="navbar-toggler" style={{ cursor: "pointer" }}>
                                        {item.linkedUser ? (
                                          <PersonIcon
                                            style={{
                                              color: GlobalStylesColor.mbppIconColorLight,
                                            }}
                                            onClick={(e) => {
                                              let params = {
                                                orgId: props._id,
                                                cityId: item._id,
                                                status: item.status,
                                                linkedUser: item.linkedUser,
                                              };
                                              linkUserClick(e, params);
                                            }}
                                          />
                                        ) : (
                                          <PersonAddDisabledIcon
                                            style={{
                                              color: GlobalStylesColor.mbppIconColorLight,
                                              opacity: 0.3,
                                            }}
                                            onClick={(e) => {}}
                                          />
                                        )}
                                      </div>
                                    </Tooltip>

                                    <Tooltip title="User details" arrow>
                                      <div className="navbar-toggler" style={{ cursor: "pointer" }}>
                                        {item.linkedUser ? (
                                          <AccountCircleIcon
                                            style={{
                                              color: GlobalStylesColor.mbppIconColorLight,
                                            }}
                                            onClick={(e) => {
                                              let params = {
                                                orgId: props._id,
                                                cityId: item._id,
                                                status: item.status,
                                                linkedUser: item.linkedUser,
                                              };
                                              userClick(e, params);
                                            }}
                                          />
                                        ) : (
                                          <AccountCircleIcon
                                            style={{
                                              color: GlobalStylesColor.mbppIconColorLight,
                                              opacity: 0.3,
                                            }}
                                            onClick={(e) => {
                                              let params = {
                                                orgId: props._id,
                                                cityId: item._id,
                                                status: item.status,
                                                linkedUser: item.linkedUser,
                                              };
                                              userClick(e, params);
                                            }}
                                          />
                                        )}
                                      </div>
                                    </Tooltip>
                                  </div>
                                </td>
                              </tr>
                            </tbody>
                          ))}
                        </Table>
                      </Row>
                    </Col>
                  </div>
                </Form>

                <Form role="form">
                  <div className="pl-lg-4">
                    {/* <hr className="my-2" /> */}

                    <>
                      <Col
                        style={{
                          display: editSection === "TRUE" ? "block" : "none",
                        }}
                      >
                        <h6 className="heading-small mb-4" style={ChsStyles.SubTopic}>
                          Branch Details
                        </h6>

                        <Row>
                          <Col md="6" style={{ marginBottom: 10 }}>
                            <FormControl fullWidth>
                              <TextField
                                fullWidth
                                id="city_name"
                                name="city_name"
                                variant="outlined"
                                label=" Location*"
                                value={currentCityClick?.name}
                                SelectProps={{
                                  native: true,
                                }}
                                className={"form-control-alternative form-control-edit"}
                                disabled={true}
                              ></TextField>
                            </FormControl>
                          </Col>

                          <Col md="6" style={{ marginBottom: 10 }}>
                            <FormControl fullWidth>
                              <TextField
                                fullWidth
                                id="city_isHeadOffice"
                                name="city_isHeadOffice"
                                variant="outlined"
                                label="Is this the head office of your organization?"
                                value={currentCityClick?.isHeadOffice === true ? "Yes" : "No"}
                                SelectProps={{
                                  native: true,
                                }}
                                className={"form-control-alternative form-control-edit"}
                                disabled={true}
                              ></TextField>
                            </FormControl>
                          </Col>
                        </Row>
                        <br />

                        <Row>
                          <Col md="12">
                            <FormControl fullWidth>
                              <label className="form-control-label" htmlFor="input-institute">
                                Number of Employees
                              </label>
                            </FormControl>
                          </Col>
                          <Col md="2" style={{ marginBottom: 10 }}>
                            <TextField
                              fullWidth
                              id="city_numberOfEmployees"
                              name="city_numberOfEmployees"
                              variant="outlined"
                              label="Answer"
                              value={currentCityClick?.numberOfEmployees?.number === null ? 0 : currentCityClick?.numberOfEmployees?.number}
                              SelectProps={{
                                native: true,
                              }}
                              className={"form-control-alternative form-control-edit"}
                              disabled={true}
                            ></TextField>
                          </Col>

                          <Col md="10" style={{ marginBottom: 10 }}>
                            <TextField
                              fullWidth
                              id="city_numberOfEmployees_remark"
                              name="city_numberOfEmployees_remark"
                              variant="outlined"
                              label="Remark"
                              multiline
                              rows={4}
                              value={currentCityClick?.numberOfEmployees?.remark}
                              SelectProps={{
                                native: true,
                              }}
                              className={"form-control-alternative form-control-edit"}
                              disabled={true}
                            ></TextField>
                          </Col>
                        </Row>
                        <br />

                        <Row>
                          <Col md="12">
                            <FormControl fullWidth>
                              <label className="form-control-label" htmlFor="input-institute">
                                DRM Activities Performed
                              </label>
                            </FormControl>
                          </Col>

                          <Col md="2" style={{ marginBottom: 10 }}>
                            <TextField
                              fullWidth
                              id="city_drmActivities"
                              name="city_drmActivities"
                              variant="outlined"
                              label="Answer"
                              value={currentCityClick?.drmActivities?.performed}
                              SelectProps={{
                                native: true,
                              }}
                              className={"form-control-alternative form-control-edit"}
                              disabled={true}
                            ></TextField>
                          </Col>

                          <Col md="10" style={{ marginBottom: 10 }}>
                            <TextField
                              fullWidth
                              id="city_drmActivities"
                              name="city_drmActivities"
                              variant="outlined"
                              label="Remark"
                              multiline
                              rows={4}
                              value={currentCityClick?.drmActivities?.remark}
                              SelectProps={{
                                native: true,
                              }}
                              className={"form-control-alternative form-control-edit"}
                              disabled={true}
                            ></TextField>
                          </Col>
                        </Row>
                        <br />
                        <br />

                        <Row>
                          <Col md="4" style={{ marginBottom: 10 }}>
                            <FormControl fullWidth style={ChsStyles.Input}>
                              <TextField
                                fullWidth
                                id="emp_address"
                                name="emp_address"
                                variant="outlined"
                                label="Head-office/Branch Website"
                                value={currentCityClick?.website}
                                className={"form-control-alternative form-control-edit"}
                                disabled={true}
                              ></TextField>
                            </FormControl>
                          </Col>

                          <Col md="4" style={{ marginBottom: 10 }}>
                            <TextField fullWidth id="contactno" name="contactno" label="Office Email" variant="outlined" value={currentCityClick?.email} className={"form-control-alternative form-control-edit"} disabled={true}></TextField>
                          </Col>

                          <Col md="4" style={{ marginBottom: 10 }}>
                            <TextField
                              fullWidth
                              id="emp_address"
                              name="emp_address"
                              variant="outlined"
                              label="Office Address"
                              value={currentCityClick?.address}
                              multiline
                              rows={4}
                              className={"form-control-alternative form-control-edit"}
                              disabled={true}
                            ></TextField>
                          </Col>
                        </Row>
                        <br />
                      </Col>
                    </>

                    {/* </div> */}

                    <br />

                    <div className="text-left">
                      <Col lg="6" className="text-left">
                        <Button
                          style={ChsStyles.GeneralButton}
                          color="primary"
                          onClick={() => {
                            // history.goBack();
                            if (insideCity === "TRUE") {
                              history.push("registered/organizational_details");
                            }
                            window.history.back();
                          }}
                        >
                          BACK
                        </Button>
                      </Col>
                    </div>
                  </div>
                </Form>
              </CardBody>
            </Card>
          </Col>
        </Row>
      </Container>
    </>
  );
};

export default Organization;
