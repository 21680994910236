import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useLocation, useHistory } from "react-router-dom";
import { fetchFrmTopics, fetchFrmQus, deleteFrmAns } from "actions/forum";

// reactstrap components
import { Card, CardHeader, Table, Container, Row, CardFooter, Col, Button } from "reactstrap";
// core components
import PageHeader from "components/Headers/PageHeader.js";
import { GlobalStylesColor, ChsStyles } from "constants/globalStyles";
import { Tooltip } from "@material-ui/core";
import NavigateNextIcon from "@material-ui/icons/NavigateNext";
import { confirmAlert } from "react-confirm-alert";

const ForumTableQuestionRow = ({ topicId, subTopicId, QusId, question, description, questionClick, questionEdit, questionDelete }) => (
  <tr id={QusId}>
    <td style={{ whiteSpace: "break-spaces", color: GlobalStylesColor.mbppThemeBlue }}>{question}</td>
    <td style={{ whiteSpace: "break-spaces" }}>{description}</td>
    <td className="text-right">
      <div className="row">
        <Tooltip title="Edit" arrow>
          <div
            className="navbar-toggler"
            style={{ cursor: "pointer" }}
            onClick={(e) => {
              questionEdit(e, topicId, subTopicId, QusId);
            }}
          >
            <i className="far fa-edit" style={{ color: GlobalStylesColor.mbppIconColor }} />
          </div>
        </Tooltip>
        <Tooltip title="Delete" arrow>
          <div
            className="navbar-toggler"
            style={{ cursor: "pointer" }}
            onClick={(e) => {
              confirmAlert({
                title: 'Confirm delete',
                buttons: [
                  { label: "No", onClick: () => { } },
                  {
                    label: "Yes", onClick: () => {
                      questionDelete(e, QusId);
                    }
                  },
                ],
                closeOnEscape: false,
                closeOnClickOutside: false
              });
            }}
          >
            <i className="far fa-times-circle" style={{ color: GlobalStylesColor.mbppIconColor }} />
          </div>
        </Tooltip>
        <Tooltip title="View answer" arrow>
          <div
            className="navbar-toggler"
            style={{ cursor: "pointer" }}
            onClick={(e) => {
              questionClick(e, topicId, subTopicId, QusId);
            }}
          >
            <NavigateNextIcon style={{ color: GlobalStylesColor.mbppIconColor }} />
          </div>
        </Tooltip>
      </div>
    </td>
  </tr>
);

const ForumSubTopic = (props) => {
  // dispatch and history
  const dispatch = useDispatch();
  const history = useHistory();
  // set state topic id
  const [topicId, setTopicId] = useState(null);
  // set state topic
  const [topic, setTopic] = useState(null);
  // set state topic description
  const [topicDescription, setTopicDescription] = useState(null);
  // set state sub topic id
  const [subTopicId, setSubTopicId] = useState(null);
  // set state sub topic
  const [subTopic, setSubTopic] = useState(null);
  // set state sub topic description
  const [subTopicDescription, setSubTopicDescription] = useState(null);
  // get topic details
  const selectFrmTopic = (state) => {
    const itemArray = state.forum.frmAllTopics?.filter((item) => {
      return item._id === props.location.state?.frmTopicId;
    });
    if (itemArray && itemArray[0]) {
      return itemArray[0];
    }
  };
  const topicDetails = useSelector(selectFrmTopic);
  // get sub topic details
  const selectFrmSubTopic = (topicDetails) => {
    const itemArray =
      topicDetails?.subTopic?.length > 0 &&
      topicDetails?.subTopic?.filter((item) => {
        return item._id === props.location.state?.frmSubTopicId;
      });
    if (itemArray && itemArray[0]) {
      return itemArray[0];
    }
  };
  const subTopicDetails = selectFrmSubTopic(topicDetails);
  // get Questions details by topic
  const selectFrmQuestions = (state) => {
    const itemArray = state.forum.frmAllQus?.filter((item) => {
      return item.subTopic === props.location.state?.frmSubTopicId;
    });
    if (itemArray) {
      return itemArray;
    }
  };
  const frmQuestionsByTopic = useSelector(selectFrmQuestions);
  useEffect(() => {
    // fetching forum topics
    dispatch(fetchFrmTopics());
    // fetching forum Articles
    dispatch(fetchFrmQus());
  }, []);

  useEffect(() => {
    if (topicDetails && topicDetails._id) {
      setTopicId(topicDetails._id);
      setTopic(topicDetails.topic);
      setTopicDescription(topicDetails.description);
    }
    if (subTopicDetails && subTopicDetails._id) {
      setSubTopicId(subTopicDetails._id);
      setSubTopic(subTopicDetails.subTopic);
      setSubTopicDescription(subTopicDetails.description);
    }
  }, [topicDetails, subTopicDetails]);

  // handle Click event of the SubTopic
  const handleQuestionClick = (e, topicId, subTopicId, questionId) => {
    e.preventDefault();
    history.push({ pathname: "/registered/faq_answers", state: { frmTopicId: topicId, frmSubTopicId: subTopicId, frmQuestionId: questionId } });
  };

  const handleAddQuestionClick = (e, topicId, subTopicId) => {
    e.preventDefault();
    history.push({ pathname: "/registered/faq_question_form", state: { frmTopicId: topicId, frmSubTopicId: subTopicId } });
  };

  const handleEditQuestionClick = (e, topicId, subTopicId, questionId) => {
    e.preventDefault();
    history.push({ pathname: "/registered/faq_question_form", state: { frmTopicId: topicId, frmSubTopicId: subTopicId, frmQuestionId: questionId } });
  };

  const handleAddAnswerClick = (e, questionId) => {
    e.preventDefault();
    history.push({ pathname: "/registered/faq_answer_form", state: { frmQuestionId: questionId } });
  };

  const handleDeleteAnswerClick = (e, questionId) => {
    e.preventDefault();
    let pathParam = {
      path: "/registered/faq_management",
      state: {},
    };
    let formData = { forumQuestionId: questionId };
    dispatch(deleteFrmAns(formData, history, pathParam));
  };

  return (
    <>
      <PageHeader />
      {/* Page content */}
      <Container className="mt--7" fluid>
        <Row className="mt-5">
          <div className="col">
            <Card className="shadow">
              <CardHeader className="border-0">
                <div className="row">
                  <div className="mb-xl-0 col-10">
                    <small>{topic && topic} &nbsp;&nbsp;&nbsp;&gt; </small>
                    <h3 className="mb-0">{subTopic && subTopic}</h3>
                    <h6 className="mb-0">{subTopicDescription && subTopicDescription}</h6>
                  </div>
                  <div className="mb-xl-0 col-2 text-right">
                    <Tooltip title="Add Question">
                      <div
                        className="navbar-toggler"
                        style={{ cursor: "pointer" }}
                        onClick={(e) => {
                          handleAddQuestionClick(e, topicId, subTopicId);
                        }}
                      >
                        <i
                          className="fas fa-plus-circle"
                          style={{
                            fontSize: 30,
                            color: GlobalStylesColor.mbppThemeBlue,
                          }}
                        />
                      </div>
                    </Tooltip>
                  </div>
                </div>
              </CardHeader>
              <Table className="align-items-center table-flush" responsive>
                <thead className="thead-light">
                  <tr>
                    <th scope="col">Questions</th>
                    <th scope="col">Description</th>
                    <th scope="col">Actions</th>
                  </tr>
                </thead>
                <tbody>
                  <tr></tr>
                  {frmQuestionsByTopic.length > 0 &&
                    Object.values(frmQuestionsByTopic).map((props, i) => {
                      return (
                        <ForumTableQuestionRow
                          key={i}
                          topicId={topicId}
                          subTopicId={subTopicId}
                          QusId={props._id}
                          question={props.question}
                          image={props.image}
                          description={props.description}
                          status={props.status}
                          questionClick={handleQuestionClick}
                          questionEdit={handleEditQuestionClick}
                          addAnswerClick={handleAddAnswerClick}
                          questionDelete={handleDeleteAnswerClick}
                        />
                      );
                    })}
                </tbody>
              </Table>
              <CardFooter className="py-4">
                <div>
                  <Col lg="6" className="text-left">
                    <Button
                      style={ChsStyles.GeneralButton}
                      color="primary"
                      onClick={() => {
                        history.push({ pathname: "/registered/faq_management" });
                      }}
                    >
                      BACK
                    </Button>
                  </Col>
                </div>
              </CardFooter>
            </Card>
          </div>
        </Row>
      </Container>
    </>
  );
};

export default ForumSubTopic;
