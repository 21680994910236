
import {
  REPORT_ANSWER_ALL,
  REPORT_ANSWER_BY_USER,
  REPORT_ANSWER_BY_COUNTRY,
  REPORT_ANSWER_STAT,
  REPORT_USER_STAT,
} from 'constants/actionTypes';

const initState = {
  answerAll: [],
  answerByUserAll:[],
  answerByCountryAll:[],
  answerStat:[],
  userStat:[],
}

const resourceReducer = (report = initState, action) => {
  switch (action.type) {
     case REPORT_ANSWER_ALL:
      return { ...report, answerAll: action.payload };
     case REPORT_ANSWER_BY_USER:
      return { ...report, answerByUserAll: action.payload };
     case REPORT_ANSWER_BY_COUNTRY:
      return { ...report, answerByCountryAll: action.payload };
     case REPORT_ANSWER_STAT:
      return { ...report, answerStat: action.payload };
     case REPORT_USER_STAT:
      return { ...report, userStat: action.payload };

    default:
      return report;
  }
};

export default resourceReducer;
