import "react-confirm-alert/src/react-confirm-alert.css"; // Import css
import { TextField, Tooltip } from "@material-ui/core";
import { fetchResourceAll, updateResource, deleteResource } from "actions/resource";
// core components
import PageHeader from "components/Headers/PageHeader.js";
import Config from "config/config.json";
import { LOGINSTATE } from "../../../config/config.json";
import { GlobalStylesColor } from "constants/globalStyles";
import Lodash from "lodash";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useHistory, useLocation } from "react-router-dom";
// reactstrap components
import { Card, CardHeader, Container, Row, Table } from "reactstrap";
import { fetchMasterDataLanguageAll } from "actions/masterData";
import { confirmAlert } from "react-confirm-alert";


const ResourceRowApp = ({ resource, editClick, statusClick, viewClick, createClick, deleteClick }) => (
  // CATEGORY |	TITLE |	DESCRIPTION |	FILE |	PUBLISHED |	ACTIONS
  <tr>
    <td style={{ whiteSpace: "break-spaces" }}>{resource.title}</td>

    <td style={{ whiteSpace: "break-spaces" }}>{resource.description}</td>

    <td style={{ whiteSpace: "break-spaces" }}>{resource.category === "local" ? resource.file : null}</td>

    <td className="text-right">
      <div className="row">
        <Tooltip title="Edit" arrow>
          <div
            className="navbar-toggler"
            style={{ cursor: "pointer" }}
            onClick={(e) => {
              editClick(e, resource);
            }}
          >
            <i className="far fa-edit" style={{ opacity: resource.published ? 0.3 : 1 }} />
          </div>
        </Tooltip>

        <Tooltip title="View resource" arrow>
          <div
            className="navbar-toggler"
            style={{ cursor: "pointer" }}
            onClick={(e) => {
              viewClick(e, resource);
            }}
          >
            <i className="far fa-eye" />
          </div>
        </Tooltip>

        <Tooltip title="Delete resource" arrow>
          <div
            className="navbar-toggler"
            style={{ cursor: "pointer" }}
            onClick={(e) => {
              confirmAlert({
                title: 'Confirm delete',
                buttons: [
                  { label: "No", onClick: () => { } },
                  {
                    label: "Yes", onClick: () => {
                      deleteClick(e, resource);
                    }
                  },
                ],
                closeOnEscape: false,
                closeOnClickOutside: false
              });
            }}
          >
            <i className="far fa-times-circle" />
          </div>
        </Tooltip>
      </div>
    </td>

  </tr>
);

const ResourceRowInternationalization = ({ resource, i18editClick, i18viewClick, i18createClick, i18deleteClick }) => (
  // CATEGORY |	TITLE |	DESCRIPTION |	FILE |	PUBLISHED |	ACTIONS
  <tr>
    <td style={{ whiteSpace: "break-spaces" }}><b>{resource.Language}</b> | {resource.category}</td>
    <td style={{ whiteSpace: "break-spaces" }}>{resource.Primer ? resource.Primer : resource.Logo ? resource.Logo : resource.json ? resource.json : null}</td>
    <td className="text-right">
      <div className="row">

        <Tooltip title="View resource" arrow>
          <div
            className="navbar-toggler"
            style={{ cursor: "pointer" }}
            onClick={(e) => {
              i18viewClick(e, resource);
            }}
          >
            <i className="far fa-eye" />
          </div>
        </Tooltip>

        <Tooltip title="Delete resource" arrow>
          <div
            className="navbar-toggler"
            style={{ cursor: "pointer" }}
            onClick={(e) => {
              confirmAlert({
                title: 'Confirm delete',
                buttons: [
                  { label: "No", onClick: () => { } },
                  {
                    label: "Yes", onClick: () => {
                      i18deleteClick(e, resource);
                    }
                  },
                ],
                closeOnEscape: false,
                closeOnClickOutside: false
              });
            }}
          >
            <i className="far fa-times-circle" />
          </div>
        </Tooltip>
      </div>
    </td>
  </tr>
);

// export function
const Recommendation = () => {
  // dispatch and history
  const dispatch = useDispatch();
  const history = useHistory();
  const location = useLocation();

  // get forum topics
  const resourceAll = useSelector((state) => state.resource.resourceAll);

  // Call back of success delete
  const resourceDeleted = useSelector((state) => state.resource.deleteResource);

  const language = useSelector((state) => state.masterData.languageAll);
  const [selectedLanguage, setSelectedLanguage] = useState('en')

  useEffect(() => {
    dispatch(fetchMasterDataLanguageAll());
  }, []);

  useEffect(() => {
    dispatch(fetchResourceAll());
  }, [resourceDeleted]);


  const handleStatusClick = (e, resource) => {
    e.preventDefault();
    if (LOGINSTATE) console.log("resource", resource);
    if (resource && !Lodash.isEmpty(resource) && resource.status === true) {
      let values = {
        resourceId: resource._id,
        status: false,
      };
      dispatch(updateResource(values, history));
    } else {
      let values = {
        resourceId: resource._id,
        status: true,
      };
      dispatch(updateResource(values, history));
    }
  };

  const handleViewClick = (e, resource) => {
    console.log("resource", resource);
    e.preventDefault();
    let pathToResource = Config.SERVER_API_URL + "uploads/resource/" + resource.file;
    if (LOGINSTATE) console.log("pathToResource: ", pathToResource);
    window.open(pathToResource, "_blank");
  };

  const handleEditClick = (e, resource, type) => {
    e.preventDefault();
    history.push({ pathname: "/registered/resource_details", state: { resource: resource } });
  };

  const handleDeleteClick = (e, resource) => {
    if (LOGINSTATE) console.log("resource", resource);
    e.preventDefault();
    let values = {
      resourceId: resource._id,
      resourceCategory: resource.category,
      resourceJSON: resource.json,
      resourcePrimer: resource.Primer,
      resourceLogo: resource.Logo,
    };
    dispatch(deleteResource(values, history));
  };

  const handleCreateClick = (e, resource, type) => {
    e.preventDefault();
  };

  const handleViewClicki18 = (e, resource) => {
    console.log("resource", resource);
    e.preventDefault();
    if (resource.category === "local") {
      let pathToResource = Config.SERVER_API_URL + "uploads/resource/" + resource.file;
      if (LOGINSTATE) console.log("pathToResource: ", pathToResource);
      window.open(pathToResource, "_blank");
    } else if (resource.category === "Localization json") {
      let pathToResource = Config.SERVER_API_URL + "uploads/json/" + resource.json;
      if (LOGINSTATE) console.log("pathToResource: ", pathToResource);
      window.open(pathToResource, "_blank");
    } else if (resource.category === "Primer") {
      let pathToResource = Config.SERVER_API_URL + "uploads/primer/" + resource.Primer;
      if (LOGINSTATE) console.log("pathToResource: ", pathToResource);
      window.open(pathToResource, "_blank");
    } else if (resource.category === "Logo") {
      let pathToResource = Config.SERVER_API_URL + "uploads/conceptImage/" + resource.Logo;
      if (LOGINSTATE) console.log("pathToResource: ", pathToResource);
      window.open(pathToResource, "_blank");
    }
  };

  const handleEditClicki18 = (e, resource, type) => {
    e.preventDefault();
    history.push({ pathname: "/registered/resource_details_i18", state: { resource: resource, edit: true } });
  };

  const handleDeleteClicki18 = (e, resource) => {
    console.log("resource", resource);
    e.preventDefault();
    let values = {
      resourceId: resource._id,
      resourceCategory: resource.category,
      resourceJSON: resource.json,
      resourcePrimer: resource.Primer,
      resourceLogo: resource.Logo,
    };
    dispatch(deleteResource(values, history));
  };

  const handleCreateClicki18 = (e, resource, type) => {
    e.preventDefault();
  };

  const handleCreateNewClick = (e, resource, type) => {
    e.preventDefault();
    history.push({ pathname: "/registered/resource_details" });
  };

  const handleCreateNewClicki18 = (e, resource, type) => {
    e.preventDefault();
    history.push({ pathname: "/registered/resource_details_i18", state: { resource: resource, edit: false } });
  };

  return (
    <>
      <PageHeader />

      {/* Page content */}
      <Container className="mt--7" fluid>
        <Row className="mt-5">
          <div className="col">
            <Card className="shadow">
              <CardHeader className="border-0">
                <div className="row">
                  <div className="mb-xl-0 col-11">
                    <h3 className="mb-0">APP Resources</h3>
                    <h6 className="mb-0">Resources for tools and more</h6>
                  </div>
                  <div className="mb-xl-0">
                    <Tooltip title="Add new resource" arrow>
                      <div
                        className="navbar-toggler"
                        style={{ cursor: "pointer" }}
                        onClick={(e) => {
                          handleCreateNewClick(e);
                        }}
                      >
                        <i className="fas fa-plus-circle" style={{ fontSize: 30, color: GlobalStylesColor.mbppThemeBlue }} />
                      </div>
                    </Tooltip>
                  </div>
                </div>
              </CardHeader>
              <Table className="align-items-center table-flush" responsive>
                <thead className="thead-light">
                  <tr>
                    <th scope="col">Title</th>
                    <th scope="col">Description</th>
                    <th scope="col">File</th>
                    <th scope="col">Actions</th>
                  </tr>
                </thead>
                <tbody>
                  <tr></tr>
                  {resourceAll.map((resource, i) =>
                    resource.status === true && resource.category === "local" ? (
                      <ResourceRowApp
                        key={i}
                        resource={resource}
                        editClick={handleEditClick}
                        statusClick={handleStatusClick}
                        viewClick={handleViewClick}
                        createClick={handleCreateClick}
                        deleteClick={handleDeleteClick} />
                    ) : null
                  )}
                </tbody>
              </Table>
            </Card>

            <br />
            <br />

            <Card className="shadow">
              <CardHeader className="border-0">
                <div className="row">
                  <div className="mb-xl-0 col-6">
                    <h3 className="mb-0">Internationalization Resources</h3>
                    <h6 className="mb-0">Resources needed for internationalization</h6>
                  </div>
                  <div className="mb-xl-0 col-5">
                    <TextField
                      select
                      fullWidth
                      label={"Language*"}
                      variant="outlined"
                      value={selectedLanguage}
                      onChange={(e) => { setSelectedLanguage(e.target.value) }}
                      SelectProps={{ native: true }}
                      className={"form-control-alternative form-control-edit"}
                    >
                      <option key="" value=""></option>
                      {language.map((item, i) => (
                        <option key={item.code} value={item.code}>
                          {item.name}
                        </option>
                      ))}
                    </TextField>
                  </div>
                  <div className="mb-xl-0">
                    <Tooltip title="Add new resource" arrow>
                      <div
                        className="navbar-toggler"
                        style={{ cursor: "pointer" }}
                        onClick={(e) => {
                          handleCreateNewClicki18(e, resourceAll);
                        }}
                      >
                        <i className="fas fa-plus-circle" style={{ fontSize: 30, color: GlobalStylesColor.mbppThemeBlue }} />
                      </div>
                    </Tooltip>
                  </div>
                </div>
              </CardHeader>
              <Table className="align-items-center table-flush" responsive>
                <thead className="thead-light">
                  <tr>
                    <th scope="col">Category</th>
                    <th scope="col">File</th>
                    <th scope="col">Actions</th>
                  </tr>
                </thead>
                <tbody>
                  <tr></tr>
                  {(Lodash.filter(resourceAll, { Language: selectedLanguage })).map((resource, i) =>
                    (resource.category === "Localization json" || resource.category === "Primer" || resource.category === "Logo") ? (
                      <ResourceRowInternationalization
                        key={i}
                        resource={resource}
                        i18editClick={handleEditClicki18}
                        i18viewClick={handleViewClicki18}
                        i18createClick={handleCreateClicki18}
                        i18deleteClick={handleDeleteClicki18} />
                    ) : null
                  )}
                </tbody>
              </Table>
            </Card>
          </div>
        </Row>
      </Container>
    </>
  );
};

export default Recommendation;
