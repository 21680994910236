import React, { useState, useEffect } from "react";
import { useFormik } from "formik";
import * as yup from "yup";
import Lodash from "lodash";
import { useDispatch, useSelector } from "react-redux";
import { useLocation, useHistory } from "react-router-dom";
import { ChsStyles } from "constants/globalStyles";

// reactstrap components
import { Card, CardHeader, CardBody, CardFooter, Container, FormGroup, Form, Button, Row, Col } from "reactstrap";

import { TextField, Grid } from "@material-ui/core";

import { fetchFrmTopics, createFrmSubTopic, updateFrmSubTopic } from "actions/forum";
import { fetchMasterDataLanguageAll } from "actions/masterData";

// core components
import PageHeader from "components/Headers/PageHeader.js";
import { LOGINSTATE } from "../../../config/config.json";

const validationSchemaSubTopic = yup.object({
  subTopic: yup.string("Enter contact title").min(2, "Description should be of minimum 2 characters length").max(2000, "Contact title should be of maximum 2000 characters length").required("Contact title is required"),
  description: yup.string("Enter contact description").min(5, "Description should be of minimum 5 characters length").max(2000, "Description should be of maximum 2000 characters length"),
});

const ForumSubTopicForm = (props) => {
  // dispatch and history
  const dispatch = useDispatch();
  const history = useHistory();
  // set state topic
  const [topic, setTopic] = useState(null);
  // set state topic description
  const [topicDescription, setTopicDescription] = useState(null);
  //set state edit or not
  const [isEdit, setIsEdit] = useState(false);

  if(LOGINSTATE) console.log("props", props);

  // get topic details
  const selectFrmTopic = (state) => {
    const itemArray = state.forum.frmAllTopics?.filter((item) => {
      return item._id === props.location.state?.frmTopicId;
    });
    if (itemArray && itemArray[0]) {
      return itemArray[0];
    }
  };
  const topicDetails = useSelector(selectFrmTopic);
  // get sub topic details
  const selectFrmSubTopic = (topicDetails) => {
    const itemArray =
      topicDetails?.subTopic?.length > 0 &&
      topicDetails?.subTopic?.filter((item) => {
        return item._id === props.location.state?.frmSubTopicId;
      });
    if (itemArray && itemArray[0]) {
      return itemArray[0];
    }
  };

  const subTopicDetails = selectFrmSubTopic(topicDetails);
  const language = useSelector((state) => state.masterData.languageAll);

  useEffect(() => {
    // fetching forum topics
    dispatch(fetchFrmTopics());
  }, []);

  useEffect(() => {
    if (topicDetails && topicDetails._id) {
      formik.values.forumTopicId = topicDetails._id;
      setTopic(topicDetails.topic);
      setTopicDescription(topicDetails.description);
    }
    if (subTopicDetails && subTopicDetails._id) {
      formik.values.forumSubTopicId = subTopicDetails._id;
      formik.values.subTopic = subTopicDetails.subTopic;
      formik.values.description = subTopicDetails.description;
      formik.values.status = subTopicDetails.status;
    }
    dispatch(fetchMasterDataLanguageAll());

    //edit or new
    if (props.location.state?.frmSubTopicId) {
      setIsEdit(true);
    } else {
    }
    if(LOGINSTATE) console.log("subTopicDetails", subTopicDetails);
  }, [topicDetails, subTopicDetails]);

  const formik = useFormik({
    initialValues: {
      forumTopicId: "",
      forumSubTopicId: "",
      subTopic: "",
      description: "",
      status: true,
      redirect: "/registered/forum",
    },
    validationSchema: validationSchemaSubTopic,
    onSubmit: (values, onSubmitProps) => {
      if (values.forumTopicId && values.forumSubTopicId) {
        if(LOGINSTATE) console.log("values", values);
        dispatch(updateFrmSubTopic(values, history));
      } else {
        dispatch(createFrmSubTopic(values, history));
      }
    },
  });

  return (
    <>
      <PageHeader />
      {/* Page content */}
      <Container className="mt--7" fluid>
        {/* Table School */}
        <Row className="mt-5">
          <div className="col">
            <Card className="shadow">
              <CardHeader className="border-0">
                <h3 className="mb-0">Localization Details</h3>
              </CardHeader>
              <Form role="form" onSubmit={formik.handleSubmit} onReset={formik.handleReset}>
                <CardBody>
                  <FormGroup className="mb-3">
                    <Grid container spacing={1} alignItems="center" style={ChsStyles.Grid}>
                      <Grid item xs={1} sm={1} className="px-3 text-right">
                        <i className="fas fa-heading  text-muted" />
                      </Grid>
                      <Grid item xs={10} sm={10}>
                        <TextField
                          select
                          fullWidth
                          id="subTopic"
                          name="subTopic"
                          label="Language"
                          variant="outlined"
                          value={formik.values.subTopic}
                          onChange={formik.handleChange}
                          SelectProps={{
                            native: true,
                          }}
                          className={"form-control-alternative form-control-edit"}
                          error={formik.touched.subTopic && Boolean(formik.errors.subTopic)}
                          helperText={formik.touched.subTopic && formik.errors.subTopic}
                          disabled={isEdit}
                        >
                          <option key="" value=""></option>
                          {!Lodash.isEmpty(props.location.state?.language) &&
                            props.location.state?.language.map((item, i) => (
                              <option key={item.name} value={item.code}>
                                {item.name}
                              </option>
                            ))}
                        </TextField>
                      </Grid>
                    </Grid>
                    <Grid container spacing={1} alignItems="center" style={ChsStyles.Grid}>
                      <Grid item xs={1} sm={1} className="px-3 text-right">
                        <i className="ni ni-align-left-2" />
                      </Grid>
                      <Grid item xs={10} sm={10}>
                        <TextField
                          fullWidth
                          id="description"
                          name="description"
                          label="Description"
                          variant="outlined"
                          value={formik.values.description}
                          onChange={formik.handleChange}
                          multiline
                          rows={4}
                          error={formik.touched.description && Boolean(formik.errors.description)}
                          helperText={formik.touched.description && formik.errors.description}
                        />
                      </Grid>
                    </Grid>
                  </FormGroup>
                </CardBody>

                <CardFooter className="py-4">
                  <Row>
                    <Col lg="6" className="text-left">
                      <Button
                        style={ChsStyles.GeneralButton}
                        color="primary"
                        onClick={() => {
                          history.push({pathname: "/registered/faq_management"});
                        }}
                      >
                        BACK
                      </Button>
                    </Col>
                    <Col lg="6" className="text-right">
                      <Button style={ChsStyles.GeneralButton} color="primary" type="reset">
                        RESET
                      </Button>
                      <Button style={ChsStyles.GeneralButton} color="primary" type="submit">
                        {formik.values.forumTopicId && formik.values.forumSubTopicId ? "UPDATE" : "SAVE"}
                      </Button>
                    </Col>
                  </Row>
                </CardFooter>
              </Form>
            </Card>
          </div>
        </Row>
      </Container>
    </>
  );
};

export default ForumSubTopicForm;
