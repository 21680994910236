// Option 2
import { TextField, RtlTextField } from "../../../components/Custom/RTLTextField";

import { Grid, IconButton, InputAdornment, TextField as TextFieldDef } from "@material-ui/core";
import Visibility from "@material-ui/icons/Visibility";
import VisibilityOff from "@material-ui/icons/VisibilityOff";
import { signin, signUp } from "actions/user";
import { MbppStyles } from "constants/globalStyles";
import { LANGUAGE } from "constants/actionTypes";
import { fetchMasterDataLanguage, fetchLocalizationContent } from "actions/masterData";
import { useFormik } from "formik";
import React, { useState, useEffect } from "react";

import "react-confirm-alert/src/react-confirm-alert.css"; // Import css

import { useDispatch, useSelector } from "react-redux";
import { useHistory, Link, NavLink } from "react-router-dom";

import { useTranslation, initReactI18next, Trans } from "react-i18next";
import { CryptoPassword, initialValues_assessmentPage, RTL_FLAG_RIGHT, RTL_FLAG_LEFT } from "helper";
import { LOGINSTATE } from "../../../config/config.json";
import Lodash from "lodash";

// reactstrap components
import { Button, Card, CardBody, Col, Form, FormGroup, Row, Container } from "reactstrap";
import * as yup from "yup";
import { ImportExportTwoTone } from "@material-ui/icons";

// const validationSchemaSignin = yup.object({
//   email: yup.string("Enter your email").email("Enter a valid email").required("Email is required"),
//   password: yup.string("Enter your password").min(3, "Password should be of minimum 8 characters length").required("Password is required"),
// });

const validationSchemaSignUp = yup.object({});

const Login = () => {
  // dispatch and history
  const dispatch = useDispatch();
  const history = useHistory();
  // set state contact number list
  const [contactList, setContactList] = useState([{ index: 0, contactno: "" }]);

  const [rtlAlignment, setRtlAlignment] = useState(RTL_FLAG_LEFT);
  const [systemContentLocale, setSystemContentLocale] = useState({});

  // set state signup /signin
  const [isSignup, setIsSignup] = useState(false);

  const { t, i18n } = useTranslation();

  const userLang = useSelector((state) => state.masterData.languageByCode);
  if (LOGINSTATE) console.log("userLanguage: ", userLang);

  useEffect(() => {
    if (userLang && userLang.language && userLang.language.length > 0) {
      const lang = userLang.language[0]

      i18n.changeLanguage(lang.code);
      const params = { languageCode: lang.code };
      dispatch(fetchLocalizationContent(params, history));

      if (lang && lang.dir === "rtl") {
        setRtlAlignment(RTL_FLAG_RIGHT);
      }
      else {
        setRtlAlignment(RTL_FLAG_LEFT);
      }
    }
  }, [userLang]);

  // get system content
  const systemContent = useSelector((state) => state.masterData.localizationContentAll);
  useEffect(() => {
    if (LOGINSTATE) console.log("systemContent from server: ", systemContent);
    if (systemContent !== undefined && systemContent !== null && Lodash.isArray(systemContent) && systemContent.length > 0) {
      setSystemContentLocale(systemContent[0].assessmentPage);
    } else {
      setSystemContentLocale(initialValues_assessmentPage);
    }
  }, [systemContent]);

  // handle click event of the Remove button of contact number

  // handle click event of the Add button of contact number

  // handle click event of password visible and password dotted
  const [showPassword, setShowPassword] = useState(false);
  const handleShowPassword = () => setShowPassword(!showPassword);

  const formik = useFormik({
    initialValues: {
      firstName: "",
      lastName: "",
      email: "",
      password: "",
      confirmPassword: "",
      profilePicture: "",
      profileThumbnail: "",
      newProfilePicture: false,
      age: "",
      gender: "",
      title: "",
      telephone: [""],
      address: "",
      emp_type: "",
      emp_designation: "",
      emp_institute: "",
      emp_department: "",
      emp_address: "",
      country: "",
      legal_entity: "",
      geo_area: "",
      no_emp: "",
      drm_act: "",
      ins_city: "",
    },
    // validationSchema: isSignup ? validationSchemaSignUp : validationSchemaSignin,
    validationSchema: isSignup
      ? validationSchemaSignUp
      : yup.object({
        email: yup.string(t('registerPage.section2.enterEmail'))
          .email(t('registerPage.section2.emailInvalid'))
          .required(t('registerPage.section2.emailRequired')),
        password: yup.string(t('registerPage.section2.enterPassword'))
          .min(6, t('registerPage.section2.parameterLengthMin'))
          .required(t('registerPage.section2.passwordRequired')),
      }),
    onSubmit: (values, onSubmitProps) => {
      if (isSignup) {
        values.password = CryptoPassword(values.password);
        if (LOGINSTATE) console.log("systemContentLocale", systemContentLocale);
        dispatch(signUp(values, history, systemContentLocale));
      } else {
        values.password = CryptoPassword(values.password);
        dispatch(signin(values, history, systemContentLocale));
        if (LOGINSTATE) console.log("systemContentLocale", systemContentLocale);
      }

      onSubmitProps.setSubmitting(false);
      setContactList([{ index: 0, contactno: "" }]);
      onSubmitProps.resetForm();
    },
    onReset: () => {
      setContactList([{ index: 0, contactno: "" }]);
    },
  });

  return (
    <>
      <div className="main-content" style={{ backgroundColor: "#af1e23" }}>
        {/* ToDo: explore */}
        {/* <AuthNavbar /> */}
        <div
          className="header py-6 py-lg-6"
          style={{
            backgroundImage: "url(" + require("../../../assets/img/theme/img_bg.jpg").default + ")",
            backgroundSize: "cover",
            backgroundPosition: "center top",
          }}
        >
          {/* Mask */}
          {/*bg-gradient-yellow*/}
          <span className="mask opacity-2" />
          <Container>
            <div className="header-body text-center mb-5">
              <Row className="justify-content-center">
                <Col lg="5" md="6">
                  <br />
                  <br />
                </Col>
              </Row>
            </div>
          </Container>
          <div className="separator separator-bottom separator-skew zindex-100" style={{ marginBottom: "-2px" }}>
            <svg xmlns="http://www.w3.org/2000/svg" preserveAspectRatio="none" version="1.1" viewBox="0 0 2560 100" x="0" y="0">
              <polygon className="fill-default" points="2560 0 2560 100 0 100" />
            </svg>
          </div>
        </div>
        {/* Page content */}
        <Container className="mt--7 pb-5">
          <Row className="justify-content-center">
            <Col lg="5" md="6">
              <Card className="bg-secondary shadow border-1">
                <CardBody className="px-lg-4 py-lg-4">
                  {rtlAlignment === RTL_FLAG_RIGHT ? (
                    <div className="heading-title text-center mb-4" style={{ color: "#af1e23", fontWeight: "bold", textAlign: "right" }}>
                      <span>{t("loginPage.section1.title")}</span>
                    </div>
                  ) : (
                    <div className="heading-title text-center mb-4" style={MbppStyles.Topic}>
                      <span>{t("loginPage.section1.title")}</span>
                    </div>
                  )}

                  <Form role="form" onSubmit={formik.handleSubmit} onReset={formik.handleReset}>
                    <>
                      <FormGroup className="mb-3 ">
                        <Grid container spacing={1} alignItems="right" style={MbppStyles.Grid}>
                          <Grid item xs={1} sm={1}>
                            <i className="ni ni-email-83" />
                          </Grid>
                          <Grid item xs={11} sm={11}>
                            <RtlTextField
                              fullWidth
                              rtol={rtlAlignment === RTL_FLAG_RIGHT}
                              id="email"
                              name="email"
                              label={t("loginPage.section1.email")}
                              variant="outlined"
                              value={formik.values.email}
                              onChange={formik.handleChange}
                              className={"form-control-alternative form-control-edit"}
                              error={formik.touched.email && Boolean(formik.errors.email)}
                              helperText={formik.touched.email && formik.errors.email}
                            />
                          </Grid>
                        </Grid>
                        <Grid container spacing={1} alignItems="right" style={MbppStyles.Grid}>
                          <Grid item xs={1} sm={1}>
                            <i className="ni ni-lock-circle-open" />
                          </Grid>
                          <Grid item xs={11} sm={11}>
                            <RtlTextField
                              fullWidth
                              rtol={rtlAlignment === RTL_FLAG_RIGHT}
                              id="password"
                              name="password"
                              label={t("loginPage.section1.password")}
                              variant="outlined"
                              type={showPassword ? "text" : "password"}
                              value={formik.values.password}
                              onChange={formik.handleChange}
                              className={"form-control-alternative form-control-edit"}
                              error={formik.touched.password && Boolean(formik.errors.password)}
                              helperText={formik.touched.password && formik.errors.password}
                              InputProps={
                                showPassword !== null && rtlAlignment === RTL_FLAG_RIGHT
                                  ? {
                                    startAdornment: (
                                      <InputAdornment position="start">
                                        <IconButton onClick={handleShowPassword}>{showPassword ? <Visibility /> : <VisibilityOff />}</IconButton>
                                      </InputAdornment>
                                    ),
                                  }
                                  : showPassword !== null && rtlAlignment === RTL_FLAG_LEFT
                                    ? {
                                      endAdornment: (
                                        <InputAdornment position="end">
                                          <IconButton onClick={handleShowPassword}>{showPassword ? <Visibility /> : <VisibilityOff />}</IconButton>
                                        </InputAdornment>
                                      ),
                                    }
                                    : null
                              }
                            />
                          </Grid>
                        </Grid>
                      </FormGroup>
                    </>

                    <div className="text-center">
                      <Button variant="contained" color="primary" type="submit">
                        {t("loginPage.section1.login")}
                      </Button>
                    </div>
                  </Form>
                </CardBody>
              </Card>

              <Row className="mt-3">
                <Col xs="6">
                  <NavLink
                    className="nav-link-icon"
                    to="/public/reset"
                    tag={Link}
                  >
                    <span className="nav-link-inner--text text-light">
                      <small>{t('loginPage.section1.fogotPassword')}</small>
                    </span>
                  </NavLink>
                </Col>
                <Col className="text-right" xs="6">
                  <NavLink
                    className="nav-link-icon"
                    to="/public/register"
                    tag={Link}
                  >
                    <span className="nav-link-inner--text text-light">
                      <small>{t('loginPage.section1.newAccount')}</small>
                    </span>
                  </NavLink>
                </Col>
              </Row>

              <Row className="justify-content-right">
                <Col lg="5" md="6">
                  <br />
                  <br />
                </Col>
              </Row>

            </Col>
          </Row>
        </Container>
      </div>
    </>
  );
};

export default Login;
