import React from 'react';
import { RtlTextField } from "./RTLTextField";
import { InputAdornment, IconButton } from "@material-ui/core";
import AttachFileIcon from '@material-ui/icons/AttachFile';
import { RTL_FLAG_RIGHT } from 'helper';
import { confirmAlert } from "react-confirm-alert";
import { useTranslation, } from "react-i18next";
import Lodash from "lodash";

const RTLFileUpload = (props) => {
  const hiddenFileInput = React.useRef(null);
  const [selectedFileName, setSelectedFileName] = React.useState('')

  const { t } = useTranslation();

  const handleClick = (event) => {
    hiddenFileInput.current.click();
  };
  const handleChange = (event) => {
    const fileUploaded = event.target.files[0];
    if (fileUploaded) {
      // var pattern = /image-*/;
      if (Lodash.isArray(props.acceptPattern)) {
        let isAccepted = false
        props.acceptPattern.forEach(pattern => {
          if (fileUploaded.type.match(pattern)) {
            isAccepted = true;
          }
        });
        if (!isAccepted) {
          if (t) {
            confirmAlert({
              title: t("common.error"),
              message: (props.acceptPattern).join(' | '),
              buttons: [
                {
                  label: t("assessmentPage.app_ok"),
                  onClick: () => { },
                },
              ],
              closeOnEscape: false,
              closeOnClickOutside: false,
            });
          }
          return;
        }
      }


      setSelectedFileName(fileUploaded?.name)
      props.onChange(event);
    }

  };
  return (
    <>
      <RtlTextField
        {...props}
        rtol={props.rtol === RTL_FLAG_RIGHT}
        value={selectedFileName ? selectedFileName : props.value}
        // onClick={handleClick}
        InputProps={{
          shrink: true,
          readOnly: true,
          startAdornment: (
            <InputAdornment position={props.rtol === RTL_FLAG_RIGHT ? "start" : "end"}>
              <IconButton onClick={handleClick}>{<AttachFileIcon />}</IconButton>
            </InputAdornment>
          ),
        }}
      />

      <input type="file"
        ref={hiddenFileInput}
        onChange={handleChange}
        accept={props.acceptFilter}
        style={{ display: 'none' }}
      />
    </>
  );
};
export default RTLFileUpload;
