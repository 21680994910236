import React, { useState, useEffect } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { useLocation, useHistory } from "react-router-dom";
import Lodash from "lodash";
import { fetchFrmTopics, fetchFrmQus } from "actions/forum";
import Config from "config/config.json";
import { LOGINSTATE } from "../../../config/config.json";
import { fetchMasterDataLanguageAll, fetchMasterDataLanguage } from "actions/masterData";

// reactstrap components
import { Card, CardHeader, CardBody, Col, CardFooter, Table, Container, Row } from "reactstrap";
import Pagination from "react-responsive-pagination";

// core components
import PageHeader from "components/Headers/PageHeader.js";
import CommonHeader from "components/Headers/CommonHeader";
import FaqHeader from "components/Headers/FaqHeader";

const ForumTableSubTopicRow = ({ subTopicId, subTopic, language, rtlAlignment }) => (
  <tr id={subTopicId} style={{ textAlign: rtlAlignment }}>
    <td style={{ whiteSpace: "break-spaces", margin: 0, padding: 0 }}>
      <h4>{Lodash.find(language, { code: subTopic })?.name}</h4>
    </td>
    {/* <td style={{ whiteSpace: "break-spaces" }}>{description}</td> */}
  </tr>
);

const ForumTableQuestionRow = ({ QusId, question, subTopic, jUser, rtlAlignment }) =>
  rtlAlignment === "right" ? (
    <tr id={QusId}>
      <td style={{ whiteSpace: "break-spaces", margin: 0, padding: 0, color: "#af1e23" }}>
        <div className="row" style={{ marginBottom: 10, marginTop: 10 }}>
          <div class="col-md-12 text-right">
            <b>{question}</b>
          </div>
        </div>
      </td>
      {/* <td style={{ whiteSpace: "break-spaces" }}>{qdescription}</td>  */}
    </tr>
  ) : (
    <tr id={QusId}>
      <td style={{ whiteSpace: "break-spaces", margin: 0, padding: 0, color: "#af1e23" }}>
        <div className="row" style={{ marginLeft: 10, marginBottom: 10, marginTop: 10 }}>
          <b>{question}</b>
        </div>
      </td>
      {/* <td style={{ whiteSpace: "break-spaces" }}>{qdescription}</td>  */}
    </tr>
  );

const ForumTableAnswerRow = ({ answerId, answer, subTopic, jUser, rtlAlignment }) =>
  rtlAlignment === "right" ? (
    <tr id={answerId}>
      <td style={{ whiteSpace: "break-spaces", margin: 0, padding: 0 }}>
        <div className="row" style={{ marginBottom: 10, marginTop: 10 }}>
          <div class="col-md-12 text-right">{answer}</div>
        </div>
      </td>
      {/* <td style={{ whiteSpace: "break-spaces" }}>{ansdescription}</td> */}
    </tr>
  ) : (
    <tr id={answerId} style={{ textAlign: rtlAlignment }}>
      <td style={{ whiteSpace: "break-spaces", margin: 0, padding: 0 }}>
        <div className="row" style={{ marginLeft: 10, marginBottom: 10, marginTop: 10 }}>
          {answer}
        </div>
      </td>
      {/* <td style={{ whiteSpace: "break-spaces" }}>{ansdescription}</td> */}
    </tr>
  );

const Faq = () => {
  // dispatch and history
  const dispatch = useDispatch();
  const history = useHistory();
  const location = useLocation();
  // get forum topics
  const forumalltopics = useSelector((state) => state.forum.frmAllTopics);
  const forumallquestions = useSelector((state) => state.forum.frmAllQus);

  // pagination
  const itemPageSize = 1;
  const [itemCurrent, setItemCurrent] = useState(1);
  const [itemPageContent, setItemPageContent] = useState([]);
  const [itemPageCount, setItemPageCount] = useState(1);
  // filter
  const [txtFilter, setTxtFilter] = useState("");
  const [filterdContent, setFilterdContent] = useState([]);

  const language = useSelector((state) => state.masterData.languageAll);
  if (LOGINSTATE) console.log("forumalltopics: ", forumalltopics);
  if (LOGINSTATE) console.log("language: ", language);

  // set state topic id
  const [topicId, setTopicId] = useState(null);
  // set state topic
  const [topic, setTopic] = useState(null);
  // set state sub topic id
  const [subTopicId, setSubTopicId] = useState(null);
  // set state sub topic
  const [subTopic, setSubTopic] = useState(null);
  // set state sub topic description
  const [subTopicDescription, setSubTopicDescription] = useState(null);
  const [faqLanguages, setFaqLanguage] = useState([]);

  const [QusId, setQ] = useState(null);
  const [question, setQuestions] = useState(null);

  const [rtlAlignment, setRtlAlignment] = useState("left");
  const { t, i18n } = useTranslation();

  const userLanguage = useSelector((state) => state.masterData.languageByCode);
  console.log("userLanguage", userLanguage);
  useEffect(() => {
    // fetching forum topics
    dispatch(fetchFrmTopics(history, t));
    dispatch(fetchFrmQus(history, t));
    dispatch(fetchMasterDataLanguageAll());
    let params = {
      user: {
        code: jUser?.user?.language,
      },
    };
    dispatch(fetchMasterDataLanguage(params.user));
  }, []);

  useEffect(() => {
    userLanguage &&
      userLanguage.language &&
      Object.values(userLanguage.language).map((lang, i) => {
        if (lang && lang.dir === "rtl") {
          setRtlAlignment("right");
        }
      });
  }, [userLanguage]);

  useEffect(() => {
    if (subTopic && subTopicId) {
      setQ(subTopic._id);
      setQuestions(subTopic.topic);
    }
  }, [forumalltopics, forumallquestions]);

  // process content with filter
  useEffect(() => {
    // 1. filter the users form the filter
    let updatedOrgs = Lodash.filter(forumalltopics[1]?.subTopic, function (item) {
      return item.subTopic.indexOf(txtFilter) > -1;
    });
    setFilterdContent(updatedOrgs);
  }, [forumalltopics, txtFilter]);

  // process the pagination with page content
  useEffect(() => {
    if (LOGINSTATE) console.log("filterdContent.length: ", filterdContent.length);
    if (LOGINSTATE) console.log("filtered cont", filterdContent);
    // Set the pagination count
    if (uRole === "ADMIN") {
      let currentPageCount = Math.ceil(filterdContent.length / itemPageSize);
      setItemPageCount(currentPageCount);

      // Set the pagination content
      if (filterdContent && filterdContent.length > 0) {
        // make sure the page doesn't exceed the array endings
        let startCount = (itemCurrent - 1) * itemPageSize < 0 ? 0 : (itemCurrent - 1) * itemPageSize;
        let endCount = itemCurrent * itemPageSize > filterdContent.length ? filterdContent.length : itemCurrent * itemPageSize;

        let updatedArray = Lodash.slice(filterdContent, startCount, endCount);
        if (updatedArray && updatedArray.length > 0) {
          setItemPageContent(updatedArray);
        } else {
          setItemPageContent([]);
        }
      } else {
        setItemPageContent([]);
      }
      if (LOGINSTATE) console.log("item page", itemPageContent);
    } else {
      setItemPageContent(filterdContent);
      // let currentPageCount = Math.ceil(filterdContent.length / filterdContent.length);
      // setItemPageCount(currentPageCount);

      // // Set the pagination content
      // if (filterdContent && filterdContent.length > 0) {
      //   // make sure the page doesn't exceed the array endings
      //   let startCount = (itemCurrent - 1) * itemPageSize < 0 ? 0 : (itemCurrent - 1) * itemPageSize;
      //   let endCount = itemCurrent * itemPageSize > filterdContent.length ? filterdContent.length : itemCurrent * itemPageSize;

      //   let updatedArray = Lodash.slice(filterdContent, startCount, endCount);
      //   if (updatedArray && updatedArray.length > 0) {
      //     setItemPageContent(updatedArray);
      //   } else {
      //     setItemPageContent([]);
      //   }
      // } else {
      //   setItemPageContent([]);
      // }
      if (LOGINSTATE) console.log("item page", itemPageContent);
    }
  }, [itemCurrent, filterdContent]);

  let currentUser = localStorage.getItem("userDetails");
  let jUser = JSON.parse(currentUser);
  let uRole = currentUser && jUser && jUser.user && jUser.user.urole ? jUser.user.urole : "GENERAL";
  if (LOGINSTATE) console.log("uRole", currentUser);

  return (
    <>
      <CommonHeader hType="faq" />
      {/* <FaqHeader /> */}

      {/* Page content */}
      <Container className="mt--7" fluid>
        {forumalltopics.length > 0 &&
          Object.values([forumalltopics[1]]).map((props, i) => {
            return (
              <Row key={i} className={i !== 0 ? "mt-5" : "mt-5"} id={props.id}>
                <div className="col">
                  <Card className="shadow">
                    <CardHeader className="border-0">
                      <div className="row">
                        <div className="mb-xl-0 col-12">
                          <h3 className="mb-0" style={{ textAlign: rtlAlignment }}>
                            {t("faq." + props.topic)}
                          </h3>
                          {/* <h6 className="mb-0">{props.description}</h6> */}
                        </div>
                      </div>
                      {/* {console.log("props **********", props)} */}
                    </CardHeader>
                    <CardBody>
                      <Table className="align-items-center table-flush" responsive>
                        {/* {itemPageContent &&
                          itemPageContent.length > 0 &&
                          itemPageContent.map((item, i) => { */}
                        {uRole === "ADMIN"
                          ? Object.values(itemPageContent).map((subprops, i) => {
                              if (LOGINSTATE) console.log("sub", subprops);
                              return (
                                <tbody>
                                  <>
                                    <ForumTableSubTopicRow
                                      key={i}
                                      topic={props.topic}
                                      description={props.description}
                                      topicId={props._id}
                                      subTopicId={subprops._id}
                                      subTopic={subprops.subTopic}
                                      // description={subprops.description}
                                      status={subprops.status}
                                      language={language}
                                    />
                                    {Lodash.filter(forumallquestions, {
                                      subTopic: subprops._id,
                                    }).map((index, j) => {
                                      {
                                        // console.log("id", subprops._id);
                                        // console.log("loop 2" + j, index);
                                      }
                                      return (
                                        <tbody>
                                          <ForumTableQuestionRow QusId={index} question={index.question} qdescription={index.description} subTopic={subprops.subTopic} />

                                          {Object.values(index.answer).map((ans, l) => {
                                            return <ForumTableAnswerRow answerId={ans._id} answer={ans.answer} ansdescription={ans.description} subTopic={subprops.subTopic} />;
                                          })}
                                        </tbody>
                                      );
                                    })}
                                  </>
                                </tbody>
                              );
                            })
                          : jUser.user.language !== " " && Lodash.filter(itemPageContent, { subTopic: jUser.user.language, status: true }).length > 0
                          ? Object.values(Lodash.filter(itemPageContent, { subTopic: jUser.user.language, status: true })).map((subprops, i) => {
                              if (LOGINSTATE) console.log("sub", subprops);

                              return (
                                <tbody>
                                  <>
                                    <ForumTableSubTopicRow
                                      key={i}
                                      topic={props.topic}
                                      description={props.description}
                                      topicId={props._id}
                                      subTopicId={subprops._id}
                                      subTopic={subprops.subTopic}
                                      // description={subprops.description}
                                      status={subprops.status}
                                      language={language}
                                      rtlAlignment={rtlAlignment}
                                    />
                                    {Lodash.filter(forumallquestions, {
                                      subTopic: subprops._id,
                                    }).map((index, j) => {
                                      {
                                        // console.log("id", subprops._id);
                                        // console.log("loop 2" + j, index);
                                      }
                                      return (
                                        <tbody>
                                          <ForumTableQuestionRow QusId={index} question={index.question} qdescription={index.description} subTopic={subprops.subTopic} rtlAlignment={rtlAlignment} />

                                          {Object.values(index.answer).map((ans, l) => {
                                            return <ForumTableAnswerRow answerId={ans._id} answer={ans.answer} ansdescription={ans.description} subTopic={subprops.subTopic} rtlAlignment={rtlAlignment} />;
                                          })}
                                        </tbody>
                                      );
                                    })}
                                  </>
                                </tbody>
                              );
                            })
                          : jUser.user.language === null
                          ? Object.values(Lodash.filter(itemPageContent, { subTopic: "en" })).map((subprops, i) => {
                              if (LOGINSTATE) console.log("sub", subprops);

                              return (
                                <tbody>
                                  <>
                                    <ForumTableSubTopicRow
                                      key={i}
                                      topic={props.topic}
                                      description={props.description}
                                      topicId={props._id}
                                      subTopicId={subprops._id}
                                      subTopic={subprops.subTopic}
                                      // description={subprops.description}
                                      status={subprops.status}
                                      language={language}
                                      rtlAlignment={rtlAlignment}
                                    />
                                    {Lodash.filter(forumallquestions, {
                                      subTopic: subprops._id,
                                    }).map((index, j) => {
                                      {
                                        // console.log("id", subprops._id);
                                        // console.log("loop 2" + j, index);
                                      }
                                      return (
                                        <tbody>
                                          <ForumTableQuestionRow QusId={index} question={index.question} qdescription={index.description} subTopic={subprops.subTopic} rtlAlignment={rtlAlignment} />

                                          {Object.values(index.answer).map((ans, l) => {
                                            return <ForumTableAnswerRow answerId={ans._id} answer={ans.answer} ansdescription={ans.description} subTopic={subprops.subTopic} rtlAlignment={rtlAlignment} />;
                                          })}
                                        </tbody>
                                      );
                                    })}
                                  </>
                                </tbody>
                              );
                            })
                          : Object.values(Lodash.filter(itemPageContent, { subTopic: "en" })).map((subprops, i) => {
                              if (LOGINSTATE) console.log("sub", subprops);
                              return (
                                <tbody>
                                  <>
                                    <ForumTableSubTopicRow
                                      key={i}
                                      topic={props.topic}
                                      description={props.description}
                                      topicId={props._id}
                                      subTopicId={subprops._id}
                                      subTopic={subprops.subTopic}
                                      // description={subprops.description}
                                      status={subprops.status}
                                      language={language}
                                      rtlAlignment={rtlAlignment}
                                    />
                                    {Lodash.filter(forumallquestions, {
                                      subTopic: subprops._id,
                                    }).map((index, j) => {
                                      {
                                        // console.log("id", subprops._id);
                                        // console.log("loop 2" + j, index);
                                      }
                                      return (
                                        <tbody>
                                          <ForumTableQuestionRow QusId={index} question={index.question} qdescription={index.description} subTopic={subprops.subTopic} rtlAlignment={rtlAlignment} />

                                          {Object.values(index.answer).map((ans, l) => {
                                            return <ForumTableAnswerRow answerId={ans._id} answer={ans.answer} ansdescription={ans.description} subTopic={subprops.subTopic} rtlAlignment={rtlAlignment} />;
                                          })}
                                        </tbody>
                                      );
                                    })}
                                  </>
                                </tbody>
                              );
                            })}
                      </Table>
                    </CardBody>
                    <CardFooter>
                      <Row>
                        <Col lg="12">
                          {itemPageCount && itemPageCount > 0 ? (
                            <Pagination
                              current={itemCurrent}
                              total={itemPageCount}
                              onPageChange={(current) => {
                                setItemCurrent(current);
                              }}
                            />
                          ) : null}
                        </Col>
                      </Row>
                    </CardFooter>
                  </Card>
                </div>
              </Row>
            );
          })}
      </Container>
    </>
  );
};

export default Faq;
